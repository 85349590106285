import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import moment from 'moment-timezone'

// // Components
import ShareButton from './ShareButton'
import Button from 'components/Button/Button'
import ErrorIndicator from 'components/ErrorIndicator/ErrorIndicator'
import Dialog from 'components/Dialog/Dialog'
import Calendar from 'components/Calendar/Calendar'
import TimePicker, { MomentTimeFormat } from 'components/Picker/TimePicker/TimePicker'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import Routes from 'helpers/routes'
import { ShareType } from 'scenes/ShareDialog/apiHelpers'

// Lang
import lang from 'scenes/ShareDialog/components/shareScheduleDialog.lang'
import buttonLang from 'helpers/lang/buttons.lang'

// Styles
import { color } from 'styles/variables'
import { styles } from 'scenes/ShareDialog/components/ShareScheduleDialog'

const mapStateToProps = (state, ownProps) => {
	// Settings
	const timeZones = safeGetNestedProp(state, 'sphere.timeZoneInfo.timeZones')
	const userTimeZone = (ownProps.user && timeZones && timeZones[ownProps.user.timeZone]) || null

	return {
		userTimeZone,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		goToAccountSettings: () => dispatch(push(Routes.Settings.Profile.generateLinkPath('timezone'))),
	}
}

class ScheduleShareDialog extends Component {
	static propTypes = {
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		formikValues: PropTypes.object,
		handleClose: PropTypes.func,
		clearUrgentBroadcast: PropTypes.func,
		setErrorMessage: PropTypes.func,
		getPost: PropTypes.func,
		share: PropTypes.func,
		reloadUser: PropTypes.func,
		isTwitterEnabled: PropTypes.bool,
		sharePost: PropTypes.object,
		openShareCompleteDialog: PropTypes.func,
		errorMessage: PropTypes.string,
		showShareChannels: PropTypes.bool,
		userChannelIds: PropTypes.array,
		setUserDefaultSharingChannels: PropTypes.func,
		setShareDateAndTime: PropTypes.func,
		user: PropTypes.object,
		viewport: PropTypes.object,
		primaryColor: PropTypes.string,
		userTimeZone: PropTypes.string,
		goToAccountSettings: PropTypes.func,
	}

	state = {
		calendarShareDate: '',
		calendarStartDate: '',
		calendarEndDate: '',
		calendarShareTime: '',
	}

	componentDidMount() {
		const { user } = this.props

		const momentShareDate = moment.tz(new Date(), user.timeZone) // Start with now in user's time zone
		let dayOffset = 0 // Today
		let hour = momentShareDate.hour()
		let minute = momentShareDate.minute()
		hour += minute < 50 ? 1 : 2 // Start next hour with at least 10 minutes left
		if (hour > 23) {
			dayOffset = 1 // Tomorrow
			hour = minute < 50 ? 0 : 1 // 12am or 1am
		}

		// Set share date/time
		momentShareDate
			.add(dayOffset, 'days')
			.hour(hour)
			.minute(minute)
			.second(0)
		const shareDate = momentShareDate.format()
		const shareTime = momentShareDate.format(MomentTimeFormat)

		// Set calendar range
		const calendarStartDate = shareDate
		const calendarEndDate = moment(shareDate)
			.add(1, 'years')
			.format()

		this.setState({
			calendarShareDate: shareDate,
			calendarShareTime: shareTime,
			calendarStartDate,
			calendarEndDate,
		})
	}

	handleCancel = e => {
		const { handleClose } = this.props
		e.preventDefault()
		handleClose('showScheduleShareDialog', 'showSharePostDialog')
	}

	handleClose = e => {
		e.preventDefault()
		const { handleClose } = this.props
		handleClose('showScheduleShareDialog')
	}

	handleScheduleDateChange = (shareDate, date) => {
		const { userTimeZone } = this.props

		// Convert calendar date to shareDate with date + time
		const userTimeZoneId = userTimeZone.id
		const newShareDate = moment.tz(date, userTimeZoneId)
		const oldShareDate = moment.tz(shareDate, userTimeZoneId)
		const formattedShareDate = newShareDate
			.hour(oldShareDate.hour())
			.minute(oldShareDate.minute())
			.format()

		this.setState({ calendarShareDate: formattedShareDate })
	}

	handleScheduleTimeChange = time => {
		const { userTimeZone } = this.props
		const { calendarShareDate } = this.state

		// Change time on calendar share date
		const userTimeZoneId = userTimeZone.id
		const newShareTime = moment(time, MomentTimeFormat)
		const oldShareDate = moment.tz(calendarShareDate, userTimeZoneId)
		const formattedShareDate = oldShareDate
			.hour(newShareTime.hour())
			.minute(newShareTime.minute())
			.format()

		this.setState({ calendarShareDate: formattedShareDate, calendarShareTime: time })
	}

	handleEditTimeZoneClick = e => {
		const { goToAccountSettings } = this.props
		this.handleClose(e)
		goToAccountSettings()
	}

	renderScheduleButton = () => {
		const {
			primaryColor,
			getPost,
			setUserDefaultSharingChannels,
			user,
			sharePost,
			share,
			showShareChannels,
			reloadUser,
			formikValues,
			clearUrgentBroadcast,
			setShareDateAndTime,
			userChannelIds,
			isTwitterEnabled,
			openShareCompleteDialog,
			setErrorMessage,
		} = this.props
		const { calendarShareDate, calendarShareTime } = this.state
		const values = {
			...formikValues,
			shareDate: calendarShareDate,
			shareTime: calendarShareTime,
		}
		return (
			<ShareButton
				setUserDefaultSharingChannels={setUserDefaultSharingChannels}
				clearUrgentBroadcast={clearUrgentBroadcast}
				getPost={getPost}
				userChannelIds={userChannelIds}
				primaryColor={primaryColor}
				postId={sharePost.postId}
				userId={user ? user.userId : null}
				userTimezone={user ? user.timeZone : null}
				reloadUser={reloadUser}
				share={share}
				values={values}
				showShareChannels={showShareChannels}
				isTwitterEnabled={isTwitterEnabled}
				openShareCompleteDialog={openShareCompleteDialog}
				setShareDateAndTime={setShareDateAndTime}
				setErrorMessage={setErrorMessage}
				render={(onSubmit, isDisabled) => {
					const dynamicStyles = {
						scheduleBackButton: {
							...styles.scheduleBackButton,
							color: isDisabled ? color.secondaryGrey : primaryColor,
						},
					}

					return (
						<Fragment>
							<span style={dynamicStyles.scheduleBackButton} onClick={this.handleCancel}>
								{buttonLang.back}
							</span>
							<Button
								onClick={() => onSubmit(ShareType.schedule)}
								disabled={isDisabled}
								type="submit"
							>
								{lang.scheduleSubmitButton}
							</Button>
						</Fragment>
					)
				}}
			/>
		)
	}

	render() {
		const { title, isOpen, errorMessage, userTimeZone, primaryColor, viewport } = this.props
		const { calendarEndDate, calendarShareTime, calendarStartDate, calendarShareDate } = this.state
		const timeZoneDisplay = userTimeZone ? userTimeZone.fullName : null
		const timeZoneId = userTimeZone ? userTimeZone.id : null

		const dynamicStyles = {
			editTimeZoneLink: {
				...styles.editTimeZoneLink,
				color: primaryColor,
			},
			scheduleError: {
				...styles.scheduleError,
				...styles.scheduleError[viewport.size],
			},
		}

		return (
			<Dialog
				title={title}
				isOpen={isOpen}
				onClose={this.handleClose}
				shouldCloseOnOverlayClick={false}
				submitButtons={this.renderScheduleButton()}
			>
				<Fragment>
					<ErrorIndicator
						isOpen={!!errorMessage}
						message={errorMessage}
						style={dynamicStyles.scheduleError}
					/>
					{calendarShareDate && (
						<div style={styles.calendarContainer}>
							<Calendar
								numberOfMonths={2}
								startDate={calendarStartDate}
								endDate={calendarEndDate}
								selectedDate={calendarShareDate}
								timeZoneId={timeZoneId}
								onDateChange={date => this.handleScheduleDateChange(calendarShareDate, date)}
							/>
						</div>
					)}
					{calendarShareTime && (
						<div style={styles.timePickerContainer}>
							<TimePicker
								style={styles.timePicker}
								value={calendarShareTime}
								onTimeChange={time => this.handleScheduleTimeChange(time)}
							/>
							{timeZoneDisplay && (
								<Fragment>
									<span style={styles.timeZone}>{timeZoneDisplay}</span>
									<span
										style={dynamicStyles.editTimeZoneLink}
										onClick={this.handleEditTimeZoneClick}
									>
										{buttonLang.edit}
									</span>
								</Fragment>
							)}
						</div>
					)}
				</Fragment>
			</Dialog>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScheduleShareDialog)
