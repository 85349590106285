import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import ReactModal from 'react-modal'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Wrappers
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Enums
import PostConstants from 'scenes/Post/enums/postConstants'

// Lang
import lang from './pageDialog.lang'

//#region Styles
import { styles as dialogStyles } from 'components/Dialog/Dialog'
import { width, padding, color, textButtonStyle } from 'styles/variables'

const styles = {
	content: {
		width: '100%',
		maxWidth: PostConstants.details.maxWidth + width.spacing.wide,
		top: 0,
		overflow: 'visible',
		display: 'flex',
		justifyContent: 'center',
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 0,
		paddingRight: 0,
		[BreakpointNames.medium]: {
			maxWidth: PostConstants.dialog.maxHeight,
		},
		[BreakpointNames.small]: {
			paddingTop: padding.wide,
			maxWidth: 'unset',
		},
	},
	close: {
		...dialogStyles.close,
		fontSize: 30,
		right: -width.spacing.wide,
		top: -width.spacing.basic,
		zIndex: 11,
		[BreakpointNames.small]: {
			right: width.spacing.narrow,
			top: 0,
		},
	},
}
//#endregion

@responsive
class PageDialog extends Component {
	static propTypes = {
		children: PropTypes.any,
		isOpen: PropTypes.bool,
		onClose: PropTypes.func,
		style: PropTypes.object,
		viewport: PropTypes.object,
	}

	onClose = e => {
		e.preventDefault()
		e.stopPropagation()

		const { onClose } = this.props

		if (onClose) {
			onClose()
		}
	}

	render() {
		const { isOpen, style: styleOverride = {}, viewport, children } = this.props
		const { closeButton = {} } = styleOverride
		const dynamicStyles = {
			dialog: {
				overlay: {
					...dialogStyles.base.overlay,
				},
				content: {
					...dialogStyles.base.content,
					...dialogStyles.base.content[viewport.size],
					...styles.content,
					...styles.content[viewport.size],
					...dialogStyles.dialog[viewport.size],
					...styleOverride,
					background: 'none',
					border: 'none',
				},
			},
			close: {
				...styles.close,
				...styles.close[viewport.size],
				...textButtonStyle,
				...closeButton,
			},
		}

		return (
			<ReactModal
				isOpen={isOpen}
				style={dynamicStyles.dialog}
				ariaHideApp={false}
				shouldCloseOnOverlayClick={true}
				onRequestClose={this.onClose}
			>
				<button style={dynamicStyles.close} onClick={this.onClose} aria-label={lang.close}>
					<Icon name={Name.timesLight} color={color.alwaysWhite} />
				</button>
				{children}
			</ReactModal>
		)
	}
}

export default PageDialog
