import { isMobile } from 'helpers/browserhelper'
import { Activity } from 'helpers/activityHelpers'
import { getRed5SdkBasePath } from 'helpers/streamingHelpers'

const oldSdkIntegrationHost = 'streaming.dsin.us'
const newSdkIntegrationHost = 'dsin.red5pro.in'
const oldSdkProductionHost = 'streaming.voicestorm.com'
const newSdkProductionHost = 'streaming7.voicestorm.com'

const integrationHostMap = new Map()
integrationHostMap.set('4.7.7.0', oldSdkIntegrationHost)
integrationHostMap.set('7.2.0.0', newSdkIntegrationHost)

const productionHostMap = new Map()
productionHostMap.set('4.7.7.0', oldSdkProductionHost)
productionHostMap.set('7.2.0.0', newSdkProductionHost)

export const getServerHost = sdkVersion => {
	if (process.env.DS_ENV_IS_PRODUCTION) {
		return productionHostMap.get(sdkVersion) || oldSdkProductionHost
	}
	if (process.env.DS_ENV_IS_INTEGRATION) {
		return integrationHostMap.get(sdkVersion) || oldSdkIntegrationHost
	}
	if (process.env.DS_ENV_IS_TEAMA) {
		return 'streaming.dsinitech.us'
	}
	// NOTE: If a developer wants to test liveStreaming locally, they need to set this to the proper server in .env
	return process.env.DS_LOCAL_STREAMINGMANAGER
}

export const LiveStreamData = liveStream => {
	const stunServers =
		liveStream.stunServers &&
		liveStream.stunServers.map(stunServer => ({ urls: `stun:${stunServer}` }))
	const turnServers =
		liveStream.turnServers &&
		liveStream.turnServers.map(turnServer => ({
			urls: `turn:${turnServer}`,
			credential: liveStream.turnServerPassword,
			username: liveStream.turnServerUser,
		}))
	// TODO: remove fallback if unnecessary
	const iceServers = [...stunServers, ...turnServers, { urls: 'stun:stun2.l.google.com:19302' }]

	return {
		rtc: {
			protocol: 'wss',
			host: getServerHost(liveStream.sdkVersion),
			port: liveStream.ports.webrtcs,
			app: 'streammanager',
			connectionParams: {
				token: liveStream.token,
				stream: liveStream.streamName,
				host: liveStream.host,
				app: liveStream.contextName,
				username: liveStream.turnServerUser,
				password: liveStream.turnServerPassword,
				activitySource: isMobile ? Activity.Source.mobileWeb : Activity.Source.desktopWeb,
			},
			subscriptionId: 'subscriber-' + Math.floor(Math.random() * 0x10000).toString(16),
			streamName: liveStream.streamName,
			iceServers,
			bandwidth: {
				audio: liveStream.audioBitrate,
				video: liveStream.videoBitrate,
			},
		},
		// TODO: Fix IE not showing video
		rtmp: {
			protocol: 'rtmps',
			host: getServerHost(liveStream.sdkVersion),
			port: liveStream.ports.rtmps,
			connectionParams: {
				token: liveStream.token,
				stream: liveStream.streamName,
				app: liveStream.contextName,
				username: liveStream.turnServerUser,
				password: liveStream.turnServerPassword,
				activitySource: isMobile ? Activity.Source.mobileWeb : Activity.Source.desktopWeb,
			},
			app: 'live',
			streamName: liveStream.streamName,
			backgroundColor: '#000000',
			autoPlay: true,
			useVideoJS: false,
			swf: getRed5SdkBasePath(liveStream.sdkVersion) + 'red5pro-subscriber.swf',
			swfobjectURL: 'https://static.dynamicsignal.com/plugins/swfobject/swfobject.js',
			productInstallURL:
				'https://static.dynamicsignal.com/plugins/swfobject/playerProductInstall.swf',
		},
	}
}
