import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import RegFlow from 'scenes/Auth/RegFlow'
import SharingOptionsBar from 'scenes/ShareDialogV8/components/SharingOptionsBar'
import EditChannelsDialog from './components/EditChannelsDialog'
import ShareSummary from './components/ShareSummary'
import ShareBody from './components/ShareBody'
import ShareButton from './components/ShareButton'
import ShareDisclosures from 'scenes/ShareDialog/components/ShareDisclosures'
import ScheduleShareDialog from './components/ScheduleShareDialog'
import ShareCompleteDialog from './components/ShareCompleteDialog'
import Button, { ButtonLevel } from 'components/Button/Button'
import Dialog from 'components/Dialog/Dialog'
import {
	Dropdown,
	DropdownTrigger,
	DropdownMenu,
	DropdownMenuAlignment,
} from 'components/Dropdown/Dropdown'
import RestrictedText, {
	Restrictions,
	RestrictText,
} from 'components/RestrictedText/RestrictedText'
import Icon, { Name, Modifier } from 'components/Icons/FontAwesome'

// Helpers
import { Activity } from 'helpers/activityHelpers'
import { safeGetNestedProp, addQueryParam, decodeQueryParams } from '@dysi/js-helpers'
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import SocialProviderData from 'helpers/socialProviderData'
import { getReactionProvider, getUserChannels } from 'scenes/ShareDialog/ShareDialog'

// Enums
import VideoTypes from 'scenes/Post/enums/videoTypes'
import ShareDialogFlows from './enums/shareDialogFlows'

// Action creators
import { asyncPostView } from 'scenes/Post/post.reducer'
import { asyncSetUserDefaultSharingChannels } from 'scenes/Auth/auth.reducer'
import { closeShareDialog, asyncGetPost } from 'scenes/Post/post.reducer'
import { clearUrgentBroadcast, asyncViewBroadcast } from 'scenes/Broadcast/broadcast.reducer'
import { asyncAddUserChannel, refreshUser } from 'helpers/userHelpers'
import {
	twitterFilter,
	facebookFilter,
	otherFilter,
	asyncSharePost,
	asyncGetPostLink,
	asyncGetEmailLink,
	ReactionType,
	ShareType,
	asyncDeleteScheduledShares,
	asyncGetFacebookLink,
	asyncGetTwitterLink,
	asyncGetXingLink,
	asyncGetLinkedInLink,
} from 'scenes/ShareDialog/apiHelpers'

// Lang
import lang from 'scenes/ShareDialog/shareDialog.lang'
//import reactionsLang from 'helpers/lang/reactions.lang'
import buttons from 'helpers/lang/buttons.lang'

// Styles
import styles from './ShareDialogV8.styles'
import { dispatchIfNotImpersonating } from 'helpers/impersonationHelpers'

//#region Redux
const mapStateToProps = state => {
	const { sharePost, shareReaction } = state.post
	const providers = safeGetNestedProp(state, 'sphere.providers')
	const user = safeGetNestedProp(state, 'auth.user')
	const shareImagesOnly = safeGetNestedProp(state, 'post.sharePost.shareImagesOnly')
	const userChannels = getUserChannels(user, shareReaction, sharePost, providers, shareImagesOnly)

	const host = safeGetNestedProp(state, 'sphere.host')
	const nativeSharingHostUrl = `https://${host}/widget/nativesharing`
	const routing = safeGetNestedProp(state, 'routing.location')
	const viewport = safeGetNestedProp(state, 'browser.viewport')

	const memberUsageComplianceRequired = safeGetNestedProp(
		state,
		'memberUsageCompliance.usageComplianceRequired'
	)
	const urgentBroadcastAllIds = state.broadcast.urgentBroadcastAllIds

	// Settings
	const showPostShareLinks = safeGetNestedProp(state, Settings.Features.ShowPostShareLinks.path)
	const enableShareByEmail = safeGetNestedProp(state, Settings.Features.EnableShareByEmail.path)
	const enableLeaderboards = safeGetSetting(state, Settings.Features.EnableLeaderboards)
	const facebookNativeConfig = safeGetSetting(state, Settings.Features.FacebookNativeConfig) || {}
	const twitterNativeConfig = safeGetSetting(state, Settings.Features.TwitterNativeConfig) || {}
	const xingNativeConfig = safeGetSetting(state, Settings.Features.XingNativeConfig) || {}
	const linkedInNativeConfig = safeGetSetting(state, Settings.Features.LinkedInNativeConfig) || {}
	const showShareButtonOnSocialPosts = safeGetNestedProp(
		state,
		Settings.Features.ShowShareButtonOnSocialPosts.path
	)
	const isScheduledSharingEnabled = safeGetNestedProp(
		state,
		Settings.Features.IsScheduledSharingEnabled.path,
		Settings.Features.IsScheduledSharingEnabled.fallback
	)
	const isSsoEnabled = safeGetNestedProp(state, Settings.Features.EnableSso.path)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)

	return {
		sharePost,
		shareReaction,
		providers,
		nativeSharingHostUrl,
		routing,
		viewport,
		user,
		memberUsageComplianceRequired,
		urgentBroadcastAllIds,
		userChannels,
		showPostShareLinks,
		enableShareByEmail,
		enableLeaderboards,
		facebookNativeConfig,
		twitterNativeConfig,
		xingNativeConfig,
		linkedInNativeConfig,
		showShareButtonOnSocialPosts,
		isScheduledSharingEnabled,
		isSsoEnabled,
		primaryColor,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		// Other Sharing Options
		getCopyLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetPostLink(postId, activity), true, true),
		getEmailLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetEmailLink(postId, activity), true, true),
		getFacebookLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetFacebookLink(postId, activity), true, true),
		getTwitterLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetTwitterLink(postId, activity), true, true),
		getXingLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetXingLink(postId, activity), true, true),
		getLinkedInLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetLinkedInLink(postId, activity), true, true),
		viewPost: postId => dispatch(asyncPostView(postId)),

		// Sharing actions
		authUserChannel: provider =>
			dispatchIfNotImpersonating(asyncAddUserChannel(provider), true, true),
		closeShareDialog: () => dispatch(closeShareDialog()),
		getPost: postId => dispatch(asyncGetPost(postId)),
		share: (postId, values, reactionType, activity) => {
			const {
				userChannelIds,
				twitterShareText,
				facebookShareText,
				otherShareText,
				shareType,
				shareDate,
			} = values
			return dispatchIfNotImpersonating(
				asyncSharePost(
					postId,
					reactionType,
					userChannelIds,
					twitterShareText,
					facebookShareText,
					otherShareText,
					shareType,
					shareDate,
					activity
				),
				true,
				true
			)
		},
		deleteScheduledShares: (userId, scheduledShareIds) =>
			dispatchIfNotImpersonating(asyncDeleteScheduledShares(userId, scheduledShareIds), true, true),

		// User related actions
		reloadUser: () => refreshUser(dispatch),
		setUserDefaultSharingChannels: (userId, userChannelIds) =>
			dispatch(asyncSetUserDefaultSharingChannels(userId, userChannelIds)),

		// Broadcasts
		clearUrgentBroadcast: broadcastId => dispatch(clearUrgentBroadcast(broadcastId)),
		viewBroadcast: broadcastId => dispatch(asyncViewBroadcast(broadcastId)),
	}
}
//#endregion

class ShareDialogV8 extends Component {
	static propTypes = {
		// Post and User properties
		sharePost: PropTypes.object.isRequired,
		shareReaction: PropTypes.oneOf(Object.values(ReactionType)),
		user: PropTypes.object,
		userChannels: PropTypes.array.isRequired,
		urgentBroadcastAllIds: PropTypes.array,
		primaryColor: PropTypes.string,

		// Routing and viewport
		routing: PropTypes.object,
		viewport: PropTypes.object,
		providers: PropTypes.object,
		nativeSharingHostUrl: PropTypes.string,

		// Boolean parameters
		showPostShareLinks: PropTypes.bool,
		enableShareByEmail: PropTypes.bool,
		enableLeaderboards: PropTypes.bool,
		memberUsageComplianceRequired: PropTypes.bool,
		showShareButtonOnSocialPosts: PropTypes.bool,
		isSsoEnabled: PropTypes.bool,
		isScheduledSharingEnabled: PropTypes.bool,

		// Native Configs
		facebookNativeConfig: PropTypes.object,
		twitterNativeConfig: PropTypes.object,
		xingNativeConfig: PropTypes.object,
		linkedInNativeConfig: PropTypes.bool,

		// Redux actions
		closeShareDialog: PropTypes.func.isRequired,
		share: PropTypes.func.isRequired,
		reloadUser: PropTypes.func.isRequired,
		setUserDefaultSharingChannels: PropTypes.func.isRequired,
		getCopyLink: PropTypes.func.isRequired,
		getEmailLink: PropTypes.func.isRequired,
		getTwitterLink: PropTypes.func.isRequired,
		getFacebookLink: PropTypes.func.isRequired,
		getLinkedInLink: PropTypes.func.isRequired,
		getXingLink: PropTypes.func.isRequired,
		getPost: PropTypes.func.isRequired,
		authUserChannel: PropTypes.func,
		shareImagesOnly: PropTypes.bool,
		clearUrgentBroadcast: PropTypes.func,
		viewBroadcast: PropTypes.func,
		viewPost: PropTypes.func,
	}

	static initialState = {
		userChannelIds: [], // connected accounts. NOT selected accounts. Mainly for passing into ShareButton, to disable/enable button.
		errorMessage: '', // To be passed into ShareBody, since that's where we're handling the errors.
		scheduleErrorMessage: '', // To be passed into Schedule Share Dialog
		[ShareDialogFlows.mainShareFlow]: false,
		[ShareDialogFlows.editChannelsFlow]: false,
		[ShareDialogFlows.scheduleSharesFlow]: false,
		[ShareDialogFlows.signInFlow]: false,
		[ShareDialogFlows.sharingDisclosuresFlow]: false,

		[ShareDialogFlows.shareCompleteFlow]: false,
		isInitialized: false,
		joinNowToShare: false,

		// For submission of share
		formikValues: null,
		shareDate: null,
		shareTime: null,
		reaction: null,
		isScheduledShare: false,
	}

	state = {
		...ShareDialogV8.initialState,
	}

	//#region Initialization and Lifecycles
	initialize() {
		const { userChannels, shareReaction, providers, sharePost: post, routing } = this.props

		// Initial values
		let isTwitterSelected = false
		const initialUserChannelIds =
			userChannels.length > 0
				? userChannels
						.filter(channel => channel.sharingDefault && !channel.authRequired)
						.filter(
							channel =>
								channel.provider !== 'Twitter' || (!isTwitterSelected && (isTwitterSelected = true))
						) // Set isTwitterSelected after 1st Twitter channel is allowed
						.map(channel => channel.userChannelId)
				: []

		// Was uid passed in the request? This indicates an email Share request
		let joinNowToShare = ShareDialogV8.initialState.joinNowToShare
		if (routing && routing.search) {
			const param = decodeQueryParams(routing.search)
			if (param && param.uid) {
				joinNowToShare = true
			}
		}

		// Get reaction
		let reaction = null
		const reactionProvider = getReactionProvider(shareReaction, post)
		if (shareReaction && providers[reactionProvider]) {
			// Is this a supported reaction?
			const reactions = Object.values(providers[reactionProvider].reactions).filter(
				reaction => reaction.reactionType.toLowerCase() === shareReaction.toLowerCase()
			)
			reaction = reactions.length > 0 ? reactions[0] : null
		}

		// Determine if we can show Share Disclosures or not.
		const allowShareDisclosures =
			post &&
			post.shareDisclosures &&
			Array.isArray(post.shareDisclosures) &&
			post.shareDisclosures.some(disclosure => disclosure.shareDisclosureText)

		this.setState({
			...ShareDialogV8.initialState,
			[ShareDialogFlows.mainShareFlow]: true,
			isInitialized: true,
			userChannelIds: initialUserChannelIds,
			reaction,
			joinNowToShare,
			allowShareDisclosures,
		})
	}

	componentDidUpdate(prevProps) {
		const { user, sharePost, shareReaction, shareImagesOnly } = this.props
		let { providers } = this.props

		if (!sharePost) return

		const { user: wasLoggedIn } = prevProps
		const { isInitialized, showCopyLinkDialog, copyLink, copyLinkError } = this.state

		// Is the ShareDialog about to open?
		if (sharePost && !isInitialized) {
			// Reset the dialog UI
			this.initialize()

			// If user has no channels to share on, show the Add Channels page
			this.showAddChannelsIfNeeded(user, shareReaction, sharePost, providers, shareImagesOnly)
		}

		// Is this a fresh login?
		if (user && !wasLoggedIn) {
			// If user can't share, reload the page (No more sharing!)
			if (!user.canSharePosts) {
				window.location.reload()
			}

			// If user has no channels to share on, show the Add Channels page
			this.showAddChannelsIfNeeded(user, shareReaction, sharePost, providers, shareImagesOnly)
		}

		// Did copy link just update?
		if (showCopyLinkDialog && copyLink !== buttons.loading && !copyLinkError) {
			this.copyInput.focus()
		}
	}

	//#endregion

	//#region State Setters/Getters
	replaceUserChannelIds = newUserChannelIds => {
		if (!newUserChannelIds || newUserChannelIds.length === 0) {
			this.setState({ userChannelIds: [] })
		} else {
			this.setState({ userChannelIds: newUserChannelIds })
		}
	}

	setSelectedChannel = selectedChannelIds => {
		const userChannelIds = Array.from(
			new Set([...this.state.userChannelIds, ...selectedChannelIds])
		)
		this.setState({ userChannelIds })
	}

	handleCloseDialog = (dialogTypeToClose, dialogTypeToOpen = null) => {
		if (dialogTypeToOpen) {
			this.setState({ [dialogTypeToClose]: false, [dialogTypeToOpen]: true })
		} else {
			this.setState({ [dialogTypeToClose]: false }, this.clearDialog)
		}
	}

	clearDialog = () => {
		const { closeShareDialog } = this.props
		closeShareDialog()

		// Reset isInitialized for next time
		this.setState({ isInitialized: false })
	}

	storeFormikValues = values => this.setState({ formikValues: values })

	setShareDateAndTime = (shareDate, shareTime) => this.setState({ shareDate, shareTime })

	openShareCompleteDialog = isScheduledShare =>
		this.setState({
			[ShareDialogFlows.mainShareFlow]: false,
			[ShareDialogFlows.scheduleSharesFlow]: false,
			[ShareDialogFlows.sharingDisclosuresFlow]: false,
			[ShareDialogFlows.shareCompleteFlow]: true,
			isScheduledShare,
		})

	shareAgain = () => {
		this.setState({
			[ShareDialogFlows.shareCompleteFlow]: false,
			[ShareDialogFlows.mainShareFlow]: true,
			shareDate: null,
			shareTime: null,
			reaction: null,
			formikValues: null,
			isScheduledShare: false,
			isInitialized: false,
		})
	}
	//#endregion

	//#region Share Dialog functions

	/** General function for determining appropriate error messages and setting theme to state. */
	getSharingError = (error, defaultMessage, isScheduledShare = false) => {
		let errorMessage = defaultMessage
		if (error && error.data && error.data.Reason) {
			switch (error.data.Reason) {
				case 'blacklisted': {
					// Restrict text if custom blacklisted error
					let blacklistMessage =
						error.messages && RestrictText(error.messages, Restrictions.ShareBlacklistError).output
					errorMessage = blacklistMessage || lang.errorBlacklisted // Fallback to default msg
					break
				}
				case 'sharing_capped': {
					errorMessage = lang.errorSharingCapped
					break
				}
				case 'already_shared': {
					errorMessage = lang.errorAlreadyShared
					break
				}
				case 'duplicate_retweet': {
					errorMessage = lang.errorRepeatedRetweet
					break
				}
				default:
					break // Default set above
			}
		}
		if (isScheduledShare) {
			this.setState({ scheduleErrorMessage: errorMessage })
		} else {
			this.setState({ errorMessage: { error: errorMessage } })
		}
	}

	getSelectedProviders = (providers, userChannelIds) => {
		const result = {
			selectedProviders: [],
			twitterProviders: [],
			facebookPageProviders: [],
			otherProviders: [],
		}

		if (providers.length > 0) {
			const selectedChannels = providers.filter(channel =>
				userChannelIds.includes(channel.userChannelId)
			) // Only include selected

			result.selectedProviders = Array.from(
				new Set(selectedChannels.map(channel => channel.provider))
			) // Only list provider names
			result.twitterProviders = result.selectedProviders.filter(twitterFilter)
			result.facebookPageProviders = result.selectedProviders.filter(facebookFilter)
			result.otherProviders = result.selectedProviders.filter(otherFilter)
		}

		return result
	}

	/** Determines if values fit requirements for share text, reaction style.  */
	areAllValuesSet = (values, reaction, userChannels) => {
		if (!reaction) return false
		const { connectedTwitterAccountIds, twitterShareText, linkedInAndOthersShareText } = values
		const { providers } = this.props
		const isTwitterEnabled = Object.values(providers).some(
			provider => provider.canonicalName === SocialProviderData.Twitter.provider
		)

		// Conditions:
		// 1.) If the reaction type is a Like
		// 2.) If the reaction type is a Retweet.
		// 3.) If we have a twitter reply w/share text.
		// 4.) If we have a comment reaction that requires text (LinkedIn/Youtube) and has share text.
		if (
			reaction.reactionType === ReactionType.like ||
			reaction.reactionType === ReactionType.share ||
			(reaction.reactionType === ReactionType.comment &&
				isTwitterEnabled &&
				twitterShareText &&
				connectedTwitterAccountIds.length) ||
			(reaction.reactionType === ReactionType.comment &&
				(reaction.maxCharacterLimit === 0 || linkedInAndOthersShareText))
		) {
			return true
		}
	}

	/** Responsible for determining whether we need to show an Add/Edit Channels dialog on load. */
	showAddChannelsIfNeeded(user, shareReaction, sharePost, providers, shareImagesOnly) {
		if (!user) return

		const userChannels = getUserChannels(user, shareReaction, sharePost, providers, shareImagesOnly)
		if (userChannels.length === 0 && providers.length > 0) {
			this.setState({ showEditChannelsDialog: true })
		}
	}

	clearUrgentBroadcast = () => {
		const { urgentBroadcastAllIds, sharePost, clearUrgentBroadcast, viewBroadcast } = this.props
		const { activity: { activityReason, activityDetail } = {} } = sharePost

		// Only clear if urgent broadcast is open
		if (
			activityReason === Activity.Reason.broadcast &&
			urgentBroadcastAllIds.includes(activityDetail)
		) {
			viewBroadcast(activityDetail)
			clearUrgentBroadcast(activityDetail)
		}
	}

	//#endregion

	//#region Rendering the Share Dialog
	renderNoteToMembers = () => {
		const { sharePost: post } = this.props
		if (!post || !post.creatorComments) return null
		const noteToMembers = post.creatorComments
		return (
			<RestrictedText limit={Restrictions.PostNoteToMembers.Full} text={noteToMembers}>
				{noteToMembers => noteToMembers}
			</RestrictedText>
		)
	}

	/** Renders other sharing options */
	renderSharingOptionsBar = () => {
		const {
			sharePost: post,
			showShareButtonOnSocialPosts,
			facebookNativeConfig,
			twitterNativeConfig,
			xingNativeConfig,
			linkedInNativeConfig,

			getEmailLink,
			getPost,
			getTwitterLink,
			getFacebookLink,
			getXingLink,
			getLinkedInLink,
			getCopyLink,

			nativeSharingHostUrl,
			viewport,
		} = this.props
		const { showEditChannelsDialog } = this.state
		if (!post) return null

		// No post to link to when ShareImagesOnly is set
		if (post.shareImagesOnly) return null

		let showPostShareLinks = this.props.showPostShareLinks
		let enableShareByEmail = this.props.enableShareByEmail
		let enableShareByCopyLink = true

		// Business Rule: Don't show Email/Copy links on social posts unless
		// ShowShareButtonOnSocialPosts is set
		if (!post.showShareButton(showShareButtonOnSocialPosts)) {
			enableShareByEmail = false
			enableShareByCopyLink = false
		}
		let isFacebookNativeSharingEnabled = facebookNativeConfig.isEnabled
		let isTwitterNativeSharingEnabled = twitterNativeConfig.isEnabled
		let isXingNativeSharingEnabled = xingNativeConfig.isEnabled
		let isLinkedInNativeSharingEnabled =
			linkedInNativeConfig.isEnabled && !!linkedInNativeConfig.shareUrlBase

		// Nothing to show if no 'other options' are available
		if (
			!showPostShareLinks &&
			!enableShareByEmail &&
			!enableShareByCopyLink &&
			!isFacebookNativeSharingEnabled &&
			!isTwitterNativeSharingEnabled &&
			!isXingNativeSharingEnabled &&
			!isLinkedInNativeSharingEnabled
		)
			return null

		const isNativeVideo =
			post.media && post.media.role === (VideoTypes.hostedVideo || VideoTypes.hostedAdvancedVideo)

		return (
			<div style={{ ...styles.otherSharingOptions, ...styles.otherSharingOptions[viewport.size] }}>
				<SharingOptionsBar
					handleClose={this.handleCloseDialog}
					nativeSharingHostUrl={nativeSharingHostUrl}
					facebookNativeConfig={facebookNativeConfig}
					twitterNativeConfig={twitterNativeConfig}
					xingNativeConfig={xingNativeConfig}
					linkedInNativeConfig={linkedInNativeConfig}
					twitterShareTextSuggestion={this.shareTextSuggestions}
					isFacebookNativeSharingEnabled={isFacebookNativeSharingEnabled}
					isTwitterNativeSharingEnabled={isTwitterNativeSharingEnabled}
					isXingNativeSharingEnabled={isXingNativeSharingEnabled}
					isLinkedInNativeSharingEnabled={isLinkedInNativeSharingEnabled}
					isNativeVideo={isNativeVideo}
					sharePostId={post.postId}
					sharePostActivity={post.activity}
					sharePostDisclosures={post.shareDisclosures}
					sharePoints={post.sharePoints}
					getEmailLink={getEmailLink}
					getCopyLink={getCopyLink}
					getPost={getPost}
					getTwitterLink={getTwitterLink}
					getFacebookLink={getFacebookLink}
					getXingLink={getXingLink}
					getLinkedInLink={getLinkedInLink}
					getSharingError={this.getSharingError}
					showEditChannelsDialog={showEditChannelsDialog}
					showPostShareLinks={showPostShareLinks}
					enableShareByEmail={enableShareByEmail}
					isV8Enabled={true}
				/>
			</div>
		)
	}

	/** Renders the Share Button for regular Share flow. */
	renderShareButton = () => {
		const {
			viewport,
			primaryColor,
			getPost,
			setUserDefaultSharingChannels,
			user,
			sharePost,
			share,
			providers,
			isScheduledSharingEnabled,
			reloadUser,
		} = this.props
		const { formikValues, shareDate, shareTime, reaction, userChannelIds } = this.state

		const isTwitterEnabled =
			providers &&
			Object.values(providers).some(
				provider => provider.canonicalName === SocialProviderData.Twitter.provider
			)
		const values = {
			...formikValues,
			shareDate,
			shareTime,
		}

		return (
			<ShareButton
				setUserDefaultSharingChannels={setUserDefaultSharingChannels}
				clearUrgentBroadcast={this.clearUrgentBroadcast}
				getPost={getPost}
				userChannelIds={userChannelIds}
				setShareDateAndTime={this.setShareDateAndTime}
				primaryColor={primaryColor}
				postId={sharePost.postId}
				userId={user ? user.userId : null}
				userTimezone={user ? user.timeZone : null}
				reloadUser={reloadUser}
				share={share}
				values={values}
				showShareChannels={true}
				isTwitterEnabled={isTwitterEnabled}
				openShareCompleteDialog={this.openShareCompleteDialog}
				setErrorMessage={this.getSharingError}
				render={(onSubmit, isDisabled) => {
					const label = lang.submitButton
					// Option 1: A react button
					if (reaction || !isScheduledSharingEnabled) {
						const areAllValuesSet = this.areAllValuesSet(values, reaction)
						return (
							<Button
								onClick={() => onSubmit(ShareType.shareNow)}
								disabled={isDisabled || !areAllValuesSet}
							>
								{label}
							</Button>
						)
					} else {
						// Option 2: A share button with options to Schedule or Auto Q
						const buttonStyle = {
							...styles.submitButton,
							...styles.submitButton[viewport.size],
						}
						return (
							<div style={buttonStyle}>
								<Button
									style={styles.submitButton.button}
									onClick={() => onSubmit(ShareType.shareNow)}
									level={ButtonLevel.secondary}
									disabled={isDisabled}
								>
									<Icon
										style={styles.submitButton.icon}
										modifier={Modifier.fixedWidth}
										color={primaryColor}
										name={Name.share}
									/>
									{label}
								</Button>
								<Dropdown>
									<DropdownTrigger>
										<Button
											style={styles.submitButton.trigger}
											icon={Name.caretDown}
											level={ButtonLevel.secondary}
											disabled={isDisabled}
										/>
									</DropdownTrigger>
									<DropdownMenu align={DropdownMenuAlignment.right}>
										<ul style={styles.submitButtonDropdownList}>
											{/* Auto Queue */}
											<li style={styles.submitButtonDropdownList.item}>
												<span
													style={styles.submitButtonDropdownList.link}
													onClick={() => onSubmit(ShareType.autoQueue)}
												>
													{lang.autoScheduleButton}
												</span>
											</li>
											{/* Schedule Share */}
											<li style={styles.submitButtonDropdownList.item}>
												<span
													style={styles.submitButtonDropdownList.link}
													onClick={() =>
														this.handleCloseDialog(
															ShareDialogFlows.mainShareFlow,
															ShareDialogFlows.scheduleSharesFlow
														)
													}
												>
													{lang.scheduleButton}
												</span>
											</li>
										</ul>
									</DropdownMenu>
								</Dropdown>
							</div>
						)
					}
				}}
			/>
		)
	}

	/** Renders Other Sharing Options and the Share Button */
	renderShareDialogV8Footer = () => {
		const { viewport } = this.props
		const { showEditChannelsDialog } = this.state
		const footerStyle = {
			...styles.footer,
			...styles.footer[viewport.size],
		}
		const footerDividerStyle = {
			...styles.footerDivider,
			...styles.footerDivider[viewport.size],
		}
		return (
			<Fragment>
				<div style={footerDividerStyle} />
				<div style={footerStyle}>
					{this.renderSharingOptionsBar()}
					{!showEditChannelsDialog && this.renderShareButton()}
				</div>
			</Fragment>
		)
	}

	/** Renders the Schedule Post dialog */
	renderScheduleShareDialogFlow = () => {
		const {
			setUserDefaultSharingChannels,
			getPost,
			user,
			reloadUser,
			sharePost,
			providers,
			primaryColor,
			viewport,
			share,
		} = this.props
		const {
			formikValues,
			userChannelIds,
			showScheduleShareDialog,
			scheduleErrorMessage,
		} = this.state

		const showShareChannels = this.showShareChannels(sharePost, providers)
		const isTwitterEnabled =
			providers &&
			Object.values(providers).some(
				provider => provider.canonicalName === SocialProviderData.Twitter.provider
			)

		return (
			<ScheduleShareDialog
				title={lang.scheduleTitle}
				isOpen={showScheduleShareDialog}
				isTwitterEnabled={isTwitterEnabled}
				formikValues={formikValues}
				storeFormikValues={this.storeFormikValues}
				setUserDefaultSharingChannels={setUserDefaultSharingChannels}
				clearUrgentBroadcast={this.clearUrgentBroadcast}
				handleClose={this.handleCloseDialog}
				getPost={getPost}
				userChannelIds={userChannelIds}
				user={user}
				primaryColor={primaryColor}
				reloadUser={reloadUser}
				share={share}
				sharePost={sharePost}
				viewport={viewport}
				setShareDateAndTime={this.setShareDateAndTime}
				openShareCompleteDialog={this.openShareCompleteDialog}
				showShareChannels={showShareChannels}
				setErrorMessage={this.getSharingError}
				errorMessage={scheduleErrorMessage}
			/>
		)
	}

	/** Renders the share dialog with the reg flow. */
	renderShareDialogRegFlow = () => {
		const {
			sharePost: post,
			user,
			primaryColor,
			isSsoEnabled,
			shareReaction,
			viewPost,
			viewport,
		} = this.props
		const { joinNowToShare } = this.state
		let ssoReturnUrl = null
		if (!user && isSsoEnabled && post) {
			ssoReturnUrl = window.location.href
			ssoReturnUrl = addQueryParam(ssoReturnUrl, 'sharePost', post.postId)
			if (shareReaction) {
				ssoReturnUrl = addQueryParam(ssoReturnUrl, 'shareReaction', shareReaction)
			}
		}
		return (
			<Dialog
				subtitle={this.renderNoteToMembers()}
				style={styles.signIn}
				isOpen={true}
				onClose={this.handleCloseDialog}
				shouldCloseOnOverlayClick={false}
				shareV8OtherActions={this.renderShareDialogV8Footer()}
				footerStyle={{ flexDirection: 'column' }}
			>
				<ShareSummary
					isSocialPost={post.isSocialPost}
					image={post.image}
					urlSlug={post.urlSlug}
					postId={post.postId}
					displayMode={post.displayMode}
					media={post.media}
					title={post.title}
					viewPost={viewPost}
					permaLink={post.cleanPermaLink}
					primaryColor={primaryColor}
					viewport={viewport}
				/>
				<RegFlow
					style={styles.regFlow}
					fromShareDialog={true}
					joinNowToShare={joinNowToShare}
					ssoReturnUrl={ssoReturnUrl}
					onSignUpClick={this.handleSignUpClick}
					onSignInClick={this.handleSignInClick}
				/>
			</Dialog>
		)
	}

	renderShareDialogFlow = () => {
		const {
			sharePost: post,
			viewport,
			primaryColor,
			userChannels,
			providers: availableProviders,
			authUserChannel,
			reloadUser,
			viewPost,
		} = this.props
		const {
			allowShareDisclosures,
			reaction,
			shareDate,
			userChannelIds,
			formikValues,
			errorMessage,
		} = this.state

		const showShareChannels = this.showShareChannels(post, availableProviders)
		const isTwitterEnabled =
			availableProviders &&
			Object.values(availableProviders).some(
				provider => provider.canonicalName === SocialProviderData.Twitter.provider
			)
		const isLinkedInEnabled =
			availableProviders &&
			Object.values(availableProviders).some(
				provider => provider.canonicalName === SocialProviderData.LinkedIn.provider
			)
		const isFacebookPageEnabled =
			availableProviders &&
			Object.values(availableProviders).some(
				provider => provider.canonicalName === SocialProviderData.FacebookPage.provider
			)
		const isNativeVideo =
			post.media && post.media.role === (VideoTypes.hostedVideo || VideoTypes.hostedAdvancedVideo)

		return (
			<Dialog
				subtitle={this.renderNoteToMembers()}
				isOpen={true}
				onClose={this.handleCloseDialog}
				shouldCloseOnOverlayClick={false}
				shareV8OtherActions={this.renderShareDialogV8Footer()}
				footerStyle={{ flexDirection: 'column' }}
			>
				<div style={styles.container}>
					{/* TOP: Note to Members (seen in subtitle), Share Summary */}
					<ShareSummary
						postType={post.postType}
						primaryColor={primaryColor}
						viewport={viewport}
						viewPost={viewPost}
						isSocialPost={post.isSocialPost}
						urlSlug={post.urlSlug}
						postId={post.postId}
						displayMode={post.displayMode}
						image={post.image}
						media={post.media}
						title={post.title}
						permaLink={post.shareImagesOnly ? null : post.cleanPermaLink}
					/>
					{/* MIDDLE: Share Text, Share Disclosures, Text Suggestions, RegFlow */}
					<ShareBody
						post={post}
						isTwitterEnabled={isTwitterEnabled}
						isLinkedInEnabled={isLinkedInEnabled}
						isFacebookPageEnabled={isFacebookPageEnabled}
						isNativeVideo={isNativeVideo}
						authUserChannel={authUserChannel}
						reloadUser={reloadUser}
						primaryColor={primaryColor}
						viewport={viewport}
						userChannelIds={userChannelIds}
						userChannels={userChannels}
						showShareChannels={showShareChannels}
						sharePost={this.submitShare}
						reactionType={reaction}
						shareDate={shareDate}
						errorState={errorMessage}
						storeFormikValues={this.storeFormikValues}
						formikValues={formikValues}
						handleClose={this.handleCloseDialog}
						submitShare={this.submitShare}
						setSelectedChannel={this.setSelectedChannel}
						replaceUserChannelIds={this.replaceUserChannelIds}
						allowShareDisclosures={allowShareDisclosures}
					/>
					{/* BOTTOM: Other Sharing Options, Share Post Button  - see shareV8OtherActions */}
				</div>
			</Dialog>
		)
	}

	/** Renders the Edit/Add Accounts dialog */
	renderEditChannelsFlow = () => {
		const { sharePost, userChannels } = this.props
		const { reaction, userChannelIds } = this.state
		const addChannelsSubtitle =
			lang.addChannelInstructions +
			(userChannels.length === 0 ? ' ' + lang.addChannelZeroChannels : '')
		return (
			<EditChannelsDialog
				subtitle={addChannelsSubtitle}
				handleClose={this.handleCloseDialog}
				footer={this.renderShareDialogV8Footer}
				sharePost={sharePost}
				reaction={reaction}
				selectedChannelList={userChannelIds}
				userChannels={userChannels}
				replaceUserChannelIds={this.replaceUserChannelIds}
			/>
		)
	}

	/** Renders the sharing disclosures dialog */
	renderSharingDisclosures = () => {
		const { sharePost, userChannels } = this.props
		const { userChannelIds } = this.state

		const providers = this.getSelectedProviders(userChannels, userChannelIds)
		const closeSharingDisclosures = () =>
			this.handleCloseDialog(
				ShareDialogFlows.sharingDisclosuresFlow,
				ShareDialogFlows.mainShareFlow
			)
		if (!sharePost.shareDisclosures || !providers || !providers.selectedProviders) return null

		return (
			<Dialog
				style={styles.sharingDisclosuresDialog}
				isOpen={true}
				onClose={this.closeSharingDisclosures}
				title={lang.sharingDisclosureTitle}
				subtitle={lang.sharingDisclosureInfo}
				submitButtons={
					<div>
						<Button onClick={closeSharingDisclosures}>{buttons.ok}</Button>
					</div>
				}
				shouldCloseOnOverlayClick={false}
			>
				<ShareDisclosures
					disclosures={sharePost.shareDisclosures}
					selectedProviders={providers.selectedProviders}
				/>
			</Dialog>
		)
	}

	/** Renders the "Successful Share" dialog after a share has been completed. */
	renderShareCompleteFlow = () => {
		const { enableLeaderboards, primaryColor, sharePost } = this.props
		const {
			showShareCompleteDialog,
			reaction,
			shareDate,
			isScheduledShare,
			userChannelIds,
		} = this.state
		const closeShareCompleteDialog = () =>
			this.handleCloseDialog(ShareDialogFlows.shareCompleteFlow)
		const shareSuccessPoints =
			!reaction && userChannelIds.length > 0 && !!sharePost.sharePoints
				? lang.shareCompletePoints(sharePost.sharePoints * userChannelIds.length)
				: null
		const reactionProvider = getReactionProvider(reaction, sharePost)

		return (
			<ShareCompleteDialog
				isOpen={showShareCompleteDialog}
				onClose={closeShareCompleteDialog}
				shareSuccessPoints={shareSuccessPoints}
				shareDate={shareDate}
				shareAgain={this.shareAgain}
				reaction={reaction}
				reactionProvider={reactionProvider}
				primaryColor={primaryColor}
				handleClose={this.handleCloseDialog}
				enableLeaderboards={enableLeaderboards}
				isScheduledShare={isScheduledShare}
			/>
		)
	}
	//#endregion

	render() {
		const { sharePost, user, memberUsageComplianceRequired } = this.props
		const {
			isInitialized,
			showEditChannelsDialog,
			showSharingDisclosures,
			showSharePostDialog,
			showScheduleShareDialog,
			showShareCompleteDialog,
			allowShareDisclosures,
		} = this.state
		if (!sharePost || !isInitialized) return null
		if (memberUsageComplianceRequired) return null

		//The share dialog consists of different 'flows'. Each state correlates to which flow
		// we're currently on.
		if (!user) {
			return this.renderShareDialogRegFlow()
		} else if (showSharePostDialog) {
			return this.renderShareDialogFlow()
		} else if (showEditChannelsDialog) {
			return this.renderEditChannelsFlow()
		} else if (showSharingDisclosures && allowShareDisclosures) {
			return this.renderSharingDisclosures()
		} else if (showScheduleShareDialog) {
			return this.renderScheduleShareDialogFlow()
		} else if (showShareCompleteDialog) {
			return this.renderShareCompleteFlow()
		} else {
			return null
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShareDialogV8)
