import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { isDesktopApp } from 'helpers/browserhelper'

// const scriptRegex = /(<script[\s\S]*?>)([\s\S]*?)(<\/script>)/gi

class HtmlText extends React.Component {
	static propTypes = {
		// allowScripts: PropTypes.bool,
		children: PropTypes.string.isRequired,
		config: PropTypes.object,
	}

	componentDidMount() {
		if (isDesktopApp) {
			// The DesktopApp needs to have all links open in the external browser
			DOMPurify.addHook('beforeSanitizeAttributes', node => {
				const isATag = node.nodeName && node.nodeName === 'A'
				if (isATag && node.attributes['href']) {
					node.setAttribute('target', '_blank')
				}
			})
		}
	}

	render() {
		const { children, config } = this.props
		return (
			<div
				dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children, config) }}
				ref={elem => (this.element = elem)}
			/>
		)
	}
}

export default HtmlText
