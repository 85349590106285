import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import {
	color,
	lineHeight,
	border,
	spacing,
	fontSize,
	padding,
	width,
	flex,
	flexContainer,
} from 'styles/variables'
import { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Enums
import PostConstants from './enums/postConstants'

const styles = {
	base: {
		position: 'relative',
		background: color.bgContent,
		border: border.basic,
		width: '100%',
		maxWidth: width.layout.mainNoSidebar,
		minWidth: 162,
		height: '100%',
		[BreakpointNames.small]: {
			maxWidth: 350,
		},
	},
	details: {
		display: 'flex',
		paddingLeft: spacing.basic,
		paddingRight: spacing.basic,
		marginBottom: spacing.narrow,
		flexDirection: 'row',
		[BreakpointNames.small]: {
			flexDirection: 'column',
		},
	},
	imageContainer: {
		cursor: 'pointer',
		...flex(1, 1, 'auto'),
		minWidth: 0,
		maxWidth: PostConstants.feed.maxWidth,
		marginRight: spacing.basic,
		position: 'relative',
		height: '100%',
		[BreakpointNames.small]: {
			marginRight: 0,
		},
		liveStream: {
			...flexContainer(),
			background: color.secondaryGrey,
		},
		noImage: {
			...flexContainer(null, 'center', null),
			background: color.alwaysBlack,
		},
	},
	image: {
		maxWidth: PostConstants.feed.maxWidth,
		height: 'auto',
		width: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	text: {
		...flex(2, 1, 0),
		lineHeight: lineHeight.base,
		wordWrap: 'break-word',
		minWidth: 0,
		[BreakpointNames.small]: {
			marginRight: 0,
			...flex(2, 1, 'auto'),
		},
		[BreakpointNames.medium]: {
			...flex(1, 1, 0),
		},
	},
	publishDate: {
		color: color.primaryGrey,
		fontSize: fontSize.small,
	},
	pointer: {
		cursor: 'pointer',
	},
	postActions: {
		borderTop: 'none',
		marginTop: 0,
		marginBottom: 0,
		marginLeft: 0,
		marginRight: 0,
		borderRight: border.basic,
		borderLeft: border.basic,
		borderBottom: border.basic,
		paddingBottom: padding.narrow,
		width: '100%',
		[BreakpointNames.small]: {
			maxWidth: 350,
		},
	},
	blankImage: {
		background: color.alwaysBlack,
	},
}

export default prefixer.prefix(styles)
