const lang = {
	title: {
		InstagramLike: 'Like on Instagram',
		LinkedInLike: 'Like on LinkedIn',
		LinkedInComment: 'Comment on LinkedIn',
		TwitterLike: 'Like on Twitter',
		TwitterComment: 'Reply on Twitter',
		TwitterShare: 'Retweet on Twitter',
		YouTubeLike: 'Like on YouTube',
		YouTubeComment: 'Comment on YouTube',
	},
	success: {
		InstagramLike: 'You have liked this post on Instagram.',
		LinkedInLike: 'You have liked this post on LinkedIn.',
		LinkedInComment: 'Your comment has been submitted on LinkedIn.',
		TwitterLike: 'You have liked this post on Twitter.',
		TwitterComment: 'Your reply has been submitted on Twitter.',
		TwitterShare: 'Your retweet has been submitted on Twitter.',
		YouTubeLike: 'You have liked this post on YouTube.',
		YouTubeComment: 'Your comment has been submitted on YouTube.',
	},
}

export default lang;