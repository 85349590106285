//import { push } from 'react-router-redux'

import { vsApi, requests } from 'helpers/apiHelpers'
import { AlertType } from 'components/Alert/Alert'
import { storageKeys, clearAuthTokens, saveAuthTokens } from 'helpers/persistor'
import UserData from './data/user.data'
import { isDesktopApp } from 'helpers/browserhelper'
import IpcEvent from 'helpers/desktopApp/ipcEvent'
import { isValidTheme, getThemeFromStorage, themeStorageKey } from 'styles/themes/themeHelpers'
import {
	isLocalStorageAvailable,
	safeGetNestedProp,
	stringIsEmptyOrWhitespace,
} from '@dysi/js-helpers'
import { notificationFlyoutKey, postRowFeedKey } from 'pages/Beta/Beta'
// import { FeedLayoutOptions } from 'reducers/sphere.data'
import UserPreferenceKey from './enums/UserPreferenceKey'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by re-rendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below
const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS'
const IMPERSONATE_USER = 'IMPERSONATE_USER'
const SHORT_TOKEN_REQUEST = 'SHORT_TOKEN_REQUEST'
const SHORT_TOKEN_SUCCESS = 'SHORT_TOKEN_SUCCESS'
const SHORT_TOKEN_FAILURE = 'SHORT_TOKEN_FAILURE'
const LONG_TOKEN_REQUEST = 'LONG_TOKEN_REQUEST'
const LONG_TOKEN_SUCCESS = 'LONG_TOKEN_SUCCESS'
const LONG_TOKEN_FAILURE = 'LONG_TOKEN_FAILURE'
const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST'
const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE'
const REG_FLOW_SUCCESS = 'REG_FLOW_SUCCESS'
const REG_FLOW_FAILURE = 'REG_FLOW_FAILURE'

const SET_USER = 'SET_USER'
const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'

const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS'

const SET_SCHEDULE_SETTINGS_REQUEST = 'SET_SCHEDULE_SETTINGS_REQUEST'
const SET_SCHEDULE_SETTINGS_SUCCESS = 'SET_SCHEDULE_SETTINGS_SUCCESS'
const SET_SCHEDULE_SETTINGS_FAILURE = 'SET_SCHEDULE_SETTINGS_FAILURE'

const FETCH_CUSTOMDATA_SUCCESS = 'FETCH_CUSTOMDATA_SUCCESS'
const SET_CUSTOMDATA_SUCCESS = 'SET_CUSTOMDATA_SUCCESS'

const FETCH_USERPREFERENCES_SUCCESS = 'FETCH_FEEDSTYLE_SUCCESS'
const SET_USERPREFERENCE_SUCCESS = 'SET_USERPREFERENCE_SUCCESS'

// This is here because it's based on a UserPreference!
const SET_FEEDLAYOUT_SUCCESS = 'SET_FEEDLAYOUT_SUCCESS'

const SET_SSO_SIGN_OUT_IN_PROGRESS = 'SET_SSO_SIGN_OUT_IN_PROGRESS'

const FETCH_CHANNEL_VERIFY_AUTHENTICATION_SUCCESS = 'FETCH_CHANNEL_VERIFY_AUTHENTICATION_SUCCESS'
const SET_HAS_CHANNELS_VERIFY_AUTHENTICATION_BEEN_CHECKED =
	'SET_HAS_CHANNELS_VERIFY_AUTHENTICATION_BEEN_CHECKED'

// Action creators

// In Redux, code with side effects should be part of the action creation process.
// Reducers are pure functions that cannot have side effects
export const clearCurrentUser = () => {
	// Remove persisted tokens
	clearAuthTokens()

	// If we are running in the DesktopApp when logging out
	// let Electron know so it can clear its session storage
	if (isDesktopApp) {
		window.ipcRenderer.send(IpcEvent.update.session, {
			clearStorage: true,
		})
	}

	// Add/Remove logout key so other apps who might be listening
	// will also Sign Out a logged in user (always in localStorage)
	localStorage[storageKeys.legacy.logout] = ''
	delete localStorage[storageKeys.legacy.logout]

	// Return Redux action
	return {
		type: CLEAR_CURRENT_USER,
	}
}

export const setUser = user => {
	return {
		type: SET_USER,
		user,
	}
}

export const persistAuthTokens = (token, expiration) => {
	saveAuthTokens({
		token,
		expiration,
	})
	return {
		type: SET_AUTH_TOKENS,
		token,
		tokenExpiration: expiration,
	}
}

export const setAuthTokens = ({
	token,
	tokenExpiration,
	shortToken,
	shortTokenExpiration,
	refreshToken,
	deviceId,
}) => {
	return {
		type: SET_AUTH_TOKENS,
		token,
		tokenExpiration,
		shortToken,
		shortTokenExpiration,
		refreshToken,
		deviceId,
	}
}

export const impersonateUser = ({ shortToken, shortTokenExpiration }) => {
	return {
		type: IMPERSONATE_USER,
		shortToken,
		shortTokenExpiration,
	}
}

export const regFlowSuccess = result => {
	// Persist new tokens
	saveAuthTokens(result)

	return {
		type: REG_FLOW_SUCCESS,
		token: result.token,
		tokenExpiration: result.expiration,
	}
}

export const regFlowFailure = () => {
	// Remove persisted tokens
	clearAuthTokens()

	return {
		type: REG_FLOW_FAILURE,
	}
}

export const ssoSignOutInProgress = () => {
	return {
		type: SET_SSO_SIGN_OUT_IN_PROGRESS,
	}
}

export const setHasChannelsVerifyAuthenticationBeenChecked = () => {
	return {
		type: SET_HAS_CHANNELS_VERIFY_AUTHENTICATION_BEEN_CHECKED,
	}
}

// Async Action Creators

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

export const asyncGetCurrentUser = allowAnonymous => {
	return vsApi({
		endpoint: requests.getCurrentUser.endpoint,
		actions: {
			request: FETCH_USER_REQUEST,
			success: FETCH_USER_SUCCESS,
			failure: FETCH_USER_FAILURE,
		},
		options: {
			...(allowAnonymous && {
				ignoreAuthFailure: true,
				captureUnhandledPromiseRejection: false,
			}),
		},
	})
}

export const asyncRemoveUserChannel = (userId, userChannelId) => {
	return vsApi({
		endpoint: requests.removeUserChannel.getEndpoint(userId, userChannelId),
		requestOptions: requests.removeUserChannel.requestOptions,
	})
}

export const asyncSetUserDefaultSharingChannels = (userId, userChannelIds) => {
	return vsApi({
		endpoint: requests.setUserDefaultSharingChannels.getEndpoint(userId),
		requestOptions: requests.setUserDefaultSharingChannels.getRequestOptions(userChannelIds),
	})
}

export const asyncGetShortToken = () => {
	return vsApi({
		endpoint: requests.getShortToken.endpoint,
		actions: {
			request: SHORT_TOKEN_REQUEST,
			success: SHORT_TOKEN_SUCCESS,
			failure: SHORT_TOKEN_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetLongToken = () => {
	return vsApi({
		endpoint: requests.getLongToken.endpoint,
		actions: {
			request: LONG_TOKEN_REQUEST,
			success: LONG_TOKEN_SUCCESS,
			failure: LONG_TOKEN_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncRefreshTokens = (refreshToken, deviceId) => {
	return vsApi({
		endpoint: requests.getTokensFromRefreshToken.endpoint,
		requestOptions: requests.getTokensFromRefreshToken.getRequestOptions(refreshToken, deviceId),
		actions: {
			request: REFRESH_TOKEN_REQUEST,
			success: REFRESH_TOKEN_SUCCESS,
			failure: REFRESH_TOKEN_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncChangePassword = (currentPassword, newPassword, abortable) => {
	return vsApi({
		endpoint: requests.changePassword.endpoint,
		actions: {
			success: CHANGE_USER_PASSWORD_SUCCESS,
		},
		requestOptions: requests.changePassword.getRequestOptions(currentPassword, newPassword),
		options: { abortable },
	})
}

export const asyncResetPassword = (token, newPassword, abortable) => {
	return vsApi({
		endpoint: requests.resetPassword.endpoint,
		requestOptions: requests.resetPassword.getRequestOptions(token, newPassword),
		options: { abortable },
	})
}

export const asyncValidatePassword = password => {
	return vsApi({
		endpoint: requests.validatePassword.endpoint,
		requestOptions: requests.validatePassword.getRequestOptions(password),
	})
}

export const asyncSaveScheduleSettings = (days, times, abortable) => {
	return vsApi({
		endpoint: requests.getCurrentUser.endpoint,
		actions: {
			request: SET_SCHEDULE_SETTINGS_REQUEST,
			success: SET_SCHEDULE_SETTINGS_SUCCESS,
			failure: SET_SCHEDULE_SETTINGS_FAILURE,
		},
		requestOptions: requests.saveSettings.getRequestOptions(days, times),
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetUserCustomData = (userId, customDataIndex) => {
	return vsApi({
		endpoint: requests.getUserCustomData.getEndpoint(userId, customDataIndex),
		actions: {
			success: FETCH_CUSTOMDATA_SUCCESS,
		},
	})
}

export const asyncSetUserCustomData = (userId, customDataIndex, data) => {
	return vsApi({
		endpoint: requests.setUserCustomData.getEndpoint(userId, customDataIndex, data),
		requestOptions: requests.setUserCustomData.getRequestOptions(userId, customDataIndex, data),
		actions: {
			success: SET_CUSTOMDATA_SUCCESS,
		},
	})
}

export const asyncGetUserPreferences = keys => {
	return vsApi({
		endpoint: requests.getUserPreferences.getEndpoint(keys),
		actions: {
			success: FETCH_USERPREFERENCES_SUCCESS,
		},
		options: {
			ignoreAuthFailure: true,
			captureUnhandledPromiseRejection: false,
		},
	})
}

export const asyncSetUserPreference = (key, data) => {
	return vsApi({
		endpoint: requests.setUserPreference.getEndpoint(),
		requestOptions: requests.setUserPreference.getRequestOptions(key, data),
		actions: {
			success: SET_USERPREFERENCE_SUCCESS,
		},
	})
}

export const setFeedLayout = layout => {
	return {
		type: SET_FEEDLAYOUT_SUCCESS,
		layout,
	}
}

export const asyncGetChannelVerifyAuthentication = userChannelId => {
	return vsApi({
		endpoint: requests.getChannelVerifyAuthentication.getEndpoint(userChannelId),
		actions: {
			success: FETCH_CHANNEL_VERIFY_AUTHENTICATION_SUCCESS,
		},
	})
}

// --- Initial State ---

// State will be mapped to the Redux store by combineReducers
const initialState = {
	isPending: false,
	isImpersonating: false,
	token: null,
	tokenExpiration: null,
	isTokenPending: false,
	isShortTokenPending: false,
	shortToken: null,
	shortTokenExpiration: null,
	refreshToken: null,
	deviceId: null,
	isRefreshTokenPending: false,
	user: null,
	signInError: null,
	showAlert: false, // For things like user settings
	saveStatus: AlertType.success,
	isSsoSignOutInProgress: false, // TA-21344, DS-1453: Set flag so ProtectedRoute knows not to send us to login page when signing out
	errors: [],
	hasChannelsVerifyAuthenticationBeenChecked: false,
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		// Redux will initialize this reducer with a stored state--a.k.a. preloadedState
		// or persistedState--with the @@INIT action. Since this reducer only stores a
		// portion of it's state, we initialize it here by applying it to the full initial state.
		case '@@INIT':
			return {
				...initialState, // Full, initial state
				...state, // Stored state pre-loaded during createStore
			}
		case SET_AUTH_TOKENS: {
			const {
				token,
				tokenExpiration,
				shortToken,
				shortTokenExpiration,
				refreshToken,
				deviceId,
			} = action
			return {
				...state,
				isImpersonating: false,
				token,
				tokenExpiration,
				shortToken,
				shortTokenExpiration,
				refreshToken,
				deviceId,
			}
		}
		case IMPERSONATE_USER: {
			const { shortToken, shortTokenExpiration } = action
			return {
				...state,
				isImpersonating: true,
				user: null, // Need to refetch
				token: null,
				tokenExpiration: null,
				shortToken,
				shortTokenExpiration,
			}
		}
		case SHORT_TOKEN_REQUEST: {
			return {
				...state,
				isShortTokenPending: true,
			}
		}
		case SHORT_TOKEN_SUCCESS: {
			const { token: shortToken, expiration: shortTokenExpiration } = action.response
			return {
				...state,
				shortToken,
				shortTokenExpiration,
				isShortTokenPending: false,
			}
		}
		case SHORT_TOKEN_FAILURE: {
			return {
				...state,
				shortToken: null,
				shortTokenExpiration: null,
				isShortTokenPending: false,
			}
		}
		case LONG_TOKEN_REQUEST: {
			return {
				...state,
				isTokenPending: true,
			}
		}
		case LONG_TOKEN_SUCCESS: {
			const { token, expiration: tokenExpiration } = action.response
			return {
				...state,
				token,
				tokenExpiration,
				isTokenPending: false,
			}
		}
		case LONG_TOKEN_FAILURE: {
			return {
				...state,
				isTokenPending: false,
			}
		}
		case REFRESH_TOKEN_REQUEST: {
			return {
				...state,
				isRefreshTokenPending: true,
			}
		}
		case REFRESH_TOKEN_SUCCESS: {
			const {
				token,
				expiration,
				shortLivedToken: shortToken,
				shortLivedTokenExpiration: shortTokenExpiration,
			} = action.response
			return {
				...state,
				token,
				expiration,
				shortToken,
				shortTokenExpiration,
				isRefreshTokenPending: false,
			}
		}
		case REFRESH_TOKEN_FAILURE: {
			return {
				...state,
				// If we can't get a new token from the refreshToken,
				// no sweat. User will just have to sign in again
				isRefreshTokenPending: false,
			}
		}
		case REG_FLOW_SUCCESS: {
			const { token, tokenExpiration } = action
			return {
				...state,
				isImpersonating: false,
				token,
				tokenExpiration,
				signInError: null, // Clear error
			}
		}
		case REG_FLOW_FAILURE: {
			const { error } = action
			return {
				...state,
				isImpersonating: false,
				token: null,
				tokenExpiration: null,
				shortToken: null,
				shortTokenExpiration: null,
				user: null,
				signInError: error,
			}
		}
		case CHANGE_USER_PASSWORD_SUCCESS: {
			const { token, expiration, shortLivedToken, shortLivedTokenExpiration } = action.response
			const user = UserData(action.response.user) // Save optimized version of user in the store

			return {
				...state,
				token,
				tokenExpiration: expiration,
				shortToken: shortLivedToken,
				shortTokenExpiration: shortLivedTokenExpiration,
				user,
			}
		}
		case FETCH_USER_REQUEST:
			return {
				...state,
				isPending: true,
			}
		case FETCH_USER_SUCCESS: {
			const user = UserData(action.response) // Save optimized version of user in the store
			return {
				...state,
				isPending: false,
				user,
			}
		}
		case FETCH_USER_FAILURE: {
			const errors = [...state.errors, ...[action.error]]
			return {
				...state,
				isPending: false,
				isImpersonating: false,
				token: null,
				tokenExpiration: null,
				shortToken: null,
				shortTokenExpiration: null,
				user: null,
				errors,
			}
		}
		case CLEAR_CURRENT_USER:
			return {
				...state,
				isImpersonating: false,
				token: null,
				tokenExpiration: null,
				shortToken: null,
				shortTokenExpiration: null,
				user: null,
				signInError: null,
			}
		case SET_USER: {
			const user = UserData(action.user) // Save optimized version of user in the store
			return {
				...state,
				user,
			}
		}
		case SET_SCHEDULE_SETTINGS_REQUEST: {
			return {
				...state,
				saveStatus: AlertType.success,
				saveMessage: '',
				showAlert: false,
			}
		}
		case SET_SCHEDULE_SETTINGS_SUCCESS: {
			const user = UserData(action.response) // Save optimized version of user in the store
			return {
				...state,
				user,
				saveStatus: AlertType.success,
				saveMessage: '',
				showAlert: false, // No message on success
			}
		}
		case SET_SCHEDULE_SETTINGS_FAILURE: {
			const errorMessage =
				action.error && action.error.messages && action.error.messages.length > 0
					? action.error.messages.join('. ')
					: ''
			return {
				...state,
				saveStatus: AlertType.error,
				saveMessage: errorMessage,
				showAlert: true,
			}
		}
		case FETCH_CUSTOMDATA_SUCCESS: {
			// Get experimental features from Custom Data
			try {
				const { customDataIndex, data } = action.response
				const parsedData = JSON.parse(data)
				const { theme, enableNotificationFlyout, enablePostRowFeed } = parsedData
				if (isLocalStorageAvailable()) {
					let reload = false
					const localEnableNotificationFlyout = localStorage.getItem(notificationFlyoutKey)
					const localEnablePostRowFeed = localStorage.getItem(postRowFeedKey)
					if (isValidTheme(theme) && getThemeFromStorage().name !== theme) {
						localStorage.setItem(themeStorageKey, theme)
						reload = true
					}
					if (enableNotificationFlyout && !localEnableNotificationFlyout) {
						localStorage.setItem(notificationFlyoutKey, new Date())
						reload = true
					} else if (!enableNotificationFlyout && localEnableNotificationFlyout) {
						localStorage.removeItem(notificationFlyoutKey)
						reload = true
					}
					if (enablePostRowFeed && !localEnablePostRowFeed) {
						localStorage.setItem(postRowFeedKey, new Date())
						reload = true
					} else if (!enablePostRowFeed && localEnablePostRowFeed) {
						localStorage.removeItem(postRowFeedKey)
						reload = true
					}

					// Reload page if localStorage preferences are different than user's
					if (reload) window.location.reload()
				}
				return {
					...state,
					user: {
						...state.user,
						customData: {
							...(state.user && state.user.customData),
							[customDataIndex]: parsedData,
						},
					},
				}
			} catch (e) {
				return state
			}
		}
		case FETCH_USERPREFERENCES_SUCCESS: {
			const { preferences } = action.response

			const prefKeys = Object.keys(preferences)

			if (prefKeys.length === 0) {
				return state
			}

			const prefState = prefKeys.reduce((memo, key) => {
				const data = safeGetNestedProp(preferences[key], 'data', '')
				if (!stringIsEmptyOrWhitespace(data)) {
					memo[key] = data
				}
				return memo
			}, {})

			return {
				...state,
				preferences: {
					...state.preferences,
					...prefState,
				},
			}
		}
		case SET_USERPREFERENCE_SUCCESS: {
			return state
		}
		case SET_FEEDLAYOUT_SUCCESS: {
			return {
				...state,
				preferences: {
					...state.preferences,
					[UserPreferenceKey.MemberAppFeedLayout]: action.layout,
				},
			}
		}
		case SET_SSO_SIGN_OUT_IN_PROGRESS: {
			return {
				...state,
				isSsoSignOutInProgress: true,
			}
		}
		case FETCH_CHANNEL_VERIFY_AUTHENTICATION_SUCCESS: {
			const { active, userChannelId } = action.response

			if (active) {
				return state
			}

			// If authentication is not active update user channel in store
			const updatedChannels = state.user.channels.map(c => {
				if (c.userChannelId === userChannelId) {
					return { ...c, authRequired: !active }
				}
				return c
			})

			return {
				...state,
				user: {
					...state.user,
					channels: updatedChannels,
				},
			}
		}
		case SET_HAS_CHANNELS_VERIFY_AUTHENTICATION_BEEN_CHECKED: {
			return {
				...state,
				hasChannelsVerifyAuthenticationBeenChecked: true,
			}
		}
		default:
			return state
	}
}
