const lang = {
	suffixes: {
		base: {
			format: number => `${number}`, //  Basic numeric representation.
			value: 1,
		},
		thousand: {
			format: number => `${number}K`, //  Thousands suffix. ex: 1K = 1,000
			value: 1e3,
		},
		million: {
			format: number => `${number}M`, //  Millions suffix. ex: 1M = 1,000,000
			value: 1e6,
		},
		billion: {
			format: number => `${number}B`, //  Billions suffix. ex: 1B = 1,000,000,000
			value: 1e9,
		},
		trillion: {
			format: number => `${number}T`, //  Trillions suffix. ex: 1T = 1,000,000,000,000
			value: 1e12,
		},
	},
}

export default lang;