import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { fontSize, fontWeight, margin, flexContainer } from 'styles/variables'

const styles = {
	container: {
		textAlign: 'center',
	},
	title: {
		fontSize: fontSize.jumbo,
		fontWeight: fontWeight.normal,
		marginTop: margin.narrow,
		marginBottom: margin.narrow,
	},
	description: {
		fontSize: fontSize.jumbo,
		fontWeight: fontWeight.light,
		marginTop: margin.narrow,
		marginBottom: margin.narrow,
		marginLeft: margin.wide,
		marginRight: margin.wide,
	},
	link: {
		fontSize: fontSize.heading,
	},
	image: {
		...flexContainer(null, 'center', null),
	},
}
export default prefixer.prefix(styles)
