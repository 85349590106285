import React from 'react'
import PropTypes from 'prop-types'

import SocialProviderData, { ProviderPropType } from 'helpers/socialProviderData'
import Icon, { Backdrop, Name } from 'components/Icons/FontAwesome'

// Styles
import { width, color as colorVars } from 'styles/variables'

export const SocialIconSize = {
	flag: 'flag',
	tiny: 'tiny',
	post: 'post',
	channel: 'channel',
	provider: 'provider',
}

const styles = {
	// Base
	display: 'inline-flex',
	lineHeight: 1,
	size: {
		[SocialIconSize.flag]: {
			fontSize: width.icon.flag,
		},
		[SocialIconSize.tiny]: {
			fontSize: width.icon.tiny,
		},
		[SocialIconSize.provider]: {
			fontSize: width.icon.provider,
		},
		[SocialIconSize.post]: {
			fontSize: width.icon.post,
		},
		[SocialIconSize.channel]: {
			fontSize: width.icon.channel,
		},
	},
}

class SocialIcon extends React.Component {
	render() {
		const { provider, size, backdrop, style: styleOverride, title, disabled, color } = this.props
		const providerData = SocialProviderData[provider]
		const providerColor = providerData ? providerData.color : 'inherit'
		const iconName = providerData ? providerData.iconName : Name.exclamation
		const tooltip = title || (providerData ? providerData.displayName : provider)

		const iconStyle = {
			...styles,
			...styles.size[size],
			...styleOverride, // Prop overrides
		}

		const actualColor = disabled ? colorVars.secondaryGrey : color ? color : providerColor

		return (
			<Icon
				name={iconName}
				title={tooltip}
				style={iconStyle}
				backdrop={backdrop}
				color={actualColor}
			/>
		)
	}
}

SocialIcon.propTypes = {
	provider: ProviderPropType.isRequired,
	size: PropTypes.oneOf(Object.values(SocialIconSize)),
	style: PropTypes.any,
	title: PropTypes.string,
	backdrop: PropTypes.oneOf(Object.values(Backdrop)),
	disabled: PropTypes.bool,
	color: PropTypes.string,
}

// Default Props
SocialIcon.defaultProps = {
	size: SocialIconSize.post,
	backdrop: Backdrop.square,
	disabled: false,
}

export default SocialIcon
