import { vsApi, requests } from 'helpers/apiHelpers'

export const sortByTypes = {
	dateNewest: 'DateNewest',
	dateOldest: 'DateOldest',
	relevance: 'Relevance',
}

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types. Callers should call Action Creators, below
// Exports for use only by other reducers
const FETCH_CATEGORY_LIST_REQUEST = 'FETCH_CATEGORY_LIST_REQUEST'
const FETCH_CATEGORY_LIST_SUCCESS = 'FETCH_CATEGORY_LIST_SUCCESS'
const FETCH_CATEGORY_LIST_FAILURE = 'FETCH_CATEGORY_LIST_FAILURE'
const FETCH_CATEGORIES_SUBSCRIBER_COUNTS_REQUEST = 'FETCH_CATEGORIES_SUBSCRIBER_COUNTS_REQUEST'
const FETCH_CATEGORIES_SUBSCRIBER_COUNTS_SUCCESS = 'FETCH_CATEGORIES_SUBSCRIBER_COUNTS_SUCCESS'
const FETCH_CATEGORIES_SUBSCRIBER_COUNTS_FAILURE = 'FETCH_CATEGORIES_SUBSCRIBER_COUNTS_FAILURE'
const SHOW_ALL_CHILD_CATEGORIES = 'SHOW_ALL_CHILD_CATEGORIES'
const SHOW_ALL_TOPLEVEL_CATEGORIES = 'SHOW_ALL_TOPLEVEL_CATEGORIES'
const FETCH_CATEGORY_POSTS_REQUEST = 'FETCH_CATEGORY_POSTS_REQUEST'
export const FETCH_CATEGORY_POSTS_SUCCESS = 'FETCH_CATEGORY_POSTS_SUCCESS'
const FETCH_CATEGORY_POSTS_FAILURE = 'FETCH_CATEGORY_POSTS_FAILURE'
const CLEAR_CATEGORY_POSTS = 'CLEAR_CATEGORY_POSTS'
const FETCH_POSTSEARCH_REQUEST = 'FETCH_POSTSEARCH_REQUEST'
export const FETCH_POSTSEARCH_SUCCESS = 'FETCH_POSTSEARCH_SUCCESS'
const FETCH_POSTSEARCH_FAILURE = 'FETCH_POSTSEARCH_FAILURE'
const RELAY_SET_CATEGORYUPDATE = 'RELAY_SET_CATEGORYUPDATE'
const ADD_PARENTSUBSCRIBED = 'ADD_PARENTSUBSCRIBED'
const REMOVE_PARENTSUBSCRIBED = 'REMOVE_PARENTSUBSCRIBED'
const SET_SUBSCRIBECATEGORY_REQUEST = 'SET_SUBSCRIBECATEGORY_REQUEST'
const SET_SUBSCRIBECATEGORY_SUCCESS = 'SET_SUBSCRIBECATEGORY_SUCCESS'
const SET_SUBSCRIBECATEGORY_FAILURE = 'SET_SUBSCRIBECATEGORY_FAILURE'
const CLEAR_SUBSCRIPTION_ERRORS = 'CLEAR_SUBSCRIPTION_ERRORS'
const FETCH_CATEGORY_LATEST_POST_SUCCESS = 'FETCH_CATEGORY_LATEST_POST_SUCCESS'
const SET_LATEST_POSTS_FETCHED_ONCE = 'SET_LATEST_POSTS_FETCHED_ONCE'
const SET_CATEGORY_DISCOVERY_FIRST_LOAD = 'SET_CATEGORY_DISCOVERY_FIRST_LOAD'

// Action Creators
export const showAllChildCategories = parentCategoryId => {
	return {
		type: SHOW_ALL_CHILD_CATEGORIES,
		parentCategoryId,
	}
}

export const showAllTopLevelCategories = () => {
	return {
		type: SHOW_ALL_TOPLEVEL_CATEGORIES,
	}
}

export const clearCategoryPosts = () => {
	return {
		type: CLEAR_CATEGORY_POSTS,
	}
}

export const setParentSubscribed = id => {
	return {
		type: ADD_PARENTSUBSCRIBED,
		id,
	}
}

export const removeParentSubscribed = id => {
	return {
		type: REMOVE_PARENTSUBSCRIBED,
		id,
	}
}

export const clearSubscriptionErrors = () => {
	return {
		type: CLEAR_SUBSCRIPTION_ERRORS,
	}
}

export const setLatestPostsFetchedOnce = () => {
	return {
		type: SET_LATEST_POSTS_FETCHED_ONCE,
	}
}

export const setCategoryDiscoveryFirstLoad = isFirstLoad => {
	return {
		type: SET_CATEGORY_DISCOVERY_FIRST_LOAD,
		isFirstLoad,
	}
}

// Async Action Creators

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

export const asyncGetCategories = () => {
	// Call the API
	return vsApi({
		endpoint: requests.getCategories.endpoint,
		actions: {
			request: FETCH_CATEGORY_LIST_REQUEST,
			success: FETCH_CATEGORY_LIST_SUCCESS,
			failure: FETCH_CATEGORY_LIST_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetCategoryPosts = (categoryId, start, count, context, abortable) => {
	// Call the API
	return vsApi({
		endpoint: requests.getCategoryPosts.getEndpoint(categoryId, start, count),
		actions: {
			request: FETCH_CATEGORY_POSTS_REQUEST,
			success: FETCH_CATEGORY_POSTS_SUCCESS,
			failure: FETCH_CATEGORY_POSTS_FAILURE,
		},
		context,
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetPostsSearch = (term, sortBy = sortByTypes.relevance, skip = 0, abortable) => {
	return vsApi({
		endpoint: requests.searchPosts.endpoint,
		actions: {
			request: FETCH_POSTSEARCH_REQUEST,
			success: FETCH_POSTSEARCH_SUCCESS,
			failure: FETCH_POSTSEARCH_FAILURE,
		},
		requestOptions: requests.searchPosts.getRequestOptions(term, sortBy, skip, 20),
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

export const asyncSubscribeCategory = (userId, categoryIds) => {
	return vsApi({
		endpoint: requests.subscribeToCategory.getEndpoint(userId),
		actions: {
			request: SET_SUBSCRIBECATEGORY_REQUEST,
			success: SET_SUBSCRIBECATEGORY_SUCCESS,
			failure: SET_SUBSCRIBECATEGORY_FAILURE,
		},
		requestOptions: requests.subscribeToCategory.getRequestOptions(categoryIds),
		context: {
			categoryId: categoryIds[0],
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncUnsubscribeCategory = (userId, categoryId) => {
	return vsApi({
		endpoint: requests.unsubscribeFromCategory.getEndpoint(userId, categoryId),
		actions: {
			request: SET_SUBSCRIBECATEGORY_REQUEST,
			success: SET_SUBSCRIBECATEGORY_SUCCESS,
			failure: SET_SUBSCRIBECATEGORY_FAILURE,
		},
		requestOptions: requests.unsubscribeFromCategory.getRequestOptions(categoryId),
		context: {
			categoryId,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetCategoriesSubscriberCounts = categoryIds => {
	return vsApi({
		endpoint: requests.getCategoriesSubscriberCounts.getEndpoint(categoryIds),
		actions: {
			request: FETCH_CATEGORIES_SUBSCRIBER_COUNTS_REQUEST,
			success: FETCH_CATEGORIES_SUBSCRIBER_COUNTS_SUCCESS,
			failure: FETCH_CATEGORIES_SUBSCRIBER_COUNTS_FAILURE,
		},
		requestOptions: requests.getCategoriesSubscriberCounts.requestOptions,
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetCategoriesLatestPost = categoryIds => {
	return vsApi({
		endpoint: requests.getCategoriesLatestPost.getEndpoint(categoryIds),
		actions: {
			success: FETCH_CATEGORY_LATEST_POST_SUCCESS,
		},
		requestOptions: requests.getCategoriesLatestPost.requestOptions,
		options: { captureUnhandledPromiseRejection: false },
	})
}

// Relay
export const relaySetCategoryUpdate = categoryIds => {
	return {
		type: RELAY_SET_CATEGORYUPDATE,
		categoryIds: categoryIds.categoryId,
	}
}

// --- Initial State ---

// State will be mapped to the Redux store by combineReducers
const initialCategories = {
	isPending: false,
	categories: [],
	categoryMap: {},
	showAllCategories: false,
	landingPageDefaultCategory: null,
	subscriptionPrompt: null,
	enableCategorySubscription: false,
	categoryUpdateIds: [],
	parentSubscribed: [],
	categoryLatestPostMap: {},
	hasLatestPostsBeenFetchedOnce: false,
	isCategoryDiscoveryFirstLoad: false,
}

const initialPosts = {
	postsPending: true,
	postsPendingCategoryId: 0,
	postIds: [], // IDs of posts to show in current category
	postsTotal: 0,
	postsNextStart: 0,
	isMorePostsAvailable: false,
}

const initialErrors = {
	errors: [],
}

const initialState = { ...initialCategories, ...initialPosts, ...initialErrors }

// --- Private Helpers ---

export const processCategoryItems = (categoryTree, depth = 0, parentCategory = null) => {
	let categoryItems = []

	categoryTree.forEach(cat => {
		// Exclude hidden categories
		if (!cat.isHidden) {
			// Add extra props for manipulating items
			let newCat = { ...cat, depth }
			if (parentCategory) {
				newCat.parentCategory = parentCategory
				if (newCat.isSubscribed) {
					parentCategory.isChildSubscribed = true // At least one child category is subscribed
				}
			}
			categoryItems.push(newCat)

			// Recurse thru children
			if (newCat.childCategories) {
				newCat.showAllChildren = false
				newCat.childCategories = processCategoryItems(cat.childCategories, depth + 1, newCat)
			}
		}
	})

	return categoryItems
}

export const flattenCategoryTree = categoryTree =>
	categoryTree.reduce((ary, cat) => {
		// Flatten to a one-dimensional array of categories

		// Add this category to the result
		ary.push(cat)

		// Recurse thru children
		if (cat.childCategories) {
			ary = ary.concat(flattenCategoryTree(cat.childCategories))
		}

		return ary
	}, []) // ary

export const convertTreeToMap = categoryTree =>
	categoryTree.reduce((obj, cat) => {
		// Convert to a map
		obj[cat.id] = cat
		return obj
	}, {}) // obj

export const setToParentSubscribed = subscriptions => {
	// checks which parents are subscribed and places them in the parentSubscribed array.
	const arr = subscriptions.filter(s => s.isSubscribed).map(s => s.id)
	return arr
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function categoryReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_CATEGORY_LIST_REQUEST: {
			const isPending = state.categories.length > 0 ? false : true

			return {
				...state,
				isPending,
			}
		}
		case FETCH_CATEGORY_LIST_SUCCESS: {
			const {
				subscriptionPrompt,
				enableCategorySubscription,
				landingPageDefaultCategory,
			} = action.response
			const categories = processCategoryItems(action.response.categories)
			const categoryMap = convertTreeToMap(flattenCategoryTree(categories))
			const parentSubscribed = setToParentSubscribed(action.response.categories)

			return {
				...state,
				isPending: false,
				categories,
				categoryMap,
				landingPageDefaultCategory,
				subscriptionPrompt,
				enableCategorySubscription,
				parentSubscribed,
			}
		}
		case FETCH_CATEGORY_LIST_FAILURE: {
			const errors = [...state.errors, ...[action.error]]
			return {
				...state,
				...initialCategories,
				errors,
			}
		}
		case SHOW_ALL_CHILD_CATEGORIES: {
			let categories = state.categories.slice() // Copy to new list
			let categoryMap = convertTreeToMap(flattenCategoryTree(categories))
			categoryMap[action.parentCategoryId].showAllChildren = true

			return {
				...state,
				categories,
				categoryMap,
			}
		}
		case SHOW_ALL_TOPLEVEL_CATEGORIES: {
			return {
				...state,
				showAllCategories: true,
			}
		}
		case FETCH_CATEGORY_POSTS_REQUEST:
			return {
				...state,
				postsPending: true,
				postsPendingCategoryId: action.context.categoryId,
			}
		case FETCH_CATEGORY_POSTS_SUCCESS: {
			// Ignore out-of-date results
			if (action.context && action.context.categoryId !== state.postsPendingCategoryId) {
				return { ...state }
			}

			const { posts, total, next } = action.response

			// Merge old postIds and new postIds into an object to remove any duplicates.
			// React does not like duplicate keys
			let postIdsMap = {}
			state.postIds.reduce((map, postId) => {
				map[postId] = 1
				return map
			}, postIdsMap)
			posts.reduce((map, post) => {
				map[post.postId] = 1
				return map
			}, postIdsMap)
			const postIds = Object.keys(postIdsMap)

			// Posts map is managed by the Post reducer

			// Add these posts to existing posts

			// Remove from categoryUpdateIds after fetching
			const categoryUpdateIds = state.categoryUpdateIds.filter(
				id => id !== action.context.categoryId
			)

			return {
				...state,
				postsPending: false,
				postsPendingCategoryId: 0,
				postIds,
				postsTotal: total,
				postsNextStart: next,
				isMorePostsAvailable: total > postIds.length,
				categoryUpdateIds,
			}
		}
		case FETCH_CATEGORY_POSTS_FAILURE: {
			return {
				...state,
				postsPending: false,
				postsPendingCategoryId: 0,
				// Leave existing posts unchanged
			}
		}
		case CLEAR_CATEGORY_POSTS: {
			return {
				...state,
				...initialPosts,
			}
		}
		case FETCH_POSTSEARCH_REQUEST: {
			return {
				...state,
				postsPending: true,
			}
		}
		case FETCH_POSTSEARCH_SUCCESS: {
			const newPostIds = action.response.results.map(result => result.post.postId)
			const next = action.response.next

			let postIdsMap = {}
			state.postIds.reduce((map, postId) => {
				map[postId] = 1
				return map
			}, postIdsMap)
			newPostIds.reduce((map, postId) => {
				map[postId] = 1
				return map
			}, postIdsMap)
			const postIds = Object.keys(postIdsMap)

			return {
				...state,
				postIds,
				postsPending: false,
				postsNextStart: next,
				isMorePostsAvailable: next > 0,
			}
		}
		case FETCH_POSTSEARCH_FAILURE: {
			return {
				...state,
				postsPending: false,
			}
		}
		case RELAY_SET_CATEGORYUPDATE: {
			return {
				...state,
				categoryUpdateIds: action.categoryIds,
			}
		}
		case ADD_PARENTSUBSCRIBED: {
			let arr = []
			if (!state.parentSubscribed.includes(action.id)) arr = [...state.parentSubscribed, action.id]
			return {
				...state,
				parentSubscribed: arr,
			}
		}
		case REMOVE_PARENTSUBSCRIBED: {
			let arr = []
			if (state.parentSubscribed.includes(action.id)) {
				const i = state.parentSubscribed.indexOf(action.id)
				arr = [...state.parentSubscribed]
				arr.splice(i, 1)
			}
			return {
				...state,
				parentSubscribed: arr,
			}
		}
		case SET_SUBSCRIBECATEGORY_REQUEST: {
			const categoryId = action.context.categoryId

			return {
				...state,
				categoryMap: {
					...state.categoryMap,
					[categoryId]: {
						...state.categoryMap[categoryId],
						isSubscribing: true,
					},
				},
			}
		}
		case SET_SUBSCRIBECATEGORY_SUCCESS: {
			const categoryId = action.context.categoryId
			const isSubscribed = !state.categoryMap[categoryId].isSubscribed

			return {
				...state,
				categoryMap: {
					...state.categoryMap,
					[categoryId]: {
						...state.categoryMap[categoryId],
						isSubscribed,
						isSubscribing: false,
					},
				},
			}
		}
		case SET_SUBSCRIBECATEGORY_FAILURE: {
			const errors = [...state.errors, ...[action.error]]
			const categoryId = action.context.categoryId

			return {
				...state,
				categoryMap: {
					...state.categoryMap,
					[categoryId]: {
						...state.categoryMap[categoryId],
						isSubscribing: false,
					},
				},
				errors,
			}
		}
		case CLEAR_SUBSCRIPTION_ERRORS: {
			return {
				...state,
				errors: [],
			}
		}
		case FETCH_CATEGORY_LATEST_POST_SUCCESS: {
			const latestPosts = action.response.latestPosts

			return {
				...state,
				categoryLatestPostMap: {
					...state.categoryLatestPostMap,
					...latestPosts,
				},
			}
		}
		case SET_LATEST_POSTS_FETCHED_ONCE: {
			return {
				...state,
				hasLatestPostsBeenFetchedOnce: true,
			}
		}
		case SET_CATEGORY_DISCOVERY_FIRST_LOAD: {
			return {
				...state,
				isCategoryDiscoveryFirstLoad: action.isFirstLoad,
			}
		}
		default:
			return state
	}
}
