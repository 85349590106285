import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

// Styles
import { border, form, color, margin, padding } from 'styles/variables'

const styles = {
	base: {
		height: form.input.height,
		borderRadius: border.radius,
		border: border.basic,
		marginTop: margin.basic,
		marginRight: 0,
		marginLeft: 0,
		marginBottom: margin.basic,
		paddingLeft: padding.narrow,
		width: '100%',
		error: {
			border: border.error,
		},
	},
	error: {
		text: {
			color: color.secondaryRed,
		},
		border: border.error,
	},
}

class Select extends Component {
	static propTypes = {
		name: PropTypes.string,
		style: PropTypes.object,
		options: PropTypes.array,
		placeholder: PropTypes.string,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		value: PropTypes.string,
		touched: PropTypes.bool,
		error: PropTypes.bool,
	}

	handleChange = e => {
		const { onChange } = this.props
		if (onChange) return onChange(e.target.value)
	}

	handleBlur = e => {
		const { onBlur } = this.props
		if (onBlur) return onBlur(e.target.value)
	}

	render() {
		const { name, options, style, placeholder, value, touched, error } = this.props
		const hasPlaceholder = placeholder || placeholder === ''
		const dynamicStyles = {
			select: {
				...styles.base,
				...(touched && error && styles.error),
				...style,
			},
		}

		return (
			<Fragment>
				<select
					name={name}
					value={value}
					style={dynamicStyles.select}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
				>
					{hasPlaceholder && (
						<option value={''} style={styles.placeholder}>
							{placeholder}
						</option>
					)}

					{options &&
						options.map((option, index) => {
							return (
								<option key={`${option.value}-${index}`} value={option.value}>
									{option.name}
								</option>
							)
						})}
				</select>
				{touched && error && <p style={styles.error.text}>{error}</p>}
			</Fragment>
		)
	}
}

export default Select
