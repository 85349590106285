import React, { Component } from 'react'

// Components
import Icon, { Name } from 'components/Icons/FontAwesome'

//#region Styles
import { color, spacing } from 'styles/variables'

const styles = {
	icon: {
		display: 'flex',
		position: 'absolute',
		fontSize: spacing.gutter,
		color: color.white,
		opacity: 1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		height: spacing.gutter,
		width: spacing.gutter,
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
		filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5))',
	},
}
//#endregion

export class LiveStreamIcon extends Component {
	render() {
		return <Icon name={Name.tvRetro} style={styles.icon} />
	}
}

class LiveStreamPlaceholder extends Component {
	render() {
		return (
			<img
				src="https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/052e6e58-6a5f-4f01-bfac-9fc2f0696d43?_=636728872606923755"
				srcSet="https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/052e6e58-6a5f-4f01-bfac-9fc2f0696d43?_=636728872606923755 1x, https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/0bced80a-bfae-4be8-bb8f-67b80e287a31?_=636728872601890564 2x, https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/4f78b196-1bcb-47a9-b8f0-9cf4c9465891?_=636728871854661234 3x"
				alt="retro-tv"
			/>
		)
	}
}

export default LiveStreamPlaceholder
