import { color, fontSize } from './variables'

// a, Link with no underline, color stays link color
export const actionLink = {
	textDecoration: 'none',

	':hover': {
		textDecoration: 'none',
	},

	':focus': {
		textDecoration: 'none',
	},

	':active': {
		textDecoration: 'none',
	},
}

// Disabled text
export const disabledText = {
	color: color.secondaryGrey,
}

// Help text
export const helpText = {
	color: color.secondaryGrey,
	fontSize: fontSize.small,
}

// Ellipsis
export const textEllipsis = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	display: 'block',
}

// Unstyled list
export const listUnstyled = {
	listStyle: 'none',
	paddingLeft: 0,
	margin: 0,
}

export const listUnstyledCentered = {
	display: 'flex',
	alignItems: 'center',
	listStyle: 'none',
	paddingTop: 0,
	paddingBottom: 0,
	paddingLeft: 0,
	paddingRight: 0,
	marginTop: 0,
	marginBottom: 0,
	marginLeft: 0,
	marginRight: 0,
}

export const highlightStyle = {
	backgroundColor: color.textHighlight,
}
