import PropTypes from 'prop-types'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { ColorPropType } from 'helpers/propTypes'
import { color } from 'styles/variables'

// Lang
import lang from './settings.lang'
import { FeedLayoutOptions } from 'reducers/sphere.data'
import UserPreferenceKey from 'scenes/Auth/enums/UserPreferenceKey'

/**
 * Attempts to safely retrieve a setting value from state via a config object
 * @param {Object} setting - Setting config object defined via the exported Settings const
 * @param {*} state - Global state
 */
export const safeGetSetting = (state, setting) => {
	// Don't bother if someone passed in garbage
	if (!setting || !setting.name || !state) return null

	let value

	// If it's a computed setting, return the value
	if (setting.value && typeof setting.value === 'function') {
		value = setting.value(state)
	} else {
		// Safe retrieval with fallback
		value = safeGetNestedProp(state, setting.path, setting.fallback)
	}

	return value
}

// NOTE: Keep alphabetical!
const Settings = {
	/**
	 * Settings related to the sphere/community and its configuration
	 */
	Community: {
		/**
		 * Sphere ID
		 */
		SphereId: {
			name: 'sphereId',
			path: 'sphere.sphereId',
			propType: PropTypes.number,
		},
		/**
		 * Localized community name
		 */
		Name: {
			name: 'communityName',
			path: 'sphere.communityName',
			propType: PropTypes.string,
			fallback: 'Community',
		},
		/**
		 * Community background color used throughout the site
		 */
		BackgroundColor: {
			name: 'backgroundColor',
			path: 'sphere.theme.backgroundColor',
			propType: ColorPropType,
			fallback: color.bgPage,
		},
		/**
		 * Root URL for the application, a.k.a. Home page URL.
		 */
		HomeUrl: {
			name: 'homeUrl',
			path: 'sphere.home',
			propType: PropTypes.string,
		},
		/**
		 * Base URL for the post details page. Don't use this property directly!
		 * Pass its value to postLinkUrl in postHelpers to build a post link.
		 */
		ArticleRoutingBaseUrl: {
			name: 'articleRoutingBaseUrl',
			path: 'sphere.parameters.articleRoutingBaseUrl',
			propType: PropTypes.string,
		},
		/**
		 * Category ID used to fetch Featured Content posts. Default, null, means no showcase shown
		 */
		FeaturedContentCategoryId: {
			name: 'featuredContentCategoryId',
			path: 'sphere.parameters.featuredContentCategoryId',
			propType: PropTypes.number,
			fallback: null,
		},
		/**
		 * Mobile styled logo image url for download app dialog
		 */
		MobileStyledLogoImageUrl: {
			name: 'mobileStyledLogoImageUrl',
			path: 'sphere.theme.mobileStyledLogoImageUrl',
			propType: PropTypes.string,
		},
		/**
		 * Password complexity requirements
		 */
		PasswordComplexity: {
			name: 'passwordComplexity',
			path: 'sphere.security.passwordComplexity',
			propType: PropTypes.object,
			fallback: {
				minLength: 6,
				minLower: 0,
				minNumber: 0,
				minSpecial: 0,
				minUpper: 0,
				errorResx: 'PasswordSecurityLevelEnumStandard',
			},
		},
	},
	/**
	 * Settings that control distinct functionality in the app. These are similar to feature flags.
	 * NOTE: If adding new sphere.parameters, make sure to also get them into state in the sphereReducer.
	 */
	Features: {
		/**
		 * Whether users are allowed to add an image when submitting posts
		 */
		AllowImageSubmission: {
			path: 'sphere.parameters.allowImageSubmission',
			fallback: 'Disabled',
			name: 'allowImageSubmission',
			propType: PropTypes.string,
		},
		/**
		 * Whether users are allowed to add categories when submitting posts
		 */
		AllowUsersToCategorizePosts: {
			path: 'sphere.parameters.allowUsersToCategorizePosts',
			fallback: 'Disabled',
			name: 'allowUsersToCategorizePosts',
			propType: PropTypes.string,
		},
		/**
		 * Whether users are allowed to add tags when submitting posts
		 */
		AllowUsersToTagPosts: {
			path: 'sphere.parameters.allowUsersToTagPosts',
			fallback: 'Disabled',
			name: 'allowUsersToTagPosts',
			propType: PropTypes.string,
		},
		/**
		 * Maximum Accepted Document File Size.
		 */
		DocumentAttachmentMaxFileSizeInMb: {
			path: 'sphere.parameters.documentAttachmentMaxFileSizeInMb',
			fallback: 0,
			name: 'documentAttachmentMaxFileSizeInMb',
			propType: PropTypes.number,
		},
		/**
		 * Maximum Accepted Document Upload Count.
		 */
		DocumentAttachmentMaxFileUploadCount: {
			path: 'sphere.parameters.documentAttachmentMaxFileUploadCount',
			fallback: 0,
			name: 'documentAttachmentMaxFileUploadCount',
			propType: PropTypes.number,
		},
		/**
		 * Duration of Messages To Store in Days. (number of days)
		 */
		DurationOfMessagesToStoreInDays: {
			path: 'sphere.parameters.durationOfMessagesToStoreInDays',
			fallback: 0,
			name: 'durationOfMessagesToStoreInDays',
			propType: PropTypes.number,
		},
		//** Whether user has access to attach documents when creating/submitting a post. */
		EnableDocumentAttachments: {
			path: 'sphere.parameters.enableDocumentAttachments',
			fallback: false,
			name: 'enableDocumentAttachments',
			propType: PropTypes.bool,
		},
		//** Whether user has access to the enhanced sharing flow on the Post Card */
		EnableEnhancedSharingFlow: {
			path: 'sphere.parameters.enableEnhancedSharingFlow',
			fallback: false,
			name: 'enableEnhancedSharingFlow',
			propType: PropTypes.bool,
		},
		//** Whether user is able to edit their profile image */
		EnableEditProfileImage: {
			path: 'sphere.parameters.enableEditProfileImage',
			fallback: true,
			name: 'enableEditProfileImage',
			propType: PropTypes.bool,
		},
		/**
		 * Whether user can sign in/sign up via Email.
		 */
		EnableEmailSignOn: {
			path: 'sphere.parameters.enableEmailSignOn',
			fallback: true,
			name: 'enableEmailSignOn',
			propType: PropTypes.bool,
		},
		/**
		 * Whether leaderboards should be visible/enabled
		 */
		EnableLeaderboards: {
			path: 'sphere.parameters.enableLeaderboards',
			fallback: true,
			name: 'enableLeaderboards',
			propType: PropTypes.bool,
		},
		/**
		 * Whether leaderboards should be visible/enabled
		 */
		EnableMemberEditName: {
			path: 'sphere.parameters.enableMemberEditName',
			fallback: true,
			name: 'enableMemberEditName',
			propType: PropTypes.bool,
		},
		/**
		 * Whether member to member should be visible/enabled
		 */
		EnableMemberToMemberMessages: {
			path: 'sphere.parameters.enableMemberToMemberMessages',
			fallback: false,
			name: 'enableMemberToMemberMessages',
			propType: PropTypes.bool,
		},
		/**
		 * Whether phone numbers are publicly visible in member profiles (different from sms notification numbers)
		 */
		EnableMemberPhoneNumber: {
			path: 'sphere.parameters.enableMemberPhoneNumber',
			fallback: false,
			name: 'enableMemberPhoneNumber',
			propType: PropTypes.bool,
		},
		/**
		 * Whether to show share post link on posts
		 */
		EnableMemberProfiles: {
			path: 'sphere.parameters.enableFullProfiles',
			fallback: true,
			name: 'enableMemberProfiles',
			propType: PropTypes.bool,
		},
		/**
		 * Whether messages should be visible/enabled
		 */
		EnableMessages: {
			path: 'sphere.parameters.enableMessages',
			fallback: false,
			name: 'enableMessages',
			propType: PropTypes.bool,
		},
		/** Whether the new (V8) Share Dialog is enabled */
		EnableShareDialogV8: {
			path: 'sphere.parameters.enableShareDialogV8',
			fallback: false,
			name: 'enableShareDialogV8',
			value: state => {
				return safeGetNestedProp(
					state,
					Settings.Features.EnableShareDialogV8.path,
					Settings.Features.EnableShareDialogV8.fallback
				)
			},
			propType: PropTypes.bool,
		},
		/**
		 * Whether sharing posts by email is allowed
		 */
		EnableShareByEmail: {
			path: 'sphere.parameters.enableShareByEmail',
			fallback: true,
			name: 'enableShareByEmail',
			propType: PropTypes.bool,
		},
		/**
		 * Whether members can download a sharing stats CSV
		 */
		EnableSharingStatsCsvDownload: {
			path: 'sphere.parameters.enableSharingStatsCsvDownload',
			fallback: false,
			name: 'enableSharingStatsCsvDownload',
			propType: PropTypes.bool,
		},
		/**
		 * Whether the 'My Content' category is enabled for logged-in users
		 */
		EnableMyContentCategory: {
			path: 'sphere.parameters.enableMyContentCategory',
			value: state => {
				return (
					safeGetSetting(state, Settings.User.IsLoggedIn) &&
					safeGetNestedProp(
						state,
						Settings.Features.EnableMyContentCategory.path,
						Settings.Features.EnableMyContentCategory.fallback
					)
				)
			},
			fallback: false,
			name: 'enableMyContentCategory',
			propType: PropTypes.bool,
		},
		/**
		 * Whether anonymous users are allowed to view the main feed and profile pages.
		 */
		EnableAnonymousAccess: {
			path: 'sphere.parameters.allowAnonymousNewsFeedAccess',
			fallback: true,
			name: 'enableAnonymousAccess',
			propType: PropTypes.bool,
		},
		/**
		 * Whether prefilled sharing text exists when user tries to share text via Facebook.
		 */
		EnableSuggestedShareTextForFacebook: {
			path: 'sphere.parameters.enableSuggestedShareText',
			name: 'enableSuggestedShareTextForFacebook',
			value: state => {
				return (
					safeGetNestedProp(
						state,
						Settings.Features.EnableSuggestedShareTextForFacebook.path,
						'Disabled'
					) === 'EnabledAllChannels'
				)
			},
			fallback: false,
			propType: PropTypes.bool,
		},
		/**
		 * Whether new members can be invited
		 */
		EnableNewMemberInvitations: {
			path: 'sphere.parameters.enableNewMemberInvitations',
			name: 'enableNewMemberInvitations',
			value: state => {
				return safeGetNestedProp(
					state,
					Settings.Features.EnableNewMemberInvitations.path,
					Settings.Features.EnableNewMemberInvitations.fallback
				)
			},
			fallback: false,
			propType: PropTypes.bool,
		},
		/**
		 * Whether members have divisions enabled or not.
		 */
		EnableDivisions: {
			path: 'sphere.parameters.enableDivisions',
			name: 'enableDivisions',
			value: state => {
				return safeGetNestedProp(
					state,
					Settings.Features.EnableDivisions.path,
					Settings.Features.EnableDivisions.fallback
				)
			},
			fallback: false,
			propType: PropTypes.bool,
		},
		/**
		 * Whether members have languages enabled or not.
		 */
		EnableLanguageTargeting: {
			path: 'sphere.parameters.enableLanguageTargeting',
			name: 'enableLanguageTargeting',
			value: state => {
				return safeGetNestedProp(
					state,
					Settings.Features.EnableLanguageTargeting.path,
					Settings.Features.EnableLanguageTargeting.fallback
				)
			},
			fallback: false,
			propType: PropTypes.bool,
		},
		/** Whether a user can view and subscribe to mobile/SMS notifications. */
		EnableSMS: {
			path: 'sphere.parameters.enableSms',
			name: 'enableSms',
			value: state => {
				return safeGetNestedProp(
					state,
					Settings.Features.EnableSMS.path,
					Settings.Features.EnableSMS.fallback
				)
			},
			fallback: false,
			propType: PropTypes.bool,
		},
		/**
		 * Whether SSO (Single Sign On) is enabled for the sphere
		 */
		EnableSso: {
			path: 'sphere.parameters.enableSso',
			fallback: false,
			name: 'enableSso',
			propType: PropTypes.bool,
		},
		/**
		 *  Whether a user can view user directory
		 */
		EnableUserDirectory: {
			path: 'sphere.parameters.enableUserDirectory',
			name: 'enableUserDirectory',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 *  Whether user can sign in/sign up via Username.
		 */
		EnableUsernameSignOn: {
			path: 'sphere.parameters.enableUsernameSignOn',
			name: 'enableUsernameSignOn',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 *  Whether user can add an email as a Username user.
		 */
		EnableEmailForUsernameUsers: {
			path: 'sphere.parameters.enableEmailForUsernameUsers ',
			name: 'enableEmailForUsernameUsers ',
			value: state => {
				return (
					safeGetSetting(state, Settings.Features.EnableUsernameSignOn) &&
					safeGetNestedProp(
						state,
						Settings.Features.EnableEmailForUsernameUsers.path,
						Settings.Features.EnableEmailForUsernameUsers.fallback
					)
				)
			},
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 * Whether all API calls require a signed in user.
		 */
		EnhancedApiSecurity: {
			path: 'sphere.parameters.enhancedApiSecurity',
			fallback: false,
			name: 'enhancedApiSecurity',
			propType: PropTypes.bool,
			value: state => {
				return safeGetNestedProp(state, Settings.Features.EnhancedApiSecurity.path, null) // return null as fallback for middleware check
			},
		},
		/**
		 *  Whether a user can view custom pages
		 */
		EnableCustomPages: {
			path: 'sphere.parameters.enableCustomPages',
			name: 'enableCustomPages',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 *  Whether the user likes to party
		 */
		EnablePartyMode: {
			path: 'browser.enablePartyMode',
			name: 'enablePartyMode',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 *  FacebookNative config
		 */
		FacebookNativeConfig: {
			path: 'sphere.parameters.facebookNativeConfig',
			name: 'facebookNativeConfig',
			propType: PropTypes.object,
		},
		/**
		 * Feed Layout Options
		 */
		FeedLayoutOptions: {
			path: 'sphere.parameters.feedLayoutOptions',
			name: 'feedLayoutOptions',
			propType: PropTypes.arrayOf(PropTypes.string),
			fallback: [],
		},
		/**
		 * Whether user can perform scheduled shares
		 */
		IsScheduledSharingEnabled: {
			path: 'sphere.parameters.isScheduledSharingEnabled',
			fallback: false,
			name: 'isScheduledSharingEnabled',
			propType: PropTypes.bool,
		},
		/**
		 * Maximum Accepted Video File Size.
		 */
		MaximumVideoFileSize: {
			path: 'sphere.parameters.maximumVideoFileSize',
			fallback: 0,
			name: 'maximumVideoFileSize',
			propType: PropTypes.number,
		},
		/**
		 *  LinkedIn Native config
		 */
		LinkedInNativeConfig: {
			path: 'sphere.parameters.linkedInNativeConfig',
			name: 'linkedInNativeConfig',
			propType: PropTypes.object,
		},
		/**
		 * Guideline text when submitting posts
		 */
		PostGuidelineText: {
			path: 'sphere.localizations.postGuidelineText',
			fallback:
				'You can submit a post which may be reviewed by a community manager prior to being published. Once submitted, you will not be able to edit the post.',
			name: 'postGuidelineText',
			propType: PropTypes.string,
		},
		/**
		 * Whether submitted posts are posted or submitted for review
		 */
		PostsRequireApproval: {
			path: 'sphere.parameters.postsRequireApproval',
			fallback: 'Pending',
			name: 'postsRequireApproval',
			propType: PropTypes.string,
		},
		/**
		 * Submission confirmation text after submitting posts
		 */
		PostSubmissionConfirmation: {
			path: 'sphere.localizations.postSubmissionConfirmation',
			fallback:
				'You have successfully submitted your post for review. If approved, you will see it in the News Feed.',
			name: 'postSubmissionConfirmation',
			propType: PropTypes.string,
		},
		/**
		 * Require SMS phone numbers to be verified
		 */
		RequireSmsPinVerification: {
			path: 'sphere.parameters.requireSmsPinVerification',
			fallback: false,
			name: 'requireSmsPinVerification',
			propType: PropTypes.bool,
		},
		/**
		 * The number of channels a user can share a post too
		 */
		SharingMaxChannelsPerShare: {
			path: 'sphere.parameters.sharingMaxChannelsPerShare',
			fallback: 0,
			name: 'sharingMaxChannelsPerShare',
			propType: PropTypes.number,
		},
		/**
		 * Whether to show share post link on posts
		 */
		ShowPostShareLinks: {
			path: 'sphere.parameters.showPostShareLinks',
			fallback: false,
			name: 'showPostShareLinks',
			propType: PropTypes.bool,
		},
		/**
		 * Whether to show Share button on social posts (in addition to reactions)
		 */
		ShowShareButtonOnSocialPosts: {
			name: 'showShareButtonOnSocialPosts',
			path: 'sphere.parameters.showShareButtonOnSocialPosts',
			propType: PropTypes.bool,
		},
		/**
		 * Third party SSO logout URL
		 */
		SsoLogoutUrl: {
			name: 'ssoLogoutUrl',
			fallback: '',
			path: 'sphere.parameters.ssoLogoutUrl',
			propType: PropTypes.string,
		},
		/**
		 *  TwitterNative config
		 */
		TwitterNativeConfig: {
			path: 'sphere.parameters.twitterNativeConfig',
			name: 'twitterNativeConfig',
			propType: PropTypes.object,
		},
		/** Whether authorization tokens should persist between sessions or expire when the user closes the browser or tab. */
		UsePersistentAuthentication: {
			name: 'usePersistentAuthentication',
			path: 'sphere.parameters.usePersistentAuthentication',
			propType: PropTypes.bool,
			fallback: false,
		},
		/** Xing Native Config */
		XingNativeConfig: {
			path: 'sphere.parameters.xingNativeConfig',
			name: 'xingNativeConfig',
			propType: PropTypes.object,
		},
	},
	/**
	 * User-specific settings
	 */
	User: {
		/**
		 * The authenticated user's internal user ID
		 */
		ID: {
			name: 'currentUserId',
			path: 'auth.user.userId',
			propType: PropTypes.number,
		},
		/**
		 * The authenticated user's display name
		 */
		DisplayName: {
			name: 'userDisplayName',
			path: 'auth.user.displayName',
			propType: PropTypes.string,
			fallback: lang.anonymousUser,
		},
		/**
		 * Whether the authenticated user is able to create/edit posts.
		 * Note that this is different from submit posts. Every user can submit posts for review.
		 * But only a few (Global, Division, Content, Basic) can create/publish posts directly.
		 */
		CanEditPost: {
			name: 'canEditPost',
			value: state => safeGetNestedProp(state, 'auth.user.canEditPost', false),
			propType: PropTypes.bool,
		},
		/**
		 * Whether the authenticated user is able to share posts
		 */
		CanShareContent: {
			name: 'userCanShareContent',
			value: state => {
				return (
					safeGetSetting(state, Settings.User.IsLoggedIn) &&
					safeGetNestedProp(
						state,
						Settings.User.CanShareContent.path,
						Settings.User.CanShareContent.fallback
					)
				)
			},
			path: 'auth.user.canSharePosts',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 * Whether the authenticated user is able to submit new posts for consideration
		 */
		CanSubmitContent: {
			name: 'userCanSubmitContent',
			value: state => {
				return (
					safeGetSetting(state, Settings.User.IsLoggedIn) &&
					safeGetNestedProp(
						state,
						Settings.User.CanSubmitContent.path,
						Settings.User.CanSubmitContent.fallback
					)
				)
			},
			path: 'auth.user.canSubmitPosts',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 * Whether the authenticated user is able to participate in internal discussions
		 */
		CanCommentContent: {
			name: 'userCanCommentPosts',
			value: state => {
				return (
					safeGetSetting(state, Settings.User.IsLoggedIn) &&
					safeGetNestedProp(
						state,
						Settings.User.CanCommentContent.path,
						Settings.User.CanCommentContent.fallback
					)
				)
			},
			path: 'auth.user.canCommentPosts',
			propType: PropTypes.bool,
			fallback: false,
		},
		/**
		 * Computed property that lets you know if there is a valid user currently logged in.
		 */
		IsLoggedIn: {
			name: 'userIsLoggedIn',
			value: state => {
				const userId = safeGetNestedProp(state, Settings.User.ID.path)
				return typeof userId !== 'undefined' && userId !== null
			},
			propType: PropTypes.bool,
		},
		/**
		 * Whether the authenticated user is a manager
		 */
		IsManager: {
			name: 'isManager',
			value: state => safeGetNestedProp(state, 'auth.user.isManager', false),
			propType: PropTypes.bool,
		},
		/**
		 * Whether the authenticated user has completed their profile
		 */
		IsProfileComplete: {
			name: 'userIsProfileComplete',
			path: 'auth.user.profileCompleted',
			fallback: false,
			propType: PropTypes.bool,
		},
		/**
		 * The authenticated user's publicly viewable phone number
		 */
		PhoneNumber: {
			name: 'phoneNumber',
			path: 'auth.user.phoneNumber.primaryNumber',
			propType: PropTypes.string,
		},
		/**
		 * The authenticated user's profile pictures
		 */
		ProfilePictures: {
			name: 'userProfilePictures',
			path: 'auth.user.profilePictureImages',
			propType: PropTypes.object,
		},
		/**
		 * Returns the user's preferred feed layout
		 */
		SelectedFeedLayout: {
			name: 'selectedFeedLayout',
			value: state => {
				// Get the default community layout
				const defaultLayout =
					safeGetNestedProp(state, 'sphere.parameters.defaultFeedLayout') || FeedLayoutOptions.Grid

				// Anonymous users always get the default
				if (!safeGetSetting(state, Settings.User.IsLoggedIn)) {
					return defaultLayout
				}

				// Get the user's persisted preference
				const userPref = safeGetNestedProp(
					state,
					`auth.preferences.${UserPreferenceKey.MemberAppFeedLayout}`
				)

				// Grab all the supported community layouts
				const supportedLayouts = safeGetSetting(state, Settings.Features.FeedLayoutOptions)

				// If they want to use a layout that isn't supported, use the default instead
				if (!supportedLayouts.includes(userPref)) {
					return defaultLayout
				}

				return userPref
			},
			propType: PropTypes.string,
		},
		/**
		 * Returns a timezone with the following preference:
		 *  - Authenticated user's selected timezone
		 *  - Authenticated user's default timezone
		 *  - Sphere default timezone
		 */
		Timezone: {
			name: 'displayTimezone',
			value: state => {
				return safeGetSetting(state, Settings.User.IsLoggedIn)
					? safeGetNestedProp(state, 'auth.user.selectedTimeZone') ||
							safeGetNestedProp(state, 'auth.user.timeZone')
					: safeGetNestedProp(state, 'sphere.timeZoneInfo.communityTimeZone.id')
			},
			propType: PropTypes.string,
		},
		/**
		 * Current user's time zone medium name (Ignores daylight savings. Excludes location): "Pacific Time"
		 */
		TimezoneMediumName: {
			name: 'timezoneMediumName',
			value: state => {
				const timeZoneId = safeGetSetting(state, Settings.User.Timezone)
				const timeZoneInfo = safeGetNestedProp(state, 'sphere.timeZoneInfo.timeZones')
				if (timeZoneId && timeZoneInfo && timeZoneInfo[timeZoneId]) {
					return timeZoneInfo[timeZoneId].mediumName
				} else {
					return ''
				}
			},
			propType: PropTypes.string,
		},
	},
}

export default Settings
