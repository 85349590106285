/*
	FONTAWESOME ICON DEFINITIONS
	- REGULAR VERSION -
*/

/*eslint no-unused-vars: "error"*/

// Please try to keep this sorted alphabetically

// NOTE: Switch this to regular imports when webpack 4 upgrades it's minifier. https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking#issues

import { faAward } from '@fortawesome/pro-regular-svg-icons/faAward'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment'
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons/faCommentLines'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faExclamationSquare } from '@fortawesome/pro-regular-svg-icons/faExclamationSquare'
import { faFrown } from '@fortawesome/pro-regular-svg-icons/faFrown'
import { faGlobeAmericas } from '@fortawesome/pro-regular-svg-icons/faGlobeAmericas'
import { faHeart } from '@fortawesome/pro-regular-svg-icons/faHeart'
import { faReply } from '@fortawesome/pro-regular-svg-icons/faReply'
import { faRetweet } from '@fortawesome/pro-regular-svg-icons/faRetweet'
import { faRocket } from '@fortawesome/pro-regular-svg-icons/faRocket'
import { faShare } from '@fortawesome/pro-regular-svg-icons/faShare'
import { faThLarge } from '@fortawesome/pro-regular-svg-icons/faThLarge'
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'

export default {
	faAward,
	faCalendarAlt,
	faCircle,
	faComment,
	faCommentLines,
	faEllipsisV,
	faExclamationSquare,
	faFrown,
	faGlobeAmericas,
	faHeart,
	faReply,
	faRetweet,
	faRocket,
	faShare,
	faThLarge,
	faThumbsUp,
	faTimes,
	faTrash,
}
