import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Helpers
import Routes from 'helpers/routes'
import { vsApi, requests } from 'helpers/apiHelpers'
import { dispatchIfNotImpersonating } from 'helpers/impersonationHelpers'

// Enums
import DisplayModes from './enums/customLinkDisplayModes'

// Actions
import { togglePagePopup } from 'reducers/browser.reducer'

//#region Styles
import { color, fontSize } from 'styles/variables'

const styles = {
	text: {
		fontSize: fontSize.small,
		color: color.primaryBlack,
		textDecoration: 'none',
		cursor: 'pointer',
	},
}
//#endregion

const mapDispatchToProps = dispatch => {
	return {
		togglePopup: customPageId => dispatch(togglePagePopup(customPageId)),
		recordClick: (id, location) =>
			dispatchIfNotImpersonating(
				vsApi({
					endpoint: requests.recordCustomLinkClick.getEndpoint(id),
					requestOptions: requests.recordCustomLinkClick.getRequestOptions(id, location),
				})
			),
	}
}

class CustomLink extends Component {
	static propTypes = {
		link: PropTypes.object.isRequired,
		style: PropTypes.object,
		location: PropTypes.string,
		onClick: PropTypes.func,
		togglePopup: PropTypes.func,
		recordClick: PropTypes.func,
		render: PropTypes.any,
	}

	handleClick = event => {
		const { onClick } = this.props

		this.recordEvent()

		if (onClick) onClick(event)
	}

	recordEvent = () => {
		const { link, recordClick, location } = this.props

		recordClick(link.id, location)
	}

	togglePopup = () => {
		const { link: { customPageId } = {}, togglePopup, onClick } = this.props
		this.recordEvent()
		togglePopup(customPageId)
		if (onClick) onClick()
	}

	render() {
		const { link, style: styleOverride, render } = this.props
		const dynamicStyles = {
			linkText: {
				...styles.text,
				...styleOverride,
			},
		}
		const isPopup = link.displayMode === DisplayModes.popup

		return (
			<Fragment>
				{link.customPageId ? (
					isPopup ? (
						<div style={dynamicStyles.linkText} onClick={this.togglePopup}>
							{render}
						</div>
					) : (
						<Link
							to={Routes.CustomPage.generateLinkPath(link.customPageId)}
							style={dynamicStyles.linkText}
							onClick={this.handleClick}
						>
							{render}
						</Link>
					)
				) : (
					<a
						href={link.url}
						style={dynamicStyles.linkText}
						rel="noopener noreferrer"
						target={link.url.toLowerCase().indexOf('mailto:') === 0 ? '_self' : '_blank'}
						onClick={this.handleClick}
					>
						{render}
					</a>
				)}
			</Fragment>
		)
	}
}

export default connect(
	null,
	mapDispatchToProps
)(CustomLink)
