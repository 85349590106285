import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

// Components
import TextButton from 'components/TextButton/TextButton'
import FormErrors from 'components/Formik/FormErrors'
import FormSuccess from 'components/Formik/FormSuccess'
import ShareText from 'scenes/ShareDialogV8/components/ShareText'
import SocialIcon, { SocialIconSize } from 'components/SocialIcon/SocialIcon'
import PostTranslationButton from 'scenes/Post/components/PostTranslationButton'
import { Backdrop } from 'components/Icons/FontAwesome'
import Button, { ButtonLevel } from 'components/Button/Button'
import Icon, { Name, Modifier } from 'components/Icons/FontAwesome'

// Helpers
import SocialProviderData from 'helpers/socialProviderData'

// Lang
import lang from './shareChannels.lang'
import buttons from 'helpers/lang/buttons.lang'

// Enums
import ShareTextFormik from '../enums/shareTextFormikKeys'
import ShareDialogFlows from '../enums/shareDialogFlows'
import ShareTextKeys from '../enums/shareTextKeys'

// Styles
import styles from './ShareBody.styles'

class ShareBody extends React.Component {
	static propTypes = {
		userChannels: PropTypes.array,
		userChannelIds: PropTypes.array,
		reactionType: PropTypes.object,
		isTwitterEnabled: PropTypes.bool,
		isLinkedInEnabled: PropTypes.bool,
		showShareChannels: PropTypes.bool,
		handleClose: PropTypes.func,
		setSelectedChannel: PropTypes.func,
		replaceUserChannelIds: PropTypes.func,
		reloadUser: PropTypes.func,
		authUserChannel: PropTypes.func,
		storeFormikValues: PropTypes.func,
		allowShareDisclosures: PropTypes.bool,
		isFacebookPageEnabled: PropTypes.bool,
		isNativeVideo: PropTypes.bool,
		errorState: PropTypes.object,
		formikValues: PropTypes.object,
		directShareProviders: PropTypes.object,

		primaryColor: PropTypes.string,
		viewport: PropTypes.object,
		post: PropTypes.object,
	}

	state = {
		errorBanner: null,
		successBanner: '',
	}

	componentDidMount() {
		const { userChannels, post, storeFormikValues } = this.props
		let initialValues = this.initialValues

		if (post.suggestedShareTextList && post.shortSuggestedShareTextList) {
			initialValues = this.formatInitialValues(
				userChannels,
				post.suggestedShareTextList,
				post.shortSuggestedShareTextList
			)
		}

		storeFormikValues(initialValues)
	}

	componentDidUpdate(prevProps) {
		// We pass an error message from the parent component so we can handle it here.
		if (prevProps.errorState !== this.props.errorState) {
			this.setErrorMessage(this.props.errorState)
			setTimeout(() => this.clearErrorMessage, 5000)
		}
	}

	initialValues = {
		// Twitter
		[ShareTextFormik.twitterConnectedAccounts]: [],
		[ShareTextFormik.twitterShareTextList]: [],
		[ShareTextFormik.twitterShareText]: '',

		// LinkedIn + Other Accounts
		[ShareTextFormik.linkedInConnectedAccounts]: [],
		[ShareTextFormik.linkedInShareTextList]: [],
		[ShareTextFormik.linkedInShareText]: '',

		// Facebook Page - No prefilled text, or suggested shares allowed.
		[ShareTextFormik.facebookPageConnectedAccounts]: [],
		[ShareTextFormik.facebookPageShareText]: '',
	}

	formatInitialValues = (
		userChannels,
		linkedInAndOthersSuggestedShareText = [],
		twitterSuggestedShareText = []
	) => {
		const { userChannelIds, formikValues } = this.props
		if (
			!userChannelIds ||
			!userChannels ||
			Object.keys(userChannels).length === 0 ||
			userChannelIds.length === 0
		) {
			return this.initialValues
		}

		let twitterAccounts = []
		let facebookPageAccounts = []
		let linkedInAndOtherAccounts = []

		const selectedUserChannels = userChannelIds.map(id => {
			const match = userChannels.find(channel => channel.userChannelId === id)
			if (match) {
				return match
			} else {
				return null
			}
		})

		selectedUserChannels.forEach(userChannel => {
			if (userChannel && userChannel.userChannelId) {
				if (userChannel.provider === SocialProviderData.Twitter.provider) {
					twitterAccounts.push(userChannel.userChannelId)
				} else if (userChannel.provider === SocialProviderData.FacebookPage.provider) {
					facebookPageAccounts.push(userChannel.userChannelId)
				} else {
					linkedInAndOtherAccounts.push(userChannel.userChannelId)
				}
			}
		})

		// Set initial values as a flat object. This makes it easier to update, since Formik's values
		// are a shallow copy of initialValues.
		this.initialValues.connectedTwitterAccountIds =
			twitterAccounts.length !== 0 ? [twitterAccounts.shift()] : twitterAccounts // Can only be one
		this.initialValues.suggestedTwitterShareTextList = twitterSuggestedShareText
		this.initialValues.twitterShareText =
			(formikValues ? formikValues.twitterShareText : false) || twitterSuggestedShareText[0] || ''

		this.initialValues.suggestedLinkedInAndOthersShareTextList = linkedInAndOthersSuggestedShareText
		this.initialValues.connectedLinkedInAndOthersAccountIds = linkedInAndOtherAccounts
		this.initialValues.linkedInAndOthersShareText =
			(formikValues ? formikValues.linkedInAndOthersShareText : false) ||
			linkedInAndOthersSuggestedShareText[0] ||
			''

		this.initialValues.connectedFacebookPageAccountIds = facebookPageAccounts

		return this.initialValues
	}

	handleShowAddChannels = values => {
		const { handleClose, replaceUserChannelIds, storeFormikValues } = this.props

		// Remember current channel selection and share text for when we return to this dialog
		replaceUserChannelIds([
			...values.connectedTwitterAccountIds,
			...values.connectedLinkedInAndOthersAccountIds,
			...values.connectedFacebookPageAccountIds,
		])
		storeFormikValues(values)

		// Close this dialog, open Edit Accounts
		handleClose(ShareDialogFlows.mainShareFlow, ShareDialogFlows.editChannelsFlow)
	}

	handleShareButtonDisabling = values => {
		const {
			userChannelIds,
			setSelectedChannel,
			replaceUserChannelIds,
			storeFormikValues,
		} = this.props

		// Submit formik values to parent component
		storeFormikValues(values)

		// Check to see if selected account IDs exist or not, so we can inform the ShareButton to be disabled/enabled.
		if (
			values.connectedTwitterAccountIds.length === 0 &&
			values.connectedLinkedInAndOthersAccountIds.length === 0 &&
			values.connectedFacebookPageAccountIds.length === 0
		) {
			replaceUserChannelIds(null)
		} else if (
			(values.connectedTwitterAccountIds.length !== 0 ||
				values.connectedLinkedInAndOthersAccountIds.length !== 0 ||
				values.connectedFacebookPageAccountIds.length !== 0) &&
			userChannelIds.length === 0
		) {
			const selectedChannels = [
				...values.connectedTwitterAccountIds,
				...values.connectedLinkedInAndOthersAccountIds,
				...values.connectedFacebookPageAccountIds,
			]
			setSelectedChannel(selectedChannels)
		}
	}

	openSharingDisclosures = values => {
		const { handleClose, replaceUserChannelIds, storeFormikValues } = this.props

		// Remember current channel selection and share text for when we return to this dialog
		replaceUserChannelIds([
			...values.connectedTwitterAccountIds,
			...values.connectedLinkedInAndOthersAccountIds,
			...values.connectedFacebookPageAccountIds,
		])
		storeFormikValues(values)

		handleClose(ShareDialogFlows.mainShareFlow, ShareDialogFlows.sharingDisclosuresFlow)
	}

	setErrorMessage = error => this.setState({ errorBanner: error })

	clearErrorMessage = () => this.setState({ errorBanner: null })

	setSuccessMessage = successMessage => this.setState({ successBanner: successMessage })

	clearSuccessMessage = () => this.setState({ successBanner: '' })

	renderEditAccountsButton = (userChannels, primaryColor, values) => {
		const {
			viewport,
			showShareChannels: showChannels,
			isTwitterEnabled,
			isLinkedInEnabled,
		} = this.props
		const dynamicStyles = {
			noAccountsButton: {
				...styles.noAccountsButton,
				...styles.noAccountsButton[viewport.size],
			},
			noAccountsText: {
				...styles.noAccountsText,
				color: primaryColor,
			},
		}
		const shareChannelsDisabled =
			!showChannels || (!isTwitterEnabled && !isLinkedInEnabled && userChannels.length === 0)

		// No Accounts connected, Twitter and LinkedIn are also disabled.
		if (shareChannelsDisabled) {
			return (
				<div style={styles.noAccountsContainer}>
					<div style={dynamicStyles.noAccountsText}>{lang.noAccountsConnected}</div>
					<Button
						style={dynamicStyles.noAccountsButton}
						onClick={() => this.handleShowAddChannels(values)}
						level={ButtonLevel.secondary}
					>
						<Icon
							style={styles.noAccountsButton.icon}
							modifier={Modifier.fixedWidth}
							color={primaryColor}
							name={Name.edit}
						/>
						{lang.editAccount}
					</Button>
				</div>
			)
		} else {
			const withAccountStyle = {
				...styles.editChannelButton,
				color: primaryColor,
			}
			return (
				<TextButton
					handleClick={() => this.handleShowAddChannels(values)}
					text={lang.editAccount}
					iconName={Name.edit}
					style={withAccountStyle}
				/>
			)
		}
	}

	renderTwitterSection = (values, setFieldValue, setFieldTouched, setFieldError, isSubmitting) => {
		const {
			userChannels,
			primaryColor,
			post,
			reloadUser,
			authUserChannel,
			isNativeVideo,
			directShareProviders,
			viewport,
		} = this.props
		let { isTwitterEnabled } = this.props

		if (
			isNativeVideo &&
			directShareProviders.length > 0 &&
			!directShareProviders.includes(SocialProviderData.Twitter.provider)
		) {
			isTwitterEnabled = false // Disable Twitter if Native Video sharing does not list it as a provider.
		}
		if (!isTwitterEnabled) return null

		const twitterAccounts = userChannels.filter(
			channel => channel.provider === SocialProviderData.Twitter.provider
		)
		const isTwitterConnected = !!twitterAccounts.length

		const dynamicStyles = {
			zeroStateText: {
				...styles.zeroState.text,
				color: primaryColor,
			},
		}

		return isTwitterConnected ? (
			// Twitter - Connected
			<ShareText
				name={ShareTextKeys.Twitter}
				primaryColor={primaryColor}
				viewport={viewport}
				shareTextValue={values[ShareTextFormik.twitterShareText]}
				suggestedTextValue={values[ShareTextFormik.twitterShareTextList]}
				connectedAccounts={twitterAccounts}
				selectedAccountsIds={values[ShareTextFormik.twitterConnectedAccounts]}
				setFieldValue={setFieldValue}
				setFieldTouched={setFieldTouched}
				setFieldError={setFieldError}
				isSubmitting={isSubmitting}
				authUserChannel={authUserChannel}
				reloadUser={reloadUser}
				twitterMax={post.twitterMaxCharCount}
				setErrorMessage={this.setErrorMessage}
				clearErrorMessage={this.clearErrorMessage}
				setSuccessMessage={this.setSuccessMessage}
			/>
		) : (
			// Twitter - Zero State
			<div style={styles.zeroState} onClick={() => this.handleShowAddChannels(values)}>
				<SocialIcon
					size={SocialIconSize.post}
					provider={SocialProviderData.Twitter.provider}
					style={styles.zeroState.icon}
					backdrop={Backdrop.circle}
				/>
				<div style={dynamicStyles.zeroStateText}>{lang.connectToTwitter}</div>
			</div>
		)
	}

	renderFacebookPageSection = (
		values,
		setFieldValue,
		setFieldTouched,
		setFieldError,
		isSubmitting
	) => {
		const {
			userChannels,
			primaryColor,
			reloadUser,
			authUserChannel,
			isNativeVideo,
			directShareProviders,
			viewport,
		} = this.props
		const facebookPageAccounts = userChannels.filter(
			channel => channel.provider === SocialProviderData.FacebookPage.provider
		)
		const isFacebookPageConnected = !!facebookPageAccounts.length
		let { isFacebookPageEnabled } = this.props
		if (
			isNativeVideo &&
			directShareProviders.length > 0 &&
			!directShareProviders.includes(SocialProviderData.FacebookPage.provider)
		) {
			isFacebookPageEnabled = false // Disable Facebook Page if Native Video sharing does not list it as a provider.
		}

		if (!isFacebookPageEnabled || !isFacebookPageConnected) return null

		return (
			<ShareText
				name={ShareTextKeys.FacebookPage}
				primaryColor={primaryColor}
				viewport={viewport}
				connectedAccounts={facebookPageAccounts}
				selectedAccountsIds={values[ShareTextFormik.facebookPageConnectedAccounts]}
				setFieldValue={setFieldValue}
				setFieldTouched={setFieldTouched}
				setFieldError={setFieldError}
				isSubmitting={isSubmitting}
				shareTextValue={values[ShareTextFormik.facebookPageShareText]}
				authUserChannel={authUserChannel}
				reloadUser={reloadUser}
				setErrorMessage={this.setErrorMessage}
				clearErrorMessage={this.clearErrorMessage}
				setSuccessMessage={this.setSuccessMessage}
			/>
		)
	}

	renderLinkedInSection = (values, setFieldValue, setFieldTouched, setFieldError, isSubmitting) => {
		const {
			userChannels,
			primaryColor,
			reloadUser,
			authUserChannel,
			isLinkedInEnabled: communityLinkedInEnabled,
			reactionType,
			directShareProviders,
			isNativeVideo,
			viewport,
		} = this.props
		const otherAccounts = userChannels.filter(
			channel =>
				channel.provider !== SocialProviderData.Twitter.provider &&
				channel.provider !== SocialProviderData.FacebookPage.provider
		)
		let isLinkedInEnabled = communityLinkedInEnabled && !(reactionType && reactionType.reactionType)
		if (
			isNativeVideo &&
			directShareProviders.length > 0 &&
			!directShareProviders.includes(SocialProviderData.LinkedIn.provider)
		) {
			isLinkedInEnabled = false // Disable LinkedIn if Native Video sharing does not list it as a provider.
		}

		const isLinkedInConnected = otherAccounts.some(
			userChannel => userChannel.provider === SocialProviderData.LinkedIn.provider
		)
		const dynamicStyles = {
			zeroStateText: {
				...styles.zeroState.text,
				color: primaryColor,
			},
		}

		return (
			<Fragment>
				{/* LinkedIn - Zero State */}
				{isLinkedInEnabled &&
					!isLinkedInConnected && (
						<div style={styles.zeroState} onClick={() => this.handleShowAddChannels(values)}>
							<SocialIcon
								size={SocialIconSize.post}
								provider={SocialProviderData.LinkedIn.provider}
								style={styles.zeroState.icon}
								backdrop={Backdrop.circle}
							/>
							<div style={dynamicStyles.zeroStateText}>{lang.connectToLinkedIn}</div>
						</div>
					)}
				{/* LinkedIn + Other Accounts */}
				{otherAccounts.length > 0 && (
					<ShareText
						name={ShareTextKeys.LinkedIn}
						primaryColor={primaryColor}
						viewport={viewport}
						shareTextValue={values[ShareTextFormik.linkedInShareText]}
						suggestedTextValue={values[ShareTextFormik.linkedInShareTextList]}
						connectedAccounts={otherAccounts}
						selectedAccountsIds={values[ShareTextFormik.linkedInConnectedAccounts]}
						setFieldValue={setFieldValue}
						isLinkedInConnected={isLinkedInConnected}
						setFieldTouched={setFieldTouched}
						setFieldError={setFieldError}
						isSubmitting={isSubmitting}
						authUserChannel={authUserChannel}
						reloadUser={reloadUser}
						setErrorMessage={this.setErrorMessage}
						clearErrorMessage={this.clearErrorMessage}
						setSuccessMessage={this.setSuccessMessage}
						clearSuccessMessage={this.clearSuccessMessage}
					/>
				)}
			</Fragment>
		)
	}

	render() {
		const {
			userChannels,
			viewport,
			showShareChannels,
			primaryColor,
			post,
			allowShareDisclosures,
		} = this.props
		const { errorBanner: errors, successBanner } = this.state

		let initialValues = this.initialValues
		if (post.suggestedShareTextList && post.shortSuggestedShareTextList) {
			initialValues = this.formatInitialValues(
				userChannels,
				post.suggestedShareTextList,
				post.shortSuggestedShareTextList
			)
		}

		const dynamicStyles = {
			sharingDisclosuresText: {
				...styles.sharingDisclosures.text,
				color: primaryColor,
			},
			alert: {
				...styles.alert,
				...styles.alert[viewport.size],
			},
		}

		return (
			<Fragment>
				<div style={styles.container}>
					<Formik
						initialValues={initialValues}
						validate={this.handleShareButtonDisabling}
						onSubmit={() => null} // Formik complains if we don't put something here...
						render={({
							values,
							touched,
							errors: formikErrors,
							setFieldTouched,
							setFieldValue,
							setFieldError,
							isSubmitting,
						}) => (
							<Fragment>
								<div style={styles.actionBar}>
									<PostTranslationButton
										style={styles.postTranslationButton}
										isTranslated={post.isTranslated}
										isTranslatable={post.userTranslatable}
										postId={post.postId}
									/>
									{this.renderEditAccountsButton(userChannels, primaryColor, values)}
								</div>
								<Form style={styles.form}>
									{/* Error Banner */}
									<FormErrors
										errors={errors || formikErrors}
										style={dynamicStyles.alert}
										touched={touched}
									/>
									{/* Success Banner */}
									<FormSuccess
										isOpen={successBanner}
										autoClose
										autoCloseTime={3}
										onClose={this.clearSuccessMessage}
										style={dynamicStyles.alert}
									>
										{successBanner}
									</FormSuccess>
									{/* Share Channels */}
									{showShareChannels && (
										<Fragment>
											{this.renderTwitterSection(
												values,
												setFieldValue,
												setFieldTouched,
												setFieldError,
												isSubmitting
											)}
											{this.renderLinkedInSection(
												values,
												setFieldValue,
												setFieldTouched,
												setFieldError,
												isSubmitting
											)}
											{this.renderFacebookPageSection(
												values,
												setFieldValue,
												setFieldTouched,
												setFieldError,
												isSubmitting
											)}
											{/* Share Disclosures Dialog toggle */}
											{allowShareDisclosures && (
												<div style={styles.sharingDisclosures}>
													{lang.sharingDisclosure}{' '}
													<a
														style={dynamicStyles.sharingDisclosuresText}
														onClick={() => this.openSharingDisclosures(values)}
													>
														{buttons.view}
													</a>
												</div>
											)}
										</Fragment>
									)}
								</Form>
							</Fragment>
						)}
					/>
				</div>
			</Fragment>
		)
	}
}

export default ShareBody
