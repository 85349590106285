import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { stringIsEmptyOrWhitespace, safeGetNestedProp } from '@dysi/js-helpers'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Components
import PanelTitle from './PanelTitle'
import Section from './Section'

// Styles
import { width, color, border } from 'styles/variables'

const styles = {
	panel: {
		maxWidth: width.layout.main,
		backgroundColor: color.white,

		borderTop: border.basic,
		borderBottom: border.basic,
		borderRight: border.basic,
		borderLeft: border.basic,

		paddingTop: width.spacing.basic,
		paddingBottom: width.spacing.basic,
		paddingRight: width.spacing.basic,
		paddingLeft: width.spacing.basic,

		marginBottom: width.spacing.basic,
		marginTop: 0,
		marginLeft: 0,
		marginRight: 0,

		[BreakpointNames.small]: {
			borderRight: 'none',
			borderLeft: 'none',

			marginTop: width.spacing.basic,

			paddingLeft: 0,
			paddingRight: 0,
		},

		pageDialog: {
			marginTop: 0,
			marginBottom: 0,
			borderTop: 'none',
			borderBottom: 'none',
			borderLeft: 'none',
			borderRight: 'none',
		},
	},
}

class Panel extends React.Component {
	static propTypes = {
		children: PropTypes.any.isRequired,
		history: PropTypes.object,
		panelTitle: PropTypes.string,
		panelLinks: PropTypes.any,
		panelDescription: PropTypes.string,
		style: PropTypes.any,
		viewport: responsive.PropType,
	}

	render() {
		const { panelTitle, panelLinks, panelDescription, style, viewport, history } = this.props
		let { children } = this.props
		const showPageDialog = safeGetNestedProp(history, 'location.state.showPageDialog', false)
		const panelStyle = {
			...styles.panel,
			...styles.panel[viewport.size],
			...(showPageDialog && styles.panel.pageDialog),
			...style,
		}

		let addBorderToTitle = false

		// Remove some styles if this panel leads with a Section
		// I made the logic kind of odd so that we're not changing children needlessly
		if (stringIsEmptyOrWhitespace(panelTitle)) {
			const childArray = React.Children.toArray(children)
			// Make sure it's a Section
			if (childArray && childArray[0] && childArray[0].type === Section) {
				const firstChild = childArray[0]
				const updatedChildStyle = {
					...firstChild.props.style,
					borderTop: 'none',
					paddingTop: 0,
					marginTop: 0,
				}
				childArray[0] = React.cloneElement(firstChild, { style: updatedChildStyle })
				children = childArray
			}
		} else {
			const childArray = React.Children.toArray(children)
			if (childArray && childArray[0] && childArray[0].type !== Section) {
				addBorderToTitle = true
			}
		}

		return (
			<div style={panelStyle}>
				{!stringIsEmptyOrWhitespace(panelTitle) && (
					<PanelTitle
						text={panelTitle}
						description={panelDescription}
						links={panelLinks}
						hasBorder={addBorderToTitle}
					/>
				)}
				<div>{children}</div>
			</div>
		)
	}
}

export default withRouter(responsive(Panel))
