const lang = {
	bookmarks: 'Bookmarks',
	broadcasts: 'Broadcasts',
	betaLink: 'Experimental Features',
	directory: 'Directory',
	downloads: 'Downloads',
	home: 'Home',
	invite: 'Invite',
	manageCommunity: 'Manage Community',
	memberHelp: 'Member Help',
	messages: 'Messages',
	notifications: 'Notifications',
	pointValue: points => `${points} Points`, //  points = the number of points that the current user has.
	pointOneValue: '1 Point',
	savedItems: 'Saved Items',
	settings: 'Settings',
	signIn: 'Sign In',
	signOut: 'Sign Out',
	submitPost: '+ Submit Post',
	userProfileDefault: 'Your Profile', //  when user has no display name
	userProfile: name => `${name}\'s Profile`, //  name = the name of the current user
}

export default lang;