import React from 'react'
import PropTypes from 'prop-types'

// Styles
import { color } from 'styles/variables'

const styles = {
	base: {},
}

class PercentCompleteCircle extends React.Component {
	constructor(props) {
		super(props)

		this.canvas = null
	}

	componentDidMount() {
		this.draw()
	}

	componentDidUpdate(prevProps) {
		this.draw()
	}

	// Draw the percent complete circle inside our canvas
	draw() {
		const { width, height, total, completeColor, incompleteColor, lineWidth } = this.props
		let { complete } = this.props

		if (width < 1 || height < 1 || total < 1 || complete < 0) return // Invalid props
		if (complete > total) complete = total

		const context = this.canvas.getContext('2d')
		const radius = Math.floor(width / 2 - lineWidth / 2)
		const centerX = Math.floor(width / 2)
		const centerY = Math.floor(height / 2)
		const fullCircle = 2 * Math.PI
		let start = 1.5 * Math.PI // Top: 1.5 PI (Radian circle starts at 0PI on right (East)
		let end = start + (fullCircle / total) * complete // Continue end around circle 1.5 -> 3.5
		//const percentComplete = Math.floor(complete / total * 100); - FUTURE: To show percent number inside circle
		if (end > fullCircle) end -= fullCircle // Normalize to radians around the circle: NESW from top: 1.5PI -> 0PI -> .5PI -> 1PI
		if (start === end || complete === total) {
			start = 0
			end = Math.PI * 2
		} // Complete circle

		context.save()

		// Start clean
		context.clearRect(0, 0, width, height)

		// Draw incomplete circle
		context.lineWidth = lineWidth
		context.beginPath()
		context.arc(centerX, centerY, radius, 0, fullCircle)
		context.strokeStyle = incompleteColor
		context.stroke()

		if (complete > 0) {
			// Draw percent complete
			context.beginPath()
			context.arc(centerX, centerY, radius, start, end)
			context.strokeStyle = completeColor
			context.stroke()
		}

		context.restore()
	}

	render() {
		const { style, width, height } = this.props

		const baseStyle = {
			...styles.base,
			...style,
		}

		return (
			<canvas
				style={baseStyle}
				ref={canvas => (this.canvas = canvas)}
				width={width}
				height={height}
			/>
		)
	}
}

PercentCompleteCircle.propTypes = {
	style: PropTypes.object, // CSS style overrides
	width: PropTypes.number, // Width of container
	height: PropTypes.number, // Height of container
	total: PropTypes.number, // Number possible
	complete: PropTypes.number, // Number achieved
	completeColor: PropTypes.string, // Color used to fill complete portion of circle
	incompleteColor: PropTypes.string, // Color used to fill incomplete portion of circle
	lineWidth: PropTypes.number, // Thickness of line around the circle, in pixels. Default: 1
}

PercentCompleteCircle.defaultProps = {
	width: 40,
	height: 40,
	total: 100,
	complete: 0,
	completeColor: color.secondaryBlue,
	incompleteColor: color.secondaryGrey,
	lineWidth: 2,
}

export default PercentCompleteCircle
