import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon, { Name } from 'components/Icons/FontAwesome'
import { Link } from 'react-router-dom'

// Components
import {
	Dropdown,
	DropdownTrigger,
	DropdownMenu,
	DropdownMenuAlignment,
} from 'components/Dropdown/Dropdown'
import NotificationList, { minimalNotificationCount } from './NotificationList'

// Enums
import { UserNotificationLayout } from 'components/UserNotification/UserNotification'

// Lang
import lang from './notificationDropdown.lang'

// Styles
import styles from './NotificationDropdown.styles'

// Helpers
import Routes from 'helpers/routes'

class NotificationDropdown extends Component {
	static propTypes = {
		currentUserId: PropTypes.number,
		getNotifications: PropTypes.func,
		isNotificationRequestPending: PropTypes.bool,
		newNotificationCount: PropTypes.number,
		notifications: PropTypes.object,
		primaryColor: PropTypes.string,
	}

	render() {
		const {
			currentUserId,
			getNotifications,
			isNotificationRequestPending,
			newNotificationCount,
			notifications,
			primaryColor,
		} = this.props
		// Only show if there are more to to see
		const showSeeAll = notifications.ids.length > minimalNotificationCount
		const dynamicStyles = {
			link: {
				color: primaryColor,
			},
		}

		return (
			<Dropdown>
				<DropdownTrigger>
					<div onClick={getNotifications} style={styles.links.link}>
						<Icon name={Name.bell} style={styles.links.icon} count={newNotificationCount} />
						{lang.notifications}
					</div>
				</DropdownTrigger>
				<DropdownMenu align={DropdownMenuAlignment.right} style={styles.dropdown.menu}>
					<div style={styles.dropdown}>
						<div style={styles.header}>
							<div style={styles.title}>{lang.notifications}</div>
							<Link to={Routes.Settings.Notifications.linkPath} style={dynamicStyles.link}>
								{lang.settings}
							</Link>
						</div>
						<NotificationList
							currentUserId={currentUserId}
							layout={UserNotificationLayout.minimal}
							notifications={notifications}
							isLoading={isNotificationRequestPending}
						/>
						{showSeeAll && (
							<div style={styles.seeAll}>
								<Link style={dynamicStyles.link} to={Routes.Notifications.linkPath}>
									{lang.seeAll}
								</Link>
							</div>
						)}
					</div>
				</DropdownMenu>
			</Dropdown>
		)
	}
}

export default NotificationDropdown
