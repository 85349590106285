// ----------------------------------------------
// Dropdown
// ----------------------------------------------
// Import like this:
//
//		import { Dropdown, DropdownTrigger, DropdownMenu } from '/components/shared/Dropdown'
//
// Use like this:
//
//		<Dropdown>
//			<DropdownTrigger>
//				<img src={profilePic} alt="Profile Menu"/>
//			</DropdownTrigger >
//			<DropdownMenu align="right">
//				<ul style={listUnstyled}>
//					<li><a onClick={onSignOut}>Sign Out</a></li>
//				</ul>
//			</DropdownMenu>
//		</Dropdown>
// ----------------------------------------------
import React from 'react'
import PropTypes from 'prop-types'

// Helpers
import KeyNames from 'helpers/keyNames'

// Lang
import lang from './dropdown.lang'

// Styles
import { color, width, border, textButtonStyle } from 'styles/variables'

const styles = {
	base: {
		position: 'relative',
		display: 'inline-block',
		lineHeight: 1,
		...textButtonStyle,
	},

	menu: {
		position: 'absolute',
		zIndex: 1000,
		whiteSpace: 'nowrap',
		left: '0',
		top: 'auto',
		padding: `${width.spacing.narrow}px ${width.spacing.basic}px`,
		background: color.white,
		border: border.basic,
		borderRadius: border.radius,
	},

	menuRight: {
		left: 'auto',
		right: '0',
	},
}

// Constants
const DropdownMenuAlignment = {
	right: 'right',
	left: 'left',
}

// DropdownTrigger
class DropdownTrigger extends React.Component {
	render() {
		return (
			// NOTE: className used when rendering Dropdown component
			<div className="DropdownTrigger" style={{ cursor: 'pointer' }}>
				{this.props.children}
			</div>
		)
	}
}

DropdownTrigger.propTypes = {
	children: PropTypes.node,
}

// DropdownMenu
class DropdownMenu extends React.Component {
	static propTypes = {
		dropdownOnTop: PropTypes.bool,
		triggerHeight: PropTypes.number,
		style: PropTypes.object,
		children: PropTypes.node,
		align: PropTypes.oneOf(Object.values(DropdownMenuAlignment)),
		setRef: PropTypes.func,
	}

	static defaultProps = {
		align: DropdownMenuAlignment.left,
	}

	render() {
		const { align, style: styleOverride, triggerHeight, dropdownOnTop } = this.props

		// Style
		let style = {
			...styles.menu,
			...(align === DropdownMenuAlignment.right && styles.menuRight),
			...(!!dropdownOnTop && { bottom: triggerHeight, top: 'auto' }),
			...styleOverride,
		}

		return (
			// NOTE: className used when rendering Dropdown component
			<div className="DropdownMenu" style={style} ref={this.props.setRef}>
				{this.props.children}
			</div>
		)
	}
}

// Dropdown
class Dropdown extends React.Component {
	state = {
		showMenu: false,
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)

		// For dropdown menu on top
		const triggerHeight = this.ref && this.ref.getBoundingClientRect().height
		if (triggerHeight) this.setState({ triggerHeight })
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClick = e => {
		e.stopPropagation()

		this.setState(prevState => ({ showMenu: !prevState.showMenu }))
	}

	handleKeyDown = e => {
		if (e.key === KeyNames.space || e.key === KeyNames.enter || e.key === KeyNames.arrowDown) {
			e.stopPropagation()
			// Show the menu
			this.setState({ showMenu: true })
		} else if (e.key === KeyNames.escape) {
			e.preventDefault()
			e.stopPropagation()
			// Hide the menu
			this.setState({ showMenu: false })
		}
	}

	handleClickOutside = event => {
		if (this.ref && !this.ref.contains(event.target)) {
			this.setState({ showMenu: false })
		}
	}

	setRef = node => {
		this.ref = node
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	return nextState.showMenu !== this.state.showMenu
	// }

	render() {
		const { triggerHeight } = this.state

		let trigger = null
		let menu = null

		// Enhance/Render children as appropriate
		React.Children.forEach(
			this.props.children,
			function(child) {
				// Radium-enhanced classes store class name in displayName
				const isDropdownTrigger =
					child.type === DropdownTrigger ||
					(child.type.displayName && child.type.displayName === 'DropdownTrigger')
				const isDropdownMenu =
					child.type === DropdownMenu ||
					(child.type.displayName && child.type.displayName === 'DropdownMenu')

				if (isDropdownTrigger) {
					trigger = child
				} else if (isDropdownMenu) {
					menu = React.cloneElement(child, { triggerHeight }) // Only show menu when showMenu state is true
				}
			},
			this
		)

		return (
			<div
				id={this.props.id}
				data-component="Dropdown"
				style={styles.base}
				ref={this.setRef}
				onClick={this.handleClick}
				onKeyDown={this.handleKeyDown}
				tabIndex="0"
				className="accessible-focus"
				aria-label={lang.dropdownMenu}
			>
				{trigger}
				{this.state.showMenu && menu}
			</div>
		)
	}
}

Dropdown.propTypes = {
	/** Optional ID */
	id: PropTypes.string,
	// children: PropTypes.node, // DropdownTrigger and DropdownMenu
	children: (props, propName, componentName) => {
		const children = props[propName]

		if (React.Children.count(children) !== 2) {
			return new Error(componentName + ' must contain a DropdownTrigger and a DropdownMenu')
		}

		React.Children.forEach(children, function(child) {
			if (child.type !== DropdownTrigger && child.type !== DropdownMenu) {
				return new Error(
					'DropdownTrigger and DropdownMenu are the only children allowed in ' + componentName
				)
			}
		})
	},
}

module.exports = {
	DropdownMenuAlignment,
	Dropdown,
	DropdownTrigger,
	DropdownMenu,
}
