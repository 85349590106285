const lang = {
	anonymousUser: 'Anonymous User',
	articleBroadcasts: {
		userSentSurvey: name => `${name} sent you a new Survey`, //  name = user's display name
		newSurvey: 'New Survey',
		startSurvey: 'Start Survey',
		surveyCompleted: 'Survey Completed',
		surveyEnded: 'Survey Ended',
		userSentNewsletter: name => `${name} sent you a new Newsletter`, //  name = user's display name
		newNewsletter: 'New Newsletter',
		userSentBroadcast: name => `${name} sent you a new Broadcast`, //  name = user's display name
		newBroadcast: 'New Broadcast',
		acknowledge: 'Acknowledge',
		acknowledged: 'Acknowledged',
		urgent: 'Urgent:',
		editResponses: 'Edit Responses',
		viewNewsletter: 'View Newsletter',
		userSentQuickPoll: name => `${name} sent you a new Poll`, //  name = user's display name
		quickPoll: 'Quick Poll',
	},
	articleCommentLike: {
		singleLike: firstUser => `${firstUser} liked your comment`, //  firstUser = display name of user who liked comment
		singleLikeWithPoints: (firstUser, points) => `${firstUser} liked your comment and you received ${points} points`, //  points = # of points received for liking a comment.
		doubleLike: (firstUser, secondUser) => `${firstUser} and ${secondUser} liked your comment`, //  firstUser, secondUser = display names of users who liked comment
		doubleLikeWithPoints: (firstUser, secondUser, points) => `${firstUser} and ${secondUser} liked your comment and you received ${points} points`, //  points = # of points received for liking a comment.
		manyLike: (firstUser, secondUser, remainder) => `${firstUser}, ${secondUser}, and ${remainder} others liked your comment`, //  firstUser, secondUser = display names of users who liked comment, remainder = number of other users who liked comment
		manyLikeWithPoints: (firstUser, secondUser, remainder, points) => `${firstUser} and ${secondUser}, and ${remainder} others liked your comment and you received ${points} points`, //  points = # of points received for liking a comment.
	},
	articleCommentReply: {
		singleReply: firstUser => `${firstUser} replied to your comment`, //  firstUser = display name of user who replied to the comment
		doubleReply: (firstUser, secondUser) => `${firstUser} and ${secondUser} replied to your comment`, //  firstUser, secondUser = display names of users who replied to the comment
		manyReply: (firstUser, secondUser, remainder) => `${firstUser}, ${secondUser}, and ${remainder} others replied to your comment`, //  firstUser, secondUser = display names of users who replied to the comment, remainder = number of other users who replied to comment
	},
	awards: {
		availableTitle: 'New Badge Available',
		awardedTitle: 'New Badge Awarded',
		headlineEarned: awardTitle => `You\'ve been awarded ${awardTitle}`, //  awardTitle = The title of the award/badge given to the user
		headlineAvailable: 'There is a new badge available on your profile',
		viewBadge: 'View Badge',
		awardTitleFallback: 'a new badge',
	},
	postApproval: {
		title: 'Congratulations! Your submitted post is approved.',
	},
	postAutoNotification: {
		title: 'New Post',
	},
	milestone: {
		title: 'New Milestone',
	},
	userMention: {
		title: name => `${name} @mentioned you in a comment.`, //  name = display name of user who mentioned current user
	},
	view: 'View',
	workflow: {
		title: 'New Notification',
	},
	failedShare: {
		title: 'Your post has failed to share',
		description: (postName, channelName) => `Your recent share of ${postName} on ${channelName} has failed`,
	},
	cappedShare: {
		title: 'Your post has failed to share',
		description: (postName, channelName) => `Your scheduled share of ${postName} on ${channelName} has failed due to you exceeding one or more sharing caps set by your administrator.`,
	},
	socialChannelRenewal: {
		title: 'Social Account Reconnection Required',
		description: socialChannelName => `Your ${socialChannelName} profile is currently disconnected from the platform. Please reconnect to continue sharing.`,
		manageSocialChannel: 'Manage Social Accounts',
	},
}

export default lang;