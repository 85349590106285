// Helpers
import { vsApi, requests } from 'helpers/apiHelpers'

// Data wrappers
import TranslationButtonData, {
	TranslationPending,
	TranslationError,
} from './translations.data'

// Actions (from other reducers)

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below
const FETCH_TRANSLATION_REQUEST = 'FETCH_TRANSLATION_REQUEST'
const FETCH_TRANSLATION_SUCCESS = 'FETCH_TRANSLATION_SUCCESS'
const FETCH_TRANSLATION_FAILURE = 'FETCH_TRANSLATION_FAILURE'
const SET_TRANSLATION = 'SET_TRANSLATION'
const REMOVE_TRANSLATION = 'REMOVE_TRANSLATION'

// Action Creators

export const setTranslation = commentId => {
	return {
		type: SET_TRANSLATION,
		context: { commentId },
	}
}

export const removeTranslation = commentId => {
	return {
		type: REMOVE_TRANSLATION,
		context: { commentId },
	}
}

// Async Action Creators

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

export const asyncTranslate = (postId, commentId, comment, modifiedDate) => {
	return vsApi({
		endpoint: requests.getCommentTranslations.getEndpoint(postId, commentId, comment, modifiedDate),
		requestOptions: requests.getCommentTranslations.requestOptions,
		actions: {
			request: FETCH_TRANSLATION_REQUEST,
			success: FETCH_TRANSLATION_SUCCESS,
			failure: FETCH_TRANSLATION_FAILURE,
		},
		context: {
			postId,
			commentId,
			comment
		},
	})
}

// --- Initial State ---

// State will be mapped to the Redux store by combineReducers
const initialState = {
	postCommentTranslations: {}, // Map postId -> TranslationButtonData
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function translationReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_TRANSLATION_REQUEST: {
			const commentId = action.context.commentId
			const postId = action.context.postId

			return {
				...state,
				postCommentTranslations: {
					...state.postCommentTranslations,
					[commentId]: TranslationPending(postId),
				},
			}
		}
		case FETCH_TRANSLATION_SUCCESS: {
			const commentId = action.context.commentId
			const comment = action.context.comment

			const postCommentTranslation = TranslationButtonData(commentId, comment, action.response)
			postCommentTranslation.isTranslated = true

			const postCommentTranslations = {
				...state.postCommentTranslations,
				[commentId]: postCommentTranslation,
			}

			return {
				...state,
				postCommentTranslations,
			}
		}
		case FETCH_TRANSLATION_FAILURE: {
			const commentId = action.context.commentId

			const postCommentTranslations = {
				...state.postCommentTranslations,
				[commentId]: TranslationError(),
			}

			return {
				...state,
				postCommentTranslations,
			}
		}
		case REMOVE_TRANSLATION: {
			const commentId = action.context.commentId
			const postCommentTranslation = state.postCommentTranslations[commentId]

			const postCommentTranslations = {
				...state.postCommentTranslations,
			}

			if (postCommentTranslation) {
				delete postCommentTranslations[commentId]
			}

			return {
				...state,
				postCommentTranslations,
			}
		}
		case SET_TRANSLATION: {
			const contextCommentId = action.context.commentId
			const postCommentTranslation = state.postCommentTranslations[contextCommentId]

			if (postCommentTranslation && !postCommentTranslation.isPending) {
				if (postCommentTranslation.isTranslated) {
					postCommentTranslation.displayText = postCommentTranslation.original.comment
					postCommentTranslation.isRtlLanguage = postCommentTranslation.original.isRtlLanguage
					postCommentTranslation.isTranslated = false
				}
				else {
					postCommentTranslation.displayText = postCommentTranslation.translated.comment
					postCommentTranslation.isRtlLanguage = postCommentTranslation.translated.isRtlLanguage
					postCommentTranslation.isTranslated = true
				}
			}

			const postCommentTranslations = {
				...state.postCommentTranslations,
				[contextCommentId]: postCommentTranslation,
			}

			return {
				...state,
				postCommentTranslations
			}
		}
		default:
			return state
	}
}
