import { vsApi, requests } from 'helpers/apiHelpers'
import moment from 'moment-timezone'
import {
	FETCH_POSTS_REQUEST,
	FETCH_POSTS_SUCCESS,
	FETCH_POSTS_FAILURE,
} from 'scenes/Post/post.reducer'

// Action types
const FETCH_SCHEDULED_SHARES_REQUEST = 'FETCH_SCHEDULED_SHARES_REQUEST'
const FETCH_SCHEDULED_SHARES_SUCCESS = 'FETCH_SCHEDULED_SHARES_SUCCESS'
const FETCH_SCHEDULED_SHARES_FAILURE = 'FETCH_SCHEDULED_SHARES_FAILURE'

const REFRESH_SCHEDULED_SHARES_SUCCESS = 'REFRESH_SCHEDULED_SHARES_SUCCESS'

const SEND_SCHEDULED_SHARE_REQUEST = 'SEND_SCHEDULED_SHARE_REQUEST'
const SEND_SCHEDULED_SHARE_SUCCESS = 'SEND_SCHEDULED_SHARE_SUCCESS'
const SEND_SCHEDULED_SHARE_FAILURE = 'SEND_SCHEDULED_SHARE_FAILURE'

const DELETE_SCHEDULED_SHARE_REQUEST = 'DELETE_SCHEDULED_SHARE_REQUEST'
const DELETE_SCHEDULED_SHARE_SUCCESS = 'DELETE_SCHEDULED_SHARE_SUCCESS'
const DELETE_SCHEDULED_SHARE_FAILURE = 'DELETE_SCHEDULED_SHARE_FAILURE'

// Action creators
export const asyncFetchScheduledShares = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getScheduledShares.getEndpoint(userId),
		actions: {
			request: FETCH_SCHEDULED_SHARES_REQUEST,
			success: FETCH_SCHEDULED_SHARES_SUCCESS,
			failure: FETCH_SCHEDULED_SHARES_FAILURE,
		},
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

// Fetch, without setting isPending flag
export const asyncRefreshScheduledShares = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getScheduledShares.getEndpoint(userId),
		actions: {
			success: REFRESH_SCHEDULED_SHARES_SUCCESS,
		},
		options: { abortable },
	})
}

export const asyncSendScheduledShare = (userId, scheduledShareId) => {
	return vsApi({
		endpoint: requests.sendScheduledShare.getEndpoint(scheduledShareId),
		actions: {
			request: SEND_SCHEDULED_SHARE_REQUEST,
			success: SEND_SCHEDULED_SHARE_SUCCESS,
			failure: SEND_SCHEDULED_SHARE_FAILURE,
		},
		requestOptions: requests.sendScheduledShare.getRequestOptions(userId, scheduledShareId),
		context: { scheduledShareId },
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncDeleteScheduledShare = (userId, scheduledShareId) => {
	return vsApi({
		endpoint: requests.deleteScheduledShare.getEndpoint(scheduledShareId),
		actions: {
			request: DELETE_SCHEDULED_SHARE_REQUEST,
			success: DELETE_SCHEDULED_SHARE_SUCCESS,
			failure: DELETE_SCHEDULED_SHARE_FAILURE,
		},
		requestOptions: requests.deleteScheduledShare.getRequestOptions(userId, scheduledShareId),
		context: { scheduledShareId },
		options: { captureUnhandledPromiseRejection: false },
	})
}

// Set the initial state
const initialState = {
	isPending: false,
	isActionPending: false,
	scheduledShareIds: [], // IDs of scheduled shares
	scheduledShares: {}, // Map scheduledShareId -> scheduled share
	isPostsFetchPending: false,
}

// Reducer
export default function scheduledSharesReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_SCHEDULED_SHARES_REQUEST: {
			return {
				...state,
				isPending: true,
			}
		}

		case FETCH_SCHEDULED_SHARES_SUCCESS:
		case REFRESH_SCHEDULED_SHARES_SUCCESS: {
			const shares = action.response.shares || []

			const scheduledShareIds = shares.map(share => share.id)
			const scheduledShares = shares.reduce((obj, share) => {
				obj[share.id] = share
				return obj
			}, {})

			return {
				...state,
				scheduledShareIds,
				scheduledShares,
				isPending: false,
			}
		}

		case FETCH_SCHEDULED_SHARES_FAILURE: {
			return {
				...state,
				isPending: false,
			}
		}

		case SEND_SCHEDULED_SHARE_REQUEST: {
			return {
				...state,
				isActionPending: true,
			}
		}

		case SEND_SCHEDULED_SHARE_SUCCESS: {
			const scheduledShareId = action.context.scheduledShareId

			// Mark share as 'sending'
			const scheduledShares = {
				...state.scheduledShares,
			}
			if (scheduledShares[scheduledShareId]) {
				scheduledShares[scheduledShareId].nextShareDate = moment()
					.utc()
					.format()
				scheduledShares[scheduledShareId].isSendPending = true
			}

			return {
				...state,
				scheduledShares,
				isActionPending: false,
			}
		}

		case SEND_SCHEDULED_SHARE_FAILURE: {
			return {
				...state,
				isActionPending: false,
			}
		}

		case DELETE_SCHEDULED_SHARE_REQUEST: {
			const scheduledShareId = action.context.scheduledShareId

			// Mark share as 'deleting'
			const scheduledShares = {
				...state.scheduledShares,
			}
			if (scheduledShares[scheduledShareId]) {
				scheduledShares[scheduledShareId].isDeleting = true
			}

			return {
				...state,
				scheduledShares,
				isActionPending: false,
			}
		}

		case DELETE_SCHEDULED_SHARE_SUCCESS: {
			return {
				...state,
				isActionPending: false,
			}
		}

		case DELETE_SCHEDULED_SHARE_FAILURE: {
			return {
				...state,
				isActionPending: false,
			}
		}
		case FETCH_POSTS_REQUEST: {
			return {
				...state,
				isPostsFetchPending: true,
			}
		}
		case FETCH_POSTS_SUCCESS: {
			return {
				...state,
				isPostsFetchPending: false,
			}
		}
		case FETCH_POSTS_FAILURE: {
			return {
				...state,
				isPostsFetchPending: false,
			}
		}
		default:
			return state
	}
}
