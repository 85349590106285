import React from 'react'
import cookie from 'react-cookies'

import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { isAtLeast } from 'helpers/breakpoints'

import lang from './environmentBanner.lang'
import Banner from './Banner'
import { color } from 'styles/variables'

export const EnvironmentBannerCookieName = 'NMA_ENV_BANNER'
export const Environment = {
	Preview: 'preview',
	Beta: 'beta',
}

@responsive
class EnvironmentBanner extends React.Component {
	static propTypes = {
		viewport: responsive.PropType.isRequired,
	}

	render() {
		if (!isAtLeast(BreakpointNames.large, this.props.viewport.size)) return null

		const environmentCookie = cookie.load(EnvironmentBannerCookieName)

		let message
		if (!environmentCookie) return null
		else if (environmentCookie === Environment.Beta) {
			message = lang.beta
		} else if (environmentCookie === Environment.Preview) {
			message = lang.preview
		} else {
			return null
		}

		return <Banner style={{ backgroundColor: color.secondaryRed }}>{message}</Banner>
	}
}

export default EnvironmentBanner
