const lang = {
	copyrightText: year => `\u00A9 ${year} Dynamic Signal, Inc.`,
	communityCodeText: registrationCode => `Community code "${registrationCode}"`, //  RegistrationCode = The short vanity code that user's can use to quickly register for the right community
	downloadAppsText: 'Download Apps',
	moreLinksText: 'More',
	termsOfUse: 'Terms of Use',
	licenses: 'Licenses',
	ariaAppDownloadiOS: 'Download the iOS app',
	ariaAppDownloadAndroid: 'Download the Android app',
}

export default lang;