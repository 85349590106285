import React from 'react'
import PropTypes from 'prop-types'
import Icon, { Name } from 'components/Icons/FontAwesome'

import styles from './Alert.styles'

// Constants
export const AlertType = {
	success: 'success',
	// warning: 'warning',
	error: 'error',
	// info: 'info',
}

class Alert extends React.Component {
	handleClose = e => {
		const { onClose } = this.props

		e.preventDefault()

		if (onClose) {
			onClose()
		}
	}

	render() {
		const { type, isOpen, children, style: styleOverride, onClose } = this.props
		const style = {
			...styles.base,
			...styles[type],
			...styleOverride,
		}
		const withCloseStyle = {
			...styles.withClose,
			...style,
		}

		if (!isOpen) return null

		return onClose ? (
			<div style={withCloseStyle}>
				<div style={styles.messageWithClose}>{children}</div>
				<span style={styles.close} onClick={onClose}>
					<Icon name={Name.timesLight} />
				</span>
			</div>
		) : (
			<div style={style}>{children}</div>
		)
	}
}

Alert.propTypes = {
	type: PropTypes.oneOf(Object.values(AlertType)),
	style: PropTypes.any,
	isOpen: PropTypes.bool,
	children: PropTypes.any,

	/** Called when close button is clicked - onClose() */
	onClose: PropTypes.func,
}

Alert.defaultProps = {
	type: AlertType.success,
	isOpen: true,
	children: '',
}

export default Alert
