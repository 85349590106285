import { vsApi, requests } from 'helpers/apiHelpers'

// Lang
import lang from './shareDialog.lang'
import { safeGetNestedProp } from '@dysi/js-helpers'

// Filters
export const twitterFilter = p => p === 'Twitter'
export const facebookFilter = p => ['Facebook', 'FacebookPage'].indexOf(p) !== -1
export const otherFilter = p => ['Twitter', 'Facebook', 'FacebookPage'].indexOf(p) === -1

// Enums
export const ReactionType = {
	share: 'Share',
	comment: 'Comment',
	like: 'Like',
}

export const ShareType = {
	shareNow: 'Now',
	autoQueue: 'Auto',
	schedule: 'Manual',
}

// Helpers
const addShareApiCall = (
	dispatch,
	apiCalls,
	endpoint,
	shareText,
	channelIds,
	shareType,
	shareDate,
	activity
) => {
	const { activityReason, activitySource, activityDetail } = activity
	const form = new FormData()
	form.append('shareType', shareType)
	form.append('userChannelIds', channelIds)
	if (shareText) form.append('comment', shareText)
	if (shareType === ShareType.schedule && shareDate) form.append('requestedShareDates', [shareDate])
	if (activityReason) form.append('activityReason', activityReason)
	if (activitySource) form.append('activitySource', activitySource)
	if (activityDetail) form.append('activityDetail', activityDetail)

	const requestOptions = {
		method: 'PUT',
		body: form,
	}

	apiCalls.push(dispatch(vsApi({ endpoint, requestOptions })))
}

// Share API
export const asyncSharePost = (
	postId,
	reactionType,
	userChannelIds,
	twitterShareText,
	facebookShareText,
	otherShareText,
	shareType = ShareType.shareNow,
	shareDate,
	activity = {}
) => {
	// Return the dispatch thunk for share
	return (dispatch, getState) => {
		const state = getState()
		const userChannels = safeGetNestedProp(state, 'auth.user.channels')

		// Build a list of API calls
		const apiCalls = []

		// Must have channels to share on
		if (
			!userChannels ||
			userChannels.length === 0 ||
			!userChannelIds ||
			userChannelIds.length === 0
		) {
			return Promise.reject({ code: 'invalid_request', messages: [lang.errorNoChannels] })
		}

		// Select the API method(s) to call
		if (!reactionType) {
			// -- Share ---
			const endpoint = `post/${postId}/share`

			// Select channels to share on
			let twitterChannelIds = []
			let facebookChannelIds = []
			let otherChannelIds = []
			if (userChannels && userChannels.length > 0) {
				const selectedChannels = userChannels.filter(
					channel => userChannelIds.indexOf(channel.userChannelId) !== -1
				) // Only include selected
				twitterChannelIds = selectedChannels
					.filter(c => twitterFilter(c.provider))
					.map(c => c.userChannelId)
				facebookChannelIds = selectedChannels
					.filter(c => facebookFilter(c.provider))
					.map(c => c.userChannelId)
				otherChannelIds = selectedChannels
					.filter(c => otherFilter(c.provider))
					.map(c => c.userChannelId)
			}

			// Share on selected channel sets
			if (twitterChannelIds.length > 0) {
				addShareApiCall(
					dispatch,
					apiCalls,
					endpoint,
					twitterShareText,
					twitterChannelIds,
					shareType,
					shareDate,
					activity
				)
			}
			if (facebookChannelIds.length > 0) {
				addShareApiCall(
					dispatch,
					apiCalls,
					endpoint,
					facebookShareText,
					facebookChannelIds,
					shareType,
					shareDate,
					activity
				)
			}
			if (otherChannelIds.length > 0) {
				addShareApiCall(
					dispatch,
					apiCalls,
					endpoint,
					otherShareText,
					otherChannelIds,
					shareType,
					shareDate,
					activity
				)
			}
		} else {
			// -- React ---
			const endpoint = `post/${postId}/reaction/${reactionType}`

			// React on single channel. Dialog decides which text to fill in and limits user channels to specific provider
			addShareApiCall(
				dispatch,
				apiCalls,
				endpoint,
				twitterShareText || otherShareText || facebookShareText,
				userChannelIds,
				shareType,
				shareDate,
				activity
			)
		}

		return Promise.all(apiCalls)
	}
}

// Get shortened post Link
const shortenLink = {
	email: 'Email',
	copy: 'CutAndPaste',
	facebook: 'Facebook',
	twitter: 'Twitter',
	xing: 'Xing',
	linkedIn: 'LinkedIn',
}

const asyncGetShortenedLink = (shortener, postId, activity = {}) => {
	const endpoint = `post/${postId}/shorten`
	const { activityReason, activitySource, activityDetail } = activity

	const form = new FormData()
	form.append('urlType', shortener) // Email or CutAndPaste or Facebook
	if (activityReason) form.append('activityReason', activityReason)
	if (activitySource) form.append('activitySource', activitySource)
	if (activityDetail) form.append('activityDetail', activityDetail)

	const requestOptions = {
		method: 'POST',
		body: form,
	}

	// Return the dispatch thunk for getPostShare, which returns the vsApi promise
	return (dispatch, getState) => dispatch(vsApi({ endpoint, requestOptions }))
}

export const asyncGetPostLink = (postId, activity) =>
	asyncGetShortenedLink(shortenLink.copy, postId, activity)
export const asyncGetEmailLink = (postId, activity) =>
	asyncGetShortenedLink(shortenLink.email, postId, activity)
export const asyncGetFacebookLink = (postId, activity) =>
	asyncGetShortenedLink(shortenLink.facebook, postId, activity)
export const asyncGetTwitterLink = (postId, activity) =>
	asyncGetShortenedLink(shortenLink.twitter, postId, activity)
export const asyncGetXingLink = (postId, activity) =>
	asyncGetShortenedLink(shortenLink.xing, postId, activity)
export const asyncGetLinkedInLink = (postId, activity) =>
	asyncGetShortenedLink(shortenLink.linkedIn, postId, activity)

// Share API
export const asyncDeleteScheduledShares = (userId, scheduledShareIds) => {
	return (dispatch, getState) => {
		// Build a list of API calls, one to delete each scheduled share
		const apiCalls = scheduledShareIds.map(id =>
			dispatch(
				vsApi({
					endpoint: requests.deleteScheduledShare.getEndpoint(id),
					requestOptions: requests.deleteScheduledShare.getRequestOptions(userId, id),
				})
			)
		)

		return Promise.all(apiCalls)
	}
}

export const asyncUpdateScheduledShares = (scheduledShareIds, shareDate) => {
	return (dispatch, getState) => {
		// Build a list of API calls, one to update each scheduled share
		const apiCalls = scheduledShareIds.map(id =>
			dispatch(
				vsApi({
					endpoint: requests.updateScheduledShare.getEndpoint(id),
					requestOptions: requests.updateScheduledShare.getRequestOptions(shareDate),
				})
			)
		)

		return Promise.all(apiCalls)
	}
}
