import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { color, form, margin, border, padding, width, fontWeight } from 'styles/variables'
import SocialProviderData from 'helpers/socialProviderData'

const styles = {
	otherActions: {
		display: 'flex',
	},
	otherAction: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: width.spacing.narrow,
		marginLeft: width.spacing.narrow,
	},
	otherActionsError: {
		marginLeft: -width.spacing.wide,
		marginRight: -width.spacing.wide,
		marginTop: width.spacing.narrow,
	},
	otherActionsHeading: {
		marginTop: 0,
		marginRight: 0,
		marginBottom: 0,
		marginLeft: 0,
	},
	otherActionIcon: {
		color: color.secondaryGrey,
		fontSize: width.icon.provider,
		pointerEvents: 'all',
	},
	facebookNativeIcon: {
		color: SocialProviderData.FacebookNative.color,
		fontSize: width.icon.provider,
		pointerEvents: 'all',
	},
	linkedInNativeIcon: {
		color: SocialProviderData.LinkedInNative.color,
		fontSize: width.icon.provider,
		pointerEvents: 'all',
	},
	twitterNativeIcon: {
		color: SocialProviderData.TwitterNative.color,
		fontSize: width.icon.provider,
		pointerEvents: 'all',
	},
	xingNativeIcon: {
		color: SocialProviderData.Xing.color,
		fontSize: width.icon.provider,
		pointerEvents: 'all',
	},
	copyWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'start',
		marginTop: width.spacing.narrow,
		marginBottom: width.spacing.narrow,
		marginRight: 0,
		marginLeft: 0,
	},
	copyButton: {
		height: form.input.height, // Match button height to input height
		marginLeft: width.spacing.narrow,
	},
	copyInput: {
		height: form.input.height,
		maxWidth: 220,
		marginTop: 0,
		marginRight: 0,
		marginBottom: 0,
		marginLeft: 0,
	},
	copyLinkError: {
		marginLeft: -width.spacing.wide,
		marginRight: -width.spacing.wide,
		marginTop: width.spacing.narrow,
		marginBottom: width.spacing.basic,
	},
	shareBarContainer: {
		display: 'inline-flex',
		paddingTop: padding.narrow,
		marginTop: width.spacing.basic,
		alignItems: 'center',
		width: '100%',
		borderTop: border.basic,
	},
	shareBarPoints: {
		display: 'flex',
		flexDirection: 'column',
		justifyItems: 'center',

		pointsText: {
			fontWeight: fontWeight.bold,
		},
	},
	shareBarIcon: {
		container: {
			marginLeft: 'auto',
			display: 'flex',
			alignItems: 'center',
		},
		iconContainer: {
			cursor: 'pointer',
			marginTop: 0,
			marginBottom: 0,
			marginLeft: margin.basic / 2,
			marginRight: margin.basic / 2,
			width: '100%',
		},
		icon: {
			fontSize: width.spacing.wide,
			display: 'inherit',
		},
	},
}
export default prefixer.prefix(styles)
