import { vsApi, requests } from 'helpers/apiHelpers'

//region Action types
export const FETCH_USER_COMMENTS_REQUEST = 'FETCH_USER_COMMENTS_REQUEST'
export const FETCH_USER_COMMENTS_SUCCESS = 'FETCH_USER_COMMENTS_SUCCESS'
export const FETCH_USER_COMMENTS_FAILURE = 'FETCH_USER_COMMENTS_FAILURE'

import { FETCH_POSTS_SUCCESS, FETCH_POSTS_FAILURE } from 'scenes/Post/post.reducer'

export const CLEAR_SHARES = 'CLEAR_SHARES'
//endregion Action types

// Action Creators
export const clearShares = () => {
	return {
		type: CLEAR_SHARES,
	}
}

//region Async Action Helpers
export const asyncGetUserComments = (userId, skip = null, take, abortable) => {
	return vsApi({
		endpoint: requests.getUsersComments.getEndpoint(userId, skip, take),
		actions: {
			request: FETCH_USER_COMMENTS_REQUEST,
			success: FETCH_USER_COMMENTS_SUCCESS,
			failure: FETCH_USER_COMMENTS_FAILURE,
		},
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

//endregion Async Action Helpers

//region Initial State
const initialSettings = {
	posts: null,
	userCommentsByPostId: {
		byId: {},
		allIds: [],
	},
	sharePost: null, // Set to a post to open ShareDialog
	isMoreShareRequestPending: true,
	areMorePostsPending: true,
	numberOfShareComments: 0,
	areMoreShareCommentsAvailable: false,
}

const initialErrors = {
	errors: [],
}

const initialState = { ...initialSettings, ...initialErrors }
//endregion

const normalize = (array, prevState) => {
	// correlates comments with each post according to postId.
	const byId = array.reduce((object, item) => {
		const postId = item.postId
		let newState = item
		if (prevState && prevState.byId && prevState.byId[postId]) {
			// Check to see if there are any other shares associated with that post
			const match = prevState.allIds.includes(item.id)
			if (!match) {
				newState = [...prevState.byId[postId], item]
			} else {
				newState = prevState.byId[postId]
			}
		}
		if (object[postId]) {
			if (Array.isArray(newState)) {
				object[postId] = Array.from(new Set([...object[postId], ...newState]))
			} else {
				object[postId].push(item)
			}
		} else {
			object[postId] = prevState && prevState.byId && prevState.byId[postId] ? newState : [item]
		}

		return object
	}, {})

	// An array of deduplicated comment ids.
	const allIds = array.map(item => item.id).filter((elem, index, self) => {
		return index === self.indexOf(elem)
	})

	return {
		byId: byId,
		allIds: allIds,
	}
}

export default function mySharesReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_USER_COMMENTS_REQUEST: {
			return {
				...state,
				isMoreShareRequestPending: true,
			}
		}
		case FETCH_USER_COMMENTS_SUCCESS: {
			const responseUserComments = Object.values(action.response.comments)

			// Comments grouped by post ID
			const additionalUserCommentsByPostId = normalize(
				responseUserComments,
				state.userCommentsByPostId
			)

			// Determine how many comments are left to grab to show the 'Load More Shares' button
			const numberOfShareComments = responseUserComments.length + state.numberOfShareComments
			const total = action.response.total
			let numberOfShareCommentsLeft = total - numberOfShareComments
			if (numberOfShareCommentsLeft < 0) numberOfShareCommentsLeft = 0

			// Check to see if there are preexisting user comments for each postId.
			const newById = Object.keys(additionalUserCommentsByPostId.byId).reduce(
				(stateById, postId, i, self) => {
					const responseCommentId = responseUserComments.find(comment => comment.postId === postId)
						.id
					let commentAlreadyExists = false

					if (stateById.hasOwnProperty(postId)) {
						if (responseCommentId) {
							commentAlreadyExists = stateById[postId].filter(
								comment => comment.id === responseCommentId
							)
						}
						if (!commentAlreadyExists) {
							stateById[postId] = stateById[postId].concat(
								additionalUserCommentsByPostId.byId[postId]
							)
						} else {
							stateById[postId] = additionalUserCommentsByPostId.byId[postId]
						}
					} else {
						stateById[postId] = additionalUserCommentsByPostId.byId[postId]
					}
					return stateById
				},
				state.userCommentsByPostId.byId
			)

			const newAllIds = state.userCommentsByPostId.allIds.concat(
				additionalUserCommentsByPostId.allIds
			)

			const userCommentsByPostId = {
				byId: newById,
				allIds: newAllIds,
			}

			return {
				...state,
				userCommentsByPostId,
				numberOfShareComments,
				areMoreShareCommentsAvailable: !!numberOfShareCommentsLeft,
				// If a user has no comments, stop the loading spinner
				isMoreShareRequestPending: false,
			}
		}
		case FETCH_USER_COMMENTS_FAILURE: {
			return {
				...state,
				isMoreShareRequestPending: false,
			}
		}
		case FETCH_POSTS_FAILURE:
		case FETCH_POSTS_SUCCESS: {
			return {
				...state,
				isMoreShareRequestPending: false,
			}
		}
		case CLEAR_SHARES: {
			return {
				...state,
			}
		}
		default:
			return state
	}
}
