const lang = {
	categoriesHeading: 'Categories',
	dateNewest: 'Date: Newest to Oldest',
	dateOldest: 'Date: Oldest to Newest',
	doNotHaveAccess: 'Sorry, you do not have access to this content',
	plusMoreCategories: number => `+${number} more...`, //  number = the number of additional categories to show that are currently hidden
	seeMore: 'See more...',
	manageSubscriptions: 'Manage Subscriptions',
	myContent: 'My Content',
	noPostsToShow: 'No posts to show',
	relevance: 'Relevance',
	searchPlaceholder: 'Search posts',
	searchAriaLabel: 'Search Posts',
	searchResultsFor: term => `Search Results for: "${term}"`, //  term = the text that the user entered into the search
	showMorePosts: 'Show more posts',
	subscribe: 'Subscribe',
	subscribed: 'Subscribed',
	youAreNotSubscribed: 'You are not subscribed to any content.',
	ariaLabel: {
		manageCategorySubscriptions: 'Manage Category Subscriptions',
	},
}

export default lang;