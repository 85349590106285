import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { flexContainer } from 'styles/variables'

const styles = {
	container: {
		...flexContainer(),
		position: 'relative',
	},
	iconContainer: {
		...flexContainer(),
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,

		margin: 'auto',
		width: '100%',
	},
	img: {
		width: '100%',
	},
}

export default prefixer.prefix(styles)
