import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import CustomLinksDropdown from './CustomLinksDropdown'
import Banner from 'components/Banner/Banner'
import CustomLink from 'scenes/CustomLinks/CustomLink'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { isAtLeast, breakpoints } from 'helpers/breakpoints'

// Actions
import { asyncGetCustomLinks } from 'reducers/sphere.reducer'

// Enums
import Locations from './enums/customLinkLocations'

//region Styles
import { color, flexContainer, width, constants, spacing } from 'styles/variables'

const styles = {
	banner: {
		...flexContainer(),
		width: '100%',
		background: color.bgPage,
		height: constants.CustomLinksHeight,
		color: color.primaryBlack,
		whiteSpace: 'nowrap',
		zIndex: 10,
		paddingLeft: 0,
		paddingRight: 0,
		narrow: {
			position: 'sticky',
			top: 0,
			left: 0,
			right: 0,
		},
	},
	container: {
		width: '100%',
		...flexContainer('row', 'flex-end', 'center'),
		maxWidth: width.layout.page,
		footer: {
			justifyContent: 'flex-start',
			[BreakpointNames.small]: {
				justifyContent: 'center',
			},
		},
	},
	link: {
		...flexContainer(null, null),
		paddingRight: spacing.basic,
		paddingLeft: spacing.basic,
		height: spacing.wide,
	},
}
//endregion

// Helper functions
export const getMaxLength = size => {
	switch (size) {
		case BreakpointNames.large:
			return 3
		case BreakpointNames.medium:
			return 2
		case BreakpointNames.small:
			return 1
		default:
			return 7
	}
}

const mapStateToProps = (state, ownProps) => {
	const viewport = safeGetNestedProp(state, 'browser.viewport')
	const customLinks = safeGetNestedProp(state, 'sphere.customLinks')

	return {
		customLinks,
		viewport,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchCustomLinks: () => dispatch(asyncGetCustomLinks()),
	}
}

class CustomLinks extends Component {
	static propTypes = {
		customLinks: PropTypes.object,
		fetchCustomLinks: PropTypes.func.isRequired,
		location: PropTypes.string,
		style: PropTypes.object,
		viewport: PropTypes.object,
	}

	static defaultProps = {
		location: Locations.header,
	}

	componentDidMount() {
		const { fetchCustomLinks, customLinks } = this.props

		if (!customLinks) fetchCustomLinks()
	}

	formatLinks = links => {
		const { viewport } = this.props
		const { size } = viewport
		const maxLength = getMaxLength(size)

		// Show at least 2 links in the dropdown unless there are only 2 links and the viewport is small
		if (links.length > maxLength + 1 || (links.length === 2 && size === BreakpointNames.small)) {
			return {
				links: links.slice(0, maxLength),
				moreLinks: links.slice(maxLength, links.length),
			}
		} else {
			return {
				links,
			}
		}
	}

	render() {
		const { customLinks, location, style: styleOverride, viewport } = this.props

		if (!customLinks) return null

		const links = customLinks[location.toLowerCase()]

		if (!links || links.length === 0) return null

		const isFooter = location === Locations.footer
		const isNarrowScreen = !isAtLeast(breakpoints.large.name, viewport.size)
		const linksObject = this.formatLinks(links)
		const dynamicStyles = {
			banner: {
				...styles.banner,
				...(isNarrowScreen && !isFooter && styles.banner.narrow),
				...styleOverride,
			},
			container: {
				...styles.container,
				...(isFooter && {
					...styles.container.footer,
					...styles.container.footer[viewport.size],
				}),
			},
		}

		return (
			<Banner style={dynamicStyles.banner} className={isNarrowScreen ? 'sticky' : ''}>
				<div style={dynamicStyles.container}>
					{linksObject.links.map(link => (
						<div key={link.id} style={styles.link}>
							<CustomLink
								link={link}
								style={styleOverride}
								location={location}
								render={
									<RestrictedText limit={Restrictions.CustomLinks} text={link.name}>
										{text => text}
									</RestrictedText>
								}
							/>
						</div>
					))}

					{linksObject.moreLinks && (
						<CustomLinksDropdown
							links={linksObject.moreLinks}
							location={location}
							style={styleOverride}
						/>
					)}
				</div>
			</Banner>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomLinks)
