import { replace } from 'react-router-redux'

import { encodeQueryParams } from '@dysi/js-helpers'
import queryString from 'query-string'
import { impersonateUser, asyncGetCurrentUser } from 'scenes/Auth/auth.reducer'

// This middleware will impersonate the user whose credentials are passed in
// as authentication credentials in the query param:
// 	shortToken - Short-lived authentication token
//	shortTokenExpiration - Short-lived authentication token expiration date
//
// NOTE:
//		o shortToken and shortTokenExpiration will be removed from history URL (not for user to see)
//
export const impersonateUserMiddleware = history => ({ dispatch, getState }) => next => action => {
	if (history.location.search && history.location.search.includes('shortToken')) {
		// Are we impersonating?
		const query = queryString.parse(history.location.search)
		const { shortToken, shortTokenExpiration } = query

		if (shortToken && shortTokenExpiration) {
			// Remove impersonation params from URL
			const newQuery = { ...query }
			delete newQuery.shortToken
			delete newQuery.shortTokenExpiration

			const search = encodeQueryParams(newQuery)
			const newLocation = { ...history.location, search }
			dispatch(replace(newLocation))

			// Impersonate user
			dispatch(impersonateUser({ shortToken, shortTokenExpiration }))
			dispatch(asyncGetCurrentUser())
		}
	}
	return next(action)
}
