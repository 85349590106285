import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { width } from 'styles/variables'

const styles = {
	base: {
		padding: 0,
		marginTop: width.spacing.narrow,
		marginBottom: width.spacing.narrow,
		marginLeft: width.spacing.basic,
		marginRight: width.spacing.basic,
		lineHeight: 1.4,
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
	},
}

export default prefixer.prefix(styles)
