import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import ImagePlaceholder from 'components/Placeholders/ImagePlaceholder'

const assetSizeQueryString = '?size=Square80'

class CommunityLogo extends Component {
	static propTypes = {
		name: PropTypes.string,
		squareLogoUrl: PropTypes.string,
		logoWidth: PropTypes.number,
		style: PropTypes.object,
	}

	render() {
		const { squareLogoUrl, logoWidth, style: styleOverride, name } = this.props
		const image = {
			url: `${squareLogoUrl}${assetSizeQueryString}`,
			width: logoWidth,
			height: logoWidth,
		}
		const dynamicStyles = {
			logo: {
				width: logoWidth,
				height: logoWidth,
				...styleOverride,
			},
			error: {
				width: logoWidth,
				height: logoWidth,
			},
		}

		return (
			<ImagePlaceholder
				style={dynamicStyles.logo}
				image={image}
				alt={name || ''}
				// TODO - Extract this URL to somewhere common
				renderError={
					<img
						src={`https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/abdff6f0-aa3f-4a04-8f40-62ac44076b66?_=636608759974128068${assetSizeQueryString}`}
						alt={name || ''}
						style={dynamicStyles.error}
					/>
				}
			/>
		)
	}
}

export default CommunityLogo
