import React from 'react'
import PropTypes from 'prop-types'

// Components
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'
import HighlightText from 'components/HighlightText/HighlightText'

// Styles
import styles from './Description.styles'
import { stringIsEmptyOrWhitespace } from '@dysi/js-helpers'

// Core Component
class Description extends React.Component {
	static propTypes = {
		text: PropTypes.any,
		style: PropTypes.any,
		searchMatches: PropTypes.array,
		isRtlLanguage: PropTypes.bool,
	}

	render() {
		const { text, style, searchMatches, isRtlLanguage } = this.props

		if (!text || (typeof text === 'string' && stringIsEmptyOrWhitespace(text))) {
			return null
		}

		const shouldForceUpdate = searchMatches && searchMatches.length > 0

		return (
			<p
				style={{ ...styles.base, ...style, ...(isRtlLanguage ? { textAlign: 'right' } : {}) }}
				dir={isRtlLanguage ? 'rtl' : null}
			>
				<RestrictedText
					limit={Restrictions.PostDescription}
					text={text}
					shouldForceUpdate={shouldForceUpdate}
				>
					{text => (
						//Wrap in span for tooltip
						<span>
							<HighlightText
								text={text}
								ranges={searchMatches}
								render={highlightedText => highlightedText}
							/>
						</span>
					)}
				</RestrictedText>
			</p>
		)
	}
}

export default Description
