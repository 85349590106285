import React from 'react'
import PropTypes from 'prop-types'
import store from '../store'

// Components
import AppDidBreak from 'scenes/App/AppDidBreak'

// Helpers
import { vsApi, requests } from 'helpers/apiHelpers'

/**
 * ErrorBoundary HOC
 * Wrap your components in this to encapsulate them in an error boundary
 *
 * @param {any} Component Component to wrap in error boundary
 * @param {any} Fallback  Component to render when an error is caught
 */
export default function ErrorBoundary(Component, Fallback) {
	if (!Fallback) {
		Fallback = AppDidBreak
	}

	class Boundary extends React.Component {
		static propTypes = {
			onError: PropTypes.func,
			sendLog: PropTypes.func,
		}

		state = {
			error: null,
			info: null,
		}

		// Lifecycle method that fires when an error is caught
		componentDidCatch(error, info) {
			const componentStack = info ? info.componentStack : ''
			const errorString = error.toString()

			// Grab some additional info just in case
			const meta = {
				componentStack: componentStack,
				message: error.message,
			}

			// Build the API log request
			const request = {
				endpoint: requests.sendLog.endpoint,
				requestOptions: requests.sendLog.getRequestOptions(errorString, error.stack, meta),
			}

			// This triggers an update letting the component know we caught an error and logs itt
			this.setState({ error, info }, () => store.dispatch(vsApi(request)))
		}

		render() {
			const { error, info } = this.state
			// An error was caught, render the fallback
			if (error !== null) {
				return (
					<React.Fragment>
						<Fallback
							{...this.props}
							componentStack={info ? info.componentStack : ''}
							error={error}
						/>
					</React.Fragment>
				)
			}

			// Otherwise, render the original content
			return <Component {...this.props} />
		}
	}

	return Boundary
}
