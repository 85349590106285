import React, { Component } from 'react'

// Components
import Dialog from 'components/Dialog/Dialog'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Lang
// import lang from './appDownloadDialog.lang.js'

import styles from './OfflineDialog.styles'

class OfflineDialog extends Component {
	render() {
		return (
			<Dialog isOpen title="Network Disconnected">
				<div style={styles.container}>
					We are displaying this message because we noticed that your network connection has gone
					offline. When connectivity has been restored this message will be dismissed.
					<Icon name={Name.globeAmericas} style={styles.icon} />
				</div>
			</Dialog>
		)
	}
}

export default OfflineDialog
