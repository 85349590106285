/*
	FONTAWESOME ICON DEFINITIONS
	- DuoTone VERSION -
*/

/*eslint no-unused-vars: "error"*/

// Please try to keep this sorted alphabetically

// NOTE: Switch this to regular imports when webpack 4 upgrades it's minifier. https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking#issues

import { faClipboardList } from '@fortawesome/pro-duotone-svg-icons/faClipboardList'
import { faFilm } from '@fortawesome/pro-duotone-svg-icons/faFilm'
import { faImage } from '@fortawesome/pro-duotone-svg-icons/faImage'
import { faImages } from '@fortawesome/pro-duotone-svg-icons/faImages'
import { faJedi } from '@fortawesome/pro-duotone-svg-icons/faJedi'
import { faTvRetro } from '@fortawesome/pro-duotone-svg-icons/faTvRetro'

import { faChevronCircleLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleRight'

export default {
	faClipboardList,
	faFilm,
	faImage,
	faImages,
	faJedi,
	faTvRetro,

	faChevronCircleLeft,
	faChevronCircleRight,
}
