import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import SocialIcon, { SocialIconSize } from 'components/SocialIcon/SocialIcon'

// Lang
import lang from './ShareDisclosures.lang'

// Shared
import { Backdrop } from 'components/Icons/FontAwesome'
import { margin, lineHeight, fontSize, color, fontWeight } from 'styles/variables'

const styles = {
	base: {
		listStyleType: 'none',
		padding: 0,
	},
	item: {
		display: 'flex',
		marginBottom: margin.basic,
	},
	disclosure: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		lineHeight: lineHeight.close,
		marginLeft: margin.basic,
	},
	provider: {
		fontWeight: fontWeight.semiBold,
	},
	text: {
		fontSize: fontSize.small,
	},
	textNone: {
		color: color.secondaryGrey,
	},
}

// Access Redux store
const mapStateToProps = (state, ownProps) => {
	const providers = state.sphere.providers

	return {
		providers,
	}
}

class ShareDisclosures extends Component {
	static propTypes = {
		disclosures: PropTypes.arrayOf(
			PropTypes.shape({
				provider: PropTypes.string.isRequired,
				providerDisplayName: PropTypes.string.isRequired,
				shareDisclosureText: PropTypes.string,
			})
		).isRequired,
		selectedProviders: PropTypes.arrayOf(PropTypes.string).isRequired,

		// From Redux store
		providers: PropTypes.object.isRequired,
	}

	render() {
		const { disclosures, selectedProviders: originalSelectedProviders, providers } = this.props

		// Are there any blog providers selected?
		const selectedProviders = [...originalSelectedProviders]
		if (selectedProviders.some(provider => providers[provider] && providers[provider].isBlog)) {
			selectedProviders.push('Blog')
		}

		// Render the sharing disclosure for each channel
		return (
			<ul style={styles.base}>
				{disclosures.map(disclosure => {
					const textStyle = disclosure.shareDisclosureText
						? styles.text
						: { ...styles.text, ...styles.textNone }

					const disabled = !selectedProviders.includes(disclosure.provider)

					return (
						<li key={disclosure.provider} style={styles.item}>
							<SocialIcon
								provider={disclosure.provider}
								size={SocialIconSize.channel}
								backdrop={Backdrop.circle}
								disabled={disabled}
							/>
							<div style={styles.disclosure}>
								<div style={styles.provider}>
									{lang.providerHeading(disclosure.providerDisplayName === "Twitter" ? "X" : disclosure.providerDisplayName)}
								</div>
								<div style={textStyle}>{disclosure.shareDisclosureText || lang.none}</div>
							</div>
						</li>
					)
				})}
			</ul>
		)
	}
}

export default connect(mapStateToProps)(ShareDisclosures)
