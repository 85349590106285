const lang = {
	authChannelErrorUnknown: 'Cannot reauthorize social accounts at this time. Please try again later.',
	connectToTwitter: 'Connect to Twitter',
	connectToLinkedIn: 'Connect to LinkedIn',
	editAccount: 'Edit Social Accounts',
	copy: 'Copy Text',
	copyLinkSuccess: 'Copied to clipboard!',
	copyLinkError: 'Unable to copy to the clipboard at this time. Please select and copy the link manually.',
	noAccountsConnected: 'Connect and add your social accounts to share!',
	placeholderOtherAccounts: 'What would you like to share?',
	placeholderTwitter: 'Say something about this tweet...',
	replace: 'Replace Message',
	replaceMessageSuccess: 'Message replaced!',
	sharingDisclosure: 'Channels may include appended text for campaigns.',
	success: 'Message copied.',
	suggestedShareTextToggle: 'Text Suggestions',
	twitterWarningPostNow: 'Post Anyway',
	twitterWarningAutoQNow: 'Auto-Queue Anyway',
	twitterWarningSchedule: 'Schedule Anyway',
	twitterWarningReturn: 'Go Back',
	twitterWarningInfo: 'Are you sure you want to post without any share text for Twitter?',
}

export default lang;