import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './Banner.styles'

class Banner extends React.Component {
	static propTypes = {
		children: PropTypes.any.isRequired,
		style: PropTypes.object,
		className: PropTypes.any,
	}

	render() {
		const dynamicStyle = {
			...styles,
			...this.props.style,
		}

		return (
			<div style={dynamicStyle} className={this.props.className}>
				{this.props.children}
			</div>
		)
	}
}

export default Banner
