import { isDesktopApp } from 'helpers/browserhelper'
import IpcEvent from '../desktopApp/ipcEvent'

const permissionStatusEnum = {
	denied: 'denied',
	granted: 'granted',
	notAsked: 'default',
}

let notificationsAllowed = false

/**
 * Checks if the app has permissions to display notifications
 * and asks if the user hasn't granted them. If the user has explicitly denied
 * then we won't ask and won't send notifications
 */
export function RequestPermission() {
	// More testing just to be safe
	if ('Notification' in window) {
		if (Notification.permission === permissionStatusEnum.granted) {
			notificationsAllowed = true
		} else if (Notification.permission === permissionStatusEnum.notAsked) {
			// request permissions
			Notification.requestPermission(permission => {
				if (permission === permissionStatusEnum.granted) {
					notificationsAllowed = true
				}
			})
		} else {
			notificationsAllowed = false
		}
	}
}

/**
 * Attempts to show a desktop notification with the given options
 *
 * @param {string} title - The title of the notification
 * @param {string} body - The text body of the notification
 * @param {string} icon - The icon to display in the notification
 * @param {function} onClick The function to call when the user clicks on the notification
 * @param {boolean} [requireInteraction=false]
 */
export const ShowNotification = (title, body, icon, onClick, requireInteraction = false) => {
	RequestPermission()

	if (
		'Notification' in window &&
		Notification.permission === permissionStatusEnum.granted &&
		notificationsAllowed
	) {
		try {
			const notification = new Notification(title, {
				body,
				icon,
				requireInteraction,
				// actions: [{action: 'test', title: 'test'}],
			})

			if (onClick) {
				notification.onclick = event => {
					onClick(event)
					if (isDesktopApp) {
						window.ipcRenderer.send(IpcEvent.clickNotification, {
							title,
							onClick,
						})
					}
				}
				// setTimeout(notification.close.bind(notification), 5000);
			}
		} catch (err) {
			notificationsAllowed = false
		}
	}
}

/**
 * Helper to create a click event for notifications to navigate to a page
 * using history.push(). This will not work with external links
 *
 * @param {string} link - The location to navigate to
 * @param {Object} history - The redux history object
 * @param {boolean} [closeAfterClick=false] - Whether the notification should be closed after the user clicks on it
 */
export const NavigateToPage = (link, history, closeAfterClick = true) => {
	return event => {
		window.focus()
		history.push(link)
		if (closeAfterClick && event && event.currentTarget) {
			event.currentTarget.close()
		}
	}
}
