import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Styles
import { width } from 'styles/variables'

// Lang
import lang from 'scenes/Settings/ChannelSettings/disconnectAccountDialog.lang'

// Components
import Dialog from 'components/Dialog/Dialog'
import UserChannel from 'components/UserChannel/UserChannel'
import ErrorIndicator from 'components/ErrorIndicator/ErrorIndicator'
import Button from 'components/Button/Button'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'

const styles = {
	base: {
		width: 500,
		maxWidth: '95%',
	},
	error: {
		marginBottom: width.spacing.basic,
	},
	disconnectAccounts: {
		marginBottom: width.spacing.wide,
	},
	userChannel: {
		marginTop: 0,
		marginBottom: width.spacing.basic,
		marginLeft: 'auto',
		marginRight: 'auto',
		flexBasis: 250,
	},
}

const mapStateToProps = (state, ownProps) => {
	const socialProviders = safeGetNestedProp(state, 'sphere.providers')

	return {
		socialProviders,
	}
}

class DisconnectAccountDialog extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		disconnectAccounts: PropTypes.array,
		onRemoveChannel: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		onReconnectClick: PropTypes.func.isRequired,	
		socialChannelRenewalEnabled: PropTypes.bool,	

		// From Redux store
		socialProviders: PropTypes.object,
	}

	static initialState = {
		disconnectAccountId: null, // Only disconnect 1 at a time
		errorMessage: null,
		isSubmitting: false,
		reconnectButtonHidden: true
	}

	state = {
		...DisconnectAccountDialog.initialState,
	}

	resetDialogState = () => {
		this.setState({ ...DisconnectAccountDialog.initialState })
	}

	handleRemoveChannel = () => {
		const { onRemoveChannel, onClose } = this.props
		const { disconnectAccountId } = this.state

		if (!disconnectAccountId) {
			// No channel selected. Close the Disconnect dialog
			onClose()
			return
		}

		// Set isSubmitting
		this.setState({ isSubmitting: true })

		onRemoveChannel(disconnectAccountId).catch(error => {
			// Show error in dialog
			if (error.messages && error.messages.length > 0) {
				error = error.messages[0]
			} else if (error.message) {
				error = error.message
			}
			this.setState({ isSubmitting: false, errorMessage: error })
		})
	}

	handleReconnectChannel = () => {
		const { disconnectAccounts, onReconnectClick } = this.props
		onReconnectClick(disconnectAccounts[0].provider)
	}

	toggleChannelDisconnect = channelId => {
		const { disconnectAccounts, socialChannelRenewalEnabled } = this.props
		const { disconnectAccountId } = this.state

		let newDisconnectAccountId = null
		let reconnectButtonHidden = true		

		// Ignore, if only one account to disconnect
		if (disconnectAccounts.length === 1) return

		if (disconnectAccountId !== channelId) {
			// Use this channel
			newDisconnectAccountId = channelId
		} // else Remove channel by leaving it null

		const matchedAccount = disconnectAccounts.filter(account => account.userChannelId === channelId)[0]

		if (matchedAccount 			
			&& socialChannelRenewalEnabled
			&& matchedAccount.authRequired 
			&& matchedAccount.userChannelId === channelId 
			&& matchedAccount.provider === 'LinkedIn'
			&& newDisconnectAccountId !== null) {			
				reconnectButtonHidden = false						
		}

		this.setState({ disconnectAccountId: newDisconnectAccountId, reconnectButtonHidden: reconnectButtonHidden })
	}

	createToggleChannelHandler = channelId => () => this.toggleChannelDisconnect(channelId)

	componentDidUpdate(prevProps, prevState) {
		const { isOpen, disconnectAccounts, socialChannelRenewalEnabled } = this.props
		const { isOpen: wasOpen } = prevProps
		let reconnectButtonHidden = true
		let disconnectAccountId = null

		if (isOpen && !wasOpen) {
			// Dialog opening. Set/Reset state					
			if (disconnectAccounts && disconnectAccounts.length === 1) {
				const matchedAccount = disconnectAccounts[0]
		
				if (matchedAccount) {		
					disconnectAccountId = matchedAccount.userChannelId
					
					if (socialChannelRenewalEnabled && matchedAccount.authRequired && matchedAccount.provider === 'LinkedIn') {					
						reconnectButtonHidden = false
					}			
				}
			}

			this.setState({
				...DisconnectAccountDialog.initialState,
				disconnectAccountId,
				reconnectButtonHidden
			})
		}
	}

	render() {
		const { isOpen, disconnectAccounts, onClose, socialProviders, socialChannelRenewalEnabled } = this.props
		const { disconnectAccountId, errorMessage, isSubmitting, reconnectButtonHidden } = this.state

		if (!isOpen) return null

		return (
			<Dialog
				style={styles.base}
				isOpen={true}
				title={lang.title}
				subtitle={disconnectAccounts && disconnectAccounts.length > 1 ? lang.instructions : null}
				onClose={onClose}
				submitButtons={
					<div>
						<Button
							onClick={this.handleReconnectChannel}
							disabled={isSubmitting}
							style={{display: reconnectButtonHidden ? 'none' : ''}}
						>
							{lang.reconnectButton}
						</Button>
						<Button
							onClick={this.handleRemoveChannel}
							disabled={isSubmitting || !disconnectAccountId}
						>
							{lang.submitButton}
						</Button>
					</div>
				}
			>
				<ErrorIndicator isOpen={!!errorMessage} message={errorMessage} style={styles.error} />
				<div style={styles.disconnectAccounts}>
					{disconnectAccounts.map(userChannel => {
						return (
							<UserChannel
								key={userChannel.userChannelId}
								style={styles.userChannel}
								provider={socialProviders[userChannel.provider]}
								userChannels={[userChannel]}
								showChannelProfilePics={true}
								hideActions={true}
								disabled={disconnectAccountId === userChannel.userChannelId}
								onClick={() => this.toggleChannelDisconnect(userChannel.userChannelId)}
								needsReauth={userChannel.authRequired}		
								socialChannelRenewalEnabled={socialChannelRenewalEnabled}							
							/>
						)
					})}
				</div>
			</Dialog>
		)
	}
}

export default connect(mapStateToProps)(DisconnectAccountDialog)
