import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Observer from '@researchgate/react-intersection-observer'

// If using ViewableMonitor on an element that is position absolute, you have to override the default options and remove the root.
class ViewableMonitor extends Component {
	static propTypes = {
		children: PropTypes.any,
		options: PropTypes.shape({
			root: PropTypes.string,
			rootMargin: PropTypes.string,
			threshold: PropTypes.number,
		}),
		handleChange: PropTypes.func,
		onlyOnce: PropTypes.bool,
	}

	state = {
		isIntersecting: false,
	}

	handleChange = ({ isIntersecting }, unobserve) => {
		const { handleChange, onlyOnce } = this.props
		this.setState({ isIntersecting })
		if (handleChange) handleChange(isIntersecting)
		if (isIntersecting && onlyOnce) unobserve() // Stops observing after visible if onlyOnce flag is true
	}

	render() {
		const { children, options: optionsOverride } = this.props
		const { isIntersecting } = this.state
		const isModalOpen = document.querySelector('.ReactModal__Body--open') !== null
		const defaultOptions = {
			...(!isModalOpen && { root: '#root' }),
			rootMargin: '500px 500px 500px 500px',
		}
		const options = optionsOverride || defaultOptions
		return (
			<Observer {...options} onChange={this.handleChange}>
				{children(isIntersecting)}
			</Observer>
		)
	}
}

export default ViewableMonitor
