const ShareDialogFlows = {
	mainShareFlow: 'showSharePostDialog',
	editChannelsFlow: 'showEditChannelsDialog',
	scheduleSharesFlow: 'showScheduleShareDialog',
	signInFlow: 'showSignInDialog',
	sharingDisclosuresFlow: 'showSharingDisclosures',
	shareCompleteFlow: 'showShareCompleteDialog',
}

export default ShareDialogFlows
