import SocialProviders from 'helpers/socialProviderData'
import ImageSize from 'scenes/Post/enums/imageSize'
import VideoTypes from 'scenes/Post/enums/videoTypes'
import { UserImageDimension } from 'components/UserPhoto/UserPhoto'
import { cdnImageUrlRegex } from 'helpers/imageHelpers'
// Post Helper functions

// TODO - Tests for these would be nice
/**
 * Return a URL to the Details view of a Post
 * @param  {String}  homeUrl For internal links, set to null. For external links, pass settings.articleRoutingBaseUrl
 * @param  {String}  articleRoutingBaseUrl Pass settings.articleRoutingBaseUrl
 * @param  {Object}  post The Post object to link to
 * @param  {Boolean} showDiscussions Set to true to show discussions on that page
 * @param  {String}  Describes the reason for the user activity
 * @param  {String}  Describes the source of the user activity
 * @param  {String}  Detail around the activity
 * @param  {Number}  The ID of the user
 * @return {String}  URL to the Post Details page
 */
export const postLinkUrl = (
	homeUrl,
	articleRoutingBaseUrl,
	post,
	showDiscussions,
	activity = {},
	userId
) => {
	if (!post || !post.postId) return null

	const { activityReason, activityDetail, activitySource } = activity
	// Get or create post link format string
	let postLinkFormat = articleRoutingBaseUrl
	if (!postLinkFormat) {
		postLinkFormat = `${homeUrl ||
			''}/post/$slug/$id?showDiscussions=$showDiscussions&actr=$actr&actd=$actd&acts=$acts&uid=$uid`
	}

	let href = postLinkFormat
		.replace('$slug', post.urlSlug || 'details')
		.replace('$id', post.postId)
		.replace('$showDiscussions', showDiscussions || '')
		.replace('$actr', activityReason || '')
		.replace('$actd', activityDetail || '')
		.replace('$acts', activitySource || '')
		.replace('$uid', userId || '')

	// Removing empty parameters
	var match = href.match(/\?(.*)/)
	if (match) {
		var params = href.substr(match.index)
		params = params.replace(/[a-zA-Z0-9]+=(&|$)/g, '').replace(/[&?]$/, '')
		href = href.substr(0, match.index) + params
	}

	return href
}

// TODO - Tests for these would be nice
export const extractHostnameFromUrl = url => {
	if (!url) return null

	let hostname

	//find & remove protocol (http, ftp, etc.) and get hostname
	if (url.indexOf('://') > -1) {
		hostname = url.split('/')[2]
	} else {
		hostname = url.split('/')[0]
	}

	//find & remove port number
	hostname = hostname.split(':')[0]
	//find & remove "?"
	hostname = hostname.split('?')[0]

	return hostname
}

// TODO - Tests for these would be nice
export const extractRootDomainFromUrl = url => {
	if (!url) return null

	let domain = extractHostnameFromUrl(url),
		splitArr = domain.split('.'),
		arrLen = splitArr.length

	//extracting the root domain here
	//if there is a subdomain
	if (arrLen > 2) {
		domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1]
		//check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
		if (splitArr[arrLen - 1].length === 2 && splitArr[arrLen - 1].length === 2) {
			//this is using a ccTLD
			domain = splitArr[arrLen - 3] + '.' + domain
		}
	}
	return domain
}

export const imgTagRegex = /<img([\w\W]+?)\/?>/g
const heightRegex = /height="[\w\W]+?"/g
const imageBaseUrlRegex = /.+?(?=\?)/g
const httpsImageUrlRegex = /^(http|https)/gi
const styleRegex = /style="[\w\W]+?"/g

export const stripStylingFromContentImage = content => {
	const matches = content.match(imgTagRegex)
	let newContent = content
	if (matches) {
		matches.forEach(match => {
			const newElement = match.replace(heightRegex, '').replace(styleRegex, '')
			newContent = newContent.replace(match, newElement)
		})
	}

	return newContent
}

export const getMedia = media => {
	if (
		!media ||
		media.length === 0 ||
		!media.some(x => Object.values(VideoTypes).includes(x.role))
	) {
		return
	}

	if (media.length === 1) {
		return media[0]
	}

	const originalMedia = media.find(
		x => x.role === VideoTypes.originalVideo || x.role === VideoTypes.originalAutoplay
	)
	if (originalMedia) {
		const highResMedia = media.reduce((prev, curr) => (prev.width > curr.width ? prev : curr))
		return originalMedia.provider === SocialProviders.YouTube.provider
			? {
					...originalMedia,
					width: highResMedia.width,
					height: highResMedia.height,
			  }
			: originalMedia
	} else {
		return media.find(
			media =>
				media.role === VideoTypes.hostedVideo ||
				media.role === VideoTypes.hostedAdvancedVideo ||
				media.role === VideoTypes.hostedLiveStreamVIdeo ||
				(media.role === VideoTypes.livestream && media.origin !== 'completed')
		)
	}
}

export const getOriginalImage = images => {
	if (!images || Object.values(images).length === 0) return

	const originalImage = Object.values(images).find(image => image.size === ImageSize.Original)

	if (!originalImage) {
		return
	} else {
		// Get the 'baseUrl' by checking if the original image has a url of the format 'cdnImage/article'
		// We first check for a http(s) url and if so, we apply the baseUrl regex and return the match.
		// In all other cases we return the url unaltered.
		//		Why?
		//		1. Sometimes original images could have data and/or blob urls and not necessarily CDN urls.
		//		2. Running a regex on a DATA URL can potentially KILL the webpage!!
		const originalImageInputUrl = originalImage.url
		let originalImageOutputUrl

		// Step 1: Apply the regexp to check for a http(s) url and cdnImage url
		const httpsImageMatch = originalImageInputUrl && originalImageInputUrl.match(httpsImageUrlRegex)
		const cdnImageUrlMatch = originalImageInputUrl && originalImageInputUrl.match(cdnImageUrlRegex)

		if (httpsImageMatch && cdnImageUrlMatch) {
			// Step 2a: If it is a http(s) url and cdnImage url, apply the baseUrl regex and return the match if found
			const baseUrlMatch = originalImageInputUrl.match(imageBaseUrlRegex)
			originalImageOutputUrl = baseUrlMatch ? baseUrlMatch[0] : originalImage.url
		} else {
			// Step 2b: If it is not a http(s) url or cdnImage url, return the original image url unaltered
			originalImageOutputUrl = originalImageInputUrl
		}

		return {
			baseUrl: originalImageOutputUrl,
			height: originalImage.height,
			width: originalImage.width,
			altText: originalImage.altText,
		}
	}
}

// TODO: temporary until all profile image objects are changed and userphoto component is changed
export const getSmallProfileImage = images => {
	if (!images || Object.values(images).length === 0) return

	return {
		[UserImageDimension.Square40]: images[UserImageDimension.Square40],
	}
}

export const filterImageGallery = imageGallery => imageGallery.filter(image => image.url)
