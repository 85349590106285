import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { margin, border, spacing, form, color, flexContainer } from 'styles/variables'
import { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { listUnstyled } from 'styles/defaults'

const styles = {
	regFlow: {
		width: 'auto',
		backgroundColor: 'rgb(255, 255, 255)',
		border: 'none',
		margin: 'auto',
	},
	footerDivider: {
		marginLeft: -margin.wide,
		marginRight: -margin.wide,
		backgroundColor: color.bgPage,
		height: margin.narrow,
		[BreakpointNames.small]: {
			marginLeft: -margin.basic,
			marginRight: -margin.basic,
		},
	},
	footer: {
		...flexContainer('row', 'space-between', 'center'),
		flexWrap: 'wrap',
		[BreakpointNames.small]: {
			justifyContent: 'center',
			marginTop: margin.basic,
		},
	},
	otherSharingOptions: {
		marginTop: margin.basic,
		[BreakpointNames.small]: {
			marginTop: 0,
			marginRight: margin.basic,
		},
	},
	submitButton: {
		...flexContainer('row', 'center', 'flex-start'),
		reaction: {
			height: form.input.height,
		},
		button: {
			height: form.input.height,
			borderRightWidth: 0,
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			paddingLeft: spacing.narrow,
			paddingTop: 0,
			paddingRight: spacing.narrow,
			paddingBottom: 0,
		},
		trigger: {
			marginLeft: 0,
			borderLeft: border.basic,
			paddingRight: 5,
			height: form.input.height,
		},
		icon: {
			marginRight: spacing.narrow,
		},
		[BreakpointNames.small]: {
			marginTop: margin.basic,
		},
	},
	submitButtonDropdownList: {
		...listUnstyled,
		...flexContainer('column', 'center', 'flex-start'),
		item: {
			paddingTop: margin.narrow / 2,
			paddingBottom: margin.narrow / 2,
			paddingLeft: margin.narrow / 2,
			paddingRight: margin.narrow / 2,
		},
		link: {
			cursor: 'pointer',
		},
	},
	sharingDisclosuresDialog: {
		top: 80,
	},
}

export default prefixer.prefix(styles)
