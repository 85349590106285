import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'

// Routing
import { Redirect } from 'react-router-dom'
import Routes from 'helpers/routes'

// Components
import Layout from 'pages/Layouts/Layout'
import RegFlow from 'scenes/Auth/RegFlow'
import FormSuccess from 'components/Formik/FormSuccess'

// Helpers
import { isUserLoggedIn } from 'helpers/userHelpers'
import { Activity } from 'helpers/activityHelpers'
import { asyncRecordInvitationLinkFollowed } from 'scenes/Invite/invite.reducer'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { changeDisplayLang } from 'store'

// Lang
import resetPasswordLang from 'pages/resetPassword.lang'

export const SignInParam = {
	signUp: 'CreateAccount',
}

// Styles
import { color, border, width } from 'styles/variables'

const styles = {
	resetPasswordSuccess: {
		color: color.secondaryGreen,
		backgroundColor: color.backgroundGreen,
		borderTop: border.success,
		borderBottom: border.success,
		borderLeft: 'none',
		borderRight: 'none',
		marginTop: 0,
		marginRight: -width.spacing.basic,
		marginLeft: -width.spacing.basic,
	},
}

const mapStateToProps = state => {
	const loggedIn = isUserLoggedIn(state.auth.user)
	const displayLanguage = safeGetNestedProp(state, 'auth.user.primaryLanguage')

	return {
		loggedIn,
		displayLanguage,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		recordInvitationLinkFollowed: activityDetail =>
			dispatch(asyncRecordInvitationLinkFollowed(activityDetail)),
	}
}

class SignInPage extends Component {
	static propTypes = {
		location: PropTypes.object,

		// from Redux
		loggedIn: PropTypes.bool.isRequired,
		displayLanguage: PropTypes.string,
		recordInvitationLinkFollowed: PropTypes.func,
	}

	state = {
		showPasswordResetSuccess: false,
	}

	// Password Reset success message
	showPasswordResetSuccess = () => {
		this.setState({ showPasswordResetSuccess: true })
	}
	hidePasswordResetSuccess = () => {
		this.setState({ showPasswordResetSuccess: false })
	}

	componentDidMount() {
		const { location, recordInvitationLinkFollowed } = this.props

		const queryParams = queryString.parse(location.search)
		const showPasswordResetSuccess = queryParams.passwordReset === 'success'
		const activityReason = queryParams.actr
		const activityDetail = queryParams.actd

		if (activityReason === Activity.Reason.invitationLink && !!activityDetail) {
			// Record that user clicked an invitation link to reach the Sign In page
			// Note: Web Invite Link routes to SignIn. Mobile Invite Link routes to Downloads
			recordInvitationLinkFollowed(activityDetail) // Ignore response
		}

		if (showPasswordResetSuccess) {
			this.showPasswordResetSuccess()
		}
	}

	componentDidUpdate(prevProps) {
		const { loggedIn, location, displayLanguage } = this.props

		if (loggedIn && loggedIn !== prevProps.loggedIn) {
			const queryParams = queryString.parse(location.search)
			const returnUrl = queryParams.returnUrl || null
			if (returnUrl) {
				// Set user's display lang
				if (displayLanguage) changeDisplayLang(displayLanguage)

				// Reload app to get settings for new user
				window.location.href = returnUrl
			}
		}
	}

	render() {
		const { loggedIn, location } = this.props
		const { showPasswordResetSuccess } = this.state

		const queryParams = queryString.parse(location.search)
		const isSignUp = queryParams.start === SignInParam.signUp
		const returnUrl = queryParams.returnUrl || null

		if (loggedIn) {
			// Already logged in! Go Home page
			return <Redirect to={Routes.Home.linkPath} />
		} else {
			return (
				<Layout>
					{/* Reset Password success message */}
					<FormSuccess
						style={styles.resetPasswordSuccess}
						isOpen={showPasswordResetSuccess}
						autoClose={true}
						onClose={this.hidePasswordResetSuccess}
					>
						{resetPasswordLang.success}
					</FormSuccess>

					{/* RegFlow */}
					<RegFlow signUp={isSignUp} returnUrl={returnUrl} />
				</Layout>
			)
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInPage)
