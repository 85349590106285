

if (typeof Promise === 'undefined') {
	// Rejection tracking prevents a common issue where React gets into an
	// inconsistent state due to an error, but it gets swallowed by a Promise,
	// and the user has no idea what causes React's erratic future behavior.
	require('promise/lib/rejection-tracking').enable()
	window.Promise = require('promise/lib/es6-extensions.js')
}

// fetch() polyfill for making API calls.
require('whatwg-fetch')

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign')

Object.values = require('object.values')

// Polyfill for location.origin, which is not supported till FF 21, IE 11
if (!window.location.origin) {
	window.location.origin =
		window.location.protocol +
		'//' +
		window.location.hostname +
		(window.location.port ? ':' + window.location.port : '')
}

// Polyfill for Element.closest()
if (!Element.prototype.closest) {
	if (!Element.prototype.matches) {
		Element.prototype.matches =
			Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
	}
	Element.prototype.closest = function(s) {
		var el = this

		do {
			if (el.matches(s)) return el
			el = el.parentElement || el.parentNode
		} while (el !== null && el.nodeType === 1)
		return null
	}
}

// canvas.toBlob polyfill
if (!HTMLCanvasElement.prototype.toBlob) {
	Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
		value: function(callback, type, quality) {
			var canvas = this
			setTimeout(function() {
				var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
					len = binStr.length,
					arr = new Uint8Array(len)
				for (var i = 0; i < len; i++) {
					arr[i] = binStr.charCodeAt(i)
				}
				callback(new Blob([arr], { type: type || 'image/png' }))
			})
		},
	})
}

const chromeMatch = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
const chromeVersion = chromeMatch ? parseInt(chromeMatch[2], 10) : false
const isNotSuckyChrome = !chromeVersion || chromeVersion > 60

function supportsIntersectionObserver() {
	return (
		'IntersectionObserver' in global &&
		'IntersectionObserverEntry' in global &&
		'intersectionRatio' in IntersectionObserverEntry.prototype &&
		isNotSuckyChrome
	)
}

if (!supportsIntersectionObserver()) {
	require('intersection-observer')
}
