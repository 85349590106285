import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Routes from 'helpers/routes'

// Styles
import { spacing, color, fontSize, flexContainer, border } from 'styles/variables'

// Lang
import lang from './category.lang'

// Components
import TextBox from 'components/Forms/TextBox'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Actions
import { toggleSideMenu } from 'reducers/browser.reducer'

// Helpers
import keyCodes from 'helpers/keycodeHelper'

const mapStateToProps = (state, ownProps) => {
	const {
		browser: {
			// Is the side menu open?
			sideMenuOpen,
		} = {},
	} = state
	return {
		sideMenuOpen,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		toggleSideMenu: () => dispatch(toggleSideMenu()),
	}
}

//region Styles
const styles = {
	container: {
		...flexContainer(null, null),
		borderBottom: border.basic,
	},
	textBox: {
		color: color.primaryBlack,
		border: 'none',
		background: 'inherit',
		paddingLeft: spacing.narrow,
		margin: 0,
		borderStyle: 'none',
		backgroundColor: 'transparent',
		width: '100%',
		paddingRight: 0,
	},
	icon: {
		color: color.secondaryGrey,
		fontSize: fontSize.heading,
	},
}
//endregion

class SearchPosts extends Component {
	static propTypes = {
		history: PropTypes.object,
		sideMenuOpen: PropTypes.bool,
		toggleSideMenu: PropTypes.func.isRequired,
	}

	state = {
		searchTerm: '',
	}

	onChange = e => {
		this.setState({ searchTerm: e.target.value })
	}

	search = e => {
		const { toggleSideMenu, sideMenuOpen, history } = this.props
		const { searchTerm } = this.state
		if (e.keyCode === keyCodes.enter) {
			if (searchTerm) {
				if (sideMenuOpen) toggleSideMenu()
				history.push(Routes.Search.generateLinkPath(searchTerm))
				this.setState({ searchTerm: '' })
			}
		}
	}

	render() {
		const { searchTerm } = this.state

		return (
			<div style={styles.container}>
				<Icon style={styles.icon} name={Name.search} />
				<TextBox
					placeholder={lang.searchPlaceholder}
					style={styles.textBox}
					onChange={this.onChange}
					onKeyUp={this.search}
					aria-label={lang.searchAriaLabel}
					value={searchTerm}
				/>
			</div>
		)
	}
}

let WrappedSearchPosts = connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchPosts)
WrappedSearchPosts = withRouter(WrappedSearchPosts)

export default WrappedSearchPosts
