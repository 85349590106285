import { color, fontWeight, border } from 'styles/variables'

// Normalize CSS across browsers
export const styleNormalize = {
	html: {
		fontFamily: 'sans-serif',
		textSizeAdjust: '100%',
	},
	body: {
		fontFamily: 'sans-serif',
		padding: 0,
	},
	'article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary': {
		display: 'block',
	},
	'audio, canvas, video': {
		display: 'inline-block',
	},
	'audio: not([controls])': {
		display: 'none',
		height: 0,
	},
	'[hidden]': {
		display: 'none',
	},
	'a:focus': {
		outline: 'thin dotted',
	},
	'a:active, a:hover': {
		outline: 0,
	},
	'abbr[title]': {
		borderBottom: '1px dotted',
	},
	'b, strong': {
		fontWeight: fontWeight.bold,
	},
	dfn: {
		fontStyle: 'italic',
	},
	mark: {
		background: color.textHighlight,
		color: color.primaryBlack,
	},
	'code, kbd, pre, samp': {
		fontFamily: 'monospace, serif',
		fontSize: '1em',
	},
	pre: {
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word',
	},
	q: {
		quotes: '\\201C \\201D \\2018 \\2019',
	},
	small: {
		fontSize: '80%',
	},
	'sub, sup': {
		fontSize: '75%',
		lineHeight: 0,
		position: 'relative',
		verticalAlign: 'baseline',
	},
	sup: {
		top: '-.5em',
	},
	sub: {
		bottom: '-.25em',
	},
	img: {
		border: 0,
	},
	'svg: not(:root)': {
		overflow: 'hidden',
	},
	fieldset: {
		border: border.basic,
		margin: '0 2px',
		padding: '.35em .625em .75em',
	},
	'button, input, select, textarea': {
		fontFamily: 'inherit',
		fontSize: '100%',
		margin: 0,
	},
	'button, input': {
		lineHeight: 'normal',
	},
	'button, html input[type = button], input[type = reset], input[type = submit]': {
		appearance: 'button',
		cursor: 'pointer',
	},
	'button[disabled], input[disabled]': {
		cursor: 'default',
	},
	'input[type=checkbox], input[type=radio]': {
		padding: 0,
	},
	'input[type=search]': {
		appearance: 'textfield',
		boxSizing: 'content-box',
	},
	'input[type = search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration': {
		appearance: 'none',
	},
	textarea: {
		overflow: 'auto',
		verticalAlign: 'top',
	},
	table: {
		borderCollapse: 'collapse',
		borderSpacing: 0,
	},
	figure: {
		margin: 0,
	},
	'legend, button::-moz-focus-inner, input::-moz-focus-inner': {
		padding: 0,
		border: 0,
	},
	'*, *:before, *:after': {
		boxSizing: 'border-box',
	},
}
