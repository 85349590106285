import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Actions
import { hideActionDisabledDialog } from 'reducers/browser.reducer'

// Components
import Dialog from '../Dialog'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'

// Lang
import lang from './actionDisabledDialog.lang'

const mapStateToProps = state => {
	const showActionDisabledDialog = safeGetNestedProp(
		state,
		'browser.showActionDisabledDialog',
		false
	)
	return {
		showActionDisabledDialog,
	}
}
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		hideDialog: () => {
			dispatch(hideActionDisabledDialog())
		},
	}
}

const ActionDisabledDialog = ({ showActionDisabledDialog, hideDialog }) => {
	if (!showActionDisabledDialog) return null

	return (
		<Dialog
			isOpen={true}
			onClose={hideDialog}
			title={lang.warning}
			shouldCloseOnOverlayClick={false}
		>
			{lang.youAreUnableToAccess}
		</Dialog>
	)
}

ActionDisabledDialog.propTypes = {
	hideDialog: PropTypes.func,
	showActionDisabledDialog: PropTypes.bool,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ActionDisabledDialog)
