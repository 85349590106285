import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// Components
import PageDialog from 'components/Dialog/PageDialog/PageDialog'

// Actions
import { togglePageDialog as togglePageDialogFlag } from 'reducers/browser.reducer'

const PageDialogWrapper = Page => {
	const mapDispatchToProps = {
		togglePageDialogFlag,
	}

	class WrappedComponent extends Component {
		static propTypes = {
			history: PropTypes.object,
			togglePageDialogFlag: PropTypes.func,
		}

		componentDidMount() {
			const { togglePageDialogFlag } = this.props

			togglePageDialogFlag()
		}

		onClose = () => {
			const { togglePageDialogFlag, history } = this.props

			togglePageDialogFlag()
			history.goBack()
		}

		render() {
			return (
				<PageDialog isOpen={true} onClose={this.onClose}>
					<Page {...this.props} />
				</PageDialog>
			)
		}
	}

	return connect(null, mapDispatchToProps)(withRouter(WrappedComponent))
}

export default PageDialogWrapper
