import React from 'react'
import PropTypes from 'prop-types'

// Router
import { matchPath } from 'react-router'
import { withRouter } from 'react-router-dom'

// Styles
import { spacing, border, color } from 'styles/variables'
const styles = {
	section: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		borderTop: border.basic,
		borderBottom: border.basic,
		paddingTop: spacing.narrow,
		paddingBottom: spacing.narrow,
		paddingRight: spacing.wide,
		minHeight: '3em',
		flex: 1,
	},
}

class Section extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		location: PropTypes.object,
		routes: PropTypes.arrayOf(PropTypes.string),
		forceBorders: PropTypes.bool,
		style: PropTypes.object,
	}

	render() {
		const { children, location, routes = [], forceBorders, style: styleOverride } = this.props

		// Are we currently on a page that matches the section?
		const routeMatch = routes.some(route =>
			matchPath(location.pathname, { path: `${route}/:subroute?`, exact: true })
		)

		// If we're currently at this location, update the section style to indicate so
		let style = {
			paddingLeft: spacing.wide,
			...((routeMatch || forceBorders) && styles.section),
			...styleOverride,
		}

		let childComponents
		// If we're currently at this location, update the heading style to indicate so
		if (routeMatch && React.Children.count(children) > 1) {
			childComponents = children.map((child, index) => {
				const updatedChildStyle = {
					...child.props.style,
					color: color.primaryGrey,
				}
				if (index === 0) {
					return React.cloneElement(child, {
						...child.props,
						style: updatedChildStyle,
						key: 'routeMatch',
					}) // added key to get rid of warning
				} else {
					return child
				}
			})
		}
		// Otherwise just render things normally
		else {
			childComponents = children
		}

		return <div style={style}>{childComponents}</div>
	}
}

export default withRouter(Section)
