import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import UserChannelPhoto from 'components/UserChannelPhoto/UserChannelPhoto'
import UserPhotoSize from 'components/UserPhoto/userPhotoSize'
import ErrorIndicator from 'components/ErrorIndicator/ErrorIndicator'

// Lang
import lang from './shareChannels.lang'

// Helpers
import { asyncAddUserChannel, refreshUser } from 'helpers/userHelpers'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import keyCodes from 'helpers/keycodeHelper'

// Styles
import { margin, fontSize } from 'styles/variables'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { connect } from 'react-redux'

const styles = {
	base: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	channel: {
		marginRight: margin.basic,
		marginBottom: margin.basic,
	},
	actionLink: {
		fontSize: fontSize.small,
		lineHeight: UserPhotoSize.medium.size, // Match height of user profile pics
		whiteSpace: 'nowrap',
		cursor: 'pointer',
	},
	error: {
		marginTop: margin.basic,
		marginBottom: margin.basic,
		marginLeft: -margin.wide,
		marginRight: -margin.wide,
		[BreakpointNames.small]: {
			marginLeft: -margin.basic,
			marginRight: -margin.basic,
		},
	},
}

const selectUserChannel = (selectedChannels, channels, userChannelId) => {
	// Business Rule: Only 1 Twitter channel can be selected at a time
	//
	let result = [...selectedChannels] // Start with existing list

	// If userChannelId is the ID of a Twitter channel, remove any
	// other IDs of Twitter channels already in the list
	const isTwitterChannel =
		channels.find(channel => channel.userChannelId === userChannelId).provider === 'Twitter'
	if (isTwitterChannel) {
		result = result.filter(
			userChannelId =>
				channels.find(channel => channel.userChannelId === userChannelId).provider !== 'Twitter'
		)
	}

	// Add new ID to the end of the list
	result.push(userChannelId)

	return result
}

const mapStateToProps = (state, ownProps) => {
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)

	return {
		primaryColor,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		// Auth is same as AddChannel from a SocialProvider perspective
		authUserChannel: provider => dispatch(asyncAddUserChannel(provider)),
		reloadUser: () => refreshUser(dispatch),
	}
}

@responsive
class ShareChannels extends Component {
	static propTypes = {
		channels: PropTypes.arrayOf(PropTypes.object),
		onAddChannel: PropTypes.func.isRequired,
		updateChannels: PropTypes.func,
		onAuthChannel: PropTypes.func,
		viewport: PropTypes.object,

		// From Redux store
		authUserChannel: PropTypes.func,
		reloadUser: PropTypes.func,

		// From Settings
		primaryColor: Theme.PrimaryColor.propType,

		// From Formik
		field: PropTypes.shape({
			name: PropTypes.string,
			value: PropTypes.array,
		}),
		form: PropTypes.shape({
			setFieldValue: PropTypes.func,
		}),
	}

	state = {
		authChannelError: null,
	}

	resetAuthChannelError = () => {
		this.setState({ authChannelError: null })
	}

	handleAuthChannel = provider => {
		const { onAuthChannel, authUserChannel, reloadUser } = this.props

		// Remove any previous error
		this.resetAuthChannelError()

		// Call the Social Popup to add a new channel
		return authUserChannel(provider)
			.then(socialPopupResponse => {
				// Channel authorized successfully. Notify container, if interested
				if (onAuthChannel) onAuthChannel(socialPopupResponse)

				// Refresh the user to show connected channel
				reloadUser()

				return socialPopupResponse
			})
			.catch(result => {
				// Show error on page. SocialPopup (AddChannel) errors are user-facing and translated
				const message =
					safeGetNestedProp(result, 'error.messages[0]') || lang.authChannelErrorUnknown
				this.setState({ authChannelError: message })

				throw result
			})
	}

	render() {
		const {
			field: { name, value },
			form: { setFieldValue },
			channels,
			onAddChannel,
			updateChannels,
			primaryColor,
			viewport,
		} = this.props
		const { authChannelError } = this.state

		const selectedChannels = value || []

		const selectChannel = userChannelId => {
			let newValue = [...selectedChannels]
			let selected = !selectedChannels.includes(userChannelId) // Toggle selection

			if (selected) {
				// Add newly selected channel (apply business rules)
				newValue = selectUserChannel(selectedChannels, channels, userChannelId)
			} else {
				// Remove deselected channel
				newValue.splice(newValue.indexOf(userChannelId), 1)
			}

			// Remove previous error
			if (authChannelError) {
				this.resetAuthChannelError()
			}

			// Update value
			setFieldValue(name, newValue)

			// Update channels in state
			updateChannels(newValue)
		}

		const handleClick = (userChannelId, authRequired) => e => {
			e.preventDefault()

			handleSelectChannel(userChannelId, authRequired)
		}

		const handleShareChannelKeyDown = (userChannelId, authRequired) => e => {					
			if (e.keyCode === keyCodes.enter) {
				handleSelectChannel(userChannelId, authRequired)
			}			 
		}

		const handleAddShareChannelKeyDown = e => {				
			if (e && e.keyCode === keyCodes.enter) {
				onAddChannel()
			}			 
		}

		const handleSelectChannel = (userChannelId, authRequired) => {
			if (authRequired) {
				// Reauthorize channel, then select it
				const channel = channels.find(channel => channel.userChannelId === userChannelId)

				this.handleAuthChannel(channel.provider)
					.then(() => selectChannel(userChannelId))
					.catch(error => {
						/* Ignore */
					})
			} else {
				// Select/Deselect channel
				selectChannel(userChannelId)
			}
		}

		// Styles
		const dynamicStyles = {
			actionLink: {
				...styles.actionLink,
				color: primaryColor,
			},
			error: {
				...styles.error,
				...styles.error[viewport.size],
			},
		}

		return (
			<Fragment>
				<ErrorIndicator
					isOpen={!!authChannelError}
					message={authChannelError}
					style={dynamicStyles.error}
				/>
				<div style={styles.base}>
					{channels.map(channel => {
						const selected = selectedChannels.includes(channel.userChannelId)
						const disabled = !selected

						return (
							<UserChannelPhoto
								key={channel.userChannelId}
								style={styles.channel}
								provider={channel.provider}
								tooltip={channel.displayName}
								disabled={disabled}
								authRequired={channel.authRequired}
								photos={channel.profilePictureImages}
								onClick={handleClick(channel.userChannelId, channel.authRequired)}
								onKeyDown={handleShareChannelKeyDown(channel.userChannelId, channel.authRequired)}
								tabIndex={0}
							/>
						)
					})}
					<div>
						<span 
							style={dynamicStyles.actionLink} 
							onClick={onAddChannel}	
							onKeyDown={handleAddShareChannelKeyDown}						
							tabIndex={0}
						>
							{channels.length === 0 ? lang.addFirstChannelButton : lang.addChannelButton}
						</span>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShareChannels)
