import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { fontSize, color, padding } from 'styles/variables'

const styles = {
	width: '100%',
	display: 'inline-flex',
	justifyContent: 'center',
	padding: padding.narrow,
	fontSize: fontSize.base,
	backgroundColor: color.dsBrandPrimaryMedium,
	color: color.white,
}

export default prefixer.prefix(styles)
