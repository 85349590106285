// ParticipantData represents a single participant. Please add any missing props or calculations you need below.
//	o Keep lists in alphabetical order!
/**
 * ParticipantData represents a single participant.
 * @param {Object} participant
 */
const ParticipantData = participant => {
	return {
		userId: participant.userId,

		// Native props - Alphabetical, please!
		firstName: participant.firstName,
		fullName: participant.fullName,
		isActive: participant.isActive,
		status: participant.status,
		userProfilePictureSquare40Url: participant.userProfilePictureSquare40Url,

		// Calculated props - Alphabetical, please!
		// - Use these when the property isn't a 1:1 map with the API participant object

		/** Verifies that the participant meets the minimum requirements needed to be valid. */
		get isValid() {
			delete this.isValid
			// TODO : Add more of these qualifiers
			return (this.isValid = !!this.userId)
		},
	}
}
export default ParticipantData
