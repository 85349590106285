// Redux
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk' // Adds middleware for asynchronous action creators
import { routerMiddleware } from 'react-router-redux'
import { persistReducer, persistStore } from 'redux-persist'
import localForage from 'localforage'
import reducers from 'reducers/root.reducer'
import createHistory from 'history/createBrowserHistory'
import cookie from 'react-cookies'

import { safeGetNestedProp } from '@dysi/js-helpers'
import { impersonateUserMiddleware } from 'middleware/impersonateUserMiddleware'
import { authCookieTransferMiddleware } from 'middleware/authCookieTranserMiddleware'
import { openSubmitPostMiddleware } from 'middleware/openSubmitPostMiddleware'

// Helpers

// Create the history object
const historyOptions = { basename: process.env.PUBLIC_URL } // For debugging
export const history = createHistory(historyOptions)

// Clears PageDialog state
if (history.location && history.location.state && history.location.state.showPageDialog) {
	const state = { ...history.location.state }
	delete state.showPageDialog
	history.replace({ ...history.location, state })
}

// Enable browser debugging via Redux DevTools if we're in development mode
let composeEnhancers = compose
if (!process.env.DS_ENV_IS_PRODUCTION && !process.env.DS_ENV_IS_INTEGRATION) {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const middlewares = [
	thunk, // Asynchronous action creators
	routerMiddleware(history),
	authCookieTransferMiddleware(history), // Take auth from [MemberAppRedirect] cookie
	impersonateUserMiddleware(history), // Impersonate user passed in URL as token/expiration pair
	openSubmitPostMiddleware(history),
]

const enhancers = composeEnhancers(applyMiddleware(...middlewares))

// redux-persist configuration
const config = {
	key: 'ds-ma',
	whitelist: [], // No peristing, just initializing
	storage: localForage,
}

const persistedReducer = persistReducer(config, reducers)

const store = createStore(
	persistedReducer,
	{
		...(process.env.DS_ENV_IS_DEPLOYED ? window.injectedState : null),
	},
	enhancers
)

// Watch store updates
const initialState = store.getState()
let oldUserLang = initialState.user && initialState.user.primaryLanguage

export const changeDisplayLang = lang => {
	// Save Language in Cookie for server-side rendering
	const expiresInOneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
	cookie.save('dysi_lang', lang, {
		secure: true,
		path: '/',
		expires: expiresInOneYear,
		domain: `.${window.location.host}`,
	})
}

store.subscribe(() => {
	const state = store.getState()
	const newUserLang = safeGetNestedProp(state, 'auth.user.primaryLanguage')

	// Make sure oldUserLang has an initial value before comparing.
	if (newUserLang && !oldUserLang) oldUserLang = newUserLang

	if (newUserLang && newUserLang !== oldUserLang) {
		oldUserLang = newUserLang
		changeDisplayLang(newUserLang)
		window.location.reload(true)
	}
})

export const persistor = persistStore(store)

export default store
