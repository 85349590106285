import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import Dialog from 'components/Dialog/Dialog'
import CustomLink from './CustomLink'
import ImagePlaceholder from 'components/Placeholders/ImagePlaceholder'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'

//#region Styles
import { border, margin, flexContainer, spacing } from 'styles/variables'

const styles = {
	container: {
		...flexContainer('row', 'flex-start'),
		flexWrap: 'wrap',
	},
	icon: {
		border: border.basic,
		borderRadius: border.roundedRadius,
		marginRight: margin.basic,
		width: spacing.gutter,
		height: spacing.gutter,
	},
	linkContainer: {
		height: spacing.gutter,
		width: 240,
		marginTop: margin.narrow,
		marginBottom: margin.narrow,
		marginLeft: margin.narrow,
		marginRight: margin.narrow,
		cursor: 'pointer',
		link: {
			...flexContainer('row', 'flex-start'),
		},
	},
	dialog: {
		maxWidth: 600,
	},
}
//#endregion

class QuickLinksDialog extends Component {
	static propTypes = {
		onClose: PropTypes.func,
		links: PropTypes.array.isRequired,
	}

	render() {
		const { onClose, links } = this.props

		return (
			<Dialog
				isOpen={true}
				onClose={onClose}
				style={styles.dialog}
				title="Quick Links" // For logging only
				titleOverride={<span />} // No title on this dialog
			>
				<div style={{ ...flexContainer() }}>
					<div style={styles.container}>
						{links.map(link => {
							const image = {
								url: link.image,
								width: styles.icon.width,
								height: styles.icon.height,
							}

							return (
								<div style={styles.linkContainer} key={link.id}>
									<CustomLink
										link={link}
										isQuickLink={true}
										render={
											<div style={styles.linkContainer.link}>
												<ImagePlaceholder image={image} alt="" style={styles.icon} />
												<RestrictedText text={link.name} limit={Restrictions.CustomLinks}>
													{text => <div>{text}</div>}
												</RestrictedText>
											</div>
										}
									/>
								</div>
							)
						})}
					</div>
				</div>
			</Dialog>
		)
	}
}

export default QuickLinksDialog
