import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Shared
import UserPhotoSize from './userPhotoSize'

import Icon, { Name } from 'components/Icons/FontAwesome'

// Styles
import { color } from 'styles/variables'

const styles = {
	// Base
	width: '1em',
	height: '1em',
	lineHeight: '1em',
	// Sizes
	tiny: {
		fontSize: UserPhotoSize.tiny.size,
	},
	small: {
		fontSize: UserPhotoSize.small.size,
	},
	medium: {
		fontSize: UserPhotoSize.medium.size,
	},
	large: {
		fontSize: UserPhotoSize.large.size,
	},
	// TODO - Refactor this!!
	count: {
		position: 'absolute',
		color: color.secondaryRed,
		bottom: 0,
		right: 0,
		fontSize: 20,
		lineHeight: 1,
	},
}

// NOTE: These colors should match iOS default user background colors.
const defaultUserImageBkgdColors = [
	'#bb5778', // [0] Red
	'#bf9d4c', // [1] Gold
	'#64b042', // [2] Green
	'#319ab4', // [3] Teal
	'#8366c0', // [4] Purple
]

const defaultProfilePictureBkgdColor = id => {
	const index = id % defaultUserImageBkgdColors.length
	return defaultUserImageBkgdColors[index]
}

class GenericUserPhoto extends React.Component {
	static propTypes = {
		tooltip: PropTypes.string,
		size: PropTypes.oneOf(Object.values(UserPhotoSize).map(s => s.name)),
		id: PropTypes.number.isRequired,
		count: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		style: PropTypes.object,
	}

	static defaultProps = {
		size: UserPhotoSize.medium.name,
	}

	render() {
		const { tooltip, size, id, count, style: styleOverride } = this.props
		const dynamicStyles = {
			icon: {
				...styles,
				...styles[size],
				color: defaultProfilePictureBkgdColor(id),
				...styleOverride,
			},
		}

		return (
			<Fragment>
				<Icon name={Name.userCircle} style={dynamicStyles.icon} title={tooltip} />
				{!!count && (
					// TODO - Refactor this crap out of the UserPhoto component!!!
					<Icon
						name={Name.circle}
						text={`+${count}`}
						style={styles.count}
						transform={{ size: 8 }}
					/>
				)}
			</Fragment>
		)
	}
}

export default GenericUserPhoto
