// Make an asynchronous request to the VoiceStorm API
//
// You can pass parameters in order:
//		vsApi(endpoint, actions, requestOptions, context, options)
// or as a single object with only the params you care about:
//		vsApi( { endpoint: 'user', options: { ignoreAuthFailure: true } } )
//
// To save API response in the Redux store, use the actions param to dispatch actions.
//		vsApi('community/info', { success: SET_SPHERE_INFO })
//
// To get the API response directly, wait on the Promise returned by dispatch.
//  	dispatch(vsApi('community/info'))
//			.then(response => console.log(`communityName: ${response.communityName}`))
//			.catch(error => console.log(`Error: ${error.code}`))
//
// Params:
//		endpoint: API endpoint without version. Ex: post/categories
//		actions: Redux action types object to dispatch during the request
//			request - Request started
//			success - Request completed successfully (fetch.response.ok, HTTP Status 200 - 299)
//			failure - Request completed, but failed (!fetch.response.ok, HTTP Status > 200)
//			Ex: { request: FETCH_CATEGORY_POSTS_REQUEST, success: FETCH_CATEGORY_POSTS_SUCCESS, failure: FETCH_CATEGORY_POSTS_FAILURE }
//		requestOptions: Additional options/overrides for the fetch request. See Request constructor init options https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
//			Ex: { method: 'POST', body: form }
//			Default: { method: 'GET' }
//		context: Any addition data you want returned in the action results
//		options: Changes to default behavior
//			ignoreAuthFailure - Set to true to ignore authorization failures. Default, false, routes to /auth/signin on auth failure
//			abortable - If you might need to abort the request, set this property to a function that will be passed another function
//			            that can be called to abort the request
//			captureUnhandledPromiseRejection - Default set to true to ignore unhandled promise rejections.

import moment from 'moment-timezone'
import { isPlainObject, safeGetNestedProp } from '@dysi/js-helpers'

// Convenience Imports
export const requests = require('./apiRequests').default

// Actions
import { setMemberUsageCompliance } from '../scenes/MemberUsageCompliance/memberUsageCompliance.reducer.js'
import {
	clearCurrentUser,
	asyncGetShortToken,
	asyncGetLongToken,
	asyncRefreshTokens,
} from 'scenes/Auth/auth.reducer'
import { saveAuthTokens } from 'helpers/persistor'

// Helpers

// API URL Builder
export const apiUrlBuilder = (state, endpoint) => {
	const apiRoot = safeGetNestedProp(state, 'sphere.apiRoot', `https://${window.location.host}`)
	const apiVersion = safeGetNestedProp(state, 'sphere.apiVersion', 'v1')
	const url = `${apiRoot}/${apiVersion}/${endpoint}`

	return url
}

// PromiseRejectionEvent/'unhandledrejection' is only supported by Chrome right now
export const supportsPromiseRejectionEvent = () => typeof PromiseRejectionEvent === 'function'

// Token expiration tester
const isTokenValid = (token, tokenExpiration) => {
	return (
		token &&
		tokenExpiration &&
		moment(tokenExpiration)
			.subtract(2, 'minutes')
			.diff(moment()) > 0
	)
}

// Build a fetch request
const getVoiceStormApiRequest = (state, endpoint, options) => {
	const headers = new Headers()
	const {
		auth: {
			token: longToken,
			tokenExpiration: longTokenExpiration,
			shortToken,
			shortTokenExpiration,
			user,
		},
		sphere,
	} = state
	const userId = safeGetNestedProp(user, 'userId')
	const sphereId = safeGetNestedProp(sphere, 'sphereId')
	const isShortTokenAvailable = isTokenValid(shortToken, shortTokenExpiration)
	const token = (isShortTokenAvailable ? shortToken : longToken) || null
	const tokenExpiration =
		(isShortTokenAvailable ? shortTokenExpiration : longTokenExpiration) || null
	const isTokenExpired = new Date(tokenExpiration) < new Date()

	// Internal headers for logging purposes
	headers.append('X-DS-Source-Name', process.env.NMA_BUILD_ID || 'MISSING NMA_BUILD_ID')

	// Additional headers in-case API is rerouted to loki/Voyage
	if (sphereId) {
		headers.append('Sphere-Id', sphereId)
	}
	if (userId) {
		headers.append('User-Id', userId)
	}

	// Add Request headers
	if (token && !isTokenExpired) {
		headers.append('Authorization', `AccessToken ${token}`)
		headers.append('Session-Id', `${token}`) // For loki
	}

	// NOTE - Don't change this. It's set via webpack for each bundle
	// This is populated via environment variables and the webpack DefinePlugin
	if (process.env.DS_ENV_IS_DEPLOYED) {
		headers.append('OverrideLang', process.env.LOCALE)
	}

	const defaultOptions = {
		method: 'GET',
	}

	// Combine built-in headers with requested headers.
	// Requested headers override default headers
	if (options && options.headers) {
		for (var header of options.headers.entries()) {
			headers.set(header[0], header[1])
		}
	}

	// Build the Request
	const url = apiUrlBuilder(state, endpoint)
	const init = {
		...defaultOptions,
		...options,
		headers,
	}

	return new Request(url, init)
}

export const vsApi = (endpoint, actions, requestOptions, context, options) => {
	// Options object instead of args? vsApi({ endpoint: 'user', options: { ignoreAuthFailure: true } })
	if (isPlainObject(endpoint)) {
		// Map options to arguments
		const optionsObject = { ...endpoint }
		endpoint = optionsObject.endpoint
		actions = optionsObject.actions
		requestOptions = optionsObject.requestOptions
		context = optionsObject.context
		options = optionsObject.options
	}
	options = { captureUnhandledPromiseRejection: true, ...options } || {
		captureUnhandledPromiseRejection: true,
	}

	// Create an abortable promise to return to dispatch
	let abortable = options.abortable
	let resolve = null
	let reject = null
	let promise = new Promise((res, rej) => {
		resolve = res
		reject = rej

		// If requested, create an abort function and pass it to the caller
		if (abortable) {
			// Pass abort function to caller
			const abort = () => {
				rej({ code: 'request_aborted', messages: ['Request aborted in vsApi'] })
			}
			abortable(abort)
			abortable = null // Remove our reference to the caller
		}
	}).finally(() => {
		// This promise is fullfilled. Don't allow any more actions
		resolve = null
		reject = null
	})

	// Return a function that will be called asynchronously by the Redux Thunk middleware
	return (dispatch, getState) => {
		actions = actions || {}

		// Dispatch 'Start Request' action
		if (actions.request) {
			let action = { type: actions.request }
			if (context) action.context = context
			dispatch(action)
		}

		const handleResponse = response => {
			// Capture JSON response. Reject if API call returned an error
			return response.json().then(
				json => {
					// API error (HTTP status > 200)?
					if (!response.ok) {
						return Promise.reject({
							...json,
							status: response.status,
						})
					}

					// Dispatch 'Request Succeeded' action
					if (actions.success) {
						let action = { type: actions.success, response: json }
						if (context) action.context = context
						dispatch(action)
					}

					return json // Return the promise result to the rest of the chain
				},
				error =>
					Promise.reject({
						code: 'server_error',
						messages: ['Response from server not JSON'],
						status: response.status,
					})
			)
		}

		const handleNetworkError = () => {
			// error => {
			return Promise.reject({ code: 'network_error', messages: ['Network connection error'] }) // TODO: i18n
		}

		const handleRejectedPromises = error => {
			const reason = error.messages ? error.messages[0] : 'none'

			// Polyfill for unhandledrejection
			const dispatchUnhandledRejectionEvent = (promise, reason) => {
				const event = document.createEvent('Event')

				Object.defineProperties(event, {
					promise: {
						value: promise,
						writable: false,
					},
					reason: {
						value: new Error(reason),
						writable: false,
					},
				})

				// we've changed this event name so that Firefox/react-error doesn't complain aka for local testing.
				event.initEvent('handledrejection', false, true)
				window.dispatchEvent(event)
			}

			if (supportsPromiseRejectionEvent()) {
				return new PromiseRejectionEvent('unhandledrejection', {
					promise,
					reason,
				})
			} else {
				dispatchUnhandledRejectionEvent(promise, reason)
			}
		}

		const dispatchFailure = error => {
			if (error && error.code) {
				const reason =
					safeGetNestedProp(error, 'data.Reason') || safeGetNestedProp(error, 'data.reason')

				// A token can be invalid in 2 ways:
				// 1. We sent an unexpired token to the server, but the server rejected it (Reason token_invalid)
				// 2. We had no token or it was expired, so we didn't send it with the request
				const isTokenInvalid = reason === 'token_invalid' || !token || isTokenExpired

				switch (error.code) {
					case 'unauthorized':
						if (!options.ignoreAuthFailure && isTokenInvalid) {
							// Invalid token. Clear user, force new sign in
							dispatch(clearCurrentUser())
						}
						if (options.captureUnhandledPromiseRejection) {
							handleRejectedPromises(error)
						}
						break
					case 'usage_compliance_required':
						if (error.data) {
							dispatch(setMemberUsageCompliance(error.data, error.messages))
						}
						break
					case 'invalid_request':
						if (options.captureUnhandledPromiseRejection) {
							handleRejectedPromises(error)
						}
						break
					default:
						break
				}
			}

			// Dispatch 'Request Failed' action
			if (actions.failure) {
				let action = { type: actions.failure, error: error }
				if (context) action.context = context
				dispatch(action)
			}

			return Promise.reject(error) // Reject the result if unhandled by actions
		}

		// Refresh tokens, if needed, for future requests
		const state = getState()
		const token = safeGetNestedProp(state, 'auth.token')
		const tokenExpiration = safeGetNestedProp(state, 'auth.tokenExpiration')
		const shortToken = safeGetNestedProp(state, 'auth.shortToken')
		const shortTokenExpiration = safeGetNestedProp(state, 'auth.shortTokenExpiration')
		const isShortTokenPending = safeGetNestedProp(state, 'auth.isShortTokenPending')
		const isTokenPending = safeGetNestedProp(state, 'auth.isTokenPending')
		const refreshToken = safeGetNestedProp(state, 'auth.refreshToken')
		const deviceId = safeGetNestedProp(state, 'auth.deviceId')
		const isRefreshTokenPending = safeGetNestedProp(state, 'auth.isRefreshTokenPending')
		const isTokenExpired = new Date(tokenExpiration) < new Date()

		// Get short token if not already getting it, unavailable, or about to expire
		if (
			token &&
			!isTokenExpired &&
			!isShortTokenPending &&
			!isTokenValid(shortToken, shortTokenExpiration)
		) {
			// Get short token for future API calls

			dispatch(asyncGetShortToken()).then(response => {
				// Persist short token in browser storage
				saveAuthTokens({
					shortLivedToken: response.token,
					shortLivedTokenExpiration: response.expiration,
				})
			})
		}

		// DA-217 Get long token if not already getting it, unavailable, or about to expire
		if (
			token &&
			tokenExpiration &&
			!isTokenExpired &&
			!isTokenPending &&
			!isTokenValid(token, tokenExpiration)
		) {
			// Get a new long token for future API calls

			dispatch(asyncGetLongToken()).then(response => {
				// Persist long token in browser storage
				saveAuthTokens({
					token: response.token,
					expiration: response.expiration,
				})
			})
		}

		// Refresh all tokens if token is about to expire, we have a refresh token, and we're
		// not already trying to update the tokens
		if (
			refreshToken &&
			deviceId &&
			!isRefreshTokenPending &&
			!isTokenValid(token, tokenExpiration)
		) {
			// Refresh all tokens for future API calls
			dispatch(asyncRefreshTokens(refreshToken, deviceId)).then(response => {
				saveAuthTokens(response)
			})
		}

		// Make the API call
		fetch(getVoiceStormApiRequest(getState(), endpoint, requestOptions))
			// Handle the fetch response
			.then(handleResponse, handleNetworkError)
			.then(response => {
				if (resolve) resolve(response) // If resolvable, resolve the abortable promise
			})
			.catch(dispatchFailure)
			.catch(error => {
				if (reject) reject(error) // If rejectable, reject the abortable promise
			})

		return promise
	}
}
