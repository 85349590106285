import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

// Components
import ImageWithPlaceholder from 'components/Placeholders/ImagePlaceholder'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'
import Icon, { Name } from 'components/Icons/FontAwesome'
import FallbackPost from 'scenes/Post/FallbackPost'
import ErrorBoundary from 'wrappers/ErrorBoundary'

// Helpers
import Routes from 'helpers/routes'
import { DisplayMode } from 'scenes/Post/data/post.data'
import { getPostImageSize } from 'helpers/imageHelpers'
import { extractHostnameFromUrl } from 'helpers/postHelpers'
import { BreakpointNames } from 'wrappers/ResponsiveComponent'
import Theme from 'wrappers/Theme'

// Enums
import postLayout from 'scenes/Post/enums/postLayout'

// Styles
import styles from './ShareSummary.styles'

const ShareSummaryImageSizes = {
	Small: {
		height: 150,
	},
	Medium: {
		height: 150,
		width: 320,
	},
}

/** This is similar to Post Summary,  but exclusive to Share Dialog V8 */
class ShareSummary extends Component {
	static propTypes = {
		viewport: PropTypes.object,
		media: PropTypes.shape({
			hasVideoUrl: PropTypes.bool,
			height: PropTypes.number,
			width: PropTypes.number,
			html: PropTypes.shape,
			mimeType: PropTypes.string,
			name: PropTypes.string,
			provider: PropTypes.string,
			role: PropTypes.string,
			url: PropTypes.string,
		}),
		image: PropTypes.shape({
			baseUrl: PropTypes.string,
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		displayMode: PropTypes.string,
		postId: PropTypes.string,
		urlSlug: PropTypes.string,
		viewPost: PropTypes.func,
		title: PropTypes.string,
		permaLink: PropTypes.string,
		isSocialPost: PropTypes.bool,
		primaryColor: Theme.PrimaryColor.propType,
	}

	getPostMediaStyle = (media, viewport) => {
		if (!media) return
		let width, height, ratio

		if (
			viewport &&
			viewport.size === BreakpointNames.small &&
			media.height > ShareSummaryImageSizes.Small.height
		) {
			ratio = ShareSummaryImageSizes.Small.height / media.height
			height = ShareSummaryImageSizes.Small.height
			width = media.width * ratio
		} else if (media.height > ShareSummaryImageSizes.Medium.height) {
			ratio = ShareSummaryImageSizes.Medium.height / media.height
			height = ShareSummaryImageSizes.Medium.height
			width = media.width * ratio
		}

		return {
			height,
			width,
			url: media.url || '',
		}
	}

	renderPostMedia = () => {
		const { image, media, viewport } = this.props

		if (!image && !media) return null

		const blankImageStyle = {
			...styles.blankImage,
			...this.getPostMediaStyle(media),
		}
		const imageContainerStyle = {
			...styles.imageContainer,
			...styles.imageContainer[viewport.size],
		}

		if (image) {
			let postImage = getPostImageSize(postLayout.feed, image, viewport)
			postImage = this.getPostMediaStyle(postImage, viewport.size)
			return (
				<div style={imageContainerStyle}>
					<ImageWithPlaceholder
						image={postImage}
						renderError={<Icon name={Name.exclamationCircle} />}
					/>
				</div>
			)
		} else {
			return <div style={blankImageStyle} />
		}
	}

	renderPostText = () => {
		const { title, permaLink, primaryColor, isSocialPost, viewport } = this.props
		const dynamicStyles = {
			title: {
				...styles.textContainer.title,
				...styles.textContainer.title[viewport.size],
			},
			link: {
				...styles.textContainer.text,
				...styles.textContainer.text[viewport.size],
				color: primaryColor,
			},
		}
		return (
			<Fragment>
				<RestrictedText
					text={title}
					limit={
						isSocialPost ? Restrictions.PostTitle.Social.Summary : Restrictions.PostTitle.NonSocial
					}
				>
					{title => <p style={dynamicStyles.title}>{title}</p>}
				</RestrictedText>
				<p style={dynamicStyles.link}>{extractHostnameFromUrl(permaLink)}</p>
			</Fragment>
		)
	}

	render() {
		const { viewport, title, urlSlug, postId, viewPost, displayMode } = this.props
		const displayInApp = displayMode === DisplayMode.displayInApp
		const dynamicStyles = {
			container: {
				...styles.container,
				...styles.container[viewport.size],
				...(!displayInApp && { cursor: 'pointer' }),
			},
			textContainer: {
				...styles.textContainer,
				...styles.textContainer[viewport.size],
			},
		}
		const postSummary = (
			<Fragment>
				{this.renderPostMedia()}
				<div style={dynamicStyles.textContainer}>{this.renderPostText()}</div>
			</Fragment>
		)

		// Unless the post is out of app, don't let it be clickable.
		if (displayInApp) {
			return <div style={dynamicStyles.container}>{postSummary}</div>
		} else {
			const url = Routes.Post.Details.generateLinkPath(urlSlug, postId, false, null, null, {})
			return (
				<a
					href={url}
					style={dynamicStyles.container}
					rel="noopener noreferrer"
					target="_blank"
					aria-label={title}
					onClick={() => viewPost(postId)}
				>
					{postSummary}
				</a>
			)
		}
	}
}

const WrappedShareSummary = ErrorBoundary(ShareSummary, FallbackPost)
export default WrappedShareSummary
