import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

// TODO - The individual PostAction icons might be decomposable. This would help with
// creating stories and testing.

// Helpers
import { Settings } from 'wrappers/SettingsWrapper'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import { Activity, getActivity, getActivityFromUrl } from 'helpers/activityHelpers'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { safeGetSetting } from 'wrappers/Settings'
import SocialProviderData from 'helpers/socialProviderData'
import VideoTypes from 'scenes/Post/enums/videoTypes'
import { testDataAttributes } from 'helpers/testDataAttributes'
import Routes from 'helpers/routes'
import { dispatchIfNotImpersonating } from 'helpers/impersonationHelpers'
import keyCodes from 'helpers/keycodeHelper'

// Actions
import { openShareDialog, asyncLikePost, asyncGetPost } from 'scenes/Post/post.reducer'
import { asyncGetBroadcast } from 'scenes/Broadcast/broadcast.reducer'
import { asyncGetFacebookLink, asyncGetTwitterLink } from 'scenes/ShareDialog//apiHelpers'
import { asyncAddUserChannel, refreshUser } from 'helpers/userHelpers'

// Components
import Icon, { Name, Modifier } from 'components/Icons/FontAwesome'
import SharingOptionsBar from 'scenes/ShareDialog/components/SharingOptionsBar'

// Lang
import buttons from 'helpers/lang/buttons.lang'
import lang from 'scenes/ShareDialog/shareDialog.lang'

// Enums
import ExternalFeedActions from 'pages/External/enums/ExternalFeedActions'

//#region Styles
import { color, padding, border, width, textButtonStyle } from 'styles/variables'

const styles = {
	// base
	base: {
		maxWidth: width.layout.mainNoSidebar,
		borderTop: border.basic,
		paddingTop: padding.narrow,
		marginTop: width.spacing.narrow,
		marginBottom: width.spacing.narrow,
		marginLeft: width.spacing.basic,
		marginRight: width.spacing.basic,
	},
	innerContainer: {
		display: 'flex',
		alignItems: 'center', // align icon and Share text along the horizontal center
		marginLeft: -width.spacing.basic,
		marginRight: -width.spacing.basic,
	},
	action: {
		flex: 1,
		display: 'flex',
		justifyContent: 'center',
		...textButtonStyle,
		paddingTop: padding.narrow,
		paddingBottom: padding.narrow,
	},
	actionLabel: {
		marginLeft: width.spacing.narrow,
		alignSelf: 'center',
	},
	commentLike: {
		justifyContent: 'space-between',
	},
	icon: {
		fontSize: 18,
		color: color.secondaryGrey,
		cursor: 'pointer',
		pointerEvents: 'all',
	},
	iconSeparator: {
		width: 0,
		borderLeft: border.basic,
	},
}
//#endregion

const mapStateToProps = state => {
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const host = safeGetNestedProp(state, 'sphere.host')
	const user = safeGetNestedProp(state, 'auth.user', null)
	const sharePostInfo = state.post.sharePost
	const nativeSharingHostUrl = `https://${host}/widget/nativesharing`
	const facebookNativeConfig = safeGetSetting(state, Settings.Features.FacebookNativeConfig) || {}
	const twitterNativeConfig = safeGetSetting(state, Settings.Features.TwitterNativeConfig) || {}
	const isEnhancedSharingFlowEnabled = safeGetSetting(
		state,
		Settings.Features.EnableEnhancedSharingFlow
	)
	const socialProviders = safeGetNestedProp(state, 'sphere.providers')
	const userChannels = safeGetNestedProp(state, 'auth.user.channels')

	const showShareButtonOnSocialPosts = safeGetSetting(
		state,
		Settings.Features.ShowShareButtonOnSocialPosts
	)

	return {
		primaryColor,
		user,
		sharePostInfo,
		nativeSharingHostUrl,
		facebookNativeConfig,
		twitterNativeConfig,
		isEnhancedSharingFlowEnabled,
		socialProviders,
		userChannels,
		showShareButtonOnSocialPosts,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	const {
		post: { postId },
		broadcastId,
	} = ownProps
	let activity = broadcastId && getActivity(Activity.Reason.broadcast, broadcastId)
	if (!activity) {
		activity = getActivityFromUrl(window.location)
	}

	return {
		likePost: isLikedByUser =>
			dispatchIfNotImpersonating(
				asyncLikePost(postId, isLikedByUser, activity, { broadcastId }),
				true,
				true
			),
		getPost: () => dispatch(asyncGetPost(postId)),
		getFacebookLink: (postId, activity) => dispatch(asyncGetFacebookLink(postId, activity)),
		getTwitterLink: (postId, activity) => dispatch(asyncGetTwitterLink(postId, activity)),
		fetchBroadcast: () => dispatch(asyncGetBroadcast(broadcastId)),
		commentOnPost: postUrl => dispatch(push(postUrl)), // Redirect to Post Details page, with Discussions
		addUserChannel: provider =>
			dispatchIfNotImpersonating(asyncAddUserChannel(provider), true, true),
		sharePost: () => dispatch(openShareDialog(postId, [postId], null, activity)),
		reloadUser: () => refreshUser(dispatch),
	}
}

//#region Post Actions subcomponents
const Separator = () => {
	return <span style={styles.iconSeparator}>&nbsp;</span>
}

// Like Component
const Like = React.memo(({ handleLikePost, showLabels, color, buttonText, isLikedByUser }) => {
	return (
		<button
			style={styles.action}
			onClick={handleLikePost}
			data-test-id={testDataAttributes.postLike}
			data-test-liked={isLikedByUser}
		>
			<Icon
				style={styles.icon}
				modifier={Modifier.fixedWidth}
				name={Name.thumbsUp}
				title={buttons.like}
				color={color}
			/>
			{showLabels && <span style={styles.actionLabel}>{buttonText}</span>}
		</button>
	)
})

Like.propTypes = {
	buttonText: PropTypes.string,
	handleLikePost: PropTypes.func,
	showLabels: PropTypes.bool,
	color: PropTypes.string,
	isLikedByUser: PropTypes.bool,
}

// Comment Component
const Comment = React.memo(({ handleCommentPost, color, showLabels }) => {
	return (
		<button style={styles.action} onClick={handleCommentPost}>
			<Icon
				style={styles.icon}
				modifier={Modifier.fixedWidth}
				name={Name.comment}
				title={buttons.comment}
				color={color}
			/>
			{showLabels && <span style={styles.actionLabel}>{buttons.comment}</span>}
		</button>
	)
})

Comment.propTypes = {
	handleCommentPost: PropTypes.func,
	showLabels: PropTypes.bool,
	color: PropTypes.string,
}

// ShareBar Component
const ShareBar = ({
	post,
	isEnhancedSharingFlowEnabled,
	user,
	nativeSharingHostUrl,
	facebookNativeConfig,
	twitterNativeConfig,
	getFacebookLink,
	getTwitterLink,
	getPost,
	socialProviders,
	userChannels,
	addUserChannel,
	reloadUser,
	handleSharePost,
	primaryColor,
	showFullActions,
	handleCloseDialog,
	sharePostInfo,
}) => {
	let isFacebookNativeSharingEnabled = facebookNativeConfig.isEnabled
	let isTwitterNativeSharingEnabled = twitterNativeConfig.isEnabled

	const isAnonymousUser = !user && isEnhancedSharingFlowEnabled

	// Anonymous users: If the native sharing is enabled by the community and enhanced Share Flow is enabled,
	// show the icons, but make them open the Sign Up To Share dialog
	if (isAnonymousUser) {
		isFacebookNativeSharingEnabled = facebookNativeConfig.isEnabled
		isTwitterNativeSharingEnabled = twitterNativeConfig.isEnabled
	}

	// This is to space this evenly with the other icons, since the share icon has whitespace.
	const openShareDialogStyle = {
		...styles.action,
		marginLeft: 4.5,
		marginRight: 4.5,
		marginTop: 0,
		marginBottom: 0,
		width: '100%',
	}

	const openShareDialog = (
		<span style={openShareDialogStyle} onClick={handleSharePost}>
			<Icon
				style={{
					...styles.icon,
					// NOTE: Design wanted this bigger
					fontSize: styles.icon.fontSize + 1,
				}}
				color={post.isSharedByUser ? primaryColor : color.secondaryGrey}
				modifier={Modifier.fixedWidth}
				name={Name.share}
			/>
		</span>
	)

	const isNativeVideo =
		post &&
		post.media &&
		post.media.role === (VideoTypes.hostedVideo || VideoTypes.hostedAdvancedVideo)

	// User channels.
	const createUserChannel = (provider, socialChannels) => ({
		canonicalName: provider.canonicalName,
		alreadyConnected: isAnonymousUser || !!socialChannels.length,
		addChannel: () => handleAddChannel(provider.canonicalName),
		openShareDialog: handleSharePost,
		displayOrder: provider.displayOrder,
	})

	const handleAddChannel = provider => {
		// Call the Social Popup to add a new channel
		addUserChannel(provider)
			.then(() => {
				// Refresh the user to show connected channel
				reloadUser().then(handleSharePost)
			})
			.catch(result => {})
	}

	const getUserChannels = (userChannels, socialProviders, isAnonymousUser) => {
		// Currently for enhanced news feed this is only for Twitter/LinkedIn.
		const allowedChannels = [
			SocialProviderData.Twitter.provider,
			SocialProviderData.LinkedIn.provider,
		]
		if (!userChannels && isAnonymousUser) userChannels = []

		let channels = Object.keys(socialProviders).reduce((arr, curr) => {
			const provider = socialProviders[curr]
			if (
				allowedChannels.includes(provider.displayName) &&
				provider.dispositions.includes('AddChannel') &&
				(provider.displayName !== 'LinkedIn' || !isNativeVideo) && // If the post is a native video, then don't include LinkedIn (native video sharing can only be done via FB/Twitter)
				provider.canShare &&
				userChannels
			) {
				const socialChannels =
					userChannels &&
					userChannels.filter(channel => channel.provider === provider.canonicalName)

				// User is logged in and has access to channel. Give them full functionality.
				if (socialChannels) {
					arr.push(createUserChannel(provider, socialChannels))
				} else {
					// User is anonymous/not logged in. Show channel, but it'll redirect to Sign Up to Share
					if (isAnonymousUser) arr.push(createUserChannel(provider, null))
				}
			}
			return arr
		}, [])

		channels.sort((a, b) => a.displayOrder - b.displayOrder)
		return channels
	}
	const socialChannels = getUserChannels(userChannels, socialProviders, isAnonymousUser)

	const handleClose = e => {
		if (e) e.preventDefault()
		// If this is a social channel triggered by a dialog, close it.
		if (sharePostInfo) handleCloseDialog()
	}

	const getSharingError = (error, defaultMessage) => {
		let errorMessage = defaultMessage
		if (error && error.data && error.data.Reason) {
			switch (error.data.Reason) {
				case 'sharing_capped': {
					errorMessage = lang.errorSharingCapped
					break
				}
				case 'already_shared': {
					errorMessage = lang.errorAlreadyShared
					break
				}
				default:
					break // Default set above
			}
		}
		return errorMessage
	}

	return (
		<SharingOptionsBar
			openShareDialog={openShareDialog}
			isAnonymousUser={!user && isEnhancedSharingFlowEnabled}
			isEnhancedSharingFlowEnabled={isEnhancedSharingFlowEnabled}
			isTwitterNativeSharingEnabled={isTwitterNativeSharingEnabled}
			isFacebookNativeSharingEnabled={isFacebookNativeSharingEnabled}
			isNativeVideo={isNativeVideo}
			nativeSharingHostUrl={nativeSharingHostUrl}
			twitterNativeConfig={twitterNativeConfig}
			facebookNativeConfig={facebookNativeConfig}
			getFacebookLink={getFacebookLink}
			getTwitterLink={getTwitterLink}
			getPost={getPost}
			sharePostId={post.postId}
			sharePostActivity={post.activity}
			sharePostDisclosures={post.shareDisclosures}
			sharePoints={post.sharePoints}
			userChannels={socialChannels}
			primaryColor={primaryColor}
			showFullActions={showFullActions}
			handleClose={handleClose}
			handleSharePost={handleSharePost}
			getSharingError={getSharingError}
		/>
	)
}

ShareBar.propTypes = {
	post: PropTypes.object,
	user: PropTypes.object, // Null if not logged in
	isEnhancedSharingFlowEnabled: PropTypes.bool,
	nativeSharingHostUrl: PropTypes.string,
	facebookNativeConfig: PropTypes.object,
	twitterNativeConfig: PropTypes.object,
	getFacebookLink: PropTypes.func,
	getTwitterLink: PropTypes.func,
	getPost: PropTypes.func,
	socialProviders: PropTypes.object,
	userChannels: PropTypes.object,
	addUserChannel: PropTypes.func,
	reloadUser: PropTypes.func,
	handleSharePost: PropTypes.func,
	primaryColor: Theme.PrimaryColor.propType,
	showFullActions: PropTypes.bool,
	sharePostInfo: PropTypes.object,
	handleCloseDialog: PropTypes.func,
}

// Share Component
const Share = React.memo(({ handleSharePost, color, showLabels, title }) => {
	return (
		<button style={styles.action} onClick={handleSharePost}>
			<Icon
				style={{
					...styles.icon,
					// NOTE: Design wanted this bigger
					fontSize: styles.icon.fontSize + 1,
				}}
				color={color}
				modifier={Modifier.fixedWidth}
				name={Name.share}
				title={title}
			/>
			{showLabels && <span style={styles.actionLabel}>{title}</span>}
		</button>
	)
})

Share.propTypes = {
	handleSharePost: PropTypes.func,
	color: PropTypes.string,
	showLabels: PropTypes.bool,
	title: PropTypes.string,
}
//#endregion

class PostActions extends Component {
	static propTypes = {
		post: PropTypes.shape({
			postId: PropTypes.string.isRequired,
			isShareable: PropTypes.bool.isRequired,
			isSharedByUser: PropTypes.bool.isRequired,
			isLikedByUser: PropTypes.bool.isRequired,
			userCommentable: PropTypes.bool.isRequired,
			userLikeable: PropTypes.bool.isRequired,
			showShareButton: PropTypes.func.isRequired,
		}).isRequired,
		showLabels: PropTypes.bool,
		onComment: PropTypes.func,
		/** If details view then its longform */
		longForm: PropTypes.bool,
		isUrgentBroadcast: PropTypes.bool,
		user: PropTypes.object,
		primaryColor: PropTypes.string,
		getFacebookLink: PropTypes.func,
		getTwitterLink: PropTypes.func,
		isEnhancedSharingFlowEnabled: PropTypes.bool,
		addUserChannel: PropTypes.func,
		reloadUser: PropTypes.func,
		sharePostInfo: PropTypes.any,
		style: PropTypes.any,
		broadcastId: PropTypes.string,
		handleCloseDialog: PropTypes.func,

		/** Called when a Post action is clicked. onPostActionClick(postId, actionType)
		 * - actionType: One of 'Share', 'Comment', 'Like'
		 * - Return false to prevent default action
		 */
		onPostActionClick: PropTypes.func,

		// From Redux store
		// primaryColor: ColorPropType.isRequired,
		sharePost: PropTypes.func.isRequired,
		likePost: PropTypes.func.isRequired,
		getPost: PropTypes.func.isRequired,
		commentOnPost: PropTypes.func.isRequired,
		nativeSharingHostUrl: PropTypes.string,
		facebookNativeConfig: PropTypes.object,
		twitterNativeConfig: PropTypes.object,
		socialProviders: PropTypes.object,
		userChannels: PropTypes.object,

		// From withSettings
		showShareButtonOnSocialPosts: Settings.Features.ShowShareButtonOnSocialPosts.propType,
	}

	static defaultProps = {
		showLabels: false,
	}

	handleSharePost = e => {
		e.preventDefault()
		e.stopPropagation()

		const {
			onPostActionClick,
			post: { postId },
		} = this.props

		if (onPostActionClick) {
			const continueToDefaultHandler = onPostActionClick(postId, ExternalFeedActions.share)
			if (continueToDefaultHandler === false) {
				e.stopPropagation()
				return // Handled
			}
		}

		const { sharePost } = this.props
		sharePost()
	}

	handleLikePost = e => {
		e.preventDefault()
		e.stopPropagation()

		const {
			likePost,
			getPost,
			post: { postId, isLikedByUser },
			onPostActionClick,
		} = this.props

		if (onPostActionClick) {
			const continueToDefaultHandler = onPostActionClick(postId, ExternalFeedActions.like)
			if (continueToDefaultHandler === false) {
				e.stopPropagation()
				return // Handled
			}
		}

		likePost(!isLikedByUser).then(getPost)
	}

	handleCommentPost = e => {
		e.preventDefault()
		e.stopPropagation()

		const {
			post,
			onComment,
			commentOnPost,
			longForm,
			broadcastId,
			handleCloseDialog: handleCloseUrgentBroadcast,
			isUrgentBroadcast,
			onPostActionClick,
		} = this.props

		if (onPostActionClick) {
			const continueToDefaultHandler = onPostActionClick(post.postId, ExternalFeedActions.comment)
			if (continueToDefaultHandler === false) {
				e.stopPropagation()
				return // Handled
			}
		}

		//NOTE: Should this be handled externally by the Post component?
		if (!longForm) {
			// In News Feed
			const activity =
				broadcastId && getActivity(broadcastId && Activity.Reason.broadcast, broadcastId)

			if (isUrgentBroadcast) handleCloseUrgentBroadcast() // Closes urgent broadcast dialog

			commentOnPost(
				Routes.Post.Details.generateDialogPath(post.slug, post.postId, true, null, null, activity)
			)
		} else {
			// In Post Details
			if (post.discussionsEnabled && onComment) {
				onComment()
			}
		}
	}

	handleSharesKeyDown = e => {
		if (e.keyCode !== keyCodes.enter) {
			return
		}
		this.handleSharePost(e)
	}

	shareButtonText = post => (post.isSharedByUser ? buttons.reshare : buttons.share)
	likeButtonText = post => (post.isLikedByUser ? buttons.liked : buttons.like)

	render() {
		const {
			user,
			post,
			primaryColor,
			showLabels,
			showShareButtonOnSocialPosts,
			nativeSharingHostUrl,
			facebookNativeConfig,
			twitterNativeConfig,
			getFacebookLink,
			getTwitterLink,
			getPost,
			handleCloseDialog,
			isEnhancedSharingFlowEnabled,
			socialProviders,
			userChannels,
			addUserChannel,
			reloadUser,
			sharePost,
			sharePostInfo,
			style: styleOverride,
			longForm,
		} = this.props

		if ((!post.isShareable && !post.userCommentable && !post.userLikeable) || post.isPreview)
			return null

		const dynamicStyles = {
			share: {
				...styles.base,
				color: primaryColor,
				cursor: 'pointer',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				...styleOverride,
				marginBottom: longForm ? width.spacing.narrow : 0,
				paddingBottom: longForm ? 0 : padding.narrow,
			},
			enhancedShare: {
				...styles.base,
				color: primaryColor,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderTop: 0,
				paddingTop: 0,
				...styleOverride,
			},
			commentLike: {
				...styles.base,
				...styles.commentLike,
				color: primaryColor,
				...styleOverride,
				marginBottom: 0,
				paddingTop: 0,
			},
			innerContainer: {
				...styles.innerContainer,
				...(showLabels && {
					marginLeft: 0,
					marginRight: 0,
					width: '100%',
				}),
			},
		}

		const commentsEnabled = post.userCommentable
		const likesEnabled = post.userLikeable

		const showFullActions = likesEnabled || commentsEnabled
		const showShareButton = post.showShareButton(showShareButtonOnSocialPosts)
		const showShareBar = isEnhancedSharingFlowEnabled && post.isShareable

		return showFullActions ? (
			<div style={dynamicStyles.commentLike}>
				<div style={dynamicStyles.innerContainer}>
					<div /> {/* An empty div makes space-between work the same as space-evenly in IE */}
					{likesEnabled && (
						<Fragment>
							<Like
								buttonText={this.likeButtonText(post)}
								handleLikePost={this.handleLikePost}
								color={post.isLikedByUser ? primaryColor : color.secondaryGrey}
								isLikedByUser={post.isLikedByUser}
								showLabels={showLabels}
							/>
							{(commentsEnabled || (showShareButton && !showShareBar)) && <Separator />}
						</Fragment>
					)}
					{commentsEnabled && (
						<Fragment>
							<Comment
								handleCommentPost={this.handleCommentPost}
								color={post.isCommentedByUser ? primaryColor : color.secondaryGrey}
								showLabels={showLabels}
							/>
							{showShareButton && !showShareBar && <Separator />}
						</Fragment>
					)}
					{showShareButton &&
						!showShareBar && (
							<Share
								handleSharePost={this.handleSharePost}
								color={post.isSharedByUser ? primaryColor : color.secondaryGrey}
								showLabels={showLabels}
								title={this.shareButtonText(post)}
							/>
						)}
					<div /> {/* An empty div makes space-between work the same as space-evenly in IE */}
				</div>
				{showShareBar && (
					<ShareBar
						post={post}
						handleSharePost={sharePost}
						nativeSharingHostUrl={nativeSharingHostUrl}
						facebookNativeConfig={facebookNativeConfig}
						twitterNativeConfig={twitterNativeConfig}
						getFacebookLink={getFacebookLink}
						getTwitterLink={getTwitterLink}
						socialProviders={socialProviders}
						userChannels={userChannels}
						isEnhancedSharingFlowEnabled={isEnhancedSharingFlowEnabled}
						getPost={getPost}
						reloadUser={reloadUser}
						addUserChannel={addUserChannel}
						primaryColor={primaryColor}
						iconStyle={dynamicStyles.shareIcon}
						showFullActions={showFullActions}
						handleCloseDialog={handleCloseDialog}
						sharePostInfo={sharePostInfo}
						user={user}
					/>
				)}
			</div>
		) : showShareButton ? (
			showShareBar ? (
				<div style={dynamicStyles.enhancedShare}>
					<ShareBar
						post={post}
						handleSharePost={sharePost}
						nativeSharingHostUrl={nativeSharingHostUrl}
						facebookNativeConfig={facebookNativeConfig}
						twitterNativeConfig={twitterNativeConfig}
						getFacebookLink={getFacebookLink}
						getTwitterLink={getTwitterLink}
						socialProviders={socialProviders}
						userChannels={userChannels}
						isEnhancedSharingFlowEnabled={isEnhancedSharingFlowEnabled}
						getPost={getPost}
						reloadUser={reloadUser}
						addUserChannel={addUserChannel}
						primaryColor={primaryColor}
						iconStyle={dynamicStyles.shareIcon}
						showFullActions={showFullActions}
						handleCloseDialog={handleCloseDialog}
						sharePostInfo={sharePostInfo}
						user={user}
					/>
				</div>
			) : (
				<div
					style={dynamicStyles.share}
					onClick={this.handleSharePost}
					onKeyDown={this.handleSharesKeyDown}
					tabIndex={0}
					aria-label={lang.share}
					role="button"
				>
					<Icon
						style={{ ...dynamicStyles.shareIcon, marginRight: 5 }}
						name={Name.share}
						title={this.shareButtonText(post)}
						modifier={Modifier.fixedWidth}
						onClick={this.handleSharePost}
					/>
					{this.shareButtonText(post)}
				</div>
			)
		) : null
	}
}

// Connect with Redux store, wrap in community settings
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PostActions)
