import { safeGetNestedProp } from '@dysi/js-helpers'

const PostLiker = postLikeResponse => {
	// We only need to save the Square40 profile picture for likers
	const profilePictureImages = {}
	const square40ProfilePicture = safeGetNestedProp(
		postLikeResponse,
		'profilePictureImages.Square40'
	)
	if (square40ProfilePicture) {
		profilePictureImages.Square40 = square40ProfilePicture
	}

	return {
		userId: postLikeResponse.userId,
		displayName: postLikeResponse.displayName,
		profilePictureImages,
		isActive: postLikeResponse.isActive || false,
	}
}

export default PostLiker
