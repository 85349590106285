const lang = {
	back: 'Back',
	cancel: 'Cancel',
	close: 'Close',
	comment: 'Comment',
	copy: 'Copy',
	delete: 'Delete',
	done: 'Done',
	edit: 'Edit',
	like: 'Like',
	liked: 'Liked',
	loading: 'Loading...',
	next: 'Next',
	ok: 'OK',
	remove: 'Remove',
	reshare: 'Share Again',
	save: 'Save',
	share: 'Share',
	submit: 'Submit',
	view: 'View',
	viewPost: 'View Post',
}

export default lang;