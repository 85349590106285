import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Routing
import { Link, withRouter } from 'react-router-dom'
import Routes from 'helpers/routes'

// Components
import CommunityBranding from './CommunityBranding'
import UserSummary from './UserSummary'
import Hamburger from 'components/Hamburger/Hamburger'
import Icon, { Name } from 'components/Icons/FontAwesome'
import SubmitPostButton from 'scenes/SubmitPost/components/SubmitPostButton'
import NotificationDropdown from 'scenes/Notifications/components/NotificationDropdown'
// import EventArea from 'helpers/events/EventArea'

// Component Preloads
// NOTE: We're not actually rendering these components. We're only importing them so that we can preload their bundles before actually needing them.
import { LoadableMessageListAndDetails } from 'pages/Messages'
import { LeaderboardsPage, SavedItemsPage } from 'scenes/App/LoadablePages'

// Lang
import lang from './header.lang'
import { FormatNumber } from 'helpers/numberHelpers'

// Responsive
import responsive from 'wrappers/ResponsiveComponent'
import { isAtLeast, breakpoints } from 'helpers/breakpoints'

// Helpers
import { safeGetNestedProp, isLocalStorageAvailable } from '@dysi/js-helpers'
import { isUserProfileIncomplete } from 'helpers/userHelpers'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'
import { notificationFlyoutKey } from 'pages/Beta/Beta'

// Actions
import { asyncGetUnreadConversationCount } from 'scenes/Messages/message.reducer'
import {
	asyncGetNotificationCount,
	asyncGetNotifications,
} from 'pages/Notifications/notification.reducer'
import { asyncGetLeaderboards } from 'scenes/Leaderboards/leaderboard.reducer'

//region Styles
import {
	width,
	color,
	spacing,
	flexContainer,
	border,
	fontSize,
	constants,
	textButtonStyle,
} from 'styles/variables'

const styles = {
	base: {
		display: 'flex',
		minHeight: constants.HeaderHeight,
		justifyContent: 'center',
		background: color.white,
		paddingTop: 0,
		paddingBottom: 0,
		paddingRight: spacing.basic,
		paddingLeft: spacing.basic,
		borderTop: border.basic,
		borderBottom: border.basic,
		width: '100%',
		alignSelf: 'center',

		position: 'sticky',
		zIndex: 9,
		top: 0,
		right: 0,
		left: 0,
	},
	rightSide: {
		display: 'flex',
	},
	inner: {
		width: '100%',
		maxWidth: width.layout.page,
		display: 'flex',
		justifyContent: 'space-between',
		minHeight: constants.HeaderHeight, // IE-bug with vertical centering
	},
	links: {
		display: 'flex',
		alignItems: 'center',
		fontSize: fontSize.small,
		link: {
			marginRight: spacing.basic + spacing.narrow,
			fontSize: fontSize.small,
			whiteSpace: 'nowrap',
			...textButtonStyle,
			color: 'inherit',
		},
		icon: {
			color: color.secondaryGrey,
			fontSize: fontSize.heading,
			marginRight: spacing.narrow,
		},
	},
}
//endregion

// Redux
const mapStateToProps = (state, ownProps) => {
	const notificationCount = safeGetNestedProp(state, 'notification.count')
	const newNotificationCount = safeGetNestedProp(notificationCount, 'totals.New', 0)
	const currentUserId = safeGetNestedProp(state, 'auth.user.userId')
	const unreadConvoCount = state.messages.unreadConversations
	const loggedInProfileIncomplete = isUserProfileIncomplete(state.auth.user)
	const headerLinks = safeGetNestedProp(state, 'sphere.customLinks.header')
	const hasHeaderLinks = headerLinks && headerLinks.length > 0
	const leaderboards = state.leaderboard.leaderboards
	const isImpersonating = state.auth.isImpersonating
	const currentNotifications = state.notification.normalizedNotifications
	const isNotificationRequestPending = state.notification.isNotificationRequestPending
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const enableLeaderboards = safeGetSetting(state, Settings.Features.EnableLeaderboards)
	const enableMessages = safeGetSetting(state, Settings.Features.EnableMessages)
	const userIsLoggedIn = safeGetSetting(state, Settings.User.IsLoggedIn)
	const userCanSubmitContent = safeGetSetting(state, Settings.User.CanSubmitContent)
	const enableNotificationFlyout =
		isLocalStorageAvailable() && localStorage.getItem(notificationFlyoutKey)
	let unredeemedPoints
	// It should be safe to retrieve this if we have a valid user
	if (enableLeaderboards && userIsLoggedIn)
		unredeemedPoints = safeGetNestedProp(state, 'auth.user.unredeemedPoints')
	const viewport = state.browser.viewport

	return {
		currentNotifications,
		currentUserId,
		enableLeaderboards,
		enableMessages,
		enableNotificationFlyout,
		hasHeaderLinks,
		isImpersonating,
		isNotificationRequestPending,
		leaderboards,
		loggedInProfileIncomplete,
		newNotificationCount,
		notificationCount,
		primaryColor,
		unredeemedPoints,
		unreadConvoCount,
		userCanSubmitContent,
		userIsLoggedIn,
		viewport,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getLeaderboards: () => dispatch(asyncGetLeaderboards()),
		getNotificationCount: () => dispatch(asyncGetNotificationCount()),
		getUnreadConvoCount: () => dispatch(asyncGetUnreadConversationCount()),
		getNotifications: userId => dispatch(asyncGetNotifications(userId)),
	}
}

// Component
class Header extends React.Component {
	static propTypes = {
		currentNotifications: PropTypes.object,
		currentUserId: PropTypes.number,
		enableLeaderboards: PropTypes.bool,
		enableMessages: PropTypes.bool,
		enableNotificationFlyout: PropTypes.bool,
		getLeaderboards: PropTypes.func,
		getNotifications: PropTypes.func,
		getNotificationCount: PropTypes.func,
		getUnreadConvoCount: PropTypes.func,
		hasHeaderLinks: PropTypes.bool,
		history: PropTypes.object,
		isImpersonating: PropTypes.bool,
		isNotificationRequestPending: PropTypes.bool,
		leaderboards: PropTypes.bool,
		location: PropTypes.bool,
		loggedInProfileIncomplete: PropTypes.bool,
		newNotificationCount: PropTypes.number,
		primaryColor: PropTypes.string,
		unredeemedPoints: PropTypes.number,
		unreadConvoCount: PropTypes.number,
		userCanSubmitContent: PropTypes.bool,
		userIsLoggedIn: PropTypes.bool,
		viewport: responsive.PropType,
	}

	componentDidMount() {
		const { userIsLoggedIn } = this.props

		if (userIsLoggedIn) this.init()
	}

	componentDidUpdate(prevProps) {
		const { userIsLoggedIn } = this.props

		if (userIsLoggedIn && userIsLoggedIn !== prevProps.userIsLoggedIn) this.init()
	}

	init = () => {
		const {
			enableLeaderboards,
			enableMessages,
			getUnreadConvoCount,
			getLeaderboards,
			getNotificationCount,
		} = this.props

		if (enableLeaderboards) getLeaderboards() // fetch leaderboards to determine whether we should show button on header
		if (enableMessages) getUnreadConvoCount()

		getNotificationCount()
	}

	getNotifications = () => {
		const { currentUserId, getNotifications, getNotificationCount } = this.props

		getNotifications(currentUserId).then(getNotificationCount)
	}

	handleNotificationsClick = event => {
		event.preventDefault()
		const { newNotificationCount, history, location } = this.props

		// already on Notifications Page
		if (location.pathname === Routes.Notifications.linkPath) {
			if (newNotificationCount > 0) {
				this.getNotifications()
			}
		} else {
			history.push(Routes.Notifications.linkPath)
		}
	}

	render() {
		const linkStyle = {
			...styles.links.link,
		}

		const {
			currentUserId,
			currentNotifications,
			enableLeaderboards,
			enableMessages,
			enableNotificationFlyout,
			hasHeaderLinks,
			isNotificationRequestPending,
			leaderboards,
			loggedInProfileIncomplete,
			newNotificationCount,
			primaryColor,
			unreadConvoCount,
			unredeemedPoints,
			userCanSubmitContent,
			userIsLoggedIn,
		} = this.props

		const isNarrowScreen = !isAtLeast(breakpoints.large.name, this.props.viewport.size)
		const showSubmitPostButton = userCanSubmitContent && !loggedInProfileIncomplete
		const showNavigationLinks = userIsLoggedIn && !loggedInProfileIncomplete
		const showLeaderboards = showNavigationLinks && enableLeaderboards && leaderboards.length > 0
		const dynamicStyles = {
			base: {
				...styles.base,
				...(isNarrowScreen && hasHeaderLinks && { top: spacing.wide }),
			},
		}

		const hamburgerBadgeCount = (newNotificationCount || 0) + (unreadConvoCount || 0)

		return (
			<header style={dynamicStyles.base} className="sticky">
				<div style={styles.inner}>
					<CommunityBranding isImpersonating={this.props.isImpersonating} />

					<div style={styles.rightSide}>
						{!isNarrowScreen && (
							<div style={styles.links}>
								<div style={{ ...flexContainer('row', 'center', 'center') }}>
									{showNavigationLinks && (
										<Fragment>
											<Link
												to={Routes.SavedItems.linkPath}
												style={linkStyle}
												onMouseEnter={() => SavedItemsPage.preload()}
											>
												<Icon name={Name.bookmark} style={styles.links.icon} />
												{lang.savedItems}
											</Link>

											{enableMessages && (
												<Link
													to={Routes.Messages.linkPath}
													style={linkStyle}
													onMouseEnter={() => LoadableMessageListAndDetails.preload()}
												>
													<Icon
														name={Name.comments}
														style={styles.links.icon}
														count={unreadConvoCount}
													/>
													{lang.messages}
												</Link>
											)}

											{enableNotificationFlyout ? (
												<NotificationDropdown
													currentUserId={currentUserId}
													getNotifications={this.getNotifications}
													notifications={currentNotifications}
													newNotificationCount={newNotificationCount}
													isNotificationRequestPending={isNotificationRequestPending}
													primaryColor={primaryColor}
												/>
											) : (
												<button
													onClick={this.handleNotificationsClick}
													style={linkStyle}
													role="link"
												>
													<Icon
														name={Name.bell}
														style={styles.links.icon}
														count={newNotificationCount}
													/>
													{lang.notifications}
												</button>
											)}
										</Fragment>
									)}

									{showLeaderboards && (
										<Link
											to={Routes.Leaderboards.linkPath}
											style={linkStyle}
											onMouseEnter={() => LeaderboardsPage.preload()}
										>
											<Icon name={Name.star} style={styles.links.icon} />
											{unredeemedPoints > 0
												? unredeemedPoints === 1
													? lang.pointOneValue
													: lang.pointValue(FormatNumber(unredeemedPoints))
												: lang.leaderboards}
										</Link>
									)}
								</div>

								<UserSummary />

								{showSubmitPostButton && <SubmitPostButton />}
							</div>
						)}

						<Hamburger badgeCount={hamburgerBadgeCount} />
					</div>
				</div>
			</header>
		)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
