/*
	FONTAWESOME ICON DEFINITIONS
	- BRANDS VERSION -
*/

/*eslint no-unused-vars: "error"*/

// Please try to keep this sorted alphabetically

// NOTE: Switch this to regular imports when webpack 4 upgrades it's minifier. https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking#issues

import { faAndroid } from '@fortawesome/free-brands-svg-icons/faAndroid'
import { faAppStoreIos } from '@fortawesome/free-brands-svg-icons/faAppStoreIos'
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faBloggerB } from '@fortawesome/free-brands-svg-icons/faBloggerB'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faTencentWeibo } from '@fortawesome/free-brands-svg-icons/faTencentWeibo'
import { faTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter'
import { faWeibo } from '@fortawesome/free-brands-svg-icons/faWeibo'
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows'
import { faWordpressSimple } from '@fortawesome/free-brands-svg-icons/faWordpressSimple'
import { faXing } from '@fortawesome/free-brands-svg-icons/faXing'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'

// TODO - Remove this
import { faHotjar } from '@fortawesome/free-brands-svg-icons/faHotjar'

import { faChatter, faSapJam, faDySi, faJive } from '@dysi/fontawesome-dysi-custom'

export default {
	faHotjar,

	// Mobile Apps
	faAndroid,
	faApple,
	faAppStoreIos,
	// Social Icons
	faBloggerB,
	faFacebookF,
	faInstagram,
	faLinkedinIn,
	faTencentWeibo,
	faTumblr,
	faXTwitter,
	faWeibo,
	faWindows,
	faWordpressSimple,
	faXing,
	faYoutube,
	faChatter,
	faSapJam,
	faDySi,
	faJive,
}
