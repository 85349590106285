const lang = {
	addChannelTitle: 'Connect Social Account',
	addChannelInstructions: 'Click the links below to begin connecting your social media accounts, and click "Next" to start sharing.',
	addChannelZeroChannels: 'You currently have zero accounts connected.',
	autoScheduleButton: 'Auto Queue',
	copyLink: 'Copy Link',
	copyLinkFailed: 'Link unavailable',
	copyLinkSuccess: 'Copied to clipboard!',
	dialogTitle: 'Share a Post',
	dialogTitleSignInRequired: 'Sign In To Share This Post',
	earnPoints: 'Share & earn',
	editChannelTitle: 'Edit Accounts',
	emailLink: 'Email',
	emailLinkFailed: 'Email unavailable',
	errorUnknownShare: 'Your share couldn’t be completed at this time. Please try again later.',
	errorUnknownReaction: 'Unfortunately, your reaction couldn’t be submitted at this time. Please try again later.',
	errorRepeatedRetweet: 'You have already retweeted this Tweet',
	errorBlacklisted: 'Your share couldn’t be completed because it contains prohibited phrases.',
	errorSharingCapped: 'You have reached your daily sharing limit.',
	errorSharingMaxChannelsExceeded: 'You have exceeded the maximum amount of social channels you can share to for an individual post.',
	errorScheduledSharingCapped: 'You have reached your daily sharing limit for this day.',
	errorAlreadyShared: 'You have already shared this post.',
	errorNoChannels: 'No channels selected',
	errorDeleteScheduledSharesFailed: 'Cannot delete scheduled shares at this time. Please try again later from Settings > Scheduled Shares.',
	errorScheduledSharesFailed: 'Cannot schedule shares at this time. Please try again later.',
	facebookNativeLinkFailed: 'Facebook sharing unavailable',
	lastShared: 'Last Shared: ',
	linkedInNativeLinkFailed: 'LinkedIn sharing unavailable',
	otherSharingOptions: 'Other Sharing Options',
	placeholder: 'What would you like to share?',
	points: number => ` ${number} pts`, //  number = number of points a user receives for sharing post
	reactionPlaceholder: 'Add a comment...',
	scheduleButton: 'Schedule Post',
	scheduleSuccess: 'Your Post is Scheduled!',
	scheduleTitle: 'Schedule Post',
	share: 'Share',
	shareAgain: 'Share again',
	shareCompletePoints: points => `You\'ve earned ${points} points.`, //  points = The number of points earned for sharing this post
	shareCompleteSuccess: 'Great! Your post is shared.',
	shareMultipleNowAndScheduleSuccess: 'Great! Your posts have been shared/scheduled.', //  
	shareMultipleNowSuccess: 'Great! Your posts have been shared.', //  
	shareMultiplePoints: totalPoints => `\nYou’ve earned ${totalPoints} points.`, //  totalPoints = The number of points earned across all posts/channels shared now on share multiple modal
	shareMultipleScheduleSuccess: 'Great! Your posts have been scheduled for share.',
	shareMultipleSubTitle: 'It may take a few minutes for your shares to appear on your chosen platforms.',
	shareMultipleSubTitleSchedule: 'Your shares can be reviewed on the scheduled shares screen.',
	sharingDisclosure: 'Channels may include appended text for campaigns.',
	sharingDisclosureInfo: 'These disclosures will automatically be added at the end of your share text for each channel.',
	sharingDisclosureTitle: 'Sharing Disclosures',
	submitButton: 'Share Now',
	twitterOnlyOneChannel: 'To comply with Twitter\'s policies, only 1 Twitter account can be selected at a time.',
	twitterCommentPlaceholder: 'Start typing to personalize your tweet.',
	twitterNativeLinkFailed: 'Twitter sharing unavailable',
	viewLeaderboard: 'View Leaderboard',
	viewScheduledSharesLink: 'View scheduled shares',
	xingNativeLinkFailed: 'Xing sharing unavailable',
}

export default lang;