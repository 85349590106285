import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { spacing, fontSize, color, width, fontWeight } from 'styles/variables'
const styles = {
	twitterWarning: {
		maxWidth: width.layout.sidebar,
		text: {
			marginBottom: spacing.basic,
		},
		cancel: {
			fontWeight: fontWeight.semiBold,
			cursor: 'pointer',
		},
		share: {
			fontSize: fontSize.base,
			color: color.secondaryGrey,
			cursor: 'pointer',
			marginBottom: spacing.narrow / 2,
		},
	},
}

export default prefixer.prefix(styles)
