import React from 'react'
import { render } from 'react-dom'

// Global variables
window.google = window.google || {}

// Moment and locale enforcement
import moment from 'moment-timezone'

// Polyfills
import 'babel-polyfill'
import 'event-source-polyfill' // Polyfill for Relay to work on Edge and IE

if (process.env.DS_ENV_IS_DEPLOYED) {
	// Replace the loaded locales for the specific locale at build-time
	// This is populated via environment variables and the webpack DefinePlugin
	moment.locale(process.env.MOMENT_LOCALE)
}

// Immutable warning fix // See more: https://github.com/facebook/draft-js/issues/950
// TODO: update when draft-js updates immutable
import Immutable from 'immutable'
Immutable.Iterable.noLengthWarning = true

// Routing
import { ConnectedRouter } from 'react-router-redux'

// Redux
import { Provider } from 'react-redux'
import store, { history, persistor } from './store'
import { PersistGate } from 'redux-persist/es/integration/react'

//
// import registerServiceWorker from 'helpers/registerServiceWorker';
import { watchLegacyToken } from 'helpers/legacyToken'

// Components
import App from 'scenes/App/App'
import Loading from 'components/Loading/Loading'

// Helpers
import { isDesktopApp } from 'helpers/browserhelper'
import { supportsPromiseRejectionEvent } from 'helpers/apiHelpers'
import { readAuthTokens, setAuthStorage, clearSsoAuthToken } from 'helpers/persistor'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { verifyUsernameUserWithEmail } from 'middleware/usernameMiddleware'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'

// Wrappers
import ErrorBoundary from 'wrappers/ErrorBoundary'

// Action Creators
import {
	asyncGetProviders,
	asyncGetSphereParams,
	asyncGetTimeZones,
	asyncGetLanguages,
} from 'reducers/sphere.reducer'
import {
	asyncGetCurrentUser,
	setAuthTokens,
	asyncGetUserCustomData,
	asyncGetUserPreferences,
} from 'scenes/Auth/auth.reducer'
import {
	watchWindowResize,
	setViewportSize,
	setPrimaryColorOverride,
} from 'reducers/browser.reducer'

// Enums
import CustomDataIndexes from 'scenes/Auth/enums/customDataIndexes'
import UserPreferenceKey from 'scenes/Auth/enums/UserPreferenceKey'

// May not be available at this point if running locally
const state = store.getState()
const sphereId = safeGetNestedProp(state, 'sphere.sphereId', 0)
const hostname = safeGetNestedProp(state, 'sphere.host', null)

// On local builds, NMA_BUILD_ID is set from .env file and could be anything
// or undefined. Unfortunately, the Client Events webservice barfs on strings
// that aren't strictly numbers, so default to hard-coded version number in
// case we can't extract a numeric version from NMA_BUILD_ID
let clientVersionNumeric = '1.0.0.0' // Local build

const buildId = process.env.NMA_BUILD_ID
if (buildId && typeof buildId === 'string') {
	// Extract client version from build ID
	// Expected: "NMA Build: MemberApp (Team A) 2020.1.13.1 - 31a97db2f04cbfa09607589cbd4e6d75205c3c88"
	const versionMatch = buildId.match(/\d+\.\d+\.\d+\.\d+/)
	if (versionMatch && Array.isArray(versionMatch) && versionMatch.length > 0) {
		clientVersionNumeric = versionMatch[0]
	}
}

// Listen to history changes for analytics
history.listen((location, action) => {
	// Don't scroll if show / hide postDialog
	if ((location.state && location.state.showPageDialog) || action === 'POP') return

	// Scroll to the top on page change
	try {
		window.scrollTo(0, 0)
		document.body.scrollTop = 0
	} catch (e) {
		// Do nothing
	}
})

//endregion

// Watch for actions from the other apps, like logout
watchLegacyToken(store.getState, store.dispatch)

// Watch for window.resize for responsive components
watchWindowResize(store.dispatch)

// 'Capturing' our unhandled Promise rejections and....doing nothing with them, since most of it's noise.
// We're creating two types, since Chrome only supports 'unhandled', and the other browsers + react throw a fit if
// we use 'unhandledrejection'.
if (supportsPromiseRejectionEvent()) {
	window.addEventListener('unhandledrejection', event => {
		// Prevent console logging of unhandled rejection
		event.preventDefault()
	})
} else {
	window.addEventListener('handledrejection', event => {
		event.preventDefault()
	})
}

store.dispatch(setViewportSize())

const getPersistedAuth = () => {
	return new Promise((resolve, reject) => {
		// Are we impersonating?
		const impersonating = safeGetNestedProp(store.getState(), 'auth.isImpersonating')
		if (!impersonating) {
			// Restore persisted auth data
			readAuthTokens()
				.then(auth => {
					store.dispatch(setAuthTokens(auth))
					resolve()
				})
				.catch(err => reject(err))
		} else {
			resolve()
		}
	})
}

const getUser = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(asyncGetCurrentUser(true))
			.then(() => {
				const user = safeGetNestedProp(store.getState(), 'auth.user')
				if (user && user.profileCompleted) {
					// Clear out SSO authentication now that the user is signed in.
					// This is specifically to avoid a race timeout where we don't authenticate the user
					// since NMA is clearing out the SSO tokens that's needed before the regflow initiates (TA-24339)
					clearSsoAuthToken()
				}

				// store
				// 	.dispatch(asyncGetUserPreferences(user.userId, Object.values(UserPreferenceKey)))
				// 	.then(() => {
				resolve(user)
				// 	})
				// 	.catch(err => reject(err))
			})
			.catch(err => reject(err))
		// Some old versions of Edge have fetch requests get stuck pending forever when the api returns a 401
		// To work around this, when loading in Edge, stick asyncGetCurrentUser in a timeout so if the call gets stuck
		// it forcibly fails and Edge can continue loading TA-24142 / MT-235
		// TODO - Come back and re-address this
		// https://github.com/gabceb/redux-persist-transform-expire
		// https://github.com/rt2zz/redux-persist
		setTimeout(reject, 5000)
	})
}

const getSphereParamsWithPrimaryColorOverride = () => {
	return new Promise((res, rej) => {
		store
			.dispatch(asyncGetSphereParams())
			.then(response => {
				const primaryColor = safeGetNestedProp(response, 'communityTheme.primaryColor')
				store.dispatch(setPrimaryColorOverride(primaryColor))
				res(response)
				return response
			})
			.catch(error => rej(error))
	})
}

const getPrimaryColorOverride = () => {
	const state = store.getState()
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	return store.dispatch(setPrimaryColorOverride(primaryColor))
}

// Redux-Persist - Let's pull what we can from existing state
const onBeforeLift = () =>
	getPersistedAuth().then(() => {
		const gatePromises = [
			// Get the sphere info if we don't have it
			...(process.env.NODE_ENV !== 'production'
				? [
						getSphereParamsWithPrimaryColorOverride(),
						store.dispatch(asyncGetProviders()),
						store.dispatch(asyncGetTimeZones()),
						store.dispatch(asyncGetLanguages()),
				  ]
				: [getPrimaryColorOverride()]),
		]

		gatePromises.push(
			getUser(),
			store.dispatch(asyncGetUserPreferences(Object.values(UserPreferenceKey)))
		)

		// NOTE - Use this to test changes by forcing the gatePromises to hang
		// gatePromises.push(
		// 	new Promise(resolve => {
		// 		setTimeout(() => {
		// 			resolve()
		// 		}, 10000)
		// 	})
		// )

		return Promise.all(gatePromises).then(() => {
			const state = store.getState()
			const dispatch = store.dispatch

			// For local communities, determine if we need to set storage to localStorage or
			// sessionStorage depending on usePersistentAuthentication.
			const usePersistentAuthentication = safeGetNestedProp(
				state,
				'sphere.parameters.usePersistentAuthentication',
				true
			)
			setAuthStorage(usePersistentAuthentication)

			// Verify username users that have unverified emails.
			// TODO: We can't do this on Home/Feed because it would refresh the page, but maybe
			// find a more optimized way to do this in the future.
			const user = safeGetNestedProp(state, 'auth.user')
			const isUsernameWithEmailEnabled =
				safeGetNestedProp(state, 'sphere.parameters.enableUsernameSignOn') &&
				safeGetNestedProp(state, 'sphere.parameters.enableEmailForUsernameUsers')
			if (user) {
				if (user.handle && isUsernameWithEmailEnabled) {
					verifyUsernameUserWithEmail(user, history, dispatch)
				}
				// TODO - Move custom data calls to user preference calls at some point. This should really be in gate
				// promises to prevent UI jumps
				dispatch(asyncGetUserCustomData(user.userId, CustomDataIndexes.experimentalFeatures))
			}
		})
	})

// TODO: update fall back page if whole app fails
let WrappedApp
WrappedApp = ErrorBoundary(App)

// Load everything
const root = (
	<Provider store={store}>
		<PersistGate
			// TODO - Replace this with something better like an "App Placeholder"
			loading={<Loading style={{ height: '100vh' }} delay={0} />}
			onBeforeLift={onBeforeLift}
			persistor={persistor}
		>
			<ConnectedRouter history={history}>
				<WrappedApp />
			</ConnectedRouter>
		</PersistGate>
	</Provider>
)

render(root, document.getElementById('root'))

// TODO - Hook this up to the dynamic serviceWorkers
// registerServiceWorker();
