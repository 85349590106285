const lang = {
	views: number => `${number} views`, //  number = the number of times a post has been viewed
	view: number => `${number} view`, //  number = when the post has been viewed one time
	by: 'by',
	nonSharableTooltip: 'This item is not approved for sharing or distribution outside the organization.',
	error: 'Sorry, you can\'t see this post right now. Don\'t worry, we\'re working to fix it.',
	errorHeading: 'Uh oh!',
	pinnedByManager: 'Pinned by Manager',
	anonymousUser: 'Anonymous User',
	categories: 'Categories',
	ok: 'OK',
	watchLive: 'WATCH LIVE',
	viewPost: 'Click to view post',
}

export default lang;