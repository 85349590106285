import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { fontSize, color, padding, spacing } from 'styles/variables'

const styles = {
	base: {
		fontSize: fontSize.base,
		padding: padding.basic,
		borderStyle: 'solid',
		borderWidth: 1,
	},
	success: {
		borderColor: color.secondaryGreen,
		backgroundColor: color.backgroundGreen,
		color: color.secondaryGreen,
	},
	error: {
		borderColor: color.secondaryRed,
		backgroundColor: color.backgroundRed,
		color: color.secondaryRed,
	},
	withClose: {
		display: 'flex',
	},
	messageWithClose: {
		paddingRight: spacing.narrow,
	},
	close: {
		color: color.primaryGrey,
		fontSize: 16,
		lineHeight: '19px',
		cursor: 'pointer',
		marginLeft: 'auto',
	},
}

export default prefixer.prefix(styles)
