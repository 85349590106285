import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Lang
import lang from './socialAccounts.lang'

// Components
import DisconnectAccountDialog from 'scenes/Settings/ChannelSettings/DisconnectAccountDialog'
import Loading from 'components/Loading/Loading'
import UserChannel from 'components/UserChannel/UserChannel'
import ErrorIndicator from 'components/ErrorIndicator/ErrorIndicator'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { testDataAttributes } from 'helpers/testDataAttributes'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { dispatchIfNotImpersonating } from 'helpers/impersonationHelpers'

// Actions
import { asyncAddUserChannel, refreshUser } from 'helpers/userHelpers'
import { asyncRemoveUserChannel } from 'scenes/Auth/auth.reducer'

//region Styles
import { margin } from 'styles/variables'

const styles = {
	base: {
		display: 'flex',
		maxWidth: 545,
		flexWrap: 'wrap',
		marginTop: margin.wide,
		marginBottom: margin.wide,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	error: {
		marginTop: margin.basic,
		marginBottom: -margin.basic,
		marginLeft: -margin.basic,
		marginRight: -margin.basic,
		[BreakpointNames.small]: {
			marginLeft: 0,
			marginRight: 0,
		},
		dialog: {
			marginLeft: -margin.wide,
			marginRight: -margin.wide,
			[BreakpointNames.small]: {
				marginLeft: -margin.basic,
				marginRight: -margin.basic,
			},
		},
	},
	userChannel: {
		marginTop: 0,
		marginBottom: margin.basic,
		marginLeft: margin.basic / 2,
		marginRight: margin.basic / 2,
		flexBasis: 250,
		[BreakpointNames.small]: {
			width: '100%',
			maxWidth: '300px',
		},
	},
}
//endregion

const mapStateToProps = (state, ownProps) => {
	const userId = safeGetNestedProp(state, 'auth.user.userId')
	const userChannels = safeGetNestedProp(state, 'auth.user.channels')
	const socialProviders = safeGetNestedProp(state, 'sphere.providers')	
	const socialChannelRenewalEnabled = safeGetNestedProp(state, 'sphere.parameters.enableSocialConnectionRenewal')

	return {
		userId,
		userChannels,
		socialProviders,
		socialChannelRenewalEnabled,		
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		addUserChannel: provider =>
			dispatchIfNotImpersonating(asyncAddUserChannel(provider), true, true),
		removeUserChannel: (userId, channelId) =>
			dispatchIfNotImpersonating(asyncRemoveUserChannel(userId, channelId), true, true),
		reloadUser: () => refreshUser(dispatch),
		authUserChannel: provider => dispatch(asyncAddUserChannel(provider)),
	}
}

class SocialAccounts extends Component {
	static propTypes = {
		onlyShareChannels: PropTypes.bool,
		onlyProviders: PropTypes.arrayOf(PropTypes.string), // Only show these providers (for Reactions and DirectShares)
		onAddChannel: PropTypes.func,
		onRemoveChannel: PropTypes.func,
		onAuthChannel: PropTypes.func,
		fromShareDialog: PropTypes.bool,

		// From Redux store
		userId: PropTypes.number,
		userChannels: PropTypes.array,
		socialProviders: PropTypes.object,
		addUserChannel: PropTypes.func,
		removeUserChannel: PropTypes.func,
		reloadUser: PropTypes.func,
		authUserChannel: PropTypes.func,
		socialChannelRenewalEnabled: PropTypes.bool,		

		// From responsive
		viewport: responsive.PropType,
	}

	state = {
		addChannelError: null,
		disconnectAccounts: null,
		channels: null,
	}

	resetDialogState = () => {
		this.setState({ disconnectAccounts: null, channels: null }) 
	}

	resetAddChannelError = () => {
		this.setState({ addChannelError: null })
	}

	resetAuthChannelError = () => {
		this.setState({ authChannelError: null })
	}

	handleAddChannel = provider => {
		const { addUserChannel, reloadUser } = this.props

		// Remove any previous error
		this.resetAddChannelError()

		// Call the Social Popup to add a new channel
		addUserChannel(provider)
			.then(socialPopupResponse => {
				// Channel added successfully
				const { onAddChannel } = this.props

				// Notify container, if interested
				if (onAddChannel) {
					onAddChannel(socialPopupResponse)
				}

				// Refresh the user to show connected channel
				reloadUser()
			})
			.catch(result => {
				// Show error on page. SocialPopup (AddChannel) errors are user-facing and translated
				const message =
					safeGetNestedProp(result, 'error.messages[0]') || lang.addChannelErrorUnknown
				this.setState({ addChannelError: message })
			})
	}

	handleRemoveChannels = disconnectAccountId => {
		const { userId, removeUserChannel, reloadUser } = this.props

		if (!disconnectAccountId) {
			// No channel selected. Close the Disconnect dialog
			this.resetDialogState()
			return
		}

		// Return a promise dialog can wait on
		return removeUserChannel(userId, disconnectAccountId)
			.then(() => {
				// Channel removed successfully

				// Notify container, if interested
				const { onRemoveChannel } = this.props
				if (onRemoveChannel) onRemoveChannel(disconnectAccountId)

				// Refresh the user to show disconnected channel
				reloadUser().finally(() =>
					// Close the Disconnect dialog
					this.resetDialogState()
				)
			})
			.catch(error => {
				// Show error in dialog
				throw new Error(lang.dialogErrorUnknown)
			})
	}

	showDisconnectAccountDialog = userChannels => {
		// Remove any previous error
		this.resetAddChannelError()

		// Show Disconnect dialog
		this.setState({ disconnectAccounts: userChannels })
	}

	channelReauthHandler = userChannels => {		
		const authRequiredChannels = userChannels.filter(value => value.authRequired);
		
		if (authRequiredChannels.length === 1) {
			this.handleAuthChannel(authRequiredChannels[0].provider)
		}
		else {
			// Remove any previous error
			this.resetAddChannelError()

			// Show Disconnect dialog
			this.setState({ disconnectAccounts: userChannels })
		}
	}
	
	handleAuthChannel = providerName => {
		const { onAuthChannel, authUserChannel, reloadUser } = this.props

		// Remove any previous error
		this.resetAuthChannelError()

		// Call the Social Popup to add a new channel
		return authUserChannel(providerName)
			.then(socialPopupResponse => {
				// Channel authorized successfully. Notify container, if interested
				if (onAuthChannel) onAuthChannel(socialPopupResponse)

				// Refresh the user to show connected channel
				reloadUser().finally(() =>
					// Close the Disconnect dialog
					this.resetDialogState()
				)
			})
			.catch(result => {
				// Show error on page. SocialPopup (AddChannel) errors are user-facing and translated
				const message =
					safeGetNestedProp(result, 'error.messages[0]') || lang.authChannelErrorUnknown
				this.setState({ authChannelError: message })

				throw result
			})
	}

	createAddChannelHandler = provider => () => this.handleAddChannel(provider)
	createRemoveChannelHandler = provider => () => this.showDisconnectAccountDialog(provider)
	createReauthChannelHandler = socialChannels => () => this.channelReauthHandler(socialChannels)

	checkForReauth = socialChannels => {
		const socialChannelsNeedReauth = socialChannels.reduce((result, channel) => {
			if (channel.authRequired) result = true
			return result
		}, false)

		return socialChannelsNeedReauth
	}

	// Provider sorting
	providerOrder = ['Twitter', 'LinkedIn', 'Facebook', 'FacebookPage', 'Instagram']
	alphabetical = (a, b) => (a > b ? 1 : a < b ? -1 : 0)
	createUserChannel = (provider, socialChannels) => ({
		provider: provider,
		userChannels: socialChannels,
		onAddChannel: this.createAddChannelHandler(provider.canonicalName),
		onRemoveChannel: this.createRemoveChannelHandler(socialChannels),
		onChannelIconClick: this.createReauthChannelHandler(socialChannels),
		needsReauth: this.checkForReauth(socialChannels),
	})

	getUserChannels = (userChannels, socialProviders) => {
		const { onlyShareChannels, onlyProviders } = this.props

		let channels = Object.keys(socialProviders).reduce((arr, canonicalName) => {
			// Only certain providers?
			let limitingProviders = Array.isArray(onlyProviders) && onlyProviders.length > 0
			if (limitingProviders && !onlyProviders.includes(canonicalName)) return arr // Skip this provider

			const provider = socialProviders[canonicalName]

			if (
				provider.dispositions.includes('AddChannel') && // Only show AddChannel providers on user's Social Accounts page
				(!onlyShareChannels || provider.canShare)
			) {
				// If requested, only show providers that can share
				const socialChannels = userChannels.filter(
					channel => channel.provider === provider.canonicalName
				)
				arr.push(this.createUserChannel(provider, socialChannels))
			}

			return arr
		}, [])

		// Sort in the order returned by the API
		channels.sort((a, b) => a.provider.displayOrder - b.provider.displayOrder)

		return channels
	}

	render() {
		const { userChannels, socialProviders, viewport, fromShareDialog, socialChannelRenewalEnabled  } = this.props		

		const { addChannelError, disconnectAccounts } = this.state		

		// Wait until data is available
		if (!socialProviders || !userChannels) {
			return <Loading />
		}

		// Style
		const dynamicStyles = {
			userChannel: {
				...styles.userChannel,
				...styles.userChannel[viewport.size],
			},
			error: {
				...styles.error,
				...(fromShareDialog
					? {
							...styles.error.dialog,
							...styles.error.dialog[viewport.size],
					  }
					: styles.error[viewport.size]),
			},
		}

		return (
			<Fragment>
				<ErrorIndicator
					isOpen={!!addChannelError}
					message={addChannelError}
					style={dynamicStyles.error}
				/>
				<div style={styles.base} data-test-id={testDataAttributes.settingsChannels}>
					{this.getUserChannels(userChannels, socialProviders).map(channel => (
						<UserChannel
							key={channel.provider.canonicalName}
							style={dynamicStyles.userChannel}
							provider={channel.provider}
							userChannels={channel.userChannels}
							onAddChannel={channel.onAddChannel}
							onDisconnect={channel.onRemoveChannel}
							fromShareDialog={fromShareDialog}
							needsReauth={channel.needsReauth}
							onChannelIconClick={channel.onChannelIconClick}			
							socialChannelRenewalEnabled={socialChannelRenewalEnabled}												
						/>
					))}
				</div>

				<DisconnectAccountDialog
					isOpen={!!disconnectAccounts}
					disconnectAccounts={disconnectAccounts}
					onRemoveChannel={this.handleRemoveChannels}
					onClose={this.resetDialogState}
					onReconnectClick={this.handleAuthChannel}	
					socialChannelRenewalEnabled={socialChannelRenewalEnabled}						
				/>
			</Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(responsive(SocialAccounts))
