import React from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Actions
import { asyncGetCategoryPosts } from 'scenes/Category/category.reducer'

// Helpers
import { Settings } from 'wrappers/SettingsWrapper'
import { Theme } from 'wrappers/ThemeWrapper'
import { safeGetSetting } from 'wrappers/Settings'
import { safeGetThemeValue } from 'wrappers/Theme'
import Routes from 'helpers/routes'
import { isMobile, isOldIE } from 'helpers/browserhelper'

// Components
import CommunityLogo from './CommunityLogo'
import lang from './communityBranding.lang'

//region Styles
import { spacing, border, color, fontWeight, margin, textButtonStyle } from 'styles/variables'
import { listUnstyledCentered } from 'styles/defaults'

const logoWidth = 48

const styles = {
	base: {
		...listUnstyledCentered,
		overflow: 'hidden',
		marginRight: margin.narrow,
	},
	item: {
		lineHeight: 0, // Prevent logo from taking extra line height
		marginRight: spacing.narrow,
		...textButtonStyle,
	},
	logo: {
		maxWidth: logoWidth,
		height: logoWidth,
		width: logoWidth,
		imageRendering: 'auto',
		borderRadius: border.roundedRadius,
		border: border.basic,
	},
	name: {
		overflow: 'hidden',
		background: 'none',
		border: 'none',
		padding: 0,
		color: 'inherit',
		header: {
			margin: 0,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			cursor: 'pointer',
		},
	},
	impersonationBadge: {
		border: border.basic,
		borderColor: color.secondaryRed,
		backgroundColor: color.secondaryRed,
		color: color.white,
		fontWeight: fontWeight.bold,
		borderRadius: 5,
		marginRight: spacing.basic,
		marginLeft: spacing.basic,
		paddingRight: spacing.narrow,
		paddingLeft: spacing.narrow,
		paddingTop: spacing.narrow / 2,
		paddingBottom: spacing.narrow / 2,
	},
}
//endregion

const mapStateToProps = state => {
	const defaultCategoryId = state.category.landingPageDefaultCategory
	const communityName = safeGetSetting(state, Settings.Community.Name)
	const squareLogoUrl = safeGetThemeValue(state, Theme.SquareLogoUrl)

	return {
		communityName,
		defaultCategoryId,
		squareLogoUrl,
	}
}

const mapDispatchToProps = dispatch => {
	const fetchTakeCount = isMobile || isOldIE ? 20 : 50
	return {
		updateFeed: categoryIds =>
			dispatch(asyncGetCategoryPosts(categoryIds, 0, fetchTakeCount, { categoryId: categoryIds })),
	}
}

// Component
class CommunityBranding extends React.Component {
	static propTypes = {
		communityName: Settings.Community.Name.propType,
		defaultCategoryId: PropTypes.number,
		history: PropTypes.object,
		isImpersonating: PropTypes.bool,
		location: PropTypes.object,
		squareLogoUrl: Theme.SquareLogoUrl.propType,
		updateFeed: PropTypes.func,
	}

	handleClick = () => {
		const {
			defaultCategoryId,
			history,
			location: { pathname },
			updateFeed,
		} = this.props

		if (pathname === Routes.Category.linkPath) {
			updateFeed(defaultCategoryId)
		} else {
			history.push(Routes.Category.linkPath)
		}
	}

	render() {
		const { squareLogoUrl, communityName, isImpersonating } = this.props

		return (
			<ul style={styles.base}>
				{squareLogoUrl && (
					<li>
						<button style={styles.item} onClick={this.handleClick} role="link">
							<CommunityLogo
								squareLogoUrl={squareLogoUrl}
								logoWidth={logoWidth}
								style={styles.logo}
								name={communityName}
							/>
						</button>
					</li>
				)}
				{communityName && (
					<li>
						<button style={styles.name} onClick={this.handleClick} role="link">
							<h2 style={styles.name.header} >
								{communityName}
							</h2>
						</button>
					</li>
				)}
				{isImpersonating && <li style={styles.impersonationBadge}>{lang.impersonating}</li>}
			</ul>
		)
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CommunityBranding)
)
