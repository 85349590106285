import React from 'react'
import PropTypes from 'prop-types'

// Styles
import { fontSize, color } from 'styles/variables'
import { Style } from 'radium'

const styles = {
	base: {
		fontSize: fontSize.base,
		color: color.primaryBlack,
	},
	error: {
		border: 'solid 1px #D3484D',
		// backgroundColor: '#EBADAF',
		color: '#D3484D',
		text: {
			color: color.secondaryRed,
		},
	},
	warning: {
		border: 'solid 1px #FFCC00',
		// backgroundColor: '#FFEB99',
		color: '#FFCC00',
	},
	disabled: {
		backgroundColor: color.disabled,
	},
	placeholder: {
		color: color.primaryGrey,
		opacity: 1,
	},
}

// Constants
export const TextBoxType = {
	text: 'text',
	email: 'email',
	password: 'password',
}

// Radium element selector, currently does not work for IE10-11
// https://github.com/FormidableLabs/radium/issues/243
const elementSelector = '.input-with-placeholder::placeholder' // Chrome, FF, etc.
const edgeElementSelector = '.input-with-placeholder::-ms-input-placeholder' // Edge

// Functional component
const TextBox = ({
	value,
	type,
	error,
	warning,
	touched,
	placeholder,
	disabled,
	style: styleOverride,
	...rest
}) => {
	const style = {
		...styles.base,
		...styleOverride,
		...(touched && error && styles.error),
		...(touched && warning && styles.warning),
	}

	return (
		<div>
			<Style scopeSelector={elementSelector} rules={styles.placeholder} />
			<Style scopeSelector={edgeElementSelector} rules={styles.placeholder} />
			<input
				value={value || ''}
				disabled={!!disabled}
				type={type}
				placeholder={placeholder}
				style={style}
				className="input-with-placeholder"
				{...rest}
			/>
			{touched && error && <p style={styles.error.text}>{error}</p>}
		</div>
	)
}

TextBox.propTypes = {
	error: PropTypes.bool,
	input: PropTypes.any,
	meta: PropTypes.any,
	type: PropTypes.oneOf(Object.values(TextBoxType)),
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	style: PropTypes.any,
	touched: PropTypes.bool,
	value: PropTypes.string,
	warning: PropTypes.bool,
}

TextBox.defaultProps = {
	type: TextBoxType.text,
	disabled: false,
}

export default TextBox
