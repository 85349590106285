import React from 'react'
import PropTypes from 'prop-types'
import { color } from 'styles/variables'
import FontAwesome, { Name } from 'components/Icons/FontAwesome'

const styles = {
	container: {
		// cursor: 'pointer',
	},
	icon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		fontSize: 72,
		height: 72,
		width: 72,
		marginTop: -72 / 2,
		marginLeft: -72 / 2,
		lineHeight: 1,
		zIndex: 8,
	},
	circle: {
		color: color.primaryBlack,
		opacity: 0.75,
	},
	triangle: {
		color: color.white,
		opacity: 1,
	},
}

class PlayButton extends React.Component {
	static propTypes = {
		handleClick: PropTypes.func,
		isPreview: PropTypes.bool,
		isYouTube: PropTypes.bool,
		watchUrl: PropTypes.string,
	}

	render() {
		const { handleClick, isPreview, isYouTube, watchUrl } = this.props

		if (isPreview) {
			styles.container = {
				...styles.container,
				cursor: 'default',
			}
		}

		return (
			<div style={styles.container} onClick={handleClick}>
				{isYouTube && !watchUrl ? (
					<div style={styles.icon}>
						<img
							src="https://static.dynamicsignal.com/images/youtube/youtube_social_icon_dark.png"
							alt=""
						/>
					</div>
				) : (
					<div>
						<FontAwesome name={Name.circle} style={{ ...styles.icon, ...styles.circle }} />
						<FontAwesome
							name={Name.play}
							style={{ ...styles.icon, ...styles.triangle }}
							transform={{ size: 8, x: 1.5 }}
						/>
					</div>
				)}
			</div>
		)
	}
}

export default PlayButton
