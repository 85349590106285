/**
 * A helper to dynamically load a script into the document head.
 *
 * @param {string} scriptUrl
 */
export const loadScript = scriptUrl =>
	new Promise((resolve, reject) => {
		const script = document.createElement('script')

		script.src = scriptUrl
		script.async = true

		script.onload = () => {
			resolve(script)
		}

		script.onerror = () => {
			reject(`Unable to load script "${scriptUrl}`)
		}

		document.body.appendChild(script)
	})

/**
 * Removes a script tag from the page
 *
 * @param {HTMLElement} script
 */
export const removeScript = script => {
	script.parentNode.removeChild(script)
}
