import { vsApi, requests } from 'helpers/apiHelpers'
import { isDesktopApp } from 'helpers/browserhelper'
import { safeGetNestedProp } from '@dysi/js-helpers'

// Data Models
import ConversationData from 'scenes/Messages/conversation.data'
import MessageData from 'scenes/Messages/message.data'
import ParticipantData from 'scenes/Messages/participant.data'
import IpcEvent from 'helpers/desktopApp/ipcEvent'

const FETCH_USERCONVERSATIONS_REQUEST = 'FETCH_USERCONVERSATIONS_REQUEST'
const FETCH_USERCONVERSATIONS_SUCCESS = 'FETCH_USERCONVERSATIONS_SUCCESS'
const FETCH_USERCONVERSATIONS_FAILURE = 'FETCH_USERCONVERSATIONS_FAILURE'
const CLEAR_USERCONVERSATIONS = 'CLEAR_USERCONVERSATIONS'
const FETCH_CONVERSATION_REQUEST = 'FETCH_CONVERSATION_REQUEST'
const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS'
const FETCH_CONVERSATION_FAILURE = 'FETCH_CONVERSATION_FAILURE'
const RELAY_FETCH_CONVERSATION_REQUEST = 'RELAY_FETCH_CONVERSATION_REQUEST'
const RELAY_FETCH_CONVERSATION_SUCCESS = 'RELAY_FETCH_CONVERSATION_SUCCESS'
const RELAY_FETCH_CONVERSATION_FAILURE = 'RELAY_FETCH_CONVERSATION_FAILURE'
const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION'
const SEND_NEW_MESSAGE_REQUEST = 'SEND_NEW_MESSAGE_REQUEST'
const SEND_NEW_MESSAGE_SUCCESS = 'SEND_NEW_MESSAGE_SUCCESS'
const SEND_NEW_MESSAGE_FAILURE = 'SEND_NEW_MESSAGE_FAILURE'
const REPLY_MESSAGE_REQUEST = 'REPLY_MESSAGE_REQUEST'
const REPLY_MESSAGE_SUCCESS = 'REPLY_MESSAGE_SUCCESS'
const REPLY_MESSAGE_FAILURE = 'REPLY_MESSAGE_FAILURE'
const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT'
const REMOVE_LAST_PARTICIPANT = 'REMOVE_LAST_PARTICIPANT'
const CLEAR_PARTICIPANTS = 'CLEAR_PARTICIPANTS'
const SET_TOMEMBER = 'SET_TOMEMBER'
const SET_TOMANAGER = 'SET_TOMANAGER'
const SET_ARCHIVE_REQUEST = 'SET_ARCHIVE_REQUEST'
const SET_ARCHIVE_SUCCESS = 'SET_ARCHIVE_SUCCESS'
const SET_ARCHIVE_FAILURE = 'SET_ARCHIVE_FAILURE'
const SET_UNARCHIVE_REQUEST = 'SET_UNARCHIVE_REQUEST'
const SET_UNARCHIVE_SUCCESS = 'SET_UNARCHIVE_SUCCESS'
const SET_UNARCHIVE_FAILURE = 'SET_UNARCHIVE_FAILURE'
const SET_READ_REQUEST = 'SET_READ_REQUEST'
const SET_READ_SUCCESS = 'SET_READ_SUCCESS'
const SET_READ_FAILURE = 'SET_READ_FAILURE'
const SET_UNREAD_REQUEST = 'SET_UNREAD_REQUEST'
const SET_UNREAD_SUCCESS = 'SET_UNREAD_SUCCESS'
const SET_UNREAD_FAILURE = 'SET_UNREAD_FAILURE'
const FETCH_UNREAD = 'FETCH_UNREAD'
const CLEAR_ERRORS = 'CLEAR_ERRORS'
const FETCH_TYPEAHEADV2_REQUEST = 'FETCH_TYPEAHEADV2_REQUEST'
const FETCH_TYPEAHEADV2_SUCCESS = 'FETCH_TYPEAHEADV2_SUCCESS'
const FETCH_TYPEAHEADV2_FAILURE = 'FETCH_TYPEAHEADV2_FAILURE'
const CLEAR_TYPEAHEADV2 = 'CLEAR_TYPEAHEADV2'
const SET_DIRECT_MANAGER = 'SET_DIRECT_MANAGER'

const SET_UNREAD_CONVERSATION_COUNT_REQUEST = 'SET_UNREAD_CONVERSATION_COUNT_REQUEST'
const SET_UNREAD_CONVERSATION_COUNT_SUCCESS = 'SET_UNREAD_CONVERSATION_COUNT'
const SET_UNREAD_CONVERSATION_COUNT_FAILURE = 'SET_UNREAD_CONVERSATION_COUNT_FAILURE'
const RELAY_SET_UNREAD_CONVERSATION_COUNT = 'RELAY_SET_UNREAD_CONVERSATION_COUNT'

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

export const ConversationTypes = {
	memberToMember: 'MemberToMember',
	managerToMember: 'ManagerToMember',
}

//#region Async action Creators

export const asyncGetUserConversations = (skip, take, archived, query, selectedConversationId) => {
	return vsApi({
		endpoint: requests.getConversations.getEndpoint(archived, skip, take, query),
		actions: {
			request: FETCH_USERCONVERSATIONS_REQUEST,
			success: FETCH_USERCONVERSATIONS_SUCCESS,
			failure: FETCH_USERCONVERSATIONS_FAILURE,
		},
		context: {
			conversationId: selectedConversationId,
		},
	})
}

export const asyncGetTypeaheadV2 = (take, term, type) => {
	return vsApi({
		endpoint: requests.searchTypeaheadV2.endpoint,
		actions: {
			request: FETCH_TYPEAHEADV2_REQUEST,
			success: FETCH_TYPEAHEADV2_SUCCESS,
			failure: FETCH_TYPEAHEADV2_FAILURE,
		},
		requestOptions: requests.searchTypeaheadV2.getRequestOptions(take, term, type),
		context: {
			term,
		},
	})
}

export const asyncGetConversation = (conversationId, skip, take, abortable) => {
	return vsApi({
		endpoint: requests.getConversation.getEndpoint(conversationId, skip, take, true),
		actions: {
			request: FETCH_CONVERSATION_REQUEST,
			success: FETCH_CONVERSATION_SUCCESS,
			failure: FETCH_CONVERSATION_FAILURE,
		},
		context: {
			conversationId,
		},
		options: {
			abortable,
		},
	})
}

export const relayGetConversation = (conversationId, skip, take, markAsRead = false) => {
	return vsApi({
		endpoint: requests.getConversation.getEndpoint(conversationId, skip, take, markAsRead),
		actions: {
			request: RELAY_FETCH_CONVERSATION_REQUEST,
			success: RELAY_FETCH_CONVERSATION_SUCCESS,
			failure: RELAY_FETCH_CONVERSATION_FAILURE,
		},
	})
}

export const asyncNewConversation = (
	conversationType,
	messageText,
	skip,
	take,
	userIds,
	postId
) => {
	return vsApi({
		endpoint: requests.createConversation.getEndpoint(skip, take),
		actions: {
			request: SEND_NEW_MESSAGE_REQUEST,
			success: SEND_NEW_MESSAGE_SUCCESS,
			failure: SEND_NEW_MESSAGE_FAILURE,
		},
		requestOptions: requests.createConversation.getRequestOptions(
			conversationType,
			messageText,
			userIds,
			postId
		),
	})
}

export const asyncReplyConversation = (conversationId, messageText, postId) => {
	const endpoint = 'conversationreply?'
	const body = {
		id: conversationId,
		messageText: messageText,
		postId: postId,
	}
	const requestOptions = { method: 'POST', body: JSON.stringify(body) }

	return vsApi(
		endpoint,
		{
			request: REPLY_MESSAGE_REQUEST,
			success: REPLY_MESSAGE_SUCCESS,
			failure: REPLY_MESSAGE_FAILURE,
		},
		requestOptions
	)
}

export const asyncArchiveConversation = (conversationId, context) => {
	return vsApi({
		endpoint: requests.archiveConversation.getEndpoint(conversationId),
		actions: {
			request: SET_ARCHIVE_REQUEST,
			success: SET_ARCHIVE_SUCCESS,
			failure: SET_ARCHIVE_FAILURE,
		},
		requestOptions: requests.archiveConversation.requestOptions,
		context,
	})
}

export const asyncUnarchiveConversation = (conversationId, context) => {
	return vsApi({
		endpoint: requests.unarchiveConversation.getEndpoint(conversationId),
		actions: {
			request: SET_UNARCHIVE_REQUEST,
			success: SET_UNARCHIVE_SUCCESS,
			failure: SET_UNARCHIVE_FAILURE,
		},
		requestOptions: requests.unarchiveConversation.requestOptions,
		context,
	})
}

export const asyncMarkAsRead = (conversationId, context) => {
	return vsApi({
		endpoint: requests.markConversationAsRead.getEndpoint(conversationId),
		actions: {
			request: SET_READ_REQUEST,
			success: SET_READ_SUCCESS,
			failure: SET_READ_FAILURE,
		},
		requestOptions: requests.markConversationAsRead.requestOptions,
		context,
	})
}

export const asyncMarkAsUnread = (conversationId, context) => {
	return vsApi({
		endpoint: requests.markConversationAsUnread.getEndpoint(conversationId),
		actions: {
			request: SET_UNREAD_REQUEST,
			success: SET_UNREAD_SUCCESS,
			failure: SET_UNREAD_FAILURE,
		},
		requestOptions: requests.markConversationAsUnread.requestOptions,
		context,
	})
}

export const asyncGetUnreadConversationCount = () => {
	return vsApi({
		endpoint: requests.getUnreadConversationsCount.endpoint,
		actions: {
			request: SET_UNREAD_CONVERSATION_COUNT_REQUEST,
			success: SET_UNREAD_CONVERSATION_COUNT_SUCCESS,
			failure: SET_UNREAD_CONVERSATION_COUNT_FAILURE,
		},
	})
}

//endregion

//region Action creators

export const clearUserConversations = () => {
	return {
		type: CLEAR_USERCONVERSATIONS,
	}
}

export const clearTypeaheadV2 = () => {
	return {
		type: CLEAR_TYPEAHEADV2,
	}
}

export const clearConversation = () => {
	return {
		type: CLEAR_CONVERSATION,
	}
}

export const addParticipant = participant => {
	return {
		type: ADD_PARTICIPANT,
		participant,
	}
}

export const removeParticipant = participantId => {
	return {
		type: REMOVE_PARTICIPANT,
		participantId,
	}
}

export const removeLastParticipant = () => {
	return {
		type: REMOVE_LAST_PARTICIPANT,
	}
}

export const clearParticipants = () => {
	return {
		type: CLEAR_PARTICIPANTS,
	}
}

export const setToManager = () => {
	return {
		type: SET_TOMANAGER,
	}
}

export const setToMember = () => {
	return {
		type: SET_TOMEMBER,
	}
}

export const relaySetUnreadConversationCount = count => {
	return {
		type: RELAY_SET_UNREAD_CONVERSATION_COUNT,
		count,
	}
}

export const getUnread = () => {
	return {
		type: FETCH_UNREAD,
	}
}

export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS,
	}
}

export const setDirectManager = directManager => {
	return {
		type: SET_DIRECT_MANAGER,
		directManager,
	}
}
//#endregion

//#region Initial State
const initialConversations = {
	conversations: {
		allIds: [],
		byId: {},
	},
}

const initialParticipants = {
	participants: {
		allIds: [],
		byId: {},
	},
}

const initialConversation = {
	currentConversationId: null,
}

const initialSelectedParticipants = {
	pendingParticipants: {
		allIds: [],
		byId: {},
	},
}

const initialConversationType = {
	conversationType: ConversationTypes.memberToMember,
}

const initialErrors = {
	errors: [],
}

const initialUnreadConvoCount = {
	unreadConversations: 0,
}

const initialDirectManager = {
	directManager: null,
}

const initialState = {
	...initialConversations,
	...initialConversation,
	...initialParticipants,
	...initialSelectedParticipants,
	...initialConversationType,
	...initialErrors,
	...initialUnreadConvoCount,
	...initialDirectManager,
}
//#endregion

//#region Private Helpers
export const normalize = (array, id) => {
	const byId = array.reduce((obj, item) => {
		obj[item[id]] = item
		return obj
	}, {})
	const allIds = array.map(item => item[id])

	return {
		byId: byId,
		allIds: allIds,
	}
}

export const addSearchResults = (conversations, term) => {
	conversations.forEach(conversation => {
		conversation.participants = conversation.participants.map(participant => participant.userId)
		// Sort by earliest date and filters for exact term only.
		// TODO API will change to return different kinds of matches
		conversation.messages = conversation.messages.sort((a, b) => {
			return new Date(b.createdDate) - new Date(a.createdDate)
		})
		conversation.messages = normalize(conversation.messages, 'id')
		conversation.messages.searchTerm = term
		conversation.conversationId = conversation.id
		conversation.lastMessage = {
			messageTextPreview:
				conversation.messages.allIds.length > 1
					? `${conversation.messages.allIds.length} results`
					: `${conversation.messages.allIds.length} result`,
		}
	})
	return conversations
}

export const mergeArrays = (array1, array2) => {
	const filteredArray = array1.filter(value => !array2.includes(value))
	return [...filteredArray, ...array2]
}

// Conversations are normalized in the store
const mergeInNewConversations = (newConversations, state) => {
	// Map incoming conversations to the conversation class and normalize it
	const newConversationMap = newConversations.map(conversation => ConversationData(conversation))
	const normalizedNewConversationMap = normalize(newConversationMap, 'conversationId')

	// Add these conversations to existing conversations
	return {
		allIds:
			state.conversations.allIds.length > 0
				? mergeArrays(state.conversations.allIds, normalizedNewConversationMap.allIds)
				: normalizedNewConversationMap.allIds,
		byId: {
			...state.conversations.byId,
			...normalizedNewConversationMap.byId,
		},
		//next: ,
		//total: ,
	}
}

const mergeInNewConversation = (newConversation, state) => {
	const orderedAllIds = [
		...new Set([newConversation.conversationId].concat(state.conversations.allIds)),
	]

	return {
		allIds: orderedAllIds,
		byId: {
			...state.conversations.byId,
			[newConversation.conversationId]: ConversationData(newConversation),
		},
		//next: ,
		//total: ,
	}
}

// Participants are stored as key:value in the store
const mergeInNewParticipants = (newConversations, state) => {
	const newParticipants = newConversations
		// Pull out arrays of participant objects
		.map(convo => {
			return convo.participants
		})
		// Combine arrays into one array
		.reduce((a, b) => {
			return a.concat(b)
		}, [])

	// Map incoming participants to the participant class and normalize it
	const newParticipantMap = newParticipants.reduce((map, participant) => {
		map[participant.userId] = ParticipantData(participant)
		return map
	}, {}) // Map starts as empty object

	// Add these participants to existing participants
	return {
		...(state && state.participants),
		...newParticipantMap,
	}
}

const updateSelectedConversation = (conversation, state) => {
	const newConversationMap = { ...state.conversations }

	const newConversation = {
		...conversation,
		isNew: false,
		unreadMessageCount: 0,
	}

	// Copy all previous existing values not included in the response
	Object.keys(conversation)
		.filter(key => key in conversation)
		.forEach(key => {
			newConversation[key] = newConversation[key]
				? newConversation[key]
				: state.conversations.byId[newConversation.conversationId][key]
		})

	newConversationMap.byId[newConversation.conversationId] = newConversation
	return newConversationMap
}

export const removeConversation = (state, response) => {
	const existingConversationsCopy = { ...state.conversations }
	const conversationId = response.context.conversationId

	const filteredIds = existingConversationsCopy.allIds.filter(id => id !== conversationId)
	const remainingConversations = filteredIds.reduce((accumulator, id) => {
		accumulator[id] = existingConversationsCopy.byId[id]
		return accumulator
	}, {})

	return {
		allIds: filteredIds,
		byId: remainingConversations,
		next:
			existingConversationsCopy.next > 0
				? existingConversationsCopy.next + 1
				: existingConversationsCopy.next,
		total: existingConversationsCopy.total - 1,
	}
}

const markConversationAsRead = (conversationId, conversations) => {
	const existingConversationsCopy = { ...conversations }

	existingConversationsCopy.byId[conversationId] = {
		...existingConversationsCopy.byId[conversationId],
		isNew: false,
		unreadMessageCount: 0,
	}

	return existingConversationsCopy
}

const markConversationAsUnread = (conversationId, state) => {
	const existingConversationsCopy = { ...state.conversations }

	const conversationCopy = {
		...state.conversations.byId[conversationId],
		isNew: true,
		unreadMessageCount: 1,
	}

	existingConversationsCopy.byId[conversationId] = conversationCopy

	return existingConversationsCopy
}

const filterUnreadConversations = state => {
	const existingConversationsCopy = { ...state.conversations }
	const filteredIds = [...existingConversationsCopy.allIds].filter(
		id => existingConversationsCopy.byId[id].isNew === true
	)
	const filteredConversations = filteredIds.reduce((accumulator, id) => {
		accumulator[id] = existingConversationsCopy.byId[id]
		return accumulator
	}, {})

	return {
		allIds: filteredIds,
		byId: filteredConversations,
		next: -1, // Don't want the LoadMore component to show when filtering
		total: state.conversations.total,
	}
}

const moveConversationToTop = (existingConversationIds, recentConversationId) => {
	if (existingConversationIds.includes(recentConversationId))
		existingConversationIds.splice(existingConversationIds.indexOf(recentConversationId), 1)

	existingConversationIds.unshift(recentConversationId)

	return existingConversationIds
}

const mergeInSuccessfulMessage = (state, message) => {
	const {
		conversationId,
		conversationMessageId,
		createdDate,
		currentTime,
		messageText,
		postId,
		userDisplayName,
		userId,
	} = message

	const newConversationOrder = moveConversationToTop(state.conversations.allIds, conversationId)
	const messageTextPreview = messageText

	return {
		...state,
		conversations: {
			...state.conversations,
			allIds: newConversationOrder,
			byId: {
				...state.conversations.byId,
				[conversationId]: {
					// Update the lastMessage object to update the preview text in the ConversationList
					...state.conversations.byId[conversationId],
					lastMessage: {
						createdDate,
						currentTime,
						messageTextPreview,
						postId,
						userDisplayName,
						userId,
					},
					// Update the messages list with the most recent message
					messages: {
						allIds: [conversationMessageId].concat([
							...state.conversations.byId[conversationId].messages.allIds,
						]),
						byId: {
							...state.conversations.byId[conversationId].messages.byId,
							[conversationMessageId]: MessageData(message),
						},
					},
				},
			},
		},
	}
}

const addNewPendingParticipant = (state, newParticipant) => {
	const participantsExist =
		state.pendingParticipants &&
		state.pendingParticipants.allIds &&
		state.pendingParticipants.allIds.length > 0

	const currentPendingParticipantIds = participantsExist ? state.pendingParticipants.allIds : []
	const deduplicatedParticipantIds =
		newParticipant && newParticipant.id
			? [...new Set(currentPendingParticipantIds.concat([newParticipant.id]))]
			: currentPendingParticipantIds

	return {
		allIds: deduplicatedParticipantIds,
		byId: {
			...state.pendingParticipants.byId,
			[newParticipant.id]: newParticipant,
		},
	}
}

const removePendingParticipant = (state, removedParticipantId) => {
	return {
		...state.pendingParticipants,
		allIds: state.pendingParticipants.allIds.filter(id => id !== removedParticipantId),
	}
}

const mergeMessagesObjects = (state, newConversation) => {
	const currentMessages = state.conversations.byId[newConversation.conversationId].messages
	const filteredMessages = newConversation.messages.allIds.filter(
		id => !currentMessages.allIds.includes(id)
	)
	const allIds = [...currentMessages.allIds, ...filteredMessages]

	return {
		allIds,
		byId: {
			...currentMessages.byId,
			...newConversation.messages.byId,
		},
	}
}

//#endregion

export default function messageReducer(state = initialState, action) {
	switch (action.type) {
		//region User Conversations
		case FETCH_USERCONVERSATIONS_REQUEST: {
			return {
				...state,
				isUserConversationsPending: true,
			}
		}
		case FETCH_USERCONVERSATIONS_SUCCESS: {
			const { conversations: newConversations } = action.response
			const { conversationId: selectedConversationId } = action.context

			let conversations = mergeInNewConversations(newConversations, state)
			conversations.next = action.response.next
			conversations.total = action.response.total

			// When a user navigates directly to a conversation, mark it as read
			if (selectedConversationId) {
				conversations = markConversationAsRead(selectedConversationId, conversations)
			}

			const participants = mergeInNewParticipants(newConversations, state)

			return {
				...state,
				conversations,
				isUserConversationsPending: false,
				participants,
			}
		}
		case FETCH_USERCONVERSATIONS_FAILURE: {
			return {
				...state,
				...initialConversations,
				isUserConversationsPending: false,
			}
		}
		case CLEAR_USERCONVERSATIONS: {
			return {
				...state,
				...initialConversations,
			}
		}
		//endregion

		//region Conversation
		case FETCH_CONVERSATION_REQUEST: {
			const conversationId = action.context.conversationId
			// So that the message is marked as read as soon as a user clicks on it
			const conversations = markConversationAsRead(conversationId, state.conversations)

			return {
				...state,
				conversations,
				isConversationPending: true,
			}
		}
		case FETCH_CONVERSATION_SUCCESS: {
			const conversation = ConversationData(action.response)
			if (state.currentConversationId === conversation.conversationId) {
				conversation.messages = mergeMessagesObjects(state, conversation)
			}

			let conversations = updateSelectedConversation(conversation, state)
			// Without this, navigating from a desktop notification
			// doesn't mark the conversation as read
			conversations = markConversationAsRead(conversation.id, state.conversations)

			const participants = mergeInNewParticipants([action.response], state)

			return {
				...state,
				isConversationPending: false,
				currentConversationId: conversation.conversationId,
				conversations,
				participants,
			}
		}
		case FETCH_CONVERSATION_FAILURE: {
			return {
				...state,
				...initialConversation,
				isConversationPending: false,
			}
		}
		case RELAY_FETCH_CONVERSATION_REQUEST: {
			return {
				...state,
			}
		}
		case RELAY_FETCH_CONVERSATION_SUCCESS: {
			const { response: newConversation } = action

			if (newConversation.conversationId === state.selectedConversationId) {
				markConversationAsRead(newConversation.conversationId)
			}

			// Update conversation object in conversation list
			newConversation.isNew =
				newConversation.conversationId === state.currentConversationId ? false : true
			newConversation.isReply = false
			newConversation.messageCount = newConversation.messages.length

			// Update message preview
			const mostRecentMessage = newConversation.messages[0]
			newConversation.lastMessage = {
				createdDate: mostRecentMessage.createdDate,
				messageTextPreview: mostRecentMessage.messageText.substr(0, 69),
				postId: mostRecentMessage.postId,
				userId: mostRecentMessage.userId,
				userDisplayName: mostRecentMessage.userId,
				currentTime: mostRecentMessage.currentTime,
			}

			const conversations = mergeInNewConversation(newConversation, state)
			const participants = mergeInNewParticipants([newConversation], state)

			return {
				...state,
				conversations,
				participants,
			}
		}
		case RELAY_FETCH_CONVERSATION_FAILURE: {
			return {
				...state,
			}
		}
		case CLEAR_CONVERSATION: {
			return {
				...state,
				...initialConversation,
			}
		}
		//endregion

		//region Search Messages
		// TODO : Change these when search gets re-enabled
		case FETCH_TYPEAHEADV2_REQUEST: {
			return {
				...state,
				isSearching: true,
			}
		}
		case FETCH_TYPEAHEADV2_SUCCESS: {
			// TODO: update when API gets updated
			const { conversations: userConversations } = action.response
			const formattedConversations = addSearchResults(userConversations, action.context.term)
			const normalizedConversations = normalize(formattedConversations, 'conversationId')
			const participants = mergeInNewParticipants(userConversations, state)

			return {
				...state,
				conversations: {
					...userConversations.conversations,
					byId: {
						...normalizedConversations.byId,
					},
					allIds: normalizedConversations.allIds,
				},
				participants,
				isSearching: false,
			}
		}
		case FETCH_TYPEAHEADV2_FAILURE: {
			return {
				...state,
				isSearching: false,
			}
		}
		//endregion

		//region Message
		case SEND_NEW_MESSAGE_REQUEST: {
			return {
				...state,
			}
		}
		case SEND_NEW_MESSAGE_SUCCESS: {
			const { response: newConversation } = action

			// Fill in information that's not included in the response
			const newConversationObject = {
				...newConversation,

				isNew: false,
				isReply: false,
				lastMessage: {
					createdDate: newConversation.lastMessageDate,
					messageTextPreview: newConversation.messages[0].messageText.substr(0, 69),
					userId: newConversation.lastPostingUserId,
					userDisplayName: '',
					userProfilePictureSquare40Url: '',
					currentTime: '',
				},
				messageCount: newConversation.messages.length,
				unreadMessageCount: 0,
			}

			const conversations = mergeInNewConversation(newConversationObject, state)
			const currentConversationId = newConversationObject.conversationId
			const participants = mergeInNewParticipants([newConversationObject], state)

			return {
				...state,
				...initialSelectedParticipants,
				conversations,
				currentConversationId,
				participants,
			}
		}
		case SEND_NEW_MESSAGE_FAILURE: {
			const errors = [...state.errors].concat(action.error)
			return {
				...state,
				errors: errors,
			}
		}
		case REPLY_MESSAGE_REQUEST: {
			return {
				...state,
			}
		}
		case REPLY_MESSAGE_SUCCESS: {
			const newMessage = action.response
			const newState = mergeInSuccessfulMessage(state, newMessage)

			return {
				...state,
				...newState,
			}
		}
		case REPLY_MESSAGE_FAILURE: {
			return {
				...state,
			}
		}
		//endregion

		//region Participants
		case ADD_PARTICIPANT: {
			const { participant: newParticipant } = action

			const pendingParticipants = addNewPendingParticipant(state, newParticipant)

			return {
				...state,
				pendingParticipants,
			}
		}
		case REMOVE_PARTICIPANT: {
			const { participantId: removedParticipantId } = action

			const pendingParticipants = removePendingParticipant(state, removedParticipantId)

			return {
				...state,
				pendingParticipants,
			}
		}
		case REMOVE_LAST_PARTICIPANT: {
			const originalIds = [...state.pendingParticipants.allIds]
			originalIds.pop()

			const pendingParticipants = {
				allIds: originalIds,
				byId: state.pendingParticipants.byId,
			}

			return {
				...state,
				pendingParticipants,
			}
		}
		case CLEAR_PARTICIPANTS: {
			return {
				...state,
				...initialSelectedParticipants,
			}
		}
		//endregion

		//region Conversation Type
		case SET_TOMEMBER: {
			return {
				...state,
				conversationType: ConversationTypes.memberToMember,
			}
		}
		case SET_TOMANAGER: {
			return {
				...state,
				...initialSelectedParticipants,
				conversationType: ConversationTypes.managerToMember,
			}
		}
		//endregion

		//region Archive / Unarchive
		case SET_ARCHIVE_REQUEST: {
			return {
				...state,
			}
		}
		case SET_ARCHIVE_SUCCESS: {
			const conversations = removeConversation(state, action)
			return {
				...state,
				conversations,
			}
		}
		case SET_ARCHIVE_FAILURE: {
			return {
				...state,
			}
		}
		case SET_UNARCHIVE_REQUEST: {
			return {
				...state,
			}
		}
		case SET_UNARCHIVE_SUCCESS: {
			const conversations = removeConversation(state, action)
			return {
				...state,
				conversations,
			}
		}
		case SET_UNARCHIVE_FAILURE: {
			return {
				...state,
			}
		}
		//endregion

		//region Read / Unread
		case SET_READ_REQUEST: // Fake immediate response, but still change it after the API call goes through
		case SET_READ_SUCCESS: {
			const conversationId = action.context.conversationId
			const conversations = markConversationAsRead(conversationId, state.conversations)

			return {
				...state,
				conversations,
			}
		}
		case SET_READ_FAILURE: {
			return {
				...state,
			}
		}
		case SET_UNREAD_REQUEST: // Fake immediate response, but still change it after the API call goes through
		case SET_UNREAD_SUCCESS: {
			const conversationId = action.context.conversationId
			const conversations = markConversationAsUnread(conversationId, state)

			// Leave current conversation if it's being set to unread (OMA behavior)
			const currentConversationId =
				state.currentConversationId === conversationId ? null : state.currentConversationId

			return {
				...state,
				conversations,
				currentConversationId,
				isConversationPending: false, // In case we're interrupting the loading conversation
			}
		}
		case SET_UNREAD_FAILURE: {
			return {
				...state,
			}
		}
		case FETCH_UNREAD: {
			const conversations = filterUnreadConversations(state)
			return {
				...state,
				conversations,
			}
		}
		//endregion

		//region Unread Conversation Count
		case SET_UNREAD_CONVERSATION_COUNT_REQUEST: {
			return {
				...state,
			}
		}
		case SET_UNREAD_CONVERSATION_COUNT_SUCCESS: {
			if (isDesktopApp) {
				window.ipcRenderer.send(IpcEvent.update.messageCount, {
					count: safeGetNestedProp(action, 'response.total', 0),
				})
			}

			return {
				...state,
				unreadConversations: action.response.total,
			}
		}
		case SET_UNREAD_CONVERSATION_COUNT_FAILURE: {
			return {
				...state,
			}
		}
		case RELAY_SET_UNREAD_CONVERSATION_COUNT: {
			return {
				...state,
				unreadConversations: action.count,
			}
		}
		//endregion

		//region Errors
		case CLEAR_ERRORS: {
			return {
				...state,
				...initialErrors,
			}
		}
		//endregion

		// LOCATION_CHANGE - Clear the current conversation when navigating away from Messages
		case LOCATION_CHANGE: {
			const changeToMessages = action.payload.pathname.substr(0, 9) === '/messages'

			if (state.currentConversationId && !changeToMessages) {
				return {
					...state,
					currentConversationId: null,
				}
			}
			return {
				...state,
			}
		}

		case SET_DIRECT_MANAGER: {
			return {
				...state,
				directManager: action.directManager,
			}
		}

		default:
			return state
	}
}
