import SocialProviderData, {
	SocialPostProviders,
	ShowShareButtonOnSocialPostProviders,
} from 'helpers/socialProviderData'
import Restrictions from 'helpers/textRestrictions'
import {
	extractRootDomainFromUrl,
	getMedia,
	getOriginalImage,
	getSmallProfileImage,
	filterImageGallery,
} from 'helpers/postHelpers'
import { safeGetNestedProp, stringIsEmptyOrWhitespace } from '@dysi/js-helpers'
import lang from '../post.lang'
import { isRtlLanguage } from 'helpers/rtlLanguageHelper'

export const ApprovalState = {
	Published: 'Published',
	Pending: 'Pending',
	Excluded: 'Excluded',
}

export const DisplayMode = {
	OpenExternally: 'OpenExternally',
	DisplayInApp: 'DisplayInApp',
}

/**
 * PostPreview represents a limited version of a post. Use it to show an anonymous
 * preview of a post, with no actions, statistics, or discussions. Create it from
 * a PostData object.
 * @param {Object} postData
 */
export const PostPreview = postData => {
	// Copy PostData, reset props
	const previewPost = {
		...postData,

		// Preview mode
		isPreview: true,

		// No stats, sharing, discussions
		statistics: {},
		isShareable: false,
		userCommentable: false,

		// Replace function props
		showShareButton: () => false,
	}

	// showLockIcon is now a getter so can't be overridden with a functional prop, do it here instead
	delete previewPost.showLockIcon
	previewPost.showLockIcon = false

	return previewPost
}

const isSocialPost = post => {
	return SocialPostProviders.includes(post.provider)
}

const isLinkedPost = post => {
	return isSocialPost(post) && post.links && post.links.length > 0
}

/**
 * 1) Linked social posts use the link URL as the title and truncate it to 80 characters.
 * 2) YouTube is special and displays the post title but truncates it to 80 characters since it.
 *    is the only social post that displays the description as well.
 * 3) Every other social post is restricted to 280 characters since they are special
 * 4) Normal posts get truncated at 80.
 *
 * If Details layout increase max from 80 to 100 characters (social posts are always 280 characters max).
 *
 * @param {Object} post
 * @param {Boolean} isDetailsLayout
 * @returns
 *
 */
export const getPostMaximumTitleLength = (post, { isDetailsLayout }) => {
	// 2
	if (post.provider === SocialProviderData.YouTube.provider) {
		return isDetailsLayout
			? Restrictions.PostTitle.NonSocialDetailsLayout
			: Restrictions.PostTitle.NonSocial
	}

	// 1
	if (isLinkedPost(post) && post.provider !== SocialProviderData.Twitter.provider) {
		return isDetailsLayout
			? Restrictions.PostTitle.NonSocialDetailsLayout
			: Restrictions.PostTitle.NonSocial
	}

	// 3
	if (isSocialPost(post)) {
		return Restrictions.PostTitle.Social.Post
	}

	// 4
	return isDetailsLayout
		? Restrictions.PostTitle.NonSocialDetailsLayout
		: Restrictions.PostTitle.NonSocial
}

// PostData represents a single post. Its properties are either native (returned directly from the API) or
// calculated. Please add any missing props or calculations you need below.
// 	o Any calculations you want to do in a component, should be here, instead, to support consistency
//		  and sharing across components
// 	o Keep lists in alphabetical order!
/**
 * PostData represents a single post. Its properties are either native (returned directly from the API) or calculated.
 * @param {Object} post
 */
const PostData = post => {
	const authorName =
		safeGetNestedProp(post, 'author.author') ||
		(post.postBylineType === 'Source' && safeGetNestedProp(post, 'author.postSourceName')) ||
		null

	return {
		// ===========================================================================================
		// Native props - Alphabetical, please!
		// ===========================================================================================
		approvalState: post.approvalState,
		categories: post.categories,
		cleanPermaLink: post.cleanPermaLink,
		createdDate: post.createdDate,
		/** AKA: "Note to Members" */
		creatorComments: post.creatorComments,
		/** Some posts can only be shared "directly" on specific providers. For example, a YouTube video post */
		directShares: post.directShares || [],
		/** Whether internal like/comment is available for the post */
		discussionsEnabled: post.internalDiscussionsEnabled,
		displayMode: post.displayMode,
		documents: post.documents,
		imageGallery: post.imageGallery && filterImageGallery(post.imageGallery), //Filter out images without url TA-25387
		isBookmarkedByUser: post.isBookmarkedByUser,
		isCommentedByUser: post.isCommentedByUser,
		isExpired: post.isExpired,
		isLikedByUser: post.isLikedByUser,
		isLiveStream: post.isLiveStream,
		isPinned: post.pinned,
		isSharedByUser: post.isSharedByUser,
		isSharingBlacklisted: post.isSharingBlacklisted,
		isViewedByUser: post.isViewedByUser,
		language: post.language,
		liveStreamState: post.liveStreamState,
		liveStreamStartDate: post.eventStartDate,
		permaLink: post.permaLink, // NOTE: Use cleanPermaLink instead!!! We should probably deprecate this.
		providerPostId: post.providerPostId,
		postId: post.postId,
		postType: post.postType,
		provider: post.provider,
		shareDisclosures: post.shareDisclosures,
		shareImagesOnly: post.shareImagesOnly,
		sharePoints: post.sharePoints,
		shortSuggestedShareTextList: post.shortSuggestedShareTextList,
		suggestedShareTextList: post.suggestedShareTextList,
		surveyId: post.surveyId,
		tagline: post.tagLine,
		/** Pre-made URL slug used to generate SEO-friendly URLs */
		urlSlug: post.urlSlug,
		/** Whether the current user is able to internally comment on the post */
		userCommentable: post.userCommentable,
		/** Whether the current user is able to internally like on the post */
		userLikeable: post.userLikeable,
		/** Whether the current user can share the post */
		userShareable: post.userShareable,
		/** Whether the current user can translate the post to his/her display language */
		userTranslatable: post.userTranslatable,

		// ===========================================================================================
		// Calculated props - Alphabetical, please!
		//   NOTE: Use these when the property isn't a 1:1 map with the API post object
		// ===========================================================================================

		/**
		 * The original content author.
		 * This is only relevant for posts from external sources
		 */
		author: {
			name: authorName,
			profileUrl: safeGetNestedProp(post, 'author.profileUrl'),
			profileImageUrl: safeGetNestedProp(post, 'author.profileImageUrl'),
			postSourceSite: safeGetNestedProp(post, 'author.postSourceSite'),
			userId: safeGetNestedProp(post, 'author.userId'),
			get providerHandle() {
				delete this.providerHandle
				const handle = safeGetNestedProp(post, 'author.providerUserName')
				return (this.providerHandle =
					post.provider === SocialProviderData.Twitter.provider &&
					!stringIsEmptyOrWhitespace(handle)
						? `@${handle}`
						: null)
			},
		},

		contentHasEntities: !!post.contentWithEntities && post.content !== post.contentWithEntities,
		/**
		 * The internal user that created the post.
		 * If they do not wish to show the creator then the API will not return one.
		 */
		creator: post.creatorInfo
			? {
					userId: post.creatorInfo.userId,
					displayName: post.creatorInfo.displayName || lang.anonymousUser,
					isActive: post.creatorInfo.isActive,
					profilePictureImages: getSmallProfileImage(post.creatorInfo.profilePictureImages),
			  }
			: null,
		descriptionHasEntities:
			!!post.descriptionWithEntities && post.description !== post.descriptionWithEntities,
		image: getOriginalImage(post.images),
		isPrivate: post.displayMode === DisplayMode.DisplayInApp && post.sharable === false,
		isRtlLanguage:
			typeof post.isRtlLanguage !== 'undefined' ? post.isRtlLanguage : isRtlLanguage(post.language),
		isShareable: !!(post.sharable && post.userShareable),

		// NOTE: isSocialPost from the API is different than our internal use case. isSocialPost refers
		// 		to whether or not we display the post in our 'social post' style. The one from the API
		// 		only affects the 'ShowShareButtonOnSocialPosts' sphere parameter. Do not confuse these
		// 		two. BE CAREFUL WHEN CREATING NEW PROPERTIES OF WHEN TO USE post.isSocialPost vs.
		// 		this.isSocialPost
		isSocialPost: isSocialPost(post),

		/** Whether fields in this post have been translated. */
		isTranslated: false,

		lastUpdate: Date.now(), // Freshness
		lastShareDate: safeGetNestedProp(post, 'userShareInfo.mostRecentShareDate'),
		media: getMedia(post.media),
		permaLinkDomain: extractRootDomainFromUrl(post.cleanPermaLink),

		statistics: {
			clickCount: safeGetNestedProp(post, 'statistics.clickCount', 0),
			commentCount: safeGetNestedProp(post, 'statistics.commentCount', 0),
			impressionCount: safeGetNestedProp(post, 'statistics.impressionCount', 0),
			likeCount: safeGetNestedProp(post, 'statistics.likeCount', 0),
			reactionCount: safeGetNestedProp(post, 'statistics.reactionCount', 0),
			shareCount: post.sharable ? safeGetNestedProp(post, 'statistics.shareCount', 0) : 0,
			viewCount: safeGetNestedProp(post, 'statistics.viewedCount', 0),
		},

		titleHasEntities: !!post.titleWithEntities && post.title !== post.titleWithEntities,

		twitterCommentRules:
			post.shareCommentRules &&
			post.shareCommentRules.filter(
				r => r.provider === SocialProviderData.Twitter.provider && r.shareMaxCharacterLimit
			),

		// ===========================================================================================
		// Getter props (Sort alphabetically)
		//   NOTE: Use these when you need to self-reference
		// ===========================================================================================

		/**
		 * Byline
		 * - Don't show on Social Posts
		 * - Don't show when postBylineType is Hidden
		 */
		get byline() {
			delete this.byline
			return (this.byline =
				!this.isSocialPost && post.postBylineType !== 'Hidden' ? authorName : null)
		},

		/**
		 * Body/Content
		 */
		get content() {
			delete this.content
			return (this.content =
				post.provider === 'InApp' && post.rawContent ? post.rawContent : post.content)
		},

		get contentWithEntities() {
			delete this.contentWithEntities
			return (this.contentWithEntities =
				post.provider === 'InApp' && post.rawContentWithEntities
					? post.rawContentWithEntities
					: post.contentWithEntities)
		},

		// Normally straightforward, we perform this song and dance because social post descriptions
		// are pretty garbage at the moment so we're not going to display them for now.
		get description() {
			delete this.description

			// YouTube is the only social provider that is allowed to have a description
			if (this.isSocialPost && post.provider !== SocialProviderData.YouTube.provider)
				return (this.description = null)

			return (this.description = post.description)
		},

		get descriptionWithEntities() {
			delete this.descriptionWithEntities

			if (!post.descriptionWithEntities) return (this.descriptionWithEntities = this.description)

			if (this.isSocialPost && post.provider !== SocialProviderData.YouTube.provider)
				return (this.descriptionWithEntities = null)

			return (this.descriptionWithEntities = post.descriptionWithEntities)
		},

		// Sometimes we import social posts that are referencing other stuff on the internet. Some
		// providers actually recognize those links and pass that along to us. If it has links to other
		// sources, we perform some magic to make the posts look different.
		get isLinkedPost() {
			delete this.isLinkedPost
			return (this.isLinkedPost = isLinkedPost(post))
		},

		/** Verifies that the post meets the minimum requirements needed to be valid. */
		get isValid() {
			delete this.isValid
			return (this.isValid = !!(
				this.postId &&
				this.postId !== '00000000-0000-0000-0000-000000000000' &&
				this.title &&
				this.approvalState === ApprovalState.Published
			))
		},

		/** The published date for the post. Internally, this is startDate, since a.) for social
		 * posts, publish date is imported as the date the content was published on the social network
		 * and b.) because posts, when edited, have their startDate changed ,but not their publishDate.
		 */
		get publishDate() {
			delete this.publishDate
			return (this.publishDate = post.startDate)
		},

		/**
		 * Whether or not we should show the permalink on a post component.
		 */
		get showPermaLink() {
			delete this.showPermaLink

			// We don't show permalinks for social posts
			if (this.isSocialPost) return false

			// Only show permalink on out-of-app posts
			if (this.displayMode !== DisplayMode.OpenExternally) return false

			// Otherwise, show it if we have one.
			return (this.showPermaLink = !stringIsEmptyOrWhitespace(this.cleanPermaLink))
		},

		// This is currently used for social posts that need a heading but have the internal title and
		// a title for links associated with it. The logic for this is complicated and should not be
		// changed without multiple considerations.
		get socialHeading() {
			delete this.socialHeading

			// Twitter never puts title in the socialHeading
			if (post.provider === SocialProviderData.Twitter.provider) return (this.socialHeading = null)

			// Otherwise, we do it for linked social posts
			return (this.socialHeading = this.isLinkedPost && post ? post.title : null)
		},

		// Leaving this as a function for now for when they inevitably want to change the logic
		get socialHeadingLength() {
			delete this.socialHeadingLength
			return (this.socialHeadingLength = Restrictions.PostTitle.Social.Post)
		},

		// Don't ask why this is so complicated
		// 1. Twitter posts always render the title normally even if they have links
		// 2. Social posts with "links" (not permalinks) switch the link title with the post title
		// 3. ¯\_(ツ)_/¯
		get title() {
			delete this.title

			if (this.isLinkedPost && post.provider !== SocialProviderData.Twitter.provider)
				return (this.title = safeGetNestedProp(post, 'links[0].title', post && post.title))

			return (this.title = post && post.title)
		},

		get titleWithEntities() {
			delete this.titleWithEntities

			if (!post.titleWithEntities) return (this.titleWithEntities = this.title)

			if (this.isLinkedPost && post.provider !== SocialProviderData.Twitter.provider)
				return (this.titleWithEntities = safeGetNestedProp(
					post,
					'links[0].title',
					post && post.titleWithEntities
				))

			return (this.titleWithEntities = post && post.titleWithEntities)
		},

		// Don't ask why this is so complicated
		// 1. Linked social posts use the link URL as the title and truncate it to 80 characters
		// 2. YouTube is special and displays the post title but truncates it to 80 characters since it
		//    is the only social post that displays the description as well.
		// 3. Every other social post is restricted to 280 characters since they are special
		// 4. Normal posts get truncated at 80
		get titleLength() {
			delete this.titleLength
			return (this.titleLength = getPostMaximumTitleLength(post, { isDetailsLayout: false }))
		},

		// How long can the customized tweet be
		get twitterMaxCharCount() {
			delete this.twitterMaxCharCount
			return (this.twitterMaxCharCount =
				this.twitterCommentRules && this.twitterCommentRules.length > 0
					? this.twitterCommentRules[0].shareMaxCharacterLimit
					: null)
		},

		// ===========================================================================================
		// Function props (Sort alphabetically)
		// - Use these when you need to pass in external params
		// ===========================================================================================

		/**
		 * Whether to show the lock icon or not.
		 * NOTE: Eventually, there will be a sphere parameter that controls this so I'm stubbing it out this way
		 * for the time being.
		 */
		get showLockIcon() {
			delete this.showLockIcon
			return (this.showLockIcon = !post.sharable && !post.isSocialPost) // && futureSphereParameterToControlTheLockIconVisibility;
		},

		/**
		 * Whether to show the share button or not.
		 * @param {*} showShareButtonOnSocialPosts - Sphere parameter that determines whether we should show the share button for particular social posts. The original intent for was to prevent users from sharing a Facebook post to Twitter, etc.
		 */
		showShareButton: function(showShareButtonOnSocialPosts) {
			if (ShowShareButtonOnSocialPostProviders.includes(post.provider)) {
				if (showShareButtonOnSocialPosts) {
					if (this.isShareable) {
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			} else {
				if (this.isShareable) {
					return true
				} else {
					return false
				}
			}
		},
	}
}
export default PostData
