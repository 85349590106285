import { vsApi, requests } from 'helpers/apiHelpers'
import { normalize } from 'scenes/Messages/message.reducer'

export const TypeaheadTypes = {
	all: 'All',
	conversation: 'Conversation',
	email: 'Email',
	group: 'Group',
	post: 'Post',
	tag: 'Tag',
	user: 'User',
}

const FETCH_TYPEAHEAD_REQUEST = 'FETCH_TYPEAHEAD_REQUEST'
const FETCH_TYPEAHEAD_SUCCESS = 'FETCH_TYPEAHEAD_SUCCESS'
const FETCH_TYPEAHEAD_FAILURE = 'FETCH_TYPEAHEAD_FAILURE'
const CLEAR_TYPEAHEAD = 'CLEAR_TYPEAHEAD'

export const asyncGetTypeahead = (take, term, type, context) => {
	return vsApi({
		endpoint: requests.searchTypeahead.endpoint,
		actions: {
			request: FETCH_TYPEAHEAD_REQUEST,
			success: FETCH_TYPEAHEAD_SUCCESS,
			failure: FETCH_TYPEAHEAD_FAILURE,
		},
		requestOptions: requests.searchTypeahead.getRequestOptions(term, type, take),
		context,
		options: {
			captureUnhandledPromiseRejection: false,
		},
	})
}

// Specifically for CommentTextBox to store results locally.
export const asyncGetMentionSuggestions = (take, term, type, context, abortable) => {
	return vsApi({
		endpoint: requests.searchTypeahead.endpoint,
		requestOptions: requests.searchTypeahead.getRequestOptions(term, type, take),
		context,
		options: { abortable },
	})
}

export const clearTypeahead = typeaheadType => {
	return {
		type: CLEAR_TYPEAHEAD,
		typeaheadType,
	}
}

const initialResults = {
	results: {},
}

const initialState = { ...initialResults }

export default function typeaheadReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_TYPEAHEAD_REQUEST: {
			return {
				...state,
				results: {
					...state.results,
					[action.context.type]: {
						isPending: true,
					},
				},
			}
		}
		case FETCH_TYPEAHEAD_SUCCESS: {
			const results = normalize(action.response.results, 'id')
			results.term = action.context.term
			return {
				...state,
				results: {
					...state.results,
					[action.context.type]: {
						...results,
						isPending: false,
					},
				},
			}
		}
		case FETCH_TYPEAHEAD_FAILURE: {
			return {
				...state,
			}
		}
		case CLEAR_TYPEAHEAD: {
			const results = action.typeaheadType
				? {
						...state.results,
						[action.typeaheadType]: {},
				  }
				: {}
			return {
				...state,
				results,
			}
		}
		default:
			return state
	}
}
