import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { safeGetNestedProp } from '@dysi/js-helpers'

import Button, { ButtonLevel } from 'components/Button/Button'

import { color, spacingBase } from 'styles/variables'
import { Style } from 'radium'

import { landmark } from 'helpers/landmark'

import lang from './SkipLinkBanner.lang'

const mapStateToProps = state => {
	return {
		show: safeGetNestedProp(state, 'browser.showSkipLinkBanner', false),
	}
}

class SkipLinkBanner extends React.Component {
	static propTypes = {
		show: PropTypes.bool,
	}

	/** Find and focus element if found */
	focusElement = (rootElement, querySelector) => {
		const element = rootElement.querySelector(querySelector)
		if (element) {
			element.focus()
			return true
		}

		return false
	}

	handleSkipLinkButtonClick = () => {
		// get the category feed element
		const feedElement = document.querySelector(`#${landmark.categoryFeed}`)
		if (!feedElement) {
			return
		}

		// get the first post within the feed
		const firstPostElement = feedElement.querySelector(`[id^="${landmark.post}-"]`)
		if (firstPostElement) {
			const postCreatorElement = firstPostElement.querySelector(`[id^="${landmark.postCreator}-"]`)
			if (postCreatorElement) {
				// focus on the first link within this element, if there is one
				if (this.focusElement(postCreatorElement, 'a')) {
					return
				}
			}

			// if not, focus on the overflow menu, if there is one
			if (this.focusElement(firstPostElement, `[id^="${landmark.overflowMenu}-"]`)) {
				return
			}
		}

		// otherwise focus on the feed
		feedElement.focus()
	}

	render() {
		if (!this.props.show) {
			return null
		}

		const styles = {
			banner: {
				backgroundColor: color.white,
				borderBottom: `1px solid ${color.borderPrimary}`,
				padding: 0,
			},
			buttonRules: {
				button: {
					marginLeft: spacingBase * 4,
					marginTop: spacingBase,
					marginBottom: spacingBase,

					/* Absolutely positioning content off-screen so that it is still read by screen reader */
					/* as using display hidden or width/height: 0 etc may mean it is skipped by the screen reader */
					position: 'absolute',
					top: 'auto',
					left: -10000,
				},
				'button:focus': {
					position: 'static',
				},
			},
		}

		return (
			<div id={landmark.skipLinkBanner} style={styles.banner}>
				<Style scopeSelector={`#${landmark.skipLinkBanner}`} rules={styles.buttonRules} />
				<Button level={ButtonLevel.secondary} onClick={this.handleSkipLinkButtonClick}>
					{lang.skipLink}
				</Button>
			</div>
		)
	}
}

export default connect(mapStateToProps)(SkipLinkBanner)
