import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cookie from 'react-cookies'
import HoverObserver from 'react-hover-observer'

// Routing
import { withRouter, Link } from 'react-router-dom'
import Routes from 'helpers/routes'

// Components
import {
	Dropdown,
	DropdownTrigger,
	DropdownMenu,
	DropdownMenuAlignment,
} from 'components/Dropdown/Dropdown'
import Button, { ButtonLevel } from 'components/Button/Button'
import {
	signOutUser,
	isUserLoggedIn,
	isUserProfileIncomplete,
	isDySiTeamMember,
} from 'helpers/userHelpers'
import { listUnstyled } from 'styles/defaults'
import Dialog from 'components/Dialog/Dialog'

// User photo stuff
import UserPhoto from 'components/UserPhoto/UserPhoto'
import userPhotoSize from 'components/UserPhoto/userPhotoSize'
import { GetUserPhotoDataForSize } from 'helpers/imageHelpers'

// Helpers
import Icon, { Name } from 'components/Icons/FontAwesome'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { Settings } from 'wrappers/SettingsWrapper'
import { SignInParam } from 'pages/SignIn'
import { safeGetSetting } from 'wrappers/Settings'
import { memberHelpUrl } from 'scenes/SideMenu/SideMenu'

// Component Preloads
// NOTE: We're only importing these so that we can preload the bundles before actually needing them.
import { SettingsRoutesPage, DirectoryPage } from 'scenes/App/LoadablePages'

// Lang
import lang from './userSummary.lang'

// Styles
import {
	fontWeight,
	fontSize,
	spacing,
	color,
	borderPrimary,
	width,
	border,
	margin,
	textButtonStyle,
} from 'styles/variables'

const styles = {
	base: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: spacing.basic,
		marginRight: spacing.basic,
	},
	dialogBody: {
		maxWidth: 650,
		marginBottom: spacing.wide,
	},
	dropdownMenu: {
		marginTop: margin.basic + margin.narrow,
	},
	dropdownList: {
		...listUnstyled,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		item: {
			padding: 6,
		},
	},
	icon: {
		fontSize: '21px',
		color: color.secondaryGrey,
		marginRight: spacing.basic,
		verticalAlign: 'middle',
	},
	link: {
		cursor: 'pointer',
	},
	locale: {
		...textButtonStyle,
		display: 'inline-block',
		width: '200px',
		paddingLeft: spacing.basic,
		marginBottom: '5px',
		textAlign: 'left',
	},
	selectedLocale: {
		display: 'inline-block',
		width: '200px',
		fontWeight: fontWeight.semiBold,
		marginBottom: '5px',
	},
	separator: {
		background: borderPrimary,
		borderBottom: border.basic,
		marginTop: width.spacing.narrow,
		marginBottom: width.spacing.narrow,
		width: '100%',
	},
	languageSelection: {
		...textButtonStyle,
		display: 'inline-block',
		paddingRight: spacing.wide,
		color: 'inherit',
	},
}

// Pull props from Redux store
const mapStateToProps = (state, ownProps) => {
	const loggedIn = isUserLoggedIn(state.auth.user)
	const loggedInProfileIncomplete = isUserProfileIncomplete(state.auth.user)
	const page = ownProps.history.location.pathname

	let userProfilePic =
		loggedIn &&
		state.auth.user.profilePictureImages &&
		GetUserPhotoDataForSize(state.auth.user.profilePictureImages, userPhotoSize.tiny.name)
	if (!userProfilePic) userProfilePic = null

	const languageInfo = safeGetNestedProp(state, 'sphere.languageInfo', {})
	const { defaultInterfaceLanguage = '', defaultUserLanguage = '', languages = [] } = languageInfo
	const languageSelectionEnabled =
		languages.filter(language => language && language.interfaceSupport).length > 1
	const isManager = safeGetSetting(state, Settings.User.IsManager)
	const enableDownloadPage = safeGetNestedProp(state, 'sphere.parameters.showDesktopAppLinks')
	const enableUserDirectory = safeGetSetting(state, Settings.Features.EnableUserDirectory)
	const enableNewMemberInvitations = safeGetSetting(
		state,
		Settings.Features.EnableNewMemberInvitations
	)
	// TODO: temp fix, make this cleaner
	const location = window.location
	const userDisplayName = loggedIn ? safeGetNestedProp(state, Settings.User.DisplayName.path) : null
	const enableBetaLink = isDySiTeamMember(state)

	return {
		defaultInterfaceLanguage,
		defaultUserLanguage,
		enableBetaLink,
		enableDownloadPage,
		enableNewMemberInvitations,
		enableUserDirectory,
		isManager,
		languages,
		languageSelectionEnabled,
		location,
		showProfilePic: loggedIn, //&& !!userProfilePic
		showSignIn: !loggedIn && !page.includes(Routes.SignIn.routerPath),
		showSignOut: loggedInProfileIncomplete && page.includes(Routes.SignIn.routerPath),
		signInLabel: lang.signIn,
		signOutLabel: lang.signOut,
		signUpLabel: lang.signUp,
		userDisplayName,
		userId: loggedIn ? safeGetNestedProp(state, 'auth.user.userId') : null,
		userProfilePic,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSignOut(e) {
			e.preventDefault()
			signOutUser(dispatch)
		},
	}
}

// Presentational Component
class UserSummary extends React.Component {
	static propTypes = {
		defaultInterfaceLanguage: PropTypes.string,
		defaultUserLanguage: PropTypes.string,
		enableBetaLink: PropTypes.bool,
		enableDownloadPage: PropTypes.bool,
		enableNewMemberInvitations: Settings.Features.EnableNewMemberInvitations.propType,
		enableUserDirectory: Settings.Features.EnableUserDirectory.propType,
		history: PropTypes.object,
		isManager: Settings.User.IsManager.propType,
		languages: PropTypes.array,
		languageSelectionEnabled: PropTypes.bool,
		location: PropTypes.object,
		onSignOut: PropTypes.func.isRequired,
		showProfilePic: PropTypes.bool.isRequired,
		showSignIn: PropTypes.bool.isRequired,
		showSignOut: PropTypes.bool.isRequired,
		signInLabel: PropTypes.string.isRequired,
		signOutLabel: PropTypes.string.isRequired,
		signUpLabel: PropTypes.string.isRequired,
		userDisplayName: PropTypes.string,
		userId: PropTypes.number,
		userProfilePic: PropTypes.object,
	}

	state = {
		isoCode: null,
		tempIsoCode: null,
		showLanguageDialog: false,
	}

	componentDidMount() {
		const cookieIso = cookie.load('dysi_lang') || cookie.load('lang')
		this.setState({ isoCode: cookieIso, tempIsoCode: cookieIso })
	}

	componentDidUpdate() {
		const { isoCode } = this.state
		const { defaultUserLanguage, defaultInterfaceLanguage } = this.props

		const cookieIso = cookie.load('dysi_lang') || cookie.load('lang')
		const newIsoCode = cookieIso || defaultUserLanguage || defaultInterfaceLanguage || 'en'

		if (newIsoCode !== isoCode) this.setState({ isoCode: newIsoCode, tempIsoCode: newIsoCode })
	}

	closeDialog = () => {
		const { isoCode } = this.state
		this.setState({ showLanguageDialog: false, tempIsoCode: isoCode })
	}

	saveAndCloseDialog = () => {
		const { isoCode, tempIsoCode } = this.state

		if (tempIsoCode !== isoCode) {
			const expiresInOneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
			cookie.remove('lang')
			cookie.remove('dysi_lang')
			cookie.save('dysi_lang', tempIsoCode, {
				secure: true,
				path: '/',
				expires: expiresInOneYear,
				domain: `.${window.location.host}`,
			})
			this.setState({ showLanguageDialog: false, isoCode: tempIsoCode })
			window.location.reload(true)
		} else {
			this.setState({ showLanguageDialog: false })
		}
	}

	handleSelectLanguage = code => {
		this.setState({ tempIsoCode: code })
	}

	render() {
		const {
			enableBetaLink,
			enableDownloadPage,
			enableNewMemberInvitations,
			enableUserDirectory,
			history,
			isManager,
			languages,
			languageSelectionEnabled,
			location,
			onSignOut,
			showProfilePic,
			showSignIn,
			showSignOut,
			signInLabel,
			signOutLabel,
			signUpLabel,
			userDisplayName,
			userId,
			userProfilePic,
		} = this.props
		const matchingArray = languages.filter(locale => locale.isoCode === this.state.isoCode)
		const selectedLanguage = matchingArray.length > 0 ? matchingArray[0].nativeName : ''
		const separator = <div style={styles.separator} />
		const profileRoute = history.location.pathname.includes(Routes.User.linkPath)
			? Routes.User.Profile.linkPath
			: Routes.User.Profile.dialogPath

		return (
			<Fragment>
				<div style={styles.base}>
					{showProfilePic && (
						<Dropdown>
							<DropdownTrigger>
								<UserPhoto
									photos={userProfilePic}
									size={userPhotoSize.tiny.name}
									tooltip={lang.profileAndSettings}
									id={userId}
								/>
							</DropdownTrigger>
							<DropdownMenu align={DropdownMenuAlignment.right} style={styles.dropdownMenu}>
								<ul style={styles.dropdownList}>
									<li style={styles.dropdownList.item}>
										<Link to={profileRoute} style={styles.link}>
											{userDisplayName
												? lang.userProfile(userDisplayName)
												: lang.userProfileDefault}
										</Link>
									</li>

									<HoverObserver
										onHoverChanged={() => SettingsRoutesPage.preload()}
										shouldDecorateChildren={false}
									>
										<li style={styles.dropdownList.item}>
											<Link to={Routes.Settings.linkPath} style={styles.link}>
												{lang.settings}
											</Link>
										</li>
									</HoverObserver>

									{enableNewMemberInvitations && (
										<li style={styles.dropdownList.item}>
											<Link to={Routes.Invite.linkPath} style={styles.link}>
												{lang.invite}
											</Link>
										</li>
									)}

									{enableUserDirectory && (
										<HoverObserver
											onHoverChanged={() => DirectoryPage.preload()}
											shouldDecorateChildren={false}
										>
											<li style={styles.dropdownList.item}>
												<Link to={Routes.Directory.linkPath} style={styles.link}>
													{lang.directory}
												</Link>
											</li>
										</HoverObserver>
									)}

									{/* Downloads */}
									{enableDownloadPage && (
										<li style={styles.dropdownList.item}>
											<Link to={Routes.Downloads.linkPath} style={styles.link}>
												{lang.downloads}
											</Link>
										</li>
									)}

									{/* Experimental Features */}
									{enableBetaLink && (
										<li style={styles.dropdownList.item}>
											<Link to={Routes.Beta.linkPath} style={styles.link}>
												{lang.betaLink}
											</Link>
										</li>
									)}

									{separator}

									{isManager && (
										<li style={styles.dropdownList.item}>
											<a
												href={Routes.Manage.linkPath}
												style={styles.link}
												rel="noopener noreferrer"
												target="_blank"
											>
												{lang.manageCommunity}
											</a>
										</li>
									)}

									<li style={styles.dropdownList.item}>
										<a href={memberHelpUrl} rel="noopener noreferrer" target="_blank">
											{lang.memberHelp}
										</a>
									</li>

									{separator}

									<li style={styles.dropdownList.item}>
										<a href="signOut" style={styles.link} onClick={onSignOut}>
											{signOutLabel}
										</a>
									</li>
								</ul>
							</DropdownMenu>
						</Dropdown>
					)}
				</div>
				<div>
					{!showProfilePic &&
						languageSelectionEnabled && (
							<button
								style={styles.languageSelection}
								onClick={() => this.setState({ showLanguageDialog: true })}
								aria-label={lang.languageSelectionModal(selectedLanguage)}
							>
								<span style={styles.icon}>
									<Icon name={Name.globe} />
								</span>
								<span style={{ verticalAlign: 'middle', fontSize: fontSize.small }}>
									{selectedLanguage}
								</span>
							</button>
						)}
					{showSignIn && (
						<Fragment>
							<Button
								href={Routes.SignIn.generateLinkPath(null, location)}
								level={ButtonLevel.secondary}
								style={{ display: 'inline-block', marginRight: spacing.basic }}
							>
								{signInLabel}
							</Button>
							<Button href={Routes.SignIn.generateLinkPath(SignInParam.signUp, location)}>
								{signUpLabel}
							</Button>
						</Fragment>
					)}
				</div>
				<Dialog
					isOpen={this.state.showLanguageDialog}
					onClose={this.closeDialog}
					title={lang.languages}
					subtitle={lang.chooseLanguage}
					submitButtons={
						<div>
							<Button onClick={this.saveAndCloseDialog}>{lang.done}</Button>
						</div>
					}
				>
					<div style={styles.dialogBody}>
						{languages.map(element => {
							if (!element.interfaceSupport) return null

							if (this.state.tempIsoCode !== this.state.isoCode) {
								if (element.isoCode === this.state.tempIsoCode) {
									return (
										<div key={element.englishName} style={styles.selectedLocale}>
											✔<span style={{ paddingLeft: '5px' }}>{element.nativeName}</span>
										</div>
									)
								}
							} else if (element.nativeName === selectedLanguage) {
								return (
									<div key={element.englishName} style={styles.selectedLocale}>
										✔<span style={{ paddingLeft: '5px' }}>{element.nativeName}</span>
									</div>
								)
							}

							return (
								<button
									key={element.englishName}
									style={styles.locale}
									onClick={() => this.handleSelectLanguage(element.isoCode)}
									aria-label={lang.select(element.nativeName)}
								>
									{element.nativeName}
								</button>
							)
						})}
					</div>
				</Dialog>
				{showSignOut && (
					<Button onClick={onSignOut} level={ButtonLevel.secondary}>
						{signOutLabel}
					</Button>
				)}
			</Fragment>
		)
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(UserSummary)
)
