// ----------------------------------------------
// Dialog
// ----------------------------------------------
// Import like this:
//
//		import { Dialog } from 'components/Dialog'
//
// Use like this:
//
//	this.state = { showDialog: false };
//
//	openDialog() {
//		this.setState({ showDialog: true });
//	}
//
// 	closeDialog(e) {
// 		e.preventDefault();
// 		this.setState({ showDialog: false });
// 	}
//
// 	<Dialog isOpen={this.state.showDialog} onClose={this.closeDialog}
// 		title="Title" subtitle="Help text (if any)"
// 		secondaryLinks={
// 			<div>
// 				<a href="close" onClick={this.closeDialog}>Close</a>
// 				<a href="close" onClick={this.closeDialog}>Dismiss</a>
// 			</div>
// 	   	}
// 		additionalInfo="Important custom text information (if any)"
// 		submitButtons={
// 			<div>
// 				<Button onClick={this.onSend}>Send</Button>
// 			</div>
// 		}
// 	>
// 		<p>Dialog body</p>
//	</Dialog>
// ----------------------------------------------
import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { Style } from 'radium'
import './Dialog.css'

import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Lang
import lang from './dialog.lang'

// Styles
import {
	color,
	margin,
	width,
	padding,
	fontSize,
	constants,
	border,
	textButtonStyle,
} from 'styles/variables'
import { helpText, actionLink } from 'styles/defaults'

export const styles = {
	base: {
		overlay: {
			backgroundColor: color.bgDialogOverlay,
			zIndex: 10,
			overflowY: 'auto',
		},
		content: {
			top: constants.HeaderHeight,
			bottom: 'auto', // Override full height default
			color: color.primaryBlack,
			background: color.white,
			width: width.dialog.basic,
			paddingTop: width.spacing.wide,
			paddingRight: width.spacing.wide,
			paddingBottom: width.spacing.wide,
			paddingLeft: width.spacing.wide,
			marginTop: width.spacing.wide,
			marginBottom: width.spacing.wide,
			marginRight: 'auto',
			marginLeft: 'auto',
			zIndex: 10,

			[BreakpointNames.small]: {
				paddingTop: width.spacing.basic,
				paddingRight: width.spacing.basic,
				paddingBottom: width.spacing.basic,
				paddingLeft: width.spacing.basic,
			},
		},
	},
	heading: {
		marginBottom: margin.basic,
	},
	title: {
		position: 'relative',
		overflow: 'hidden',
		minHeight: width.lineHeight.jumbo, // Same as h1
		textOverflow: 'ellipsis',
		paddingRight: padding.wide, // Room for absolute-positioned x (close button)
		margin: 0,
	},
	subtitle: {
		...helpText,
		fontSize: 14,
	},
	close: {
		position: 'absolute',
		top: 8,
		right: 0,
		fontSize: 20,
		lineHeight: '1',
		color: color.secondaryGrey,
		...textButtonStyle,
	},
	previous: {
		position: 'absolute',
		top: -8,
		right: 60,
		fontSize: 50,
		lineHeight: '1',
		...textButtonStyle,
	},
	next: {
		position: 'absolute',
		top: -8,
		right: 25,
		fontSize: 50,
		lineHeight: '1',
		...textButtonStyle,
	},
	body: {},
	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	footerRules: {
		// <Style rules
		a: {
			...actionLink,
		},
		'a + a': {
			marginLeft: margin.narrow,
		},
		button: {
			marginLeft: margin.narrow,
		},
	},
	info: {
		fontSize: fontSize.small,
		paddingTop: '9px',
	},
	other: {
		background: color.bgPage,
		borderTop: border.basic,
		marginTop: width.spacing.wide,
		marginBottom: 0,
		marginLeft: -width.spacing.wide,
		marginRight: -width.spacing.wide,
		paddingTop: width.spacing.basic,
		paddingBottom: width.spacing.basic,
		paddingLeft: width.spacing.wide,
		paddingRight: width.spacing.wide,
	},
	buttons: {
		marginBottom: '5px',
		whiteSpace: 'nowrap',
	},
	dialog: {
		[BreakpointNames.small]: {
			borderRadius: 0,
			width: '100%',
			display: 'inline-block',
			left: 0,
			right: 0,
		},
	},
}

// Dialog
class Dialog extends React.Component {
	constructor(props) {
		super(props)
		this.infoDivRef = React.createRef()
		this.dialogFooterRef = React.createRef()

		this.submitButtonsDivRef = React.createRef()
		this.state = {
			divWidth: 0,
		}
	}

	static propTypes = {
		style: PropTypes.object,
		footerStyle: PropTypes.object,
		shareV8OtherActions: PropTypes.object,
		isOpen: PropTypes.bool.isRequired,
		title: PropTypes.string,
		titleOverride: PropTypes.object,
		titleStyleOverride: PropTypes.object,
		subtitle: PropTypes.string,
		children: PropTypes.node,
		secondaryLinks: PropTypes.node,
		additionalInfo: PropTypes.object,
		otherActions: PropTypes.node,
		submitButtons: PropTypes.node,
		onClose: PropTypes.func,
		onOpen: PropTypes.func,
		isPaged: PropTypes.bool,
		onNext: PropTypes.func,
		onPrevious: PropTypes.func,
		disableNext: PropTypes.bool,
		disablePrevious: PropTypes.bool,
		shouldCloseOnOverlayClick: PropTypes.bool,
		viewport: PropTypes.shape({
			size: PropTypes.string,
		}),
	}

	static defaultProps = {
		onClose: null,
		onOpen: null,
		isPaged: false,
		shouldCloseOnOverlayClick: true,
	}

	componentDidMount() {
		const { isOpen } = this.props

		if (isOpen) {
			// Update styling for spacing between buttons and text.
			//Timeout delays by a tick to ensure DOM is updated before accessing.
			setTimeout(() => {
				this.updateStyling()
			}, 0)
		}
	}

	updateStyling = () => {
		if (this.submitButtonsDivRef.current) {
			const width = this.submitButtonsDivRef.current.offsetWidth
			this.setState({ divWidth: width })

			if (width > 400) {
				if (this.infoDivRef.current) {
					this.infoDivRef.current.style.marginRight = 'auto'
				}

				if (this.dialogFooterRef.current) {
					this.dialogFooterRef.current.style.flexDirection = 'column-reverse'
				}
			}
		}
	}

	render() {
		const {
			isOpen,
			title,
			subtitle,
			children,
			onClose,
			onOpen,
			isPaged,
			onNext,
			onPrevious,
			disableNext,
			disablePrevious,
			style,
			footerStyle,
			secondaryLinks,
			additionalInfo,
			otherActions,
			submitButtons,
			titleOverride,
			titleStyleOverride,
			shouldCloseOnOverlayClick,
			shareV8OtherActions,
		} = this.props

		if (!isOpen) return null

		const { viewport } = this.props
		// If requested, override the dialog body default styles
		let dialogStyle = {
			overlay: {
				...styles.base.overlay,
			},
			content: {
				...styles.base.content,
				...styles.base.content[viewport.size],
				...styles.dialog[viewport.size],
				...(otherActions ? { paddingBottom: 0 } : {}),
				...(shareV8OtherActions && { overflow: 'visible' }),
			},
		} // styles.base;

		if (style) {
			dialogStyle = {
				overlay: {
					...dialogStyle.overlay,
				},
				content: {
					...dialogStyle.content,
					...style,
				},
			}
		}

		// If requested, override the dialog footer styles
		let footerStyles = styles.footer
		if (footerStyle) {
			footerStyles = {
				...styles.footer,
				...footerStyle,
			}
		}

		if (shareV8OtherActions) footerStyles = {}

		return (
			// TODO:  react-modal recommends you to setAppElement. ariaHideApp false is temp  https://github.com/reactjs/react-modal/pull/570*/}
			<ReactModal
				isOpen={isOpen}
				contentLabel={title}
				shouldCloseOnOverlayClick={shouldCloseOnOverlayClick} // Closes when clicked outside of dialog
				style={dialogStyle}
				ariaHideApp={false}
				onAfterOpen={onOpen}
				onRequestClose={shouldCloseOnOverlayClick ? onClose : null}
			>
				<Style scopeSelector=".Dialog-footer" rules={styles.footerRules} />
				<div style={styles.heading}>
					<h1 style={titleStyleOverride ? titleStyleOverride : styles.title}>
						{titleOverride ? titleOverride : title}
						{onClose && (
							<button style={styles.close} onClick={onClose} aria-label={lang.close}>
								<Icon name={Name.timesLight} />
							</button>
						)}
						{isPaged && (
							<div>
								<button
									style={styles.previous}
									onClick={onPrevious}
									aria-label={lang.close}
									disabled={disablePrevious}
								>
									<Icon
										name={Name.angleLeftLight}
										color={disablePrevious ? 'LightGray' : 'secondaryGrey'}
									/>
								</button>
								<button
									style={styles.next}
									onClick={onNext}
									aria-label={lang.close}
									disabled={disableNext}
								>
									<Icon
										name={Name.angleRightLight}
										color={disableNext ? 'LightGray' : 'secondaryGrey'}
									/>
								</button>
							</div>
						)}
					</h1>
					{subtitle && (
						<small style={styles.subtitle} className="help">
							{subtitle}
						</small>
					)}
				</div>
				<div style={styles.body}>{children}</div>
				{/* NOTE: className used to scope style rules, above */}
				<div className="Dialog-footer" style={footerStyles} ref={this.dialogFooterRef}>
					{shareV8OtherActions}
					<div ref={this.infoDivRef} style={styles.info}>
						{secondaryLinks && <div>{secondaryLinks}</div>}
						{additionalInfo && <div>{additionalInfo}</div>}
					</div>
					<div ref={this.submitButtonsDivRef} style={styles.buttons}>
						{submitButtons && submitButtons}
					</div>
				</div>
				{otherActions && <div style={styles.other}>{otherActions}</div>}
			</ReactModal>
		)
	}
}

export default responsive(Dialog)
