import GraphemeSplitter from 'grapheme-splitter'

// NOTE - PLEASE DO NOT MODIFY THIS FILE UNLESS YOU KNOW WHAT YOU'RE DOING.
//        ALL CHANGES SHOULD BE VALIDATED AGAINST EXISTING TESTS, STORYBOOK, AND A PERFTEST
//

/** Helper function that will truncate text based on arguments
 *
 * @param {string} text - The text to be restricted
 * @param {number} limit - The max allowed length of the text
 * @param {string} ellipsis - Custom ellipsis
 * @returns {Object} - Returns an object with the truncated text and the full length text for tooltips */
export default (text, limit, ellipsis = '...') => {
	let output = text
	let tooltip = null

	// I'm doing this because it is fair to assume that incorrect length calculations will always be
	// greater-than-or-equal-to its actual length.
	let length = text && [...text].length

	// If the limit is invalid or if our naive length is less than the limit, dip out
	if (!limit || isNaN(limit) || limit <= 0 || length <= limit) {
		return {
			output,
			length,
			tooltip,
		}
	}

	// Perform a basic regex check on the text to see if it has any character is outside the "normal"
	// range of characters. This is _in no way_ an exhaustive test but hopefully a good way to escape
	// most strings needing to go through the grapheme splitter.
	// eslint-disable-next-line no-control-regex
	if (!/[^\u0000-\u00ff]/.test(text)) {
		// It's fair to assume that we can perform a clean trim on this since all of its characters fall
		// with the "normal" range.

		// Find the last space so we don't cutoff mid-word
		let testLastSpace = text.lastIndexOf(' ', limit)
		// If you can't find a space, just use the limit
		if (testLastSpace <= 0) testLastSpace = limit

		// Set the output to the trimmed string with ellipsis
		const trimmed = text.substr(0, testLastSpace).trim()
		output = `${trimmed}${ellipsis}`
		length = trimmed.length
		tooltip = text

		return {
			output,
			length,
			tooltip,
		}
	}

	// We're using this to help deal with Unicode insanity
	const splitter = new GraphemeSplitter()

	// We used to get an exact count but it can be slow so who cares
	// length = text && splitter.countGraphemes(text)

	let splitText = ''
	let originalLength = 0
	// Wrap in try-catch so a custom error can be logged with the content attempting to be
	// restricted included
	try {
		// Split by graphemes to account for things like Emoji, etc
		splitText = splitter.splitGraphemes(text)

		// Grab the original length so we can compare it later
		originalLength = splitText.length
	} catch (err) {
		// Eat the error for now, previously logged to Sentry
	}

	// Find the last space so we don't cut off mid-word
	let testLastSpace = splitText.lastIndexOf(' ', limit)
	if (testLastSpace <= 0) testLastSpace = limit

	// Trim the array to length
	const rebuilt = splitText.splice(0, testLastSpace)

	// Grab the new length in case somebody wants to use it
	length = rebuilt.length
	// length = splitter.countGraphemes(rebuilt) // Old slow way

	// Join the components back together and add the ellipsis
	output = `${rebuilt.join('').trim()}${ellipsis}`

	// Only provide a tooltip if we actively trimmed something
	tooltip = length !== originalLength ? text : null
	// const tooltip = text !== output ? text : null // Old way

	return {
		output,
		length,
		tooltip,
	}
}
