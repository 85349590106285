import PropTypes from 'prop-types'

// Helpers
import { ColorPropType } from 'helpers/propTypes'
import { color } from 'styles/variables'
import { safeGetSetting } from './Settings'
import { safeGetNestedProp } from '@dysi/js-helpers'

// Internal Use Only. Don't expose outside this class
// NOTE: Layout must match structure of Settings object (src\wrappers\Settings.js)
const settings = {
	coverImageUrl: {
		name: 'coverImageUrl',
		path: 'sphere.theme.coverImageUrl',
		userPath: 'auth.user.theme.coverImage.coverImageUrl',
	},
	primaryColor: {
		name: 'primaryColor',
		path: 'sphere.theme.primaryColor',
		fallback: color.defaultPrimaryColor,
		userPath: 'auth.user.theme.primaryColor',
	},
	squareLogoUrl: {
		name: 'squareLogoUrl',
		path: 'sphere.theme.squareLogoUrl',
		userPath: 'auth.user.theme.squareLogoUrl',
	},
}

/**
 * Attempts to safely retrieve a theme value from state via a config object
 * @param {Object} themeSetting - Theme config object defined via the exported Theme const
 * @param {*} state - Global state from Redux Store
 */
export const safeGetThemeValue = (state, themeSetting) => {
	// Don't bother if someone passed in garbage
	if (!state || !themeSetting || !themeSetting.name || !settings[themeSetting.name]) return null

	let value

	if (themeSetting.name === settings.primaryColor.name) {
		value =
			safeGetNestedProp(state, 'browser.primaryColorOverride', null) ||
			safeGetNestedProp(state, settings[themeSetting.name].userPath) ||
			safeGetSetting(state, settings[themeSetting.name])
	} else {
		value =
			safeGetNestedProp(state, settings[themeSetting.name].userPath) ||
			safeGetSetting(state, settings[themeSetting.name])
	}

	return value
}

/**
 * Theme settings configuration. Pass these props to safeGetThemeValue, withTheme
 * Ex: export default withTheme(MyComponent, [Theme.PrimaryColor, Theme.SquareLogoUrl])
 * Ex: const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
 */
const Theme = {
	// NOTE: Alphabetical, please!
	/**
	 * URL for the community's cover image
	 */
	CoverImageUrl: {
		name: 'coverImageUrl',
		propType: PropTypes.string,
	},
	/**
	 * Community color used throughout the site for accents
	 */
	PrimaryColor: {
		name: 'primaryColor',
		propType: ColorPropType,
	},
	/**
	 * URL for the community's square logo
	 */
	SquareLogoUrl: {
		name: 'squareLogoUrl',
		propType: PropTypes.string,
	},
}

export default Theme
