import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Wrappers
import PageDialog from 'components/Dialog/PageDialog/PageDialog'

// Pages
import { CustomPagesErrorBounded as CustomPages } from 'scenes/App/AppRoutes'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Actions
import { togglePagePopup } from 'reducers/browser.reducer'

//#region Styles
const popupWidth = 540

const styles = {
	cardDialog: {
		maxWidth: popupWidth,
		[BreakpointNames.small]: {
			maxWidth: '100%',
		},
	},
}

const mapStateToProps = state => {
	const pagePopupId = safeGetNestedProp(state, 'browser.pagePopupId')

	return {
		pagePopupId,
	}
}

const mapDispatchToProps = {
	togglePagePopup,
}

@responsive
class PagePopup extends Component {
	static propTypes = {
		pagePopupId: PropTypes.string,
		togglePagePopup: PropTypes.func,
		viewport: PropTypes.object,
	}

	render() {
		const { pagePopupId, togglePagePopup, viewport } = this.props

		if (!pagePopupId) return null

		const dynamicStyles = {
			cardDialog: {
				...styles.cardDialog,
				...styles.cardDialog[viewport.size],
			},
		}

		return (
			<PageDialog isOpen={true} style={dynamicStyles.cardDialog} onClose={() => togglePagePopup()}>
				<CustomPages />
			</PageDialog>
		)
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PagePopup)
