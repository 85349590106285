import { UserImageDimension } from 'components/UserPhoto/UserPhoto'
import isEmpty from 'lodash/isEmpty'

// Enums
import UserPhotoSize from 'components/UserPhoto/userPhotoSize'
import Layout from 'scenes/Post/enums/postLayout'
import ImageSize from 'scenes/Post/enums/imageSize'
import { BreakpointNames } from 'wrappers/ResponsiveComponent'
import PostConstants from 'scenes/Post/enums/postConstants'
import imageConstants from 'scenes/Post/enums/imageConstants'

// Helpers
import { isBrowserZoomed, isIE } from './browserhelper'

export const cdnImageUrlRegex = /(cdnImage\/article)|(images\/?.*\/article)/gi // Account for possibility that CDN url does not have image processing service enabled
export const GetUserPhotoDataForSize = (images, size) => {
	if (!images) return null
	let response = {}
	switch (size) {
		case UserPhotoSize.tiny.name:
			response[UserImageDimension.Square40] = images[UserImageDimension.Square40]
			break
		case UserPhotoSize.small.name:
			response[UserImageDimension.Square40] = images[UserImageDimension.Square40]
			break
		case UserPhotoSize.medium.name:
			response[UserImageDimension.Square80] = images[UserImageDimension.Square80]
			break
		case UserPhotoSize.large.name:
			response[UserImageDimension.Square160] = images[UserImageDimension.Square160]
			break
		default:
			response[UserImageDimension.Original] = images[UserImageDimension.Original]
			break
	}
	return response
}

/**
 * Reads the image exif data to find the orientation. This method expects a file object (Such as an uploaded image)
 * It will not accept an image uri.
 * Go here to understand what each orientation number represents http://sylvana.net/jpegcrop/exif_orientation.html
 *
 * Method heavily inspired by https://stackoverflow.com/a/32490603
 *
 * @param {File | Blob} imgFile - The image file
 * @returns {Promise.<number>} The exif orientation number
 */
export const GetImageOrientation = imgFile => {
	const fileReader = new FileReader()

	return new Promise(resolve => {
		fileReader.onload = event => {
			const view = new DataView(event.target.result)

			if (view.getUint16(0, false) !== 0xffd8) {
				return resolve(-2)
			}
			const length = view.byteLength
			let offset = 2

			while (offset < length) {
				if (view.getUint16(offset + 2, false) <= 8) {
					return resolve(-1)
				}

				const marker = view.getUint16(offset, false)
				offset += 2
				if (marker === 0xffe1) {
					const check = view.getUint32((offset += 2), false) !== 0x45786966
					if (check) {
						return resolve(-1)
					}

					const little = view.getUint16((offset += 6), false) === 0x4949
					offset += view.getUint32(offset + 4, little)
					const tags = view.getUint16(offset, little)
					offset += 2
					for (let i = 0; i < tags; i++) {
						if (view.getUint16(offset + i * 12, little) === 0x0112) {
							return resolve(view.getUint16(offset + i * 12 + 8, little))
						}
					}
				} else if ((marker & 0xff00) !== 0xff00) {
					break
				} else {
					offset += view.getUint16(offset, false)
				}
			}

			return resolve(-1)
		}
		fileReader.readAsArrayBuffer(imgFile)
	})
}

/**
 * Grabs an image blob file from a src string
 *
 * @param {string} imageSrc
 */
const getImageBlob = async imageSrc => {
	const resp = await fetch(imageSrc)
	return await resp.blob()
}

/**
 * Gets an image's height, width, and orientation from a src string
 *
 * @param {string} imageSrc
 * @returns {Promise.<{width: number, height: number, orientation: number}>} The image info
 */
export const GetImageInfoFromSource = async imageSrc => {
	const helperImage = new Image()
	const blobTask = getImageBlob(imageSrc)
	return new Promise(resolve => {
		helperImage.onload = async () => {
			const blob = await blobTask
			const orientation = await GetImageOrientation(blob)

			return resolve({
				width: helperImage.width,
				height: helperImage.height,
				orientation: orientation,
			})
		}
		helperImage.src = imageSrc
	})
}

/**
 * Gets a video thumbnail's height, width and the url from a video src string.
 * In this function, thumbnail width === video width. They are the same value. Same with height.
 * Use Case 1: When the user uploads a native video and we need to get the video thumbnail for preview purposes.
 *
 * @param {string} videoSrc
 * @returns {Promise.<{thumbnailWidth: number, thumbnailHeight: number, videoWidth: number, videoHeight: number, thumbnailUrl: string}>}
 */
export const GetVideoThumbnail = async videoSrc => {
	const helperVideo = document.createElement('video')

	return new Promise(resolve => {
		helperVideo.addEventListener('loadeddata', async () => {
			const canvas = document.createElement('canvas')
			canvas.width = helperVideo.videoWidth
			canvas.height = helperVideo.videoHeight
			canvas.getContext('2d').drawImage(helperVideo, 0, 0)

			return resolve({
				width: helperVideo.videoWidth,
				height: helperVideo.videoHeight,
				thumbnailUrl: canvas.toDataURL(),
			})
		})

		helperVideo.src = videoSrc
	})
}

/**
 * Returns the appropriate size of image depending on layout and viewport
 *
 * @param {string} layout - current layout Feed / Details / Showcase
 * @param {array} images - array of images
 * @param {object} viewport - viewport object
 * @returns {string} image size
 */

export const getMediaSize = (layout, images, viewport) => {
	if (isEmpty(images)) return

	switch (layout) {
		case Layout.column:
			return ImageSize.Box640
		case Layout.feed:
		case Layout.showcase:
			if (images.Original && images.Original.width < 320 && images.Original.height < 320) {
				return ImageSize.Original
			} else {
				if (images.Original.width === images.Original.height) {
					return ImageSize.Square320
				} else {
					return ImageSize.Box320
				}
			}
		case Layout.details:
			switch (viewport.size) {
				case BreakpointNames.small: {
					if (images.Original && images.Original.width < 320 && images.Original.height < 320) {
						return ImageSize.Original
					} else {
						if (images.Original.width === images.Original.height) {
							return ImageSize.Square320
						} else {
							return ImageSize.Box320
						}
					}
				}
				case BreakpointNames.medium:
					return ImageSize.Box640
				default:
					return ImageSize.Box960
			}
		case Layout.notification:
			return ImageSize.Square80
		default:
			return ImageSize.Original
	}
}

const createImageUrl = (baseUrl, size, layout) => {
	// We would only want to create a url if the image is a cdnImage.
	// All other urls (like blob, data, regular https) need to pass through this function unaltered.
	if (baseUrl && baseUrl.match(cdnImageUrlRegex)) {
		if (layout === Layout.messaging) {
			return `${baseUrl}?Size=${size}`
		}

		const newSize = isBrowserZoomed && size === ImageSize.Box320 && !isIE ? ImageSize.Box640 : size
		return `${baseUrl}?Size=${newSize}`
	} else {
		return baseUrl
	}
}

const getHeightWidth = (image, size) => {
	let height, width, max
	switch (size) {
		case ImageSize.Square80:
			max = 80
			break
		case ImageSize.Square320:
		case ImageSize.Box320:
			max = PostConstants.feed.maxWidth
			break
		case ImageSize.Box640:
			max = PostConstants.details.maxHeight
			break
		case ImageSize.Box960:
			max = PostConstants.details.maxWidth
			break
		default:
			height = image.height
			width = image.width
			break
	}

	if (max) {
		if (image.height > image.width) {
			height = max
			const ratio = height / image.height
			width = image.width * ratio
		} else {
			width = max
			const ratio = width / image.width
			height = image.height * ratio
		}
	}

	return {
		height: Math.floor(height),
		width: Math.floor(width),
	}
}
/**
 * Returns the appropriate size of image depending on layout and viewport
 *
 * @param {string} layout - current layout Feed / Details / Showcase
 * @param {array} image - original image
 * @param {object} viewport - viewport object
 * @returns {string} image object with appropriate size for layout and viewport
 */

export const getPostImageSize = (layout, image, viewport, isVideo) => {
	if (!image) return

	let size

	switch (layout) {
		case Layout.column:
			size = ImageSize.Original
			break
		case Layout.feed:
		case Layout.showcase:
			if (image.width < PostConstants.feed.maxWidth && image.height < PostConstants.feed.maxWidth) {
				size = ImageSize.Original
			} else {
				if (image.width === image.height) {
					size = ImageSize.Square320
				} else {
					size = isBrowserZoomed && !isIE && !isVideo ? ImageSize.Box640 : ImageSize.Box320
				}
			}
			break
		case Layout.details:
			switch (viewport.size) {
				case BreakpointNames.small: {
					if (
						image.width < PostConstants.feed.maxWidth &&
						image.height < PostConstants.feed.maxWidth
					) {
						size = ImageSize.Original
					} else {
						if (image.width === image.height) {
							size = ImageSize.Square320
						} else {
							size = ImageSize.Box320
						}
					}
					break
				}
				case BreakpointNames.medium:
					if (
						image.width < PostConstants.details.maxHeight &&
						image.height < PostConstants.details.maxHeight
					) {
						size = ImageSize.Original
					} else {
						size = ImageSize.Box640
					}
					break
				default:
					if (
						image.width < PostConstants.details.maxWidth &&
						image.height < PostConstants.details.maxWidth
					) {
						size = ImageSize.Original
					} else {
						size = ImageSize.Box960
					}
					break
			}
			break
		case Layout.notification:
			size = ImageSize.Square80
			break
		case Layout.messaging:
			if (image.width < PostConstants.feed.maxWidth && image.height < PostConstants.feed.maxWidth) {
				size = ImageSize.Original
			} else {
				if (image.width === image.height) {
					size = ImageSize.Square320
				} else {
					size = ImageSize.Box320
				}
			}
			break
		default:
			size = ImageSize.Original
			break
	}

	const imageSize = getHeightWidth(image, size)

	return {
		url: createImageUrl(image.baseUrl, size, layout),
		height: imageSize.height ? imageSize.height : null,
		width: imageSize.width ? imageSize.width : null,
		altText: image.altText ? image.altText : null,
	}
}

export const isImageLowHeight = height => {
	if (!height) {
		return false
	}
	return height < imageConstants.lowHeightImage
}
