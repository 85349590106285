export default {
	none: 'None',
	playbackStarted: 'PlaybackStarted',
	playbackPaused: 'PlaybackPaused',
	playbackEnded: 'PlaybackEnded',
	playbackStopped: 'PlaybackStopped',
	playbackTwentyFivePercent: 'PlaybackTwentyFivePercent',
	playbackFiftyPercent: 'PlaybackFiftyPercent',
	playbackSeventyFivePercent: 'PlaybackSeventyFivePercent',
	playbackSeekedForward: 'PlaybackSeekedForward',
	playbackSeekBackward: 'PlaybackSeekedBackward',
	livestreamStarted: 'LivestreamStarted',
	livestreamPaused: 'LivestreamPaused',
	livestreamEnded: 'LivestreamEnded',
	livestreamStopped: 'LivestreamStopped',
}
