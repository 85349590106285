import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SocialIcon, { SocialIconSize } from 'components/SocialIcon/SocialIcon'
import { Backdrop } from 'components/Icons/FontAwesome'
import { flexContainer, color } from 'styles/variables'

const styles = {
	base: {
		lineHeight: 0,
		...flexContainer(),
	},
	item: {
		marginLeft: 5,
		marginRight: 5,
		height: 36,
		...flexContainer(),
		cursor: 'pointer',
	},
	channel: {
		color: color.white,
	},
}

class SocialChannels extends Component {
	static propTypes = {
		channels: PropTypes.array,
	}

	render() {
		const { channels } = this.props

		return (
			<div style={styles.base}>
				{channels.map(channel => {
					return (
						<div key={channel.id} style={styles.item}>
							<a href={channel.url} target="_blank" rel="noopener noreferrer">
								<SocialIcon
									size={SocialIconSize.tiny}
									provider={channel.name}
									style={styles.channel}
									backdrop={Backdrop.square}
									color={color.white}
								/>
							</a>
						</div>
					)
				})}
			</div>
		)
	}
}

export default SocialChannels
