import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { apiUrlBuilder, requests } from 'helpers/apiHelpers'

// Actions
import { setOnlineStatus } from 'reducers/browser.reducer'

const pollingInterval = 5000 //milliseconds

const mapStateToProps = state => {
	const isOnline = state.browser.isOnline || !!navigator.onLine
	const pingUrl = apiUrlBuilder(state, requests.ping.endpoint)
	return { isOnline, pingUrl }
}

const mapDispatchToProps = dispatch => {
	return {
		setOnlineStatus: isOnline => dispatch(setOnlineStatus(isOnline)),
	}
}

class OfflineChecker extends Component {
	static propTypes = {
		children: PropTypes.node,
		isOnline: PropTypes.bool,
		onChange: PropTypes.func,
		pingUrl: PropTypes.string,
		setOnlineStatus: PropTypes.func,
	}

	componentDidMount() {
		const { isOnline } = this.props

		if (!isOnline) {
			this.startPolling()
		}

		window.addEventListener('online', this.goOnline)
		window.addEventListener('offline', this.goOffline)
	}

	goOffline = () => {
		this.changeStatus(false)
		// navigator.onLine is not always reliable,
		// so do some manual polling to see if we can reach our api
		if (!this.pollingId) {
			this.startPolling()
		}
	}

	goOnline = () => {
		this.changeStatus(true)
		// We don't want to constantly poll while online
		this.stopPolling()
	}

	changeStatus = isNowOnline => {
		const { onChange, isOnline, setOnlineStatus } = this.props
		if (isNowOnline !== isOnline) {
			setOnlineStatus(isNowOnline)
			onChange(isNowOnline)
		}
	}

	startPolling = () => {
		const { pingUrl } = this.props
		this.pollingId = setInterval(() => {
			fetch(pingUrl)
				.then(() => {
					// We have an internet connection, time to turn the app back on!
					this.goOnline()
				})
				// Still can't connect
				.catch(() => this.goOffline())
		}, pollingInterval)
	}

	stopPolling = () => {
		clearInterval(this.pollingId)
	}

	render() {
		const { children, isOnline } = this.props

		if (!isOnline) {
			return children
		}

		return null
	}
}

let WrappedOfflineChecker = connect(
	mapStateToProps,
	mapDispatchToProps
)(OfflineChecker)
export default WrappedOfflineChecker
