import { connect } from 'react-redux'

// Convenience for implementation-sake
import importSettings, { safeGetSetting } from './Settings'
export const Settings = importSettings

//region Enhanced MapStateToProps
const wrappedMapStateToProps = settings => (state, ownProps) => {
	// Perform safe retrieval for all of the passed in settings and then apply them to the new settings object
	const injectedSettings = settings.reduce((memo, current) => {
		// Don't bother if someone passed in garbage
		if (!current || !current.name) return memo

		// Is setting overridden by props?
		let settingValue
		if (ownProps.hasOwnProperty(current.name)) {
			settingValue = ownProps[current.name] // Use setting value from props
		} else {
			settingValue = safeGetSetting(state, current) // Use our fancy helper to get the value
		}

		// Add the retrieved setting to the new props
		return {
			...memo,
			[current.name]: settingValue,
		}
	}, {})

	// Return the new settings prop with all of the injected settings
	return injectedSettings
}
//endregion

/**
 * Convenience wrapper that will safely inject 'settings' props based on configuration
 *
 * @param {any} WrappedComponent - Component that needs settings
 * @param {Array<any>} settings - Array of setting configurations that tell the wrapper how to retrieve the values
 */
function WithSettingsWrapper(WrappedComponent, settings = []) {
	return connect(wrappedMapStateToProps(settings))(WrappedComponent)
}

/**
 * Convenience function that will build your propType definitions for injected settings.
 * @param {Array<any>} settings - Array of settings that need propType definitions
 */
export const SettingsPropType = settings => {
	const propTypeShape = settings.reduce((memo, current) => {
		if (!current) return memo

		return {
			...memo,
			[current.key]: current.propType,
		}
	}, {})
	return propTypeShape
}

// Make it available to everyone else
export default WithSettingsWrapper
