import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Components
import DateTime, { DateFormat } from 'components/DateTime/DateTime'
import Dialog from 'components/Dialog/Dialog'
import Loading from 'components/Loading/Loading'

// Actions
import { closeShareCompleteDialog } from 'scenes/Post/post.reducer'

// Helpers
import Routes from 'helpers/routes'
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'

// Lang
import lang from 'scenes/ShareDialog/shareDialog.lang'
import reactionsLang from 'helpers/lang/reactions.lang'
import shareCompleteDialogLang from './shareCompleteDialog.lang'

// Styles
import { styles } from 'scenes/ShareDialog/ShareDialog'

const mapStateToProps = state => {
	const { sharePoints, isOpen, isSharePostPending, shareSucceeded, failedReason } = state.post
	const enableLeaderboards = safeGetSetting(state, Settings.Features.EnableLeaderboards)

	return {
		shareSuccessPoints: sharePoints,
		isOpen,
		isSharePostPending,
		shareSucceeded,
		failedReason,
		enableLeaderboards,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onClose: () => dispatch(closeShareCompleteDialog()),
	}
}

class ShareCompleteDialog extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		onClose: PropTypes.func,
		shareSuccessPoints: PropTypes.string,
		primaryColor: PropTypes.string,
		reaction: PropTypes.object,
		reactionProvider: PropTypes.object,
		shareDate: PropTypes.string,
		shareAgain: PropTypes.func,
		enableLeaderboards: PropTypes.bool,
		isScheduledShare: PropTypes.bool,
		handleClose: PropTypes.func,
		isSharePostPending: PropTypes.bool,
		shareSucceeded: PropTypes.bool,
		failedReason: PropTypes.string,
	}

	state = {
		successMessage: lang.shareCompleteSuccess,
	}

	componentDidMount() {
		const { reaction, reactionProvider } = this.props
		if (reaction) {
			const message = reactionsLang.success[`${reactionProvider}${reaction.reactionType}`]
			this.setState({ successMessage: message })
		}
	}

	handleCloseDialog = () => {
		const { handleClose } = this.props
		handleClose('showShareCompleteDialog')
	}

	getFailedReason = reason => {
		switch (reason) {
			case 'sharing_capped':
				return shareCompleteDialogLang.FailedReason.SharingCapped
			case 'already_shared':
				return shareCompleteDialogLang.FailedReason.AlreadyShared
			default:
				return shareCompleteDialogLang.FailedReason.Default
		}
	}

	render() {
		const {
			isOpen,
			onClose,
			isScheduledShare,
			shareDate,
			primaryColor,
			shareSuccessPoints,
			shareAgain,
			reaction,
			enableLeaderboards,
			isSharePostPending,
			shareSucceeded,
			failedReason,
		} = this.props

		const { successMessage } = this.state
		const dynamicStyles = {
			editScheduledShare: { ...styles.editScheduledShare, color: primaryColor },
		}

		if (isSharePostPending) {
			return (
				<Dialog isOpen={isOpen}>
					<Loading />
				</Dialog>
			)
		}

		const showPoints = shareSucceeded !== false && enableLeaderboards && shareSuccessPoints
		const failedReasonMessage = this.getFailedReason(failedReason)

		return (
			<Dialog isOpen={isOpen} onClose={onClose}>
				{/* Scheduled Share Version */}
				{isScheduledShare ? (
					<Fragment>
						<h1 style={styles.shareScheduled}>{lang.scheduleSuccess}</h1>
						<div style={styles.shareScheduledDate}>
							<DateTime date={shareDate} format={DateFormat.DateTimeTimeZone}>
								{formattedDateTime => formattedDateTime}
							</DateTime>
						</div>
						<div style={dynamicStyles.editScheduledShare}>
							<Link
								to={Routes.Settings.ScheduledShares.linkPath}
								style={styles.editDeleteLink}
								onClick={this.handleCloseDialog}
							>
								{lang.viewScheduledSharesLink}
							</Link>
							<span style={styles.editDeleteSeparator}>•</span>
							<span style={styles.editDeleteLink} onClick={shareAgain}>
								{lang.shareAgain}
							</span>
						</div>
					</Fragment>
				) : (
					// Non Scheduled Share Version
					<div style={styles.shareComplete}>
						<h1 style={styles.shareComplete.text}>
							{shareSucceeded === false ? failedReasonMessage : successMessage}
						</h1>
						{showPoints && (
							<h1 style={styles.shareComplete.points}>
								{shareCompleteDialogLang.PointsEarnedMessage(shareSuccessPoints)}
							</h1>
						)}
						{shareSucceeded === true && <span>{shareCompleteDialogLang.PossibleDelayMessage}</span>}
						{!reaction && (
							<div style={dynamicStyles.editScheduledShare}>
								{showPoints && (
									<Fragment>
										<Link
											style={styles.editDeleteLink}
											to={Routes.Leaderboards.linkPath}
											onClick={this.handleCloseDialog}
										>
											{lang.viewLeaderboard}
										</Link>
										{shareAgain && <span style={styles.editDeleteSeparator}>•</span>}
									</Fragment>
								)}
								{shareAgain && (
									<span style={styles.editDeleteLink} onClick={shareAgain}>
										{lang.shareAgain}
									</span>
								)}
							</div>
						)}
					</div>
				)}
			</Dialog>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShareCompleteDialog)
