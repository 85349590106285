import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Actions
import { toggleSideMenu } from 'reducers/browser.reducer'

// Components
import Icon, { Name } from 'components/Icons/FontAwesome'

//region Styles
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { isAtLeast } from 'helpers/breakpoints'
import { spacing } from 'styles/variables'
//endregion

const mapStateToProps = (state, ownProps) => {
	const viewport = state.browser.viewport

	return {
		viewport,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		toggleSideMenu: () => dispatch(toggleSideMenu()),
	}
}

class Hamburger extends React.Component {
	static propTypes = {
		viewport: responsive.PropType.isRequired,
		toggleSideMenu: PropTypes.func.isRequired,
		badgeCount: PropTypes.number,
	}

	onClick = e => {
		this.props.toggleSideMenu()
	}

	render() {
		const { viewport, badgeCount = 0 } = this.props
		const styles = {
			base: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontSize: 26,
				padding: spacing.basic,
				background: 'none',
				border: 'none',
				color: 'inherit',
			},
			icon: {},
		}

		if (isAtLeast(BreakpointNames.large, viewport.size)) return null

		return (
			<button style={styles.base} onClick={this.onClick}>
				<Icon name={Name.bars} style={styles.icon} count={badgeCount} />
			</button>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Hamburger)
