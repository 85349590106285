const lang = {
	backToHome: 'Back to Home',
	completed: '(Completed)',
	editResponse: 'Edit My Responses',
	isRequired: title => `${title} is required.`, //  title is the title of the question
	otherIsRequired: 'If \'Other\' is selected, please make sure you enter a response.',
	other: 'Other (please specify)',
	required: '- Required',
	submit: 'Submit',
	surveyCompleteResponse: 'Thank you for your input!',
	surveyEndedResponse: 'This survey has already ended.',
}

export default lang;