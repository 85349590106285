import { vsApi, requests } from 'helpers/apiHelpers'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Helpers
export const logLevels = {
	critical: 'Critical',
	debug: 'Debug',
	error: 'Error',
	info: 'Info',
	warning: 'Warning',
}

export const logTypes = {
	api: 'Api',
	memberApp: 'MemberApp',
}

// Action types - Not exposed. Callers should call Action Creators, below
// const ACKNOWLEDGE_ERROR = 'ACKNOWLEDGE_ERROR';
const ADD_ERROR = 'ADD_ERROR'

// Action Creators
// TODO: might need in the future
// export const acknowledgeError = (pathname) => {
// 	return {
// 		type: ACKNOWLEDGE_ERROR,
// 		pathname,
// 	}
// }

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

// Async Action Creators
export const asyncAddError = (error, pathname) => {
	const date = new Date().toISOString()
	const { messages } = error

	return vsApi({
		endpoint: requests.sendLog.endpoint,
		actions: {
			success: ADD_ERROR,
		},
		requestOptions: requests.sendLog.getRequestOptions(messages || error),
		context: {
			date,
			error,
			pathname,
		},
	})
}

// --- Initial State ---

// State will be mapped to the Redux store by combineReducers
const initialState = {}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function errorReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_ERROR: {
			return {
				...state,
				[action.context.pathname]: {
					date: action.context.date,
					...action.context.error,
				},
			}
		}
		// TODO: might need in the future
		// case ACKNOWLEDGE_ERROR: {
		// 	return {
		// 		...state,
		// 		[action.pathname]: {
		// 			...state[action.pathname],
		// 			isAcknowledged: true,
		// 		},
		// 	}
		// }
		default:
			return state
	}
}
