import { vsApi, requests } from 'helpers/apiHelpers'
import {
	FETCH_CATEGORY_POSTS_SUCCESS,
	FETCH_POSTSEARCH_SUCCESS,
} from 'scenes/Category/category.reducer'
import { FETCH_POST_SUCCESS, FETCH_EXTERNAL_FEED_POSTS_SUCCESS } from 'scenes/Post/post.reducer'
import { FETCH_BROADCAST_SUCCESS } from 'scenes/Broadcast/broadcast.reducer'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below
const FETCH_SURVEY_REQUEST = 'FETCH_SURVEY_REQUEST'
const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS'
const FETCH_SURVEY_FAILURE = 'FETCH_SURVEY_FAILURE'
const FETCH_SURVEYRESULTS_REQUEST = 'FETCH_SURVEYRESULTS_REQUEST'
const FETCH_SURVEYRESULTS_SUCCESS = 'FETCH_SURVEYRESULTS_SUCCESS'
const FETCH_SURVEYRESULTS_FAILURE = 'FETCH_SURVEYRESULTS_REQUEST'
const ANSWER_SURVEY = 'ANSWER_SURVEY'
const COMPLETE_SURVEY = 'COMPLETE_SURVEY'
const CLEAR_SURVEY = 'CLEAR_SURVEY'

// Action Creators
export const clearSurvey = () => {
	return {
		type: CLEAR_SURVEY,
	}
}

// Async Action Creators
export const asyncGetSurvey = surveyId => {
	return vsApi({
		endpoint: requests.getSurvey.getEndpoint(surveyId),
		actions: {
			request: FETCH_SURVEY_REQUEST,
			success: FETCH_SURVEY_SUCCESS,
			failure: FETCH_SURVEY_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetSurveyResults = surveyId => {
	return vsApi({
		endpoint: requests.getSurveyResults.getEndpoint(surveyId),
		actions: {
			request: FETCH_SURVEYRESULTS_REQUEST,
			success: FETCH_SURVEYRESULTS_SUCCESS,
			failure: FETCH_SURVEYRESULTS_FAILURE,
		},
		context: {
			surveyId,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}
export const asyncAnswerSurvey = (surveyId, questionId, answers, abortable) => {
	return vsApi({
		endpoint: requests.answerSurvey.getEndpoint(surveyId),
		actions: {
			success: ANSWER_SURVEY,
		},
		options: { abortable },
		requestOptions: requests.answerSurvey.getRequestOptions(questionId, answers),
		context: {
			surveyId,
		},
	})
}

export const asyncCompleteSurvey = (surveyId, abortable) => {
	const userCompleteDate = new Date().toISOString()
	return vsApi({
		endpoint: requests.completeSurvey.getEndpoint(surveyId),
		actions: {
			success: COMPLETE_SURVEY,
		},
		options: { abortable },
		requestOptions: requests.completeSurvey.requestOptions,
		context: {
			surveyId,
			userCompleteDate,
		},
	})
}

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

// Helpers
const mergeAnswers = (response, answers) => {
	let newAnswers = [...answers]

	if (response.answers.length > 0) {
		newAnswers = answers.filter(answer => answer.questionId !== response.answers[0].questionId)
		newAnswers.push(...response.answers)
	}

	return newAnswers
}

const getSurveys = posts =>
	posts.reduce((prev, curr) => {
		if (curr.survey) {
			prev[curr.survey.id] = curr.survey
		}
		return prev
	}, {})

const getSurveysFromResults = results =>
	results.reduce((prev, curr) => {
		const survey = curr.post.survey
		if (survey) {
			prev[survey.id] = survey
		}

		return prev
	}, {})

const getSurveyIds = surveyMap => Object.keys(surveyMap).map(x => Number(x))

// --- Initial State ---
const initialSurvey = {
	surveyAllIds: [],
	surveyById: {},
}

// State will be mapped to the Redux store by combineReducers
const initialState = {
	...initialSurvey,
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function surveyReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_SURVEY_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case FETCH_SURVEY_SUCCESS: {
			return {
				...state,
				isLoading: false,
				surveyAllIds: [...state.surveyAllIds, action.response.id],
				surveyById: {
					...state.surveyById,
					[action.response.id]: action.response,
				},
			}
		}
		case FETCH_SURVEY_FAILURE: {
			return {
				...state,
				isLoading: true,
			}
		}
		case FETCH_SURVEYRESULTS_REQUEST: {
			const surveyId = action.context.surveyId

			return {
				...state,
				surveyById: {
					...state.surveyById,
					[surveyId]: {
						...state.surveyById[surveyId],
						isResultsLoading: true,
					},
				},
			}
		}
		case FETCH_SURVEYRESULTS_SUCCESS: {
			const surveyId = action.context.surveyId
			const { participants, totalCompleted, questions } = action.response

			return {
				...state,
				surveyById: {
					...state.surveyById,
					[surveyId]: {
						...state.surveyById[surveyId],
						participants,
						totalCompleted,
						results: questions[0].answers,
						isResultsLoading: false,
					},
				},
			}
		}
		case FETCH_SURVEYRESULTS_FAILURE: {
			const surveyId = action.context.surveyId

			return {
				...state,
				surveyById: {
					...state.surveyById,
					[surveyId]: {
						...state.surveyById[surveyId],
						isResultsLoading: false,
					},
				},
			}
		}
		case ANSWER_SURVEY: {
			const surveyId = action.context.surveyId
			const answers = mergeAnswers(action.response, state.surveyById[surveyId].answers)
			const totalCompleted =
				(state.surveyById[surveyId].totalCompleted ||
					state.surveyById[surveyId].totalCompleted === 0) &&
				state.surveyById[surveyId].totalCompleted + 1
			return {
				...state,
				surveyById: {
					...state.surveyById,
					[surveyId]: {
						...state.surveyById[surveyId],
						answers,
						totalCompleted,
					},
				},
			}
		}
		case COMPLETE_SURVEY: {
			const surveyId = action.context.surveyId
			const userCompleteDate = action.context.userCompleteDate

			return {
				...state,
				surveyById: {
					...state.surveyById,
					[surveyId]: {
						...state.surveyById[surveyId],
						userCompleteDate,
					},
				},
			}
		}
		case CLEAR_SURVEY: {
			return {
				...state,
				...initialSurvey,
			}
		}
		case FETCH_POSTSEARCH_SUCCESS: {
			const { results } = action.response
			const surveyById = getSurveysFromResults(results)
			const surveyAllIds = getSurveyIds(surveyById)

			return {
				...state,
				surveyAllIds: [...state.surveyAllIds, ...surveyAllIds],
				surveyById: {
					...state.surveyById,
					...surveyById,
				},
			}
		}
		case FETCH_BROADCAST_SUCCESS:
		case FETCH_EXTERNAL_FEED_POSTS_SUCCESS:
		case FETCH_CATEGORY_POSTS_SUCCESS: {
			const { posts } = action.response

			if (!posts) return { ...state }

			const surveyById = getSurveys(posts)
			const surveyAllIds = getSurveyIds(surveyById)

			return {
				...state,
				surveyAllIds: [...state.surveyAllIds, ...surveyAllIds],
				surveyById: {
					...state.surveyById,
					...surveyById,
				},
			}
		}
		case FETCH_POST_SUCCESS: {
			const { survey } = action.response

			if (!survey) return { ...state }

			const surveyById = {
				...state.surveyById,
				[survey.id]: {
					...state.surveyById[survey.id],
					...survey,
				},
			}
			const surveyAllIds = getSurveyIds(surveyById)

			return {
				...state,
				surveyAllIds,
				surveyById,
			}
		}
		default:
			return state
	}
}
