// ConversationData represents a single conversation. Please add any missing props or calculations you need below.
//	o Keep lists in alphabetical order!
/**
 * ConversationData represents a single conversation.
 * @param {Object} conversation
 */
import { ConversationTypes, normalize } from './message.reducer.js'

const ConversationData = conversation => {
	return {
		conversationId: conversation.conversationId,

		// Native props - Alphabetical, please!
		conversationType: conversation.conversationType,
		isNew: conversation.isNew,
		isReply: conversation.isReply,
		lastMessage: conversation.lastMessage,
		lastMessageDate: conversation.lastMessageDate,
		lastPostingUserId: conversation.lastPostingUserId,
		messageCount: conversation.messageCount,
		next: conversation.next,
		unreadMessageCount: conversation.unreadMessageCount,

		// Calculated props - Alphabetical, please!
		// - Use these when the property isn't a 1:1 map with the API conversation object
		messages: conversation.messages
			? normalize(conversation.messages, 'conversationMessageId')
			: { allIds: [], byId: {} },
		participants: conversation.participants.map(participant => participant.userId),

		// ===========================================================================================
		// Getter props (Sort alphabetically)
		//   NOTE: Use these when you need to self-reference
		// ===========================================================================================

		get isMemberConversation() {
			delete this.isMemberConversation
			return (this.isMemberConversation =
				conversation.conversationType === ConversationTypes.memberToMember)
		},

		get isManagerConversation() {
			delete this.isManagerConversation
			return (this.isManagerConversation =
				conversation.conversationType === ConversationTypes.managerToMember)
		},

		get hasOneParticipant() {
			delete this.hasOneParticipant
			return (this.hasOneParticipant = conversation.participants.length === 1)
		},

		/** Verifies that the conversation meets the minimum requirements needed to be valid. */
		get isValid() {
			delete this.isValid
			// TODO : Add more of these qualifiers
			return (this.isValid = !!(this.conversationId && this.lastMessage))
		},
	}
}
export default ConversationData
