const UserPhotoSize = {
	tiny: {
		name: 'tiny',
		size: '24px',
		width: 24,
	},
	small: {
		name: 'small',
		size: '30px',
		width: 30,
	},
	medium: {
		name: 'medium',
		size: '48px',
		width: 48,
	},
	large: {
		name: 'large',
		size: '160px',
		width: 160,
	},
}
export default UserPhotoSize
