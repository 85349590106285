import store from '../store'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { showActionDisabledDialog } from 'reducers/browser.reducer'

export const getIsImpersonating = () => {
	return (
		process.env.DS_ENV_IS_PRODUCTION &&
		safeGetNestedProp(store.getState(), 'auth.isImpersonating', false)
	)
}

/**
 * Checks if user is impersonating and will toggleActionDisableDialog and return a rejected promise based on the two flags.
 * @param {function} action
 * @param {boolean} toggleDialog
 * @param {boolean} reject
 */
export const dispatchIfNotImpersonating = (action, toggleDialog = false, reject = false) => {
	if (!action) return
	const dispatch = store.dispatch
	const isImpersonating = getIsImpersonating()
	if (isImpersonating) {
		if (toggleDialog) dispatch(showActionDisabledDialog())
		if (reject) return Promise.reject()
		return
	} else {
		return dispatch(action)
	}
}
