/**
 * Dark Theme
 */
export default {
	name: 'Dark',
	colors: {
		bgPage: '#141517',
		bgContent: '#1F2124',
		white: '#1F2124',
		borderPrimary: '#373B40',
		primaryBlack: '#EBEBEB',
		primaryGrey: '#EBEBEB',
		secondaryGrey: '#A6A5A6',
		textHighlight: '#3297FD',
	},
}
