/**
 * Dark Blue Theme
 */
export default {
	name: 'Dark Blue',
	colors: {
		bgPage: '#010e1a',
		// bgContent: '#011627',
		bgContent: '#081d31',
		white: '#011627',
		borderPrimary: '#081d31',
		primaryBlack: '#C0CCDA',
		primaryGrey: '#C0CCDA',
		secondaryGrey: '#A6A5A6',
		textHighlight: '#3297FD',
	},
}
