import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()
import { color, spacing, fontSize, margin, flexContainer, border, padding } from 'styles/variables'

const styles = {
	links: {
		display: 'flex',
		alignItems: 'center',
		fontSize: fontSize.small,
		link: {
			marginRight: spacing.basic + spacing.narrow,
			fontSize: fontSize.small,
			whiteSpace: 'nowrap',
			cursor: 'pointer',
		},
		icon: {
			color: color.secondaryGrey,
			fontSize: fontSize.heading,
			marginRight: spacing.narrow,
		},
	},
	dropdown: {
		width: 500,
		menu: {
			marginTop: margin.wide - 1,
		},
	},
	header: {
		...flexContainer('row', 'space-between'),
		borderBottom: border.basic,
		paddingTop: padding.narrow,
		paddingBottom: padding.narrow,
		paddingLeft: padding.basic,
		paddingRight: padding.basic,
	},
	title: {
		fontSize: fontSize.title,
	},
	seeAll: {
		marginTop: margin.narrow,
		textAlign: 'center',
	},
	loading: {
		marginTop: margin.basic,
		borderBottom: border.basic,
	},
}

export default prefixer.prefix(styles)
