/**
 * Standardized post image sizes returned from the API
 */
export default {
	Original: 'Original',
	Box1280: 'Box1280',
	Box960: 'Box960',
	Box640: 'Box640',
	Box320: 'Box320',
	Square80: 'Square80',
	Square240: 'Square240',
	Square320: 'Square320',
	Box100: 'Box100',
	Box80: 'Box80',
	Box40: 'Box40',
	AspectRatio191: 'AspectRatio191',
}
