import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import SocialIcon from 'components/SocialIcon/SocialIcon'

// Shared
import Icon, { Name, Backdrop } from 'components/Icons/FontAwesome'
import UserPhotoSize from 'components/UserPhoto/userPhotoSize'
import { SocialIconSize } from 'components/SocialIcon/SocialIcon'
import SocialProviderData from 'helpers/socialProviderData'

// Styles
import { width, color, border, borderStyle } from 'styles/variables'

// Exports

const photoSize = {
	width: UserPhotoSize.medium.width,
	height: UserPhotoSize.medium.width,
}

const styles = {
	base: {
		position: 'relative',
		width: UserPhotoSize.medium.width + width.icon.flag / 2 + width.border.basic,
		height: UserPhotoSize.medium.width + 6,
	},
	withClick: {
		cursor: 'pointer',
	},
	icon: {
		base: {
			position: 'absolute',
			right: 0,
			bottom: 0,
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			lineHeight: 1,
			backgroundColor: color.white,
			borderRadius: border.radius,
			width: width.icon.flag,
			height: width.icon.flag,
		},
		disabled: {
			color: color.secondaryGrey,
		},
	},
	iconOnly: {
		width: 'auto',
	},
	iconOnlyDisabled: {
		opacity: 0.4,
	},
	img: {
		borderRadius: '50%',
		height: UserPhotoSize.medium.size,
	},
	errorIcon: {
		color: color.secondaryGrey,
		fontSize: UserPhotoSize.medium.size,
		lineHeight: UserPhotoSize.medium.size,
	},
	disabledMask: {
		position: 'absolute',
		top: 0,
		left: 0,
		background: 'rgba(255, 255, 255, .4)',
		borderRadius: '50%',
		...photoSize,
	},
	reauthIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: color.secondaryRed,
		background: color.secondaryGrey,
		borderWidth: width.border.basic,
		borderStyle: borderStyle.solid,
		borderColor: color.secondaryRed,
		borderRadius: '50%',
		fontSize: '20px',
		...photoSize,
	},
}

export const UserImageDimension = {
	Original: 'Original',
	Square160: 'Square160',
	Square80: 'Square80',
	Square40: 'Square40',
	Square20: 'Square20',
}

class UserChannelPhoto extends Component {
	static propTypes = {
		provider: PropTypes.oneOf(Object.keys(SocialProviderData)),
		photos: PropTypes.object,
		tooltip: PropTypes.string,
		disabled: PropTypes.bool,
		authRequired: PropTypes.bool,
		onClick: PropTypes.func,
		onKeyDown: PropTypes.func,
		style: PropTypes.object,
	}

	static defaultProps = {
		tooltip: '',
	}

	state = {
		showImageError: false,
	}

	onError = () => {
		this.setState({ showImageError: true })
	}

	render() {
		const {
			provider,
			photos,
			tooltip,
			disabled,
			authRequired,
			onClick,
			onKeyDown,
			style: styleOverride,
		} = this.props
		const { showImageError } = this.state

		// Create the styles for the primary tag
		let style = {
			...styles.base,
			...styleOverride,
			...(onClick && styles.withClick),
		}

		// Create the styles for the img tag
		const imgStyle = {
			...styles.img,
		}

		const iconStyle = {
			...styles.icon.base,
			...(disabled && styles.icon.disabled),
		}

		const iconOnlyStyle = {
			...(disabled && {
				...styles.iconOnlyDisabled,
			}),
		}

		const disabledMaskStyle = {
			...styles.disabledMask,
		}

		const reauthIconStyle = {
			...styles.reauthIcon,
		}

		// Get the appropriate image size
		const image = photos && (photos[UserImageDimension.Square80] || photos[UserImageDimension.Original])
		if (!authRequired && !image) style = { ...style, ...styles.iconOnly }

		// Render - Position social photo (or icon) in the center and
		//          the provider icon in the lower right, overlapping.
		//   Only show social icon if no photo available.
		//   Special rendering when a reauthorization is required.
		return authRequired ? (
			// Special Reauthorization Required rendering
			<div
				data-component="UserChannelPhoto"
				style={style}
				onClick={onClick}
				onKeyDown={onKeyDown}
				title={tooltip}
				aria-label={tooltip}
				tabIndex={0}
			>
				<Icon style={reauthIconStyle} name={Name.exclamation} />
				<SocialIcon
					style={iconStyle}
					provider={provider}
					size={SocialIconSize.flag}
					title={tooltip}
					disabled={disabled}
				/>
			</div>
		) : (
			// Normal Rendering
			<div
				data-component="UserChannelPhoto"
				style={style}
				onClick={onClick}
				onKeyDown={onKeyDown}
				title={tooltip}
				aria-label={tooltip}
				tabIndex={0}
			>
				{showImageError ? (
					<Icon name={Name.frown} style={styles.errorIcon} />
				) : image ? (
					<img src={image.url} style={imgStyle} onError={this.onError} alt={tooltip} />
				) : (
					<SocialIcon
						style={iconOnlyStyle}
						provider={provider}
						size={SocialIconSize.channel}
						backdrop={Backdrop.circle}
						title={tooltip}
						disabled={disabled}
					/>
				)}
				{image &&
					disabled && (
						// Mask draws on top of img to give disabled effect
						<div style={disabledMaskStyle} />
					)}
				{image && (
					<SocialIcon
						style={iconStyle}
						provider={provider}
						size={SocialIconSize.flag}
						disabled={disabled}
					/>
				)}
			</div>
		)
	}
}

export default UserChannelPhoto
