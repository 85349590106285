import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Convenience Imports
export const Section = require('./Section').default
export const Panel = require('./Panel').default

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import StickyBox from 'react-sticky-box'

// Styles
import responsive from 'wrappers/ResponsiveComponent'
import { isAtLeast, breakpoints } from 'helpers/breakpoints'
import { styleLayout } from 'styles/layout'
// import { isSafari } from 'helpers/browserhelper'
import { constants } from 'styles/variables'
import { chromeVersion } from 'helpers/browserhelper'

// Pull props from Redux store
const mapStateToProps = (state, ownProps) => {
	const viewport = state.browser.viewport

	// withRouter replacement
	const location = safeGetNestedProp(state, 'routing.location')
	const showPageDialog = !!(location && location.state && location.state.showPageDialog)

	return {
		viewport,
		showPageDialog,
	}
}

class PageLayout extends React.Component {
	static propTypes = {
		children: PropTypes.any.isRequired,
		sidebar: PropTypes.any,
		viewport: responsive.PropType,
		style: PropTypes.any,
		showPageDialog: PropTypes.bool,
		stickySidebar: PropTypes.bool,
	}

	static defaultProps = {
		stickySidebar: true,
	}

	renderSidebar = (sidebar, viewport, stickySidebar) => {
		// Get rid of the sidebar if the screen isn't >= large
		const hideSidebar = !isAtLeast(breakpoints.large.name, viewport.size)

		const style = {
			...styleLayout.sidebar,
			...styleLayout.sidebar[viewport.size],
			...(hideSidebar && {
				display: 'none',
			}),
		}

		// stickySidebar = stickySidebar && !isSafari
		stickySidebar = stickySidebar && (!chromeVersion || chromeVersion > 60)
		const Sidebar = sidebar

		return (
			<div style={style}>
				{stickySidebar ? (
					<StickyBox
						bottom={false}
						offsetTop={constants.HeaderHeight}
						// offsetBottom={70}
						style={styleLayout.sidebarSection}
					>
						<Sidebar />
					</StickyBox>
				) : (
					<div style={styleLayout.sidebarSection}>
						<Sidebar />
					</div>
				)}
			</div>
		)
	}

	render() {
		const { children, sidebar, viewport, style, stickySidebar, showPageDialog } = this.props

		const hasSidebar = sidebar && sidebar !== null && typeof sidebar === 'function'

		const dynamicStyles = {
			main: {
				...styleLayout.main,
				...styleLayout.main[viewport.size],
				...(hasSidebar && styleLayout.main.withSidebar),
				...(hasSidebar && styleLayout.main.withSidebar[viewport.size]),
				...(showPageDialog && {
					overflow: 'visible',
				}),
				...style,
			},
			container: {
				...styleLayout.container,
				...(showPageDialog && {
					...styleLayout.container.pageDialog,
					...styleLayout.container.pageDialog[viewport.size],
				}),
			},
		}

		return (
			<div style={styleLayout.page}>
				{/* Sidebar */}
				{hasSidebar && this.renderSidebar(sidebar, viewport, stickySidebar)}
				{/* Main */}
				<main style={dynamicStyles.main}>
					<div style={dynamicStyles.container}>{children}</div>
				</main>
			</div>
		)
	}
}
export default connect(mapStateToProps)(PageLayout)
