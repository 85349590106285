import queryString from 'query-string'
import { isMobile } from './browserhelper'

/* User Activity for activity and event analytics TODO: Add more Reason / Source */
export const Activity = {
	Reason: {
		broadcast: 'Broadcast',
		invitationLink: 'InvitationLink',
		postAutoNotification: 'PostAutoNotification',
	},
	Source: {
		web: 'Web',
		mobileWeb: 'MobileWeb',
		iOS: 'IOS',
		android: 'Android',
		desktopWeb: 'DesktopWeb',
	},
}

export const getActivity = (reason, detail, source) => {
	if (!reason && !detail) return

	const activitySource =
		source || (isMobile ? Activity.Source.mobileWeb : Activity.Source.desktopWeb)

	return {
		activitySource,
		activityReason: reason,
		activityDetail: detail,
	}
}

export const getActivityFromUrl = location => {
	if (!location) return

	const queryParams = queryString.parse(location.search)
	const reason = queryParams.actr
	const detail = queryParams.actd

	return getActivity(reason, detail)
}
