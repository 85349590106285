import React from 'react'
import PropTypes from 'prop-types'

// Helpers
import { withRouter } from 'react-router'
import Routes from 'helpers/routes'
// import IpcEvent from 'helpers/desktopApp/ipcEvent'

class DesktopListener extends React.Component {
	static propTypes = {
		history: PropTypes.array,
	}

	componentDidMount() {
		window.ipcRenderer.on('navigate', this.handleNavigate)
	}

	componentWillUnmount() {
		window.ipcRenderer.removeListener('navigate', this.handleNavigate)
	}

	handleNavigate = (event, route) => {
		const { history } = this.props

		if (route === 'user-settings') {
			history.push(Routes.Settings.routerPath)
		}
	}
	render() {
		return null
	}
}

export default withRouter(DesktopListener)
