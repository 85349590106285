import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import RestrictedText from 'components/RestrictedText/RestrictedText'
import SocialIcon, { SocialIconSize } from 'components/SocialIcon/SocialIcon'
import ImageWithPlaceholder from 'components/Placeholders/ImagePlaceholder'
import PostLink from 'components/PostLink/PostLink'
import Icon, { Name } from 'components/Icons/FontAwesome'
import HighlightText from 'components/HighlightText/HighlightText'

// Helpers
import SocialProviderData, { SocialPostProvidersPropType } from 'helpers/socialProviderData'
import { testDataAttributes } from 'helpers/testDataAttributes'

// Helpers
import { stringIsEmptyOrWhitespace } from '@dysi/js-helpers'

// Styles
import {
	spacing,
	width,
	color,
	fontWeight,
	flexContainer,
	lineHeight,
	fontSize,
	border,
	margin,
} from 'styles/variables'
import { textEllipsis } from 'styles/defaults'

const styles = {
	base: {
		backgroundColor: color.bgContent,
	},
	author: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: width.icon.provider, // This is done to have common height if there is no profile picture

		paddingRight: spacing.basic,
		paddingLeft: spacing.basic,
		paddingTop: spacing.narrow, // paddingTop so the background color fills up
		paddingBottom: spacing.narrow, // paddingBottom so the background color fills down

		visibleHeadingOverride: {
			paddingBottom: 0,
			marginBottom: spacing.narrow,
		},
	},
	heading: {
		paddingRight: spacing.basic,
		paddingLeft: spacing.basic,
		paddingBottom: spacing.narrow, // paddingBottom so the background color fills down
		marginTop: spacing.narrow,
		display: 'block',
		wordWrap: 'break-word',
	},
	name: {
		flex: 1,
		fontSize: fontSize.base,
		fontWeight: fontWeight.semiBold,
		...flexContainer('column', 'center', 'flex-start'),
		lineHeight: lineHeight.close,
		marginRight: margin.wide + margin.narrow / 2, //TODO: update styling once we figure out bookmark styling
		textEllipsis: {
			...textEllipsis,
		},
	},
	handle: {
		color: color.primaryGrey,
		fontWeight: fontWeight.normal,
	},
	profileImage: {
		height: width.icon.provider,
		width: width.icon.provider,
		marginRight: spacing.basic,
		objectFit: 'cover',
		borderRadius: 5,
		container: {
			position: 'relative',
		},
	},
	socialIcon: {
		backgroundColor: color.white,
		borderRadius: border.radius,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: width.icon.provider,
		height: width.icon.provider,
		provider: {
			marginRight: margin.basic,
		},
		profile: {
			position: 'absolute',
			width: width.icon.tiny,
			height: width.icon.tiny,
			bottom: -6,
			right: 6,
		},
	},
}

class SocialHeader extends Component {
	showHeading = heading => !stringIsEmptyOrWhitespace(heading)

	render() {
		const {
			author,
			provider,
			style,
			heading,
			post,
			isUrgentBroadcast,
			searchMatches,
			disabled,
		} = this.props

		// Dip out if we get garbage in
		if (!author || !author.name || !provider) return null

		const shouldForceUpdate = searchMatches && searchMatches.length > 0
		const profileImage = author.profileImageUrl && {
			url: author.profileImageUrl,
			height: width.icon.provider,
			width: width.icon.provider,
		}

		const dynamicStyles = {
			author: {
				...styles.author,
				...(this.showHeading(heading) && styles.author.visibleHeadingOverride),
			},
			socialIcon: {
				...styles.socialIcon,
				...(!profileImage ? styles.socialIcon.provider : styles.socialIcon.profile),
			},
		}

		return (
			<PostLink
				postId={post.postId}
				postTitle={post.title}
				urlSlug={post.urlSlug}
				displayMode={post.displayMode}
				disabled={isUrgentBroadcast || disabled}
			>
				<div style={{ ...styles.base, ...style }}>
					{/* Social Author */}
					<div style={dynamicStyles.author} data-test-id={testDataAttributes.postSocialHeader}>
						{/* Profile Image */}
						{profileImage ? (
							<div style={styles.profileImage.container}>
								<ImageWithPlaceholder
									image={profileImage}
									style={styles.profileImage}
									renderError={<Icon name={Name.exclamationCircle} />}
								/>

								<SocialIcon
									provider={provider}
									size={SocialIconSize.tiny}
									style={dynamicStyles.socialIcon}
								/>
							</div>
						) : (
							<SocialIcon
								provider={provider}
								size={SocialIconSize.provider}
								style={dynamicStyles.socialIcon}
							/>
						)}

						{/* Display Name */}
						<div style={{ ...styles.name, ...styles.name.textEllipsis }}>
							<div style={styles.name.textEllipsis}>{author.name}</div>

							{author.providerHandle &&
								provider === SocialProviderData.Twitter.provider && (
									<span style={styles.handle}>{author.providerHandle}</span>
								)}
						</div>

						{/* Social Provider Icon */}
						{/*<SocialIcon provider={provider} size={SocialIconSize.tiny} style={styles.socialIcon} />*/}
					</div>

					{/* Social Heading / Title for weird cases */}
					{this.showHeading(heading) && (
						<RestrictedText
							text={heading}
							limit={post.socialHeadingLength}
							shouldForceUpdate={shouldForceUpdate}
						>
							{text => (
								//Wrap in span for tooltip
								<span>
									<HighlightText
										text={text}
										ranges={searchMatches}
										render={highlightedText => <div style={styles.heading}>{highlightedText}</div>}
									/>
								</span>
							)}
						</RestrictedText>
					)}
				</div>
			</PostLink>
		)
	}
}

SocialHeader.propTypes = {
	heading: PropTypes.string,
	isUrgentBroadcast: PropTypes.bool,
	style: PropTypes.object,
	author: PropTypes.shape({
		providerUserName: PropTypes.string,
		profileImageUrl: PropTypes.string, // this is a URL
	}).isRequired,
	provider: SocialPostProvidersPropType.isRequired,
	post: PropTypes.object,
	searchMatches: PropTypes.array,
	disabled: PropTypes.bool,
}

export default SocialHeader
