// Hide showcase
const small = {
	name: 'small',
	min: 0,
	max: 720,
}
// Hide sidebar
const medium = {
	name: 'medium',
	min: small.max + 1,
	max: 940,
}
// Normal
const large = {
	name: 'large',
	min: medium.max + 1,
	max: 1200,
}
// Do something awesome!
const huge = {
	name: 'huge',
	min: large.max + 1,
	max: 20000,
}

export const breakpoints = {
	small,
	medium,
	large,
	huge,
}

export const isSmall = width => {
	return width >= small.min && width <= small.max
}
export const isMedium = width => {
	return width >= medium.min && width <= medium.max
}
export const isLarge = width => {
	return width >= large.min && width <= large.max
}
export const isHuge = width => {
	return width >= huge.min && width <= huge.max
}

export const getViewportSize = width => {
	if (isSmall(width)) return small.name
	else if (isMedium(width)) return medium.name
	else if (isLarge(width)) return large.name
	else if (isHuge(width)) return huge.name
}

// Returns whether the actual viewport width is at least/most (depending on flag) as large as the requested size.
const compareWidth = (comparedViewportSize, actual, isAtLeast) => {
	// Grab the appropriate breakpoint
	const viewportSize = breakpoints[comparedViewportSize]
	// Grab the actual breakpoint
	const actualSize = breakpoints[actual]

	// If either doesn't exist, don't keep going
	if (!viewportSize || !actualSize) return false

	// Check to see if how we compare to the request size (depending on flag)
	return isAtLeast ? viewportSize.min <= actualSize.min : viewportSize.max >= actualSize.max
}

// Returns whether the actual viewport width is at least as large as the requested size.
export const isAtLeast = (atLeast, actual) => {
	return compareWidth(atLeast, actual, true)
}

// Returns whether the actual viewport width is at most as large as the requested size.
export const isAtMost = (atMost, actual) => {
	return compareWidth(atMost, actual, false)
}
