import { Component } from 'react'
import PropTypes from 'prop-types'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { connect } from 'react-redux'

const mapStateToProps = state => {
	const user = safeGetNestedProp(state, 'auth.user')
	const sphere = safeGetNestedProp(state, 'sphere')

	return {
		user,
		sphere,
	}
}

class PendoAnalytics extends Component {
	static propTypes = {
		user: PropTypes.object,
		sphere: PropTypes.object,
	}

	componentDidMount() {
		// Only in PROD
		if (!process.env.DS_ENV_IS_PRODUCTION) {
			return
		}

		this.startAppTracking()
	}

	startAppTracking = () => {
		const { user, sphere } = this.props

		// Check if Pendo is registered
		if (!window.pendo) {
			return
		}

		// Sanity check
		if (!user || !sphere) {
			return
		}

		const userId = user.userId
		const sphereId = sphere.sphereId
		const sphereName = sphere.communityName

		// NOTE: Sphere ID is used as the unique account ID for Pendo. If Pendo tracking is
		// ever expanded to include internal environments like TeamA, Dev, etc., the account ID
		// must be extended to include the internal environment name (sphere.environment) to
		// guarantee uniqueness across environments within Pendo. That's why account.id is set as
		// a string
		window.pendo.initialize({
			visitor: {
				id: `${sphereId}-${userId}`,
			},
			account: {
				id: `${sphereId}`,
				name: sphereName,
			},
		})
	}

	render() {
		return null
	}
}

export default connect(mapStateToProps)(PendoAnalytics)
