//
// NOTE: Should this handle soft/hard differently?
//

const Restrictions = {
	// Post
	PostAuthor: 35,
	PostTitle: {
		NonSocial: 80,
		NonSocialDetailsLayout: 100,
		Social: {
			Post: 280,
			Showcase: 80,
			Summary: 80,
		},
	},
	PostSubtitle: {
		Default: 80,
		DetailsLayout: 100,
	},
	PostDescription: 300,
	PostNoteToMembers: {
		Restricted: 250,
		Full: 500,
	},
	PostGuidelineText: 300,
	PostSubmissionConfirmation: 150,
	// Discussions
	CommentText: 5000,
	// Newsletter
	NewsletterTitle: 80,
	// Category
	CategoryName: 20,
	CategoryNameLong: 25,
	CategoryDescription: 250,
	// Broadcast
	BroadcastHeadline: 100,
	// Survey
	// SurveyTitle: 80,
	// SurveyDescription: 250,
	SurveyQuestion: 150,
	SurveyAnswer: 150,
	// Leaderboard
	LeaderboardTitle: 25,
	// Division
	DivisionName: 25,
	DivisionPrompt: 150,
	// Target
	TargetPrompt: 150,
	TargetName: 25,
	TargetValue: 50,
	// Sharing
	ShareBlacklistError: 150,
	// Profile Questions
	ProfileQuestionTitle: 150,
	ProfileQuestionAnswer: 150,
	// Messaging
	MessageText: 1000,
	// Invitations
	EmailsPerMemberInvite: 10,
	InvitationMessageText: 1000,
	// Custom Links
	CustomLinks: 25,
	QuickLinks: 12,
	//TODO: FirstName
	//TODO: LastName
}

export default Restrictions
