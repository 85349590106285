const lang = {
	improvements: 'Improvements',
	notificationDisable: 'Disable Notification Flyout',
	notificationEnable: 'Enable Notification Flyout',
	postRowFeedEnable: 'Enable Post Row Feed',
	postRowFeedDisable: 'Disable Post Row Feed',
	pageTitle: 'Super Secret Beta Functionality',
	partyModeDisable: 'Disable Party Mode',
	partyModeEnable: 'Enable Party Mode',
	sillyness: 'Sillyness',
	themes: 'Themes',
}

export default lang;