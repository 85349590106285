const lang = {
	add: 'Add',
	addChannelErrorUnknown: 'Cannot add social accounts at this time. Please try again later.',
	disconnect: 'Disconnect',
	dialogTitle: 'Disconnect Social Account',
	dialogInstructions: 'Select the social account to disconnect:',
	dialogSubmitButton: 'Disconnect',
	dialogErrorUnknown: 'Cannot disconnect social accounts at this time. Please try again later.',
}

export default lang;