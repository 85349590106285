import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { matchPath, withRouter } from 'react-router'
import { Link } from 'react-router-dom'

// Styles
import { color, margin, padding, width, fontSize, flexContainer } from 'styles/variables'

// Lang
import lang from 'components/Footer/footer.lang'
import FooterText from './footer.lang'

// Wrappers
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Components
import Icon from 'components/Icons/FontAwesome'
import MobileIcons from 'components/Icons/Sets/MobileIcons'
import CustomLinks from 'scenes/CustomLinks/CustomLinks'
import SocialChannels from './SocialChannels'

// Helpers
import { stringIsEmptyOrWhitespace, safeGetNestedProp } from '@dysi/js-helpers'
import Routes from 'helpers/routes'
import { FooterVariables } from 'components/Footer/FooterVariables'

// Enums
import Locations from 'scenes/CustomLinks/enums/customLinkLocations'

//region Styles
const styles = {
	base: {
		color: color.white,
		backgroundColor: color.primaryBlack,
		display: 'flex',
		justifyContent: 'center',
	},
	inner: {
		width: '100%',
		maxWidth: width.layout.page,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: padding.basic,
		paddingRight: padding.basic,
		[BreakpointNames.small]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	list: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
	},
	item: {
		maxHeight: FooterVariables.height * 2,
		...flexContainer(),
		alignItems: 'center',
		flexWrap: 'wrap-reverse',
		text: {
			...flexContainer(),
			height: FooterVariables.height,
			textAlign: 'center',
			flexWrap: 'wrap',
		},
	},
	apps: {
		base: {
			display: 'flex',
			alignItems: 'center',
			marginTop: 0,
			marginBottom: 0,
			marginLeft: margin.narrow,
			marginRight: margin.narrow,
			justifyContent: 'space-evenly',
		},
		text: {
			marginRight: margin.narrow,
		},
		item: {
			marginTop: 0,
			marginRight: 5,
			fontSize: width.icon.tiny,
		},
	},
	customLinks: {
		...flexContainer(),
		width: 'inherit',
		text: {
			background: color.primaryBlack,
			color: color.white,
			fontSize: fontSize.base,
		},
		[BreakpointNames.small]: {
			width: '100%',
		},
	},
	terms: {
		textDecoration: 'none',
		color: color.white,
		paddingLeft: margin.basic,
		paddingRight: margin.basic,
		whiteSpace: 'nowrap',
	},
}
//endregion

const mapStateToProps = (state, ownProps) => {
	const showMobileAppLinks =
		ownProps.showMobileAppLinks || state.sphere.parameters.showMobileAppLinks
	const registrationCode = ownProps.registrationCode || state.sphere.communityRegistrationCode
	const iosAppDownloadUrl = ownProps.iosAppDownloadUrl || state.sphere.parameters.iosAppDownloadUrl
	const androidAppDownloadUrl =
		ownProps.androidAppDownloadUrl || state.sphere.parameters.androidAppDownloadUrl
	const footerLinks = safeGetNestedProp(state, 'sphere.customLinks.footer', [])
	const hasFooterLinks = footerLinks.length > 0
	const socialAccounts = safeGetNestedProp(state, 'sphere.customLinks.socialAccount', [])
	const isMessagesPage = matchPath(ownProps.location.pathname, { path: Routes.Messages.routerPath })

	return {
		showMobileAppLinks,
		registrationCode,
		iosAppDownloadUrl,
		androidAppDownloadUrl,
		isMessagesPage,
		hasFooterLinks,
		socialAccounts,
	}
}

class Footer extends React.Component {
	static propTypes = {
		iosAppDownloadUrl: PropTypes.string,
		androidAppDownloadUrl: PropTypes.string,
		viewport: responsive.PropType,
		hasFooterLinks: PropTypes.bool,
		registrationCode: PropTypes.string,
		showMobileAppLinks: PropTypes.bool,
		isMessagesPage: PropTypes.bool,
		socialAccounts: PropTypes.array,
	}

	renderMobileApps = () => {
		const { iosAppDownloadUrl, androidAppDownloadUrl } = this.props

		if (!iosAppDownloadUrl && !androidAppDownloadUrl) return null

		return (
			<div style={styles.apps.base}>
				<span style={styles.apps.text}>{FooterText.downloadAppsText}</span>
				{!stringIsEmptyOrWhitespace(iosAppDownloadUrl) && (
					<a
						style={styles.apps.item}
						href={iosAppDownloadUrl}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={lang.ariaAppDownloadiOS}
					>
						<Icon name={MobileIcons.apple} color={color.white} />
					</a>
				)}
				{!stringIsEmptyOrWhitespace(androidAppDownloadUrl) && (
					<a
						style={styles.apps.item}
						href={androidAppDownloadUrl}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={lang.ariaAppDownloadAndroid}
					>
						<Icon name={MobileIcons.android} color={color.white} />
					</a>
				)}
			</div>
		)
	}

	render() {
		const {
			viewport,
			registrationCode,
			showMobileAppLinks,
			isMessagesPage,
			hasFooterLinks,
			socialAccounts,
		} = this.props

		// Don't show on messages page
		if (isMessagesPage) return null

		const showChannels = socialAccounts.length > 0
		const currentYear = new Date().getFullYear()
		const dynamicStyles = {
			customLinks: {
				...styles.customLinks,
				...styles.customLinks.text,
				...styles.customLinks[viewport.size],
			},
			inner: {
				...styles.inner,
				...styles.inner[viewport.size],
			},
		}

		return (
			<footer style={styles.base}>
				<div style={dynamicStyles.inner}>
					<div style={styles.list}>
						<div style={styles.item}>
							<div style={styles.item.text}>
								<span>{lang.copyrightText(currentYear)}</span>
								<Link
									to={Routes.Terms.linkPath}
									target="_blank"
									rel="noopener noreferrer"
									style={styles.terms}
								>
									{lang.termsOfUse}
								</Link>
								<Link
									to={Routes.Licenses.linkPath}
									target="_blank"
									rel="noopener noreferrer"
									style={styles.terms}
								>
									{lang.licenses}
								</Link>
							</div>

							{hasFooterLinks && (
								<div style={dynamicStyles.customLinks}>
									<CustomLinks location={Locations.footer} style={styles.customLinks.text} />
								</div>
							)}
						</div>

						{(showMobileAppLinks || showChannels) && (
							<div style={styles.list}>
								{registrationCode !== null && (
									<span style={styles.item}>{lang.communityCodeText(registrationCode)}</span>
								)}
								{showMobileAppLinks && <span style={styles.item}>{this.renderMobileApps()}</span>}

								{showChannels && (
									<div style={styles.item}>
										<SocialChannels channels={socialAccounts} />
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</footer>
		)
	}
}

let WrappedFooter = responsive(Footer)
WrappedFooter = connect(mapStateToProps)(WrappedFooter)
WrappedFooter = withRouter(WrappedFooter)

export default WrappedFooter
