import tinycolor from 'tinycolor2'

// NOTE: To add isRequired support to any custom PropType, set
//       XyzPropType.isRequired = isPropRequired(XyzPropType)
//       Example:
//           ColorPropType.isRequired = isPropRequired(ColorPropType);
//
const isPropRequired = validator => (props, propName, componentName) => {
	if (!props[propName]) {
		return new Error(
			`The prop '${propName}' is marked as required in '${componentName}', but its value is '${
				props[propName]
			}'`
		)
	}
	return validator(props, propName, componentName)
}

export const ColorPropType = (props, propName, componentName) => {
	const color = props[propName]
	if (color && !tinycolor(color).isValid()) {
		return new Error(
			'Invalid value "' + color + '" for prop ' + propName + ' supplied to ' + componentName + '.'
		)
	}
}
ColorPropType.isRequired = isPropRequired(ColorPropType)
