var prefix = 'fads';
var faAdp = { prefix: 'fads', iconName: 'Adp', icon: [576, 512, [], '', 'M474.9 127.1h-91.1v44.4a128 128 0 0 0-96.9-44.3H151.2L0 384.8h102.5l32.3-55.6h67.8v55.6h84.3c38.8 0 73.1-17.2 96.8-44.4v44.4h87.6v-55.6h3.6a101 101 0 1 0 0-202.1zm0 170.3h-35.6V353h-23.6v-55.5h-40.8a96.7 96.7 0 0 1-88 55.4h-52.3v-55.5H115.7L82.9 353h-26l113.9-193.7h116.1a97 97 0 0 1 96.2 82.6h32.6V159h59.2a69.2 69.2 0 1 1 0 138.4zM360.2 256a73.4 73.4 0 0 1-73.3 73.4h-28.7v-31.9c0-.5 28.7 0 28.7 0 23.1 0 41.8-18.3 41.8-41.4s-18.8-41.9-41.8-41.9h-28.7v-31.5h28.7a73.2 73.2 0 0 1 73.3 73.3zm160.1-27.8a45.5 45.5 0 0 1-45.4 45.5h-35.6v-31.6h35.6a13.8 13.8 0 0 0 0-27.6h-35.6v-31.6h35.6c25-.2 45.4 20.2 45.4 45.3zm-317.7-45.5h31.8v91H129.3l18.7-31.8h54.7v-59.2z'] };
var faChatter = { prefix: 'fads', iconName: 'Chatter', icon: [540, 512, [], '', 'M398 255.4c0 1.4 3.7 1-25.7 1 2.7-20.1 24.5-19.4 25.7-1zm-168.8 11c-9.2 6.3-1.1 21.8 13.2 13.9 6.7-3.7 5.3-6.1 5.3-16.6-6.3.1-13.8-.5-18.5 2.7zM540 241.7c0 73.9-67.1 129.8-139.4 115.2a85.1 85.1 0 0 1-111.5 35c-34.3 80.8-150.1 78.1-180.5-4.4C7.9 408.9-42.6 270.7 45 219.5a104.9 104.9 0 0 1 96.3-146.4 104.8 104.8 0 0 1 83.4 41.2 94.8 94.8 0 0 1 151.8 20A116.6 116.6 0 0 1 540 241.7zm-440.8 4.7c6.2-8.3 21.5-5.8 22 7.1H134c-.4-13.2-9.8-22.7-24.5-22.7-31.7 0-32.5 40.3-19.9 54.2s43.7 8 44.5-12.8c-21.7 0-7.3-.6-16.3 7.3-4.7 4.2-14.1 4.3-18.6-1.9s-4.9-24.6 0-31.2zm97.7 6.4c-.3-26.7-25.9-26.3-36.2-14.3v-31.8h-13.5V292h13.5v-42.9c5.4-9.7 22.7-11.3 22.7 4.2v38.8h13.5zm64.3 25.4c0-26.3 2-34.9-6.4-42-12.9-10.9-41.6-4.5-41.6 13.1 22.9 0 8.1.7 16.4-5.9 4.1-3.2 12.1-3.2 15.5.5s2.6 7 2.6 11.4c-9.7 0-20.6-.7-28.7 5.1a17.9 17.9 0 0 0-1.4 27.5 22.5 22.5 0 0 0 30.5-1.6 20.8 20.8 0 0 0 1.6 5.6c21.1.2 11.5 3.8 11.5-13.7zm45.2 3.3c-14.2 3.3-10.9-2.1-10.9-39.5h10.6v-10h-10.6v-14.7H282V232h-9.9v10h9.9c0 32.7-5.4 58.2 24.4 49.9zm41.1 0c-14.2 3.3-10.9-2.2-10.9-39.5h10.6v-10h-10.6v-14.7h-13.5V232h-9.9v10h9.9c0 32.7-5.4 58.2 24.4 49.9zm57.1-42.8c-8-9.4-23.2-10-33-3.9-16.5 10.3-17.1 38.3-5.2 50.3s34.3 11.1 44-3.2l-7.3-6.9c-9.4 12.1-29.5 9.9-31-8.6h39.3c-.1-9.4.1-19.6-6.8-27.7zm51.9-7.1a12.5 12.5 0 0 0-4.7-.8c-15.5 0-13.1 16.9-13.9 1.1H425V292h13.5v-41c3.2-7 10.4-7.9 18-6.7z'] };
var faCircleFilled = { prefix: 'fads', iconName: 'CircleFilled', icon: [512, 512, [], '', ['M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 464a216 216 0 1 1 0-432 216 216 0 0 1 0 432z', 'M256 40A215.6 215.6 0 0 0 40 256 216 216 0 1 0 256 40z']] };
var faDySi = { prefix: 'fads', iconName: 'DynamicSignal', icon: [512, 512, [], '', 'M498.2 254.8c0 70.6-29.2 136.1-82.3 184.3a272.5 272.5 0 0 1-181.1 70.5H59.1a15.8 15.8 0 0 1-14.9-21.1L83.8 378a8.9 8.9 0 0 0-8.4-11.9H29.6A15.7 15.7 0 0 1 14.8 345l67.6-187.2a9.2 9.2 0 0 1 8.7-6.1h61.4a9.2 9.2 0 0 1 8.7 12.4l-41.9 115.7a9 9 0 0 0 8.4 12h46a15.7 15.7 0 0 1 14.8 21l-39 108.7a9.4 9.4 0 0 0 8.8 12.6h76.5c116 0 187.8-93.1 187.8-179.3 0-109.2-97.5-176.9-187.8-176.9H91a15.8 15.8 0 0 1-14.9-21.2L92 12.8a15.9 15.9 0 0 1 14.9-10.4h127.9c126.7 0 263.4 96.5 263.4 252.4z'] };
var faJive = { prefix: 'fads', iconName: 'Jive', icon: [565, 512, [], '', 'M49.1 158.8h54.4v36.6l-54.4-4.6zM246.6 225l55.2 1.8 10.9 32.1 32.9-100.1h57.9l-66.6 172.7h-50.1zm167-26q22.7-42.6 73.9-43.9c57.2-1.4 79.7 44.2 75.5 99.1l-108.1 7.5q5.2 24.9 33.4 27.8t43.3-3.2l27.4 35q-46.8 19-93.9 7.8c-49.1-11.7-69.7-68.3-61.3-102q111.8-5.8 114.3-7.3c1.6-1-1.7-23.5-24.4-25.7-20.7-2-17.2 1.1-80 4.9zm-363.9 4.2l53.8 8.6v143q-6.7 43.7-60.7 56.1l-19.4-44q26.8-5.6 25.6-34.4c-.4-12.4-.2-131.1.7-129.3zm93-44.2H197v41.8l-54.3-2.7zm.2 57.5l54.1 4.8v110.5h-54.2zm78.1-57.7h58.9l14.6 44.3-57-.7z'] };
var faSapJam = { prefix: 'fads', iconName: 'SapJam', icon: [540, 512, [], '', 'M540 343.4h-25.4v-90.1q0-21.6-2.6-31.6a30.9 30.9 0 0 0-11.2-16.8q-8.6-6.8-22.7-6.8t-26.5 9.1a47 47 0 0 0-17.2 24.7q-4.8 15.6-4.8 46.3v65.3h-24.5v-84.8q0-24.9-2.6-35.8a31.5 31.5 0 0 0-11.3-17.9q-8.8-7-22.1-7t-25.3 8.3a50 50 0 0 0-17.7 21.9q-5.7 13.5-5.7 44.8v70.4h-24.8V178h24.8v23.4q21.2-27.6 52.9-27.6a55.7 55.7 0 0 1 31.3 9.3q14.1 9.3 20.1 26.3 10.3-17.3 25.7-26.4a63.3 63.3 0 0 1 32.7-9.1 54.7 54.7 0 0 1 40.2 16.7q16.7 16.5 16.7 62.7zM225.4 178v25.2q-24.5-29.4-60.6-29.4t-61.3 25.7Q78.4 225 78.4 261t25 61.9q25 26 60 26t62-29.8v24.3h24.5V178zm-17 129.3q-18.1 18.2-43.3 18.2t-43.6-19q-18.1-19-18.1-44.8t17.9-44.7q17.8-19 43.7-19t43.7 18.4q17.8 18.4 17.8 45.5t-18.1 45.4zM45.7 355.9q0 20.9-5.4 30.7t-16 9.7q-10.6 0-24.2-5.2V368q9.6 4 13.5 4a5.5 5.5 0 0 0 5.4-3.9q1.5-3.9 1.5-18.2V178h25.2zM33 115.7a18.3 18.3 0 0 1 13.5 5.6 18.5 18.5 0 0 1 5.5 13.6 18.7 18.7 0 0 1-5.7 13.7 18.2 18.2 0 0 1-13.3 5.7 18.7 18.7 0 0 1-13.5-5.8 18.3 18.3 0 0 1-5.9-13.5 18.5 18.5 0 0 1 5.7-13.5 18.6 18.6 0 0 1 13.7-5.8z'] };
var faSquareFilled = { prefix: 'fads', iconName: 'SquareFilled', icon: [448, 512, [], '', ['M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm16 400a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V80a16 16 0 0 1 16-16h352a16 16 0 0 1 16 16v352z', 'M400 64H48a16 16 0 0 0-16 16v352a16 16 0 0 0 16 16h352a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16z']] };
var faTimesLightBig = { prefix: 'fads', iconName: 'TimesLightBig', icon: [512, 512, [], '', 'M310.3 256l198-197.9c5-5 5-13.1 0-18L472 3.9c-5-5-13.1-5-18 0L256 201.8 58.1 3.8c-5-5-13.1-5-18 0L3.8 40c-5 5-5 13.1 0 18l198 198-198 197.9c-5 5-5 13.1 0 18L40 508.1c5 5 13.1 5 18 0l198-197.9 197.9 197.9c5 5 13.1 5 18 0l36.2-36.2c5-5 5-13.1 0-18L310.3 256z'] };
var faToggleSwitch = { prefix: 'fads', iconName: 'ToggleSwitch', icon: [324, 512, [], '', 'M159.3-29.7h5.4a285.9 285.9 0 1 1-5.4 0z'] };
var faToggleTrack = { prefix: 'fads', iconName: 'ToggleTrack', icon: [1143.6, 512, [], '', 'M1142.1 256a191.4 191.4 0 0 1-191.4 191.4H193a191.3 191.3 0 1 1 0-382.8h757.7A191.4 191.4 0 0 1 1142.1 256z'] };
var faUnsplash = { prefix: 'fads', iconName: 'Unsplash', icon: [512, 512, [], '', 'M162.9 147.4V7.7h186.2v139.7zM349.1 225h155.2v279.3H7.7V225h155.2v139.6h186.2z'] };
var faUrgent = { prefix: 'fads', iconName: 'Urgent', icon: [384, 512, [], '', 'M176 432a80 80 0 1 1-80-80 80.1 80.1 0 0 1 80 80zM25.3 25.2l13.6 272a24 24 0 0 0 24 22.8h66.3a24 24 0 0 0 24-22.8l13.6-272a24 24 0 0 0-24-25.2H49.2a24 24 0 0 0-23.9 25.2zM368 432a80 80 0 1 1-80-80 80.1 80.1 0 0 1 80 80zM217.3 25.2l13.6 272a24 24 0 0 0 24 22.8h66.3a24 24 0 0 0 24-22.8l13.6-272a24 24 0 0 0-24-25.2h-93.6a24 24 0 0 0-23.9 25.2z'] };
var faWebhook = { prefix: 'fads', iconName: 'webhook', icon: [512, 512, [], '', 'M343.1 393h-96.4a124.3 124.3 0 0 1-122.1 99.7A125 125 0 0 1 0 368.1c0-57.3 38.6-105.9 93.4-120.8l12.5 48.6a75.9 75.9 0 0 0-56.1 72.3c0 41.1 33.6 74.7 74.7 74.7s74.7-33.6 74.7-74.7v-24.9H343c25.8-44.4 92.9-25.3 92.9 24.9.1 50.1-67 69.2-92.8 24.8zm7-143.2l-45.5-80.7a49.9 49.9 0 1 0-92.7-25.2 50 50 0 0 0 49.4 49.8L329 315.8l21.2-12.5c60.6-26.9 110.9 16.2 110.9 66 0 69.5-89 102.5-133.3 46.1L289 446.6c75.4 91.3 223 36.9 223-78.5 0-78.1-74.6-144.5-161.9-118.3zM261.6 19.3c-111.2 0-168.2 136.2-84.7 215.5-55 90.4-50 83.5-52.3 83.5a50 50 0 0 0-49.8 49.8 49.9 49.9 0 1 0 93.4-24.1l76-124.1-21.2-12.5c-65.5-40-35.5-138.3 38.6-138.3 43.2 0 83.4 37.8 72.3 93.4l48.6 12.5A125.2 125.2 0 0 0 261.6 19.3z'] };
var faWebhookDuo = { prefix: 'fads', iconName: 'webhookDuo', icon: [512, 512, [], '', ['M261.6 19.3A125 125 0 0 0 137 143.9c0 34.9 14.9 67.3 39.9 90.9l-50.8 83.5h-1.5c-27.4 0-49.8 22.4-49.8 49.8s22.4 49.8 49.8 49.8a50 50 0 0 0 43.5-73.9l76-124.1-21.1-12.5a74.3 74.3 0 0 1 38.6-138.2 74.9 74.9 0 0 1 74.7 74.7c0 6.2-1.2 12.5-2.5 18.7l48.6 12.5A124.9 124.9 0 0 0 261.6 19.3z', 'M386.2 417.9a50 50 0 0 0 49.8-49.8 50 50 0 0 0-92.9-24.9H199.3v24.9c0 41.1-33.6 74.7-74.7 74.7s-74.7-33.6-74.7-74.7a76 76 0 0 1 56.1-72.3l-12.5-48.6A125.3 125.3 0 0 0 0 368.1a125 125 0 0 0 124.6 124.6c59.8 0 110.9-42.4 122.1-99.7h96.4a49.7 49.7 0 0 0 43.1 24.9zm1.2-174.4a132 132 0 0 0-37.4 6.2L304.5 169a50 50 0 0 0-42.9-75 50 50 0 0 0-.4 99.6l67.7 122.1 21.2-12.5c11.2-5 23.7-8.7 36.1-8.7 41.1 0 74.7 33.6 74.7 74.7s-33.6 74.7-74.7 74.7a76 76 0 0 1-58.5-28.7L289 446.6a127.3 127.3 0 0 0 97.2 46.1c68.5 0 125.8-56.1 125.8-124.6s-56.1-124.6-124.6-124.6z']] };
var icons = {
	faAdp: faAdp,
	faChatter: faChatter,
	faCircleFilled: faCircleFilled,
	faDySi: faDySi,
	faJive: faJive,
	faSapJam: faSapJam,
	faSquareFilled: faSquareFilled,
	faTimesLightBig: faTimesLightBig,
	faToggleSwitch: faToggleSwitch,
	faToggleTrack: faToggleTrack,
	faUnsplash: faUnsplash,
	faUrgent: faUrgent,
	faWebhook:  faWebhook,
	faWebhookDuo:  faWebhookDuo,
};

export {
	prefix,
	faAdp,
	faChatter,
	faCircleFilled,
	faDySi,
	faJive,
	faSapJam,
	faSquareFilled,
	faTimesLightBig,
	faToggleSwitch,
	faToggleTrack,
	faUnsplash,
	faUrgent,
	faWebhook,
	faWebhookDuo
};
export default icons;
