import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { flexContainer, fontSize, spacing } from 'styles/variables'
const styles = {
	item: {
		...flexContainer('flex', 'space-between', 'center'),
		paddingTop: spacing.narrow,
		paddingBottom: spacing.narrow,
		paddingLeft: spacing.narrow,
		paddingRight: spacing.narrow / 2, // Keep horizontally aligned with the extras from ShareText
		width: '100%',
		shareText: {
			...flexContainer('row', 'none', 'center'),
			flexWrap: 'wrap',
			wordBreak: 'break-word',
			maxWidth: '80%',
		},
		icon: {
			fontSize: fontSize.heading,
			pointer: 'cursor',
		},
	},
	iconContainer: {
		...flexContainer(),
		alignSelf: 'start',
		marginRight: spacing.narrow,
	},
}

export default prefixer.prefix(styles)
