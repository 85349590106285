/**
 * Light Theme
 */
export default {
	name: 'Light',
	colors: {
		bgPage: '#f9f9f9',
		bgContent: '#f4f3f4',
		white: '#ffffff',
		borderPrimary: '#e8e8e8',
		primaryBlack: '#4E4B4E',
		primaryGrey: '#747274',
		secondaryGrey: '#A6A5A6',
		textHighlight: '#fddab6',
	},
}
