import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { flexContainer } from 'styles/variables'

const styles = {
	container: {
		...flexContainer('column'),
		textAlign: 'center',
	},
	image: {
		maxWidth: 200,
	},
}

export default prefixer.prefix(styles)
