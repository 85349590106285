import tinycolor from 'tinycolor2'

import themeName, { themeStorageKey, getThemeByName } from './themes/themeHelpers'

// ---------------------
// THEME TRICKERY
// ---------------------
let loadedTheme = localStorage.getItem(themeStorageKey)
if (!loadedTheme) {
	loadedTheme = themeName.light
	localStorage.setItem(themeStorageKey, themeName.light)
}
const themeColors = getThemeByName(loadedTheme).colors

// ---------------------
// GLOBAL CONSTANTS
// ---------------------
const CustomLinksHeight = 30
const HeaderHeight = 72

export const constants = {
	CustomLinksHeight,
	HeaderHeight,
}

// ---------------------
// COLORS
// ---------------------

// DS Brand
const dsBrandPrimaryDark = '#1B3253'
const dsBrandPrimaryMedium = '#026DAF' // TODO Remove usages
const dsBrandPrimaryLight = '#009EFF'
const dsBrandSecondaryBlue = '#66C5FF' // TODO Remove usages
const dsBrandSecondaryGreyLight = '#DCDCDC' // TODO Remove usages
const dsBrandSecondaryGreyDark = '#747474'

export const DysiBrandColor = {
	dsBrandPrimaryDark,
	dsBrandPrimaryMedium,
	dsBrandPrimaryLight,
	dsBrandSecondaryBlue,
	dsBrandSecondaryGreyLight,
	dsBrandSecondaryGreyDark,
}

// Brand
const defaultPrimaryColor = dsBrandPrimaryMedium // TODO Investigate usages

/*
 * Themed Colors
 * NOTE: These colors are overridden inside their theme files respectively. If you modify these, you MUST test out the changes across all themes.
 */
const primaryBlack = themeColors.primaryBlack || '#4E4B4E'
const primaryGrey = themeColors.primaryGrey || '#747274'
const secondaryGrey = themeColors.secondaryGrey || '#A6A5A6'
const white = themeColors.white || '#ffffff'
const bgPage = themeColors.bgPage || '#f9f9f9'
const borderPrimary = themeColors.borderPrimary || '#e8e8e8'
const textHighlight = themeColors.textHighlight || '#fddab6'
const bgContent = themeColors.bgContent || '#f4f3f4'

// Static
const alwaysBlack = '#000000'
const alwaysWhite = '#ffffff'

// Secondary
const secondaryRed = '#d3484d'
const secondaryYellow = '#fbaf5d'
const secondaryBlue = '#00aeef'
const secondaryGreen = '#438443'
const secondaryYellowDark = '#8a6d3b' // TODO - Only used once

// Background Colors
const backgroundRed = '#fbeced'
const backgroundGreen = '#dff0d8'
const backgroundYellow = '#fff8da'

// Transparent Backgrounds
const bgPointsBanner = tinycolor(alwaysBlack)
	.setAlpha(0.5)
	.toRgbString()

const bgDialogOverlay = tinycolor(alwaysBlack)
	.setAlpha(0.4) // Use black, 40% opacity. Same as Manager App DS-3037
	.toRgbString()

// TODO - Colors to take a good hard look at
const bgBrandPrimaryMedium = tinycolor(dsBrandPrimaryMedium)
	.setAlpha(0.2)
	.toRgbString()

// Misc
const linkPrimary = '#297cbb' // TODO Investigate usages

// ---------------------
// COLORS
// ---------------------
export const color = {
	// Sphere Color
	defaultPrimaryColor,

	// DS Brand
	// TODO - Clean up usages
	// dsBrandPrimaryDark,
	// dsBrandPrimaryLight,
	dsBrandPrimaryMedium,
	dsBrandSecondaryBlue,
	// dsBrandSecondaryGreyDark,
	dsBrandSecondaryGreyLight,

	// Theme Colors
	primaryBlack,
	primaryGrey,
	secondaryGrey,
	white,
	bgPage,
	bgContent,
	textHighlight,
	borderPrimary,

	// Secondary
	secondaryRed,
	secondaryYellow,
	secondaryBlue,
	secondaryGreen,
	secondaryYellowDark,

	// Static
	alwaysBlack,
	alwaysWhite,

	// Background Colors
	backgroundRed,
	backgroundGreen,
	backgroundYellow,

	// Transparent Backgrounds
	bgPointsBanner,
	bgDialogOverlay,
	bgBrandPrimaryMedium,

	// Misc
	linkPrimary,
}

// --------------------------------------
// WIDTHS - Use for calculated widths
// --------------------------------------
export const width = {
	spacing: {
		narrow: 10,
		basic: 15,
		wide: 30,
		gutter: 60,
	},
	border: {
		basic: 1,
		radius: 3,
		roundedRadius: 5,
	},
	lineHeight: {
		base: 24,
		input: 30, // form input fields
		jumbo: 30,
	},
	layout: {
		page: 1440,
		sidebar: 320,
		main: 1120,
		mainNoSidebar: 980,
		sidebarSection: 300,
		dropdownMenu: 300,
	},
	dialog: {
		basic: 680,
		narrow: 480,
	},
	icon: {
		flag: 20,
		tiny: 24,
		provider: 36,
		post: 42,
		action: 48,
		channel: 54,
		// award: 80,
	},
}

// ---------------------
// BORDERS
// ---------------------
export const borderStyle = {
	solid: 'solid',
}
export const border = {
	basic: `${width.border.basic}px ${borderStyle.solid} ${borderPrimary}`,
	table: `${width.border.basic}px ${borderStyle.solid} ${secondaryGrey}`,
	radius: width.border.radius + 'px',
	error: `${width.border.basic}px ${borderStyle.solid} ${secondaryRed}`,
	warning: `${width.border.basic}px ${borderStyle.solid} ${backgroundYellow}`,
	success: `${width.border.basic}px ${borderStyle.solid} ${secondaryGreen}`,
	roundedRadius: width.border.roundedRadius + 'px',
}

// ---------------------
// SPACING
// ---------------------
export const spacingBase = 8
export const spacing = {
	narrow: width.spacing.narrow,
	basic: width.spacing.basic,
	wide: width.spacing.wide,
	gutter: width.spacing.gutter,
}
export const margin = spacing
export const padding = spacing

// ---------------------
// TIMING
// ---------------------
export const timing = {
	default: '400ms',
}

// ---------------------
// FONT FAMILY
// ---------------------
export const fontFamily = {
	base: "'Open Sans', sans-serif",
}

// ---------------------
// FONT WEIGHTS
// ---------------------
export const fontWeight = {
	light: '300', // OpenSans-Light
	normal: '400', // OpenSans-Regular
	semiBold: '600', // OpenSans-SemiBold
	bold: '700', // OpenSans-Bold
}

// ---------------------
// FONT SIZES
// ---------------------
export const fontSize = {
	base: 13.5,
	small: 12,
	heading: 15,
	title: 18,
	jumbo: 24,
}

// --------------------------------------
// LINE-HEIGHT
// --------------------------------------
export const lineHeight = {
	close: 1.2,
	base: 1.5,
	input: '30px', // form input fields
	tableRow: '30px', // table row height
	jumbo: 1.75,
}

// --------------------------------------
// FORM
// --------------------------------------
export const form = {
	// TODO - Do something with this
	input: {
		height: 39,
	},
}

//#region FLEX-HELPER
export const flexContainer = (
	direction = 'row',
	justifyContent = 'center',
	alignItems = 'center'
) => {
	return {
		display: 'flex',
		flexDirection: direction,
		justifyContent: justifyContent,
		alignItems: alignItems,
	}
}

export const flex = (flexShrink = 0, flexGrow = 1, flexBasis = 'auto') => {
	return {
		flexShrink,
		flexGrow,
		flexBasis,
	}
}
//#endregion

// Field Error style
export const fieldErrorStyle = (error, touched) => ({
	...(error && touched ? { border: border.error } : {}),
})

export const dialogFormErrorStyle = {
	borderTop: border.error,
	borderBottom: border.error,
	marginLeft: -width.spacing.wide,
	marginRight: -width.spacing.wide,
}

// imageOrientation
export const imageExifOrientation = {
	'2': {
		transformOrigin: 'center',
		transform: 'scaleX(-1)',
	},
	'3': {
		transformOrigin: 'center',
		transform: 'rotate(180deg)',
	},
	'4': {
		transformOrigin: 'center',
		transform: 'rotate(180deg) scaleX(-1)',
	},
	'5': {
		transformOrigin: 'center',
		transform: 'rotate(270deg) scaleX(-1)',
	},
	'6': {
		transformOrigin: 'center',
		transform: 'rotate(90deg)',
	},
	'7': {
		transform: 'rotate(90deg) scaleX(-1)',
		transformOrigin: 'center',
	},
	'8': {
		transformOrigin: 'center',
		transform: 'rotate(270deg)',
	},
}
// --------------------------------------
// BUTTONS
// --------------------------------------
export const textButtonStyle = {
	background: 'none',
	border: 'none',
	padding: 0,
}
