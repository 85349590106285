import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { flexContainer, color, border, fontSize, fontWeight, spacing } from 'styles/variables'
const styles = {
	container: {
		...flexContainer('column', 'center', 'flex-start'),
		width: '100%',
		marginTop: spacing.narrow,
		marginBottom: spacing.narrow,
	},
	connectedAccountsContainer: {
		...flexContainer('row', 'flex-start', 'center'),
		flexWrap: 'wrap',
	},
	connectedAccountIcon: {
		marginRight: spacing.narrow,
	},
	connectedAccountText: {
		...flexContainer('column', 'center', 'flex-start'),
		accountText: {
			fontWeight: fontWeight.bold,
		},
		displayName: {
			fontWeight: fontWeight.light,
		},
	},
	textContainer: {
		position: 'relative',
		width: '100%',
		border: border.basic,
		marginTop: spacing.narrow,
		height: spacing.gutter * 2,
		error: {
			borderColor: color.secondaryRed,
		},
		textArea: {
			border: 'none',
			resize: 'none',
			width: '100%',
			marginBottom: 0,
			marginTop: 0,
			padding: spacing.narrow,
		},
	},
	extras: {
		...flexContainer(),
		countdownCircle: {
			position: 'absolute',
			right: spacing.basic,
			bottom: 0,
		},
		copy: {
			fontSize: fontSize.heading,
		},
		suggestedShareTextToggle: {
			...flexContainer(),
			position: 'absolute',
			cursor: 'pointer',
			left: spacing.narrow,
			bottom: spacing.narrow / 2,
			text: {
				textTransform: 'uppercase',
				fontWeight: fontWeight.semiBold,
			},
			icon: {
				marginLeft: spacing.narrow / 2,
				fontSize: fontSize.base,
			},
		},
	},
}

export default prefixer.prefix(styles)
