import { vsApi, requests } from 'helpers/apiHelpers'

// Action types
const GET_INVITE_LINK_SUCCESS = 'GET_INVITE_LINK_SUCCESS'

// Action creators

// Get invitation link. This link doesn't change unless user changes, so
// keep it in the store and only fetch it once (per user/page load)
export const asyncGetInviteLink = () => {
	return vsApi({
		endpoint: requests.getInviteLink.endpoint,
		actions: {
			success: GET_INVITE_LINK_SUCCESS,
		},
	})
}

// Record that user clicked the invitation Copy Link button
export const asyncRecordInvitationLinkCopied = userId => {
	return vsApi({
		endpoint: requests.recordInvitationLinkCopied.endpoint,
		requestOptions: requests.recordInvitationLinkCopied.getRequestOptions(userId),
	})
}

// Record that user clicked an invitation link to reach the Sign In page
export const asyncRecordInvitationLinkFollowed = (activityDetail, activitySource) => {
	return vsApi({
		endpoint: requests.recordInvitationLinkFollowed.endpoint,
		requestOptions: requests.recordInvitationLinkFollowed.getRequestOptions(
			activityDetail,
			activitySource
		),
	})
}

export const asyncCheckEmailWhitelist = (emails = []) => {
	return vsApi({
		endpoint: requests.checkWhitelist.endpoint,
		requestOptions: requests.checkWhitelist.getRequestOptions(emails),
		options: { captureUnhandledPromiseRejection: false },
	})
}
// Emails is an array containing one or more emails
export const asyncSendInvite = (emails, message = null) => {
	return vsApi({
		endpoint: requests.sendInvite.endpoint,
		requestOptions: requests.sendInvite.getRequestOptions(emails, message),
		options: { captureUnhandledPromiseRejection: false },
	})
}

// Set the initial state
const initialState = {
	inviteLink: null,
}

// Reducer
export default function api(state = initialState, action) {
	switch (action.type) {
		case GET_INVITE_LINK_SUCCESS: {
			const { shortUrl: inviteLink } = action.response

			return {
				...state,
				inviteLink,
			}
		}

		// Default
		default:
			return state
	}
}
