import { replace } from 'react-router-redux'

import { decodeQueryParams, encodeQueryParams } from '@dysi/js-helpers'
import { openShareDialog, asyncGetPost, startOpenShareDialog } from 'scenes/Post/post.reducer'
import { getActivityFromUrl } from 'helpers/activityHelpers'
import { Settings } from 'wrappers/SettingsWrapper'

// Helpers
export const shouldSkipMiddleware = state => {
	const isLoggedIn = Settings.User.IsLoggedIn.value(state)
	const enhancedSecurityEnabled = Settings.Features.EnhancedApiSecurity.value(state)

	return (enhancedSecurityEnabled === null ? true : enhancedSecurityEnabled) && !isLoggedIn
}

// This middleware will open the ShareDialog and update the URL on any page
// that includes sharePost={postid} as a query param. Optional: shareReaction
// 	sharePost - postId to share/react to
//	shareReaction - reactionType (see ReactionType)
//
// NOTE:
//		o Post will be fetched before ShareDialog opens
//		o sharePost & shareReaction will be removed from history URL (not for user to see)
//
export const openShareDialogMiddleware = history => ({ dispatch, getState }) => next => action => {
	if (shouldSkipMiddleware(getState())) {
		return next(action)
	}

	if (
		history.location.search &&
		(history.location.search.includes('sharePost') ||
			history.location.search.includes('fromDialog'))
	) {
		const query = decodeQueryParams(history.location.search)
		if (query.sharePost || query.fromDialog) {
			// Remove share params from URL
			const newQuery = { ...query }
			if (query.sharePost) {
				delete newQuery.sharePost
				delete newQuery.shareReaction
			} else if (query.fromDialog) {
				delete newQuery.fromDialog
			}

			const search = encodeQueryParams(newQuery)
			const newLocation = {
				...history.location,
				search,
			}
			dispatch(replace(newLocation))

			// Fetch post and open ShareDialog
			const sharePost = query.sharePost
			const shareReaction = query.shareReaction || null
			const activity = getActivityFromUrl(history.location)

			if (sharePost) {
				dispatch(startOpenShareDialog())
				dispatch(asyncGetPost(sharePost)).then(post =>
					dispatch(openShareDialog(post.postId, [post.postId], shareReaction, activity))
				)
			}
		}
	}

	return next(action)
}
