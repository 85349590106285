import { decodeQueryParams } from '@dysi/js-helpers'
import { asyncVerifyAccount } from 'scenes/Settings/AccountSettings/accountSettings.reducer'
import { asyncGetCurrentUser } from 'scenes/Auth/auth.reducer'

// This isn't actually middleware, but a function that's specifically for Username users that need to verify their email
// but are already logged in the NMA. As a result, we will perform the verify API call.
// TODO: This works for now, but still feels really messy. Find a way to clean this up?

export const verifyUsernameUserWithEmail = (user, history, dispatch) => {
	if (history.location.search && history.location.search.includes('isUsername')) {
		const query = decodeQueryParams(history.location.search)
		if (
			query.isUsername &&
			query.verificationCode &&
			!user.isEmailVerified &&
			user.email &&
			user.userId
		)
			// Verify their account, and re-retrieve user information.
			dispatch(asyncVerifyAccount(user.userId, query.verificationCode)).then(() =>
				dispatch(asyncGetCurrentUser())
			)
	}
}
