import React from 'react'
import PropTypes from 'prop-types'

import responsive /* , { BreakpointNames } */ from 'wrappers/ResponsiveComponent'
import { border, width } from 'styles/variables'

const styles = {
	// NOTE: Panel will attempt to remove the borderTop and paddingTop as-needed
	marginTop: width.spacing.basic,
	borderTop: border.basic,
	paddingTop: width.spacing.basic,

	paddingBottom: 0,
	paddingLeft: width.spacing.basic,
	paddingRight: width.spacing.basic,
}

class Section extends React.Component {
	static propTypes = {
		children: PropTypes.any.isRequired,
		style: PropTypes.any,
	}

	render() {
		const style = {
			...styles,
			...this.props.style,
		}

		return <div style={style}>{this.props.children}</div>
	}
}

export default responsive(Section)
