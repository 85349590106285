const keyCodes = {
	backspace: 8,
	enter: 13,
	shift: 16,
	space: 32,
	leftArrow: 37,
	upArrow: 38,
	rightArrow: 39,
	downArrow: 40,
	delete: 46,
}

export default keyCodes
