/**
 * Element IDs (prefixes) for different sections of the page
 */
export const landmark = {
	/** Category feed element */
	categoryFeed: 'category-feed',
	/** Additional actions on post element */
	overflowMenu: 'overflow-menu',
	/** Post element */
	post: 'post',
	/** Post author element */
	postCreator: 'post-creator',
	/** Skip content button at top of the page */
	skipLinkBanner: 'skip-link-banner',
}
