/**
 * Hotdog Stand Theme
 */
// TODO
export default {
	name: 'Hotdog Stand',
	colors: {
		bgPage: '#FF2500',
		bgContent: '#FFFB01',
		white: '#FFFB01',
		borderPrimary: '#000',
		primaryBlack: '#000',
		primaryGrey: '#FF2500',
		secondaryGrey: '#FF2500',
		textHighlight: '#FF2500',
	},
}
