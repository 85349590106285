import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

// React-Loadable
import Loadable from 'react-loadable'
import Loading from 'components/Loading/Loading'

// Styles
import responsive from 'wrappers/ResponsiveComponent'

// Components
import Layout from 'pages/Layouts/Layout'

// Lang
import lang from './messages.lang'

// Loadable Components
export const LoadableMessageListAndDetails = Loadable.Map({
	loader: {
		ConversationList: () => import('scenes/Messages/ConversationList'),
		ConversationDetails: () => import('scenes/Messages/ConversationDetails'),
	},
	loading: Loading,
	render(loaded, props) {
		let ConversationList = loaded.ConversationList.default
		let ConversationDetails = loaded.ConversationDetails.default
		return (
			<React.Fragment>
				<ConversationList {...props} />
				<ConversationDetails {...props} />
			</React.Fragment>
		)
	},
})

// Reducers
import {
	addParticipant,
	asyncArchiveConversation,
	asyncGetUserConversations,
	asyncGetConversation,
	asyncMarkAsRead,
	asyncMarkAsUnread,
	asyncNewConversation,
	asyncReplyConversation,
	asyncUnarchiveConversation,
	clearConversation,
	clearErrors,
	clearParticipants,
	clearUserConversations,
	getUnread,
	removeLastParticipant,
	removeParticipant,
	setToManager,
	setToMember,
	setDirectManager,
} from 'scenes/Messages/message.reducer'
import { clearTypeahead, TypeaheadTypes, asyncGetTypeahead } from 'reducers/typeahead.reducer'
import { asyncGetPost } from 'scenes/Post/post.reducer'
import { asyncGetUserById } from 'scenes/Profile/profile.reducer'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { constants } from 'styles/variables'
import withSettings, { Settings, SettingsPropType } from 'wrappers/SettingsWrapper'

//#region Styles
const styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 0,
		width: '100%',
		flex: 1,
	},
	layout: {
		maxWidth: '100%',
		paddingTop: 0,
		paddingBottom: 0,
		paddingRight: 0,
		paddingLeft: 0,
		marginBottom: 0,
		marginTop: 0,
	},
}
//#endregion

export const messageFilters = {
	archived: 'archived',
	unread: 'unread',
}

const mapStateToProps = (state, ownProps) => {
	const queryParams = queryString.parse(ownProps.location.search)
	const searchTerm = queryParams.search || ''
	const isArchived = queryParams.filter === messageFilters.archived
	const isUnread = queryParams.filter === messageFilters.unread
	const isToManagers = queryParams.managers ? true : false
	const conversations = state.messages.conversations
	const conversationType = state.messages.conversationType
	const selectedConversationId = ownProps.match.params.conversationId
	const currentConversation =
		selectedConversationId && conversations.byId[selectedConversationId]
			? conversations.byId[selectedConversationId]
			: {}
	const errors = state.messages.errors
	const isConversationPending = state.messages.isConversationPending
	const isUserConversationsPending = state.messages.isUserConversationsPending
	const participants = state.messages.participants
	const pendingParticipants = state.messages.pendingParticipants
	const posts = state.post.posts
	const postsPending = state.post.postsPending
	const results = state.typeahead.results
	const user = state.auth.user
	const forwardedPostId = safeGetNestedProp(state, 'routing.location.state.selectedPostId', '')

	const isCustomLinksHeaderVisible =
		safeGetNestedProp(state, 'sphere.customLinks.header.length', 0) > 0
	const communityName = state.sphere.communityName
	const isOrganizationalHierarchyEnabled = safeGetNestedProp(
		state,
		'sphere.parameters.enableOrganizationalHierarchy',
		false
	)
	const directManager = state.messages.directManager

	return {
		conversations,
		conversationType,
		currentConversation,
		errors,
		isConversationPending,
		isUserConversationsPending,
		participants,
		pendingParticipants,
		posts,
		postsPending,
		results,
		selectedConversationId,
		forwardedPostId,
		user,
		isCustomLinksHeaderVisible,
		isArchived,
		isUnread,
		searchTerm,
		isToManagers,
		communityName,
		isOrganizationalHierarchyEnabled,
		directManager,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		addParticipant: participantId => dispatch(addParticipant(participantId)),
		clearConversation: () => dispatch(clearConversation()),
		clearErrors: () => dispatch(clearErrors()),
		clearParticipants: () => dispatch(clearParticipants()),
		clearTypeahead: type => dispatch(clearTypeahead(type)),
		clearUserConversations: () => dispatch(clearUserConversations()),
		fetchConversation: (conversationId, skip, take, abortable) =>
			dispatch(asyncGetConversation(conversationId, skip, take, abortable)),
		fetchPost: postId => dispatch(asyncGetPost(postId)),
		// fetchTypeaheadV2: (take, term) => dispatch(asyncGetTypeaheadV2(take, term, TypeaheadTypes.conversation)),
		fetchTypeahead: (take, value, type) =>
			dispatch(asyncGetTypeahead(take, value, type, { type: type, term: value })),
		fetchUnread: () => dispatch(getUnread()),
		fetchUserConversations: (skip, take, archived, query, selectedConversationId = null) =>
			dispatch(asyncGetUserConversations(skip, take, archived, query, selectedConversationId)),
		removeLastParticipant: () => dispatch(removeLastParticipant()),
		removeParticipant: participant => dispatch(removeParticipant(participant)),
		replyConversation: (conversationId, messageText, postId) =>
			dispatch(asyncReplyConversation(conversationId, messageText, postId)),
		sendConversation: (conversationType, messageText, skip, take, userIds, postId) =>
			dispatch(asyncNewConversation(conversationType, messageText, skip, take, userIds, postId)),
		setArchive: conversationId =>
			dispatch(asyncArchiveConversation(conversationId, { conversationId: conversationId })),
		setToManager: () => dispatch(setToManager()),
		setToMember: () => dispatch(setToMember()),
		setUnarchive: conversationId =>
			dispatch(asyncUnarchiveConversation(conversationId, { conversationId: conversationId })),
		setRead: conversationId =>
			dispatch(asyncMarkAsRead(conversationId, { conversationId: conversationId })),
		setUnread: conversationId =>
			dispatch(asyncMarkAsUnread(conversationId, { conversationId: conversationId })),
		getUserById: userId => dispatch(asyncGetUserById(userId)),
		setDirectManager: directManager => dispatch(setDirectManager(directManager)),
	}
}

const injectedSettings = [
	Settings.Features.EnableMessages,
	Settings.Features.EnableMemberToMemberMessages,
]

class MessagesPage extends Component {
	static propTypes = {
		...SettingsPropType(injectedSettings),

		clearConversation: PropTypes.func,
		clearErrors: PropTypes.func,
		clearParticipants: PropTypes.func,
		clearTypeahead: PropTypes.func,
		conversations: PropTypes.object,
		currentConversation: PropTypes.object,
		errors: PropTypes.array,
		fetchConversation: PropTypes.func.isRequired,
		fetchUserConversations: PropTypes.func.isRequired,
		isCustomLinksHeaderVisible: PropTypes.bool,
		isUserConversationsPending: PropTypes.bool,
		results: PropTypes.object,
		selectedConversationId: PropTypes.string,
		setToMember: PropTypes.func,
		viewport: responsive.PropType,
	}

	state = {
		messagesToManagersOnly: false,
		participants: '',
	}

	componentDidMount() {
		const {
			fetchUserConversations,
			isUserConversationsPending,
			selectedConversationId,
			fetchConversation,
			setToManager,
			isArchived,
			isUnread,
			enableMessages,
			enableMemberToMemberMessages,
			fetchUnread,
			searchTerm,
			isToManagers,
			user,
			getUserById,
			isOrganizationalHierarchyEnabled,
			setDirectManager,
		} = this.props

		// Only fetch if needed
		if (selectedConversationId)
			fetchConversation(selectedConversationId, 0, 100, this.abortable).catch(error => {
				if (error && error.code && error.code === 'request_aborted') {
					return // Request was aborted (by component unmount)
				}
			})
		if (!isUserConversationsPending) {
			fetchUserConversations(0, 10, isArchived, searchTerm, selectedConversationId).then(() => {
				if (isUnread) fetchUnread()
			})
		}

		// Preset to member-to-manager message if sphere is set to Member-to-Manager ONLY or has been requested in querystring
		const messagesToManagersOnly = enableMessages && !enableMemberToMemberMessages
		if (messagesToManagersOnly || (!selectedConversationId && isToManagers)) {
			setToManager()
			if (messagesToManagersOnly) this.setState({ messagesToManagersOnly: true })
		}

		if (user.managerUserId && isOrganizationalHierarchyEnabled) {
			getUserById(user.managerUserId, this.abortable)
				.then(response => {
					// Only extract the information we care about.
					const { id, displayName, status } = response

					if (status === 'Active') setDirectManager({ id: id.toString(), name: displayName })
				})
				.catch(error => {
					if (error && error.code && error.code === 'request_aborted') {
						return // Request was aborted (by component unmount)
					}
				})
		}
	}

	componentDidUpdate(prevProps) {
		const {
			clearUserConversations,
			clearConversation,
			clearTypeahead,
			fetchConversation,
			results,
			selectedConversationId,
			setToMember,
			isArchived,
			isUnread,
			fetchUserConversations,
			fetchUnread,
			searchTerm,
		} = this.props
		const { messagesToManagersOnly } = this.state

		if (selectedConversationId !== prevProps.selectedConversationId) {
			if (this.abortFetch) this.abortFetch()
			if (selectedConversationId) {
				fetchConversation(selectedConversationId, 0, 100, this.abortable).catch(error => {
					if (error && error.code && error.code === 'request_aborted') {
						return // Request was aborted (by component unmount)
					}
				})
			} else {
				clearConversation()
				this.clearParticipants()
				if (!messagesToManagersOnly) setToMember()
			}

			if (results[TypeaheadTypes.user] || results[TypeaheadTypes.post]) clearTypeahead() // Clear typeahead when changing conversation
		}

		if (
			isArchived !== prevProps.isArchived ||
			searchTerm !== prevProps.searchTerm ||
			isUnread !== prevProps.isUnread
		) {
			clearUserConversations()
			fetchUserConversations(0, 10, isArchived, searchTerm).then(() => {
				if (isUnread) fetchUnread()
			})
		}
	}

	componentWillUnmount() {
		const { results, clearTypeahead } = this.props
		const userResults = safeGetNestedProp(results, 'User.allIds', [])
		const postResults = safeGetNestedProp(results, 'Post.allIds', [])

		// Clear typeahead results
		if (userResults.length > 0 || postResults.length > 0) clearTypeahead()
		// Abort pending fetch
		if (this.abortFetch) this.abortFetch()
		// Clear pending participants
		this.clearParticipants()
	}

	clearParticipants = () => {
		const { clearParticipants, pendingParticipants } = this.props
		const { allIds } = pendingParticipants

		if (allIds.length > 0) clearParticipants()
	}

	abortable = abort => {
		// Save abort function
		this.abortFetch = abort
	}

	render() {
		const { isCustomLinksHeaderVisible, communityName } = this.props

		const dynamicStyles = {
			container: {
				...styles.container,
				height: `calc(100vh - ${constants.HeaderHeight +
					(isCustomLinksHeaderVisible ? constants.CustomLinksHeight : 0) +
					2}px)`,
			},
		}

		return (
			<Layout style={styles.layout}>
				<Helmet>
					<title>{`${communityName} - ${lang.header}`}</title>
				</Helmet>
				<div style={dynamicStyles.container} id="shawn">
					<LoadableMessageListAndDetails
						{...this.props}
						messagesToManagersOnly={this.state.messagesToManagersOnly}
					/>
					{/* <ConversationList {...this.props} /> */}
					{/* <ConversationDetails {...this.props} /> */}
				</div>
			</Layout>
		)
	}
}

let WrappedMessagesPage = responsive(MessagesPage)
WrappedMessagesPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(WrappedMessagesPage)
WrappedMessagesPage = withSettings(WrappedMessagesPage, injectedSettings)

export default WrappedMessagesPage
