import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import PercentCompleteCircle from '../PercentCompleteCircle/PercentCompleteCircle'

// Styles
import { color, width } from 'styles/variables'

//region Styles
const styles = {
	percentCircle: {
		marginBottom: width.spacing.narrow - 4,
	},
	charRemaining: {
		color: color.secondaryGrey,
		marginRight: 4,
		marginBottom: width.spacing.narrow - 4,
	},
	container: {
		display: 'inline-flex',
		alignItems: 'center',
	},
}
//endregion

const charState = {
	safe: 'safe',
	warn: 'warn',
	over: 'over',
}

const circleCompleteColor = {
	safe: color.secondaryBlue,
	warn: color.secondaryYellow,
	over: color.secondaryRed,
}

const charsRemainingColor = {
	safe: color.secondaryGrey,
	warn: color.secondaryGrey,
	over: color.secondaryRed,
}

class CountdownCircle extends Component {
	static propTypes = {
		currentLength: PropTypes.number.isRequired,
		maxLength: PropTypes.number.isRequired,
	}
	state = {
		charState: charState.safe,
		charsRemaining: null,
		percentComplete: 0,
	}

	componentDidMount() {
		this.getState()
	}

	componentDidUpdate(prevProps) {
		const { currentLength } = this.props
		if (currentLength !== prevProps.currentLength) {
			if (currentLength === 0) {
				this.setState({
					charState: charState.safe,
					charsRemaining: null,
					percentComplete: 0,
				})
			} else {
				this.getState()
			}
		}
	}

	getState = () => {
		const { currentLength, maxLength } = this.props
		const percentComplete = Math.floor((currentLength / maxLength) * 100)
		const charsRemaining = maxLength - currentLength
		let newCharState

		if (!currentLength || !maxLength) return

		if (percentComplete < 93) {
			newCharState = charState.safe
		} else if (percentComplete > 93 && percentComplete < 100) {
			newCharState = charState.warn
		} else if (percentComplete >= 100) {
			newCharState = charState.over
		}

		this.setState({
			charsRemaining: percentComplete < 93 ? null : charsRemaining,
			charState: newCharState,
			percentComplete,
		})
	}

	render() {
		const { charsRemaining, charState, percentComplete, style: styleOverride } = this.state
		const charRemainingStyle = {
			...styles.charRemaining,
			color: charsRemainingColor[charState],
		}
		const containerStyle = {
			...styles.container,
			...styleOverride,
		}

		return (
			<div style={containerStyle}>
				{charsRemaining !== null && <span style={charRemainingStyle}>{charsRemaining}</span>}

				<PercentCompleteCircle
					style={styles.percentCircle}
					width={18}
					height={18}
					total={100}
					complete={percentComplete}
					completeColor={circleCompleteColor[charState]}
					incompleteColor={color.secondaryGrey}
					lineWidth={2}
				/>
			</div>
		)
	}
}

export default CountdownCircle
