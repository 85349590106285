import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import Icon, { Name } from 'components/Icons/FontAwesome'
import ImagePlaceholder from 'components/Placeholders/ImagePlaceholder'
import CustomLink from './CustomLink'
import QuickLinksDialog from './QuickLinksDialog'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Lang
import lang from './quickLinks.lang'

// Actions
import { toggleSideMenu } from 'reducers/browser.reducer'

// Enums
import Locations from './enums/customLinkLocations'

//region Styles
import { flexContainer, spacing, border, fontSize, margin, color } from 'styles/variables'

const styles = {
	container: {
		...flexContainer('row', 'flex-start', null),
		flexWrap: 'wrap',
	},
	linkContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: spacing.gutter,
		marginTop: 0,
		marginBottom: margin.narrow,
		marginLeft: margin.basic,
		marginRight: margin.basic,
		cursor: 'pointer',
	},
	icon: {
		border: border.basic,
		borderRadius: border.roundedRadius,
		width: spacing.gutter,
		height: spacing.gutter,
	},
	label: {
		marginTop: spacing.narrow / 2,
		whiteSpace: 'nowrap',
		fontSize: fontSize.small,
		...flexContainer(),
	},
	ellipsis: {
		...flexContainer(),
		fontSize: fontSize.jumbo,
		border: border.basic,
		borderRadius: border.roundedRadius,
		height: spacing.gutter,
		background: color.white,
	},
}
//endregion

const mapStateToProps = (state, ownProps) => {
	const quickLinks = safeGetNestedProp(state, 'sphere.customLinks.quickLinksWeb')
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const sideMenuOpen = safeGetNestedProp(state, 'browser.sideMenuOpen')
	const viewport = safeGetNestedProp(state, 'browser.viewport')

	return {
		primaryColor,
		quickLinks,
		sideMenuOpen,
		viewport,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		toggleSideMenu: () => dispatch(toggleSideMenu()),
	}
}

export const formatLinks = (links, max) => {
	const linksObject = {}
	if (links.length > max + 1) {
		const linkCopy = [...links]
		linksObject.links = linkCopy.slice(0, max)
		linksObject.moreLinks = linkCopy.slice(max, links.length)
	} else {
		linksObject.links = links
	}

	return linksObject
}

class QuickLinks extends Component {
	static propTypes = {
		isSideMenu: PropTypes.bool,
		quickLinks: PropTypes.array,
		primaryColor: Theme.PrimaryColor.propType,
		style: PropTypes.object,
		sideMenuOpen: PropTypes.bool,
		toggleSideMenu: PropTypes.func,
		viewport: responsive.PropType.isRequired,
	}

	static defaultProps = {
		isSideMenu: false,
		quickLinks: [],
	}

	state = {
		showDialog: false,
	}

	toggleDialog = () => {
		const { sideMenuOpen, toggleSideMenu } = this.props

		if (sideMenuOpen) toggleSideMenu()
		this.setState(prevState => ({ showDialog: !prevState.showDialog }))
	}

	handleClick = () => {
		const { isSideMenu, sideMenuOpen, toggleSideMenu } = this.props
		if (isSideMenu && sideMenuOpen) {
			toggleSideMenu()
		}
	}

	render() {
		const {
			quickLinks,
			style: styleOverride,
			primaryColor,
			toggleSideMenu,
			isSideMenu,
			viewport,
		} = this.props

		if (quickLinks.length === 0) return null

		const { showDialog } = this.state
		const max = isSideMenu ? 11 : viewport.size === BreakpointNames.large ? 5 : 8
		const formattedLinks = formatLinks(quickLinks, max)
		const dynamicStyles = {
			container: {
				...styles.container,
				...styleOverride,
			},
			ellipsis: {
				...styles.linkContainer,
				...styles.ellipsis,
				color: primaryColor,
			},
		}

		return (
			<div name="QuickLinks">
				<div style={dynamicStyles.container}>
					{formattedLinks.links.map(link => {
						const image = {
							url: link.image,
							width: styles.icon.width,
							height: styles.icon.height,
						}

						return (
							<div style={styles.linkContainer} key={link.id}>
								<CustomLink
									link={link}
									location={Locations.quickLinksWeb}
									onClick={this.handleClick}
									render={
										<div title={link.name} aria-label={link.name}>
											<ImagePlaceholder image={image} alt="" style={styles.icon} />
											<RestrictedText text={link.name} limit={Restrictions.QuickLinks}>
												{text => <div style={styles.label}>{text}</div>}
											</RestrictedText>
										</div>
									}
								/>
							</div>
						)
					})}
					{formattedLinks.moreLinks && (
						<div
							style={dynamicStyles.ellipsis}
							onClick={this.toggleDialog}
							title={lang.seeMore}
							aria-label={lang.seeMore}
						>
							<Icon name={Name.ellipsisH} />
						</div>
					)}
				</div>

				{/* Dialog to show all quick links */}
				{showDialog && (
					<QuickLinksDialog
						onClose={this.toggleDialog}
						links={quickLinks}
						toggleSideMenu={toggleSideMenu}
					/>
				)}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks)
