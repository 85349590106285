import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// Helpers
import Routes from 'helpers/routes'
import { DisplayMode } from 'scenes/Post/data/post.data'
import KeyNames from 'helpers/keyNames'

// Actions
import { asyncPostView } from 'scenes/Post/post.reducer'
import { stringIsEmptyOrWhitespace } from '@dysi/js-helpers'

//region Style
const styles = {
	container: {
		cursor: 'pointer',
	},
}
//endregion

const mapDispatchToProps = (dispatch, ownProps) => {
	const { postId, activity } = ownProps

	return {
		viewPost: () => dispatch(asyncPostView(postId, activity)),
	}
}

/**
 * Reusable component to create links to posts. Makes checks
 * for whether the post is inApp or outOfApp, and generates
 * the correct output to handle the click correctly
 *
 * @class PostLink
 */
class PostLink extends React.Component {
	static propTypes = {
		activity: PropTypes.object,
		urlSlug: PropTypes.string,
		postId: PropTypes.string,
		postTitle: PropTypes.string.isRequired,
		displayMode: PropTypes.string,
		children: PropTypes.any,
		showDiscussions: PropTypes.bool,
		style: PropTypes.object,
		disabled: PropTypes.bool,
		viewPost: PropTypes.func.isRequired,
		isUrgentBroadcast: PropTypes.bool,
		handleCloseDialog: PropTypes.func,
		showPageDialog: PropTypes.bool,
		markBroadcastAsRead: PropTypes.func,
		history: PropTypes.object,
		asDiv: PropTypes.bool,
		asAccessibleDiv: PropTypes.bool,
	}

	onClickInApp = event => {
		const { isUrgentBroadcast, handleCloseDialog } = this.props

		event.stopPropagation()
		if (isUrgentBroadcast && handleCloseDialog) handleCloseDialog()
	}

	openPost = (event, displayInApp, url) => {
		if (displayInApp) {
			this.onClickInApp(event)

			this.props.history.push(url)
		} else {
			this.handleExternalLinkClick(event)
			window.open(url, '_blank', 'noopener noreferrer')
		}
	}

	handleDivClick = (event, displayInApp, url) => {
		// prevent text selection from opening post
		if (window.getSelection().toString()) {
			return
		}

		event.stopPropagation()
		this.openPost(event, displayInApp, url)
	}

	/**
	 * Called when navigating by keyboard - but not in JAWS or NVDAs which invoke the click event
	 */
	handleAccessibleDivKeyDown = (event, displayInApp, url) => {
		if (event.key !== KeyNames.enter) {
			return
		}

		event.stopPropagation()
		this.openPost(event, displayInApp, url)
	}

	handleExternalLinkClick = event => {
		const { viewPost, markBroadcastAsRead } = this.props

		event.stopPropagation()
		// OutOfApp Posts from PostNotification
		if (markBroadcastAsRead) {
			markBroadcastAsRead()
		}

		viewPost()
	}

	render() {
		const {
			activity,
			postId,
			postTitle,
			urlSlug,
			displayMode,
			disabled,
			children = null,
			showDiscussions,
			style: styleOverride,
			showPageDialog,
			asDiv,
			asAccessibleDiv,
		} = this.props
		const dynamicStyles = {
			container: {
				...(!disabled && styles.container),
				...styleOverride,
			},
		}

		// Fallback to children if a post wasn't passed
		if (!postId || !!disabled) {
			return children
		}
		const displayInApp = displayMode === DisplayMode.DisplayInApp || showDiscussions

		let url =
			showPageDialog && displayInApp
				? Routes.Post.Details.generateDialogPath(
						urlSlug,
						postId,
						showDiscussions,
						null,
						null,
						activity
				  )
				: Routes.Post.Details.generateLinkPath(
						urlSlug,
						postId,
						showDiscussions,
						null,
						null,
						activity
				  )

		// HACK: href URLs need to be relative to the root including the public URL
		if (!displayInApp && !stringIsEmptyOrWhitespace(process.env.PUBLIC_URL)) {
			// Different environments have different public URLs so remove accidental double slashes as a precaution
			url = `/${process.env.PUBLIC_URL}/${url}`.replace(/\/\//g, '/')
		}

		if (asAccessibleDiv) {
			return (
				<div
					tabIndex="0"
					onKeyDown={event => this.handleAccessibleDivKeyDown(event, displayInApp, url)}
					onClick={() => this.handleDivClick(displayInApp, url)}
					aria-label={postTitle}
					role="link"
					style={dynamicStyles.container}
				/>
			)
		} else if (asDiv) {
			return (
				<div
					onClick={event => this.handleDivClick(event, displayInApp, url)}
					style={dynamicStyles.container}
				>
					{children}
				</div>
			)
		}

		return displayInApp ? (
			<Link
				to={url}
				style={dynamicStyles.container}
				aria-label={postTitle}
				onClick={event => this.onClickInApp(event)}
			>
				{children}
			</Link>
		) : (
			<a
				href={url}
				style={dynamicStyles.container}
				rel="noopener noreferrer"
				target="_blank"
				aria-label={postTitle}
				onClick={event => this.handleExternalLinkClick(event)}
			>
				{children}
			</a>
		)
	}
}

export default withRouter(
	connect(
		null,
		mapDispatchToProps
	)(PostLink)
)
