/**
 * Action types in External Feeds (Touchpoints)
 */
 export default {
	bookmark: 'Bookmark',
	comment: 'Comment',
	like: 'Like',
	overflowMenu: 'OverflowMenu',
	share: 'Share',
	userDirectory: 'UserDirectory',
}
