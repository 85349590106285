import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import CustomLink from 'scenes/CustomLinks/CustomLink'
import Icon, { Name } from 'components/Icons/FontAwesome'
import {
	Dropdown,
	DropdownTrigger,
	DropdownMenu,
	DropdownMenuAlignment,
} from 'components/Dropdown/Dropdown'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'

// Lang
import lang from 'scenes/CustomLinks/customLinks.lang'

// Enums
import CustomLinkLocations from './enums/customLinkLocations'

// region Styles
import { color, spacing, flexContainer, fontSize } from 'styles/variables'

const styles = {
	container: {
		paddingLeft: spacing.basic,
		paddingRight: spacing.basic,
		height: spacing.wide, // Firefox fix
	},
	dropdown: {
		background: color.bgContent,
	},
	link: {
		...flexContainer(),
		height: spacing.wide,
		paddingLeft: 0,
		paddingRight: 0,
	},
	button: {
		...flexContainer(),
		height: spacing.wide,
		cursor: 'pointer',
		fontSize: fontSize.small,
	},
}

//endregion

class CustomLinksDropdown extends Component {
	static propTypes = {
		location: PropTypes.string,
		links: PropTypes.array.isRequired,
		style: PropTypes.object,
	}

	render() {
		const { links, location, style: styleOverride } = this.props
		const isFooter = location === CustomLinkLocations.footer
		const dynamicStyles = {
			container: {
				...styles.container,
				...styleOverride,
			},
			dropdown: {
				...styles.dropdown,
				...styleOverride,
			},
			button: {
				...styles.button,
				...styleOverride,
			},
		}

		return (
			<div style={dynamicStyles.container}>
				<Dropdown>
					<DropdownTrigger>
						<div onClick={this.handleClick} style={dynamicStyles.button}>
							{lang.more}&nbsp;
							<Icon name={Name.caretDown} />
						</div>
					</DropdownTrigger>
					<DropdownMenu
						align={DropdownMenuAlignment.right}
						style={dynamicStyles.dropdown}
						dropdownOnTop={isFooter}
					>
						{links.map(link => (
							<div key={link.id} style={styles.link}>
								<CustomLink
									link={link}
									location={location}
									style={styleOverride}
									render={
										<RestrictedText limit={Restrictions.CustomLinks} text={link.name}>
											{text => text}
										</RestrictedText>
									}
								/>
							</div>
						))}
					</DropdownMenu>
				</Dropdown>
			</div>
		)
	}
}

export default CustomLinksDropdown
