const IpcEvent = {
	window: {
		show: 'desktop.show',
	},
	update: {
		messageCount: 'update-message-count',
		notificationCount: 'update-notification-count',
		customLinks: 'update-custom-links',
		session: 'update-session',
		settings: 'update-settings',
	},
	clickNotification: 'click-notification',
	persisted: {
		authRequest: 'persisted-auth-request',
		authResponse: 'persisted-auth-response',
	},
}

export default IpcEvent
