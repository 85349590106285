import tinycolor from 'tinycolor2'

import lightTheme from './light'
import darkTheme from './dark'
import darkBlueTheme from './darkBlue'
import hotdogTheme from './hotdog'
import { storage, buildStorageKey } from 'helpers/persistor'

const themeName = {
	dark: darkTheme.name,
	darkBlue: darkBlueTheme.name,
	light: lightTheme.name,
	hotdog: hotdogTheme.name,
}

export const themeStorageKey = buildStorageKey('theme')

export const getThemeFromStorage = () => {
	let loadedTheme = storage.getItem(themeStorageKey)
	if (!loadedTheme) {
		loadedTheme = themeName.light
		storage.setItem(themeStorageKey, themeName.light)
	}
	return getThemeByName(loadedTheme)
}

export const getThemeByName = name => {
	switch (name) {
		case themeName.light:
			return lightTheme
		case themeName.dark:
			return darkTheme
		case themeName.hotdog:
			return hotdogTheme
		case themeName.darkBlue:
			return darkBlueTheme
		default:
			return lightTheme
	}
}

export const isValidTheme = name => Object.values(themeName).includes(name)

export const getReadablePrimaryColor = primaryColor => {
	const backgroundColor = getThemeFromStorage().colors.bgContent
	const tinyPrimary = tinycolor(primaryColor)

	// If the primary color is legible, leave everything alone
	if (tinycolor.readability(backgroundColor, primaryColor) > 4) return tinyPrimary.toHexString()

	// Create array of darker and lighter colors
	const tinyArray = getColorSpectrum(primaryColor)

	// Then return the best possibility
	const output = tinycolor.mostReadable(backgroundColor, tinyArray).toHexString()

	return output
}

export default themeName

export const getColorSpectrum = primaryColor => {
	let brightness = 10
	const colorArray = []
	while (brightness <= 50) {
		colorArray.push(tinycolor(primaryColor).darken(brightness))
		colorArray.push(tinycolor(primaryColor).brighten(brightness))
		brightness += 10
	}
	return colorArray
}
