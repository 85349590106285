import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { regFlowSuccess, regFlowFailure, asyncGetCurrentUser } from './auth.reducer'
import { asyncGetCategories } from 'scenes/Category/category.reducer'
// import { SignInParam } from 'pages/SignIn'
import { padding, margin, border } from 'styles/variables'

// Lang
import lang from './RegFlow.lang'

// Helpers
import Routes from 'helpers/routes'
import { safeGetNestedProp, decodeQueryParams, encodeQueryParams } from '@dysi/js-helpers'

// Styles
import lightTheme from 'styles/themes/light'

const styles = {
	main: {
		width: '496px',
		backgroundColor: lightTheme.colors.white, // Override theme
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: padding.basic,
		paddingRight: padding.basic,
		borderTop: border.basic,
		borderBottom: border.basic,
		borderLeft: border.basic,
		borderRight: border.basic,
		borderRadius: border.radius,
		marginTop: padding.gutter,
		marginBottom: padding.gutter,
		marginLeft: 'auto',
		marginRight: 'auto',
		small: {
			width: '100%',
			borderLeft: 'none',
			borderRight: 'none',
			marginTop: margin.wide,
			marginBottom: margin.wide,
		},
	},
	iframe: {
		width: '100%',
	},
}

// Pull props from Redux store
const mapStateToProps = (state, ownProps) => {
	const host = safeGetNestedProp(state, 'sphere.host')
	const hostUrl = `https://${host}`
	const regAppOrigin = safeGetNestedProp(state, 'sphere.regAppOrigin')
	const home = safeGetNestedProp(state, 'sphere.home')
	const user = safeGetNestedProp(state, 'auth.user')
	const token = safeGetNestedProp(state, 'auth.token')
	const tokenExpiration = safeGetNestedProp(state, 'auth.tokenExpiration')
	const viewport = safeGetNestedProp(state, 'browser.viewport')
	const routing = safeGetNestedProp(state, 'routing.location')

	return {
		hostUrl,
		regAppOrigin,
		home,
		user,
		token,
		tokenExpiration,
		viewport,
		routing,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	onAuthenticated: result => {
		if (result && result.token) {
			dispatch(regFlowSuccess(result))
			dispatch(asyncGetCurrentUser()).then(() => {
				dispatch(asyncGetCategories()) // Categories are user-specific. Let's update them
			})
		} else if (result.returnUrl) {
			// Token already received (Join Now to Share flow, post share)
			// Just reload the page MO-272
			window.location.href = result.returnUrl
		}
	},
	onAuthFailed(result) {
		// TODO: Should we display a message??
		dispatch(regFlowFailure(result.error))

		if (result.returnUrl) {
			window.location.href = result.returnUrl
		} else {
			dispatch(asyncGetCategories()) // Categories are user-specific. User-auth failed, get sphere default instead
		}
	},
})

// Future: Support these parameters:
// 		userId
// 		email
// 		verificationCode
// 		returnUrl
// 		cssUrl
// 		actr
// 		actd

// Presentational Component
class RegFlow extends React.Component {
	static propTypes = {
		style: PropTypes.object,
		fromShareDialog: PropTypes.bool,
		joinNowToShare: PropTypes.bool,
		returnUrl: PropTypes.string,
		ssoReturnUrl: PropTypes.string,
		onSignUpClick: PropTypes.func,
		onSignInClick: PropTypes.func,

		// From Redux store
		hostUrl: PropTypes.string.isRequired,
		regAppOrigin: PropTypes.string,
		home: PropTypes.string.isRequired,
		user: PropTypes.object,
		token: PropTypes.string,
		tokenExpiration: PropTypes.string,
		onAuthenticated: PropTypes.func.isRequired,
		onAuthFailed: PropTypes.func.isRequired,
		viewport: PropTypes.object.isRequired,
		routing: PropTypes.object,
	}

	// For PostMessage flow
	messageOrigin = null
	messageSource = null
	iframe = null

	// Listen for messages from the RegFlow iframe
	onMessage = event => {
		const { onAuthenticated, onAuthFailed, onSignUpClick, onSignInClick } = this.props

		// A postMessage event received. Verify caller, handle request
		var msg = null
		if (!event || typeof event.data !== 'string') return
		if (event.origin !== this.messageOrigin) return
		if (event.source !== this.messageSource) return

		try {
			msg = JSON.parse(event.data)
		} catch (ex) {}
		if (!msg || !msg.msgId || !msg.sender || msg.sender !== 'RegFlow') return

		switch (msg.msgId) {
			case 'adjustHeight':
				// Inner iframe contents have changed. Adjust iframe to the new height
				if (msg.height && msg.height > 10) {
					this.iframe.height = msg.height
					this.iframe.style.height = msg.height
				}
				break

			case 'signUpClicked':
				// User clicked Sign Up from the Sign In page
				if (onSignUpClick) {
					onSignUpClick()
				}
				break

			case 'signInClicked':
				// User clicked Sign In from the Sign Up page
				if (onSignInClick) {
					onSignInClick()
				}
				break

			case 'userLogin':
				// User signed in with VoiceStorm SDK in RegFlow (but not here, yet! That's the authenticated event)

				// RegFlow records its own amplitude events.
				break

			case 'authenticated':
				// User completed the RegFlow
				// Expected: msg: { token, expiration, returnUrl }
				// No token means either user has already completed RegFlow or there was an error
				onAuthenticated(msg)
				break

			case 'authfailed':
				// Expected: msg: { error, returnUrl }
				onAuthFailed(msg)
				break

			default:
				break
		}
	}

	getRegFlowUrl = () => {
		const {
			fromShareDialog,
			joinNowToShare,
			returnUrl,
			ssoReturnUrl,
			home,
			user,
			token,
			tokenExpiration,
			hostUrl,
			routing,
		} = this.props

		// Tell RegFlow who we are (our origin)
		const origin =
			window.location.origin || `${window.location.protocol}//${window.location.hostname}` // Not all browsers support location.origin

		// Pull query params (if any) off the current URL
		const params = decodeQueryParams(routing && routing.search)
		let paramsToPassAlong = {}

		// Registration source tracking
		const regFlowQueryParams = params && params.returnUrl && params.returnUrl.split('?')[1]
		const regFlowSourceParams = regFlowQueryParams && decodeQueryParams(regFlowQueryParams)
		if (regFlowSourceParams) {
			if (regFlowSourceParams.actd) paramsToPassAlong.actd = regFlowSourceParams.actd
			if (regFlowSourceParams.actr) paramsToPassAlong.actr = regFlowSourceParams.actr
			if (regFlowSourceParams.channel) paramsToPassAlong.channel = regFlowSourceParams.channel
		}

		// SignIn page customization
		if (!fromShareDialog) {
			const signInReturnUrl = `${home}${Routes.SignIn.linkPath}`
			const returnUrlParam = returnUrl || signInReturnUrl

			paramsToPassAlong.returnUrl = returnUrlParam
		}
		// ShareDialog-specific customization
		else {
			// fromShareDialog && ssoReturnUrl
			if (ssoReturnUrl) {
				paramsToPassAlong.returnUrl = ssoReturnUrl
			}

			// fromShareDialog && uid (email share request)
			if (params && params.uid) {
				paramsToPassAlong.userId = params.uid
			}
		}

		// If user's signed in locally, tell RegFlow who it is
		if (user && user.email) {
			paramsToPassAlong.email = user.email
		}

		try {
			sessionStorage['regflow:setToken'] = token || ''
			sessionStorage['regflow:setExpiration'] = tokenExpiration || ''
		} catch (ex) {}

		// Package up all the query params to pass along to the regFlow
		paramsToPassAlong = {
			...paramsToPassAlong,
			origin,
			...params,
		}

		const authRoute = joinNowToShare ? 'Join' : 'Login'
		return `${hostUrl}/Auth/${authRoute}?${encodeQueryParams(paramsToPassAlong)}`
	}

	constructor(props) {
		super(props)

		// TA-24263: Build regFlowUrl initially, then add it to state
		const regFlowUrl = this.getRegFlowUrl()
		this.state = { regFlowUrl }
	}

	componentDidMount() {
		const { hostUrl, regAppOrigin } = this.props

		// Save source and origin to assure secure postMessage flow
		this.messageOrigin = regAppOrigin || hostUrl // Who we expect PostMessage to come from
		this.messageSource = this.iframe.contentWindow // Which window

		// Start listening for message events
		window.addEventListener('message', this.onMessage, false)
	}

	componentDidUpdate(prevProps, prevState) {
		// Did we just get a regAppOrigin?
		if (!prevProps.regAppOrigin && !!this.props.regAppOrigin) {
			this.messageOrigin = this.props.regAppOrigin // Who we expect PostMessage to come from
		}
	}

	render() {
		const { style, viewport } = this.props

		// TA-24263: Get regFlowUrl from state so it doesn't change and cause the iframe to reload
		const regFlowUrl = this.state.regFlowUrl ? this.state.regFlowUrl : this.getRegFlowUrl()
		const mainStyle = { ...styles.main, ...styles.main[viewport.size], ...style }

		return (
			<div style={mainStyle}>
				<iframe
					title={lang.title}
					aria-label={lang.title}
					ref={iframe => (this.iframe = iframe)}
					src={regFlowUrl}
					style={styles.iframe}
					height="600"
					allowFullScreen={true}
					scrolling="no"
					frameBorder="0"
				/>
			</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegFlow)
