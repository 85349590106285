import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// Helpers
import { vsApi } from 'helpers/apiHelpers'
import apiRequests from 'helpers/apiRequests'
import Routes from 'helpers/routes'

import * as NotifyHelper from 'helpers/notifications/notificationHelper'
import { isDesktopApp, isEdge, isIE } from 'helpers/browserhelper'
import { stringIsEmptyOrWhitespace, safeGetNestedProp } from '@dysi/js-helpers'
import { Settings } from 'wrappers/SettingsWrapper'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'

// Lang
import lang from './notifications.lang'

// Actions
import { asyncGetProviders } from 'reducers/sphere.reducer'
import { asyncGetBroadcast, setUrgentBroadcast } from 'scenes/Broadcast/broadcast.reducer'
import { relaySetUpgradeNotice } from 'reducers/browser.reducer'
import { relaySetCategoryUpdate } from 'scenes/Category/category.reducer'
import {
	relayGetConversation,
	relaySetUnreadConversationCount,
} from 'scenes/Messages/message.reducer'

import {
	asyncGetNotification,
	asyncGetNotifications,
	asyncGetNotificationCount,
	relaySetUnreadNotificationCount,
} from 'pages/Notifications/notification.reducer'
import { asyncGetNotificationsPreferences } from 'scenes/Settings/NotificationSettings/notificationSettings.reducer'
import { asyncGetPost, asyncGetLiveStreamStatus } from 'scenes/Post/post.reducer'
import { asyncGetSurvey } from 'scenes/Survey/survey.reducer'

// Enums
import BroadcastPriorities from 'scenes/Broadcast/enums/broadcastPriorities'
import IpcEvent from 'helpers/desktopApp/ipcEvent'
import LiveStreamState from 'scenes/Post/enums/liveStreamState'
import { safeGetSetting } from 'wrappers/Settings'
import NotificationTypes from 'scenes/Settings/NotificationSettings/enums/notificationTypes'
import NotificationEventTypes from './enums/notificationEventTypes'

// Redux
const mapStateToProps = (state, ownProps) => {
	const isUserLoggedIn = Settings.User.IsLoggedIn.value(state)
	const currentUserId = isUserLoggedIn && safeGetSetting(state, Settings.User.ID)
	const communityIcon = safeGetThemeValue(state, Theme.SquareLogoUrl)
	const currentConversationId = state.messages.currentConversationId
	const posts = state.post.posts
	const surveyAllIds = state.survey.surveyAllIds
	const preferences = safeGetNestedProp(state, 'setting.notificationSettings.preferences')

	return {
		currentUserId,
		isUserLoggedIn,
		communityIcon,
		currentConversationId,
		posts,
		surveyAllIds,
		preferences,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchRelayUrl: () =>
			dispatch(
				vsApi({
					endpoint: apiRequests.getRelayUrl.endpoint,
					options: { ignoreAuthFailure: true }, // Don't let relay auth failure cause app to reload
				})
			),
		fetchBroadcast: broadcastId => dispatch(asyncGetBroadcast(broadcastId)),
		fetchLiveStreamStart: postId => dispatch(asyncGetLiveStreamStatus(postId)),
		fetchPost: postId => dispatch(asyncGetPost(postId)),
		fetchSurvey: surveyId => dispatch(asyncGetSurvey(surveyId)),
		fetchNotificationCount: () => dispatch(asyncGetNotificationCount()),
		fetchNotifications: () => dispatch(asyncGetNotifications()),
		fetchNotificationPreferences: userId => dispatch(asyncGetNotificationsPreferences(userId)),
		fetchUserConversation: (conversationId, skip, take, markAsRead) =>
			dispatch(relayGetConversation(conversationId, skip, take, markAsRead)),
		fetchUserNotification: notificationId => dispatch(asyncGetNotification(notificationId)),
		setUnreadConversationCount: count => dispatch(relaySetUnreadConversationCount(count)),
		setUnreadNotificationCount: count => dispatch(relaySetUnreadNotificationCount(count)),
		setCategoryUpdate: categoryIds => dispatch(relaySetCategoryUpdate(categoryIds)),
		setUrgentBroadcast: broadcast => dispatch(setUrgentBroadcast(broadcast)),
		setUpgradeNotice: version => dispatch(relaySetUpgradeNotice(version)),
		getProviders: () => dispatch(asyncGetProviders()),
	}
}

const RelayEventType = {
	ConnectionClosed: 'ConnectionClosed',
	UnreadConversationCount: 'unreadconversationscount',
	UnreadNotificationsCount: 'unreadnotificationscount',
	NewConversationMessage: 'message',
	CategoryUpdate: 'categoryupdate',
	UserNotification: 'usernotification',
	// NewManagerMessage: 'managermessage',
	// managerunreadconversationscount: 'managerunreadconversationscount',
	LiveStreamStateChange: 'livestreamstatechange',
	UpgradeNotice: 'upgradenotice',
	UserChannelAuthRequired: 'userchannelauthrequired',
}

// const LivestreamState = {
// 	running: 'Running',
// }

// const ConnectionClosedReason = {
// 	TokenExpired: 'TokenExpired',
// 	TokenInvalidated: 'TokenInvalidated',
// }

class RelayListener extends React.Component {
	static propTypes = {
		communityIcon: Theme.SquareLogoUrl.propType,
		currentConversationId: PropTypes.string,
		currentUserId: Settings.User.ID.propType,
		isUserLoggedIn: Settings.User.IsLoggedIn.propType,
		fetchBroadcast: PropTypes.func.isRequired,
		fetchLiveStreamStart: PropTypes.func.isRequired,
		fetchNotificationCount: PropTypes.func.isRequired,
		fetchNotificationPreferences: PropTypes.func.isRequired,
		// fetchNotifications: PropTypes.func.isRequired,
		fetchRelayUrl: PropTypes.func.isRequired,
		fetchPost: PropTypes.func.isRequired,
		fetchSurvey: PropTypes.func.isRequired,
		fetchUserConversation: PropTypes.func.isRequired,
		fetchUserNotification: PropTypes.func.isRequired,
		history: PropTypes.object,
		posts: PropTypes.object,
		preferences: PropTypes.array,
		setUnreadConversationCount: PropTypes.func.isRequired,
		setUnreadNotificationCount: PropTypes.func.isRequired,
		surveyAllIds: PropTypes.array,
		setCategoryUpdate: PropTypes.func.isRequired,
		setUrgentBroadcast: PropTypes.func.isRequired,
		setUpgradeNotice: PropTypes.func.isRequired,
		getProviders: PropTypes.func.isRequired,
	}

	relayEventSource = null
	relayOrigin = null
	retryIntervals = [10000, 30000, 60000, 300000] // 10 seconds, 30 seconds, 1 minute, 5 minutes
	relayRetryIndex = 0

	// Whether we should verify the event origin. This is skipped for IE/Edge because their polyfill
	// does not return the origin property
	verifyOrigin = !isEdge && !isIE

	isValidOrigin = eventOrigin => this.verifyOrigin && eventOrigin !== this.relayOrigin

	connectToRelay = () => {
		const { fetchRelayUrl } = this.props

		// Get the Relay URL/token
		fetchRelayUrl()
			.then(({ url, token }) => {
				// Connect to DySi Relay
				this.relayEventSource = new EventSource(
					apiRequests.getRelayEventSource.getEndpoint(url, token)
				)

				if (this.verifyOrigin) {
					this.relayOrigin = new URL(url).origin // This breaks IE
				}

				this.relayRetryIndex = 0

				// Can we send browser notifications?
				NotifyHelper.RequestPermission()

				this.registerRelayEventListeners()
			})
			.catch(() => {
				// Retry in a bit
				const timeout = this.retryIntervals[this.relayRetryIndex]
				if (this.relayRetryIndex < this.retryIntervals.length - 1) {
					this.relayRetryIndex++
				}
				setTimeout(this.connectToRelay, timeout)
			})
	}

	registerRelayEventListeners = () => {
		// Unread Conversation Count
		this.relayEventSource.addEventListener(
			RelayEventType.UnreadConversationCount,
			this.handleUnreadConversationCount
		)

		this.relayEventSource.addEventListener(
			RelayEventType.UnreadNotificationsCount,
			this.handleUnreadNotificationsCount
		)

		// New Conversation Message
		this.relayEventSource.addEventListener(
			RelayEventType.NewConversationMessage,
			this.handleNewConversationMessage
		)

		// Category Update
		// this.relayEventSource.addEventListener(
		// 	RelayEventType.CategoryUpdate,
		// 	this.handleCategoryUpdate
		// )

		// UserNotifications
		this.relayEventSource.addEventListener(
			RelayEventType.UserNotification,
			this.handleUserNotification
		)

		// Connection Closed
		this.relayEventSource.addEventListener(
			RelayEventType.ConnectionClosed,
			this.handleConnectionClosed
		)

		// Livestream State Change
		this.relayEventSource.addEventListener(
			RelayEventType.LiveStreamStateChange,
			this.handleLiveStreamStateChange
		)

		// Only handle UpgradeNotice events when deployed, otherwise the page reload will just get index.html locally instead of from NMAS
		if (process.env.DS_ENV_IS_DEPLOYED) {
			// Upgrade Available
			this.relayEventSource.addEventListener(RelayEventType.UpgradeNotice, this.handleUpgradeNotice)
		}

		this.relayEventSource.addEventListener(
			RelayEventType.UserChannelAuthRequired,
			this.handleUserChannelAuthRequired
		)
	}

	componentDidMount() {
		const { isUserLoggedIn } = this.props

		// TODO - Maybe we should remove the Auth requirements on relay.
		if (!isUserLoggedIn) return

		this.init()
	}

	componentDidUpdate(prevProps) {
		const { isUserLoggedIn } = this.props

		if (isUserLoggedIn && isUserLoggedIn !== prevProps.isUserLoggedIn) {
			this.init()
		}
	}

	init = () => {
		const { currentUserId, fetchNotificationPreferences } = this.props

		this.connectToRelay()

		// Attempt to reconnect to Relay if the desktopApp is shown
		if (isDesktopApp) {
			fetchNotificationPreferences(currentUserId)
			window.ipcRenderer.on(IpcEvent.window.show, this.handleShowDesktopApp)
		}
	}

	handleShowDesktopApp = (event, message) => {
		const { isUserLoggedIn } = this.props
		if (!isUserLoggedIn) return

		// If the relay connection isn't open then reconnect
		if (this.relayEventSource && this.relayEventSource.readyState !== 1) this.connectToRelay()
	}

	componentWillUnmount() {
		const tempRelayEventSource = this.relayEventSource

		// Remove our reference to Relay and close the connection
		this.relayEventSource = null // Release reference and tell handleConnectionClosed not to attempt a reconnect
		if (tempRelayEventSource && typeof tempRelayEventSource.close === 'function')
			tempRelayEventSource.close()

		//
		if (isDesktopApp) {
			window.ipcRenderer.removeListener(IpcEvent.window.show, this.handleShowDesktopApp)
		}
	}

	//
	//region Event Listeners
	//

	handleNewConversationMessage = event => {
		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		const { communityIcon, currentConversationId, fetchUserConversation, history } = this.props
		// Verify we have good data
		const data = JSON.parse(event.data)
		if (!data) return

		const markAsRead = currentConversationId === data.conversationId
		fetchUserConversation(data.conversationId, 0, 100, markAsRead)

		const notificationTitle = !stringIsEmptyOrWhitespace(data.userDisplayName)
			? lang.newMessageFrom(data.userDisplayName)
			: lang.newMessage

		if (this.shouldShowNotification(NotificationTypes.chatDesktop)) {
			NotifyHelper.ShowNotification(
				notificationTitle,
				data.messageText,
				data.userProfilePictureSquare40Url || communityIcon,
				NotifyHelper.NavigateToPage(Routes.Messages.generateLinkPath(data.conversationId), history)
			)
		}
	}

	handleUnreadConversationCount = event => {
		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		const { setUnreadConversationCount } = this.props

		// Verify we have good data
		const data = JSON.parse(event.data)
		if (data && data.total !== null && !isNaN(data.total)) {
			// Dispatch action
			setUnreadConversationCount(data.total)

			if (isDesktopApp) {
				window.ipcRenderer.send(IpcEvent.update.messageCount, {
					count: data.total,
				})
			}
		}
	}

	handleUnreadNotificationsCount = event => {
		// Verify the origin
		if (event.origin !== this.relayOrigin) return

		const { setUnreadNotificationCount } = this.props

		// Verify we have good data
		const data = JSON.parse(event.data)
		if (data && data.total !== null && !isNaN(data.total)) {
			// Dispatch action
			setUnreadNotificationCount(data.total)
		}
	}

	handleCategoryUpdate = event => {
		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		const { setCategoryUpdate } = this.props
		const data = JSON.parse(event.data)

		if (!data) return

		setCategoryUpdate(data)
	}

	handleUserNotification = event => {
		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		const {
			communityIcon,
			fetchBroadcast,
			fetchPost,
			fetchSurvey,
			fetchNotificationCount,
			fetchUserNotification,
			history,
			setUrgentBroadcast,
			surveyAllIds,
			posts,
		} = this.props
		const data = JSON.parse(event.data)
		if (!data) return
		// Fetch count to update header
		fetchNotificationCount()

		const urgentBroadcastNotifications = data.filter(
			notification =>
				notification.type === NotificationEventTypes.articleBroadcast &&
				notification.priority === BroadcastPriorities.urgent
		)

		// Get urgent broadcasts and display urgent broadcast modal
		if (urgentBroadcastNotifications.length > 0) {
			urgentBroadcastNotifications.forEach(notification => {
				const { userNotificationId } = notification
				fetchUserNotification(userNotificationId).then(response => {
					const { broadcast } = response
					fetchBroadcast(broadcast.broadcastId).then(response => {
						// Fetch survey if needed
						if (response.surveyIds && !surveyAllIds.includes(response.surveyIds[0])) {
							fetchSurvey(response.surveyIds[0])
						}

						// Fetch post if needed
						if (response.posts && !posts[response.posts[0].postId]) {
							fetchPost(response.posts[0].postId)
						}

						// Set urgent broadcast modal
						if (notification.priority === BroadcastPriorities.urgent) {
							setUrgentBroadcast(response)
						}
					})
				})
			})
		}

		const showNotification = () => {
			NotifyHelper.ShowNotification(
				lang.newNotificationTitle,
				lang.newNotificationBody,
				communityIcon,
				NotifyHelper.NavigateToPage(Routes.Notifications.linkPath, history)
			)
		}

		if (isDesktopApp) {
			// Check desktop notification preferences
			const notification = data[0] // Get latest notification
			if (notification.type === NotificationEventTypes.articleBroadcast) {
				fetchUserNotification(notification.userNotificationId).then(response => {
					if (this.shouldShowUserNotification(notification, response)) {
						showNotification()
					}
				})
			} else {
				if (this.shouldShowUserNotification(notification)) {
					showNotification()
				}
			}
		} else {
			showNotification()
		}
	}

	handleConnectionClosed = event => {
		// TODO: Log ConnectionClosed event and reason?
		// const reason = event.data // ConnectionClosedReason

		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		// If we still care (i.e. this component hasn't unmounted), attempt a reconnect
		if (this.relayEventSource) {
			// Reset future retries to initial (shortest) retry interval
			this.relayRetryIndex = 0

			// Retry connection to Relay after a delay, to let cause of disconnect settle
			const timeout = this.retryIntervals[0]
			setTimeout(this.connectToRelay, timeout)
		}
	}

	handleLiveStreamStateChange = event => {
		const { communityIcon, fetchLiveStreamStart, history, fetchPost } = this.props
		const data = JSON.parse(event.data)

		if (data.state === LiveStreamState.running) {
			fetchPost(data.id).then(response => {
				if (response.postId) {
					fetchLiveStreamStart(response.postId).then(() => {
						// Display notification
						if (data.state === LiveStreamState.running) {
							NotifyHelper.ShowNotification(
								lang.newLivestreamTitle,
								lang.newLivestreamBody,
								communityIcon,
								NotifyHelper.NavigateToPage(
									Routes.Post.Details.generateLinkPath(false, data.id, true),
									history
								)
							)
						}
					})
				}
			})
		}
	}

	handleUpgradeNotice = event => {
		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		const { setUpgradeNotice } = this.props

		const data = JSON.parse(event.data)
		if (!data) return

		setUpgradeNotice(data.version)
	}

	handleUserChannelAuthRequired = event => {
		// Verify the origin
		if (this.isValidOrigin(event.origin)) return

		const { getProviders } = this.props

		getProviders()
	}

	shouldShowNotification = type => {
		if (!isDesktopApp) return true

		const { preferences } = this.props
		const preference = preferences.find(p => p.id === type)
		return preference && preference.enabled
	}

	shouldShowUserNotification = (notification, response) => {
		if (!isDesktopApp) return true

		switch (notification.type) {
			case NotificationEventTypes.articleBroadcast: {
				if (notification.priority === BroadcastPriorities.urgent) {
					return this.shouldShowNotification(NotificationTypes.urgentBroadcastDesktop)
				} else {
					const { broadcast } = response
					if (broadcast.survey) {
						return this.shouldShowNotification(NotificationTypes.broadcastSurveyDesktop)
					}
					if (broadcast.post) {
						return this.shouldShowNotification(NotificationTypes.articleBroadcastDesktop)
					}
					if (broadcast.newsletter) {
						return this.shouldShowNotification(NotificationTypes.newsletterBroadcastDesktop)
					}
					return this.shouldShowNotification(NotificationTypes.broadcastDesktop)
				}
			}
			case NotificationEventTypes.userMention: {
				return this.shouldShowNotification(NotificationTypes.userMentionDesktop)
			}
			case NotificationEventTypes.postApproval: {
				return this.shouldShowNotification(NotificationTypes.postApprovalDesktop)
			}
			case NotificationEventTypes.articleCommentLike: {
				return this.shouldShowNotification(NotificationTypes.articleCommentLikeDesktop)
			}
			case NotificationEventTypes.articleCommentReply: {
				return this.shouldShowNotification(NotificationTypes.articleCommentReplyDesktop)
			}
			default:
				return true
		}
	}

	//
	//endregion
	//
	render() {
		return null
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(RelayListener)
)
