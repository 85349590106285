import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { margin, color, flexContainer, fontWeight, width, fontSize } from 'styles/variables'
import { BreakpointNames } from 'wrappers/ResponsiveComponent'

const styles = {
	container: {
		...flexContainer('row', 'none'),
		backgroundColor: color.bgPage,
		minWidth: width.layout.sidebar,
		height: 200,
		paddingLeft: margin.basic,
		paddingRight: margin.basic,
		marginTop: margin.narrow,
		marginBottom: margin.narrow,
		marginLeft: -margin.wide,
		marginRight: -margin.wide,

		[BreakpointNames.small]: {
			flexDirection: 'column',
			height: '100%',
			marginLeft: -margin.basic,
			marginRight: -margin.basic,
		},
	},
	imageContainer: {
		maxWidth: width.layout.sidebar,
		maxHeight: 150,
		position: 'relative',

		[BreakpointNames.small]: {
			marginRight: 0,
			marginTop: margin.basic,
		},
	},
	textContainer: {
		marginLeft: margin.wide,
		[BreakpointNames.small]: {
			marginLeft: 0,
		},
		title: {
			fontSize: fontSize.title,
			fontWeight: fontWeight.bold,
			[BreakpointNames.small]: {
				textAlign: 'center',
				fontSize: fontSize.heading,
				marginLeft: margin.basic,
				marginRight: margin.basic,
			},
		},
		text: {
			[BreakpointNames.small]: {
				textAlign: 'center',
				marginRight: margin.basic,
				marginLeft: margin.basic,
				fontSize: fontSize.small,
			},
		},
	},
	blankImage: {
		backgroundColor: color.alwaysBlack,
		maxWidth: '100%',
		maxHeight: '100%',
	},
}

export default prefixer.prefix(styles)
