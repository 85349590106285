// ----------------------------------------------
// Calendar
// ----------------------------------------------
// Import like this:
//
//		import { Calendar } from 'components/Calendar'
//
// Props:
//		name            HTML field name
//		numberOfMonths  How many months to show per page. Default: 1
//		timeZoneId      IANA/API time zone id: "America/Los_Angeles"
//		startDate       First date to show in calendar, as an API date string: "2018-05-23T20:23:00.0000000Z"
//		endDate         Last date to show in calendar, as an API date string. Default is startDate + 1 year
//		selectedDate    Date selected in calendar, as an API date string
//		onDateChange    Event handler called when the selected date changes. API date string sent as first argument
//
// DayPicker props can also be used to further customize Calendar:
//		See http://react-day-picker.js.org/api/DayPicker
//
// Use like this:
//
//		<Calendar
//			name="shareDate"
//			numberOfMonths={2}
//			startDate={calendarStartDate}
//			endDate={calendarEndDate}
//			selectedDate={values.shareDate}
//			timeZoneId={timeZoneId}
//			onDateChange={date => this.handleScheduleDateChange(values, setFieldValue, date)}
//		/>
// ----------------------------------------------
import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Style } from 'radium'
import ReactDayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

// Components
import Icon, { Name } from 'components/Icons/FontAwesome'
import withTheme, { Theme, ThemeValuePropType } from 'wrappers/ThemeWrapper'

// Styles
import styles from './Calendar.styles'
import { color } from 'styles/variables'
const injectedThemeValues = [Theme.PrimaryColor]

class Calendar extends Component {
	static propTypes = {
		name: PropTypes.string,
		numberOfMonths: PropTypes.number,
		timeZoneId: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		selectedDate: PropTypes.string,
		onDateChange: PropTypes.func,

		// From Settings
		...ThemeValuePropType(injectedThemeValues),
	}

	// Mini-component for formatting Calendar days of the week
	Weekday = ({ weekday, className, localeUtils, locale }) => {
		const weekdayName = localeUtils.formatWeekdayLong(weekday, locale)
		return (
			<div className={className} title={weekdayName} aria-label={weekdayName}>
				{weekdayName.slice(0, 1)}
			</div>
		)
	}

	// Mini-component for formatting Calendar month name
	Month = ({ date, localeUtils, locale }) => {
		const monthTitle = moment(date)
			.locale(locale)
			.format('MMM YYYY')
		return (
			<div className="DayPicker-Caption">
				<div title={monthTitle} aria-label={monthTitle}>
					{monthTitle}
				</div>
			</div>
		)
	}

	// Mini-component for formatting Calendar top navigation
	Navbar = ({ showNextButton, showPreviousButton, onPreviousClick, onNextClick }) => {
		const { primaryColor } = this.props
		const iconStyle = {
			...styles.calendar.navbarIcon,
			color: primaryColor,
		}

		return (
			<div style={styles.calendar.navbar}>
				<span onClick={() => onPreviousClick()} style={iconStyle}>
					{showPreviousButton && <Icon name={Name.angleLeft} />}
				</span>
				<span onClick={() => onNextClick()} style={iconStyle}>
					{showNextButton && <Icon name={Name.angleRight} />}
				</span>
			</div>
		)
	}

	zeroPrefixedDay = (day, modifiers) => {
		return moment(day).format('DD')
	}

	apiDateToJsDate(startDate, timeZoneId) {
		const momentDate = timeZoneId ? moment.tz(startDate, timeZoneId) : moment(startDate)
		const jsDate = new Date(momentDate.year(), momentDate.month(), momentDate.date(), 0, 0)
		return jsDate
	}

	handleDayClick = (date, modifiers) => {
		if (modifiers.outside || modifiers.disabled) return // Ignore clicks on outside dates and disabled dates

		const { onDateChange, timeZoneId } = this.props
		if (onDateChange) {
			// Translate JavaScript date to API date in correct time zone
			const momentDate = timeZoneId
				? moment.tz(moment(date).format('YYYY-MM-DD'), timeZoneId)
				: moment(date)
			const newDate = momentDate.format()
			onDateChange(newDate)
		}
	}

	render() {
		const { startDate, endDate, selectedDate, timeZoneId, primaryColor, ...props } = this.props

		const dynamicStyles = {
			calendar: {
				...styles.calendar,
				selected: {
					...styles.calendar.selected,
					color: color.white,
					backgroundColor: primaryColor,
				},
			},
		}

		// Convert convenience props to DayPicker props
		const dayPickerProps = {}
		if (startDate) {
			// Translate API dates to JavaScript dates
			const dayPickerStartDate = this.apiDateToJsDate(startDate, timeZoneId)
			const dayPickerEndDate = endDate
				? this.apiDateToJsDate(endDate, timeZoneId)
				: new Date(
						dayPickerStartDate.getFullYear() + 1,
						dayPickerStartDate.getMonth(),
						dayPickerStartDate.getDate(),
						0,
						0
				  )

			// Translate start/end dates to DayPicker props
			dayPickerProps.fromMonth = dayPickerStartDate
			dayPickerProps.toMonth = dayPickerEndDate

			dayPickerProps.disabledDays = {
				before: dayPickerStartDate,
				after: dayPickerEndDate,
			}
		}
		if (selectedDate) {
			// Translate API date to JavaScript date
			const dayPickerSelectedDate = this.apiDateToJsDate(selectedDate, timeZoneId)

			// Add to DayPicker props
			dayPickerProps.initialMonth = dayPickerSelectedDate
			dayPickerProps.selectedDays = dayPickerSelectedDate
		}

		return (
			<Fragment>
				<Style rules={styles.calendarRules} />
				<ReactDayPicker
					fixedWeeks
					pagedNavigation
					renderDay={this.zeroPrefixedDay}
					weekdayElement={<this.Weekday />}
					captionElement={<this.Month />}
					navbarElement={<this.Navbar color={primaryColor} />}
					modifiersStyles={dynamicStyles.calendar}
					onDayClick={this.handleDayClick}
					{...dayPickerProps}
					{...props}
				/>
			</Fragment>
		)
	}
}

export default withTheme(Calendar, injectedThemeValues)
