import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Routes from 'helpers/routes'
import { Link } from 'react-router-dom'
import tinycolor from 'tinycolor2'

// Components
import Banner from './Banner'
import HtmlText from 'components/HtmlText/HtmlText'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'
import { isDesktopApp, isMobile } from 'helpers/browserhelper'
import { storage, buildStorageKey } from 'helpers/persistor'
import { safeGetThemeValue } from 'wrappers/Theme'
import { Theme } from 'wrappers/ThemeWrapper'

// Language
import lang from './downloadBanner.lang'

//region Styles
import { color, flexContainer, width } from 'styles/variables'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',

		color: color.primaryBlack,
		width: '100%',
		maxWidth: width.layout.page,
		...flexContainer('row', 'space-between'),
		small: {
			flexWrap: 'wrap',
		},
	},
	text: {
		width: '100%',

		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	link: {
		marginLeft: 3,
		fontWeight: 'bold',
	},
	button: {
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		small: {
			width: '100%',
			textAlign: 'right',
		},
	},
}
//endregion
const storageKey = buildStorageKey('downloadBannerShown')

const mapStateToProps = state => {
	const communityName = safeGetSetting(state, Settings.Community.Name)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const enableDownloadBanner = safeGetNestedProp(state, 'sphere.parameters.showDesktopAppLinks')
	const hasDownloadBannerDismissed = storage.getItem(storageKey)
	const viewport = state.browser.viewport

	return {
		communityName,
		enableDownloadBanner,
		hasDownloadBannerDismissed,
		primaryColor,
		viewport,
	}
}

class DownloadBanner extends Component {
	static propTypes = {
		communityName: Settings.Community.Name.propType,
		enableDownloadBanner: PropTypes.bool,
		hasDownloadBannerDismissed: PropTypes.bool,
		primaryColor: Theme.PrimaryColor.propType,
		viewport: PropTypes.object,
	}

	state = {
		showDownloadBanner: false,
	}

	componentDidMount() {
		this.showDownloadBannerIfNeeded()
	}

	showDownloadBannerIfNeeded = () => {
		const { hasDownloadBannerDismissed } = this.props

		// Only show banner if it hasn't been dismissed before
		if (!hasDownloadBannerDismissed) {
			this.setState({ showDownloadBanner: true })
		}
	}

	dismissDownloadBanner = () => {
		const { hasDownloadBannerDismissed } = this.props
		this.setState({ showDownloadBanner: false })
		if (!hasDownloadBannerDismissed) {
			const dismissedDate = new Date().toISOString()
			const data = JSON.stringify({ dismissedDate })
			storage.setItem(storageKey, data)
		}
	}

	render() {
		const { communityName, enableDownloadBanner, primaryColor, viewport } = this.props
		const { showDownloadBanner } = this.state

		if (isDesktopApp || isMobile || !enableDownloadBanner || !showDownloadBanner) return null

		const config = { ALLOWED_ATTR: ['target', 'href'] }

		let textColor = color.white
		if (tinycolor.readability(primaryColor, textColor) < 1.5) {
			textColor = color.primaryBlack
		}

		const dynamicStyles = {
			banner: {
				background: primaryColor,
			},
			container: {
				...styles.container,
				...styles.container[viewport.size],
				color: textColor,
			},
			link: {
				...styles.link,
				color: color.white,
			},
			button: {
				...styles.button,
				...styles.button[viewport.size],
			},
		}

		return (
			<Banner style={dynamicStyles.banner}>
				<div style={dynamicStyles.container}>
					<div style={styles.text}>
						<HtmlText config={config}>{lang.nameMessage(communityName)}</HtmlText>
						<Link
							style={dynamicStyles.link}
							to={Routes.Downloads.linkPath}
							onClick={this.dismissDownloadbanner}
						>
							{lang.downloadLink}
						</Link>
					</div>
					<div style={dynamicStyles.button} onClick={this.dismissDownloadBanner}>
						<Icon name={Name.times} />
					</div>
				</div>
			</Banner>
		)
	}
}

export default connect(mapStateToProps)(DownloadBanner)
