const lang = {
	betaLink: 'Experimental Features',
	bookmarks: 'Bookmarks',
	chooseLanguage: 'Choose a language',
	directory: 'Directory',
	done: 'Done',
	downloads: 'Downloads',
	invite: 'Invite',
	languages: 'Languages',
	manageCommunity: 'Manage Community',
	profileAndSettings: 'Profile and Settings',
	savedItems: 'Saved Items',
	settings: 'Settings',
	signIn: 'Sign In',
	signOut: 'Sign Out',
	signUp: 'Sign Up',
	memberHelp: 'Member Help',
	userProfileDefault: 'Your Profile', //  if User has no displayName
	userProfile: name => `${name}\'s Profile`,
	languageSelectionModal: selectedLanguage => `Language selection modal.Selected language:${selectedLanguage}`,
	select: name => `Select ${name}`,
}

export default lang;