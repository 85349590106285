/** Key names used for React SyntheticEvent key prop.
 * @see https://reactjs.org/docs/events.html#keyboard-events
 * @see https://www.w3.org/TR/uievents-key/#named-key-attribute-values for a list of valid key names.
 */
const KeyNames = {
	arrowDown: 'ArrowDown',
	arrowLeft: 'ArrowLeft',
	arrowRight: 'ArrowRight',
	arrowUp: 'ArrowUp',
	end: 'End',
	enter: 'Enter',
	escape: 'Escape', // Esc key
	home: 'Home',
	pageDown: 'PageDown',
	pageUp: 'PageUp',
	shift: 'Shift',
	space: ' ',
	tab: 'Tab',
}
KeyNames.arrowKeys = [KeyNames.arrowDown, KeyNames.arrowLeft, KeyNames.arrowRight, KeyNames.arrowUp]
export default KeyNames
