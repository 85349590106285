import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

// Components
import Button from 'components/Button/Button'
import Icon, { Name, Backdrop } from 'components/Icons/FontAwesome'
import ErrorIndicator from 'components/ErrorIndicator/ErrorIndicator'
import SocialIcon from 'components/SocialIcon/SocialIcon'
import Dialog from 'components/Dialog/Dialog'

// Lang
import lang from 'scenes/ShareDialog/shareDialog.lang'
import buttons from 'helpers/lang/buttons.lang'

// Helpers
import SocialProviderData from 'helpers/socialProviderData'
import { isDesktopApp } from 'helpers/browserhelper'

// Styles
import { width } from 'styles/variables'
import styles from './SharingOptionsBar.styles'

const NativeSharingParameters = {
	twitter: 'Twitter',
	facebook: 'Facebook',
	xing: 'Xing',
	linkedIn: 'LinkedIn',
}

// Component for Share Dialog Other Sharing Options, or for Enhanced Sharing Flow (see EE-5)
class SharingOptionsBar extends Component {
	static propTypes = {
		handleClose: PropTypes.func,
		getFacebookLink: PropTypes.func,
		getTwitterLink: PropTypes.func,
		sharePostId: PropTypes.number,
		sharePostActivity: PropTypes.any,
		sharePostDisclosures: PropTypes.any,
		facebookNativeConfig: PropTypes.object,
		twitterNativeConfig: PropTypes.object,
		nativeSharingHostUrl: PropTypes.string,
		getPost: PropTypes.func,
		isTwitterNativeSharingEnabled: PropTypes.bool,
		isFacebookNativeSharingEnabled: PropTypes.bool,
		isNativeVideo: PropTypes.bool,
		twitterShareTextSuggestion: PropTypes.string,
		getSharingError: PropTypes.func,
		primaryColor: PropTypes.string,

		// Share Dialog specifics
		getEmailLink: PropTypes.func,
		getCopyLink: PropTypes.func,
		showPostShareLinks: PropTypes.bool,
		enableShareByEmail: PropTypes.bool,
		xingNativeConfig: PropTypes.object,
		linkedInNativeConfig: PropTypes.object,
		isXingNativeSharingEnabled: PropTypes.bool,
		isLinkedInNativeSharingEnabled: PropTypes.bool,
		getLinkedInLink: PropTypes.func,
		getXingLink: PropTypes.func,
		isV8Enabled: PropTypes.bool,

		// Enhanced Share Flow specifics
		isEnhancedSharingFlowEnabled: PropTypes.bool,
		sharePoints: PropTypes.number,
		showFullActions: PropTypes.bool,
		userChannels: PropTypes.arrayOf(
			PropTypes.shape({
				canonicalName: PropTypes.string,
				alreadyConnected: PropTypes.bool,
				openShareDialog: PropTypes.func,
			})
		),
		openShareDialog: PropTypes.func,
		handleSharePost: PropTypes.func,
		isAnonymousUser: PropTypes.bool,
	}

	state = {
		otherActionsError: null,
		isEmailLoading: null,
		showCopyLinkDialog: null,
		showCopySuccess: null,
		copyLink: null, // This is transient; only set when requested
		copyLinkError: null, // This is transient; only set when requested
	}

	fbShareOrigin = window.location.protocol + '//' + window.location.host
	fbShareWindow = null
	fbNotifyTimeout = null
	fbNativeShareUrl = null

	twitterNotifyTimeout = null
	twitterNativeShareUrl = null
	twitterShareOrigin = this.fbShareOrigin
	twitterShareWindow = null

	xingShareOrigin = this.fbShareOrigin
	xingShareWindow = null
	xingNotifyTimeout = null
	xingNativeShareUrl = null

	linkedInShareOrigin = this.fbShareOrigin
	linkedInShareWindow = null
	linkedInNotifyTimeout = null
	linkedInNativeShareUrl = null

	componentDidMount() {
		window.addEventListener('message', this.handleMessageEvents)
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.handleMessageEvents)
	}

	//#region Handling Links (Copying and Email)
	handleCopyLinkClick = e => {
		e.preventDefault()

		const { getCopyLink, sharePostId, sharePostActivity, getPost, getSharingError } = this.props

		// Set loading...
		this.setState({
			copyLink: buttons.loading,
			copyLinkError: null,
			showCopySuccess: false,
			showCopyLinkDialog: true,
			otherActionsError: null,
		})

		// Call API to get copy link URL
		getCopyLink(sharePostId, sharePostActivity)
			.then(result => {
				// Update post information
				getPost(sharePostId)

				this.setState({
					copyLink: result.shortUrl || lang.copyLinkFailed,
					copyLinkError: null,
				})
			})
			.catch(error =>
				this.setState({
					copyLink: lang.copyLinkFailed,
					copyLinkError: getSharingError(error, lang.copyLinkFailed),
				})
			)
	}

	handleCopyLinkDialogClose = () => {
		this.setState({ showCopyLinkDialog: false })
	}

	copyLinkToClipboard = () => {
		try {
			this.copyInput.focus()
			document.execCommand('copy')
			this.setState({ showCopySuccess: true })
		} catch (e) {}
	}

	handleCopyInputFocus = event => {
		event.target.select()
	}
	handleEmailLinkClick = e => {
		e.preventDefault()

		// Set loading...
		this.setState({ isEmailLoading: true, otherActionsError: null })

		// Call API to get email link URL
		const { getEmailLink, getSharingError, sharePostId, sharePostActivity, getPost } = this.props
		getEmailLink(sharePostId, sharePostActivity)
			.then(result => {
				// Update post information
				getPost(sharePostId).then(() => {
					// Open email client
					window.location.href = result.emailUrl // mailto: link will open email client, rather than navigate
				})
			})
			.catch(error =>
				this.setState({ otherActionsError: getSharingError(error, lang.emailLinkFailed) })
			)
			.finally(() => this.setState({ isEmailLoading: false }))
	}
	//#endregion

	//#region Facebook and Twitter Native Sharing

	// Repeatedly send a notification to the Native Sharing popup window
	// (Until timeout is cancelled when it responds)
	shareWindowNotify = (msgId, shareType) => {
		switch (shareType) {
			case NativeSharingParameters.facebook:
				clearTimeout(this.fbNotifyTimeout)

				// Post message to NativeSharing window
				if (this.fbShareWindow) {
					this.fbShareWindow.postMessage(
						JSON.stringify({
							msgId: msgId,
							sender: 'ShareDialog',
							channel: SocialProviderData.FacebookNative.provider,
						}),
						this.fbShareOrigin
					)
				}
				// Try again
				this.fbNotifyTimeout = setTimeout(() => {
					this.shareWindowNotify(msgId, NativeSharingParameters.facebook)
				}, 250)
				break
			case NativeSharingParameters.xing:
				clearTimeout(this.xingNotifyTimeout)

				if (this.xingShareWindow) {
					this.xingShareWindow.postMessage(
						JSON.stringify({
							msgId: msgId,
							sender: 'ShareDialog',
							channel: SocialProviderData.Xing.provider,
						}),
						this.xingShareOrigin
					)
				}

				this.xingNotifyTimeout = setTimeout(() => {
					this.shareWindowNotify(msgId, NativeSharingParameters.xing)
				}, 250)
				break
			case NativeSharingParameters.twitter:
				clearTimeout(this.twitterNotifyTimeout)

				if (this.twitterShareWindow) {
					this.twitterShareWindow.postMessage(
						JSON.stringify({
							msgId: msgId,
							sender: 'ShareDialog',
							channel: SocialProviderData.TwitterNative.provider,
						}),
						this.twitterShareOrigin
					)
				}

				this.twitterNotifyTimeout = setTimeout(() => {
					this.shareWindowNotify(msgId, NativeSharingParameters.twitter)
				}, 250)
				break
			case NativeSharingParameters.linkedIn:
				clearTimeout(this.linkedInNotifyTimeout)

				if (this.linkedInShareWindow) {
					this.linkedInShareWindow.postMessage(
						JSON.stringify({
							msgId: msgId,
							sender: 'ShareDialog',
							channel: SocialProviderData.LinkedInNative.provider,
						}),
						this.linkedInShareOrigin
					)
				}

				this.linkedInNotifyTimeout = setTimeout(() => {
					this.shareWindowNotify(msgId, NativeSharingParameters.linkedIn)
				}, 250)
				break
			default:
				return null
		}
	}

	getMessageParams = channel => {
		switch (channel) {
			case SocialProviderData.FacebookNative.provider:
				return {
					notifyTimeout: this.fbNotifyTimeout,
					redirectUrl: this.fbNativeShareUrl,
					shareOrigin: this.fbShareOrigin,
					shareWindow: this.fbShareWindow,
				}
			case SocialProviderData.TwitterNative.provider:
				return {
					notifyTimeout: this.twitterNotifyTimeout,
					redirectUrl: this.twitterNativeShareUrl,
					shareOrigin: this.twitterShareOrigin,
					shareWindow: this.twitterShareWindow,
				}
			case SocialProviderData.Xing.provider:
				return {
					notifyTimeout: this.xingNotifyTimeout,
					redirectUrl: this.xingNativeShareUrl,
					shareOrigin: this.xingShareOrigin,
					shareWindow: this.xingShareWindow,
				}
			case SocialProviderData.LinkedInNative.provider:
				return {
					notifyTimeout: this.linkedInNotifyTimeout,
					redirectUrl: this.linkedInNativeShareUrl,
					shareOrigin: this.linkedInShareOrigin,
					shareWindow: this.linkedInShareWindow,
				}
			default:
				return null
		}
	}

	// postMessage event handler for both Facebook and Twitter
	handleMessageEvents = event => {
		// postMessage received. Verify caller & msg, then handle request
		let msg = null

		if (!event || typeof event.data !== 'string') return

		try {
			msg = JSON.parse(event.data)
		} catch (ex) {}

		if (!msg || !msg.msgId || !msg.sender || msg.sender !== 'NativeSharing') return

		// Get appropriate params/objects for this social channel
		const messageParams = this.getMessageParams(msg.channel)
		if (!messageParams) return
		if (event.origin !== messageParams.shareOrigin) return
		if (event.source !== messageParams.shareWindow) return

		// Handle the message
		switch (msg.msgId) {
			case 'loaded': {
				// NativeSharing window loaded

				// Stop checking status
				clearTimeout(messageParams.notifyTimeout)

				// Tell NativeSharing window to redirect to Sharing URL
				const redirectMsg = {
					msgId: 'redirect',
					redirectUrl: messageParams.redirectUrl,
					sender: 'ShareDialog',
				}
				messageParams.shareWindow.postMessage(
					JSON.stringify(redirectMsg),
					messageParams.shareOrigin
				)

				// We're done.
				this.props.handleClose()

				// Handled
				event.stopPropagation()
				event.preventDefault()
				return false
			}
			case 'closed': {
				// NativeSharing window closed

				// Stop trying to close
				clearTimeout(messageParams.notifyTimeout)

				// Handled
				event.stopPropagation()
				event.preventDefault()
				return false
			}

			default:
				break
		}
	}

	handleError = (error, shareType) => {
		const { getSharingError } = this.props
		let errorMessage = lang.errorUnknownShare
		switch (shareType) {
			case NativeSharingParameters.twitter: {
				errorMessage = lang.twitterNativeLinkFailed
				break
			}
			case NativeSharingParameters.facebook: {
				errorMessage = lang.facebookNativeLinkFailed
				break
			}
			case NativeSharingParameters.xing: {
				errorMessage = lang.xingNativeLinkFailed
				break
			}
			case NativeSharingParameters.linkedIn: {
				errorMessage = lang.linkedInNativeLinkFailed
				break
			}
			default:
				break
		}
		if (error) {
			this.setState({
				otherActionsError: getSharingError(error, errorMessage),
			})
		} else {
			this.setState({
				otherActionsError: errorMessage,
			})
		}
	}

	handleFacebookNativeShareClick = e => {
		e.preventDefault()

		const {
			getFacebookLink,
			sharePostId,
			sharePostActivity,
			sharePostDisclosures,
			facebookNativeConfig,
			nativeSharingHostUrl,
			getPost,
			isAnonymousUser,
			handleSharePost,
		} = this.props

		// Anonymous users + Enhanced Share Flow: redirect to Share Dialog
		if (isAnonymousUser) {
			handleSharePost()
		} else {
			// To share to Facebook in its own popup window:
			//
			// 1. Listen for PostMessage events from the popup window (In handleMessageEvents. Registered in componentDidMount)
			// 2. Open the popup window to our /Widget/NativeSharing page
			// 3. Get the Facebook Native sharing URL from the postshorten API call
			// 4. If the API returns a shortened sharing URL:
			//    a. Check the status of popup window. Repeat till popup responds with 'loaded'
			//    b. Once loaded, tell the popup to redirect to the Facebook sharing URL
			//    c. Close the ShareDialog
			// 5. If API fails, show error message here in ShareDialog

			// Open the pop-up window that will host the native Facebook share
			this.fbShareWindow = window.open(
				nativeSharingHostUrl,
				'FacebookNativeShare',
				'width=600,height=600,top=200,left=200,toolbar=0,menubar=0,centerscreen',
				true
			)

			// Reset any previous error
			this.setState({ otherActionsError: null })

			// Call API to get Facebook Native post sharing URL
			getFacebookLink(sharePostId, sharePostActivity)
				.then(result => {
					if (result.shortUrl) {
						// Build Facebook native share dialog URL. See https://developers.facebook.com/docs/sharing/reference/share-dialog#redirect
						this.fbNativeShareUrl = `https://www.facebook.com/dialog/share?display=popup&app_id=${
							facebookNativeConfig.appId
						}&href=${result.shortUrl}`

						// Add the Facebook Native sharing disclosure, if present
						const fbShareDisclosure = sharePostDisclosures.find(
							disclosure => disclosure.provider === SocialProviderData.FacebookNative.provider
						)
						if (fbShareDisclosure && fbShareDisclosure.shareDisclosureText) {
							this.fbNativeShareUrl += `&hashtag=${encodeURIComponent(
								fbShareDisclosure.shareDisclosureText
							)}`
						}

						// Update Post status
						getPost(sharePostId)

						// Start checking Native Sharing popup window. After it's loaded,
						// we'll pass it the native sharing URL
						this.shareWindowNotify('status', NativeSharingParameters.facebook)
					} else {
						// Unable to fetch native sharing URL from API
						// - Tell Native Sharing window to close
						// - Show error on ShareDialog
						this.shareWindowNotify('close', NativeSharingParameters.facebook)

						this.handleError(null, NativeSharingParameters.facebook)
					}
				})
				.catch(error => {
					// Unable to fetch native sharing URL from API
					// - Tell Native Sharing window to close
					// - Show error on ShareDialog
					this.shareWindowNotify('close', NativeSharingParameters.facebook)
					this.handleError(error, NativeSharingParameters.facebook)
				})
		}
	}

	buildTwitterNativeLink = () => {
		// Call API to get Twitter Native post sharing URL
		const {
			getTwitterLink,
			sharePostId,
			sharePostActivity,
			twitterShareTextSuggestion,
			sharePostDisclosures,
		} = this.props

		return new Promise((resolve, reject) => {
			// Get the TwitterNative share URL
			getTwitterLink(sharePostId, sharePostActivity)
				.then(result => {
					if (result.shortUrl) {
						// Build Twitter native share dialog URL. See https://developer.twitter.com/en/docs/twitter-for-websites/web-intents/overview.html
						this.twitterNativeShareUrl = `https://twitter.com/intent/tweet/?url=${encodeURIComponent(
							result.shortUrl
						)}`

						// Pre-filled share text will be a combination of Suggested Share Hashtags/Mentions and Disclosure
						// Suggested Share Hashtags/Mentions are the same as classic Twitter via API, but the Disclosure is separate
						let shareAndDisclosureText = twitterShareTextSuggestion || ''
						const twitterShareDisclosure = sharePostDisclosures.find(
							disclosure => disclosure.provider === SocialProviderData.TwitterNative.provider
						)

						if (twitterShareDisclosure && twitterShareDisclosure.shareDisclosureText) {
							shareAndDisclosureText += ` ${twitterShareDisclosure.shareDisclosureText}`
						}

						if (shareAndDisclosureText) {
							this.twitterNativeShareUrl += `&text=${encodeURIComponent(shareAndDisclosureText)}`
						}

						resolve()
					} else {
						reject()
					}
				})
				.catch(error => {
					reject(error)
				})
		})
	}

	handleTwitterNativeShareClick = e => {
		e.preventDefault()

		const {
			nativeSharingHostUrl,
			getPost,
			sharePostId,
			isAnonymousUser,
			handleSharePost,
		} = this.props // Reset any previous error

		// Anonymous users + Enhanced Share Flow: redirect to Share Dialog
		if (isAnonymousUser) {
			handleSharePost()
		} else {
			this.setState({ otherActionsError: null })
			const windowConfig = {
				target: 'TwitterNativeShare',
				features: 'width=600,height=600,top=200,left=200,toolbar=0,menubar=0,centerscreen',
				replace: true,
			}

			// To share to Twitter in its own popup window:
			//
			// 1. Listen for PostMessage events from the popup window (In handleMessageEvents. Registered in componentDidMount)
			// 2. Open the popup window to our /Widget/NativeSharing page
			// 3. Get the Twitter Native sharing URL from the postshorten API call
			// 4. If the API returns a shortened sharing URL:
			//    a. Check the status of popup window. Repeat till popup responds with 'loaded'
			//    b. Once loaded, tell the popup to redirect to the Twitter sharing URL
			//    c. Close the ShareDialog
			// 5. If API fails, show error message here in ShareDialog
			if (!isDesktopApp) {
				this.twitterShareWindow = window.open(
					nativeSharingHostUrl,
					windowConfig.target,
					windowConfig.features,
					windowConfig.replace
				)

				this.buildTwitterNativeLink()
					.then(() => {
						// Retrieve post information to update Post Actions and whatnot.
						getPost(sharePostId)

						// Start checking Native Sharing popup window. After it's loaded,
						// we'll pass it the native sharing URL
						this.shareWindowNotify('status', NativeSharingParameters.twitter)
					})
					.catch(error => {
						// Unable to fetch native sharing URL from API
						// - Tell Native Sharing window to close
						this.shareWindowNotify('close', NativeSharingParameters.twitter)
						this.handleError(error, NativeSharingParameters.twitter)
					})
			} else {
				// The desktop app can open the sharing url directly without having
				// to need to go through with /Widget/NativeSharing
				this.buildTwitterNativeLink()
					.then(() => {
						this.twitterShareWindow = window.open(
							this.twitterNativeShareUrl,
							windowConfig.target,
							windowConfig.features,
							windowConfig.replace
						)
						// Retrieve post information to update Post Actions, and then close the share dialog
						getPost(sharePostId).then(() => this.handleClose())
					})
					.catch(error => {
						// Unable to fetch native sharing URL from API
						this.handleError(error, NativeSharingParameters.twitter)
					})
			}
		}
	}

	handleXingNativeShareClick = e => {
		e.preventDefault()

		const {
			getXingLink,
			nativeSharingHostUrl,
			sharePostActivity,
			getPost,
			sharePostId,
			isAnonymousUser,
			handleSharePost,
		} = this.props // Reset any previous error

		// Anonymous users + Enhanced Share Flow: redirect to Share Dialog
		if (isAnonymousUser) {
			handleSharePost()
		} else {
			this.setState({ otherActionsError: null })
			this.xingShareWindow = window.open(
				nativeSharingHostUrl,
				'XingNativeShare',
				'width=600,height=600,top=200,left=200,toolbar=0,menubar=0,centerscreen',
				true
			)

			getXingLink(sharePostId, sharePostActivity)
				.then(result => {
					if (result.shortUrl) {
						// See: https://dev.xing.com/plugins/share_button/docs
						this.xingNativeShareUrl = `https://www.xing.com/spi/shares/new?url=${result.shortUrl}`
						getPost(sharePostId)
						this.shareWindowNotify('status', NativeSharingParameters.xing)
					} else {
						// Error in grabbing a native sharing user
						this.shareWindowNotify('close', NativeSharingParameters.xing)
						this.handleError(null, NativeSharingParameters.xing)
					}
				})
				.catch(error => {
					this.shareWindowNotify('close', NativeSharingParameters.xing)
					this.handleError(error, NativeSharingParameters.xing)
				})
		}
	}

	handleLinkedInNativeShareClick = e => {
		e.preventDefault()

		const {
			getLinkedInLink,
			linkedInNativeConfig,
			nativeSharingHostUrl,

			sharePostActivity,
			getPost,
			sharePostId,
			isAnonymousUser,
			handleSharePost,
		} = this.props // Reset any previous error

		// Anonymous users + Enhanced Share Flow: redirect to Share Dialog
		if (isAnonymousUser) {
			handleSharePost()
		} else {
			this.setState({ otherActionsError: null })

			this.linkedInShareWindow = window.open(
				nativeSharingHostUrl,
				'LinkedInNativeShare',
				'width=600,height=600,top=200,left=200,toolbar=0,menubar=0,centerscreen',
				true
			)

			getLinkedInLink(sharePostId, sharePostActivity)
				.then(result => {
					if (result.shortUrl) {
						// See: https://stackoverflow.com/questions/33426752/linkedin-share-post-url/33746543#33746543
						this.linkedInNativeShareUrl = linkedInNativeConfig.shareUrlBase + result.shortUrl
						getPost(sharePostId)
						this.shareWindowNotify('status', NativeSharingParameters.linkedIn)
					} else {
						// Error in grabbing a native sharing user
						this.shareWindowNotify('close', NativeSharingParameters.linkedIn)
						this.handleError(null, NativeSharingParameters.linkedIn)
					}
				})
				.catch(error => {
					this.shareWindowNotify('close', NativeSharingParameters.linkedIn)
					this.handleError(error, NativeSharingParameters.linkedIn)
				})
		}
	}

	//#endregion

	renderEnhancedShareFlowSharingOptions = () => {
		// Currently: Twitter and LinkedIn (non-native)
		const { userChannels } = this.props
		return userChannels.map(channel => (
			<span
				key={channel.canonicalName}
				style={styles.shareBarIcon.iconContainer}
				onClick={channel.alreadyConnected ? channel.openShareDialog : channel.addChannel}
			>
				<SocialIcon provider={channel.canonicalName} style={styles.shareBarIcon.icon} />
			</span>
		))
	}

	render() {
		const {
			facebookNativeConfig,
			isFacebookNativeSharingEnabled,
			twitterNativeConfig,
			isTwitterNativeSharingEnabled,
			xingNativeConfig,
			isXingNativeSharingEnabled,
			linkedInNativeConfig,
			isLinkedInNativeSharingEnabled,
			showPostShareLinks,
			sharePoints,
			isEnhancedSharingFlowEnabled,
			isNativeVideo,
			enableShareByEmail,
			openShareDialog,
			primaryColor,
			showFullActions,
			isV8Enabled,
		} = this.props
		const {
			copyLink,
			showCopyLinkDialog,
			copyLinkError,
			showCopySuccess,
			isEmailLoading,
			otherActionsError,
		} = this.state

		// Styles and Naming
		const facebookNativeDisplayName = facebookNativeConfig && facebookNativeConfig.displayName
		const twitterNativeDisplayName = twitterNativeConfig && twitterNativeConfig.displayName
		const xingNativeDisplayName = xingNativeConfig && xingNativeConfig.displayName
		const linkedInNativeDisplayName = linkedInNativeConfig && linkedInNativeConfig.displayName
		let copyCommandSupported = document.queryCommandSupported('copy')
		const showCopyButton = copyLink !== buttons.loading && !copyLinkError && copyCommandSupported

		const dynamicStyles = {
			emailLoading: {
				...styles.otherAction,
				justifyContent: 'center',
			},
			copyLinkSuccess: {
				visibility: showCopySuccess ? 'visible' : 'hidden',
			},
			sharePoints: {
				...styles.shareBarPoints.pointsText,
				...(primaryColor ? { color: primaryColor } : {}),
			},
			shareBarContainer: {
				...styles.shareBarContainer,
				...(!showFullActions ? { marginTop: 0 } : { marginTop: width.spacing.narrow }),
			},
			enhancedShareFlowFacebookIcon: {
				...styles.shareBarIcon.icon,
				color: SocialProviderData.FacebookNative.color,
			},
			otherAction: {
				...styles.otherAction,
				maxWidth: 42, // width of the icon
			},
			copyLinkText: {
				textAlign: 'center',
			},
		}

		return isEnhancedSharingFlowEnabled ? (
			// Enhanced Sharing Flow
			<div style={dynamicStyles.shareBarContainer}>
				{sharePoints ? (
					<div style={styles.shareBarPoints}>
						<span>{lang.earnPoints}</span>
						<span style={dynamicStyles.sharePoints}>{lang.points(sharePoints)}</span>
					</div>
				) : (
					<span>{lang.submitButton}</span>
				)}
				<div style={styles.shareBarIcon.container}>
					{this.renderEnhancedShareFlowSharingOptions()}
					{isFacebookNativeSharingEnabled && (
						<span
							style={styles.shareBarIcon.iconContainer}
							onClick={this.handleFacebookNativeShareClick}
						>
							<Icon
								style={dynamicStyles.enhancedShareFlowFacebookIcon}
								name={Name.facebook}
								backdrop={Backdrop.square}
							/>
						</span>
					)}
					{openShareDialog}
				</div>
			</div>
		) : (
			// Share Dialog: Other Sharing Options
			<Fragment>
				{!isV8Enabled && <h4 style={styles.otherActionsHeading}>{lang.otherSharingOptions}</h4>}
				<ErrorIndicator
					isOpen={!!otherActionsError}
					message={otherActionsError}
					style={styles.otherActionsError}
				/>
				<div style={styles.otherActions}>
					{isTwitterNativeSharingEnabled && (
						<div style={dynamicStyles.otherAction} onClick={this.handleTwitterNativeShareClick}>
							<Icon
								style={styles.twitterNativeIcon}
								name={Name.twitter}
								backdrop={Backdrop.square}
							/>
							<div>{twitterNativeDisplayName}</div>
						</div>
					)}
					{isLinkedInNativeSharingEnabled &&
						!isNativeVideo && (
							<div style={dynamicStyles.otherAction} onClick={this.handleLinkedInNativeShareClick}>
								<Icon
									style={styles.linkedInNativeIcon}
									name={Name.linkedin}
									backdrop={Backdrop.square}
								/>
								<div>{linkedInNativeDisplayName}</div>
							</div>
						)}
					{isFacebookNativeSharingEnabled && (
						<div style={dynamicStyles.otherAction} onClick={this.handleFacebookNativeShareClick}>
							<Icon
								style={styles.facebookNativeIcon}
								name={Name.facebook}
								backdrop={Backdrop.square}
							/>
							<div>{facebookNativeDisplayName}</div>
						</div>
					)}
					{/* If the post is a native video, then don't include XING (native video sharing can only be done via FB/Twitter */}
					{isXingNativeSharingEnabled &&
						!isNativeVideo && (
							<div style={dynamicStyles.otherAction} onClick={this.handleXingNativeShareClick}>
								<Icon style={styles.xingNativeIcon} name={Name.xing} backdrop={Backdrop.square} />
								<div>{xingNativeDisplayName}</div>
							</div>
						)}
					{showPostShareLinks && (
						<Fragment>
							<div style={dynamicStyles.otherAction} onClick={this.handleCopyLinkClick}>
								<Icon
									style={styles.otherActionIcon}
									name={Name.cutandpaste}
									backdrop={Backdrop.square}
								/>
								<div style={dynamicStyles.copyLinkText}>{lang.copyLink}</div>
							</div>
							<Dialog
								title={lang.copyLink}
								isOpen={showCopyLinkDialog}
								onClose={this.handleCopyLinkDialogClose}
							>
								<ErrorIndicator
									isOpen={!!copyLinkError}
									message={copyLinkError}
									style={styles.copyLinkError}
								/>
								<div style={styles.copyWrapper}>
									<input
										ref={input => {
											this.copyInput = input
										}}
										onFocus={this.handleCopyInputFocus}
										style={styles.copyInput}
										type="text"
										readOnly={true}
										value={copyLink}
									/>
									{showCopyButton && (
										<Button style={styles.copyButton} onClick={this.copyLinkToClipboard}>
											{buttons.copy}
										</Button>
									)}
								</div>
								{copyCommandSupported && (
									<div style={dynamicStyles.copyLinkSuccess}>
										<small>{lang.copyLinkSuccess}</small>
									</div>
								)}
							</Dialog>
						</Fragment>
					)}
					{enableShareByEmail && (
						<Fragment>
							<div style={styles.otherAction} onClick={this.handleEmailLinkClick}>
								<Icon style={styles.otherActionIcon} name={Name.email} backdrop={Backdrop.square} />
								<div>{lang.emailLink}</div>
							</div>
							{isEmailLoading && <div style={dynamicStyles.emailLoading}>{buttons.loading}</div>}
						</Fragment>
					)}
				</div>
			</Fragment>
		)
	}
}

export default SharingOptionsBar
