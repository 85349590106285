//region Action types
import {
	FETCH_APPROVED_CONTENT_REQUEST,
	FETCH_APPROVED_CONTENT_SUCCESS,
	FETCH_APPROVED_CONTENT_FAILURE,
} from 'scenes/Post/post.reducer'
import ApprovedPostData from 'scenes/Settings/ShareSettings/approvedPost.data'

export const CLEAR_POSTS = 'CLEAR_POSTS'
//endregion Action types

// Action Creators
export const clearPosts = () => {
	return {
		type: CLEAR_POSTS,
	}
}

//region Initial State
const initialSettings = {
	approvedPosts: null,
	isMoreContentRequestPending: false,
	areMorePostsAvailable: false,
	next: -1,
}

const initialErrors = {
	errors: [],
}

const initialState = { ...initialSettings, ...initialErrors }
//endregion

// Helpers
const mergeInNewApprovedPosts = (newPosts, state) => {
	const newPostData = newPosts.map(post => ApprovedPostData(post))

	// Add these posts to existing posts
	return state.approvedPosts ? state.approvedPosts.concat(newPostData) : newPostData
}

export default function approvedContentReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_APPROVED_CONTENT_REQUEST: {
			return {
				...state,
				isMoreContentRequestPending: true,
			}
		}
		case FETCH_APPROVED_CONTENT_SUCCESS: {
			const { posts: newPosts } = action.response

			const approvedPosts = mergeInNewApprovedPosts(newPosts, state)

			return {
				...state,
				approvedPosts,
				isMoreContentRequestPending: false,
				areMorePostsAvailable: action.response.next > 0 ? true : false,
				next: action.response.next,
			}
		}
		case FETCH_APPROVED_CONTENT_FAILURE: {
			return {
				...state,
				isMoreContentRequestPending: false,
			}
		}
		case CLEAR_POSTS: {
			return {
				...state,
				...initialState,
			}
		}
		default:
			return state
	}
}
