import {
	color,
	fontFamily,
	fontWeight,
	fontSize,
	lineHeight,
	border,
	spacing,
	form,
	margin,
} from 'styles/variables'
import { minBodyWidth } from 'styles/layout'
import { isSafariMobile, isIphone } from 'helpers/browserhelper'
import lightTheme from './themes/light'

// Base styles for the App
export const styleBase = (primaryColor, sideMenuOpen, backgroundColor) => {
	// NOTE: Do NOT nest rules because the Style tag does not know how to handle them.
	//
	// The example below will not work
	// 'a': {
	//    ':hover': { color: primaryBlack }
	//    color: color.primaryBlack,
	// },
	//
	// Instead, it needs to be like this:
	// 'a': { color: color.primaryBlack },
	// 'a:hover': { color: primaryColor },
	//

	const htmlConditional = {
		...(sideMenuOpen
			? {
					overflowX: 'hidden', // Just in case
					overflowY: 'hidden',
			  }
			: {
					overflowX: 'hidden', // Just in case
					overflowY: 'hidden',
					// overflowX: 'inherit', // Just in case
					// overflowY: 'inherit',
			  }),
	}

	const bodyConditional = {
		...(sideMenuOpen
			? {
					overflowY: 'hidden',
			  }
			: {
					overflowY: 'scroll',
					overflowScrolling: 'touch',
					WebkitOverflowScrolling: 'touch',
			  }),
		// Only modify this for Safari on iPhones
		...(isSafariMobile && isIphone
			? {
					height: 'calc(100vh - 112px)',
			  }
			: {
					height: '100vh',
			  }),
	}

	return {
		'*': {
			flexShrink: 1,
		},
		html: {
			...htmlConditional,
			// overflowX: 'hidden', // Just in case
			// overflowY: 'hidden',
		},
		body: {
			// height: '100vh',
			// ...bodyConditional,
			fontFamily: fontFamily.base,
			fontSize: fontSize.base,
			fontWeight: fontWeight.normal,
			lineHeight: lineHeight.base,
			color: color.primaryBlack,
			backgroundColor: backgroundColor,
			marginTop: 0,
			marginBottom: 0,
			marginRight: 0,
			marginLeft: 0,
			padding: 0,
			minWidth: minBodyWidth,
		},
		'#root': {
			...bodyConditional,
			marginTop: 0,
			marginBottom: 0,
			marginRight: 0,
			marginLeft: 0,
			padding: 0,
			minWidth: minBodyWidth,
		},
		// This is necessary because the default style provider doesn't vendor prefix sticky positioning
		'.sticky': {
			position: ['sticky', '-webkit-sticky', '-moz-sticky', '-ms-sticky', '-o-sticky'],
		},
		h1: {
			fontSize: fontSize.jumbo,
			lineHeight: lineHeight.jumbo,
			fontWeight: fontWeight.light,
		},
		h2: {
			fontSize: fontSize.title,
			fontWeight: fontWeight.semiBold,
		},
		h3: {
			fontSize: fontSize.heading,
			fontWeight: fontWeight.semiBold,
		},
		h4: {
			fontSize: fontSize.heading,
			fontWeight: fontWeight.light,
			textTransform: 'uppercase' /* All CAPS */,
			color: color.primaryGrey,
		},
		a: {
			fontSize: 'inherit',
			color: color.primaryBlack,
			textDecoration: 'none',
		},
		'a:hover': { color: color.primaryBlack },
		'a:focus': { color: color.primaryBlack },
		'a:active': { color: color.primaryBlack },
		'a.ac-anchor': {
			color: primaryColor,
		},
		'.ac-selectable': {
			cursor: 'pointer',
		},
		textarea: {
			color: lightTheme.colors.primaryBlack,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 5,
			paddingBottom: 5,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="text"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="date"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="password"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="number"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="time"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="url"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="email"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type="tel"]': {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		select: {
			...form.input,
			color: lightTheme.colors.primaryBlack,
			lineHeight: lineHeight.input,
			border: border.basic,
			borderRadius: border.radius,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
			marginTop: spacing.narrow,
			marginBottom: spacing.narrow,
			marginRight: 0,
			marginLeft: 0,
		},
		'input[type=date]::-webkit-inner-spin-button': {
			marginTop: 'auto',
			marginBottom: 'auto',
		},
		'input[type=time]::-webkit-inner-spin-button': {
			marginTop: 'auto',
			marginBottom: 'auto',
		},
		'input.large-text': {
			fontSize: fontSize.heading,
		},
		'input::placeholder': {
			color: color.secondaryGrey,
		},
		'textarea::placeholder': {
			color: color.secondaryGrey,
		},
		'input::-webkit-input-placeholder': {
			color: color.secondaryGrey,
		},
		'input:-moz-placeholder': {
			/* Firefox 18- */
			color: color.secondaryGrey,
		},
		'input::-moz-placeholder': {
			/* Firefox 19+ */
			color: color.secondaryGrey,
		},
		'input:-ms-input-placeholder': {
			color: color.secondaryGrey,
		},
		'textarea::-webkit-input-placeholder': {
			color: color.secondaryGrey,
		},
		'textarea:-moz-placeholder': {
			/* Firefox 18- */
			color: color.secondaryGrey,
		},
		'textarea::-moz-placeholder': {
			/* Firefox 19+ */
			color: color.secondaryGrey,
		},
		'textarea:-ms-input-placeholder': {
			color: color.secondaryGrey,
		},
		'.public-DraftEditorPlaceholder-root': {
			/* DraftJS text placeholder */
			color: color.secondaryGrey,
		},
		'form input': {
			display: 'block',
		},
		'form .button-row': {
			textAlign: 'right',
		},
		'.help-block': {
			display: 'block',
		},
		small: {
			fontSize: fontSize.small,
		},
		'small.gray': {
			color: color.primaryGrey,
		},
		'small.help': {
			color: color.secondaryGrey,
		},
		'small.light': {
			fontWeight: fontWeight.light,
		},
		'*.disabled': {
			color: color.secondaryGrey,
		},
		'*[disabled]': {
			color: color.secondaryGrey,
		},
		p: {
			marginTop: margin.narrow,
			marginBottom: margin.narrow,
		},
		'table.no-border': {
			border: 'none !important',
		},
		'.snowflake': {
			color: color.white,
			fontSize: '1em',
			fontFamily: 'Arial, sans-serif',
			textShadow: '0 0 5px #000',
		},
		'.hover-trigger > .hover-target': {
			visibility: 'hidden',
			opacity: 0,
			transition: 'opacity 200ms, visibility 200ms',
		},
		'.hover-trigger:hover > .hover-target': {
			visibility: 'visible',
			opacity: 1,
			transition: 'opacity 200ms, visibility 200ms',
		},
	}
}
