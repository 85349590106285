import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Styles
import { color, margin } from 'styles/variables'

// Lang
import lang from './survey.lang'

// Components
import Button from 'components/Button/Button'

// Constants
import Routes from 'helpers/routes'
import TextButton from 'components/TextButton/TextButton'

export const SurveyStatus = {
	finished: 'Finished',
}

//#region Styles
const styles = {
	container: {
		background: color.white,
		height: '100%',
		marginTop: margin.basic,
		marginBottom: margin.basic,
		textAlign: 'center',
	},
	button: {
		marginTop: margin.basic,
		marginBottom: margin.basic,
	},
}
//#endregion

class SurveyComplete extends Component {
	static propTypes = {
		handleClick: PropTypes.func,
		survey: PropTypes.object,
	}

	render() {
		const { survey, handleClick } = this.props
		const isEditable = survey.settings && survey.settings.isEditableAfterComplete
		const surveyFinished = survey.status === SurveyStatus.finished

		if (!survey) return null

		return (
			<div style={styles.container}>
				<h1>{surveyFinished ? lang.surveyEndedResponse : lang.surveyCompleteResponse}</h1>

				{isEditable &&
					!surveyFinished && (
						<div style={styles.button}>
							<TextButton text={lang.editResponse} handleClick={handleClick} />
						</div>
					)}

				<div style={styles.button}>
					<Button href={Routes.Home.linkPath}>{lang.backToHome}</Button>
				</div>
			</div>
		)
	}
}

export default SurveyComplete
