import { connect } from 'react-redux'

// Convenience for implementation-sake. Make Themes object also available from here
import importTheme, { safeGetThemeValue } from './Theme'
export const Theme = importTheme

//region Enhanced MapStateToProps
const wrappedMapStateToProps = themeSettings => (state, ownProps) => {
	// Perform safe retrieval for all of the passed in theme values and then apply them to the new props object
	const injectedThemeValues = themeSettings.reduce((memo, themeSetting) => {
		// Don't bother if someone passed in garbage
		if (!themeSetting || !themeSetting.name) return memo

		// Is setting overridden by props?
		const propName = themeSetting.name
		let propValue
		if (ownProps.hasOwnProperty(propName)) {
			propValue = ownProps[propName] // Use setting value from props
		} else {
			propValue = safeGetThemeValue(state, themeSetting) // Use our fancy helper to get the value
		}

		// Add the retrieved setting to the new props
		return {
			...memo,
			[propName]: propValue,
		}
	}, {})

	// Return the new settings prop with all of the injected settings
	return injectedThemeValues
}
//endregion

/**
 * Convenience wrapper that will safely inject theme props into a component
 *
 * @param {any} WrappedComponent - Component that needs settings
 * @param {Array<any>} themeSettings - Array of setting configurations that tell the wrapper how to retrieve the values
 */
function withTheme(WrappedComponent, themeSettings = []) {
	return connect(wrappedMapStateToProps(themeSettings))(WrappedComponent)
}

/**
 * Convenience function that will build your propType definitions for injected theme values.
 * @param {Array<any>} themeSettings - Array of theme values that need propType definitions
 */
export const ThemeValuePropType = themeSettings => {
	const propTypeShape = themeSettings.reduce((obj, themeSetting) => {
		if (!themeSetting) return obj

		return {
			...obj,
			[themeSetting.name]: themeSetting.propType,
		}
	}, {})
	return propTypeShape
}

// Make it available to everyone else
export default withTheme
