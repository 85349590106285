export default {
	feed: {
		maxWidth: 320,
	},
	column: {
		maxWidth: 600,
	},
	details: {
		maxHeight: 640,
		maxWidth: 950,
	},
	showcase: {
		width: 840,
		singleColumnWidth: 500,
		media: {
			maxHeight: 290,
		},
	},
	dialog: {
		maxHeight: 640,
		maxWidth: 950,
	},
}
