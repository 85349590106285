import lang from './numberHelpers.lang'

// TODO - Add "maxDigits" param to prevent overflowing UI
/**
 * Takes a number and converts it to the appropriate abbreviation
 * @param {number} number
 * @param {number} decimals
 */
export const FormatNumber = (number, decimals = 1) => {
	const suffixes = Object.values(lang.suffixes).sort((a, b) => a.value - b.value)

	for (let i = suffixes.length - 1; i >= 0; i--) {
		if (number >= suffixes[i].value) {
			const final = (number / suffixes[i].value).toFixed(decimals)
			// I18N - Add decimal formatter
			return suffixes[i].format(Number(final))
		}
	}
	return `${number}`
}
