import { vsApi, requests } from 'helpers/apiHelpers'
import ContentData from 'scenes/Content/helpers/content.data'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below

const FETCH_NEWSLETTER_REQUEST = 'FETCH_NEWSLETTER_REQUEST'
const FETCH_NEWSLETTER_SUCCESS = 'FETCH_NEWSLETTER_SUCCESS'
const FETCH_NEWSLETTER_FAILURE = 'FETCH_NEWSLETTER_FAILURE'

const CLEAR_NEWSLETTER = 'CLEAR_NEWSLETTER'
// Action Creators

// Async Action Creators
export const asyncGetNewsletter = (newsletterId, broadcastId) => {
	return vsApi({
		endpoint: requests.getNewsletterSections.getEndpoint(newsletterId, broadcastId),
		actions: {
			request: FETCH_NEWSLETTER_REQUEST,
			success: FETCH_NEWSLETTER_SUCCESS,
			failure: FETCH_NEWSLETTER_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const clearNewsletter = () => {
	return {
		type: CLEAR_NEWSLETTER,
	}
}

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

// --- Initial State ---
const initialNewsletter = {
	newslettersById: {},
}
// State will be mapped to the Redux store by combineReducers
const initialState = {
	...initialNewsletter,
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function newsletterReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_NEWSLETTER_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case FETCH_NEWSLETTER_SUCCESS: {
			const newsletter = ContentData(action.response)
			return {
				...state,
				isLoading: false,
				newslettersById: {
					[newsletter.id]: newsletter,
				},
			}
		}
		case FETCH_NEWSLETTER_FAILURE: {
			return {
				...state,
				isLoading: true,
			}
		}
		default:
			return state
	}
}
