import { vsApi, requests } from 'helpers/apiHelpers'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below
const ACKNOWLEDGE_BROADCAST = 'ACKNOWLEDGE_BROADCAST'
const FETCH_BROADCAST_REQUEST = 'FETCH_BROADCAST_REQUEST'
export const FETCH_BROADCAST_SUCCESS = 'FETCH_BROADCAST_SUCCESS'
const FETCH_BROADCAST_FAILURE = 'FETCH_BROADCAST_FAILURE'
const CLEAR_BROADCAST = 'CLEAR_BROADCAST'
const SET_URGENT_BROADCAST = 'SET_URGENT_BROADCAST'
const CLEAR_URGENT_BROADCAST = 'CLEAR_URGENT_BROADCAST'

// Action Creators
export const setUrgentBroadcast = broadcast => {
	return {
		type: SET_URGENT_BROADCAST,
		broadcast,
	}
}

export const clearUrgentBroadcast = broadcastId => {
	return {
		type: CLEAR_URGENT_BROADCAST,
		broadcastId,
	}
}

// Async Action Creators
export const asyncGetBroadcast = broadcastId => {
	return vsApi({
		endpoint: requests.getBroadcast.getEndpoint(broadcastId),
		actions: {
			request: FETCH_BROADCAST_REQUEST,
			success: FETCH_BROADCAST_SUCCESS,
			failure: FETCH_BROADCAST_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncViewBroadcast = (broadcastId, userId) => {
	return vsApi({
		endpoint: requests.viewBroadcast.getEndpoint(broadcastId),
		requestOptions: userId
			? requests.viewBroadcast.getRequestOptions(userId)
			: requests.viewBroadcast.requestOptions,
	})
}

export const asyncAcknowledgeBroadcast = broadcastId => {
	const date = new Date().toISOString()
	return vsApi({
		endpoint: requests.acknowledgeBroadcast.getEndpoint(broadcastId),
		actions: {
			success: ACKNOWLEDGE_BROADCAST,
		},
		requestOptions: requests.acknowledgeBroadcast.requestOptions,
		context: {
			broadcastId,
			date,
		},
	})
}

export const clearBroadcast = () => {
	return {
		type: CLEAR_BROADCAST,
	}
}

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

// --- Initial State ---
const initialBroadcast = {
	broadcastAllIds: [],
	broadcastById: {},
	urgentBroadcastAllIds: [],
	urgentBroadcastById: {},
}
// State will be mapped to the Redux store by combineReducers
const initialState = {
	...initialBroadcast,
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function broadcastReducer(state = initialState, action) {
	switch (action.type) {
		case ACKNOWLEDGE_BROADCAST: {
			const broadcastId = action.context.broadcastId
			const readConfirmationDate = action.context.date
			return {
				...state,
				broadcastById: {
					...state.broadcastById,
					[broadcastId]: {
						...state.broadcastById[broadcastId],
						readConfirmationDate,
					},
				},
			}
		}
		case FETCH_BROADCAST_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case FETCH_BROADCAST_SUCCESS: {
			const postId =
				action.response.posts && action.response.posts[0] && action.response.posts[0].postId
			const broadcast = {
				...action.response,
				postId,
			}
			if (broadcast.posts) delete broadcast.posts

			return {
				...state,
				isLoading: false,
				broadcastAllIds: [...state.broadcastAllIds, broadcast.id],
				broadcastById: {
					...state.broadcastById,
					[broadcast.id]: {
						...broadcast,
					},
				},
			}
		}
		case FETCH_BROADCAST_FAILURE: {
			return {
				...state,
				isLoading: true,
			}
		}
		case CLEAR_BROADCAST: {
			return {
				...initialBroadcast,
			}
		}
		case SET_URGENT_BROADCAST: {
			const postId =
				action.broadcast.posts && action.broadcast.posts[0] && action.broadcast.posts[0].postId
			const broadcast = {
				...action.broadcast,
				postId,
			}
			if (broadcast.posts) delete broadcast.posts

			return {
				...state,
				urgentBroadcastAllIds: [...state.urgentBroadcastAllIds, broadcast.id],
				urgentBroadcastById: {
					...state.urgentBroadcastById,
					[broadcast.id]: {
						...broadcast,
					},
				},
			}
		}
		case CLEAR_URGENT_BROADCAST: {
			const urgentBroadcastById = { ...state.urgentBroadcastById }
			delete urgentBroadcastById[action.broadcastId]
			const urgentBroadcastAllIds = state.urgentBroadcastAllIds.filter(
				id => id !== action.broadcastId
			)

			return {
				...state,
				urgentBroadcastAllIds,
				urgentBroadcastById,
			}
		}
		default:
			return state
	}
}
