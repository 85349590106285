/** PostTranslationData - Original and translated properties of a Post
 * @param {PostData} - A Post
 * @param {object} - Response from POST post/{id}/translate request
 */
import clone from 'lodash/clone'
import SocialProviderData from 'helpers/socialProviderData'
import { isRtlLanguage } from 'helpers/rtlLanguageHelper'

export const getTranslatedDescription = (post, postTranslation) => {
	// YouTube is the only social provider that is allowed to have a description
	if (post.isSocialPost && post.provider !== SocialProviderData.YouTube.provider) return null

	return postTranslation.translated.description
}

export const getTranslatedDescriptionWithEntities = (post, postTranslation) => {
	// YouTube is the only social provider that is allowed to have a description
	if (post.isSocialPost && post.provider !== SocialProviderData.YouTube.provider) return null

	return postTranslation.translated.descriptionWithEntities
}

const PostTranslationData = (post, postTranslateResponse) => {
	const useLinkTitle = post.isLinkedPost && post.provider !== SocialProviderData.Twitter.provider	

	return {
		// Fetch state
		isPending: false,
		isError: false,

		// Translation direction
		fromLang: postTranslateResponse.fromLang,
		toLang: postTranslateResponse.toLang,

		// Copies of original Post properties (to revert to)
		original: {
			title: clone(post.title),
			titleWithEntities: clone(post.titleWithEntities),
			description: clone(post.description),
			descriptionWithEntities: clone(post.descriptionWithEntities),
			content: clone(post.content),
			suggestedShareTextList: clone(post.suggestedShareTextList),
			tagline: clone(post.tagline),
			isRtlLanguage: isRtlLanguage(postTranslateResponse.fromLang),
		},

		// Same properties, but translated from original language in Post to user's display language
		translated: {
			title:
				(useLinkTitle && postTranslateResponse.linkTitle) || postTranslateResponse.title || null,
			titleWithEntities: postTranslateResponse.titleWithEntities,
			description: postTranslateResponse.description || null,
			descriptionWithEntities: postTranslateResponse.descriptionWithEntities || null,
			content: postTranslateResponse.content || null,
			suggestedShareTextList: postTranslateResponse.suggestedShareTextList || [],
			tagline: postTranslateResponse.tagline || null,
			isRtlLanguage: isRtlLanguage(postTranslateResponse.toLang),
		},
	}
}
export const PostTranslationPending = () => ({
	isPending: true,
	isError: false,
})
export const PostTranslationError = () => ({
	isPending: false,
	isError: true,
})

export default PostTranslationData
