import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { spacing, width, border } from 'styles/variables'
import { breakpoints } from 'helpers/breakpoints'

export const minBodyWidth = 320

// Page layout styles
export const styleLayout = prefixer.prefix({
	body: {
		minWidth: minBodyWidth,
	},

	// Container of everything specific to a page
	page: {
		display: 'flex',
		flex: '1 1 auto',
		// flex: 1,
		// maxWidth: width.layout.page,
		width: '100%',
		alignSelf: 'center',
		justifyContent: 'center',
	},

	// Main content area for the page
	main: {
		flexGrow: 1,
		flexShrink: 1,
		maxWidth: width.layout.mainNoSidebar,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		minHeight: 200,
		overflow: 'hidden',

		paddingTop: spacing.basic,
		paddingBottom: 0,
		paddingRight: spacing.basic,
		paddingLeft: spacing.basic,

		[breakpoints.small.name]: {
			// Remove the panel padding on small devices so post feeds look nice on small mobile devices
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: 0,
			paddingLeft: 0,
			width: '1vw', // Added for SocialHeader title
		},

		// Sidebar adjustments
		withSidebar: {
			maxWidth: width.layout.main,
			flexBasis: width.layout.main,
		},
	},

	/** Panels live inside the main. It will adjust to varying screen sizes */
	panel: {
		maxWidth: width.layout.main,
		// width: '100%',
		marginBottom: spacing.basic,

		// Sidebar adjustments
		// withSidebar: {
		// 	maxWidth: width.layout.main,
		// },
	},

	// This is the entire sidebar area. It will grow and accommodate different screen sizes.
	sidebar: {
		// Flex
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: width.layout.sidebar,
		display: 'flex',
		justifyContent: 'flex-end', // Attach to the right side
		alignItems: 'flex-start', // Don't stretch beyond contents
		maxWidth: width.layout.sidebar,

		[breakpoints.large.name]: {
			maxWidth: 250,
			flexBasis: 250,
			minWidth: 250,
		},
	},

	// This is the container(s) inside a sidebar. It restricts width and adjusts itself within the sidebar based on screen size.
	sidebarSection: {
		// Flex
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 'auto',
		maxWidth: width.layout.sidebarSection,

		// Spacing
		paddingTop: spacing.basic,
		paddingBottom: spacing.basic,
		paddingLeft: spacing.basic,
		paddingRight: spacing.narrow,

		minWidth: 0,

		// Added to fix IE 11 sidebar not shrinking
		width: '100%',
	},

	/** This is a common page heading style that allows for consistent spacing and borders across different pages. */
	pageHeading: {},

	/** This is a common footer area below a form, generally containing action button/link elements to save the form (and other actions like cancel). */
	formFooter: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	container: {
		width: '100%',
		pageDialog: {
			borderTop: border.basic,
			borderBottom: border.basic,
			borderRight: border.basic,
			borderLeft: border.basic,
			[breakpoints.small.name]: {
				borderLeft: 'none',
				borderRight: 'none',
			},
		},
	},
})
