const lang = {
	PossibleDelayMessage: 'It may take a few minutes for your share to appear on your chosen platform.',
	PointsEarnedMessage: points => `You\'ve earned ${points} points.`,
	FailedReason: {
		SharingCapped: 'I\'m sorry, you are unable to share this post as you have reached your sharing limit for today.',
		AlreadyShared: 'I\'m sorry, you are unable to share this post as you have already shared it today.',
		Default: 'Oops! Something went wrong when trying to share this post',
	},
}

export default lang;