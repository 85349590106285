import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import Button, { ButtonLevel } from 'components/Button/Button'

// Helpers
import { Settings } from 'wrappers/SettingsWrapper'
import { safeGetSetting } from 'wrappers/Settings'

// Lang
import lang from 'scenes/SubmitPost/submitPost.lang'

// Actions
import { toggleSideMenu, toggleSubmitPost, toggleSubmitPostManager } from 'reducers/browser.reducer'

const mapStateToProps = (state, ownProps) => {
	const sideMenuOpen = state.browser.sideMenuOpen
	const canEditPost = safeGetSetting(state, Settings.User.CanEditPost)

	return {
		sideMenuOpen,
		canEditPost,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		toggleSubmitPost: () => dispatch(toggleSubmitPost()),
		toggleSubmitPostManager: () => dispatch(toggleSubmitPostManager()),
		toggleSideMenu: () => dispatch(toggleSideMenu()),
	}
}

class SubmitPost extends Component {
	static propTypes = {
		sideMenuOpen: PropTypes.bool.isRequired,
		toggleSideMenu: PropTypes.func.isRequired,
		toggleSubmitPost: PropTypes.func.isRequired,
		toggleSubmitPostManager: PropTypes.func.isRequired,
		canEditPost: PropTypes.bool.isRequired,
	}

	handleClick = () => {
		const {
			sideMenuOpen,
			toggleSubmitPost,
			toggleSubmitPostManager,
			toggleSideMenu,
			canEditPost,
		} = this.props

		if (sideMenuOpen) toggleSideMenu()
		canEditPost ? toggleSubmitPostManager() : toggleSubmitPost()
	}

	render() {
		return (
			<Button level={ButtonLevel.secondary} onClick={this.handleClick} blurOnClick={true}>
				{lang.submitPost}
			</Button>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubmitPost)
