/**
 * Layout enum determining the style in which to render the post.
 */
export default {
	feed: 'feed',
	column: 'column',
	showcase: 'showcase',
	details: 'details',
	dialog: 'dialog',
	messaging: 'messaging',
}
