import { Component } from 'react'
import PropTypes from 'prop-types'

// Helpers
import { connect } from 'react-redux'
import { isLocalStorageAvailable } from '@dysi/js-helpers'
import { storageKeys } from 'helpers/persistor'
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'
import { safeGetNestedProp } from '@dysi/js-helpers'

// Actions
import { setCategoryDiscoveryFirstLoad } from './category.reducer'

const mapStateToProps = state => {
	return {
		userIsLoggedIn: safeGetSetting(state, Settings.User.IsLoggedIn),
		currentUserId: safeGetNestedProp(state, 'auth.user.userId'),
		enableCategoryDiscovery: safeGetNestedProp(state, 'sphere.parameters.enableCategoryDiscovery'),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setCategoryDiscoveryFirstLoad: isFirstLoad =>
			dispatch(setCategoryDiscoveryFirstLoad(isFirstLoad)),
	}
}

/**
 * Sets a flag to prevent the new post (red dot) indicator
 * being shown when Category Discovery is first enabled.
 */
class CategoryDiscovery extends Component {
	static propTypes = {
		userIsLoggedIn: PropTypes.bool,
		currentUserId: PropTypes.number,
		setCategoryDiscoveryFirstLoad: PropTypes.bool,
		enableCategoryDiscovery: PropTypes.bool,
	}

	componentDidMount() {
		if (!this.props.enableCategoryDiscovery || !this.props.userIsLoggedIn) {
			return
		}

		this.handleSetCategoryDiscoveryFirstLoad()
	}

	handleSetCategoryDiscoveryFirstLoad = () => {
		const { currentUserId } = this.props

		if (!isLocalStorageAvailable()) {
			return
		}

		const firstLoadKey = storageKeys.categoryDiscovery.firstLoad(currentUserId)
		const isCategoryDiscoveryFirstLoad = localStorage.getItem(firstLoadKey)

		if (isCategoryDiscoveryFirstLoad === null) {
			localStorage.setItem(firstLoadKey, true)
			this.props.setCategoryDiscoveryFirstLoad(true)

			const firstTimeKey = storageKeys.categoryDiscovery.firstTime(currentUserId)
			localStorage.setItem(firstTimeKey, JSON.stringify(Date.now()))
			return
		}
		// Setting to false for clarity
		localStorage.setItem(firstLoadKey, false)
	}

	render() {
		return null
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CategoryDiscovery)
