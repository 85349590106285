import React from 'react'
import PropTypes from 'prop-types'
import { fontSize, fontWeight, spacing, textButtonStyle } from 'styles/variables'
import Icon from 'components/Icons/FontAwesome'
import withTheme, { Theme, ThemeValuePropType } from 'wrappers/ThemeWrapper'

//#region Styles
const styles = {
	container: {
		display: 'inline-flex',
		alignItems: 'center',
		fontWeight: fontWeight.semiBold,
		fontSize: fontSize.heading,
		...textButtonStyle,
		icon: {
			fontSize: spacing.narrow,
		},
	},
}
//#endregion

const TextButton = props => {
	const { handleClick, text, iconName, primaryColor, style: styleOverride } = props
	const containerStyle = {
		...styles.container,
		color: primaryColor,
		...styleOverride,
	}
	return (
		<button style={containerStyle} onClick={handleClick}>
			{iconName && (
				<span style={styles.container.icon}>
					<Icon name={iconName} color={primaryColor} />&nbsp;
				</span>
			)}
			<span>{text}</span>
		</button>
	)
}

const injectedThemeValues = [Theme.PrimaryColor]

TextButton.propTypes = {
	handleClick: PropTypes.func,
	text: PropTypes.string,
	iconName: PropTypes.string,
	...ThemeValuePropType(injectedThemeValues),
}

export default withTheme(TextButton, injectedThemeValues)
