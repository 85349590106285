//
// NOTE: THIS FILE NEEDS TO MATCH sphereState.data.js INSIDE THE NMAS!!!
//

import { safeGetNestedProp } from '@dysi/js-helpers'

export const MobileAppDownloadMode = {
	Custom: 'Custom',
	Default: 'Default',
	None: 'None',
}

export const DesktopAppStatus = {
	Disabled: 'Disabled',
	Beta: 'Beta',
	Stable: 'Stable',
}

export const DesktopAppDownloadMode = {
	Disabled: 'Disabled',
	LinkOnly: 'LinkOnly',
	DownloadsPage: 'DownloadsPage',
}

export const FeedLayoutOptions = {
	Grid: 'Grid',
	Column: 'Column',
	Row: 'Row',
}

export const DysiAppLinks = {
	ios: 'https://itunes.apple.com/us/app/dynamic-signal/id608659964?mt=8',
	android: 'https://play.google.com/store/apps/details?id=com.dynamicsignal.androidphone',
	mac: '/member/downloads/mac', // TODO - Change this with /member removal!!!
	windows: '/member/downloads/windows', // TODO - Change this with /member removal!!!
}

export const processApiTimezones = apiTimezones => {
	return {
		communityTimeZone: apiTimezones.communityTimeZone,
		communityTimeZoneIanaName: apiTimezones.communityTimeZoneIanaName,
		timeZones: apiTimezones.timeZones.reduce((timeZoneMap, timeZone) => {
			timeZoneMap[timeZone.id] = timeZone
			return timeZoneMap
		}, {}),
	}
}

// OBSOLETE: api should be result from v1/internal/community/sphereinfo
export const SphereDataFromSphereInfo = api => {
	//#region Mobile Apps

	// iOS Mobile App Cleanup
	const iosAppDownloadMode = safeGetNestedProp(
		api,
		'parameters.iosAppDownloadMode',
		MobileAppDownloadMode.None
	)
	const iosAppDownloadUrl =
		iosAppDownloadMode !== MobileAppDownloadMode.None ? api.parameters.iosAppDownloadUrl : null

	// Android Mobile App Cleanup
	const androidAppDownloadMode = safeGetNestedProp(
		api,
		'parameters.androidAppDownloadMode',
		MobileAppDownloadMode.None
	)
	const androidAppDownloadUrl =
		androidAppDownloadMode !== MobileAppDownloadMode.None
			? api.parameters.androidAppDownloadUrl
			: null

	// This is a sphere parameter received from the API
	const enableMobileApps = safeGetNestedProp(api, 'parameters.enableMobileApps', false)

	const showMobileAppLinks = !!(enableMobileApps && (iosAppDownloadUrl || androidAppDownloadUrl))

	const communityRegistrationCode =
		showMobileAppLinks &&
		(iosAppDownloadMode === MobileAppDownloadMode.Default ||
			androidAppDownloadMode === MobileAppDownloadMode.Default)
			? api.communityRegistrationCode
			: null

	//#endregion

	//#region DesktopApp

	const desktopAppStatus = safeGetNestedProp(
		api,
		'parameters.desktopAppConfig.desktopApplicationStatus',
		DesktopAppStatus.Disabled
	)
	const desktopAppDownloadMode = safeGetNestedProp(
		api,
		'parameters.desktopAppConfig.desktopApplicationDownload',
		DesktopAppDownloadMode.Disabled
	)
	const showDesktopAppLinks =
		desktopAppDownloadMode === DesktopAppDownloadMode.DownloadsPage &&
		desktopAppStatus !== DesktopAppStatus.Disabled

	//#endregion

	const sphereData = {
		// Id
		sphereId: api.sphereId,

		// Basics
		communityName: api.translatedCommunityName,
		communityRegistrationCode: communityRegistrationCode,
		regAppOrigin: api.regAppOrigin || null,

		// Theme
		theme: {
			primaryColor: api.communityTheme.primaryColor,
			backgroundColor: api.communityTheme.backgroundColor,
			squareLogoUrl: api.communityTheme.squareLogoUrl,
			coverImageUrl: safeGetNestedProp(api, 'communityTheme.coverImage.coverImageUrl'),
			mobileStyledLogoImageUrl: safeGetNestedProp(
				api,
				'communityTheme.mobileStyledLogoImage.Original.url'
			),
		},

		// Security Settings
		security: {
			passwordComplexity: safeGetNestedProp(api, 'passwordComplexity'),
		},

		// Sphere Parameters
		// NOTE - ALPHABETICAL PLEASE
		// NOTE: If we continue to keep adding native config sphere params, let's try to work w/Shane API-wise and redux-wise to keep them in their own sub property.
		parameters: {
			allowAnonymousNewsFeedAccess: api.parameters.allowAnonymousNewsFeedAccess,
			allowImageSubmission: api.parameters.allowImageSubmission,
			allowUsersToCategorizePosts: api.parameters.allowUsersToCategorizePosts,
			allowUsersToTagPosts: api.parameters.allowUsersToTagPosts,
			androidAppDownloadUrl: androidAppDownloadUrl,
			androidAppDownloadMode: androidAppDownloadMode,
			androidAppId: api.parameters.androidAppId,
			articleRoutingBaseUrl: api.parameters.articleRoutingBaseUrl,
			defaultFeedLayout: safeGetNestedProp(
				api,
				'parameters.defaultFeedLayout',
				FeedLayoutOptions.Grid
			),
			documentAttachmentMaxFileSizeInMb: api.parameters.documentAttachmentMaxFileSizeInMb,
			documentAttachmentMaxFileUploadCount: api.parameters.documentAttachmentMaxFileUploadCount,
			durationOfMessagesToStoreInDays: api.parameters.durationOfMessagesToStoreInDays,
			enableAlternateShareText: api.parameters.enableAlternateShareText,
			enableCategoryDiscovery: api.parameters.enableCategoryDiscovery,
			enableCustomPages: api.parameters.enableCustomPages,
			enableDivisions: api.parameters.enableDivisions,
			enableDocumentAttachments: api.parameters.enableDocumentAttachments,
			enableEditProfileImage: api.parameters.enableEditProfileImage,
			enableEnhancedMentionSupport: api.parameters.enableEnhancedMentionSupport,
			enableEnhancedSharingFlow: api.parameters.enhancedSharingFlow,
			enableEmailForUsernameUsers: api.parameters.enableEmailForUsernameUsers,
			enableEmailSignOn: api.parameters.enableEmailSignOn,
			enableFullProfiles: api.parameters.enableFullProfiles,
			enableLanguageTargeting: api.parameters.enableLanguageTargeting,
			enableLeaderboards: api.parameters.enableLeaderboards,
			enableMemberEditName: api.parameters.enableMemberEditName,
			enableMemberPhoneNumber: api.parameters.enableMemberPhoneNumber,
			enableMemberToMemberMessages: api.parameters.enableMemberToMemberMessages,
			enableMessages: api.parameters.enableMessages,
			enableMobileApps: api.parameters.enableMobileApps,
			enableMultiImagePostSharing: api.parameters.enableMultiImagePostSharing,
			enableMyContentCategory: api.parameters.enableMyContentCategory,
			enableNewMemberInvitations: api.parameters.enableNewMemberInvitations,
			enableOrganizationalHierarchy: api.parameters.enableOrganizationalHierarchy,
			enablePendo: api.parameters.enablePendo,
			enableShareByEmail: api.parameters.enableShareByEmail,
			enableShareDialogV8: api.parameters.enableShareDialogV8,
			enableSharingStatsCsvDownload: api.parameters.enableSharingStatsCsvDownload,
			enableSocialConnectionRenewal: api.parameters.enableSocialConnectionRenewal,
			enableSms: api.parameters.enableSms,
			enableSso: api.parameters.enableSso,
			enableSuggestedShareText: api.parameters.enableSuggestedShareText,
			enableUserDirectory: api.parameters.enableUserDirectory,
			enableUsernameSignOn: api.parameters.enableUsernameSignOn,
			enableWelcomeBanner: api.parameters.enableWelcomeBanner,
			enabledProcessors: api.parameters.enabledProcessors,
			enhancedApiSecurity: api.parameters.enhancedApiSecurity,
			facebookNativeConfig: api.parameters.facebookNativeConfig,
			featuredContentCategoryId: api.parameters.featuredContentCategoryId,
			feedLayoutOptions: safeGetNestedProp(api, 'parameters.feedLayoutOptions', [
				FeedLayoutOptions.Grid,
			]),
			googleAnalyticsId: api.parameters.googleAnalyticsId,
			iosAppDownloadUrl: iosAppDownloadUrl,
			iosAppDownloadMode: iosAppDownloadMode,
			iosAppId: api.parameters.iosAppId,
			isScheduledSharingEnabled: api.parameters.isScheduledSharingEnabled,
			landingPageCategoryLabel: api.parameters.landingPageCategoryLabel,
			linkedInNativeConfig: api.parameters.linkedInNativeConfig,
			maximumVideoFileSize: api.parameters.maximumVideoFileSize,
			memberCanEditDivisionsAfterOnboarding: api.parameters.memberCanEditDivisionsAfterOnboarding,
			requireSmsPinVerification: api.parameters.requireSmsPinVerification,
			shareDialogVersions: api.parameters.shareDialogVersions,
			sharingMaxChannelsPerShare: api.parameters.sharingMaxChannelsPerShare,
			showDesktopAppLinks: showDesktopAppLinks,
			showMobileAppLinks: showMobileAppLinks,
			showPostShareLinks: api.parameters.showPostShareLinks,
			showShareButtonOnSocialPosts: api.parameters.shareButtonSocialPosts,
			ssoLogoutUrl: api.parameters.ssoLogoutUrl,
			twitterNativeConfig: api.parameters.twitterNativeConfig,
			usePersistentAuthentication: api.parameters.usePersistentAuthentication,
			xingNativeConfig: api.parameters.xingNativeConfig,
		},

		// Localized sphere info
		localizations: {
			postGuidelineText: api.parameters.postGuidelineText,
			postSubmissionConfirmation: api.parameters.postSubmissionConfirmation,
			welcomeBannerMessage: api.parameters.welcomeBannerMessage,
			welcomeBannerButtonText: api.parameters.welcomeBannerButtonText,
			contentLanguagePrompt: api.languageInfo.contentLanguagePrompt,
		},

		...(process.env.DS_ENV_IS_DEPLOYED
			? {
					// Providers
					providers: api.providers,
					// Languages
					languageInfo: api.languageInfo,
					// TimeZone
					timeZoneInfo: api.timeZoneInfo,
			  }
			: null),
	}

	return sphereData
}

// api should be result from v1/community
const SphereData = api => {
	//#region Mobile Apps

	// iOS Mobile App Cleanup
	const iosAppDownloadMode = safeGetNestedProp(
		api,
		'info.mobileApps.ios.downloadMode',
		MobileAppDownloadMode.None
	)
	const iosAppDownloadUrl =
		iosAppDownloadMode !== MobileAppDownloadMode.None ? api.info.mobileApps.ios.downloadUrl : null
	const iosAppId =
		iosAppDownloadMode !== MobileAppDownloadMode.None ? api.info.mobileApps.ios.appId : null

	// Android Mobile App Cleanup
	const androidAppDownloadMode = safeGetNestedProp(
		api,
		'info.mobileApps.android.downloadMode',
		MobileAppDownloadMode.None
	)
	const androidAppDownloadUrl =
		androidAppDownloadMode !== MobileAppDownloadMode.None
			? api.info.mobileApps.android.downloadUrl
			: null
	const androidAppId =
		androidAppDownloadMode !== MobileAppDownloadMode.None ? api.info.mobileApps.android.appId : null

	// This is a sphere parameter received from the API
	const enableMobileApps = safeGetNestedProp(api, 'settings.enableMobileApps', false)

	const showMobileAppLinks = !!(enableMobileApps && (iosAppDownloadUrl || androidAppDownloadUrl))

	const communityRegistrationCode =
		showMobileAppLinks &&
		(iosAppDownloadMode === MobileAppDownloadMode.Default ||
			androidAppDownloadMode === MobileAppDownloadMode.Default)
			? safeGetNestedProp(api, 'info.registrationCode', '')
			: null

	//#endregion

	//#region DesktopApp

	const desktopAppStatus = safeGetNestedProp(
		api,
		'info.desktopApp.desktopApplicationStatus',
		DesktopAppStatus.Disabled
	)
	const desktopAppDownloadMode = safeGetNestedProp(
		api,
		'info.desktopApp.desktopApplicationDownload',
		DesktopAppDownloadMode.Disabled
	)
	const showDesktopAppLinks =
		desktopAppDownloadMode === DesktopAppDownloadMode.DownloadsPage &&
		desktopAppStatus !== DesktopAppStatus.Disabled

	//#endregion

	//#region Other Sharing Options

	const otherSharingOptions = api.otherSharingOptions || []
	const nativeConfig = {
		facebook: { isEnabled: false },
		linkedIn: { isEnabled: false },
		twitter: { isEnabled: false },
		xing: { isEnabled: false },
	}

	// Collect supported sharing options
	otherSharingOptions.forEach(option => {
		switch (option.channelType) {
			case 'FacebookNative':
				nativeConfig.facebook.isEnabled = true
				nativeConfig.facebook.appId = option.appId
				nativeConfig.facebook.displayName = option.displayName
				break

			case 'LinkedInNative':
				nativeConfig.linkedIn.isEnabled = true
				nativeConfig.linkedIn.displayName = option.displayName
				nativeConfig.linkedIn.shareUrlBase =
					option.shareUrlBase +
					(Array.isArray(option.shareUrlParameters) && option.shareUrlParameters.length > 0
						? option.shareUrlParameters[0] + '='
						: '')
				break

			case 'TwitterNative':
				nativeConfig.twitter.isEnabled = true
				nativeConfig.twitter.appId = option.appId
				nativeConfig.twitter.displayName = option.displayName
				break

			case 'XingNative':
				nativeConfig.xing.isEnabled = true
				nativeConfig.xing.appId = option.appId
				nativeConfig.xing.displayName = option.displayName
				break

			default:
				break
		}
	})

	//#endregion

	const sphereData = {
		// Id
		sphereId: safeGetNestedProp(api, 'info.id'),

		// Basics
		communityName: safeGetNestedProp(api, 'info.translatedName'),
		communityRegistrationCode: communityRegistrationCode,
		regAppOrigin: safeGetNestedProp(api, 'info.regAppOrigin', null),

		// Theme
		theme: {
			primaryColor: safeGetNestedProp(api, 'theme.primaryColor'),
			backgroundColor: safeGetNestedProp(api, 'theme.backgroundColor'),
			squareLogoUrl: safeGetNestedProp(api, 'theme.squareLogoUrl'),
			coverImageUrl: safeGetNestedProp(api, 'theme.coverImage.coverImageUrl'),
		},

		// Security Settings
		security: api.security,

		// Sphere Parameters
		// NOTE - ALPHABETICAL PLEASE
		// NOTE: If we continue to keep adding native config sphere params, let's try to work w/Shane API-wise and redux-wise to keep them in their own sub property.
		parameters: {
			allowAnonymousNewsFeedAccess: api.settings.allowAnonymousNewsFeedAccess,
			allowImageSubmission: api.settings.allowImageSubmission,
			allowUsersToCategorizePosts: api.settings.allowUsersToCategorizePosts,
			allowUsersToTagPosts: api.settings.allowUsersToTagPosts,
			androidAppDownloadUrl: androidAppDownloadUrl,
			androidAppDownloadMode: androidAppDownloadMode,
			androidAppId: androidAppId,
			articleRoutingBaseUrl: api.settings.articleRoutingBaseUrl,
			defaultFeedLayout: safeGetNestedProp(
				api,
				'settings.defaultFeedLayout',
				FeedLayoutOptions.Grid
			),
			documentAttachmentMaxFileSizeInMb: api.settings.documentAttachmentMaxFileSizeInMb,
			documentAttachmentMaxFileUploadCount: api.settings.documentAttachmentMaxFileUploadCount,
			durationOfMessagesToStoreInDays: api.settings.durationOfMessagesToStoreInDays,
			enableAlternateShareText: api.settings.enableAlternateShareText,
			enableCategoryDiscovery: api.settings.enableCategoryDiscovery,
			enableCustomPages: api.settings.enableCustomPages,
			enableDivisions: api.settings.enableDivisions,
			enableDocumentAttachments: api.settings.enableDocumentAttachments,
			enableEditProfileImage: api.settings.enableEditProfileImage,
			enableEnhancedMentionSupport: api.settings.enableEnhancedMentionSupport,
			enableEnhancedSharingFlow: api.settings.enhancedSharingFlow,
			enableEmailForUsernameUsers: api.settings.enableEmailForUsernameUsers,
			enableEmailSignOn: api.settings.enableEmailSignOn,
			enableFullProfiles: api.settings.enableFullProfiles,
			enableLanguageTargeting: api.settings.enableLanguageTargeting,
			enableLeaderboards: api.settings.enableLeaderboards,
			enableMemberEditName: api.settings.enableMemberEditName,
			enableMemberPhoneNumber: api.settings.enableMemberPhoneNumber,
			enableMemberToMemberMessages: api.settings.enableMemberToMemberMessages,
			enableMessages: api.settings.enableMessages,
			enableMobileApps: api.settings.enableMobileApps,
			enableMultiImagePostSharing: api.settings.enableMultiImagePostSharing,
			enableMyContentCategory: api.settings.enableMyContentCategory,
			enableNewMemberInvitations: api.settings.enableNewMemberInvitations,
			enableOrganizationalHierarchy: api.settings.enableOrganizationalHierarchy,
			enablePendo: api.settings.enablePendo,
			enableShareByEmail: api.settings.enableShareByEmail,
			enableShareDialogV8: api.settings.enableShareDialogV8,
			enableSharingStatsCsvDownload: api.settings.enableSharingStatsCsvDownload,
			enableSms: api.settings.enableSms,
			enableSocialConnectionRenewal: api.settings.enableSocialConnectionRenewal,
			enableSso: api.settings.enableSso,
			enableSuggestedShareText: api.settings.enableSuggestedShareText,
			enableUserDirectory: api.settings.enableUserDirectory,
			enableUsernameSignOn: api.settings.enableUsernameSignOn,
			enableWelcomeBanner: api.settings.enableWelcomeBanner,
			enabledProcessors: api.settings.enabledProcessors,
			enhancedApiSecurity: api.settings.enhancedApiSecurity,
			facebookNativeConfig: nativeConfig.facebook,
			featuredContentCategoryId: api.settings.featuredContentCategoryId,
			feedLayoutOptions: safeGetNestedProp(api, 'settings.feedLayoutOptions', [
				FeedLayoutOptions.Grid,
			]),
			googleAnalyticsId: api.settings.googleAnalyticsId,
			iosAppDownloadUrl: iosAppDownloadUrl,
			iosAppDownloadMode: iosAppDownloadMode,
			iosAppId: iosAppId,
			isScheduledSharingEnabled: api.settings.isScheduledSharingEnabled,
			landingPageCategoryLabel: safeGetNestedProp(api, 'localizations.landingPageCategoryLabel'),
			linkedInNativeConfig: nativeConfig.linkedIn,
			maximumVideoFileSize: api.settings.maximumVideoFileSize,
			memberCanEditDivisionsAfterOnboarding: api.settings.memberCanEditDivisionsAfterOnboarding,
			requireSmsPinVerification: api.settings.requireSmsPinVerification,
			shareDialogVersions: api.settings.shareDialogVersion,
			sharingMaxChannelsPerShare: api.settings.sharingMaxChannelsPerShare,
			showDesktopAppLinks: showDesktopAppLinks,
			showMobileAppLinks: showMobileAppLinks,
			showPostShareLinks: api.settings.showPostShareLinks,
			showShareButtonOnSocialPosts: api.settings.shareButtonSocialPosts,
			ssoLogoutUrl: api.settings.ssoLogoutUrl,
			twitterNativeConfig: nativeConfig.twitter,
			usePersistentAuthentication: api.settings.usePersistentAuthentication,
			xingNativeConfig: nativeConfig.xing,
		},

		// Localized sphere info
		localizations: safeGetNestedProp(api, 'localizations', {}),

		...(process.env.DS_ENV_IS_DEPLOYED
			? {
					// Providers
					providers: api.providers,
					// Languages
					languageInfo: api.languageInfo,
					// TimeZone
					timeZoneInfo: api.timeZoneInfo,
			  }
			: null),
	}

	return sphereData
}

export default SphereData
