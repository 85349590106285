import Prefixer from 'inline-style-prefixer'
// import prefixAll from 'inline-style-prefixer/static'
const prefixer = new Prefixer()

import UserPhotoSize from './userPhotoSize'
import { spacing, color } from 'styles/variables'

const styles = {
	base: {
		// flex: 0,
		flexGrow: 0,
		flexShrink: 0,
		marginRight: spacing.basic,
		display: 'flex',
		position: 'relative',
	},
	size: {
		[UserPhotoSize.tiny.name]: {
			height: UserPhotoSize.tiny.size,
			width: UserPhotoSize.tiny.size,
		},
		[UserPhotoSize.small.name]: {
			height: UserPhotoSize.small.size,
			width: UserPhotoSize.small.size,
		},
		[UserPhotoSize.medium.name]: {
			height: UserPhotoSize.medium.size,
			width: UserPhotoSize.medium.size,
		},
		[UserPhotoSize.large.name]: {
			height: UserPhotoSize.large.size,
			width: UserPhotoSize.large.size,
		},
	},
	img: {
		borderRadius: '50%',
		size: {
			[UserPhotoSize.tiny.name]: {
				height: UserPhotoSize.tiny.size,
				width: UserPhotoSize.tiny.size,
			},
			[UserPhotoSize.small.name]: {
				height: UserPhotoSize.small.size,
				width: UserPhotoSize.small.size,
			},
			[UserPhotoSize.medium.name]: {
				height: UserPhotoSize.medium.size,
				width: UserPhotoSize.medium.size,
			},
			[UserPhotoSize.large.name]: {
				height: UserPhotoSize.large.size,
				width: UserPhotoSize.large.size,
			},
		},
	},
	disabledMask: {
		position: 'absolute',
		top: 0,
		left: 0,
		background: 'rgba(255, 255, 255, .4)',
		borderRadius: '50%',
	},
	count: {
		position: 'absolute',
		color: color.secondaryRed,
		bottom: 0,
		right: 0,
		fontSize: 20,
		lineHeight: 1,
	},
}

export default prefixer.prefix(styles)
