//
// NOTE: This dialog initializes in componentDidMount so either render it or don't. There's no isOpen prop.
//
import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Formik, Form } from 'formik'
import moment from 'moment-timezone'

// Components
import Button from 'components/Button/Button'
import Dialog from 'components/Dialog/Dialog'
import ErrorIndicator from 'components/ErrorIndicator/ErrorIndicator'
import Calendar from 'components/Calendar/Calendar'
import TimePicker, { MomentTimeFormat } from 'components/Picker/TimePicker/TimePicker'

// Helpers
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import Routes from 'helpers/routes'
import { ShareType } from 'scenes/ShareDialog/apiHelpers'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Lang
import lang from 'scenes/ShareDialog/components/shareScheduleDialog.lang'
import buttons from 'helpers/lang/buttons.lang'

// Styles
import { margin, fontWeight, width } from 'styles/variables'
import { safeGetNestedProp } from '@dysi/js-helpers'

export const styles = {
	shareScheduled: {
		textAlign: 'center',
		marginBottom: width.spacing.basic,
	},
	timePickerContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 61,
	},
	timePicker: {
		marginTop: width.spacing.narrow,
	},
	timeZone: {
		marginLeft: width.spacing.basic,
		marginRight: width.spacing.narrow,
	},
	editTimeZoneLink: {
		cursor: 'pointer',
	},
	scheduleError: {
		marginBottom: width.spacing.basic,
		marginLeft: -margin.wide,
		marginRight: -margin.wide,
		[BreakpointNames.small]: {
			marginLeft: -margin.basic,
			marginRight: -margin.basic,
		},
	},
	scheduleBackButton: {
		fontWeight: fontWeight.semiBold,
		cursor: 'pointer',
		marginRight: width.spacing.wide,
	},
	calendarContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	footer: {
		marginTop: margin.wide,
	},
}

// Redux generated Container Component
const mapStateToProps = (state, ownProps) => {
	// Settings
	const user = safeGetNestedProp(state, 'auth.user')
	const timeZones = safeGetNestedProp(state, 'sphere.timeZoneInfo.timeZones')
	const userTimeZone = (user && timeZones && timeZones[user.timeZone]) || null
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)

	return {
		userTimeZone,
		primaryColor,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		goToAccountSettings: () => dispatch(push(Routes.Settings.Profile.generateLinkPath('timezone'))),
	}
}

@responsive
class ShareScheduleDialog extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		shareDate: PropTypes.string,
		shareTime: PropTypes.string,
		calendarStartDate: PropTypes.string,
		calendarEndDate: PropTypes.string,
		scheduleError: PropTypes.string,
		isSubmitting: PropTypes.bool,
		onSubmit: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		viewport: PropTypes.object,

		// From Redux store
		userTimeZone: PropTypes.object,
		goToAccountSettings: PropTypes.func,

		// From Settings
		primaryColor: Theme.PrimaryColor.propType,
	}

	static initialState = {
		initialValues: {
			shareDate: null,
			shareTime: null,
			shareType: ShareType.schedule,
		},
	}

	state = {
		...ShareScheduleDialog.initialState,
	}

	handleScheduleDateChange = (values, setFieldValue, date) => {
		const { userTimeZone } = this.props

		// Convert calendar date to shareDate with date + time
		const userTimeZoneId = userTimeZone.id
		const newShareDate = moment.tz(date, userTimeZoneId)
		const oldShareDate = moment.tz(values.shareDate, userTimeZoneId)
		const shareDate = newShareDate
			.hour(oldShareDate.hour())
			.minute(oldShareDate.minute())
			.format()

		// Save it to Formik
		setFieldValue('shareDate', shareDate)
	}

	handleScheduleTimeChange = (values, setFieldValue, time) => {
		const { userTimeZone } = this.props

		// Change time on calendar share date
		const userTimeZoneId = userTimeZone.id
		const newShareTime = moment(time, MomentTimeFormat)
		const oldShareDate = moment.tz(values.shareDate, userTimeZoneId)
		const shareDate = oldShareDate
			.hour(newShareTime.hour())
			.minute(newShareTime.minute())
			.format()

		// Save it to Formik
		setFieldValue('shareDate', shareDate)
		setFieldValue('shareTime', time)
	}

	handleEditTimeZoneClick = e => {
		const { goToAccountSettings, onClose } = this.props

		if (onClose) {
			// Close the dialog
			onClose(e)
		}

		// Switch to Account Settings page
		goToAccountSettings()
	}

	handleCancel = e => {
		const { onCancel } = this.props

		e.preventDefault()

		// Call the container
		onCancel(e)
	}

	handleSubmit = (values, formikProps) => {
		const { onSubmit } = this.props

		// Remember share date for when we re-render the Formik dialog page (e.g. Scheduled Share Edit)
		this.setState({
			...this.state.initialValues,
			...values,
		})

		// Call the container
		onSubmit(values, formikProps)
	}

	handleClose = () => {
		const { onClose } = this.props

		onClose()
	}

	componentDidMount() {
		const { shareDate, shareTime } = this.props

		const initialValues = {
			...this.state.initialValues,
			shareDate,
			shareTime,
		}
		this.setState({ initialValues })
	}

	render() {
		const {
			title,
			userTimeZone,
			calendarStartDate,
			calendarEndDate,
			onCancel,
			scheduleError,
			primaryColor,
			viewport,
			isSubmitting,
		} = this.props
		const { initialValues } = this.state

		const timeZoneDisplay = userTimeZone ? userTimeZone.fullName : null
		const timeZoneId = userTimeZone ? userTimeZone.id : null

		const dynamicStyles = {
			editTimeZoneLink: {
				...styles.editTimeZoneLink,
				color: primaryColor,
			},
			scheduleBackButton: {
				...styles.scheduleBackButton,
				color: primaryColor,
			},
			scheduleError: {
				...styles.scheduleError,
				...styles.scheduleError[viewport.size],
			},
		}

		return (
			<Formik
				initialValues={initialValues}
				enableReinitialize
				onSubmit={this.handleSubmit}
				render={({ values, submitForm, setFieldValue }) => {
					return (
						<Fragment>
							<Form>
								<Dialog
									title={title}
									isOpen={true}
									onClose={this.handleClose}
									submitButtons={
										<div>
											{onCancel && (
												<span style={dynamicStyles.scheduleBackButton} onClick={this.handleCancel}>
													{buttons.back}
												</span>
											)}
											<Button onClick={submitForm} disabled={isSubmitting} type="submit">
												{lang.scheduleSubmitButton}
											</Button>
										</div>
									}
									shouldCloseOnOverlayClick={false}
								>
									<ErrorIndicator
										isOpen={!!scheduleError}
										message={scheduleError}
										style={dynamicStyles.scheduleError}
									/>

									{/* Calendar */}
									{values.shareDate && (
										<div style={styles.calendarContainer}>
											<Calendar
												name="shareDate"
												numberOfMonths={2}
												startDate={calendarStartDate}
												endDate={calendarEndDate}
												selectedDate={values.shareDate}
												timeZoneId={timeZoneId}
												onDateChange={date =>
													this.handleScheduleDateChange(values, setFieldValue, date)
												}
											/>
										</div>
									)}

									{/* Time of Day */}
									{values.shareTime && (
										<div style={styles.timePickerContainer}>
											<TimePicker
												name="shareTime"
												style={styles.timePicker}
												value={values.shareTime}
												onTimeChange={time =>
													this.handleScheduleTimeChange(values, setFieldValue, time)
												}
											/>
											{timeZoneDisplay && (
												<Fragment>
													<span style={styles.timeZone}>{timeZoneDisplay}</span>
													<span
														style={dynamicStyles.editTimeZoneLink}
														onClick={this.handleEditTimeZoneClick}
													>
														{buttons.edit}
													</span>
												</Fragment>
											)}
										</div>
									)}
								</Dialog>
							</Form>
						</Fragment>
					)
				}}
			/>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShareScheduleDialog)
