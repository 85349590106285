import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Routing
import { Route, Redirect } from 'react-router'
import Routes from 'helpers/routes'

// Components
import NotFoundPage from 'pages/NotFound'

// Wrappers
import { Settings, SettingsPropType } from 'wrappers/SettingsWrapper'

// Lang
import lang from 'pages/notFound.lang'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { safeGetSetting } from 'wrappers/Settings'

const mapStateToProps = state => {
	const isSsoSignOutInProgress = safeGetNestedProp(state, 'auth.isSsoSignOutInProgress')
	const usageComplianceRequired = safeGetNestedProp(
		state,
		'memberUsageCompliance.usageComplianceRequired'
	)
	const userIsLoggedIn = safeGetSetting(state, Settings.User.IsLoggedIn)
	const userIsProfileComplete = safeGetSetting(state, Settings.User.IsProfileComplete)
	const enhancedApiSecurity = safeGetSetting(state, Settings.User.EnhancedApiSecurity)

	return {
		isSsoSignOutInProgress,
		usageComplianceRequired,
		userIsLoggedIn,
		userIsProfileComplete,
		enhancedApiSecurity,
	}
}
/**
 * Used when you want to prevent access to a particular route.
 *
 * @param {function} authorized - If this fails, the user will be redirected to the sign-in page.
 * @param {function} enabled - If this fails, a 404 will be displayed.
 * @class ProtectedRoute
 * @extends {React.Component}
 */
class ProtectedRoute extends React.Component {
	static propTypes = {
		authorized: PropTypes.func,
		component: PropTypes.any.isRequired,
		enabled: PropTypes.func,
		render: PropTypes.any,
		ignoreEnhancedSecurity: PropTypes.bool,
		ignoreProfileIncompleteCheck: PropTypes.bool,
		isImpersonating: PropTypes.bool,
		allowImpersonation: PropTypes.bool,

		isSsoSignOutInProgress: PropTypes.bool,
		usageComplianceRequired: PropTypes.bool,
		userIsLoggedIn: Settings.User.IsLoggedIn.propType,
		userIsProfileComplete: Settings.User.IsProfileComplete.propType,
		enhancedApiSecurity: SettingsPropType.enhancedApiSecurity.propType,
	}

	static defaultProps = {
		allowImpersonation: true,
		ignoreEnhancedSecurity: false,
		ignoreProfileIncompleteCheck: false,
		isImpersonating: false,
	}

	render() {
		const {
			component: Component,
			authorized,
			enabled,
			render,
			usageComplianceRequired,
			userIsLoggedIn,
			userIsProfileComplete,
			enhancedApiSecurity,
			ignoreEnhancedSecurity,
			ignoreProfileIncompleteCheck,
			isImpersonating,
			allowImpersonation,
			isSsoSignOutInProgress,
			...rest
		} = this.props

		// DS-1453: If SSO logout in progress flag is set, return null so that the redirect from clearUserAndRedirect happens
		if (isSsoSignOutInProgress) return null

		const isAuthorized = !authorized || authorized() === true

		// DS-4641: If MUC required return null so that the page doesn't get into an infinite loop loading MUC<->Login
		if (usageComplianceRequired && (userIsLoggedIn || isAuthorized)) return null

		// If we need to redirect to Sign In, tell RegFlow to return to original location
		const query = `?returnUrl=${encodeURIComponent(window.location.href)}`
		const redirect = <Redirect to={{ pathname: Routes.SignIn.linkPath, search: query }} />

		// Anonymous access never allowed so reroute to Sign In page
		if (!ignoreEnhancedSecurity && !userIsLoggedIn && enhancedApiSecurity) return redirect

		// User needs to finish RegFlow so reroute to Sign In page
		if (!ignoreProfileIncompleteCheck && userIsLoggedIn && !userIsProfileComplete) return redirect

		if (enabled && enabled() === false) return <NotFoundPage />

		if (!allowImpersonation && isImpersonating)
			return <NotFoundPage text={lang.youAreUnableToAccess} />

		return (
			<Route
				{...rest}
				render={props =>
					isAuthorized ? render ? render(this.state) : <Component {...props} /> : redirect
				}
			/>
		)
	}
}

export default connect(mapStateToProps)(ProtectedRoute)
