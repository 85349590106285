import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Style } from 'radium'
import { styleBase } from 'styles/base'
import { styleNormalize } from 'styles/normalize'

import { safeGetNestedProp } from '@dysi/js-helpers'
import Settings from 'wrappers/Settings'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import themeName, { getThemeFromStorage } from 'styles/themes/themeHelpers'
import { isDesktopApp } from 'helpers/browserhelper'
import IpcEvent from 'helpers/desktopApp/ipcEvent'

const mapStateToProps = (state, ownProps) => {
	// Check to see if the SideMenu is open. If it is open, we nee to
	// apply some overflow overrides to the HTML element to prevent
	// weird scrolling.
	const sideMenuOpen = safeGetNestedProp(state, 'browser.sideMenuOpen', false)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)

	let backgroundColor = safeGetNestedProp(
		state,
		Settings.Community.BackgroundColor.path,
		Settings.Community.BackgroundColor.fallback
	)

	const theme = getThemeFromStorage()
	if (theme.name !== themeName.light) {
		backgroundColor = theme.colors.bgPage
	}

	return {
		primaryColor,
		backgroundColor,
		sideMenuOpen,
	}
}

class AppStyles extends React.Component {
	static propTypes = {
		primaryColor: Theme.PrimaryColor.propType,
		backgroundColor: Settings.Community.BackgroundColor.propType,
		sideMenuOpen: PropTypes.bool.isRequired,
	}

	componentDidMount() {
		const { backgroundColor } = this.props

		if (isDesktopApp) {
			// Tell the desktop app to keep its background color in sync
			window.ipcRenderer.send(IpcEvent.update.settings, {
				backgroundColor: backgroundColor,
			})
		}
	}

	render() {
		const { primaryColor, sideMenuOpen, backgroundColor } = this.props
		const styleRules = {
			...styleNormalize,
			...styleBase(primaryColor, sideMenuOpen, backgroundColor),
		}

		return <Style rules={styleRules} />
	}
}

export default connect(mapStateToProps)(AppStyles)
