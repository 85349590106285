import { vsApi, requests } from 'helpers/apiHelpers'
import { color } from 'styles/variables'

import SphereData, { processApiTimezones } from './sphere.data'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { isDesktopApp } from 'helpers/browserhelper'
import IpcEvent from 'helpers/desktopApp/ipcEvent'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below
const SET_SPHERE_INFO = 'SET_SPHERE_INFO'
const CLEAR_PROVIDERS = 'CLEAR_PROVIDERS'
const UPDATE_PROVIDERS = 'UPDATE_PROVIDERS'
const SET_PROVIDERS = 'SET_PROVIDERS'
const SET_TIMEZONES = 'SET_TIMEZONES'
const SET_LANGUAGES = 'SET_LANGUAGES'
const SET_CUSTOMLINKS = 'SET_CUSTOMLINKS'
const SET_TERMS_OF_SERVICE = 'SET_TERMS_OF_SERVICE'

// Action Creators
export const setSphereInfo = info => {
	return {
		type: SET_SPHERE_INFO,
		response: info,
	}
}

export const clearProviders = () => {
	return {
		type: CLEAR_PROVIDERS,
	}
}

export const updateProviders = providers => {
	return {
		type: UPDATE_PROVIDERS,
		response: { providers }, // Mirror result from API call
	}
}

// NOTE: Use this for Storybook only!
export const setProviders = providers => {
	return {
		type: SET_PROVIDERS,
		response: { providers }, // Mirror result from API call
	}
}

export const setTimeZones = timeZones => {
	return {
		type: SET_TIMEZONES,
		response: timeZones, // Mirror result from API call
	}
}

export const setLanguages = languages => {
	return {
		type: SET_LANGUAGES,
		response: { languages }, // Mirror result from API call
	}
}

// Async Action Creators

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

// TODO: Replace with initialization from server
export const asyncGetSphereParams = () => {
	// Note: This call is only made when running locally, i.e. process.env.NODE_ENV !== 'production'
	const apiRequest = {
		endpoint: requests.getSphereInfo.endpoint,
		actions: {
			success: SET_SPHERE_INFO,
		},
		requestOptions: {},
	}

	// If a local debug program ID is specified, pass it along in the headers with the request
	if (process.env.NODE_ENV !== 'production' && !!process.env.REACT_APP_VOYAGE_PROGRAM) {
		// Create a fetch Request Headers object
		const requestHeaders = new Headers()
		requestHeaders.append('Sphere-Id', process.env.REACT_APP_VOYAGE_PROGRAM) // Voyage dev program id

		// Add headers to the request
		apiRequest.requestOptions.headers = requestHeaders
	}

	// Call API
	return vsApi(apiRequest)
}

export const asyncGetProviders = () =>
	vsApi({
		endpoint: requests.getProviders.endpoint,
		actions: { success: SET_PROVIDERS },
	})

export const asyncGetTimeZones = () => {
	return vsApi({
		endpoint: requests.getTimezones.endpoint,
		actions: {
			success: SET_TIMEZONES,
		},
	})
}

export const asyncGetLanguages = () => {
	return vsApi({
		endpoint: requests.getLanguages.endpoint,
		actions: {
			success: SET_LANGUAGES,
		},
	})
}

export const asyncGetCustomLinks = () => {
	return vsApi({
		endpoint: requests.getCustomLinks.endpoint,
		actions: {
			success: SET_CUSTOMLINKS,
		},
	})
}

export const asyncGetTermsOfService = () => {
	return vsApi({
		endpoint: requests.getTermsOfService.endpoint,
		actions: {
			success: SET_TERMS_OF_SERVICE,
		},
	})
}

// --- Initial State ---

const getHost = () => {
	if (process.env.NODE_ENV !== 'production') {
		// Try to get a host
		const host = safeGetNestedProp(window, 'location.host', 'localhost')

		// Fallback to Star Wars because whatever
		if (host.includes('localhost')) {
			return 'starwars.dsinitech.us'
		}

		return host
	}

	return window.location.host
}

const getApiRoot = () => {
	if (process.env.NODE_ENV !== 'production' && !!process.env.REACT_APP_API_ROOT) {
		// Use API override for local debugging
		return process.env.REACT_APP_API_ROOT
	}

	return `https://${getHost()}`
}

// State will be mapped to the Redux store by combineReducers
const initialState = {
	host: getHost(),
	home: `https://${getHost()}/${process.env.PUBLIC_URL}`,
	apiVersion: 'v1',
	apiRoot: getApiRoot(),
	communityName: null,
	providers: {
		// Dictionary of providers, keyed by provider.canonicalName
	},
	theme: {
		primaryColor: color.defaultPrimaryColor,
	},
	parameters: {
		enableSuggestedShareTextForFacebook: false,
	},
	lastUpdatedDate: null,
	timeZoneInfo: {
		communityTimeZone: '',
		communityTimeZoneIanaName: '',
		timeZones: {}, // Map of ID (e.g. America/Los_Angeles to info)
	},
	languageInfo: {
		languages: [], // known languages
		showUserContentLanguageSelection: null,
		backfillContentLanguage: null,
		contentLanguages: [], // list of content languages available if content languages are inherently restricted.
		defaultInterfaceLanguage: null,
		defaultUserLanguage: null,
	},
	localizations: {
		contentLanguagePrompt: null,
	},
	customLinks: null,
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//

export default function sphereReducer(state = initialState, action) {
	switch (action.type) {
		// v1/community
		case SET_SPHERE_INFO: {
			const sphereData = SphereData(action.response)
			return {
				...state,
				...sphereData,
				lastUpdatedDate: new Date(),
			}
		}
		case SET_PROVIDERS:
			return {
				...state,
				providers: action.response.providers,
			}
		case SET_TIMEZONES: {
			// Use sphereData to process these so they're the same on the NMAS injected version
			let timeZoneInfo = processApiTimezones(action.response)
			return {
				...state,
				timeZoneInfo,
			}
		}
		case SET_LANGUAGES: {
			const {
				languages,
				showUserContentLanguageSelection,
				backfillContentLanguage,
				contentLanguagePrompt,
				contentLanguages,
			} = action.response
			const languageInfo = {
				...state.languageInfo,
				languages,
				showUserContentLanguageSelection,
				backfillContentLanguage,
				contentLanguages,
			}

			const localizations = {
				...state.localizations,
				contentLanguagePrompt,
			}

			return {
				...state,
				languageInfo,
				localizations,
			}
		}
		case SET_CUSTOMLINKS: {
			const customLinks = action.response

			if (isDesktopApp) {
				window.ipcRenderer.send(IpcEvent.update.customLinks, {
					links: action.response || {},
				})
			}

			return {
				...state,
				customLinks,
			}
		}
		default:
			return state
	}
}
