import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components
import Description from './components/Description'
import SocialHeader from 'scenes/Post/components/SocialHeader'
import ImageWithPlaceholder from 'components/Placeholders/ImagePlaceholder'
import Icon, { Name } from 'components/Icons/FontAwesome'
import DateTime, { DateFormat } from 'components/DateTime/DateTime'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'
import FallbackPost from './FallbackPost'
import PostActions from './components/PostActions'
import PostLink from 'components/PostLink/PostLink'
import PlayButton from './components/PostMedia/PlayButton'
import LiveStreamPlaceholder, { LiveStreamIcon } from './components/PostMedia/LiveStreamPlaceholder'
import { ReactPhotoCollage } from 'react-photo-collage'

// Helpers
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { getPostImageSize } from 'helpers/imageHelpers'
import Routes from 'helpers/routes'
import { Activity, getActivity } from 'helpers/activityHelpers'
import ErrorBoundary from 'wrappers/ErrorBoundary'
import { isAtLeast } from 'helpers/breakpoints'
import { safeGetNestedProp } from '@dysi/js-helpers'
import SocialProviders from 'helpers/socialProviderData'

// Enums
import Layout from 'scenes/Post/enums/postLayout'
import PostTypes from './enums/postTypes'
import PostConstants from './enums/postConstants'
import VideoTypes from './enums/videoTypes'
import LiveStreamState from './enums/liveStreamState'

// Actions
import { asyncPostView } from './post.reducer'

// Styles
import { spacing } from 'styles/variables'
import styles from './PostSummary.styles'

const mapStateToProps = state => {
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const enableMultiImagePostSharing = safeGetNestedProp(
		state,
		'sphere.parameters.enableMultiImagePostSharing'
	)

	return {
		primaryColor,
		enableMultiImagePostSharing,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	const postId = safeGetNestedProp(ownProps, 'post.postId')
	const activity =
		ownProps.broadcastId && getActivity(Activity.Reason.broadcast, ownProps.broadcastId)

	return {
		viewPost: () => dispatch(asyncPostView(postId, activity)),
	}
}

export const getMediaStyle = media => {
	if (!media) return

	let height, width, ratio

	if ((!media.height && !media.width) || media.role === VideoTypes.livestream) {
		height = PostConstants.feed.maxWidth
		width = PostConstants.feed.maxWidth
	} else if (media.height >= media.width) {
		ratio = PostConstants.feed.maxWidth / media.height
		height = PostConstants.feed.maxWidth
		width = media.width * ratio
	} else {
		ratio = PostConstants.feed.maxWidth / media.width
		width = PostConstants.feed.maxWidth
		height = media.height * ratio
	}

	return {
		height,
		width,
	}
}

@responsive
class PostSummary extends React.Component {
	static propTypes = {
		broadcastId: PropTypes.number,
		showPostActions: PropTypes.bool,
		openInAppLinkInNewTab: PropTypes.bool,
		isUrgentBroadcast: PropTypes.bool,
		handleCloseDialog: PropTypes.func,
		viewPost: PropTypes.func.isRequired,
		post: PropTypes.object.isRequired,
		style: PropTypes.object,
		viewport: PropTypes.object,
		primaryColor: Theme.PrimaryColor.propType,
		enableMultiImagePostSharing: PropTypes.bool,
		disabled: PropTypes.bool,
		layout: PropTypes.oneOf(Object.keys(Layout)),
	}

	render() {
		const {
			post,
			style,
			viewport,
			broadcastId,
			showPostActions,
			openInAppLinkInNewTab,
			isUrgentBroadcast,
			handleCloseDialog,
			viewPost,
			primaryColor,
			enableMultiImagePostSharing,
			disabled,
			layout = Layout.feed,
		} = this.props

		if (!post) return null

		const image = getPostImageSize(layout, post.image, viewport)
		const activity = getActivity(broadcastId && Activity.Reason.broadcast, broadcastId)
		const postLinkProps = {
			postId: post.postId,
			postTitle: post.title,
			urlSlug: post.urlSlug,
			displayMode: post.displayMode,
			isUrgentBroadcast,
			handleCloseDialog,
			activity,
			showPageDialog: true,
			disabled: disabled,
		}
		const inAppLinkTarget = openInAppLinkInNewTab ? '_blank' : null

		const dynamicStyles = {
			base: {
				...styles.base,
				...styles.base[viewport.size],
				...style,
			},
			details: {
				...styles.details,
				...styles.details[viewport.size],
				marginTop: !post.isSocialPost ? spacing.narrow : 0,
			},
			imageContainer: {
				...styles.imageContainer,
				...styles.imageContainer[viewport.size],
				...(!image && styles.imageContainer.noImage),
				...(post.isLiveStream && styles.imageContainer.liveStream),
				...(disabled && { cursor: 'default' }),
				// In messaging layout let the container only take up the image space
				...(layout === Layout.messaging && { flexGrow: 0 }),
			},
			text: {
				...styles.text,
				...styles.text[viewport.size],
			},
			postActions: {
				...styles.postActions,
				...styles.postActions[viewport.size],
			},
			permaLink: {
				color: primaryColor,
			},
			blankImage: {
				...styles.blankImage,
				maxWidth: '100%',
				maxHeight: '100%',
				...getMediaStyle(post.media),
			},
		}

		const getImageGallerySettings = imageGallery => {
			let photos = []
			if (!imageGallery) {
				return null
			}

			imageGallery.forEach(image => {
				photos.push({ source: image.url })
			})

			return {
				width: '300px', //width of the entire gallery
				height: ['150px', '100px'], //first height is for top row photo (main photo), second is for the next row
				layout: [1, 3], //1 photo on top, 3 on second row
				photos: photos,
				showNumOfRemainingPhotos: true, //Displays '+x' over the third photo
			}
		}

		return (
			<Fragment>
				<div style={dynamicStyles.base}>
					{post.isSocialPost && (
						<SocialHeader
							post={post}
							author={post.author}
							provider={post.provider}
							disabled={disabled}
						/>
					)}

					<div style={dynamicStyles.details}>
						{((image && !(post.imageGallery && enableMultiImagePostSharing)) ||
							post.postType === PostTypes.video) && (
							<div style={dynamicStyles.imageContainer}>
								<PostLink {...postLinkProps}>
									<Fragment>
										{image ? (
											<ImageWithPlaceholder
												style={styles.image}
												image={image}
												renderPlaceholder={null}
												renderError={
													<div style={{ margin: 10, fontSize: 40 }}>
														<Icon name={Name.exclamationCircle} />
													</div>
												}
											/>
										) : post.media.role === VideoTypes.livestream ? (
											<LiveStreamPlaceholder />
										) : (
											<div style={dynamicStyles.blankImage} />
										)}
										{!disabled &&
											post.postType === PostTypes.video &&
											(post.isLiveStream && post.liveStreamState !== LiveStreamState.completed ? (
												image && <LiveStreamIcon />
											) : (
												<PlayButton
													isYouTube={post.provider === SocialProviders.YouTube.provider}
												/>
											))}
									</Fragment>
								</PostLink>
							</div>
						)}
						{post.imageGallery && enableMultiImagePostSharing && (
							<div style={dynamicStyles.imageContainer}>
								<PostLink {...postLinkProps}>
									<Fragment>
										<ReactPhotoCollage {...getImageGallerySettings(post.imageGallery)} />
									</Fragment>
								</PostLink>
							</div>
						)}
						<div style={dynamicStyles.text}>
							{/* Title */}
							<PostLink {...postLinkProps}>
								<RestrictedText
									text={post.title}
									limit={
										post.isSocialPost
											? Restrictions.PostTitle.Social.Summary
											: Restrictions.PostTitle.NonSocial
									}
								>
									{text => (
										<div dir={post.isRtlLanguage ? 'rtl' : null}>
											<strong>{text}</strong>
										</div>
									)}
								</RestrictedText>
								<Description
									text={post.description}
									style={{ margin: 0 }}
									isRtlLanguage={post.isRtlLanguage}
								/>
							</PostLink>

							{/* Permalink */}
							{post.showPermalink && post.cleanPermaLink && !disabled && (
								<div>
									<a
										href={post.cleanPermaLink}
										target="_blank"
										rel="noopener noreferrer" /* Prevent reverse tabnabbing security vulnerability. https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/ */
										onClick={viewPost}
										style={dynamicStyles.permaLink}
									>
										{post.permaLinkDomain}
									</a>
								</div>
							)}

							{/* Publish Date */}
							{disabled ? (
								<div style={styles.publishDate}>
									<DateTime date={post.publishDate} format={DateFormat.FromNow} />
								</div>
							) : (
								<Link
									to={Routes.Post.Details.generateDialogPath(
										post.urlSlug,
										post.postId,
										post.discussionsEnabled,
										null,
										null,
										activity
									)}
									style={styles.publishDate}
									target={inAppLinkTarget}
									rel={inAppLinkTarget ? 'noopener noreferrer' : null}
								>
									<div>
										<DateTime date={post.publishDate} format={DateFormat.FromNow} />
									</div>
								</Link>
							)}
						</div>
					</div>
				</div>
				{showPostActions && !disabled && (
					<PostActions
						post={post}
						showLabels={isAtLeast(BreakpointNames.medium, viewport.size)}
						style={dynamicStyles.postActions}
						broadcastId={broadcastId}
						isUrgentBroadcast={isUrgentBroadcast}
						handleCloseDialog={handleCloseDialog}
					/>
				)}
			</Fragment>
		)
	}
}

let WrappedPostSummary = ErrorBoundary(PostSummary, FallbackPost) //TODO: Change FallbackPost to new design
WrappedPostSummary = connect(mapStateToProps, mapDispatchToProps)(WrappedPostSummary)

export default WrappedPostSummary
