import { vsApi, requests } from 'helpers/apiHelpers'

//region Async API calls
export const asyncGetUserRankings = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getUserIdRankings.getEndpoint(userId),
		options: { abortable },
	})
}

export const asyncGetUserById = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getUserById.getEndpoint(userId),
		options: { abortable },
	})
}

export const asyncGetUserProfile = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getUserProfile.getEndpoint(userId),
		options: { abortable },
	})
}

export const asyncGetSharingStatsCsvDownload = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getSharingStatCsvDownload.getEndpoint(userId),
		options: { abortable },
	})
}

export const asyncGetUserDirectReports = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getUserDirectReports.getEndpoint(userId),
		options: { abortable },
	})
}
