import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import ViewableMonitor from 'components/ViewableMonitor/ViewableMonitor'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Helpers
import { isImageLowHeight } from 'helpers/imageHelpers'

// Styles
import { color, flexContainer } from 'styles/variables'
import { safeGetNestedProp } from '@dysi/js-helpers'

const styles = {
	hide: {
		display: 'none',
	},
	container: {
		// backgroundColor: color.white,
		...flexContainer(),
	},
	image: {
		maxHeight: '100%',
		maxWidth: '100%',
	},
	noBorder: {
		border: 'none',
	},
	error: {
		fontSize: 32,
	},
}

const mapStateToProps = state => {
	const sideMenuOpen = safeGetNestedProp(state, 'browser.sideMenuOpen')

	return {
		sideMenuOpen,
	}
}
class ImagePlaceholder extends Component {
	static propTypes = {
		image: PropTypes.object,
		renderError: PropTypes.object,
		renderPlaceholder: PropTypes.object,
		style: PropTypes.object,
		sideMenuOpen: PropTypes.bool,
		isPreview: PropTypes.bool,
		onSetIsLowHeightImage: PropTypes.func,
	}

	// Instance variables to track changes to rendered image height
	prevImageHeight = 0
	imageHeight = 0

	state = {
		isLoading: true,
		onError: false,
	}

	componentDidMount() {
		const { onSetIsLowHeightImage } = this.props

		this.setState({ isLoading: true })

		if (onSetIsLowHeightImage) {
			if (isImageLowHeight(this.imageHeight)) {
				onSetIsLowHeightImage()
			}
		}

		this.prevImageHeight = this.imageHeight
	}

	componentDidUpdate() {
		const { onSetIsLowHeightImage } = this.props

		if (onSetIsLowHeightImage && this.prevImageHeight !== this.imageHeight) {
			if (isImageLowHeight(this.imageHeight)) {
				onSetIsLowHeightImage()
			}
		}

		this.prevImageHeight = this.imageHeight
	}

	onLoad = () => {
		this.setState({ isLoading: false })
	}

	onError = () => {
		this.setState({
			isLoading: false,
			onError: true,
		})
	}

	getStyle = image => {
		const container = this.container && this.container.getBoundingClientRect()

		let width = image.width
		let height = image.height
		if (container && (image.width > container.width || image.height > container.height)) {
			const ratio = Math.min(container.width / image.width, container.height / image.height)
			width = width ? width * ratio : container.width
			height = height ? height * ratio : container.height
		}

		this.imageHeight = Math.floor(height)

		return {
			...styles.container,
			height,
			width,
		}
	}

	render() {
		const {
			image,
			style: styleOverride,
			renderError,
			renderPlaceholder,
			sideMenuOpen,
			isPreview,
		} = this.props
		const { isLoading, onError } = this.state

		if (!image) return null

		const style = this.getStyle(image)
		const dynamicStyles = {
			image: {
				...(!isPreview && style),
				...styles.image,
				...styleOverride,
				...((isLoading || onError) && styles.hide),
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
			},
			placeholder: {
				...style,
				color: color.secondaryGrey,
				fontSize: 60,
				textAlign: 'center',
				width: '100%',
				// if image is smaller then icon size
				...(image &&
					image.height <= 60 && {
					fontSize: image.height / 2,
				}),
			},
			container: {
				...(isLoading || onError ? style : null),
				...styles.container,
				...styleOverride,
				...styles.noBorder,
			},
		}

		const placeholder = renderPlaceholder ? renderPlaceholder : <Icon name={Name.image} />
		// ViewableMonitor doesn't work with ReactModal when a root is specified
		const isModalOpen = document.querySelector('.ReactModal__Body--open') !== null
		const options = {
			...(!isModalOpen && !sideMenuOpen && { root: '#root' }),
			rootMargin: '500px 500px 500px 500px',
		}
		const errorComponent = renderError || (
			<div style={dynamicStyles.container}>
				<Icon name={Name.exclamationCircle} style={styles.error} color={color.primaryGrey} />
			</div>
		)

		return (
			<ViewableMonitor options={options} onlyOnce={true}>
				{isVisible => (
					<div
						style={dynamicStyles.container}
						ref={elem => (this.container = elem)}
						data-id="ImagePlaceholder"
					>
						{isVisible ? (
							<Fragment>
								{isLoading && <div style={dynamicStyles.placeholder}>{placeholder}</div>}
								{onError && errorComponent}
								<img
									src={image.url}
									alt={image.altText}
									onLoad={this.onLoad}
									onError={this.onError}
									style={dynamicStyles.image}
								/>
							</Fragment>
						) : (
							<div style={dynamicStyles.placeholder}>{placeholder}</div>
						)}
					</div>
				)}
			</ViewableMonitor>
		)
	}
}

export default connect(mapStateToProps)(ImagePlaceholder)
