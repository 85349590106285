import { isLocalStorageAvailable, safeGetNestedProp } from '@dysi/js-helpers'
import { isDesktopApp } from './browserhelper'
import cloneDeep from 'lodash/cloneDeep'
import IpcEvent from 'helpers/desktopApp/ipcEvent'

export const storageKeys = {
	auth: {
		token: 'dysi:token',
		tokenExpiration: 'dysi:tokenExpiration',
		shortToken: 'dysi:shortToken',
		shortTokenExpiration: 'dysi:shortTokenExpiration',
		refreshToken: 'dysi:refreshToken',
		deviceId: 'dysi:deviceId',
		ssoCallbackData: 'sso:callbackdata',
		legacyToken: 'voicestorm.com:token',
		legacyTokenExpiration: 'voicestorm.com:tokenExpiration',
		legacyShortToken: 'voicestorm.com:shortLivedToken',
		legacyShortTokenExpiration: 'voicestorm.com:shortLivedTokenExpiration',
	},
	user: {
		primaryLanguage: 'dysi:primaryLanguage',
	},
	legacy: {
		logout: 'voicestorm.com:logout-event', // BrandApp, Manager App, sign out signal (always in localStorage)
	},
	categoryDiscovery: {
		firstLoad: currentUserId => `nma:categoryDiscoveryFirstLoad-${currentUserId}`,
		firstTime: currentUserId => `nma:categoryDiscoveryFirstTime-${currentUserId}`,
		categoryViewed: (currentUserId, categoryId) =>
			`nma:categoryViewed-${currentUserId}-${categoryId}`,
	},
}

export const isSessionStorageAvailable = () => {
	let sessionStorage = window.sessionStorage
	try {
		sessionStorage.setItem('test', 'test')
		sessionStorage.removeItem('test')
		return true
	} catch (e) {
		return false
	}
}

export let storage = {
	// Fake, local implementation to prevent crashes as app loads. App should do nothing
	// but show LocalStorage error page when storage is not available. See AppRoutes
	data: {},
	setItem: function(key, item) {
		storage.data[key] = item
	},
	getItem: function(key) {
		return storage.data[key]
	},
	removeItem: function(key) {
		delete storage.data[key]
	},
}

// Specifically for authentication tokens/cookies, since the storage location of these are dependent on usePersistentAuthentication.
export let authStorage = cloneDeep(storage)

export const setAuthStorage = usePersistentAuthentication => {
	if (usePersistentAuthentication && isLocalStorageAvailable()) {
		authStorage = window.localStorage
	} else if (!usePersistentAuthentication && isSessionStorageAvailable()) {
		authStorage = window.sessionStorage
	}
}

try {
	// Deployed communities will already have injected communityInfo properties. For running things locally,
	// we have to run this logic after we've performed the API call and created the redux store (see: index.js)
	let usePersistentAuthentication = false

	// When running locally store auth tokens in localStorage rather than defaulting to sessionStorage
	if (process.env.DS_ENV_IS_LOCAL) {
		usePersistentAuthentication = true
	}

	if (process.env.DS_ENV_IS_DEPLOYED) {
		usePersistentAuthentication = safeGetNestedProp(
			window.injectedState,
			'sphere.parameters.usePersistentAuthentication',
			false
		)
	}

	// Set storage for authorization keys to browser storage
	setAuthStorage(usePersistentAuthentication)

	// Set general storage to browser storage, if supported
	if (isLocalStorageAvailable()) {
		storage = window.localStorage
	}
} catch (ex) {}

// Helpers
export const clearAuthTokens = () => {
	const keysToClear = [
		storageKeys.auth.token,
		storageKeys.auth.tokenExpiration,
		storageKeys.auth.shortToken,
		storageKeys.auth.shortTokenExpiration,
		storageKeys.auth.refreshToken,
		storageKeys.auth.deviceId,
	]

	// Clear both localStorage AND sessionStorage for consistency
	keysToClear.forEach(key => {
		delete window.localStorage[key]
		delete window.sessionStorage[key]
	})
}

export const saveAuthTokens = apiResult => {
	if (apiResult.token && apiResult.expiration) {
		authStorage[storageKeys.auth.token] = apiResult.token
		authStorage[storageKeys.auth.tokenExpiration] = apiResult.expiration
		// Stringify the legacy tokens to keep compatibility with how VoicestormSDK uses them
		authStorage[storageKeys.auth.legacyToken] = JSON.stringify(apiResult.token)
		authStorage[storageKeys.auth.legacyTokenExpiration] = JSON.stringify(apiResult.expiration)
	}
	if (apiResult.shortLivedToken && apiResult.shortLivedTokenExpiration) {
		authStorage[storageKeys.auth.shortToken] = apiResult.shortLivedToken
		authStorage[storageKeys.auth.shortTokenExpiration] = apiResult.shortLivedTokenExpiration
		authStorage[storageKeys.auth.legacyShortToken] = JSON.stringify(apiResult.shortLivedToken)
		authStorage[storageKeys.auth.legacyShortTokenExpiration] = JSON.stringify(
			apiResult.shortLivedTokenExpiration
		)
	}
	if (isDesktopApp && apiResult.refreshToken && apiResult.deviceId) {
		authStorage[storageKeys.auth.refreshToken] = apiResult.refreshToken
		authStorage[storageKeys.auth.deviceId] = apiResult.deviceId

		// Send the auth info to the DesktopApp to persist in the OS Keychain
		window.ipcRenderer.send(IpcEvent.update.session, {
			persistedAuth: {
				token: apiResult.token,
				tokenExpiration: apiResult.expiration,
				shortToken: apiResult.shortLivedToken,
				shortTokenExpiration: apiResult.shortLivedTokenExpiration,
				refreshToken: apiResult.refreshToken,
				deviceId: apiResult.deviceId,
			},
		})
	}
}

export const clearSsoAuthToken = () => {
	// Reset SSO callback data
	if (window.sessionStorage && window.sessionStorage[storageKeys.auth.ssoCallbackData])
		window.sessionStorage.removeItem(storageKeys.auth.ssoCallbackData)
}

export const readAuthTokens = () => {
	return new Promise(resolve => {
		if (!storage) resolve({})

		// Are we returning from SSO authentication?
		if (isLocalStorageAvailable() && isSessionStorageAvailable()) {
			try {
				// Attempt to pull SSO response from session storage
				const ssoJson = window.sessionStorage[storageKeys.auth.ssoCallbackData] || '{}'
				const ssoData = JSON.parse(ssoJson)

				if (ssoData.token && ssoData.expiration) {
					// Update our locally persisted authentication keys
					authStorage[storageKeys.auth.token] = ssoData.token
					authStorage[storageKeys.auth.tokenExpiration] = ssoData.expiration
					authStorage[storageKeys.auth.legacyToken] = JSON.stringify(ssoData.token)
					authStorage[storageKeys.auth.legacyTokenExpiration] = JSON.stringify(ssoData.expiration)
				}
			} catch (ex) {}
		}

		// Return persisted auth, if it exists
		const persistedAuth = {
			token: authStorage[storageKeys.auth.token] || null,
			tokenExpiration: authStorage[storageKeys.auth.tokenExpiration] || null,
			shortToken: authStorage[storageKeys.auth.shortToken] || null,
			shortTokenExpiration: authStorage[storageKeys.auth.shortTokenExpiration] || null,
			refreshToken: authStorage[storageKeys.auth.refreshToken] || null,
			deviceId: authStorage[storageKeys.auth.deviceId] || null,
		}

		// If we are in the DesktopApp check if it has persisted credentials in the OS keychain
		if (!persistedAuth.token && isDesktopApp) {
			getKeyChainAuth()
				.then(keyAuth => resolve(keyAuth))
				.catch(() => resolve(persistedAuth))
		} else {
			resolve(persistedAuth)
		}
	})
}

const getKeyChainAuth = () => {
	return new Promise((resolve, reject) => {
		window.ipcRenderer.once(IpcEvent.persisted.authResponse, (event, arg) => {
			const keyChainAuth = arg

			clearTimeout(keyChainAuthTimeout)
			if (keyChainAuth && keyChainAuth.token) {
				resolve(keyChainAuth)
			} else {
				// Give back an empty object if the DesktopApp sends back something invalid
				resolve({})
			}
		})

		// Set a timeout for this in case the site is loaded in an older DesktopApp that can't send this response
		const keyChainAuthTimeout = setTimeout(() => {
			reject('Keychain auth request timed out')
		}, 2500)

		window.ipcRenderer.send(IpcEvent.persisted.authRequest)
	})
}

export const buildStorageKey = key => `nma:${key}`
