import { vsApi, requests } from 'helpers/apiHelpers'

//region Action types
export const SET_AGREEMENT_DATA = 'SET_AGREEMENT_DATA'

export const SET_AGREEMENT_REQUEST = 'SET_AGREEMENT_REQUEST'
export const SET_AGREEMENT_SUCCESS = 'SET_AGREEMENT_SUCCESS'
export const SET_AGREEMENT_FAILURE = 'SET_AGREEMENT_FAILURE'
//endregion Action types

//region Async Action Helpers
export const setMemberUsageCompliance = (usageComplianceData, messages) => {
	return {
		type: SET_AGREEMENT_DATA,
		usageComplianceRequired: true,
		usageComplianceData,
		messages,
	}
}

export const asyncSetAgreement = (accept = true) => {
	return vsApi({
		endpoint: requests.setAgreement.endpoint,
		actions: {
			request: SET_AGREEMENT_REQUEST,
			success: SET_AGREEMENT_SUCCESS,
			failure: SET_AGREEMENT_FAILURE,
		},
		requestOptions: requests.setAgreement.getRequestOptions(accept),
		context: {
			accept,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}
//endregion

//region Initial State
const initialSettings = {
	usageComplianceRequired: false,
	pageTitle: '',
	bodyText: '',
	buttonAgree: '',
	buttonDecline: '',
	pageTitleDecline: '',
	bodyTextDecline: '',
	buttonTryAgain: '',
	saveSuccess: false,
}

const initialErrors = {
	errors: [],
}

const initialState = { ...initialSettings, ...initialErrors }
//endregion

// Reducer TODO: reducer composition/slicing the state (http://redux.js.org/docs/basics/Reducers.html)
export default function scheduleSettingsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AGREEMENT_DATA: {
			// When the API errors because user compliance is required, the error response already includes the member compliance data which is passed in here
			// so there is no need for a separate fetch action
			// Note: the API returns properties with the first letter capitalized as seen below
			return {
				...state,
				isPending: false,
				usageComplianceRequired: action.usageComplianceRequired,
				pageTitle: action.usageComplianceData.PageTitle,
				bodyText: action.usageComplianceData.BodyText,
				buttonAgree: action.usageComplianceData.ButtonAgree,
				buttonDecline: action.usageComplianceData.ButtonDecline,
				pageTitleDecline: action.usageComplianceData.PageTitleDecline,
				bodyTextDecline: action.usageComplianceData.BodyTextDecline,
				buttonTryAgain: action.usageComplianceData.ButtonTryAgain,
			}
		}
		case SET_AGREEMENT_REQUEST: {
			return {
				...state,
				isPending: true,
			}
		}
		case SET_AGREEMENT_SUCCESS: {
			return {
				...state,
				isPending: false,
				usageComplianceRequired: !action.context.accept,
			}
		}
		case SET_AGREEMENT_FAILURE: {
			return {
				...state,
				isPending: false,
				usageComplianceRequired: true,
			}
		}
		default:
			return state
	}
}
