import React from 'react'
import PropTypes from 'prop-types'

// Helpers
import { stringIsEmptyOrWhitespace } from '@dysi/js-helpers'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'

// Styles
import { width, flexContainer, fontSize, color, border } from 'styles/variables'

const styles = {
	container: {
		// Margins
		marginTop: width.spacing.basic,
		marginBottom: width.spacing.basic,
		marginLeft: 0,
		marginRight: 0,

		// Padding
		paddingTop: 0,
		paddingBottom: width.spacing.basic,
		paddingLeft: width.spacing.basic,
		paddingRight: width.spacing.basic,

		[BreakpointNames.small]: {
			marginTop: 0,
			marginBottom: 0,
			paddingBottom: 0,
		},
	},
	title: {
		...flexContainer('row', 'space-between', 'center'),
		width: '100%',
	},
	description: {
		marginTop: 0,
		marginBottom: 0,
		marginRight: 0,
		marginLeft: 0,
		fontSize: fontSize.small,
		color: color.primaryGrey,
		[BreakpointNames.small]: {
			marginBottom: width.spacing.basic,
		},
	},
	border: {
		borderBottom: border.basic,
		paddingBottom: width.spacing.wide,

		[BreakpointNames.small]: {
			paddingBottom: width.spacing.basic,
		},
	},
	header: {
		margin: 0,
	},
}

class PanelTitle extends React.Component {
	static propTypes = {
		text: PropTypes.string.isRequired,
		links: PropTypes.object,
		description: PropTypes.string,
		viewport: responsive.PropType,
		hasBorder: PropTypes.bool,
		style: PropTypes.object,
	}

	render() {
		const { links, description, style: styleOverride, text, viewport, hasBorder } = this.props

		if (stringIsEmptyOrWhitespace(text)) return null

		const containerStyle = {
			...styles.container,
			...styles.container[viewport.size],
			...(hasBorder && {
				...styles.border,
				...styles.border[viewport.size],
			}),
			...styleOverride,
		}

		const descriptionStyle = {
			...styles.description,
			...styles.description[viewport.size],
		}

		return (
			<div style={containerStyle}>
				<div style={styles.title}>
					{/* Heading */}
					<h1 style={styles.header}>{text}</h1>
					{/* Optional Links */}
					{links && <div>{links}</div>}
				</div>
				{/* Optional Description */}
				{description && <p style={descriptionStyle}>{description}</p>}
			</div>
		)
	}
}

export default responsive(PanelTitle)
