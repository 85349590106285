import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { width, color, borderStyle, padding } from 'styles/variables'

const styles = {
	base: {
		display: 'flex',
		backgroundColor: color.backgroundRed,
		color: color.secondaryRed,
		borderTop: `${width.border.basic}px ${borderStyle.solid} ${color.secondaryRed}`,
		borderBottom: `${width.border.basic}px ${borderStyle.solid} ${color.secondaryRed}`,
	},
	message: {
		paddingTop: padding.narrow,
		paddingBottom: padding.narrow,
		paddingRight: padding.wide,
		paddingLeft: padding.wide,
	},
}

class ErrorIndicator extends Component {
	static propTypes = {
		message: PropTypes.string,
		isOpen: PropTypes.bool,
		style: PropTypes.object,
	}
	render() {
		const { message, isOpen, style: styleOverride } = this.props

		const indicatorStyle = {
			...styles.base,
			...styleOverride,
		}

		return isOpen ? (
			<div style={indicatorStyle}>
				<span style={styles.message}>{message}</span>
			</div>
		) : null
	}
}

export default ErrorIndicator
