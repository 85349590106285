import React from 'react'
import PropTypes from 'prop-types'

// Common Scenes
import AppStyles from './AppStyles'
import AccessibleFocusStyles from 'styles/AccessibleFocusStyles/AccessibleFocusStyles'
import DesktopListener from 'components/DesktopListener/DesktopListener'
import RelayListener from 'components/RelayListener/RelayListener'
import EnvironmentBanner from 'components/Banner/EnvironmentBanner'
import Header from 'scenes/Header/Header'
import AppRoutes from './AppRoutes'
import SideMenu from 'scenes/SideMenu/SideMenu'
import AppDownloadDialog from 'components/Downloads/AppDownloadDialog/AppDownloadDialog'
import Footer from 'components/Footer/Footer'
import ShareDialog, { ShareText } from 'scenes/ShareDialog/ShareDialog'
import CustomLinks from 'scenes/CustomLinks/CustomLinks'
import DownloadBanner from 'components/Banner/DownloadBanner'
import WelcomeBanner from 'components/Banner/WelcomeBanner'
// import UpdateBanner from 'components/Banner/UpdateBanner'
import OfflineChecker from 'components/OfflineChecker/OfflineChecker'
import OfflineDialog from 'components/OfflineDialog/OfflineDialog'
import MemberUsageCompliance from 'scenes/MemberUsageCompliance/MemberUsageCompliance'
import AnnouncementDialog from 'scenes/Announcements/AnnouncementDialog'
import PagePopup from 'scenes/Content/components/PagePopup'
import ActionDisabledDialog from 'components/Dialog/ActionDisabledDialog/ActionDisabledDialog'
import GoogleAnalytics from 'scenes/GoogleAnalytics/GoogleAnalytics'
import SkipLinkBanner from 'components/Banner/SkipLinkBanner'
import CategoryDiscovery from 'scenes/Category/CategoryDiscovery'

// Loadable Components
import Loadable from 'react-loadable'
import withRouter from 'react-router-dom/withRouter'
import matchPath from 'react-router-dom/matchPath'

// Helpers
import Routes from 'helpers/routes'
import { isDesktopApp, browserGuess, browserVersionGuess } from 'helpers/browserhelper'
import { safeGetNestedProp } from '@dysi/js-helpers'
import PendoAnalytics from 'scenes/PendoAnalytics/PendoAnalytics'
import ShareCompleteDialog from 'scenes/ShareDialogV8/components/ShareCompleteDialog'

export const SubmitPost = Loadable({
	loader: () => import('scenes/SubmitPost/SubmitPost'),
	loading: () => {
		return null
	},
})
export const SubmitPostManager = Loadable({
	loader: () => import('scenes/SubmitPostManager/SubmitPostManager'),
	loading: () => {
		return null
	},
})
export const UrgentBroadcast = Loadable({
	loader: () => import('scenes/Broadcast/UrgentBroadcast'),
	loading: () => {
		return null
	},
})

const styles = {
	app: {
		display: 'flex',
		flexDirection: 'column',
		height: '1', // NOTE: Stupid IE11 bug that fixes min-height. https://stackoverflow.com/questions/25177791/ie11-flexbox-and-vh-height-units-not-compatible
		minHeight: '100vh',
	},
}

class App extends React.Component {
	static propTypes = {
		// from withRouter
		location: PropTypes.object,
	}

	componentDidMount() {
		ShareText.preload()
	}

	refreshPage = isOnline => {
		if (isOnline && !!window) window.location.reload()
	}

	render() {
		const { location } = this.props
		const isPreviewPath = matchPath(location.pathname, { path: Routes.Preview.routerPath })
		const isExternalPath = matchPath(location.pathname, { path: Routes.External.routerPath })

		return isPreviewPath ? ( // Render just the raw component, no app chrome (header, footer, sidebar, ...)
			<div id="flex-root" style={styles.app}>
				<AppStyles />
				<AppRoutes />
			</div>
		) : isExternalPath ? ( // Render just the raw component, no app chrome (header, footer, sidebar, ...)
			<div id="flex-root" style={styles.app}>
				<AppStyles />
				<GoogleAnalytics />
				<AppRoutes />
			</div>
		) : (
			<div id="flex-root" style={styles.app}>
				<AppStyles />
				<AccessibleFocusStyles />
				<RelayListener />
				<GoogleAnalytics />
				<PendoAnalytics />
				{isDesktopApp && <DesktopListener />}
				<OfflineChecker onChange={this.refreshPage}>
					<OfflineDialog />
				</OfflineChecker>
				<SkipLinkBanner />
				<EnvironmentBanner />
				<DownloadBanner />
				<WelcomeBanner />
				<CategoryDiscovery />
				{/* <UpdateBanner /> */}
				<CustomLinks />
				<Header />
				<AppRoutes />
				<SideMenu />
				<SubmitPost />
				<SubmitPostManager />
				<ShareDialog />
				<ShareCompleteDialog />
				<UrgentBroadcast />
				<AppDownloadDialog />
				<MemberUsageCompliance />
				<AnnouncementDialog />
				<ActionDisabledDialog />
				<PagePopup />
				<Footer />
			</div>
		)
	}
}

export default withRouter(App)
