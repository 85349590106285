import { replace } from 'react-router-redux'

// Helpers
import { decodeQueryParams, encodeQueryParams } from '@dysi/js-helpers'

// Actions
import { toggleSubmitPost } from 'reducers/browser.reducer'

// Enums
import Actions from 'helpers/actions'
import { shouldSkipMiddleware } from './openShareDialogMiddleware'

// This middleware will open the submitPostDialog coming from BrandApp redirect and remove it from the URL

// TODO: maybe we can combine the middleware into an actions middleware later on
export const openSubmitPostMiddleware = history => ({ dispatch, getState }) => next => action => {
	if (shouldSkipMiddleware(getState())) {
		return next(action)
	}

	if (history.location.search && history.location.search.includes('action')) {
		const query = decodeQueryParams(history.location.search)
		if (query.action && query.action === Actions.createPost) {
			// Remove params from URL
			const newQuery = { ...query }
			delete newQuery.action

			const search = encodeQueryParams(newQuery)
			const newLocation = {
				...history.location,
				search,
			}

			dispatch(replace(newLocation))
			dispatch(toggleSubmitPost())
		}
	}

	return next(action)
}
