export default {
	articleBroadcastDesktop: 'ArticleBroadcastDesktop',
	articleCommentLikeDesktop: 'ArticleCommentLikeDesktop',
	articleCommentReplyDesktop: 'ArticleCommentReplyDesktop',
	broadcastDesktop: 'BroadcastDesktop',
	broadcastSurveyDesktop: 'BroadcastSurveyDesktop',
	chatDesktop: 'ChatDesktop',
	newsletterBroadcastDesktop: 'NewsletterBroadcastDesktop',
	postApprovalDesktop: 'PostApprovalDesktop',
	urgentBroadcastDesktop: 'UrgentBroadcastDesktop',
	userMentionDesktop: 'UserMentionDesktop',
}
