import React, { Fragment } from 'react'
import { Style } from 'radium'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { safeGetNestedProp } from '@dysi/js-helpers'

import { setIsAccessibleKeyboardTabStyles } from 'reducers/browser.reducer'

const mapStateToProps = (state, ownProps) => {
	return {
		isAccessibleKeyboardTabStyles: safeGetNestedProp(state, 'browser.isAccessibleKeyboardTabStyles'),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setAccessibleKeyboardTabStyle: isEnabled => dispatch(setIsAccessibleKeyboardTabStyles(isEnabled)),
	}
}

const styles = {
	itemRules: {
		'button:focus': {
			outline: 'none',
		},
		'a:focus': {
			outline: 'none',
		},
		'input:focus': {
			outline: 'none',
		},
		'textarea:focus': {
			outline: 'none',
		},
		'.accessible-focus:focus': {
			outline: 'none',
		},
	},
}

/**
 * This middleware component handles focus outline for keyboard and mouse users.
 * If the users 'tabs' let the browser outline takeover otherwise it is disabled.
 *
 * Extended state to redux store to allow for dynamic keyboard tabbing only styles through out the App.
 */
class AccessibleFocusStyles extends React.Component {
	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		window.removeEventListener('mousedown', this.handleMouseDown)
	}

	// Listen for a 'tab' keydown and let the browser focus outline style takeover
	handleKeyDown = e => {
		if (e.key === 'Tab') {
			this.props.setAccessibleKeyboardTabStyle(true)
			window.removeEventListener('keydown', this.handleKeyDown)
			window.addEventListener('mousedown', this.handleMouseDown)
		}
	}

	// Handles the edge case where a user 'tabs' but then reverts to mouse
	handleMouseDown = () => {
		this.props.setAccessibleKeyboardTabStyle(false)
		window.removeEventListener('mousedown', this.handleMouseDown)
		window.addEventListener('keydown', this.handleKeyDown)
	}

	render() {
		return (
			<Fragment>
				<Style rules={this.props.isAccessibleKeyboardTabStyles ? {} : styles.itemRules} />
				{this.props.children}
			</Fragment>
		)
	}
}

AccessibleFocusStyles.propTypes = {
	children: PropTypes.node,
	isAccessibleKeyboardTabStyles: PropTypes.bool,
	setAccessibleKeyboardTabStyle: PropTypes.func,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccessibleFocusStyles)
