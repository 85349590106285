// List of supported RTL language ISO codes (justified right-to-left)
const rtlLanguages = ['he', 'ar', 'dv', 'ff', 'ku', 'az', 'fa', 'ur']

/**
 * @param {string} langCode ISO language code
 * @returns {boolean}
 */
export const isRtlLanguage = langCode => {
	return rtlLanguages.includes(langCode)
}
