import React from 'react'
import PropTypes from 'prop-types'

import lang from './loading.lang'

// Components
import Icon, { Name, Modifier } from 'components/Icons/FontAwesome'
import { color, fontSize, width } from 'styles/variables'
import Button from 'components/Button/Button'

// Styles
const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		flex: 1,
		color: color.primaryGrey,
	},
	spinner: {
		fontSize: 30,
	},
	timeout: {
		fontSize: 45,
	},
	error: {
		fontSize: 45,
	},
	loadingText: {
		fontSize: fontSize.base,
		marginTop: width.spacing.narrow,
		textAlign: 'center',
		marginBottom: width.spacing.narrow,
	},
}

class Loading extends React.Component {
	static propTypes = {
		style: PropTypes.any,
		iconStyle: PropTypes.any,
		children: PropTypes.node,
		error: PropTypes.object,
		delay: PropTypes.number,
		// React Loadable
		timedOut: PropTypes.bool,
		retry: PropTypes.func,
	}

	static defaultProps = {
		delay: 1500,
	}

	state = {
		isDelayed: this.props.delay > 0,
	}

	componentDidMount() {
		const { delay } = this.props
		const { isDelayed } = this.state

		if (isDelayed) {
			this.timeout = setTimeout(() => {
				this.setState({
					isDelayed: false,
				})
			}, delay)
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout)
		}
	}

	renderError(error) {
		return (
			<div style={{ ...styles.container }}>
				<Icon name={Name.exclamation} style={styles.error} />
				<div style={styles.loadingText}>{lang.error}</div>
				{this.props.retry && (
					<React.Fragment>
						<div style={styles.loadingText}>{lang.clickTheButton}</div>
						<Button onClick={this.props.retry}>{lang.retry}</Button>
					</React.Fragment>
				)}
			</div>
		)
	}

	renderTimeout() {
		return (
			<div style={{ ...styles.container }}>
				<span style={styles.loadingText}>{lang.timeout}</span>
			</div>
		)
	}

	loadContent() {
		if (this.props.children) return this.props.children

		return <span style={styles.loadingText}>{/* {lang.loading} */}</span>
	}

	render() {
		const {
			style: containerStyle,
			iconStyle,
			// React Loadable props
			error,
			timedOut,
		} = this.props

		const { isDelayed } = this.state

		if (error) return this.renderError(error)
		else if (timedOut) return this.renderTimeout()

		const dynamicStyles = {
			container: {
				...styles.container,
				...containerStyle,
				...(isDelayed ? { visibility: 'hidden' } : {}),
			},
		}

		return (
			<div style={dynamicStyles.container}>
				<Icon
					name={Name.spinner}
					modifier={Modifier.pulse}
					color={iconStyle && iconStyle.color ? iconStyle.color : color.primaryGrey}
					style={{ ...styles.spinner, ...iconStyle }}
				/>

				{this.loadContent()}
			</div>
		)
	}
}

export default Loading
