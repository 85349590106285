'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'far';
var iconName = 'rocket';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'f135';
var svgPathData = 'M367.96813,103.99609a39.999,39.999,0,1,0,40.00384,40A40.02908,40.02908,0,0,0,367.96813,103.99609ZM505.07337,19.3418c-1.21875-5.60742-6.75-11.13868-12.34373-12.3418-32.62885-7-58.162-7-83.57017-7C305.39988,0,242.95858,55.0918,196.236,127.99609H94.82015c-16.34567.01563-35.53314,11.875-42.87883,26.48243L2.53125,253.28906A28.12512,28.12512,0,0,0,0,263.99219a24.00617,24.00617,0,0,0,24.00191,23.998h92.63266l-10.59373,21.42188c-9.33592,18.91016,4.27733,34.77344,6.15624,36.62305l53.75381,53.71875c15.56443,15.54492,33.81635,7.52929,36.6601,6.13867l21.34567-10.57617V488a24.00659,24.00659,0,0,0,24.00191,24,28.618,28.618,0,0,0,10.71873-2.51562l98.6971-49.4043c14.625-7.29688,26.50191-26.5,26.50191-42.85938V315.69336c72.72449-46.76367,128.10525-109.44922,128.10525-212.69727C512.07531,77.4668,512.07531,51.99805,505.07337,19.3418ZM358.53065,274.99023c-36.94135,18.48438-121.10527,60.14063-166.7966,82.73243l-37.50189-37.49805c22.59567-45.6875,64.25575-129.99609,82.72447-166.88672C284.33741,79.5293,335.96623,47.99805,409.15947,47.99805c18.00192,0,34.2851,0,52.56632,2.34375,2.375,18.71875,2.31249,35.27929,2.25,52.63867C463.97578,175.75977,432.41138,227.30469,358.53065,274.99023Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faRocket = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;