import React from 'react'
import PropTypes from 'prop-types'

// Components
import Icon, { Name } from 'components/Icons/FontAwesome'

// Styles
import styles from './BadgeIcon.styles'

// Associates the DySi Icon type to the Font Awesome Icon name we want to display
export const iconTypeToNameDict = {
	Award: Name.awardOutline,
	BirthdayCake: Name.birthdayCake,
	BullseyeArrow: Name.bullsEyeArrow,
	Comments: Name.comments,
	CommentLines: Name.commentLinesOutline,
	Rocket: Name.rocketOutline,
	Star: Name.star,
	ThumbsUp: Name.thumbsUp,
}

/**
 * BadgeIcon presentational component. ImageUrl has priority over the icon type and color.
 *
 * @param {BadgeIconPropTypes} props
 */
const BadgeIcon = ({ color, iconType, imageUrl, style: stylesOverride }) => {
	// This Fa icon setup doesn't auto-scale well, so until we need truly responsive icons, this component expects a concrete width
	// There is also an issue on the underlying Icon component not detecting style prop changes correctly
	const iconSize = stylesOverride && stylesOverride.width ? stylesOverride.width : 30

	const containerStyle = {
		...styles.container,
		...stylesOverride,
		width: iconSize,
		height: iconSize,
	}

	if (imageUrl) {
		// Create a unique pattern Id in case multiple badges are shown
		const patternId = `ImgPatternFor-${imageUrl}`

		return (
			<div style={containerStyle}>
				<svg
					style={styles.svg}
					width="100%"
					height="100%"
					viewBox="0 0 90 90"
					xmlns="http://www.w3.org/2000/svg"
				>
					<defs>
						<pattern id={patternId} patternUnits="userSpaceOnUse" width="100%" height="100%">
							<image xlinkHref={imageUrl} x="0" y="0" width="100%" height="100%" />
						</pattern>
					</defs>
					<path
						fill={`url(#${patternId})`}
						d="M16.5089 0L0 16.5089V73.4911L16.5089 90H73.4911L90 73.4911V16.5089L73.4911 0H16.5089Z"
					/>
				</svg>
			</div>
		)
	} else {
		const iconStyle = {
			fontSize: iconSize / 2,
		}

		const backgroundColor = color ? color : '#CCCCCC'

		const badgeIconName = iconTypeToNameDict[iconType]
			? iconTypeToNameDict[iconType]
			: Name.question

		return (
			<div style={containerStyle}>
				<svg
					style={styles.svg}
					width="100%"
					height="100%"
					viewBox="0 0 90 90"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill={backgroundColor}
						d="M16.5089 0L0 16.5089V73.4911L16.5089 90H73.4911L90 73.4911V16.5089L73.4911 0H16.5089Z"
					/>
				</svg>
				{/* IE needs this wrapping div or else it absolute positions the Icon somewhere different than other browsers do */}
				<div style={styles.iconContainer}>
					<Icon name={badgeIconName} style={iconStyle} />
				</div>
			</div>
		)
	}
}

/**
 * @typedef {object} BadgeIconPropTypes - Proptypes for the BadgeIcon component
 * @property {string} color - The badge color
 * @property {string} iconType - The type of icon we want to display
 * @property {object} style - The style override for the icon container
 * @property {string} imageUrl - The image url to use for the icon
 */
BadgeIcon.propTypes = {
	color: PropTypes.string,
	iconType: PropTypes.string,
	style: PropTypes.object,
	imageUrl: PropTypes.string,
}

export default BadgeIcon
