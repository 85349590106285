import { vsApi, requests } from 'helpers/apiHelpers'

// Action Types
const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

const CLEAR_USERS = 'CLEAR_USERS'

// Action Creators
export const asyncGetUsers = (term = '', searchType = 'Contains', skip = 0, take = 100) => {
	return vsApi({
		endpoint: requests.directorySearch.endpoint,
		actions: {
			request: FETCH_USERS_REQUEST,
			success: FETCH_USERS_SUCCESS,
			failure: FETCH_USERS_FAILURE,
		},
		requestOptions: requests.directorySearch.getRequestOptions(term, searchType, skip, take),
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const clearUsers = () => {
	return {
		type: CLEAR_USERS,
	}
}

// Initial State
const initialState = {
	users: [],
	total: null,
	next: null,
	isLoading: false,
}

// Reducer
export default function directoryReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_USERS_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case FETCH_USERS_SUCCESS: {
			const { users, total, next } = action.response
			const ids = new Set()
			users.forEach(user => ids.add(user.userId))
			// If multiple fetch users requests are sent, remove any old users that are part of the latest response.
			// Ensures that there aren't duplicates and correct order is maintained.
			const prevUsers = state.users.filter(user => !ids.has(user.userId))
			return {
				...state,
				isLoading: false,
				users: [...prevUsers, ...users],
				total,
				next,
			}
		}
		case FETCH_USERS_FAILURE: {
			return {
				...state,
				...initialState,
			}
		}
		case CLEAR_USERS: {
			return {
				...state,
				users: [],
				total: null,
				next: null,
			}
		}
		default:
			return state
	}
}
