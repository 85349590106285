import './red5pro/red5pro/red5pro-media.css'
import mediaEventTypes from 'scenes/Post/enums/mediaViewEventTypes'
import { LiveStreamData } from './data/livestream.data'

// A map to hold the mobile and web sdk versions
const red5SdkVersionMap = new Map()
red5SdkVersionMap.set('0.0.0.0', '4.2.0')
red5SdkVersionMap.set('4.7.7.0', '4.2.0')
red5SdkVersionMap.set('7.2.0.0', '8.0.0')

export const getRed5SdkBasePath = mobileSdkVersion => {
	const webSdkVersion = red5SdkVersionMap.get(mobileSdkVersion)
	if (!webSdkVersion || webSdkVersion === '4.2.0') {
		// The old SDK
		return 'https://static.dynamicsignal.com/plugins/red5pro/'
	} else {
		// The new SDK
		return `https://static.dynamicsignal.com/plugins/red5pro-${webSdkVersion}/`
	}
}

export const createSubscriber = (
	liveStreamInfo,
	recordingCallback,
	red5prosdk,
	getOrientation,
	sendFullScreenState,
	setStatus,
	setSubscriber
) => {
	let retryTimeout
	let connected = false

	const retryConnect = () => {
		clearTimeout(retryTimeout)
		if (!connected) {
			retryTimeout = setTimeout(connect, 1000)
		}
	}

	const setConnected = value => {
		connected = value
		if (!connected) {
			retryConnect()
		}
	}

	const connect = () => {
		clearTimeout(retryTimeout)

		const RED5PRO_SUBSCRIBER = new red5prosdk.Red5ProSubscriber()
		RED5PRO_SUBSCRIBER.setPlaybackOrder(['rtc', 'rtmp'])
			.init(LiveStreamData(liveStreamInfo))
			.then(function(subscriber) {
				let metaDataSent = false
				subscriber.on('*', function(event) {
					let mediaEvent = mediaEventTypes.none

					switch (event.type) {
						case red5prosdk.RTCSubscriberEventTypes.OFFER_START:
							setStatus(red5prosdk.RTCSubscriberEventTypes.OFFER_START)
							break
						case red5prosdk.SubscriberEventTypes.SUBSCRIBE_START:
							setStatus(red5prosdk.SubscriberEventTypes.SUBSCRIBE_START)
							mediaEvent = mediaEventTypes.livestreamStarted
							break
						case red5prosdk.SubscriberEventTypes.CONNECTION_CLOSED:
						case red5prosdk.SubscriberEventTypes.UNPUBLISH_SUCCESS:
						case red5prosdk.SubscriberEventTypes.PLAY_UNPUBLISH:
							setStatus(red5prosdk.SubscriberEventTypes.PLAY_UNPUBLISH)
							mediaEvent = mediaEventTypes.livestreamStopped
							break
						case red5prosdk.SubscriberEventTypes.SUBSCRIBE_METADATA:
							if (!metaDataSent) getOrientation(Number(event._data.orientation))
							metaDataSent = true
							break
						case red5prosdk.SubscriberEventTypes.FULL_SCREEN_STATE_CHANGE:
							sendFullScreenState(event._data)
							break
						case red5prosdk.SubscriberEventTypes.SUBSCRIBE_FAIL:
							setStatus(red5prosdk.SubscriberEventTypes.SUBSCRIBE_FAIL)
							setConnected(false)
							break
						case red5prosdk.SubscriberEventTypes.CONNECT_SUCCESS:
							subscriber.play()
							break
						default:
							break
					}

					if (mediaEvent !== mediaEventTypes.none) {
						// Record the event
						recordingCallback(mediaEvent)
					}
				})
				// `subcriber` is the WebRTC Subscriber instance.
				return subscriber.subscribe()
			})
			.then(function(subscriber) {
				setSubscriber(subscriber)
				setConnected(true)
			})
			.catch(function(error) {
				setStatus(red5prosdk.SubscriberEventTypes.SUBSCRIBE_FAIL)
				setConnected(false)
			})
	}

	connect()
}
