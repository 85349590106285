import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Style } from 'radium'

// Components
import Banner from './Banner'
import HtmlText from 'components/HtmlText/HtmlText'

// Styles
import { color, flexContainer, width } from 'styles/variables'

// Helpers
import { vsApi, requests } from 'helpers/apiHelpers'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { storage, buildStorageKey } from 'helpers/persistor'
import { dismissWelcomeBannerEvent } from 'scenes/GoogleAnalytics/GoogleAnalytics'

//region Styles
const styles = {
	banner: {
		background: '#fff8da',
		// CSS rules specific to Welcome Banner since users can specify their own HTML - different from normal styling
		contentRules: {
			a: {
				color: color.linkPrimary,
			},
		},
	},
	container: {
		color: color.primaryBlack,
		width: '100%',
		maxWidth: width.layout.page,
		...flexContainer('row', 'space-between'),
		small: {
			flexWrap: 'wrap',
		},
	},
	button: {
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		small: {
			width: '100%',
			textAlign: 'right',
		},
	},
}
//endregion

const storageKey = buildStorageKey('welcomeBannerShown')

const mapStateToProps = state => {
	const currentUserId = safeGetNestedProp(state, 'auth.user.userId')
	const hasWelcomeBannerDismissed = storage && storage.getItem(storageKey)
	const viewport = state.browser.viewport
	const enableWelcomeBanner = safeGetNestedProp(state, 'sphere.parameters.enableWelcomeBanner')
	const welcomeBannerMessage = safeGetNestedProp(state, 'sphere.localizations.welcomeBannerMessage')
	const welcomeBannerButtonText = safeGetNestedProp(
		state,
		'sphere.localizations.welcomeBannerButtonText'
	)
	const welcomeBannerDismissed = safeGetNestedProp(state, 'auth.user.welcomeBannerDismissed')

	return {
		currentUserId,
		enableWelcomeBanner,
		hasWelcomeBannerDismissed,
		viewport,
		welcomeBannerMessage,
		welcomeBannerButtonText,
		welcomeBannerDismissed,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		dismissWelcomeBanner: userId =>
			dispatch(
				vsApi({
					endpoint: requests.dismissWelcomeBanner.getEndpoint(userId),
					requestOptions: requests.dismissWelcomeBanner.requestOptions,
				})
			),
	}
}

class WelcomeBanner extends Component {
	static propTypes = {
		currentUserId: PropTypes.number,
		dismissWelcomeBanner: PropTypes.func.isRequired,
		enableWelcomeBanner: PropTypes.bool,
		hasWelcomeBannerDismissed: PropTypes.bool,
		viewport: PropTypes.object,
		welcomeBannerButtonText: PropTypes.string,
		welcomeBannerMessage: PropTypes.string,
		welcomeBannerDismissed: PropTypes.bool,
	}

	state = {
		showWelcomeBanner: false,
	}

	componentDidMount() {
		this.showWelcomeBannerIfNeeded()
	}

	componentDidUpdate(prevProps) {
		const { currentUserId, welcomeBannerDismissed } = this.props
		if (
			currentUserId !== prevProps.currentUserId ||
			welcomeBannerDismissed !== prevProps.welcomeBannerDismissed
		) {
			this.showWelcomeBannerIfNeeded()
		}
	}

	showWelcomeBannerIfNeeded = () => {
		const { currentUserId, hasWelcomeBannerDismissed, welcomeBannerDismissed } = this.props

		// Only show banner if it hasn't been dismissed before
		if (
			((currentUserId && !welcomeBannerDismissed) || !currentUserId) &&
			!hasWelcomeBannerDismissed
		) {
			this.setState({ showWelcomeBanner: true })
		} else if (currentUserId && welcomeBannerDismissed && !hasWelcomeBannerDismissed) {
			this.dismissWelcomeBanner()
		}
	}

	dismissWelcomeBanner = () => {
		const {
			currentUserId,
			dismissWelcomeBanner,
			welcomeBannerDismissed,
			hasWelcomeBannerDismissed,
		} = this.props
		const { showWelcomeBanner } = this.state

		const dismissedDate = new Date().toISOString() // could be anything
		if (storage && !hasWelcomeBannerDismissed) {
			const data = JSON.stringify({ dismissedDate })
			storage.setItem(storageKey, data)
			const event = document.createEvent('Event')
			event.initEvent(dismissWelcomeBannerEvent, true, true)
			window.dispatchEvent(event)
		}

		if (showWelcomeBanner) {
			this.setState({ showWelcomeBanner: false })
		}

		if (currentUserId && !welcomeBannerDismissed) dismissWelcomeBanner(currentUserId)
	}

	render() {
		const {
			enableWelcomeBanner,
			viewport,
			welcomeBannerMessage,
			welcomeBannerButtonText,
		} = this.props
		const { showWelcomeBanner } = this.state

		if (!enableWelcomeBanner || !showWelcomeBanner) return null

		const config = { ALLOWED_ATTR: ['target', 'href'] }
		const dynamicStyles = {
			container: {
				...styles.container,
				...styles.container[viewport.size],
			},
			button: {
				...styles.button,
				...styles.button[viewport.size],
			},
		}

		return (
			<Banner style={styles.banner}>
				<div style={dynamicStyles.container}>
					<div id="welcome-banner">
						<Style scopeSelector="#welcome-banner" rules={styles.banner.contentRules} />
						<HtmlText config={config}>{welcomeBannerMessage}</HtmlText>
					</div>
					<div style={dynamicStyles.button} onClick={this.dismissWelcomeBanner}>
						<b>
							<u>{welcomeBannerButtonText}</u>
						</b>
					</div>
				</div>
			</Banner>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WelcomeBanner)
