// ApprovedPostData represents the relationship between an approved post and its social comments. Please add any missing props or calculations you need below.
// 	o Keep lists in alphabetical order!
/**
 * ApprovedPostData represents the relationship between an approved post and its social comments.
 * @param {Object} post
 */
const ApprovedPostData = post => {
	return {
		postId: post.postId,

		// Native props - Alphabetical, please!

		// Calculated props - Alphabetical, please!
		// - Use these when the property isn't a 1:1 map with the API post object
		socialCommentIds: post.socialComments.map(comment => comment.id),

		/** Verifies that the comment meets the minimum requirements needed to be valid. */
		get isValid() {
			delete this.isValid
			return (this.isValid = !!(this.postId && this.socialCommentIds.length > 0))
		},
	}
}
export default ApprovedPostData
