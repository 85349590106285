import { logTypes, logLevels } from 'reducers/error.reducer'
import { getActivity, Activity } from './activityHelpers'

export const postIncludes = {
	content: 'content',
	documents: 'documents',
	images: 'images',
	rawContent: 'rawcontent',
	watchVideo: 'watchvideo',
	survey: 'survey',
}

export const userIncludes = {
	affiliations: 'affiliations',
	channels: 'channels',
	images: 'images',
	scheduleSettings: 'scheduleSettings',
}

export const postIncludesDefault = Object.values(postIncludes)
	.filter(include => include !== postIncludes.watchVideo)
	.join(',')
export const userIncludesDefault = Object.values(userIncludes).join(',')
const includesQuery = includes => `include=${includes}`
const typeaheadIncludes =
	'include=images&includeManagers=true&includePreregistered=true&includeEmails=true&includeMembers=true'

const apiRequests = {
	//#region Auth

	/** Retrieves the currently logged-in user */
	getCurrentUser: {
		// DON'T CHANGE THIS! user in store must be consistent, regardless of why it was fetched
		endpoint: `user?${includesQuery(userIncludesDefault)}`,
	},

	/** Retrieves a short token for logged-in user */
	getShortToken: {
		endpoint: 'login/shorttoken',
	},

	getLongToken: {
		endpoint: 'login/longtoken',
	},

	/** Retrieves a short token for logged-in user */
	// Only called by mobile / desktop apps
	getTokensFromRefreshToken: {
		endpoint: 'login/token',
		getRequestOptions: (refreshToken, deviceId) => {
			// Build the form data
			var form = new FormData()
			form.append('token', refreshToken)
			form.append('deviceId', deviceId)

			return {
				method: 'POST',
				body: form,
			}
		},
	},

	/** TODO - This should probably be updated to handle usernames or whatever else */
	login: {
		endpoint: 'login',
		getRequestOptions: (email, password) => {
			// Build the form data
			var form = new FormData()
			form.append('email', email)
			form.append('password', password)

			return {
				method: 'POST',
				body: form,
			}
		},
	},

	/** Sets default sharing channels */
	setUserDefaultSharingChannels: {
		getEndpoint: userId => `user/${userId}/defaultsharingchannels`,
		getRequestOptions: userChannelIds => {
			// Build the form data
			var form = new FormData()
			form.append('userChannelIds', userChannelIds)

			return {
				method: 'POST',
				body: form,
			}
		},
	},

	/** Changes the password for a user who is logged in */
	changePassword: {
		endpoint: 'login/changepassword',
		getRequestOptions: (currentPassword, newPassword) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					currentPassword,
					newPassword,
				}),
			}
		},
	},

	/** Reset password for a user from Forgot Password flow */
	resetPassword: {
		endpoint: 'login/resetpassword',
		getRequestOptions: (token, newPassword) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					token,
					newPassword,
				}),
			}
		},
	},

	/** Allows the user to either CHANGE or REMOVE their email. This is an API PATCH method */
	removeEmail: {
		getEndpoint: userId => apiRequests.updateUser.getEndpoint(userId),
		requestOptions: {
			method: 'PATCH',
			body: JSON.stringify([
				{
					op: 'remove',
					path: '/email',
				},
			]),
		},
	},

	//#endregion

	//#region Broadcasts

	//** Retrive specific broadcast */
	getBroadcast: {
		getEndpoint: broadcastId => `broadcast?id=${broadcastId}`,
	},

	//** Mark broadcasts as viewed */
	viewBroadcast: {
		getEndpoint: broadcastId => `broadcast/${broadcastId}/view`,
		requestOptions: {
			// For current logged in user
			method: 'POST',
		},
		getRequestOptions: userId => {
			// For specifying a user (usually for cases where user not logged in)
			return {
				method: 'POST',
				body: JSON.stringify({
					userId: userId,
				}),
			}
		},
	},

	//** Acknowledge broadcast */
	acknowledgeBroadcast: {
		getEndpoint: broadcastId => `broadcast/${broadcastId}/readconfirmation`,
		requestOptions: {
			method: 'POST',
		},
	},

	//#endregion

	//#region Category

	/** Retrieves a list of categories */
	getCategories: {
		endpoint: 'categories',
	},

	/** Retrieves a paged list of posts for a given category */
	getCategoryPosts: {
		getEndpoint: (categoryId, skip, take, excludePinnedPosts) => {
			let endpoint = categoryId > -1 ? `posts/categories?id=${categoryId}&` : 'posts/categories?'
			if (skip) endpoint += `skip=${skip}&`
			if (take) endpoint += `take=${take}&`
			if (excludePinnedPosts) endpoint += `excludePinnedPosts=${excludePinnedPosts}&`
			endpoint += includesQuery(postIncludesDefault)
			return endpoint
		},
	},

	/** Returns a paged list of posts based on search criteria */
	searchPosts: {
		endpoint: 'search',
		getRequestOptions: (term, sortBy, skip, take) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					term: term,
					type: 'Post',
					sortBy: sortBy,
					include: postIncludesDefault,
					skip: skip,
					take: take,
				}),
			}
		},
	},

	/** Retrieves an object that has the subscriber counts of the requested categories.
	 * {
		"counts": {
				"34": {
					"total": 2,
					"showLargeCategoryWarning": false
				},
				"39": {
					"total": 12,
					"showLargeCategoryWarning": false
				}
			}
		}
	 */
	getCategoriesSubscriberCounts: {
		getEndpoint: categoryIds =>
			'/manage/categories/subscriptions/counts?categoryIds=' + categoryIds.join(','),
		requestOptions: {
			method: 'GET',
		},
	},

	getCategoriesLatestPost: {
		getEndpoint: categoryIds => `posts/categories/latestpost?categoryIds=${categoryIds.join(',')}`,
		requestOptions: {
			method: 'GET',
		},
	},

	//#endregion

	//#region CustomPages

	getCustomPages: {
		getEndpoint: pageId => `pages/${pageId}/sections`,
	},

	getScriptCard: {
		getEndpoint: scriptId => `scripts/${scriptId}/run`,
		getRequestOptions: (data, token) => {
			// Build the form data
			var form = new FormData()
			if (data) {
				form.append('data', JSON.stringify(data))
			}
			if (token) {
				form.append('scriptExecutionToken', token)
			}
			return {
				method: 'POST',
				body: form,
			}
		},
	},

	getDedicatedPages: {
		endpoint: 'pages',
	},

	//#endregion

	//#region Discussions V2
	fetchCommentsV2: {
		getEndpoint: (
			postId,
			parentCommentId,
			offset,
			beforeId,
			afterId,
			commentId,
			activitySource,
			activityReason,
			activityDetail
		) => {
			let endpoint = `post/${postId}/discussion`

			// Fetching replies
			if (parentCommentId) {
				const limit = offset || 10
				endpoint += `?id=${postId}&parentCommentId=${parentCommentId}&limit=${limit}`
			}
			// Fetching comment by URL
			else if (commentId) {
				endpoint += `/${commentId}?`
				if (beforeId) endpoint += `before=${beforeId}&`
				else if (afterId) endpoint += `after=${afterId}&`
				if (activitySource) endpoint += `activitySource=${activitySource}&`
				if (activityReason) endpoint += `activityReason=${activityReason}&`
				if (activityDetail) endpoint += `activityDetail=${activityDetail}&`
			}

			// Fetching parent comments or replies
			if (beforeId) {
				if (parentCommentId || commentId) {
					endpoint += `&before=${beforeId}`
				} else {
					endpoint += `?before=${beforeId}`
				}
			} else if (afterId) {
				if (parentCommentId || commentId) {
					endpoint += `&after=${afterId}`
				} else {
					endpoint += `?after=${afterId}`
				}
			}
			return endpoint
		},
	},

	editCommentV2: {
		getEndpoint: (postId, commentId) => `post/${postId}/discussion/${commentId}`,
		getRequestOptions: (commentId, commentText, commentTextWithEntities, id) => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					id,
					commentId,
					commentText,
					commentTextWithEntities,
				}),
			}
		},
	},

	deleteCommentV2: {
		getRequestOptions: (id, commentId) => {
			return {
				method: 'DELETE',
				body: JSON.stringify({
					id,
					commentId,
				}),
			}
		},
	},

	postCommentV2: {
		getEndpoint: postId => `post/${postId}/discussion`,
		getRequestOptions: (id, commentText, commentTextWithEntities, parentCommentId, activity) => {
			const { activitySource, activityReason, activityDetail } = activity
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					commentText,
					commentTextWithEntities,
					parentCommentId,
					activitySource,
					activityReason,
					activityDetail,
				}),
			}
		},
	},

	likeCommentV2: {
		getEndpoint: (postId, commentId) => `post/${postId}/discussion/${commentId}/like`,
		getRequestOptions: {
			method: 'POST',
		},
	},

	unlikeCommentV2: {
		getRequestOptions: {
			method: 'DELETE',
		},
	},

	getUsersLikedCommentToolTipV2: {
		getEndpoint: (postId, commentId, count = 9) =>
			`post/${postId}/discussion/${commentId}/like/tooltip?count=${count}`,
	},

	getUsersLikedCommentV2: {
		getEndpoint: (postId, commentId, offset, after) => {
			let endpoint = `post/${postId}/discussion/${commentId}/like/list?`
			if (offset) endpoint += `limit=${offset}&`
			if (after) endpoint += `after=${after}&`
			return endpoint
		},
	},

	viewDiscussionV2: {
		getEndpoint: postId => `event/post/${postId}/discussionview`,
		getRequestOptions: (activitySource, activityReason, activityDetail) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					activitySource,
					activityReason,
					activityDetail,
				}),
			}
		},
	},

	//#endregion

	//#region Leaderboards

	/** Retrieves a list of leaderboards */
	getAllLeaderboards: {
		endpoint: 'leaderboards',
	},

	/** Retrieves rankings for a single leaderboard */
	getLeaderboardRankings: {
		getEndpoint: (id, skip, take) => {
			let endpoint = `leaderboardrankings?id=${id}&`
			if (skip) endpoint += `skip=${skip}&`
			if (take) endpoint += `take=${take}&`
			endpoint += 'sortMode=rank&include=images'
			return endpoint
		},
	},

	/** Retrieves rankings for different leaderboards for the authenticated user */
	getUserRankings: {
		endpoint: 'userrankings',
	},

	//#endregion

	//#region Logging

	//** Send logging information from applications or services integrating with the Dynamic Signal platform */
	sendLog: {
		endpoint: 'logging/integration',
		getRequestOptions: (message, stackTrace, meta, level = logLevels.error) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					type: logTypes.memberApp,
					logs: [
						{
							level,
							userAgent: navigator.userAgent,
							message,
							stackTrace,
							meta,
						},
					],
				}),
			}
		},
	},

	//#endregion

	//#region MemberUsageCompliance

	//** Set member usage compliance agreement */
	setAgreement: {
		endpoint: 'login/compliance',
		getRequestOptions: accept => ({
			method: 'POST',
			body: JSON.stringify({
				accept,
			}),
		}),
	},

	//#endregion

	//#region Messages

	//** Retrieve conversations for current user */
	getConversations: {
		getEndpoint: (archived, skip, take, query) => {
			let endpoint = 'conversations?'
			if (archived) endpoint += `archived=${archived}&`
			if (skip) endpoint += `skip=${skip}&`
			if (take) endpoint += `take=${take}&`
			if (query) endpoint += `query=${query}`
			return endpoint
		},
	},

	//** Retrieve specific conversation */
	getConversation: {
		getEndpoint: (conversationId, skip, take, markAsRead) => {
			let endpoint = `conversation?id=${conversationId}&`
			if (skip) endpoint += `skip=${skip}&`
			if (take) endpoint += `take=${take}&`
			endpoint += `markAsRead=${markAsRead}`
			return endpoint
		},
	},

	//** Creates new conversation */
	createConversation: {
		getEndpoint: (skip, take) => {
			let endpoint = 'conversations?'
			if (skip) endpoint += `skip=${skip}`
			if (take) endpoint += `take=${take}`
			return endpoint
		},
		getRequestOptions: (conversationType, messageText, userIds, postId) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					conversationType,
					messageText,
					userIds,
					postId,
				}),
			}
		},
	},

	//** Reply to a conversation */
	replyConversation: {
		getEndpoint: conversationId => `conversations/${conversationId}/reply`,
		getRequestOptions: (id, messageText, postId) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					messageText,
					postId,
				}),
			}
		},
	},

	//** Archive a conversation */
	archiveConversation: {
		getEndpoint: conversationId => `conversations/${conversationId}/archive`,
		requestOptions: {
			method: 'POST',
		},
	},

	//** Unarchive a conversation */
	unarchiveConversation: {
		getEndpoint: conversationId => `conversations/${conversationId}/unarchive`,
		requestOptions: {
			method: 'POST',
		},
	},

	//** Mark most recent message as read */
	markConversationAsRead: {
		getEndpoint: conversationId => `conversations/${conversationId}/read`,
		requestOptions: {
			method: 'POST',
		},
	},

	//** Mark most recent message as unread */
	markConversationAsUnread: {
		getEndpoint: conversationId => `conversations/${conversationId}/unread`,
		requestOptions: {
			method: 'POST',
		},
	},

	//**  Gets the count of unread conversations for the current user */
	getUnreadConversationsCount: {
		endpoint: 'conversations/count',
	},

	//#endregion

	//#region Newsletters

	//** Gets all of the individual sections of a newsletter for the authenticated user */
	getNewsletterSections: {
		getEndpoint: (newsletterId, broadcastId) => {
			let endpoint = `newsletters/${newsletterId}/sections?`
			if (broadcastId) endpoint += `broadcastId=${broadcastId}`
			return endpoint
		},
	},

	//#endregion

	//#region Notifications

	//** Retrieve all notifications */
	getNotifications: {
		getEndpoint: (userId, before, limit) => {
			let endpoint = `user/notifications?userId=${userId}`
			if (before) endpoint += `&before=${before}`
			if (limit) endpoint += `&limit=${limit}` // API defaults to 20
			return endpoint
		},
	},

	//** Retrieve single notification using notification Id */
	getNotification: {
		getEndpoint: notificationId => `user/notifications/${notificationId}`,
	},

	//** Retrieve notification count */
	getNotificationsCount: {
		endpoint: 'user/notifications/count',
	},

	//** Retrieve notification summary for urgent broadcast check */
	getNotificationsSummary: {
		endpoint: 'user/notifications/summary',
	},

	//** Mark notification as read */
	markAsRead: {
		endpoint: 'user/notifications/read',
		getRequestOptions: (currentUserId, notificationIds) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					userId: currentUserId,
					ids: notificationIds,
				}),
			}
		},
	},

	//#endregion

	//#region Posts

	//** Retrieve specific post */
	getPost: {
		getEndpoint: (postId, postIncludes) => {
			let endpoint = `post/${postId}`
			if (postIncludes) endpoint += `?${includesQuery(postIncludes)}`
			return endpoint
		},
	},

	//** Retrieve a set of posts by IDs */
	getPosts: {
		getEndpoint: postIds => `posts?ids=${postIds.join(',')}&${includesQuery(postIncludesDefault)}`,
	},

	//** Retrieve a set of posts from a feed for external viewing */
	getExternalFeedPosts: {
		getEndpoint: (feedId, skip, take) => {
			let endpoint = `posts/feeds/${feedId}`
			if (skip || take) endpoint += '?'
			if (skip) endpoint += `skip=${skip}`
			if (skip && take) endpoint += '&'
			if (take) endpoint += `take=${take}`
			return endpoint
		},
	},

	//** Retrive a list of posts made by a user */
	getApprovedPosts: {
		getEndpoint: (userId, skip, take = 5) => {
			let endpoint = `/user/${userId}/posts/approved?take=${take}&`
			if (skip) endpoint += `skip=${skip}`
			return endpoint
		},
	},

	//** Like / unlike post */
	likePost: {
		getEndpoint: postId => `post/${postId}/like`,
		getRequestOptions: (like, activity) => {
			const { activitySource, activityReason, activityDetail } = activity
			return {
				method: like ? 'POST' : 'DELETE',
				body: JSON.stringify({
					activitySource,
					activityReason,
					activityDetail,
				}),
			}
		},
	},

	//** Retrieves posts for showcase */
	getShowcasePosts: {
		getEndpoint: (categoryId, maxNumberOfPosts) =>
			`posts/categories?id=${categoryId}&take=${maxNumberOfPosts}&${includesQuery(
				postIncludesDefault
			)}`,
	},

	//** Retrieve list of likers for post */
	getPostLikers: {
		getEndpoint: (postId, after, limit) => {
			let endpoint = `post/${postId}/like/list?limit=${limit}`
			if (after) endpoint += `&after=${after}`
			return endpoint
		},
	},

	//** Retrieves list of likers for post - specifically for tooltips */
	getPostLikersTooltip: {
		getEndpoint: (postId, count) => `post/${postId}/like/tooltip?count=${count}`,
	},

	//** View post */
	viewPost: {
		endpoint: 'event/postview',
		getRequestOptions: (id, activity, userId) => {
			const { activityDetail, activityReason, activitySource } = activity
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					activityDetail,
					activityReason,
					activitySource,
					userId,
				}),
			}
		},
	},

	//** Sets post view duration */
	setPostViewDuration: {
		getEndpoint: (id, sessionId) => `event/post/${id}/duration/${sessionId}`,
		getRequestOptions: (sessionId, seconds, activity) => {
			const { activityDetail, activityReason, activitySource } = activity
			return {
				method: 'PUT',
				body: JSON.stringify({
					sessionId,
					seconds,
					activityDetail,
					activityReason,
					activitySource,
				}),
			}
		},
	},

	//** View media */
	viewMedia: {
		endpoint: 'event/mediaview',
		getRequestOptions: (id, eventType, userId, activity) => {
			const { activityDetail, activityReason, activitySource } = activity
			return {
				method: 'PUT',
				body: JSON.stringify({
					id,
					eventType,
					userId,
					activityDetail,
					activityReason,
					activitySource,
				}),
			}
		},
	},

	//** Retrieves list of post tags */
	getPostTags: {
		endpoint: 'posttags',
	},

	//** Creates a new post from a web URL */
	importPost: {
		endpoint: 'post/import',
		getRequestOptions: url => {
			return {
				method: 'POST',
				body: JSON.stringify({
					url,
				}),
			}
		},
	},

	//** Creates a post */
	createPost: {
		endpoint: 'post',
		getRequestOptions: post => {
			return {
				method: 'POST',
				body: JSON.stringify(post),
			}
		},
	},

	//** Creates a post as a Manager */
	createPostManager: {
		endpoint: 'manage/post',
		getRequestOptions: post => {
			return {
				method: 'POST',
				body: JSON.stringify(post),
			}
		},
	},

	//** Sets the post image via binary upload */
	updateImage: {
		getEndpoint: postId => `post/${postId}/image`,
		getRequestOptions: image => {
			return {
				method: 'PUT',
				body: image,
			}
		},
	},

	//** Sets an image to the post's image gallery */
	updateImageGallery: {
		getEndpoint: postId => `post/${postId}/gallery`,
		getRequestOptions: image => {
			return {
				method: 'POST',
				body: image,
			}
		},
	},

	//** Sets the post image via a URL */
	updateImageUrl: {
		getEndpoint: postId => `post/${postId}/imageurl`,
		getRequestOptions: url => {
			return {
				method: 'POST',
				body: JSON.stringify({
					url,
				}),
			}
		},
	},

	//** Starts the process of uploading a large video */
	startUploadVideo: {
		getEndpoint: postId => `post/${postId}/startvideo`,
		getRequestOptions: contentType => {
			return {
				method: 'POST',
				body: JSON.stringify({
					autoPublishVideoPost: false,
					contentType,
				}),
			}
		},
	},

	//** Completes the process of uploading a large video */
	completeUploadVideo: {
		getEndpoint: postId => `post/${postId}/completevideo`,
		getRequestOptions: (mimeType, ticket) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					mimeType,
					ticket,
				}),
			}
		},
	},

	//** Deletes a post */
	deletePost: {
		getEndpoint: postId => `post/${postId}`,
		requestOptions: {
			method: 'DELETE',
		},
	},

	//** Retrieves list of post sharers */
	getPostShares: {
		getEndpoint: (postId, skip, take) => {
			let endpoint = `/post/${postId}/shares/users?take=${take}`
			if (skip) endpoint += `&skip=${skip}`
			return endpoint
		},
	},

	//** Attaches a document to the post */
	updateDocument: {
		getEndpoint: (postId, document) => `post/${postId}/documents/?fileName=${document.name}`,
		getRequestOptions: document => {
			return {
				method: 'PUT',
				body: document,
			}
		},
	},

	//** Retrieve a list of Trending posts */
	getTrendingPosts: {
		getEndpoint: (type, skip, take) => {
			let endpoint = 'posts/trending?'
			if (type) endpoint += `trendingType=${type}&`
			if (skip) endpoint += `skip=${skip}&`
			if (take) endpoint += `take=${take}`
			return endpoint
		},
	},

	/** Get translated versions of select post properties */
	getPostTranslation: {
		getEndpoint: postId =>
			`post/${postId}/translate?include=title,linkTitle,description,content,suggestedShareText,tagline`,
		requestOptions: {
			method: 'POST',
		},
	},

	//#endregion

	//#region Translations

	getCommentTranslations: {
		getEndpoint: (postId, commentId, comment, modifiedDate) =>
			`post/translation/${postId}/comment?commentId=${commentId}&comment=${comment}&modifiedDate=${modifiedDate}`,
		requestOptions: {
			method: 'POST',
		},
	},

	//#endregion

	//#region Profile

	//** Retrieve user rankings from userId */
	getUserIdRankings: {
		getEndpoint: userId => `user/${userId}/rankings`,
	},

	//** Retrieve user by id */
	getUserById: {
		getEndpoint: userId => `user/${userId}?include=images,affiliations,channels`,
	},

	//** Retrieve user's profile */
	getUserProfile: {
		getEndpoint: userId => `user/${userId}/profile`,
	},

	//** Retrieve user's organizational direct reports */
	getUserDirectReports: {
		getEndpoint: userId => `user/${userId}/organization/reports`,
	},

	//** Retrieve a CSV download of a user's sharing statistics */
	getSharingStatCsvDownload: {
		getEndpoint: userId => `user/${userId}/reports/sharing/csv`,
	},

	//#endregion

	//#region Relay

	/** Authenticates a user and returns a dynamic Relay endpoint */
	getRelayUrl: {
		endpoint: 'relay/auth',
	},

	/** Using the URL and token returned from getRelayUrl, this generates the appropriate EventSource
	 *  URL. */
	getRelayEventSource: {
		getEndpoint: (url, token) => `${url}?AccessToken=${token}`,
	},

	//#endregion

	//#region Settings

	//#region Account
	//** Tests whether a given password satisfies the community's password security requirements */
	validatePassword: {
		endpoint: 'utility/testpassword',
		getRequestOptions: sample => {
			return {
				method: 'POST',
				body: JSON.stringify({
					sample,
				}),
			}
		},
	},

	//** Sends user a new email with verification code **//
	sendVerifyEmail: {
		endpoint: 'register/sendverifyemail',
		getRequestOptions: (email, id) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					email,
				}),
			}
		},
	},

	//** Verifies account based on verification code sent *//
	verifyAccount: {
		endpoint: 'register/verifyaccount',
		getRequestOptions: (id, verificationCode, checkOnly) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					verificationCode,
					checkOnly,
				}),
			}
		},
	},

	//** Retrieve email address whitelist */
	checkWhitelist: {
		endpoint: 'whitelist/check',
		getRequestOptions: emails => {
			return {
				method: 'POST',
				body: JSON.stringify({
					emails,
				}),
			}
		},
	},

	//** Update user's properties */
	updateUser: {
		getEndpoint: userId => `user/${userId}`,
	},

	//** Update divisions property on user */
	setDivisions: {
		getRequestOptions: divisions => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					divisions,
				}),
			}
		},
	},

	//** Retrieve user's divisions */
	getDivisions: {
		endpoint: 'divisions',
	},

	//** Retrieve user's targets */
	getTargets: {
		getEndpoint: (userId, childTargets) =>
			childTargets ? 'targets?childtargetsonly=true' : `user/${userId}/targets`,
	},

	//** Set user's content languages */
	setContentLanguages: {
		getRequestOptions: languages => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					languages,
				}),
			}
		},
	},

	//** Set user's primary interface language */
	setPrimaryLanguage: {
		getRequestOptions: primaryLanguage => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					primaryLanguage,
				}),
			}
		},
	},

	//** Set user's timezone */
	setTimezone: {
		getRequestOptions: timezone => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					timezone,
				}),
			}
		},
	},

	//** Set user's targets */
	setTargets: {
		getEndpoint: userId => `user/${userId}/targets`,
		getRequestOptions: targetIds => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					targetIds,
				}),
			}
		},
	},

	//** Set user's profile phone number (separate from sms notification phone number). Pass empty string to remove existing phone number. */
	setPhoneNumber: {
		getRequestOptions: phoneNumber => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					phoneNumber: {
						primaryNumber: phoneNumber,
					},
				}),
			}
		},
	},

	//** Sets user's email address *//
	setEmail: {
		getRequestOptions: email => {
			return {
				method: 'POST',
				body: JSON.stringify({
					email,
				}),
			}
		},
	},

	//** Deletes a user from the system */
	deleteUser: {
		getEndpoint: userId => `user/${userId}`,
		getRequestOptions: id => {
			return {
				method: 'DELETE',
				body: JSON.stringify({
					id,
				}),
			}
		},
	},
	//#endregion

	//#region Channel

	//** Retrieves a list of channels connected by a user */
	getUserChannels: {
		getEndpoint: userId => `user/${userId}/channels`,
	},

	//** Retrieves a list of channels connected by a user */
	getSocialProviders: {
		endpoint: 'providers?type=AddChannel',
	},

	//** Removes a user channel */
	removeUserChannel: {
		getEndpoint: (userId, userChannelId) => `user/${userId}/removechannel/${userChannelId}`,
		requestOptions: {
			method: 'POST',
		},
	},

	//** Verifies authentication status for a user's sharing channel */
	getChannelVerifyAuthentication: {
		getEndpoint: userChannelId => `share/channel/${userChannelId}/verifyauthentication`,
	},

	//#endregion

	//#region Invite

	//** Gets an invitation link that can be shared by the user to invite someone to join the community */
	getInviteLink: {
		endpoint: 'invite/link',
	},

	//** Sends an invitation to join the community to one or more email addresses */
	sendInvite: {
		endpoint: 'invite',
		getRequestOptions: (emails, message) => {
			const email = emails.length === 1 && emails.shift()
			const emailObject = email ? { email } : { emails }
			return {
				method: 'POST',
				body: JSON.stringify({
					...emailObject,
					message,
				}),
			}
		},
	},

	//** Records that an invitation link was copied to the clipboard */
	recordInvitationLinkCopied: {
		endpoint: 'event/invite/share',
		getRequestOptions: userId => {
			const activity = getActivity(Activity.Reason.invitationLink, userId)
			return {
				method: 'POST',
				body: JSON.stringify(activity),
			}
		},
	},

	//** Records that an invitation link was followed to reach the Sign In page */
	recordInvitationLinkFollowed: {
		endpoint: 'event/click',
		getRequestOptions: (activityDetail, activitySource) => {
			const activity = getActivity(Activity.Reason.invitationLink, activityDetail, activitySource)
			return {
				method: 'POST',
				body: JSON.stringify(activity),
			}
		},
	},

	//#endregion

	//region Livestream

	/** Checks if a livestream is started */
	getLiveStreamStatus: {
		endpoint: 'livestream/started',
		getRequestOptions: postId => {
			const id = postId
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
				}),
			}
		},
	},

	//** Starts a live stream view */
	startLiveStream: {
		endpoint: 'livestream/startview',
		getRequestOptions: postId => {
			const id = postId
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
				}),
			}
		},
	},

	//** Finishes a live stream view */
	finishLiveStream: {
		endpoint: 'livestream/finishview',
		getRequestOptions: token => {
			return {
				method: 'POST',
				body: JSON.stringify({
					token,
				}),
			}
		},
	},

	//region MemberDirectory

	//** Returns a paged list of user search results based on the requested criteria */
	directorySearch: {
		endpoint: 'directory/search',
		getRequestOptions: (term, searchType, skip, take) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					term,
					searchType,
					skip,
					take,
				}),
			}
		},
	},

	//#endregion

	//#region Bookmarks

	getBookmarks: {
		getEndpoint: userId => `user/${userId}/bookmarks?types=Post`,
	},

	deleteBookmark: {
		getEndpoint: (userId, bookmarkId) => `user/${userId}/bookmarks/${bookmarkId}`,
		requestOptions: {
			method: 'DELETE',
		},
	},

	addPostBookmark: {
		getEndpoint: userId => `user/${userId}/bookmarks`,
		getRequestOptions: postId => {
			var type = 'Post'
			return {
				method: 'POST',
				body: JSON.stringify({
					type,
					postId,
				}),
			}
		},
	},

	deletePostBookmark: {
		getEndpoint: (userId, postId) => `user/${userId}/bookmarks/posts/${postId}`,
		requestOptions: {
			method: 'DELETE',
		},
	},

	//#endregion

	//#region Notification

	//** Retrieves the notification preferences for the specified user */
	getNotificationPreferences: {
		getEndpoint: userId => `user/${userId}/notifications/preferences`,
	},

	//** Set notification preferences */
	setNotificationPreferences: {
		getRequestOptions: (id, preferences, subscribeToSms, smsPhoneNumber, smsReturnUrl) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					preferences,
					subscribeToSms,
					smsPhoneNumber,
					smsReturnUrl,
				}),
			}
		},
	},

	//** Resends the SMS code for the specified user */
	resendSmsCode: {
		getEndpoint: userId => `user/${userId}/notifications/resendsmscode`,
		getRequestOptions: (id, smsReturnUrl) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					smsReturnUrl,
				}),
			}
		},
	},

	//** Verifies the SMS code for the specified user */
	verifySmsCode: {
		getEndpoint: userId => `user/${userId}/notifications/verifysmscode`,
		getRequestOptions: (id, smsVerificationCode) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					smsVerificationCode,
				}),
			}
		},
	},

	//#endregion

	//#region Profile

	//** Retrieves the list of profile questions and their associated answers that users are capable of answering */
	getProfileQuestions: {
		endpoint: 'profilequestions',
	},

	//** Updates all of the current user's answers to the profile questions */
	setProfileQuestions: {
		endpoint: 'user/profilequestions',
		getRequestOptions: profileQuestions => {
			return {
				method: 'PUT',
				body: JSON.stringify({
					profileQuestions,
				}),
			}
		},
	},

	//** Sets the user's profile image via MIME-multipart upload */
	setUserPhoto: {
		getEndpoint: (userId, crop) => {
			let endpoint = `user/${userId}/profileimage?`
			if (crop) {
				const { startx, starty, width, height } = crop
				endpoint += `cropimage=true&startx=${startx}&starty=${starty}&width=${width}&height=${height}`
			}
			return endpoint
		},
		getRequestOptions: file => {
			return {
				method: 'PUT',
				body: file,
			}
		},
	},

	//** Set profile name */
	setProfileName: {
		getRequestOptions: (id, firstName, lastName) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					firstName,
					lastName,
				}),
			}
		},
	},

	//#endregion

	//#region ScheduledShares

	//** Retrieves a list of pending scheduled shares */
	getScheduledShares: {
		getEndpoint: (userId, ids) => {
			let endpoint = `share/scheduled?userId=${userId}&`
			if (ids) endpoint += `ids=${ids}`
			return endpoint
		},
	},

	//** Sets the share dates on a scheduled share */
	setScheduledShare: {
		getEndpoint: id => `share/scheduled/${id}`,
		getRequestOptions: (id, shareDate) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					shareDates: [shareDate],
				}),
			}
		},
	},

	//** Sends a previously scheduled share immediately */
	sendScheduledShare: {
		getEndpoint: id => `share/${id}/sendnow`,
		getRequestOptions: (userId, id) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					userId,
				}),
			}
		},
	},

	//** Updates a previously scheduled share */
	updateScheduledShare: {
		getEndpoint: id => `share/scheduled/${id}`,
		getRequestOptions: shareDate => {
			return {
				method: 'POST',
				body: JSON.stringify({
					shareDates: [shareDate],
				}),
			}
		},
	},

	//** Deletes a previously scheduled share */
	deleteScheduledShare: {
		getEndpoint: id => `share/${id}/delete`,
		getRequestOptions: (userId, id) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					userId,
				}),
			}
		},
	},

	//#endregion

	//#region Scheduled Settings

	//** Suggests a time of day that would be ideal to share a post */
	getSuggestedShareTime: {
		getEndpoint: existingTimes => {
			let endpoint = 'share/suggestedsharetime'
			if (existingTimes && existingTimes.length > 0)
				endpoint += `?currentTimes=${existingTimes.join(',')}`
			return endpoint
		},
	},

	//** Save user's settings */
	saveSettings: {
		getRequestOptions: (days, times) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					scheduleSettings: {
						days,
						times,
					},
				}),
			}
		},
	},

	//#endregion

	//#region Share Settings

	//** Retrieves user's comments */
	getUsersComments: {
		getEndpoint: (userId, skip, take = 5) => {
			let endpoint = `usercomments?id=${userId}&take=${take}&include=channels&`
			if (skip) endpoint += `skip=${skip}`
			return endpoint
		},
	},

	//#endregion

	//#region Subscriptions

	//** Adds a categories to the user's subscriptions */
	subscribeToCategory: {
		getEndpoint: userId => `user/${userId}/subscriptions`,
		getRequestOptions: categoryIds => {
			return {
				method: 'POST',
				body: JSON.stringify({
					categoryIds: categoryIds,
				}),
			}
		},
	},

	//** Removes a category from the user's subscriptions */
	unsubscribeFromCategory: {
		getEndpoint: (userId, categoryId) => `/user/${userId}/subscriptions/${categoryId}`,
		getRequestOptions: categoryId => {
			return {
				method: 'DELETE',
				body: JSON.stringify({
					categoryId: categoryId,
				}),
			}
		},
	},

	//#endregion

	//#region Sphere

	/** Internal endpoint used to retrieve anything and everything about a community */
	getSphereInfo: {
		endpoint: 'community',
	},

	/** Retrieves all social providers */
	getProviders: {
		endpoint: 'providers?type=All',
	},

	/** Retrieves all timezone data including the community timezone */
	getTimezones: {
		endpoint: 'utility/timezones',
	},

	/** Retrieves language data including some community-specific information */
	getLanguages: {
		endpoint: 'utility/languages',
	},

	//** Retrieves community custom links */
	getCustomLinks: {
		endpoint: 'community/links',
	},

	//** Retrieves Terms of Service */
	getTermsOfService: {
		endpoint: 'community/legal',
	},

	//** Record custom link click */
	recordCustomLinkClick: {
		getEndpoint: id => `event/click/community/link/${id}`,
		getRequestOptions: (id, location) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					location,
				}),
			}
		},
	},

	//#endregion

	//#region Survey

	//** Retrieves an active survey for the authenticated user */
	getSurvey: {
		getEndpoint: surveyId => `surveys/${surveyId}?includeAnswers=true`,
	},

	//** Retrieves the results of the survey */
	getSurveyResults: {
		getEndpoint: surveyId => `surveys/${surveyId}/results`,
	},

	//** Submits an answer to a survey question for the authenticated user */
	answerSurvey: {
		getEndpoint: surveyId => `surveys/${surveyId}/answers`,
		getRequestOptions: (questionId, answers) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					questionId,
					answers,
				}),
			}
		},
	},

	//** Marks the survey as completed for the authenticated user */
	completeSurvey: {
		getEndpoint: surveyId => `surveys/${surveyId}/complete`,
		requestOptions: {
			method: 'POST',
		},
	},

	//#endregion

	//#region Typeahead

	/** Searches users and groups based on partial-string matching */
	searchTypeahead: {
		endpoint: 'typeahead?' + typeaheadIncludes,
		getRequestOptions: (term, type, take) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					take,
					term,
					type,
				}),
			}
		},
	},

	//** Searches posts, users, groups, tags, attributes, and conversations based on partial-string matching */
	searchTypeaheadV2: {
		endpoint: 'typeaheadV2?' + typeaheadIncludes,
		getRequestOptions: (term, type, take) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					take,
					term,
					type,
				}),
			}
		},
	},

	//#endregion

	//#region User

	/** Universally signs a user out by removing the authentication cookies */
	logout: {
		endpoint: 'logout',
		requestOptions: {
			method: 'POST',
			credentials: 'same-origin', // Required to be able to write cookies
		},
	},

	//** Dismiss download banner */
	dismissDownloadBanner: {
		getEndpoint: userId => `user/${userId}/dismissdownloadbanner`,
		requestOptions: {
			method: 'POST',
		},
	},

	//** Dismiss welcome banner */
	dismissWelcomeBanner: {
		getEndpoint: userId => `user/${userId}/dismisswelcomebanner`,
		requestOptions: {
			method: 'POST',
		},
	},

	// You might be wondering why 'User' region has an endpoint related to posts.
	// That is because this file (apiRequests.js) is aligned closely with the regions provided by the Api.
	// Rather than the scenes that the App comprises of.
	fetchPostWritePermissions: {
		getEndpoint: userId => `manage/user/${userId}/post/config`,
	},

	//** Retrieve user's customData */
	getUserCustomData: {
		getEndpoint: (userId, dataIndex) => `user/${userId}/customdata/${dataIndex}`,
	},

	setUserCustomData: {
		getEndpoint: (userId, dataIndex) => `user/${userId}/customdata/${dataIndex}`,
		getRequestOptions: (id, customDataIndex, data) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
					customDataIndex,
					data: JSON.stringify(data),
				}),
			}
		},
	},

	getUserPreferences: {
		getEndpoint: keys => `user/preferences?keys=${keys.join(',')}`,
	},

	setUserPreference: {
		getEndpoint: () => 'user/preferences',
		getRequestOptions: (key, data) => {
			return {
				method: 'POST',
				body: JSON.stringify({
					key,
					data,
				}),
			}
		},
	},

	//* Retrieve user's active login sessions *//
	getUserSessions: {
		endpoint: 'user/sessions',
	},

	//* Remove a user's login session *//
	deleteUserSession: {
		getEndpoint: sessionId => `user/sessions/${sessionId}`,
		getRequestOptions: () => {
			return {
				method: 'DELETE',
			}
		},
	},

	//* Remove of a user's active login sessions *//
	deleteAllUserSessions: {
		endpoint: 'user/sessions',
		getRequestOptions: () => {
			return {
				method: 'DELETE',
			}
		},
	},

	//#endregion

	//#region Utility

	ping: {
		endpoint: 'utility/ping',
	},

	//#endregion

	//#region Documents
	getDocument: {
		getEndpoint: documentId => `documents/${documentId}`,
	},

	trackDocumentUrl: {
		getEndpoint: documentId => `event/documents/${documentId}/track`,
		getRequestOptions: id => {
			return {
				method: 'POST',
				body: JSON.stringify({
					id,
				}),
			}
		},
	},
	//#endregion
}

export default apiRequests
