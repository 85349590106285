const ShareTextFormikKeys = {
	// Twitter
	twitterConnectedAccounts: 'connectedTwitterAccountIds',
	twitterShareTextList: 'suggestedTwitterShareTextList',
	twitterShareText: 'twitterShareText',

	// Facebook Page
	facebookPageConnectedAccounts: 'connectedFacebookPageAccountIds',
	facebookPageShareText: 'facebookPageShareText',

	// LinkedIn + Other Accounts
	linkedInConnectedAccounts: 'connectedLinkedInAndOthersAccountIds',
	linkedInShareTextList: 'suggestedLinkedInAndOthersShareTextList',
	linkedInShareText: 'linkedInAndOthersShareText',
}

export default ShareTextFormikKeys
