import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Lang
import lang from './beta.lang'

// Routing
// import Routes from 'helpers/routes'

// Components
import Layout, { Panel, Section } from 'pages/Layouts/Layout'
import themeName, { getThemeFromStorage } from 'styles/themes/themeHelpers'
import Button, { ButtonLevel } from 'components/Button/Button'
import { setUiTheme, setPartyMode } from 'reducers/browser.reducer'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { storage, buildStorageKey } from 'helpers/persistor'
import { asyncSetUserCustomData } from 'scenes/Auth/auth.reducer'
import Settings, { safeGetSetting } from 'wrappers/Settings'

// Enums
import CustomDataIndexes from 'scenes/Auth/enums/customDataIndexes'

// Style
// const styles = {}
export const notificationFlyoutKey = buildStorageKey('notificationFlyoutEnabled')

const mapStateToProps = state => {
	const isUserLoggedIn = Settings.User.IsLoggedIn.value(state)
	const currentUserId = isUserLoggedIn && safeGetSetting(state, Settings.User.ID)
	const enablePartyMode = safeGetNestedProp(state, 'browser.enablePartyMode')
	const enableNotificationFlyout = !!storage.getItem(notificationFlyoutKey)
	const enablePostRowFeed = false //storage.getItem(postRowFeedKey)
	const experimentalFeatures = safeGetNestedProp(
		state,
		`auth.user.customData[${CustomDataIndexes.experimentalFeatures}]`
	)
	return {
		currentUserId,
		enableNotificationFlyout,
		enablePartyMode,
		enablePostRowFeed,
		experimentalFeatures,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setCustomData: (userId, dataIndex, data) =>
			dispatch(asyncSetUserCustomData(userId, dataIndex, data)),
		setTheme: theme => dispatch(setUiTheme(theme)),
		togglePartyMode: enablePartyMode => dispatch(setPartyMode(!enablePartyMode)),
	}
}

// Page
class Beta extends React.Component {
	static propTypes = {
		currentUserId: PropTypes.number,
		enableNotificationFlyout: PropTypes.bool,
		enablePartyMode: PropTypes.bool,
		enablePostRowFeed: PropTypes.bool,
		experimentalFeatures: PropTypes.object,
		setCustomData: PropTypes.func,
		setTheme: PropTypes.func.isRequired,
		togglePartyMode: PropTypes.func.isRequired,
	}

	componentDidUpdate(prevProps) {
		const {
			currentUserId,
			experimentalFeatures: { theme, enableNotificationFlyout, enablePostRowFeed } = {},
			enableNotificationFlyout: localEnableNotificationFlyout,
			enablePostRowFeed: localEnablePostRowFeed,
			setCustomData,
			experimentalFeatures,
		} = this.props
		//Set current preferences to user if user doesn't have any preferences.
		if (!prevProps.experimentalFeatures && !experimentalFeatures) {
			if (currentUserId) {
				const data = {}
				if (!theme) {
					data.theme = getThemeFromStorage().name
				}
				if (!enableNotificationFlyout) {
					data.enableNotificationFlyout = localEnableNotificationFlyout
				}
				if (!enablePostRowFeed) {
					data.enablePostRowFeed = localEnablePostRowFeed
				}
				setCustomData(currentUserId, CustomDataIndexes.experimentalFeatures, data)
			}
		}
	}

	handleThemeClick = (theme, isCurrentTheme) => {
		const { currentUserId, experimentalFeatures, setCustomData, setTheme } = this.props
		if (!isCurrentTheme) {
			if (currentUserId) {
				const data = {
					...experimentalFeatures,
					theme,
				}
				setCustomData(currentUserId, CustomDataIndexes.experimentalFeatures, data).then(() =>
					setTheme(theme)
				)
			} else {
				setTheme(theme)
			}
		}
	}

	// Enables the notification center displaying in a flyout
	handleEnableNotificationClick = e => {
		e.preventDefault()
		const {
			currentUserId,
			experimentalFeatures,
			enableNotificationFlyout,
			setCustomData,
		} = this.props
		if (currentUserId) {
			const data = {
				...experimentalFeatures,
				enableNotificationFlyout: !enableNotificationFlyout,
			}
			setCustomData(currentUserId, CustomDataIndexes.experimentalFeatures, data).then(
				this.setNotificationFlyout
			)
		} else {
			this.setNotificationFlyout()
		}
	}

	// Enables the experimental postRow news feed
	handleEnablePostRowFeedClick = e => {
		e.preventDefault()
		const { currentUserId, experimentalFeatures, enablePostRowFeed, setCustomData } = this.props
		if (currentUserId) {
			const data = {
				...experimentalFeatures,
				enablePostRowFeed: !enablePostRowFeed,
			}
			setCustomData(currentUserId, CustomDataIndexes.experimentalFeatures, data).then(
				this.setPostRowFeed
			)
		} else {
			this.setPostRowFeed()
		}
	}

	setNotificationFlyout = () => {
		const { enableNotificationFlyout } = this.props
		if (enableNotificationFlyout) {
			storage.removeItem(notificationFlyoutKey)
		} else {
			const date = new Date()
			storage.setItem(notificationFlyoutKey, date)
		}
		window.location.reload()
	}

	render() {
		const currentTheme = getThemeFromStorage().name
		const { enablePartyMode, enableNotificationFlyout } = this.props

		return (
			<Layout>
				<Panel panelTitle={lang.pageTitle}>
					<Section>
						<h3>{lang.themes}</h3>
						{Object.values(themeName).map(theme => {
							const isCurrentTheme = theme === currentTheme
							return (
								<Button
									key={theme}
									onClick={() => this.handleThemeClick(theme, isCurrentTheme)}
									style={{ marginRight: 10 }}
									level={isCurrentTheme ? ButtonLevel.primary : ButtonLevel.secondary}
								>
									{theme}
								</Button>
							)
						})}
					</Section>
					<Section>
						<h3>{lang.improvements}</h3>
						<Button onClick={this.handleEnableNotificationClick} style={{ marginRight: 10 }}>
							{enableNotificationFlyout ? lang.notificationDisable : lang.notificationEnable}
						</Button>
					</Section>
					<Section>
						<h3>{lang.sillyness}</h3>
						<Button
							onClick={() => this.props.togglePartyMode(enablePartyMode)}
							style={{ marginRight: 10 }}
						>
							{enablePartyMode ? lang.partyModeDisable : lang.partyModeEnable}
						</Button>
					</Section>
				</Panel>
			</Layout>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Beta)
