import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'

// Helpers
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'

// Lang
import lang from './emailUnsubscribe.lang'

// Components
import Layout, { Panel, Section } from 'pages/Layouts/Layout'
import NotFoundPage from 'pages/NotFound'
import Routes from 'helpers/routes'

const mapStateToProps = (state, ownProps) => {
	const communityName = safeGetSetting(state, Settings.Community.Name)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const { email } = queryString.parse(ownProps.location.search)

	return {
		communityName,
		primaryColor,
		email,
	}
}

const createSettingsLink = primaryColor => {
	var basePath = process.env.PUBLIC_URL

	let startSlash = '/'
	if (basePath.indexOf('/') === 0) {
		// Avoid accidentally adding double slashes
		startSlash = ''
	}

	const linkPath = startSlash + basePath + Routes.Settings.Notifications.routerPath
	return `<a style="color:${primaryColor}" href="${linkPath}">${lang.settings}</a>`
}

class EmailUnsubscribe extends Component {
	static propTypes = {
		email: PropTypes.string.isRequired,
		communityName: Settings.Community.Name.propType.isRequired,
		primaryColor: Theme.PrimaryColor.propType,
	}
	render() {
		const { email, communityName, primaryColor } = this.props
		if (!email || !communityName) {
			return <NotFoundPage />
		}

		return (
			<Layout>
				<Panel>
					<Section>
						<h2>{lang.title}</h2>
						<p
							dangerouslySetInnerHTML={{
								__html: lang.message(email, communityName, createSettingsLink(primaryColor)),
							}}
						/>
						<p>{lang.importantTip}</p>
					</Section>
				</Panel>
			</Layout>
		)
	}
}

let wrappedEmailUnsubscribe = connect(mapStateToProps)(EmailUnsubscribe)
export default wrappedEmailUnsubscribe
