import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// Components
import Select from 'components/Forms/Select'

// Lang
import lang from './timePicker.lang'

// Helpers
const twoDigitNumber = number => (number < 10 ? '0' : '') + number
export const MomentTimeFormat = 'HH:mm:ss'

// Styles
const pickerMinWidth = 50
const styles = {
	base: {
		display: 'flex',
	},
	select: {
		width: 'auto',
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 'auto',
		minWidth: pickerMinWidth,
		marginRight: 6, // per mock
	},
}

const selectOptions = {
	hours: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(x => {
		const hour = twoDigitNumber(x)
		return {
			name: hour,
			value: hour,
		}
	}),
	minutes: [...Array(60).keys()].map(x => {
		const minute = twoDigitNumber(x)
		return {
			name: minute,
			value: minute,
		}
	}),
	period: [
		{
			name: lang.period.am,
			value: 'a',
		},
		{
			name: lang.period.pm,
			value: 'p',
		},
	],
}

class TimePicker extends React.Component {
	state = {
		hour: '12',
		minute: '00',
		period: 'a',
	}

	static propTypes = {
		value: (props, propName, componentName) => {
			// Prop value expected to be a string in API Time Format: 'HH:MM:SS'
			const prop = props[propName]
			const momentTime = moment(prop, MomentTimeFormat)
			if (!momentTime.isValid()) {
				return new Error(
					`Invalid prop ${propName} supplied to ${componentName}. Expected value in API Time Format: 'HH:MM:SS'. Received: ${prop}`
				)
			}
		},
		onTimeChange: PropTypes.func, // Takes new time in API Time Format: 'HH:MM:SS' as a parameter
		style: PropTypes.object,
	}

	setStateFromValue = value => {
		// Prop "value" expected to be in API Time Format: 'HH:MM:SS', where HH is 24-hour
		const momentTime = moment(value, MomentTimeFormat)
		if (!momentTime.isValid())
			throw new Error(`Expected value in API Time Format: 'HH:MM:SS'. Received: ${value}`)

		const hour = momentTime.format('hh')
		const minute = momentTime.format('mm')
		const period = momentTime.format('a')[0]

		this.setState({ hour, minute, period })
	}

	componentDidMount() {
		const { value } = this.props

		// Initialize state from props
		this.setStateFromValue(value)
	}

	componentDidUpdate(prevProps, prevState) {
		const { value } = this.props
		const { value: oldValue } = prevProps

		if (value !== oldValue) {
			// New value. Update state
			this.setStateFromValue(value)
		}
	}

	handleChange = () => {
		const { onTimeChange } = this.props
		const { hour, minute, period } = this.state
		const momentTime = moment(`${hour}:${minute} ${period}`, 'hh:mm a')
		const value = momentTime.format(MomentTimeFormat)

		// Update value, notify listeners
		this.setState({ value }, onTimeChange ? () => onTimeChange(value) : null)
	}

	handleHourChange = e => {
		const hour = e
		this.setState({ hour }, this.handleChange)
	}

	handleMinuteChange = e => {
		const minute = e
		this.setState({ minute }, this.handleChange)
	}

	handlePeriodChange = e => {
		const period = e
		this.setState({ period }, this.handleChange)
	}

	render() {
		const { style } = this.props
		const { hour, minute, period } = this.state

		const selectStyle = {
			...styles.select,
			...style,
		}

		return (
			<div style={styles.base}>
				<Select
					style={selectStyle}
					value={hour}
					onChange={this.handleHourChange}
					options={selectOptions.hours}
				/>
				<Select
					style={selectStyle}
					value={minute}
					onChange={this.handleMinuteChange}
					options={selectOptions.minutes}
				/>
				<Select
					style={selectStyle}
					value={period}
					onChange={this.handlePeriodChange}
					options={selectOptions.period}
				/>
			</div>
		)
	}
}

export default TimePicker
