import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import Dialog from 'components/Dialog/Dialog'
import ImagePlaceholder from 'components/Placeholders/ImagePlaceholder'

// Styles
import styles from './AnnouncementDialog.styles'

// Helpers
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import Settings, { safeGetSetting } from 'wrappers/Settings'
import { safeGetNestedProp } from '@dysi/js-helpers'

// Actions
import { toggleAnnouncementDialog } from 'reducers/browser.reducer'

export const dsCookie = 'DySi-Cookies'

const mapStateToProps = state => {
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const isUserLoggedIn = Settings.User.IsLoggedIn.value(state)
	const currentUserId = isUserLoggedIn && safeGetSetting(state, Settings.User.ID)
	const showDialog = safeGetNestedProp(state, 'browser.announcementDialogOpen', false)

	return {
		currentUserId,
		primaryColor,
		showDialog,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		toggleDialog: () => dispatch(toggleAnnouncementDialog()),
	}
}

class AnnouncementDialog extends Component {
	static propTypes = {
		currentUserId: Settings.User.ID.propType,
		primaryColor: Theme.PrimaryColor.propType,
		showDialog: PropTypes.bool,
		toggleDialog: PropTypes.func,
		image: PropTypes.object,
		title: PropTypes.string,
		description: PropTypes.string,
		link: PropTypes.string,
		linkName: PropTypes.string,
	}

	render() {
		const {
			primaryColor,
			showDialog,
			toggleDialog,
			currentUserId,
			image,
			title,
			description,
			link,
			linkName,
		} = this.props
		const dynamicStyles = {
			link: {
				...styles.link,
				color: primaryColor,
			},
		}

		if (!showDialog || !currentUserId) return null

		return (
			<Dialog isOpen={true} onClose={toggleDialog}>
				<div style={styles.container}>
					<div style={styles.image}>
						<ImagePlaceholder image={image} />
					</div>

					<div style={styles.title}>{title}</div>
					<div style={styles.description}>{description}</div>
					{link && (
						<Link to={link} style={dynamicStyles.link} onClick={toggleDialog}>
							{linkName}
						</Link>
					)}
				</div>
			</Dialog>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AnnouncementDialog)
