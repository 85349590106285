import cloneDeep from 'lodash/cloneDeep'

// Helpers
import { vsApi, requests } from 'helpers/apiHelpers'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { postIncludesDefault, postIncludes } from 'helpers/apiRequests'
import { loadScript, removeScript } from 'helpers/scriptHelpers'
import { getRed5SdkBasePath } from 'helpers/streamingHelpers'

// Components
import PostData from 'scenes/Post/data/post.data'
import PostLiker from 'scenes/Post/data/post.liker.data'
import SocialCommentData from 'scenes/Post/data/socialComment.data'
import LiveStreamState from './enums/liveStreamState'

// Data wrappers
import PostTranslationData, {
	PostTranslationPending,
	PostTranslationError,
	getTranslatedDescription,
	getTranslatedDescriptionWithEntities,
} from './data/postTranslation.data'

// Actions (from other reducers)
import { FETCH_BROADCAST_SUCCESS } from 'scenes/Broadcast/broadcast.reducer'
import {
	FETCH_CATEGORY_POSTS_SUCCESS,
	FETCH_POSTSEARCH_SUCCESS,
} from 'scenes/Category/category.reducer'
import { CLEAR_CURRENT_USER } from 'scenes/Auth/auth.reducer'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below
const FETCH_POST_REQUEST = 'FETCH_POST_REQUEST'
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS'
const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE'

export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST'
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS' // Only for other reducers
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE' // Only for other reducers

export const FETCH_EXTERNAL_FEED_POSTS_SUCCESS = 'FETCH_EXTERNAL_FEED_POSTS_SUCCESS'

export const FETCH_APPROVED_CONTENT_REQUEST = 'FETCH_APPROVED_CONTENT_REQUEST' // Only for other reducers
export const FETCH_APPROVED_CONTENT_SUCCESS = 'FETCH_APPROVED_CONTENT_SUCCESS' // Only for other reducers
export const FETCH_APPROVED_CONTENT_FAILURE = 'FETCH_APPROVED_CONTENT_FAILURE' // Only for other reducers

const FETCH_POSTS_LIKERS_REQUEST = 'FETCH_POSTS_LIKERS_REQUEST'
const FETCH_POSTS_LIKERS_SUCCESS = 'FETCH_POSTS_LIKERS_SUCCESS'
const FETCH_POSTS_LIKERS_FAILURE = 'FETCH_POSTS_LIKERS_FAILURE'

const OPEN_SHARE_DIALOG_REQUEST = 'OPEN_SHARE_DIALOG_REQUEST'
const OPEN_SHARE_DIALOG = 'OPEN_SHARE_DIALOG'
const CLOSE_SHARE_DIALOG = 'CLOSE_SHARE_DIALOG'

const OPEN_SHARE_COMPLETE_DIALOG_REQUEST = 'OPEN_SHARE_COMPLETE_DIALOG_REQUEST'
const OPEN_SHARE_COMPLETE_DIALOG = 'OPEN_SHARE_COMPLETE_DIALOG'
const CLOSE_SHARE_COMPLETE_DIALOG = 'CLOSE_SHARE_COMPLETE_DIALOG'

const FETCH_LIVESTREAM_STATUS_SUCCESS = 'FETCH_LIVESTREAM_STATUS_SUCCESS'

const FETCH_POSTIMPORT_REQUEST = 'FETCH_POSTIMPORT_REQUEST'
const FETCH_POSTIMPORT_SUCCESS = 'FETCH_POSTIMPORT_SUCCESS'
const FETCH_POSTIMPORT_FAILURE = 'FETCH_POSTIMPORT_FAILURE'
const CLEAR_POSTIMPORT = 'CLEAR_POSTIMPORT'

const FETCH_SHOWCASE_POSTS_SUCCESS = 'FETCH_SHOWCASE_POSTS_SUCCESS'

const FETCH_POSTTAGS_REQUEST = 'FETCH_POSTTAGS_REQUEST'
const FETCH_POSTTAGS_SUCCESS = 'FETCH_POSTTAGS_SUCCESS'
const FETCH_POSTTAGS_FAILURE = 'FETCH_POSTTAGS_FAILURE'

const ADD_CANDIDATEIMAGE = 'ADD_CANDIDATEIMAGE'
const CLEAR_CANDIDATEIMAGES = 'CLEAR_CANDIDATEIMAGES'

const CREATE_POST_REQUEST = 'CREATE_POST'
const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS'
const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE'

const CREATE_POST_MANAGER_REQUEST = 'CREATE_POST_MANAGER_REQUEST'
const CREATE_POST_MANAGER_SUCCESS = 'CREATE_POST_MANAGER_SUCCESS'
const CREATE_POST_MANAGER_FAILURE = 'CREATE_POST_MANAGER_FAILURE'

const COMPLETE_LIVESTREAM = 'COMPLETE_LIVESTREAM'

const ADD_BOOKMARK_SUCCESS = 'ADD_BOOKMARK_SUCCESS'

const FETCH_POST_WRITE_PERMISSIONS_REQUEST = 'FETCH_POST_WRITE_PERMISSIONS_REQUEST'
const FETCH_POST_WRITE_PERMISSIONS_SUCCESS = 'FETCH_POST_WRITE_PERMISSIONS_SUCCESS'
export const FETCH_POST_WRITE_PERMISSIONS_FAILURE = 'FETCH_POST_WRITE_PERMISSIONS_FAILURE'

const FETCH_POST_TRANSLATION_REQUEST = 'FETCH_POST_TRANSLATION_REQUEST'
const FETCH_POST_TRANSLATION_SUCCESS = 'FETCH_POST_TRANSLATION_SUCCESS'
const FETCH_POST_TRANSLATION_FAILURE = 'FETCH_POST_TRANSLATION_FAILURE'

const REVERT_POST_TRANSLATION = 'REVERT_POST_TRANSLATION'
const SET_POST_TRANSLATION = 'SET_POST_TRANSLATION'
const REMOVE_POST_TRANSLATION = 'REMOVE_POST_TRANSLATION'

const FETCH_RED5PRO_SDK_REQUEST = 'FETCH_RED5PRO_SDK'
const FETCH_RED5PRO_SDK_SUCCESS = 'FETCH_RED5PRO_SDK_SUCCESS'
const FETCH_RED5PRO_SDK_FAILURE = 'FETCH_RED5PRO_SDK_FAILURE'

// Action Creators
export const startOpenShareDialog = () => {
	return {
		type: OPEN_SHARE_DIALOG_REQUEST,
	}
}

export const openShareDialog = (sharePostId, postIds, reactionType, activity) => {
	return {
		type: OPEN_SHARE_DIALOG,
		activity,
		sharePostId,
		postIds,
		reactionType,
		}
}

export const closeShareDialog = () => {
	return {
		type: CLOSE_SHARE_DIALOG,
	}
}

export const startOpenShareCompleteDialog = () => {
	return {
		type: OPEN_SHARE_COMPLETE_DIALOG_REQUEST,
	}
}

export const openShareCompleteDialog = (sharePoints, isOpen, shareSucceeded, failedReason) => {
	return {
		type: OPEN_SHARE_COMPLETE_DIALOG,
		sharePoints,
		isOpen,
		shareSucceeded,
		failedReason,
	}
}

export const closeShareCompleteDialog = () => {
	return {
		type: CLOSE_SHARE_COMPLETE_DIALOG,
	}
}

// This synchronous call is used to indicate in the store that
// an asynchronous post fetch call is about to start
export const startPostRequest = postId => {
	return {
		type: FETCH_POST_REQUEST,
		context: { postId },
	}
}

export const clearPostImport = () => {
	return {
		type: CLEAR_POSTIMPORT,
	}
}

export const addCandidateImage = image => {
	return {
		type: ADD_CANDIDATEIMAGE,
		context: { image },
	}
}

export const clearCandidateImages = () => {
	return {
		type: CLEAR_CANDIDATEIMAGES,
	}
}

export const completeLiveStream = postId => {
	return {
		type: COMPLETE_LIVESTREAM,
		context: { postId },
	}
}

export const revertPostTranslation = postId => {
	return {
		type: REVERT_POST_TRANSLATION,
		context: { postId },
	}
}

export const setPostTranslation = postId => {
	return {
		type: SET_POST_TRANSLATION,
		context: { postId },
	}
}

export const removePostTranslation = postId => {
	return {
		type: REMOVE_POST_TRANSLATION,
		context: { postId },
	}
}

// Async Action Creators

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

export const asyncGetPost = (postId, abortable) => {
	// Call the API
	return vsApi({
		endpoint: requests.getPost.getEndpoint(postId, postIncludesDefault),
		actions: {
			request: FETCH_POST_REQUEST,
			success: FETCH_POST_SUCCESS,
			failure: FETCH_POST_FAILURE,
		},
		context: {
			postId,
		},
		options: { captureUnhandledPromiseRejection: false, abortable },
	})
}

export const asyncGetPosts = postIds => {
	return vsApi({
		endpoint: requests.getPosts.getEndpoint(postIds),
		actions: {
			request: FETCH_POSTS_REQUEST,
			success: FETCH_POSTS_SUCCESS,
			failure: FETCH_POSTS_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetApprovedPosts = (userId, skip, take) => {
	return vsApi({
		endpoint: requests.getApprovedPosts.getEndpoint(userId, skip, take),
		actions: {
			request: FETCH_APPROVED_CONTENT_REQUEST,
			success: FETCH_APPROVED_CONTENT_SUCCESS,
			failure: FETCH_APPROVED_CONTENT_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetExternalFeedPosts = (feedId, skip, take, abortable) => {
	return vsApi({
		endpoint: requests.getExternalFeedPosts.getEndpoint(feedId, skip, take),
		actions: {
			success: FETCH_EXTERNAL_FEED_POSTS_SUCCESS,
		},
		options: { abortable },
	})
}

export const asyncGetPostWatchVideo = (postId, abortable) => {
	return vsApi({
		endpoint: requests.getPost.getEndpoint(postId, postIncludes.watchVideo),
		options: { abortable },
	})
}

export const asyncLikePost = (postId, like = true, activity = {}, context = {}) => {
	// like = true: LIKE, like = false: UNLIKE
	return vsApi({
		endpoint: requests.likePost.getEndpoint(postId),
		requestOptions: requests.likePost.getRequestOptions(like, activity),
		context,
	})
}

export const asyncGetShowcasePosts = (featuredContentCategoryId, maxNumberOfPosts) => {
	return vsApi({
		endpoint: requests.getShowcasePosts.getEndpoint(featuredContentCategoryId, maxNumberOfPosts),
		actions: {
			success: FETCH_SHOWCASE_POSTS_SUCCESS,
		},
	})
}

export const asyncGetPostLikers = (postId, after = null, limit = 3, abortable) => {
	return vsApi({
		endpoint: requests.getPostLikers.getEndpoint(postId, after, limit),
		context: {
			postId,
			after,
		},
		options: { captureUnhandledPromiseRejection: false, abortable },
	})
}

export const asyncGetPostLikersTooltip = (postId, count = 5, abortable) => {
	return vsApi({
		endpoint: requests.getPostLikersTooltip.getEndpoint(postId, count),
		context: {
			postId,
			count,
		},
		options: { captureUnhandledPromiseRejection: false, abortable },
	})
}

export const asyncPostView = (id, activity = {}, userId) => {
	return vsApi({
		endpoint: requests.viewPost.endpoint,
		requestOptions: requests.viewPost.getRequestOptions(id, activity, userId),
	})
}

export const asyncSetPostViewDuration = (id, sessionId, seconds, activity = {}) => {
	return vsApi({
		endpoint: requests.setPostViewDuration.getEndpoint(id, sessionId),
		requestOptions: requests.setPostViewDuration.getRequestOptions(sessionId, seconds, activity),
	})
}

export const asyncMediaView = (id, eventType, abortable, userId, activity = {}) => {
	return vsApi({
		endpoint: requests.viewMedia.endpoint,
		requestOptions: requests.viewMedia.getRequestOptions(id, eventType, userId, activity),
		options: { abortable },
	})
}

export const asyncGetLiveStreamStatus = (postId, abortable) => {
	return vsApi({
		endpoint: requests.getLiveStreamStatus.endpoint,
		requestOptions: requests.getLiveStreamStatus.getRequestOptions(postId),
		actions: {
			success: FETCH_LIVESTREAM_STATUS_SUCCESS,
		},
		context: {
			postId,
		},
	})
}
export const asyncPlayLiveStream = (postId, abortable) => {
	return vsApi({
		endpoint: requests.startLiveStream.endpoint,
		requestOptions: requests.startLiveStream.getRequestOptions(postId),
		options: { abortable },
	})
}
export const asyncEndLiveStream = (token, postId) => {
	return vsApi({
		endpoint: requests.finishLiveStream.endpoint,
		requestOptions: requests.finishLiveStream.getRequestOptions(token),
	})
}
export const asyncGetPostTags = () => {
	return vsApi({
		endpoint: requests.getPostTags.endpoint,
		actions: {
			request: FETCH_POSTTAGS_REQUEST,
			success: FETCH_POSTTAGS_SUCCESS,
			failure: FETCH_POSTTAGS_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetPostImport = (url, abortable) => {
	return vsApi({
		endpoint: requests.importPost.endpoint,
		actions: {
			request: FETCH_POSTIMPORT_REQUEST,
			success: FETCH_POSTIMPORT_SUCCESS,
			failure: FETCH_POSTIMPORT_FAILURE,
		},
		requestOptions: requests.importPost.getRequestOptions(url),
		options: { captureUnhandledPromiseRejection: false, abortable },
	})
}

export const asyncCreatePost = post => {
	return vsApi({
		endpoint: requests.createPost.endpoint,
		actions: {
			request: CREATE_POST_REQUEST,
			success: CREATE_POST_SUCCESS,
			failure: CREATE_POST_FAILURE,
		},
		requestOptions: requests.createPost.getRequestOptions(post),
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncCreatePostManager = post => {
	return vsApi({
		endpoint: requests.createPostManager.endpoint,
		actions: {
			request: CREATE_POST_MANAGER_REQUEST,
			success: CREATE_POST_MANAGER_SUCCESS,
			failure: CREATE_POST_MANAGER_FAILURE,
		},
		requestOptions: requests.createPostManager.getRequestOptions(post),
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncUpdateImage = (postId, image) => {
	return vsApi({
		endpoint: requests.updateImage.getEndpoint(postId),
		requestOptions: requests.updateImage.getRequestOptions(image),
	})
}

export const asyncUpdateImageGallery = (postId, image) => {
	return vsApi({
		endpoint: requests.updateImageGallery.getEndpoint(postId),
		requestOptions: requests.updateImageGallery.getRequestOptions(image),
	})
}

export const asyncUpdateDocument = (postId, document) => {
	return vsApi({
		endpoint: requests.updateDocument.getEndpoint(postId, document),
		requestOptions: requests.updateDocument.getRequestOptions(document),
	})
}

export const asyncUpdateImageUrl = (postId, url) => {
	return vsApi({
		endpoint: requests.updateImageUrl.getEndpoint(postId),
		requestOptions: requests.updateImageUrl.getRequestOptions(url),
	})
}

export const asyncStartUploadVideo = (postId, contentType) => {
	return vsApi({
		endpoint: requests.startUploadVideo.getEndpoint(postId),
		requestOptions: requests.startUploadVideo.getRequestOptions(contentType),
	})
}

export const asyncCompleteUploadVideo = (postId, mimeType, ticket) => {
	return vsApi({
		endpoint: requests.completeUploadVideo.getEndpoint(postId),
		requestOptions: requests.completeUploadVideo.getRequestOptions(mimeType, ticket),
	})
}

export const asyncDeletePost = postId => {
	return vsApi({
		endpoint: requests.deletePost.getEndpoint(postId),
		requestOptions: requests.deletePost.requestOptions,
	})
}

export const asyncGetPostShares = (postId, skip, take = 20, abortable) => {
	return vsApi({
		endpoint: requests.getPostShares.getEndpoint(postId, skip, take),
		options: { abortable },
	})
}

export const asyncAddPostBookmark = (userId, postId, abortable) => {
	return vsApi({
		endpoint: requests.addPostBookmark.getEndpoint(userId),
		requestOptions: requests.addPostBookmark.getRequestOptions(postId),
		actions: {
			success: ADD_BOOKMARK_SUCCESS,
		},
		options: { abortable },
	})
}

export const asyncDeletePostBookmark = (userId, postId, abortable) => {
	return vsApi({
		endpoint: requests.deletePostBookmark.getEndpoint(userId, postId),
		requestOptions: requests.deletePostBookmark.requestOptions,
		options: { abortable },
	})
}

export const asyncFetchPostWritePermissions = userId => {
	return vsApi({
		endpoint: requests.fetchPostWritePermissions.getEndpoint(userId),
		actions: {
			request: FETCH_POST_WRITE_PERMISSIONS_REQUEST,
			success: FETCH_POST_WRITE_PERMISSIONS_SUCCESS,
			failure: FETCH_POST_WRITE_PERMISSIONS_FAILURE,
		},
	})
}

export const asyncTranslatePost = postId => {
	return vsApi({
		endpoint: requests.getPostTranslation.getEndpoint(postId),
		requestOptions: requests.getPostTranslation.requestOptions,
		actions: {
			request: FETCH_POST_TRANSLATION_REQUEST,
			success: FETCH_POST_TRANSLATION_SUCCESS,
			failure: FETCH_POST_TRANSLATION_FAILURE,
		},
		context: {
			postId,
		},
	})
}

export const asyncLoadRed5ProSdk = sdkVersion => {
	return async (dispatch, getState) => {
		const state = getState()

		const existingSdk = safeGetNestedProp(state, 'post.red5Sdk')

		const sdkUrl = getRed5SdkBasePath(sdkVersion) + 'red5pro-sdk.min.js'

		try {
			dispatch({ type: FETCH_RED5PRO_SDK_REQUEST })

			if (existingSdk.script) {
				removeScript(existingSdk.script)
			}

			// Check to see if there is a script already loaded.
			let script = document.querySelector(`script[src="${sdkUrl}"]`)

			// If no such script exists, load it from the external source.
			if (!script) {
				script = await loadScript(sdkUrl)
			}

			dispatch({ type: FETCH_RED5PRO_SDK_REQUEST, sdkVersion, script })
		} catch (error) {
			dispatch({
				type: FETCH_RED5PRO_SDK_FAILURE,
			})
		}
	}
}

// --- Initial State ---

// State will be mapped to the Redux store by combineReducers
const initialState = {
	hasBookmarkedPost: false,
	postsPending: {}, // Map: postId -> true. If postId exists in this object, it's being fetched for the first time
	posts: {}, // Map: postId -> post data
	postTranslations: {}, // Map postId -> PostTranslationData
	postCommentTranslations: {}, // Map postId -> PostTranslationData
	privatePosts: {}, // Map: postId -> true. If postId exists in this object, it's private (cannot be viewed unless signed in)
	showcasePostIds: [],
	isSharePostPending: false, // Is sharePost dispatch starting?
	sharePost: null, // Set to a post to open ShareDialog
	shareReaction: null, // Set to a reactionType to limit ShareDialog to a Social Reaction
	postLikes: {}, // Map: postId -> { [PostLiker], pending, after, hasMore }
	postTags: {},
	postPreview: {},
	postShares: {},
	postWritePermissions: {
		permissionsPending: false,
		managedCategoryPermissions: {},
		openCategoryPermissions: {},
		targetPermissions: [],
	},
	red5Sdk: {
		isLoading: false,
		script: null,
		version: null,
	},
}

// Helpers
const mergeInNewPosts = (newPosts, state) => {
	const newPostMap = newPosts.reduce((map, post) => {
		const originalPost = state.posts[post.postId]
		const postTranslation = state.postTranslations[post.postId]
		let isTranslated = false

		// Is post currently translated in store?
		if (originalPost && postTranslation && originalPost.isTranslated) {
			isTranslated = true

			// Replace translated fields in post response
			post.title = postTranslation.translated.title
			post.description = getTranslatedDescription(post, postTranslation)
			post.content = postTranslation.translated.content
			post.rawContent = postTranslation.translated.content
			post.suggestedShareTextList = postTranslation.translated.suggestedShareTextList
			post.tagline = postTranslation.translated.tagline

			post.isRtlLanguage = postTranslation.translated.isRtlLanguage
		}

		// Save optimized version of post in the store
		const newPost = PostData(post)
		newPost.isTranslated = isTranslated

		map[post.postId] = newPost

		return map
	}, {}) // map starts as an empty object

	// Add these posts to existing posts
	return { ...state.posts, ...newPostMap }
}

const mergeInNewSocialComments = (newPosts, state) => {
	const newCommentMap = newPosts.reduce((map, post) => {
		post.socialComments.forEach(comment => {
			map[comment.id] = SocialCommentData(comment) // Save optimized version of comment in the store
		})
		return map
	}, {}) // map starts as an empty object

	// Add these comments to existing social comments
	return { ...state.socialComments, ...newCommentMap }
}

const getPostsFromSearch = results =>
	results.reduce((obj, result) => {
		obj[result.post.postId] = PostData(result.post)
		return obj
	}, {})
// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function postReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_POST_REQUEST: {
			const postId = action.context.postId

			if (state.posts[postId]) {
				// Post exists. Remove pending state, if present
				const postsPending = { ...state.postsPending }
				delete postsPending[postId]

				return {
					...state,
					postsPending,
				}
			}

			// Post doesn't exist. Add to pending state
			const postsPending = { ...state.postsPending, [postId]: true }
			return {
				...state,
				postsPending,
			}
		}
		case FETCH_POST_SUCCESS: {
			const post = action.response
			const originalPost = state.posts[post.postId]
			let isTranslated = false

			// Replace this post in the store
			const newPost = PostData(post)

			if (originalPost && originalPost.isTranslated) {
				newPost.isTranslated = isTranslated
			}

			const posts = {
				...state.posts,

				[post.postId]: {
					...state.posts[post.postId],
					...newPost,
				},
			}

			// Remove from private posts
			const privatePosts = { ...state.privatePosts }
			delete privatePosts[post.postId]

			// Remove from pending list
			const postsPending = { ...state.postsPending }
			delete postsPending[post.postId]

			return {
				...state,
				posts,
				postsPending,
				privatePosts,
			}
		}
		case FETCH_POST_FAILURE: {
			const postId = action.context.postId
			const privatePosts = { ...state.privatePosts }

			// Did this fail because it is a private post?
			const status = safeGetNestedProp(action, 'error.status')
			if (status === 401) {
				// Add to private list
				privatePosts[postId] = true
			}

			// Remove from pending list
			const postsPending = { ...state.postsPending }
			delete postsPending[postId]

			return {
				...state,
				postsPending,
				privatePosts,
			}
		}
		case FETCH_POSTS_REQUEST: {
			// TODO : Do we need to update pending state?
			return {
				...state,
			}
		}
		case FETCH_POSTS_SUCCESS: {
			const { posts: newPosts } = action.response

			const posts = mergeInNewPosts(newPosts, state)
			return {
				...state,
				posts,
			}
		}
		case FETCH_POSTS_FAILURE: {
			// TODO : Do we need to update pending state?
			return {
				...state,
			}
		}

		case FETCH_POST_TRANSLATION_REQUEST: {
			const postId = action.context.postId

			return {
				...state,
				postTranslations: {
					...state.postTranslations,
					[postId]: PostTranslationPending(),
				},
			}
		}
		case FETCH_POST_TRANSLATION_SUCCESS: {
			const postId = action.context.postId
			const post = state.posts[postId] // Original Post

			if (!post) {
				// Post no longer in store. Also remove translation
				const postTranslations = { ...state.postTranslations }
				delete postTranslations[postId]

				return {
					...state,
					postTranslations,
				}
			}

			// Add post translation to store
			const postTranslation = PostTranslationData(post, action.response) // Save the post translation
			const postTranslations = {
				...state.postTranslations,
				[postId]: postTranslation,
			}

			// Update post with translated fields
			const translatedPost = cloneDeep(post)
			translatedPost.isTranslated = true
			translatedPost.title = postTranslation.translated.title
			translatedPost.titleWithEntities = postTranslation.translated.titleWithEntities
			translatedPost.description = getTranslatedDescription(post, postTranslation)
			translatedPost.descriptionWithEntities = getTranslatedDescriptionWithEntities(post, postTranslation)
			translatedPost.content = postTranslation.translated.content
			translatedPost.suggestedShareTextList = postTranslation.translated.suggestedShareTextList
			translatedPost.tagline = postTranslation.translated.tagline
			translatedPost.isRtlLanguage = postTranslation.translated.isRtlLanguage

			const posts = {
				...state.posts,
				[postId]: translatedPost,
			}

			// If post is being shared, update sharePost as well
			let sharePost = state.sharePost
			if (sharePost) {
				sharePost = cloneDeep(translatedPost)
			}

			return {
				...state,
				posts,
				postTranslations,
				sharePost,
			}
		}
		case FETCH_POST_TRANSLATION_FAILURE: {
			const postId = action.context.postId
			const postTranslations = {
				...state.postTranslations,
				[postId]: PostTranslationError(),
			}

			return {
				...state,
				postTranslations,
			}
		}
		case REMOVE_POST_TRANSLATION: {
			const postId = action.context.postId
			const postTranslations = {
				...state.postTranslations,
			}
			delete postTranslations[postId]

			return {
				...state,
				postTranslations,
			}
		}
		case REVERT_POST_TRANSLATION: {
			const postId = action.context.postId
			const post = state.posts[postId] // Translated Post
			const postTranslation = state.postTranslations[postId] // Post Translation

			if (!post || !post.isTranslated || !postTranslation)
				return {
					// Nothing to revert to!
					...state,
				}

			// Revert post to original fields
			const revertedPost = cloneDeep(post)
			revertedPost.isTranslated = false
			revertedPost.title = postTranslation.original.title
			revertedPost.titleWithEntities = postTranslation.original.titleWithEntities
			revertedPost.description = postTranslation.original.description
			revertedPost.descriptionWithEntities = postTranslation.original.descriptionWithEntities
			revertedPost.content = postTranslation.original.content
			revertedPost.suggestedShareTextList = postTranslation.original.suggestedShareTextList
			revertedPost.tagline = postTranslation.original.tagline

			revertedPost.isRtlLanguage = postTranslation.original.isRtlLanguage

			const posts = {
				...state.posts,

				[postId]: revertedPost,
			}

			// If post is being shared, update sharePost as well
			let sharePost = state.sharePost
			if (sharePost) {
				sharePost = cloneDeep(revertedPost)
			}

			return {
				...state,
				posts,
				sharePost,
			}
		}
		case SET_POST_TRANSLATION: {
			const postId = action.context.postId
			const post = state.posts[postId] // Original Post
			const postTranslation = state.postTranslations[postId] // Post Translation

			if (!post || !postTranslation)
				return {
					// Nothing to set from!
					...state,
				}

			// Reset post to translated fields
			const translatedPost = cloneDeep(post)
			translatedPost.isTranslated = true
			translatedPost.title = postTranslation.translated.title
			translatedPost.titleWithEntities = postTranslation.translated.titleWithEntities
			translatedPost.description = getTranslatedDescription(post, postTranslation)
			translatedPost.descriptionWithEntities = getTranslatedDescriptionWithEntities(post,	postTranslation)
			translatedPost.content = postTranslation.translated.content
			translatedPost.suggestedShareTextList = postTranslation.translated.suggestedShareTextList
			translatedPost.tagline = postTranslation.translated.tagline
			translatedPost.isRtlLanguage = postTranslation.translated.isRtlLanguage

			const posts = {
				...state.posts,
				[post.postId]: translatedPost,
			}

			// If post is being shared, update sharePost as well
			let sharePost = state.sharePost
			if (sharePost) {
				sharePost = cloneDeep(translatedPost)
			}

			return {
				...state,
				posts,
				sharePost,
			}
		}

		case FETCH_APPROVED_CONTENT_REQUEST: {
			// TODO : Do we need to update pending state?
			return {
				...state,
			}
		}
		case FETCH_APPROVED_CONTENT_SUCCESS: {
			const { posts: newPosts } = action.response

			const posts = mergeInNewPosts(newPosts, state)
			const socialComments = mergeInNewSocialComments(newPosts, state)

			return {
				...state,
				posts,
				socialComments,
			}
		}
		case FETCH_APPROVED_CONTENT_FAILURE: {
			// TODO : Do we need to update pending state?
			return {
				...state,
			}
		}
		case FETCH_SHOWCASE_POSTS_SUCCESS: {
			const { posts: newPosts } = action.response

			const posts = mergeInNewPosts(newPosts, state)
			const showcasePostIds = newPosts.map(post => post.postId)

			return {
				...state,
				posts,
				showcasePostIds,
			}
		}
		case CLEAR_CURRENT_USER: {
			return {
				...state,
				showcasePostIds: [], // Remove showcase posts, which can be user-specific
			}
		}
		case FETCH_CATEGORY_POSTS_SUCCESS: {
			const { posts: newPosts } = action.response

			// Category reducer saves the list of post IDs shown in the
			// currently selected category. The FETCH_CATEGORY_POSTS_SUCCESS
			// action type result also includes (potentially) a bunch of new
			// or updated posts. This Post reducer manages the actual posts,
			// so merge those new/updated posts into state
			const posts = mergeInNewPosts(newPosts, state)

			return {
				...state,
				posts,
			}
		}
		case FETCH_EXTERNAL_FEED_POSTS_SUCCESS: {
			const { posts: newPosts } = action.response

			// The External Feed request returns a set of posts. This reducer
			// action merges those new/updated posts into state
			const posts = mergeInNewPosts(newPosts, state)

			return {
				...state,
				posts,
			}
		}
		case FETCH_POSTS_LIKERS_REQUEST: {
			const postId = action.context.postId
			const fetchState = state.postLikes[postId] || { likers: [], hasMore: false }
			fetchState.pending = true

			const postLikes = {
				...state.postLikes,
				[postId]: fetchState,
			}

			return {
				...state,
				postLikes,
			}
		}
		case FETCH_POSTS_LIKERS_SUCCESS: {
			const postId = action.context.postId
			const newLikers = action.response.users.map(likeUser => PostLiker(likeUser))
			const allLikers = state.postLikes[postId].likers.concat(newLikers)
			const after = safeGetNestedProp(action, 'response.cursors.afterId')

			// Remove any duplicate entries. [{liker}] => {userId: {liker}} => [{liker}]
			const uniqueLikerMap = allLikers
				? allLikers.reduce((obj, liker) => {
						// Convert to a map
						obj[liker.userId] = liker
						return obj
				  }, {})
				: newLikers.reduce((obj, liker) => {
						// Convert to a map
						obj[liker.userId] = liker
						return obj
				  }, {})

			const fetchState = {
				...state.postLikes[postId],
				likers: Object.values(uniqueLikerMap),
				pending: false,
				after: after,
				hasMore: !!after,
			}
			const postLikes = {
				...state.postLikes,
				[postId]: fetchState,
			}

			return {
				...state,
				postLikes,
			}
		}
		case FETCH_POSTS_LIKERS_FAILURE: {
			const postId = action.context.postId

			const fetchState = {
				...state.postLikes[postId],
				pending: false,
			}

			const postLikes = {
				...state.postLikes,
				[postId]: fetchState,
			}

			return {
				...state,
				postLikes,
			}
		}

		case OPEN_SHARE_DIALOG_REQUEST: {
			return {
				...state,
				isSharePostPending: true,
			}
		}

		case OPEN_SHARE_DIALOG: {
			const {sharePostId, postIds, reactionType = null, activity } = action
			const sharePost = state.posts[sharePostId] || null

			return {
				...state,
				isSharePostPending: false,
				sharePost: {
					...sharePost,
					activity,
				},
				shareReaction: reactionType,
				postIds: postIds
			}
		}
		case CLOSE_SHARE_DIALOG: {
			const sharePost = null
			const shareReaction = null
			const postIds = null

			return {
				...state,
				sharePost,
				shareReaction,
				postIds,
			}
		}
		case OPEN_SHARE_COMPLETE_DIALOG_REQUEST: {
			return {
				...state,
				isSharePostPending: true,
				isOpen: true,
			}
		}
		case OPEN_SHARE_COMPLETE_DIALOG: {
			const { sharePoints, isOpen, shareSucceeded, failedReason } = action

			return {
				...state,
				isSharePostPending: false,
				sharePoints,
				isOpen,
				shareSucceeded,
				failedReason,
			}
		}
		case CLOSE_SHARE_COMPLETE_DIALOG: {
			return {
				...state,
				isOpen: false,
			}
		}
		case FETCH_LIVESTREAM_STATUS_SUCCESS: {
			const stats = action.response
			const post = state.posts[stats.id]

			if (!post) return { ...state }

			return {
				...state,
				posts: {
					...state.posts,
					[stats.id]: {
						...post,
						liveStreamStartDate: stats.startDate,
						liveStreamState: stats.disconnected
							? LiveStreamState.disconnected
							: stats.started
								? LiveStreamState.running
								: post.liveStreamState,
					},
				},
			}
		}
		case COMPLETE_LIVESTREAM: {
			const { postId } = action.context
			return {
				...state,
				posts: {
					...state.posts,
					[postId]: {
						...state.posts[postId],
						liveStreamState: LiveStreamState.completed,
					},
				},
			}
		}
		case FETCH_POSTSEARCH_SUCCESS: {
			const results = action.response.results
			const posts = getPostsFromSearch(results)

			return {
				...state,
				posts: {
					...state.posts,
					...posts,
				},
			}
		}
		case FETCH_POSTTAGS_REQUEST: {
			return {
				...state,
				isTagsPending: true,
			}
		}
		case FETCH_POSTTAGS_SUCCESS: {
			const postTags = action.response
			return {
				...state,
				postTags,
				isTagsPending: false,
			}
		}
		case FETCH_POSTTAGS_FAILURE: {
			return {
				...state,
				isTagsPending: false,
			}
		}
		case FETCH_POSTIMPORT_REQUEST: {
			return {
				...state,
				postPreview: {
					isLoading: true,
				},
			}
		}
		case FETCH_POSTIMPORT_SUCCESS: {
			const postPreview = PostData(action.response)
			const candidateImages = action.response.candidateImages

			return {
				...state,
				postPreview: {
					...postPreview,
					isPotentialDuplicate: action.response.isPotentialDuplicate,
					candidateImages,
					isLoading: false,
					postObject: action.response,
				},
			}
		}
		case FETCH_POSTIMPORT_FAILURE: {
			return {
				...state,
				postPreview: {
					isLoading: false,
				},
			}
		}
		case CLEAR_POSTIMPORT: {
			return {
				...state,
				postPreview: {},
			}
		}
		case ADD_CANDIDATEIMAGE: {
			const image = action.context.image
			return {
				...state,
				postPreview: {
					...state.postPreview,
					uploadedImage: image,
				},
			}
		}
		case CLEAR_CANDIDATEIMAGES: {
			return {
				...state,
				postPreview: {
					...state.postPreview,
					candidateImages: [],
					uploadedImage: '',
				},
			}
		}
		case CREATE_POST_REQUEST: {
			return {
				...state,
				isSubmitting: true,
			}
		}
		case CREATE_POST_SUCCESS: {
			return {
				...state,
				isSubmitting: false,
			}
		}
		case CREATE_POST_FAILURE: {
			return {
				...state,
				isSubmitting: false,
			}
		}
		case CREATE_POST_MANAGER_REQUEST: {
			return {
				...state,
				isSubmitting: true,
			}
		}
		case CREATE_POST_MANAGER_SUCCESS: {
			return {
				...state,
				isSubmitting: false,
			}
		}
		case CREATE_POST_MANAGER_FAILURE: {
			return {
				...state,
				isSubmitting: false,
			}
		}
		case FETCH_BROADCAST_SUCCESS: {
			if (!action.response.posts) return { ...state }

			const posts = mergeInNewPosts(action.response.posts, state)

			return {
				...state,
				posts,
			}
		}
		case ADD_BOOKMARK_SUCCESS: {
			return {
				...state,
				hasBookmarkedPost: true,
			}
		}
		case FETCH_POST_WRITE_PERMISSIONS_REQUEST: {
			const postWritePermissions = {
				permissionsPending: true,
				failure: false,
			}
			return {
				...state,
				postWritePermissions,
			}
		}
		case FETCH_POST_WRITE_PERMISSIONS_SUCCESS: {
			const postWritePermissions = {
				permissionsPending: false,
				managedCategoryPermissions: action.response.managedCategoryPermissions
					? action.response.managedCategoryPermissions
					: {},
				openCategoryPermissions: action.response.openCategoryPermissions
					? action.response.openCategoryPermissions
					: {},
				targetPermissions: action.response.targetPermissions
					? action.response.targetPermissions
					: [],
				postAutoNotification: action.response.postAutoNotification,
			}

			return {
				...state,
				postWritePermissions,
			}
		}
		case FETCH_POST_WRITE_PERMISSIONS_FAILURE: {
			return {
				...state,
				postWritePermissions: {
					...state.postWritePermissions,
					permissionsPending: false,
					requestFailure: true,
				},
			}
		}
		case FETCH_RED5PRO_SDK_REQUEST: {
			return {
				...state,
				red5Sdk: {
					isLoading: true,
					version: null,
					script: null,
				},
			}
		}
		case FETCH_RED5PRO_SDK_SUCCESS: {
			return {
				...state,
				red5Sdk: {
					isLoading: false,
					version: action.sdkVersion,
					script: action.script,
				},
			}
		}
		case FETCH_RED5PRO_SDK_FAILURE: {
			return {
				...state,
				red5Sdk: {
					isLoading: false,
					version: null,
					script: null,
				},
			}
		}
		default:
			return state
	}
}
