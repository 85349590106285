import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { border, fontWeight, width, fontSize, color } from 'styles/variables'

const styles = {
	calendar: {
		selected: { borderRadius: 0 },
		outside: {
			color: 'transparent',
			backgroundColor: 'transparent',
		},
		navbar: {
			position: 'absolute',
			top: 23,
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			fontSize: fontSize.title,
			fontWeight: fontWeight.bold,
		},
		navbarIcon: {
			cursor: 'pointer',
			marginTop: width.spacing.narrow,
			marginRight: 13,
			marginBottom: 0,
			marginLeft: 13,
		},
	},
	calendarRules: {
		'.DayPicker-wrapper': {
			paddingBottom: 0,
		},
		'.DayPicker-wrapper:focus': {
			outline: 'none',
		},
		'.DayPicker-Month': {
			borderRight: border.basic,
			borderBottom: border.basic,
			borderLeft: border.basic,
			marginLeft: 0,
		},
		'.DayPicker-Month:last-of-type': {
			marginRight: 0,
		},
		'.DayPicker-Weekday': {
			color: color.primaryBlack,
			fontSize: fontSize.heading,
			fontWeight: fontWeight.bold,
		},
		'.DayPicker-Day': {
			color: color.primaryGrey,
			fontSize: fontSize.heading,
			fontWeight: fontWeight.bold,
		},
		'.DayPicker-Day.DayPicker-Day--disabled': {
			color: color.secondaryGrey,
			fontWeight: fontWeight.normal,
		},
		'.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
			backgroundColor: 'transparent',
			borderRadius: 0,
		},
		'.DayPicker-Caption': {
			textAlign: 'center',
			borderTop: border.basic,
			borderRight: border.basic,
			borderLeft: border.basic,
			paddingTop: width.spacing.narrow,
			paddingBottom: width.spacing.basic,
			marginBottom: 0,
		},
		'.DayPicker-Caption > div': {
			fontSize: fontSize.jumbo,
			fontWeight: fontWeight.normal,
		},
	},
}

export default prefixer.prefix(styles)
