// Action types
export const SET_VIEWPORT_SIZE = 'SET_VIEWPORT_SIZE'
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU'
export const TOGGLE_SUBMIT_POST = 'TOGGLE_SUBMIT_POST'
export const TOGGLE_SUBMIT_POST_MANAGER = 'TOGGLE_SUBMIT_POST_MANAGER'
const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
export const RELAY_SET_UPGRADENOTICE = 'RELAY_SET_UPGRADENOTICE'
const TOGGLE_PAGE_DIALOG = 'TOGGLE_PAGE_DIALOG'
const TOGGLE_ANNOUNCEMENT_DIALOG = 'TOGGLE_ANNOUNCEMENT_DIALOG'
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS'
export const SET_PARTY_MODE = 'SET_PARTY_MODE'
export const SET_UI_THEME = 'SET_UI_THEME'
export const SET_PRIMARYCOLOR_OVERRIDE = 'SET_PRIMARYCOLOR_OVERRIDE'
const TOGGLE_PAGE_POPUP = 'TOGGLE_PAGE_POPUP'
const SHOW_ACTION_DISABLED_DIALOG = 'SHOW_ACTION_DISABLED_DIALOG'
const HIDE_ACTION_DISABLED_DIALOG = 'HIDE_ACTION_DISABLED_DIALOG'
const SET_CURRENT_PLAYING_ELEMENT = 'SET_CURRENT_PLAYING_ELEMENT'
const SHOW_SKIP_LINK_BANNER = 'SHOW_SKIP_LINK_BANNER'
const HIDE_SKIP_LINK_BANNER = 'HIDE_SKIP_LINK_BANNER'
const SET_IS_ACCESSIBLE_KEYBOARD_TAB_STYLES = 'SET_IS_ACCESSIBLE_KEYBOARD_TAB_STYLES'

// Helpers
import { isLocalStorageAvailable } from '@dysi/js-helpers'
import { getViewportSize, breakpoints, isAtLeast } from 'helpers/breakpoints'
import { themeStorageKey, isValidTheme, getThemeFromStorage } from 'styles/themes/themeHelpers'
import { getReadablePrimaryColor } from 'styles/themes/themeHelpers'
import lightTheme from 'styles/themes/light'

// Action creators
export function setViewportSize() {
	return {
		type: SET_VIEWPORT_SIZE,
	}
}

export function toggleSideMenu() {
	return {
		type: TOGGLE_SIDE_MENU,
	}
}

export function toggleSubmitPost() {
	return {
		type: TOGGLE_SUBMIT_POST,
	}
}

export function toggleSubmitPostManager() {
	return {
		type: TOGGLE_SUBMIT_POST_MANAGER,
	}
}

// this function doesn't perform the actual toggle of the dialog. It just stores a flag.
export function togglePageDialog() {
	return {
		type: TOGGLE_PAGE_DIALOG,
	}
}

export function togglePagePopup(customPageId) {
	return {
		type: TOGGLE_PAGE_POPUP,
		customPageId,
	}
}
// Relay
export function relaySetUpgradeNotice(version) {
	return {
		type: RELAY_SET_UPGRADENOTICE,
		version,
	}
}

// this function doesn't perform the actual toggle of the dialog. It just stores a flag.
export function toggleAnnouncementDialog() {
	return {
		type: TOGGLE_ANNOUNCEMENT_DIALOG,
	}
}

// Online status
export function setOnlineStatus(isOnline) {
	return {
		type: SET_ONLINE_STATUS,
		isOnline,
	}
}

// Party Mode
export function setPartyMode(enablePartyMode) {
	return {
		type: SET_PARTY_MODE,
		enablePartyMode,
	}
}

// UI Theme (Dark, Light, etc)
export function setUiTheme(theme) {
	return {
		type: SET_UI_THEME,
		theme,
	}
}

export function setPrimaryColorOverride(primaryColor) {
	return {
		type: SET_PRIMARYCOLOR_OVERRIDE,
		primaryColor,
	}
}

export function showActionDisabledDialog() {
	return {
		type: SHOW_ACTION_DISABLED_DIALOG,
	}
}

export function hideActionDisabledDialog() {
	return {
		type: HIDE_ACTION_DISABLED_DIALOG,
	}
}

export function showSkipLinkBanner() {
	return {
		type: SHOW_SKIP_LINK_BANNER,
	}
}

export function hideSkipLinkBanner() {
	return {
		type: HIDE_SKIP_LINK_BANNER,
	}
}

export const setCurrentPlayingElement = elementId => {
	return {
		type: SET_CURRENT_PLAYING_ELEMENT,
		context: { elementId },
	}
}

export const setIsAccessibleKeyboardTabStyles = isEnabled => {
	return {
		type: SET_IS_ACCESSIBLE_KEYBOARD_TAB_STYLES,
		context: { isEnabled },
	}
}

// Set the initial state
const initialState = {
	viewport: {
		size: breakpoints.large.name,
	},
	sideMenuOpen: false,
	submitPostOpen: false,
	submitPostManagerOpen: false,
	upgradeAvailable: false,
	pageDialogOpen: false,
	announcementDialogOpen: false,
	currentPlayingElement: '',
	// When a user is keyboard tabbing
	isAccessibleKeyboardTabStyles: false,
}

// Reducer
export default function api(state = initialState, action) {
	switch (action.type) {
		// SET_VIEWPORT_SIZE
		case SET_VIEWPORT_SIZE: {
			const size = getViewportSize(window.innerWidth)
			if (size !== state.viewport.size)
				return {
					...state,
					viewport: {
						size,
					},
					sideMenuOpen: false,
				}
			return state
		}

		// TOGGLE_SIDE_MENU
		case TOGGLE_SIDE_MENU: {
			// Don't let them open it if the screen is too big
			if (isAtLeast(breakpoints.large.name, state.viewport.size))
				return {
					...state,
					sideMenuOpen: false,
				}

			const newState = !state.sideMenuOpen
			return {
				...state,
				sideMenuOpen: newState,
			}
		}

		// TOGGLE_SUBMIT_POST
		case TOGGLE_SUBMIT_POST: {
			const newState = !state.submitPostOpen

			return {
				...state,
				submitPostOpen: newState,
			}
		}

		// TOGGLE_SUBMIT_POST_MANAGER
		// This action is different from the regular submit post toggle.
		// Because managers now have extra permissions and data to input for post creation. (ManagerLite)
		case TOGGLE_SUBMIT_POST_MANAGER: {
			const newState = !state.submitPostManagerOpen

			return {
				...state,
				submitPostManagerOpen: newState,
			}
		}

		// LOCATION_CHANGE - Close the side menu on any kind of navigation
		case LOCATION_CHANGE: {
			if (state.upgradeAvailable) {
				// Reload the current page from the server, not the browser's cache
				window.location.reload(true)

				return {
					...state,
					upgradeAvailable: false,
					sideMenuOpen: false,
				}
			}

			return {
				...state,
				sideMenuOpen: false,
			}
		}

		case RELAY_SET_UPGRADENOTICE: {
			const { version } = action

			if (version && version !== state.version) {
				return {
					...state,
					upgradeAvailable: true,
					version,
				}
			}

			return state
		}

		case TOGGLE_PAGE_DIALOG: {
			return {
				...state,
				pageDialogOpen: !state.pageDialogOpen,
			}
		}

		case TOGGLE_ANNOUNCEMENT_DIALOG: {
			return {
				...state,
				announcementDialogOpen: !state.announcementDialogOpen,
			}
		}

		case TOGGLE_PAGE_POPUP: {
			const { customPageId } = action
			const pagePopupId = customPageId

			return {
				...state,
				pagePopupId,
			}
		}

		case SET_ONLINE_STATUS: {
			const { isOnline } = action
			return {
				...state,
				isOnline,
			}
		}

		case SET_PARTY_MODE: {
			const { enablePartyMode } = action
			return {
				...state,
				enablePartyMode,
			}
		}

		case SET_UI_THEME: {
			const { theme } = action

			if (
				isValidTheme(theme) &&
				getThemeFromStorage().name !== theme &&
				isLocalStorageAvailable()
			) {
				localStorage.setItem(themeStorageKey, theme)
				window.location.reload()
			}
			return {
				...state,
				theme,
			}
		}

		case SET_PRIMARYCOLOR_OVERRIDE: {
			const { primaryColor } = action
			const primaryColorOverride = getReadablePrimaryColor(primaryColor)

			// Don't override for light theme
			if (getThemeFromStorage().name === lightTheme.name) return state

			// Only add override if it is different
			if (primaryColor.toLowerCase() === primaryColorOverride.toLowerCase()) return state

			return {
				...state,
				primaryColorOverride,
			}
		}

		case SHOW_ACTION_DISABLED_DIALOG: {
			return {
				...state,
				showActionDisabledDialog: true,
			}
		}

		case HIDE_ACTION_DISABLED_DIALOG: {
			return {
				...state,
				showActionDisabledDialog: false,
			}
		}

		case SHOW_SKIP_LINK_BANNER: {
			return {
				...state,
				showSkipLinkBanner: true,
			}
		}

		case HIDE_SKIP_LINK_BANNER: {
			return {
				...state,
				showSkipLinkBanner: false,
			}
		}

		case SET_CURRENT_PLAYING_ELEMENT: {
			const elementId = action.context.elementId

			return {
				...state,
				currentPlayingElement: elementId,
			}
		}

		case SET_IS_ACCESSIBLE_KEYBOARD_TAB_STYLES: {
			return {
				...state,
				isAccessibleKeyboardTabStyles: action.context.isEnabled,
			}
		}
		// Default
		default:
			return state
	}
}

// Misc
export const watchWindowResize = dispatch => {
	var resizeTimeout
	const resizeThrottler = () => {
		// ignore resize events as long as an actualResizeHandler execution is in the queue
		if (!resizeTimeout) {
			resizeTimeout = setTimeout(function() {
				resizeTimeout = null
				actualResizeHandler()
				// The actualResizeHandler will execute at a rate of 30fps
			}, 33)
		}
	}

	const actualResizeHandler = () => {
		dispatch(setViewportSize())
	}

	window.addEventListener('resize', resizeThrottler, false)
}
