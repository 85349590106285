import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import twitter from 'twitter-text'

// Components
import UserChannelPhoto from 'components/UserChannelPhoto/UserChannelPhoto'
import CountdownCircle from 'components/CountdownCircle/CountdownCircle'
import Icon, { Name } from 'components/Icons/FontAwesome'
import SuggestedShareText from './SuggestedShareText'

// Lang
import lang from './shareChannels.lang'

// Helpers and Actions
import { safeGetNestedProp } from '@dysi/js-helpers'
import SocialProviderData from 'helpers/socialProviderData'

// Enums
import ShareTextKeys from '../enums/shareTextKeys'
import ShareTextFormik from '../enums/shareTextFormikKeys'

// Styles
import styles from './ShareText.styles'
import { color, spacing } from 'styles/variables'

class ShareText extends Component {
	constructor(props) {
		super(props)
		this.textArea = React.createRef()
	}
	static propTypes = {
		primaryColor: PropTypes.string,
		twitterMax: PropTypes.number,
		connectedAccounts: PropTypes.array, // [{channel object}, {channel object}]
		setErrorMessage: PropTypes.func,
		clearErrorMessage: PropTypes.func,
		setSuccessMessage: PropTypes.func,

		// Actions
		authUserChannel: PropTypes.func,
		reloadUser: PropTypes.func,

		// Formik properties
		name: PropTypes.string,
		shareTextValue: PropTypes.string,
		suggestedTextValue: PropTypes.array,
		selectedAccountsIds: PropTypes.arrayOf(PropTypes.number), // [12, 4, 3]
		setFieldValue: PropTypes.func,
		setFieldError: PropTypes.func,
		isSubmitting: PropTypes.bool,
	}

	state = {
		textValue: '',
		placeholder: lang.placeholderOtherAccounts,
		tweetLength: null,
		isSuggestedShareEnabled: true,
		hasExtraFeatures: true,
		copyCommandSupported: true,
		accountFieldName: '',
		shareTextFieldName: '',
		suggestedShareTextOpen: false,
		copyLinkResult: '',
	}

	componentDidMount() {
		const { shareTextValue, twitterMax, suggestedTextValue, name } = this.props

		const accountFieldName =
			name === ShareTextKeys.Twitter
				? ShareTextFormik.twitterConnectedAccounts
				: name === ShareTextKeys.FacebookPage
					? ShareTextFormik.facebookPageConnectedAccounts
					: ShareTextFormik.linkedInConnectedAccounts
		const shareTextFieldName =
			name === ShareTextKeys.Twitter
				? ShareTextFormik.twitterShareText
				: name === ShareTextKeys.FacebookPage
					? ShareTextFormik.facebookPageShareText
					: ShareTextFormik.linkedInShareText
		const placeholder =
			name === ShareTextKeys.Twitter ? lang.placeholderTwitter : lang.placeholderOtherAccounts
		const copyCommandSupported = document.queryCommandSupported('copy')

		// NOTE: Due to compliance issues with Facebook Page API, Facebook Page share text area will
		// be independent of the LI + Others and Twitter text areas, but will not have any copy/text suggestion
		// functionality.
		const isSuggestedShareEnabled =
			name !== ShareTextKeys.FacebookPage
				? suggestedTextValue && suggestedTextValue.length > 0
				: false
		const hasExtras =
			isSuggestedShareEnabled ||
			(name === ShareTextKeys.Twitter && twitterMax) ||
			(name === ShareTextKeys.LinkedIn && copyCommandSupported)

		this.setState({
			textValue: name !== ShareTextKeys.FacebookPage ? shareTextValue : '',
			accountFieldName,
			shareTextFieldName,
			placeholder,
			copyCommandSupported,
			hasExtraFeatures: hasExtras,
			isSuggestedShareEnabled,
		})
	}

	componentDidUpdate(prevProps) {
		const { shareTextValue } = this.props
		if (prevProps.shareTextValue !== shareTextValue) {
			this.setState({ textValue: shareTextValue })
		}
	}

	componentWillUnmount() {
		if (this.successTimeout) clearTimeout(this.successTimeout)
	}

	toggleSuggestedShareText = () =>
		this.setState(prevState => ({ suggestedShareTextOpen: !prevState.suggestedShareTextOpen }))

	handleChange = e => this.setState({ textValue: e.target.value })

	handleReplaceText = text => {
		const { setFieldValue, setSuccessMessage } = this.props
		const { shareTextFieldName } = this.state

		setSuccessMessage(lang.replaceMessageSuccess)
		this.setState(
			{ textValue: text, suggestedShareTextOpen: false },
			setFieldValue(shareTextFieldName, text)
		)
	}

	handleTextBlur = e => {
		const { setFieldValue } = this.props
		const { shareTextFieldName } = this.state
		if (setFieldValue) setFieldValue(shareTextFieldName, e.target.value)
	}

	copyShareTextToClipboard = e => {
		const { setSuccessMessage, setErrorMessage } = this.props
		e.preventDefault()
		try {
			// iOS won't let you select and copy unless input readOnly is false and contentEditable is true
			if (this.textArea.current) {
				this.textArea.current.readOnly = false
				this.textArea.current.contentEditable = true
				this.textArea.current.select()
				document.execCommand('copy')
				setSuccessMessage(lang.copyLinkSuccess)
			}
		} catch (e) {
			setErrorMessage({ error: lang.copyLinkError })
			this.startTimeout()
		}
	}

	startTimeout = () => {
		const { copyLinkResult } = this.state
		const { clearErrorMessage } = this.props

		if (this.successTimeout) clearTimeout(this.successTimeout)

		if (copyLinkResult) {
			this.successTimeout = setTimeout(() => clearErrorMessage(), 5000)
		}
	}

	handleReAuth = provider => {
		const { authUserChannel, setFieldError, reloadUser } = this.props
		const { accountFieldName } = this.state

		return authUserChannel(provider)
			.then(response => {
				reloadUser()
				return response
			})
			.catch(error => {
				// Send error message back to ShareBody's FormError component
				const message = safeGetNestedProp(
					error,
					'error.messages[0]' || lang.authChannelErrorUnknown
				)
				setFieldError(accountFieldName, message)
			})
	}

	selectAccount = (userChannelId, isTwitter = false) => {
		const { selectedAccountsIds, setFieldValue } = this.props
		const { accountFieldName } = this.state

		let newSelectedAccountsIds = [...selectedAccountsIds]
		const wasAlreadySelected = selectedAccountsIds.includes(userChannelId)

		if (wasAlreadySelected) {
			// Deselect from list
			newSelectedAccountsIds.splice(newSelectedAccountsIds.indexOf(userChannelId), 1)
		} else {
			// Add channel to selected list. RULES:
			// Twitter - only one can be selected at a time. Remove any other twitter channels in selected list.
			// LI + Others and/or FBPage - multiple can be selected at a time. Add to list.
			if (isTwitter) {
				newSelectedAccountsIds = [userChannelId]
			} else {
				newSelectedAccountsIds = [...newSelectedAccountsIds, userChannelId]
			}
		}
		setFieldValue(accountFieldName, newSelectedAccountsIds)
	}

	handleAccount = channel => e => {
		e.preventDefault()

		if (channel.authRequired) {
			// Reauthorize channel, then select it
			this.handleReAuth(channel.provider)
				.then(() =>
					this.selectAccount(
						channel.userChannelId,
						channel.provider === SocialProviderData.Twitter.provider
					)
				)
				.catch(error => {
					/* Ignore */
				})
		} else {
			// Select or Deselect channel
			this.selectAccount(
				channel.userChannelId,
				channel.provider === SocialProviderData.Twitter.provider
			)
		}
	}

	renderConnectedAccounts = () => {
		const { connectedAccounts, selectedAccountsIds, name } = this.props
		if (!connectedAccounts) return null

		// If this is LinkedIn + Others, we need to prioritize LinkedIn to appear first.
		let sortedConnectedAccounts = connectedAccounts

		if (name === ShareTextKeys.LinkedIn) {
			sortedConnectedAccounts = connectedAccounts.sort(channel => {
				if (channel.provider === SocialProviderData.LinkedIn.provider) {
					return -1
				} else {
					return 0
				}
			})
		}

		return sortedConnectedAccounts.map(account => {
			if (!account) return null
			const selected = selectedAccountsIds.includes(account.userChannelId)
			const disabled = !selected

			return (
				<Fragment key={account.userChannelId}>
					<UserChannelPhoto
						style={styles.connectedAccountIcon}
						provider={account.provider}
						tooltip={account.displayName}
						disabled={disabled}
						authRequired={account.authRequired}
						photos={account.profilePictureImages}
						onClick={this.handleAccount(account)}
					/>
					{/* If there's only one account connected, display name and account name*/}
					{connectedAccounts.length === 1 && (
						<div style={styles.connectedAccountText}>
							<div style={styles.connectedAccountText.accountText}>{account.provider}</div>
							<div style={styles.connectedAccountText.displayName}>{account.displayName}</div>
						</div>
					)}
				</Fragment>
			)
		})
	}

	render() {
		const {
			textValue,
			placeholder,
			shareTextFieldName,
			isSuggestedShareEnabled,
			hasExtraFeatures,
			suggestedShareTextOpen,
			copyCommandSupported,
		} = this.state
		const {
			twitterMax,
			isSubmitting,
			selectedAccountsIds,
			name,
			primaryColor,
			suggestedTextValue,
			setErrorMessage,
			setSuccessMessage,
			clearErrorMessage,
		} = this.props
		const disabled = isSubmitting || selectedAccountsIds.length === 0
		const tweetLength =
			twitterMax && twitter.parseTweet(textValue, { maxWeightedTweetLength: twitterMax })

		const dynamicStyles = {
			textArea: {
				...styles.textContainer.textArea,
				height: hasExtraFeatures ? '75%' : '100%',
			},
			suggestedShareTextToggle: {
				...styles.extras.suggestedShareTextToggle,
				...(disabled && { cursor: 'not-allowed' }),
			},
			suggestedShareTextToggleText: {
				...styles.extras.suggestedShareTextToggle.text,
				color: disabled ? color.secondaryGrey : primaryColor,
			},
			copy: {
				...styles.extras.countdownCircle,
				cursor: disabled ? 'not-allowed' : 'pointer',
				bottom: spacing.narrow / 2,
			},
		}

		return (
			<div style={styles.container}>
				{/* Connected Accounts */}
				<div style={styles.connectedAccountsContainer}>{this.renderConnectedAccounts()}</div>
				{/* Share Text */}
				<div style={styles.textContainer}>
					<textarea
						name={shareTextFieldName}
						onBlur={this.handleTextBlur}
						disabled={disabled}
						onChange={this.handleChange}
						placeholder={placeholder}
						value={textValue}
						style={dynamicStyles.textArea}
						ref={this.textArea}
					/>
					{hasExtraFeatures && (
						<div style={styles.extras}>
							{/* Twitter Countdown Circle */}
							{twitterMax &&
								tweetLength && (
									<div style={styles.extras.countdownCircle}>
										<CountdownCircle
											currentLength={tweetLength.weightedLength}
											maxLength={twitterMax}
										/>
									</div>
								)}
							{/* Copy/Paste for LinkedIn + Others */}
							{name === ShareTextKeys.LinkedIn &&
								copyCommandSupported && (
									<div
										onClick={!disabled ? this.copyShareTextToClipboard : null}
										title={lang.copy}
										style={dynamicStyles.copy}
									>
										<Icon name={Name.copy} style={styles.extras.copy} color={color.secondaryGrey} />
									</div>
								)}
							{/* Suggested Share Text Toggle */}
							{isSuggestedShareEnabled && (
								<div
									style={dynamicStyles.suggestedShareTextToggle}
									onClick={!disabled ? this.toggleSuggestedShareText : null}
								>
									<div style={dynamicStyles.suggestedShareTextToggleText}>
										{lang.suggestedShareTextToggle}
									</div>
									<Icon
										name={suggestedShareTextOpen ? Name.chevronUp : Name.chevronDown}
										style={styles.extras.suggestedShareTextToggle.icon}
										color={disabled ? color.secondaryGrey : primaryColor}
									/>
								</div>
							)}
						</div>
					)}
				</div>
				{/* Suggested Share Text*/}
				{isSuggestedShareEnabled && (
					<SuggestedShareText
						isOpen={suggestedShareTextOpen}
						name={name}
						primaryColor={primaryColor}
						suggestedTextValue={suggestedTextValue}
						disabled={disabled}
						setSuccessMessage={setSuccessMessage}
						setErrorMessage={setErrorMessage}
						clearErrorMessage={clearErrorMessage}
						copyCommandSupported={copyCommandSupported}
						handleReplaceText={this.handleReplaceText}
						toggleSuggestedShareText={this.toggleSuggestedShareText}
					/>
				)}
			</div>
		)
	}
}

export default ShareText
