import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { breakpoints } from 'helpers/breakpoints'

const mapStateToProps = (state, ownProps) => {
	return {
		viewport: state.browser.viewport,
	}
}

export const BreakpointNames = {
	/** 'small' string value useful for style definitions, etc. */
	small: breakpoints.small.name,
	/** 'medium' string value useful for style definitions, etc. */
	medium: breakpoints.medium.name,
	/** 'large' string value useful for style definitions, etc. */
	large: breakpoints.large.name,
	/** 'huge' string value useful for style definitions, etc. */
	huge: breakpoints.huge.name,
}

// This HOC allows components access to the root viewport state.
// Originally, this was intended to make components aware of the responsive
// size of the browser window but it can be extended past that as well.
function Responsive(WrappedComponent) {
	return connect(mapStateToProps)(WrappedComponent)
}

const PropType = PropTypes.shape({
	size: PropTypes.oneOf(Object.values(breakpoints).map(b => b.name)),
})
Responsive.PropType = PropType

export default Responsive
