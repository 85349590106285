const ua = window.navigator.userAgent

// Chrome 1+
// No longer works on Chrome 71
// export const isChrome = !!window.chrome && !!window.chrome.webstore

const chromeMatch = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
export const chromeVersion = chromeMatch ? parseInt(chromeMatch[2], 10) : false

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined'

// iOS Mobile
export const isIphone = !!ua.match(/iPhone|iPod/i)
export const isIpad = !!ua.match(/iPad/i)
export const isIos = isIpad || isIphone

// Safari
export const isSafari = !!ua.match(/WebKit/i) && !ua.match(/Chrome|CriOS|OPiOS|EdgiOS/i)

// iOS Mobile Safari
export const isSafariMobile = isIos && isSafari

// Android Mobile
export const isAndroid = !!ua.match(/Android/i)

// Any mobile browser
export const isMobile = isIos || isAndroid

// Edge
export const isEdge = !!ua.match(/Edge/i)

// IE
export const isIE = !!document.documentMode

// Unsupported IE versions
export const isOldIE = document.documentMode && document.documentMode <= 11

/**
 * Whether or not the application is being run inside the NMAD environment
 */
export const isDesktopApp = !!window.ipcRenderer

/**
 * Whether browser is zoomed
 */
export const isBrowserZoomed = window.devicePixelRatio > 1 || false

// Is window being rendered in an iframe
export const isIframe = window !== window.parent

// Browser (Guess)
// WARNING: Not reliable! Use for logging only, not for code!!!
let browser = null
const browserInit = () => {
	try {
		return isEdge
			? 'Edge'
			: chromeVersion
				? 'Chrome'
				: isFirefox
					? 'Firefox'
					: isIE
						? 'IE'
						: isSafari
							? 'Safari'
							: isAndroid
								? 'Android'
								: 'Unknown'
	} catch (error) {}

	return 'Unknown'
}
/**
 * Which browser this might be
 * WARNING: Not reliable! Use for logging only, not for code!!!
 */
export const browserGuess = () => {
	if (browser === null) {
		browser = browserInit()
	}

	return browser
}

// Browser Version (Guess) as a Number (or null)
// WARNING: Not reliable! Use for logging only, not for code!!!
let browserVersion = -1
const browserVersionInit = () => {
	try {
		const edgeMatch = ua.match(/Edge\/([0-9]+)./i)
		const edgeVersion = edgeMatch && edgeMatch[1]
		const firefoxMatch = ua.match(/Firefox\/([0-9]+)./i)
		const firefoxVersion = firefoxMatch && firefoxMatch[1]
		const safariMatch = ua.match(/Safari\/([0-9]+)./i)
		const safariVersion = safariMatch && safariMatch[1]

		let version = isIE
			? document.documentMode
			: edgeVersion
				? edgeVersion
				: firefoxVersion
					? firefoxVersion
					: chromeVersion
						? chromeVersion
						: safariVersion
							? safariVersion
							: null

		if (typeof version === 'string') {
			version = parseInt(version, 10)
		}

		return version
	} catch (error) {}

	return null
}

/**
 * Which browser version this might be
 * WARNING: Not reliable! Use for logging only, not for code!!!
 */
export const browserVersionGuess = () => {
	if (browserVersion === -1) {
		browserVersion = browserVersionInit()
	}

	return browserVersion
}
