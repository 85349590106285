import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// Components
import Dialog from 'components/Dialog/Dialog'

// Helpers
import { ReactionType, ShareType } from 'scenes/ShareDialog/apiHelpers'
import { MomentTimeFormat } from 'components/Picker/TimePicker/TimePicker'

// Lang
import twitterLang from './shareChannels.lang'
import lang from 'scenes/ShareDialog/shareDialog.lang'

// Styles
import { color } from 'styles/variables'
import styles from './ShareButton.styles'

class ShareButton extends Component {
	static propTypes = {
		values: PropTypes.shape({
			connectedTwitterAccountIds: PropTypes.array,
			suggestedTwitterShareTextList: PropTypes.array,
			twitterShareText: PropTypes.string,
			connectedLinkedInAndOthersAccountIds: PropTypes.array,
			suggestedLinkedInAndOthersShareTextList: PropTypes.array,
			linkedInAndOthersShareText: PropTypes.string,
			connectedFacebookPageAccountIds: PropTypes.array,
			facebookPageShareText: PropTypes.string,
			shareDate: PropTypes.string,
		}),
		reaction: PropTypes.oneOf(Object.values(ReactionType)), // Note: reactionType === null means SharePost, reactionType !== null means ReactToPost
		postId: PropTypes.number,
		activity: PropTypes.string,
		share: PropTypes.func,

		getPost: PropTypes.func,
		setUserDefaultSharingChannels: PropTypes.func,
		clearUrgentBroadcast: PropTypes.func,
		setShareDateAndTime: PropTypes.func,
		openShareCompleteDialog: PropTypes.func,
		reloadUser: PropTypes.func,
		setErrorMessage: PropTypes.func,

		isTwitterEnabled: PropTypes.bool,
		primaryColor: PropTypes.string,
		userChannelIds: PropTypes.array,
		userId: PropTypes.number,
		userTimezone: PropTypes.string,
		showShareChannels: PropTypes.bool,
		render: PropTypes.any,
	}

	state = {
		isDisabled: false,
		shareType: null,
		showTwitterWarningDialog: false,
	}

	componentDidMount() {
		const { userChannelIds, userId, showShareChannels } = this.props
		if (!showShareChannels || !userId || !userChannelIds || userChannelIds.length === 0) {
			this.setState({ isDisabled: true })
		}
	}

	componentDidUpdate(prevProps) {
		if (
			(prevProps.showShareChannels && !this.props.showShareChannels) ||
			(prevProps.userChannelIds.length !== 0 && this.props.userChannelIds.length === 0)
		) {
			this.setState({ isDisabled: true })
		} else if (
			(!prevProps.showShareChannels && this.props.showShareChannels) ||
			(prevProps.userChannelIds.length === 0 && this.props.userChannelIds.length !== 0)
		) {
			this.setState({ isDisabled: false })
		}
	}

	onSubmit = shareType => {
		const {
			values: { connectedTwitterAccountIds, twitterShareText },
			isTwitterEnabled,
		} = this.props

		// Is Twitter share text empty? Show the warning dialog.
		if (isTwitterEnabled && connectedTwitterAccountIds.length > 0 && !twitterShareText) {
			this.setState({ shareType, showTwitterWarningDialog: true })
		} else {
			this.setState({ isDisabled: true }, this.sharePost(shareType))
		}
	}

	sharePost = shareType => {
		const {
			postId,
			activity,
			share,
			userId,
			userTimezone,
			reloadUser,
			setErrorMessage,
			clearUrgentBroadcast,
			getPost,
			openShareCompleteDialog,
			setUserDefaultSharingChannels,
			setShareDateAndTime,
			values: {
				connectedTwitterAccountIds,
				connectedLinkedInAndOthersAccountIds,
				connectedFacebookPageAccountIds,
				twitterShareText,
				facebookPageShareText,
				linkedInAndOthersShareText,
				shareDate,
			},
		} = this.props

		// Format values for API Call
		const newValues = {
			userChannelIds: [
				...connectedTwitterAccountIds,
				...connectedLinkedInAndOthersAccountIds,
				...connectedFacebookPageAccountIds,
			],
			twitterShareText,
			facebookShareText: facebookPageShareText,
			otherShareText: linkedInAndOthersShareText,
			shareType,
			shareDate,
		}
		const reaction = (this.props.reaction && this.props.reaction.reactionType) || null
		const isScheduledShare = shareType === ShareType.schedule || shareType === ShareType.autoQueue

		share(postId, newValues, reaction, activity)
			.then(shareResult => {
				// If scheduled share, remember the date for Share complete dialog.
				if (isScheduledShare) {
					if (
						shareType === ShareType.autoQueue &&
						shareResult[0].shareDate &&
						shareResult[0].shareDate[0]
					) {
						const momentShareDate = moment.tz(shareResult[0].shareDate[0], userTimezone)
						const newShareDate = momentShareDate.format()
						const newShareTime = momentShareDate.format(MomentTimeFormat)
						setShareDateAndTime(newShareDate, newShareTime)
					} else {
						setShareDateAndTime(shareDate)
					}
				}

				clearUrgentBroadcast()
				getPost(postId)
				setUserDefaultSharingChannels(userId, newValues.userChannelIds)
					.then(() => {
						// Update user's stats and channels.
						reloadUser()
							.then(() => {
								// Move to Share complete page.
								openShareCompleteDialog(isScheduledShare)
							})
							.catch(() => this.setState({ isDisabled: false }))
					})
					.catch(() => this.setState({ isDisabled: false }))
			})
			.catch(error => {
				let errorMessage = lang.errorUnknownShare

				if (!error.data || !error.data.Reason) {
					if (newValues.shareType === ShareType.schedule) {
						errorMessage = lang.errorScheduledSharesFailed
					} else if (reaction) {
						errorMessage = lang.errorUnknownReaction
					}
					setErrorMessage(null, errorMessage, isScheduledShare)
				} else {
					setErrorMessage(error, errorMessage, isScheduledShare)
				}

				this.setState({ isDisabled: false })
			})
	}

	closeTwitterWarningDialog = () => this.setState({ showTwitterWarningDialog: false })

	renderTwitterWarningDialog = () => {
		const { primaryColor } = this.props
		const { shareType, isDisabled, showTwitterWarningDialog } = this.state

		const dynamicStyles = {
			twitterWarningShare: {
				...styles.twitterWarning.share,
				color: isDisabled ? color.secondaryGrey : primaryColor,
				...(isDisabled && { cursor: 'not-allowed' }),
			},
			twitterWarningCancel: {
				...styles.twitterWarning.cancel,
				...(isDisabled && { color: color.secondaryGrey, cursor: 'not-allowed' }),
			},
		}

		let submitText = twitterLang.twitterWarningPostNow
		if (shareType === ShareType.autoQueue) {
			submitText = twitterLang.twitterWarningAutoQNow
		} else if (shareType === ShareType.schedule) {
			submitText = twitterLang.twitterWarningSchedule
		}

		const postAnyways = () => {
			this.setState({ showTwitterWarningDialog }, this.sharePost(shareType))
		}

		return (
			<Dialog
				isOpen={showTwitterWarningDialog}
				onClose={this.closeTwitterWarningDialog}
				title={twitterLang.postWithoutTwitter}
				style={styles.twitterWarning}
				secondaryLinks={
					<div style={dynamicStyles.twitterWarningShare}>
						<a onClick={postAnyways}>{submitText}</a>
					</div>
				}
				submitButtons={
					<div style={dynamicStyles.twitterWarningCancel}>
						<a onClick={this.closeTwitterWarningDialog}>{twitterLang.twitterWarningReturn}</a>
					</div>
				}
			>
				<div style={styles.twitterWarning.text}>{twitterLang.twitterWarningInfo}</div>
			</Dialog>
		)
	}

	render() {
		const { render } = this.props
		const { isDisabled, showTwitterWarningDialog } = this.state
		const onSubmit = this.onSubmit

		if (showTwitterWarningDialog) {
			return this.renderTwitterWarningDialog()
		} else {
			return <Fragment>{render(onSubmit, isDisabled)}</Fragment>
		}
	}
}

export default ShareButton
