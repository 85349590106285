export const testDataAttributes = {
	// User Profile
	userDetails: 'user-details',
	userPoints: 'user-points',
	userStats: 'user-stats',
	userChannels: 'user-channels',
	// Posts
	post: 'post',
	postOverflowMenu: 'post-overflow-menu',
	postCreator: 'post-creator',
	postSocialHeader: 'post-social-header',
	postMedia: 'post-media',
	postReactions: 'post-reactions',
	postActions: 'post-actions',
	postLike: 'post-like',
	// Discussions
	comment: 'comment',
	// Settings
	settingsChannels: 'settings-channels',
	// Messages
	conversation: 'conversation',
	conversationMessage: 'conversation-message',
}
