import cookie from 'react-cookies'
import rawCookie from 'cookie'
import queryString from 'query-string'
import { persistAuthTokens } from 'scenes/Auth/auth.reducer'
import { Settings } from 'wrappers/SettingsWrapper'

const DySiAuthExchangeCookieName = 'DySi_Auth_XFER'

// This middleware handles incoming AUTH passed in an auth transfer cookie. Its primary purpose
// is to help clients using external widgets like VoicestormSDK and Outbound Feeds (Touchpoints)
// by allowing them to auth on their side and have incoming clicks populate the signed-in user.
//
// The auth cookie is set by the [MemberAppRedirect] and [MemberAppPostRedirect] attributes
// in BrandApp as part of forwarding external requests from BrandApp to NMA
export const authCookieTransferMiddleware = history => ({
	dispatch,
	getState,
}) => next => action => {
	const authTranserCookieValue = cookie.load(DySiAuthExchangeCookieName, true)

	// Does the special authentication transfer cookie exist?
	if (authTranserCookieValue && action.type !== 'SET_AUTH_TOKENS') {
		// Attempt to get the incoming token and expiration from the hash
		const query = queryString.parse(authTranserCookieValue)
		const { token, expiration } = query

		// Remove auth transfer cookie by expiring it
		// NOTE: react-cookies v0.1.0 (cookie.remove) uses an old version
		//       of the cookie package that does not support sameSite: 'none'
		//       So we use the latest cookie pkg (rawCookie) to do the remove - Oct 2020
		const options = {
			expires: new Date(1970, 1, 1, 0, 0, 1),
			maxAge: 0,
			path: '/',
			secure: true,
			sameSite: 'none',
		}
		document.cookie = rawCookie.serialize(DySiAuthExchangeCookieName, '', options)
		//cookie.remove(DySiAuthExchangeCookieName, { path: '/', secure: true, sameSite: 'none' })

		// If we already have a valid user, move along
		if (Settings.User.IsLoggedIn.value(getState())) {
			return next(action)
		}

		// Persist the auth tokens to storage and state
		if (token && expiration) {
			dispatch(persistAuthTokens(token, expiration)) // SET_AUTH_TOKENS
		}
	}

	// Move along
	return next(action)
}
