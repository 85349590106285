/**
 * IF YOU MODIFY THIS FILE OR ANY RELATED TO IT
 * PLEASE RUN THE TESTS BEFORE CHECKING IN YOUR CHANGES!
 */

import React from 'react'
import PropTypes from 'prop-types'
import { safeGetNestedProp, stringIsEmptyOrWhitespace } from '@dysi/js-helpers'

// Styles
import tinycolor from 'tinycolor2'
import { fontWeight, color as colorVars } from 'styles/variables'

// Root FontAwesome library
// import { icon, layer, text, counter } from '@fortawesome/fontawesome-svg-core'
import { icon, layer, text, counter } from './TEMP-fontawesome-svg-core-1.2.20'

// ----------------------------------------
// Icon sets
// ----------------------------------------
import BrandIcons from './Variations/FontAwesome.brands'
import RegularIcons from './Variations/FontAwesome.regular'
import LightIcons from './Variations/FontAwesome.light'
import SolidIcons from './Variations/FontAwesome.solid'
import DuotoneIcons from './Variations/FontAwesome.duotone'

// ----------------------------------------
// Constants
// ----------------------------------------

// Icon modifiers
export const Modifier = {
	fixedWidth: 'fixedWidth',
	spin: 'spin',
	pulse: 'pulse',
}
const ModifierMapping = {
	fixedWidth: 'fa-fw',
	spin: 'fa-spin',
	pulse: 'fa-pulse',
}

// Icon Backdrops
export const Backdrop = {
	none: '',
	square: 'square',
	circle: 'circle',
}
export const BackdropMapping = {
	square: SolidIcons.faSquare,
	circle: SolidIcons.faCircle,
}

// Dev-friendly icon names
// - Keep these sorted alphabetically by grouping
export const Name = {
	// Duotone
	duoClipboardList: 'duoClipboardList',
	duoFilm: 'duoFilm',
	duoImage: 'duoImage',
	duoImages: 'duoImages',
	duoTvRetro: 'duoTvRetro',
	jedi: 'jedi',
	duoChevronCircleLeft: 'duoChevronCircleLeft',
	duoChevronCircleRight: 'duoChevronCircleRight',
	// Light
	angleLeftLight: 'angleLeftLight',
	angleRightLight: 'angleRightLight',
	timesLight: 'timesLight',
	snowflake: 'snowflake',
	// Regular
	awardOutline: 'awardOutline',
	calendar: 'calendar',
	circleOutline: 'circleOutline',
	commentOutline: 'commentOutline',
	commentLinesOutline: 'commentLinesOutline',
	ellipsisV: 'ellipsisV',
	exclamationSquare: 'exclamationSquare',
	heartOutline: 'heartOutline',
	replyOutline: 'replyOutline',
	retweetOutline: 'retweetOutline',
	rocketOutline: 'rocketOutline',
	shareOutline: 'shareOutline',
	thLargeOutline: 'thLargeOutline',
	thumbsUpOutline: 'thumbsUpOutline',
	times: 'times',
	// Solid
	alignJustify: 'alignJustify',
	angleLeft: 'angleLeft',
	angleRight: 'angleRight',
	bars: 'bars',
	bell: 'bell',
	bold: 'bold',
	bookmark: 'bookmark',
	birthdayCake: 'birthdayCake',
	bullsEyeArrow: 'bullsEyeArrow',
	// bullhorn: 'bullhorn',
	camera: 'camera',
	capsLock: 'capsLock',
	caretDown: 'caretDown',
	check: 'check',
	chevronCircleLeft: 'chevronCircleLeft',
	chevronCircleRight: 'chevronCircleRight',
	chevronDown: 'chevronDown',
	chevronLeft: 'chevronLeft',
	chevronRight: 'chevronRight',
	chevronUp: 'chevronUp',
	circle: 'circle',
	circleNotch: 'circleNotch',
	clipboard: 'clipboard',
	copy: 'copy',
	cog: 'cog',
	comment: 'comment',
	comments: 'comments',
	compress: 'compress',
	desktop: 'desktop',
	edit: 'edit',
	ellipsisH: 'ellipsisH',
	exclamation: 'exclamation',
	exclamationCircle: 'exclamationCircle',
	externalLink: 'externalLink',
	expand: 'expand',
	file: 'file',
	frown: 'frown',
	globe: 'globe',
	globeAmericas: 'globeAmericas',
	image: 'image',
	images: 'images',
	italic: 'italic',
	link: 'link',
	lock: 'lock',
	longArrowDown: 'longArrowDown',
	mobileAlt: 'mobileAlt',
	pencil: 'pencil',
	phone: 'phone',
	pause: 'pause',
	play: 'play',
	plus: 'plus',
	question: 'question',
	search: 'search',
	spinner: 'spinner',
	star: 'star',
	thumbsUp: 'thumbsUp',
	thumbtack: 'thumbtack',
	trash: 'trash',
	tv: 'tv',
	tvRetro: 'tvRetro',
	userCircle: 'userCircle',
	share: 'share',
	underline: 'underline',
	urgent: 'urgent',
	video: 'video',
	// brands
	apple: 'apple',
	android: 'android',
	dysi: 'dysi',
	ios: 'ios',
	windows: 'windows',
	// Social Providers
	blog: 'blog', // Social Icon pseudo-provider
	blogger: 'blogger',
	chatter: 'chatter',
	cutandpaste: 'cutandpaste',
	email: 'email',
	facebook: 'facebook',
	facebookpage: 'facebookpage',
	instagram: 'instagram',
	jive: 'jive',
	linkedin: 'linkedin',
	linkedinpage: 'linkedinpage',
	rss: 'rss',
	sapjam: 'sapjam',
	tencent: 'tencent',
	tumblr: 'tumblr',
	twitter: 'twitter',
	weibo: 'weibo',
	wordpress: 'wordpress',
	xing: 'xing',
	youtube: 'youtube',
	// TODO - Remove this
	fire: 'fire',
}

// Friendly-name to Icon mapping
//   This is only exported to make testing easier!
export const IconMapping = {
	// Duotone
	[Name.duoClipboardList]: DuotoneIcons.faClipboardList,
	[Name.duoFilm]: DuotoneIcons.faFilm,
	[Name.duoImage]: DuotoneIcons.faImage,
	[Name.duoImages]: DuotoneIcons.faImages,
	[Name.duoTvRetro]: DuotoneIcons.faTvRetro,
	[Name.jedi]: DuotoneIcons.faJedi,
	[Name.duoChevronCircleLeft]: DuotoneIcons.faChevronCircleLeft,
	[Name.duoChevronCircleRight]: DuotoneIcons.faChevronCircleRight,
	// Light
	[Name.angleLeftLight]: LightIcons.faAngleLeft,
	[Name.angleRightLight]: LightIcons.faAngleRight,
	[Name.timesLight]: LightIcons.faTimes,
	[Name.snowflake]: LightIcons.faSnowflake,
	// Outline
	[Name.awardOutline]: RegularIcons.faAward,
	[Name.calendar]: RegularIcons.faCalendarAlt,
	[Name.circleOutline]: RegularIcons.faCircle,
	[Name.commentOutline]: RegularIcons.faComment,
	[Name.commentLinesOutline]: RegularIcons.faCommentLines,
	[Name.ellipsisV]: RegularIcons.faEllipsisV,
	[Name.exclamationSquare]: RegularIcons.faExclamationSquare,
	[Name.frown]: RegularIcons.faFrown,
	[Name.globeAmericas]: RegularIcons.faGlobeAmericas,
	[Name.heartOutline]: RegularIcons.faHeart,
	[Name.rocketOutline]: RegularIcons.faRocket,
	[Name.replyOutline]: RegularIcons.faReply,
	[Name.retweetOutline]: RegularIcons.faRetweet,
	[Name.shareOutline]: RegularIcons.faShare,
	[Name.thLargeOutline]: RegularIcons.faThLarge,
	[Name.thumbsUpOutline]: RegularIcons.faThumbsUp,
	[Name.times]: RegularIcons.faTimes,
	// Solid
	[Name.alignJustify]: SolidIcons.faAlignJustify,
	[Name.angleLeft]: SolidIcons.faAngleLeft,
	[Name.angleRight]: SolidIcons.faAngleRight,
	[Name.bars]: SolidIcons.faBars,
	[Name.bell]: SolidIcons.faBell,
	[Name.birthdayCake]: SolidIcons.faBirthdayCake,
	[Name.bold]: SolidIcons.faBold,
	[Name.bookmark]: SolidIcons.faBookmark,
	[Name.bullsEyeArrow]: SolidIcons.faBullseyeArrow,
	[Name.camera]: SolidIcons.faCamera,
	[Name.capsLock]: SolidIcons.faArrowAltFromBottom,
	[Name.caretDown]: SolidIcons.faCaretDown,
	[Name.check]: SolidIcons.faCheck,
	[Name.chevronCircleLeft]: SolidIcons.faChevronCircleLeft,
	[Name.chevronCircleRight]: SolidIcons.faChevronCircleRight,
	[Name.chevronDown]: SolidIcons.faChevronDown,
	[Name.chevronLeft]: SolidIcons.faChevronLeft,
	[Name.chevronRight]: SolidIcons.faChevronRight,
	[Name.chevronUp]: SolidIcons.faChevronUp,
	[Name.circle]: SolidIcons.faCircle,
	[Name.circleNotch]: SolidIcons.faCircleNotch,
	[Name.clipboard]: SolidIcons.faClipboardList,
	[Name.cog]: SolidIcons.faCog,
	[Name.comment]: SolidIcons.faComment,
	[Name.comments]: SolidIcons.faComments,
	[Name.compress]: SolidIcons.faCompressAlt,
	[Name.copy]: SolidIcons.faCopy,
	[Name.desktop]: SolidIcons.faDesktop,
	[Name.edit]: SolidIcons.faEdit,
	[Name.ellipsisH]: SolidIcons.faEllipsisH,
	[Name.exclamation]: SolidIcons.faExclamation,
	[Name.exclamationCircle]: SolidIcons.faExclamationCircle,
	[Name.externalLink]: SolidIcons.faLink,
	[Name.expand]: SolidIcons.faExpandAlt,
	[Name.file]: SolidIcons.faFile,
	[Name.globe]: SolidIcons.faGlobe,
	[Name.image]: SolidIcons.faImage,
	[Name.images]: SolidIcons.faImages,
	[Name.italic]: SolidIcons.faItalic,
	[Name.link]: SolidIcons.faLink,
	[Name.lock]: SolidIcons.faLock,
	[Name.longArrowDown]: SolidIcons.faLongArrowDown,
	[Name.mobileAlt]: SolidIcons.faMobileAlt,
	[Name.pencil]: SolidIcons.faPencil,
	[Name.pause]: SolidIcons.faPause,
	[Name.phone]: SolidIcons.faPhone,
	[Name.play]: SolidIcons.faPlay,
	[Name.plus]: SolidIcons.faPlus,
	[Name.question]: SolidIcons.faQuestion,
	[Name.search]: SolidIcons.faSearch,
	[Name.share]: SolidIcons.faShare,
	[Name.spinner]: SolidIcons.faSpinner,
	[Name.square]: SolidIcons.faSquare,
	[Name.star]: SolidIcons.faStar,
	[Name.thumbsUp]: SolidIcons.faThumbsUp,
	[Name.thumbtack]: SolidIcons.faThumbtack,
	[Name.trash]: RegularIcons.faTrash,
	[Name.tv]: SolidIcons.faTv,
	[Name.tvRetro]: SolidIcons.faTvRetro,
	[Name.underline]: SolidIcons.faUnderline,
	[Name.urgent]: SolidIcons.faUrgent,
	[Name.userCircle]: SolidIcons.faUserCircle,
	[Name.video]: SolidIcons.faVideo,
	// [Name.bullhorn]: SolidIcons.faBullhorn,
	// Brands
	[Name.apple]: BrandIcons.faApple,
	[Name.android]: BrandIcons.faAndroid,
	[Name.dysi]: BrandIcons.faDySi,
	[Name.ios]: BrandIcons.faAppStoreIos,
	[Name.windows]: BrandIcons.faWindows,
	// Social Providers
	[Name.blog]: SolidIcons.faRss,
	[Name.chatter]: BrandIcons.faChatter,
	[Name.cutandpaste]: SolidIcons.faLink,
	[Name.email]: SolidIcons.faEnvelope,
	[Name.facebook]: BrandIcons.faFacebookF,
	[Name.facebookpage]: BrandIcons.faFacebookF,
	[Name.twitter]: BrandIcons.faXTwitter,
	[Name.blogger]: BrandIcons.faBloggerB,
	[Name.instagram]: BrandIcons.faInstagram,
	[Name.jive]: BrandIcons.faJive,
	[Name.linkedin]: BrandIcons.faLinkedinIn,
	[Name.linkedinpage]: BrandIcons.faLinkedinIn,
	[Name.rss]: SolidIcons.faRss,
	[Name.sapjam]: BrandIcons.faSapJam,
	[Name.tencent]: BrandIcons.faTencentWeibo,
	[Name.tumblr]: BrandIcons.faTumblr,
	[Name.weibo]: BrandIcons.faWeibo,
	[Name.wordpress]: BrandIcons.faWordpressSimple,
	[Name.xing]: BrandIcons.faXing,
	[Name.youtube]: BrandIcons.faYoutube,
	[Name.fire]: BrandIcons.faHotjar,
}

const getBackdropOptions = backdrop => {
	if (!backdrop) return {}

	return {
		mask: BackdropMapping[backdrop],
		transform: {
			size: 10,
		},
	}
}

class FontAwesomeIcon extends React.Component {
	static propTypes = {
		name: PropTypes.oneOf(Object.values(Name)).isRequired,
		// Shrinks the icon and masks it with an overlay (e.g. social provider icons)
		backdrop: PropTypes.oneOf(Object.values(Backdrop)),
		modifier: PropTypes.oneOf(Object.values(Modifier)),
		// Text to display over the middle of the icon (e.g. +3)
		text: PropTypes.string,
		// Displays a counter over the icon (e.g. notification count)
		count: PropTypes.number,
		// FontAwesome-specific transform object
		transform: PropTypes.object,
		style: PropTypes.any,
		// NOTE: Only use this for things that need a grey outer circle with a slightly smaller icon (e.g. UserPhoto counts)
		bordered: PropTypes.bool,
		// NOTE: Only use this for things that need a white backdrop (e.g. carousel chevrons)
		filled: PropTypes.bool,
		// The icon color
		color: PropTypes.string,
		// The secondary color of Duotone icons
		secondaryColor: PropTypes.string,
		// The tooltip to display on hover
		title: PropTypes.string,
	}

	static defaultProps = {
		backdrop: Backdrop.none,
		modifier: null,
		bordered: false,
		filled: false,
	}

	// NOTE - Don't mess with this unless you need to. We don't need to render icons unless something
	// truly meaningful changed. If you run into issues with this because you're changing any of the
	// following  props (style, transform, etc), then we should have a conversation before tweaking this.
	shouldComponentUpdate(nextProps) {
		const isDifferent =
			nextProps.name !== this.props.name ||
			nextProps.text !== this.props.text ||
			nextProps.count !== this.props.count ||
			nextProps.backdrop !== this.props.backdrop ||
			nextProps.modifier !== this.props.modifier ||
			nextProps.color !== this.props.color ||
			nextProps.secondaryColor !== this.props.secondaryColor

		return isDifferent
	}

	render() {
		// props to not apply to the outer span
		const {
			name,
			modifier,
			backdrop,
			text: txt,
			count,
			transform = {},
			bordered,
			filled,
			color,
			secondaryColor,
			title,
			...rest
		} = this.props

		// props to apply to the outer span
		const { style } = this.props

		const backgroundColor = color
			? tinycolor(color)
			: tinycolor(safeGetNestedProp(style, 'color', colorVars.primaryBlack))

		const iconVariable = IconMapping[name]

		const iconMods = modifier ? [ModifierMapping[modifier]] : []

		let ic

		// Icons with text
		if (txt) {
			// Build the text icon layer by layer
			ic = layer(push => {
				if (bordered) {
					push(
						icon(SolidIcons.faCircle, {
							styles: {
								color: `${colorVars.secondaryRed}`,
							},
							transform: {
								size: 17,
							},
						})
					)
				}
				// Base icon first
				push(
					icon(iconVariable, {
						classes: iconMods,
						...getBackdropOptions(backdrop),
					})
				)
				// Layer the text on top. This should be white regardless of theme.
				push(
					text(txt, {
						styles: {
							color: `${colorVars.alwaysWhite}`,
							'font-weight': `${fontWeight.semiBold}`,
						},
						transform: transform,
					})
				)
			}).html
		}
		// Icons with a count badge
		else if (count) {
			const displayCount = count > 99 ? '99+' : count
			ic = layer(push => {
				// Base icon first
				push(icon(iconVariable))
				// Layer the count on top
				push(
					counter(displayCount, {
						styles: {
							'background-color': `${colorVars.secondaryRed}`,
							'font-size': '1.75em',
							'font-weight': `${fontWeight.semiBold}`,
							position: 'absolute',
							top: '-25%',
							right: '-25%',
							'-webkit-transform': 'scale(0.3)',
							transform: 'scale(0.3)',
							'-webkit-transform-origin': 'top right',
							'transform-origin': 'top right',
							'line-height': 0.95,
						},
					})
				)
			}).html
		}
		// All other icons
		else {
			ic = icon(iconVariable, {
				classes: iconMods,
				transform: transform,
				...getBackdropOptions(backdrop),
				// Title is applied to the icon instead of the span since span has pointerEvents: none applied
				title: title,
				// This is required to allow the title tooltip to seep through
				styles: {
					// 'pointer-events': 'fill',
					color: backgroundColor.toHexString(),
				},
			}).html
		}

		const iconStyle = {
			// We do this so that the icon doesn't hijack the clicks but be careful since this prevents
			// title attributes from displaying as expected.
			pointerEvents: 'none',
			...this.props.style,
			...(backdrop !== Backdrop.none && {
				color: backgroundColor.toHexString(),
			}),
			...(filled && {
				lineHeight: 1,
				background: colorVars.white,
				borderRadius: '50%',
				width: '1em',
				textAlign: 'center',
			}),
			...(secondaryColor && {
				'--fa-secondary-opacity': 1.0,
				'--fa-secondary-color': secondaryColor,
			}),
		}

		// CT-38 Web accessibility compliance
		if (stringIsEmptyOrWhitespace(title)) rest['aria-label'] = title

		return <span {...rest} style={iconStyle} dangerouslySetInnerHTML={{ __html: ic }} />
	}
}

export default FontAwesomeIcon
