import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { safeGetThemeValue } from 'wrappers/Theme'
import { Theme } from 'wrappers/ThemeWrapper'
import keyCodes from 'helpers/keycodeHelper'

// Actions
import {
	asyncTranslatePost,
	revertPostTranslation,
	setPostTranslation,
	removePostTranslation,
} from '../post.reducer'

// Lang
import lang from './postTranslationButton.lang'
import buttons from 'helpers/lang/buttons.lang'

//#region Styles
import { fontSize, color } from 'styles/variables'
import Alert, { AlertType } from 'components/Alert/Alert'

const styles = {
	loading: {
		color: color.primaryGrey,
		fontSize: fontSize.small,
	},
	link: {
		cursor: 'pointer',
		fontSize: fontSize.small,
	},
}
//#endregion

const mapStateToProps = state => {
	const postTranslationsMap = state.post.postTranslations
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)

	return {
		postTranslationsMap,
		primaryColor,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		translatePost: postId => dispatch(asyncTranslatePost(postId)),
		revertPostTranslation: postId => dispatch(revertPostTranslation(postId)),
		setPostTranslation: postId => dispatch(setPostTranslation(postId)),
		removePostTranslation: postId => dispatch(removePostTranslation(postId)),
	}
}

class PostTranslateButton extends Component {
	static propTypes = {
		postId: PropTypes.string,
		isTranslated: PropTypes.bool,
		isTranslatable: PropTypes.bool,
		style: PropTypes.object,

		// From Redux store
		postTranslationsMap: PropTypes.object,
		primaryColor: PropTypes.string,

		// Reducer actions
		translatePost: PropTypes.func,
		revertPostTranslation: PropTypes.func,
		setPostTranslation: PropTypes.func,
		removePostTranslation: PropTypes.func,
	}

	// Event Handlers
	handleClick = e => {
		e.preventDefault()
		e.stopPropagation()

		const {
			postId,
			isTranslated,
			postTranslationsMap,
			translatePost,
			revertPostTranslation,
			setPostTranslation,
		} = this.props

		if (isTranslated) {
			revertPostTranslation(postId)
		} else {
			const postTranslation = postTranslationsMap[postId]
			if (postTranslation) {
				// Translation exists. Set translation in Post
				setPostTranslation(postId)
			} else {
				// Translation does not exist. Fetch translation and update Post
				translatePost(postId)
			}
		}
	}

	handleAlertClose = e => {
		e.stopPropagation()

		const { postId, removePostTranslation } = this.props

		// Remove translation error from Redux store
		removePostTranslation(postId)
	}

	handleTranslateKeyDown = e => {
		if (e.keyCode !== keyCodes.enter) {
			return
		}
		this.handleClick(e)
	}

	render() {
		const {
			postId,
			isTranslated,
			isTranslatable,
			postTranslationsMap,
			primaryColor,
			style: styleOverride,
		} = this.props

		if (!isTranslatable) return null

		const postTranslation = postTranslationsMap[postId]
		const isLoading = safeGetNestedProp(postTranslation, 'isPending', false)
		const isError = safeGetNestedProp(postTranslation, 'isError', false)
		const linkStyle = {
			...styles.link,
			color: primaryColor,
			...styleOverride,
		}
		const loadingStyle = {
			...styles.loading,
			...styleOverride,
		}
		const translationText = isTranslated ? lang.revertToOriginal : lang.translate

		if (isLoading) return <span style={loadingStyle}>{buttons.loading}</span>

		if (isError)
			return (
				<Alert type={AlertType.error} onClose={this.handleAlertClose}>
					{lang.errorUnknown}
				</Alert>
			)

		return (
			<span
				style={linkStyle}
				onClick={this.handleClick}
				onKeyDown={this.handleTranslateKeyDown}
				tabIndex={0}
				aria-label={translationText}
				role="button"
			>
				{translationText}
			</span>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PostTranslateButton)
