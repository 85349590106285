import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Routes from 'helpers/routes'
import { withRouter } from 'react-router-dom'

// Components
import UserPhoto from 'components/UserPhoto/UserPhoto'
import Icon, { Name } from 'components/Icons/FontAwesome'
import BroadcastIcon from 'components/Icons/Sets/BroadcastIcons'
import DateTime, { DateFormat } from 'components/DateTime/DateTime'
import RestrictedText, { Restrictions } from 'components/RestrictedText/RestrictedText'
import PostLink from 'components/PostLink/PostLink'
import BadgeIcon from 'components/BadgeIcon/BadgeIcon'

// Styles
import { color, fontSize, fontWeight, border, spacing, margin } from 'styles/variables'

// Enums
import broadcastPriorities from 'scenes/Broadcast/enums/broadcastPriorities'
import UserPhotoSize from 'components/UserPhoto/userPhotoSize'
import { SurveyStatus } from 'scenes/Survey/SurveyComplete'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { Settings } from 'wrappers/SettingsWrapper'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'
import { Activity } from 'helpers/activityHelpers'
import { DisplayMode } from 'scenes/Post/data/post.data'
import KeyNames from 'helpers/keyNames'

// Reducer
import { asyncMarkAsRead } from 'pages/Notifications/notification.reducer'
import { asyncViewBroadcast } from 'scenes/Broadcast/broadcast.reducer'

// Lang
import lang from './userNotification.lang'
import CommunityLogo from 'scenes/Header/CommunityLogo'

// Supported notification types
export const SupportedUserNotificationTypes = {
	articleBroadcast: 'ArticleBroadcast',
	birthday: 'Birthday',
	workAnniversary: 'WorkAnniversary',
	articleCommentLike: 'ArticleCommentLike',
	articleCommentReply: 'ArticleCommentReply',
	postApproval: 'PostApproval',
	postAutoNotification: 'PostAutoNotification',
	userMention: 'UserMention',
	quickPoll: 'QuickPoll',
	workflow: 'Workflow',
	awardEarned: 'AwardEarned',
	awardAvailable: 'AwardAvailable',
	failedShare: 'FailedShare',
	cappedShare: 'CappedShare',
	socialChannelRenewal: 'SocialChannelRenewal'
}
const SupportedUserNotificationTypesList = Object.values(SupportedUserNotificationTypes)

export const UserNotificationLayout = {
	full: 'Full',
	minimal: 'Minimal',
}

//region Styles
const styles = {
	base: {
		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		paddingTop: spacing.basic,
		paddingBottom: spacing.basic,
		paddingRight: spacing.basic,
		paddingLeft: spacing.basic,
		textDecoration: 'none',
		borderBottom: border.basic,
		cursor: 'pointer',
		[UserNotificationLayout.minimal]: {
			paddingTop: 5,
			paddingBottom: 5,
			paddingRight: spacing.basic,
			paddingLeft: spacing.basic,
		},
	},
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		[UserNotificationLayout.minimal]: {
			marginTop: margin.narrow / 4,
			marginBottom: margin.narrow / 4,
		},
	},
	// Information
	information: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignContent: 'center',
		flex: 1,
		width: '0',
		overflow: 'hidden',
	},
	title: {
		fontSize: fontSize.small,
		color: color.primaryGrey,
	},
	priorityIcon: {
		urgent: {
			color: color.secondaryRed,
			marginRight: 5,
		},
		high: {
			color: color.secondaryYellow,
			marginRight: 5,
		},
		low: {
			color: color.secondaryBlue,
			marginRight: 5,
		},
	},
	headline: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',

		fontSize: fontSize.base,
		fontWeight: fontWeight.semiBold,
		color: color.primaryBlack,
	},
	date: {
		fontSize: fontSize.small,
		color: color.primaryGrey,
		[UserNotificationLayout.minimal]: {
			marginRight: 5,
		},
	},
	logo: {
		marginRight: spacing.basic,
		borderRadius: border.roundedRadius,
		width: UserPhotoSize.small.width,
	},
	// Interaction
	interaction: {
		display: 'flex',
		flexFlow: 'column nowrap',
		marginLeft: 'auto', // Right justify column
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
	navLink: {
		fontSize: fontSize.small,
		textAlign: 'right',
	},
	pin: {
		color: color.secondaryRed,
	},
}
//endregion

const mapStateToProps = (state, ownProps) => {
	const communityName = safeGetNestedProp(state, Settings.Community.Name.path)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const squareLogoUrl = safeGetThemeValue(state, Theme.SquareLogoUrl)
	const broadcastMap = safeGetNestedProp(state, 'broadcast.broadcastById')

	return {
		broadcastMap,
		communityName,
		primaryColor,
		squareLogoUrl,
	}
}
const mapDispatchToProps = (dispatch, ownProps) => {
	const { currentUserId, notificationInfo } = ownProps

	return {
		markAsRead: () => dispatch(asyncMarkAsRead(currentUserId, notificationInfo.notificationIds)),
		viewBroadcast: broadcastId => dispatch(asyncViewBroadcast(broadcastId)),
	}
}

class UserNotification extends React.Component {
	static propTypes = {
		notificationInfo: PropTypes.shape({
			notificationType: PropTypes.oneOf(SupportedUserNotificationTypesList).isRequired,
		}),
		history: PropTypes.shape({
			push: PropTypes.func,
		}),

		layout: PropTypes.oneOf(Object.values(UserNotificationLayout)),

		// From mapStateToProps
		broadcastMap: PropTypes.object,
		communityName: Settings.Community.Name.propType,
		currentUserId: PropTypes.number,
		primaryColor: Theme.PrimaryColor.propType,
		squareLogoUrl: Theme.SquareLogoUrl.propType,

		// From mapDispatchToProps
		markAsRead: PropTypes.func,
		fetchSurvey: PropTypes.func,
		viewBroadcast: PropTypes.func,
	}

	static defaultProps = {
		layout: UserNotificationLayout.full,
	}

	getPhoto() {
		const { notificationInfo, squareLogoUrl, communityName } = this.props
		let notificationSender

		switch (notificationInfo.notificationType) {
			case SupportedUserNotificationTypes.articleBroadcast:
				if (notificationInfo.broadcast.senderInfo.userId) {
					// A user sent this, show the user
					notificationSender = notificationInfo.broadcast.senderInfo
				}
				break
			case SupportedUserNotificationTypes.articleCommentReply:
				notificationSender = notificationInfo.replyToComment.replies[0].creatorInfo
				break
			case SupportedUserNotificationTypes.articleCommentLike:
				notificationSender = notificationInfo.likeOnComment.likes[0]
				break
			case SupportedUserNotificationTypes.userMention:
				notificationSender = notificationInfo.mention.originalComment.creatorInfo
				break
			case SupportedUserNotificationTypes.postApproval:
			case SupportedUserNotificationTypes.postAutoNotification:
			case SupportedUserNotificationTypes.birthday:
			case SupportedUserNotificationTypes.workAnniversary:
			case SupportedUserNotificationTypes.failedShare:
			case SupportedUserNotificationTypes.cappedShare:
			case SupportedUserNotificationTypes.socialChannelRenewal:
			default:
				break
		}

		if (notificationSender) {
			return (
				<UserPhoto
					displayName={notificationSender.displayName}
					size={UserPhotoSize.small.name}
					id={notificationSender.userId}
					photos={notificationSender.profilePictureImages}
				/>
			)
		} else if (notificationInfo.awardAvailable) {
			return <BadgeIcon style={styles.logo} />
		} else if (notificationInfo.awardEarned) {
			return (
				<BadgeIcon
					color={notificationInfo.awardEarned.color}
					iconType={notificationInfo.awardEarned.icon}
					imageUrl={notificationInfo.awardEarned.imageUrl}
					title={notificationInfo.awardEarned.title}
					style={styles.logo}
				/>
			)
		} else {
			return (
				<CommunityLogo
					squareLogoUrl={squareLogoUrl}
					logoWidth={UserPhotoSize.small.width}
					style={styles.logo}
					name={communityName}
				/>
			)
		}
	}

	showPriority() {
		const { notificationInfo } = this.props
		return notificationInfo.notificationType === SupportedUserNotificationTypes.articleBroadcast
	}

	getPriorityIconInfo(priorityIconStyle) {
		const { notificationInfo } = this.props

		switch (notificationInfo.priority) {
			case 'Urgent':
				return {
					name: BroadcastIcon.priorityUrgent,
					style: priorityIconStyle.urgent,
				}
			case 'High':
				return {
					name: BroadcastIcon.priorityHigh,
					style: priorityIconStyle.high,
				}
			case 'Low':
				return {
					name: BroadcastIcon.priorityLow,
					style: priorityIconStyle.low,
				}
			default:
				return null
		}
	}

	getTitle() {
		const { notificationInfo } = this.props
		let firstUser,
			secondUser,
			remainder,
			points = 0

		const broadcastDisplayName = safeGetNestedProp(
			notificationInfo,
			'broadcast.senderInfo.displayName',
			lang.anonymousUser
		)

		const commentLikeDisplayName = x => {
			return safeGetNestedProp(
				notificationInfo,
				`likeOnComment.likes[${x}].displayName`,
				lang.anonymousUser
			)
		}

		const commentReplyDisplayName = x => {
			return safeGetNestedProp(
				notificationInfo,
				`replyToComment.replies[${x}].creatorInfo.displayName`,
				lang.anonymousUser
			)
		}

		const userMentionDisplayName = safeGetNestedProp(
			notificationInfo,
			'mention.originalComment.creatorInfo.displayName',
			lang.anonymousUser
		)

		switch (notificationInfo.notificationType) {
			case SupportedUserNotificationTypes.articleBroadcast:
				if (notificationInfo.broadcast.survey) {
					// Survey
					const userSent = notificationInfo.broadcast.senderInfo.userId ? true : false
					return userSent
						? lang.articleBroadcasts.userSentSurvey(broadcastDisplayName)
						: lang.articleBroadcasts.newSurvey
				} else if (notificationInfo.broadcast.newsletter) {
					// Newsletter
					const userSent = notificationInfo.broadcast.senderInfo.userId ? true : false
					return userSent
						? lang.articleBroadcasts.userSentNewsletter(broadcastDisplayName)
						: lang.articleBroadcasts.newNewsletter
				} else {
					const urgentBroadcast = notificationInfo.priority === broadcastPriorities.urgent
					const userSent = notificationInfo.broadcast.senderInfo.userId ? true : false
					if (urgentBroadcast) {
						return `${lang.articleBroadcasts.urgent} ${
							userSent
								? lang.articleBroadcasts.userSentBroadcast(broadcastDisplayName)
								: lang.articleBroadcasts.newBroadcast
						}`
					} else {
						return userSent
							? lang.articleBroadcasts.userSentBroadcast(broadcastDisplayName)
							: lang.articleBroadcasts.newBroadcast
					}
				}
			case SupportedUserNotificationTypes.articleCommentLike:
				if (notificationInfo.likeOnComment && notificationInfo.likeOnComment.totalPointValue)
					points = notificationInfo.likeOnComment.totalPointValue
				firstUser =
					notificationInfo.likeOnComment &&
					notificationInfo.likeOnComment.likes &&
					commentLikeDisplayName(0)

				secondUser =
					notificationInfo.likeOnComment &&
					notificationInfo.likeOnComment.likes &&
					notificationInfo.likeOnComment.likes.length > 1 &&
					commentLikeDisplayName(1)

				remainder = notificationInfo.likeOnComment.likes.length - 2

				if (points) {
					if (notificationInfo.likeOnComment.likes.length === 1) {
						return lang.articleCommentLike.singleLikeWithPoints(firstUser, points)
					} else if (notificationInfo.likeOnComment.likes.length === 2) {
						return lang.articleCommentLike.doubleLikeWithPoints(firstUser, secondUser, points)
					} else {
						return lang.articleCommentLike.manyLikeWithPoints(
							firstUser,
							secondUser,
							remainder,
							points
						)
					}
				} else {
					if (notificationInfo.likeOnComment.likes.length === 1) {
						return lang.articleCommentLike.singleLike(firstUser)
					} else if (notificationInfo.likeOnComment.likes.length === 2) {
						return lang.articleCommentLike.doubleLike(firstUser, secondUser)
					} else {
						return lang.articleCommentLike.manyLike(firstUser, secondUser, remainder)
					}
				}
			case SupportedUserNotificationTypes.articleCommentReply:
				firstUser =
					notificationInfo.replyToComment &&
					notificationInfo.replyToComment.replies &&
					commentReplyDisplayName(0)

				secondUser =
					notificationInfo.replyToComment &&
					notificationInfo.replyToComment.replies &&
					notificationInfo.replyToComment.replies.length > 1 &&
					commentReplyDisplayName(1)

				remainder = notificationInfo.replyToComment.replies.length - 2

				if (notificationInfo.replyToComment.replies.length === 1) {
					return lang.articleCommentReply.singleReply(firstUser)
				} else if (notificationInfo.replyToComment.replies.length === 2) {
					return lang.articleCommentReply.doubleReply(firstUser, secondUser)
				} else {
					return lang.articleCommentReply.manyReply(firstUser, secondUser, remainder)
				}
			case SupportedUserNotificationTypes.postApproval:
				return lang.postApproval.title
			case SupportedUserNotificationTypes.postAutoNotification:
				return lang.postAutoNotification.title
			case SupportedUserNotificationTypes.userMention:
				return lang.userMention.title(userMentionDisplayName)
			case SupportedUserNotificationTypes.birthday:
			case SupportedUserNotificationTypes.workAnniversary:
				return lang.milestone.title
			case SupportedUserNotificationTypes.awardAvailable:
				return lang.awards.availableTitle
			case SupportedUserNotificationTypes.awardEarned:
				return lang.awards.awardedTitle
			case SupportedUserNotificationTypes.workflow:
				return lang.workflow.title
			case SupportedUserNotificationTypes.failedShare:
				return lang.failedShare.title
			case SupportedUserNotificationTypes.cappedShare:
				return lang.cappedShare.title
			case SupportedUserNotificationTypes.socialChannelRenewal:
				return lang.socialChannelRenewal.title
			default:
				return ''
		}
	}

	getHeadline() {
		const { notificationInfo } = this.props
		switch (notificationInfo.notificationType) {
			case SupportedUserNotificationTypes.articleBroadcast:
			case SupportedUserNotificationTypes.birthday:
			case SupportedUserNotificationTypes.workAnniversary:
				return (
					<RestrictedText
						text={notificationInfo.broadcast.headline}
						limit={Restrictions.BroadcastHeadline}
					>
						{text => text}
					</RestrictedText>
				)
			case SupportedUserNotificationTypes.postAutoNotification:
				return (
					<RestrictedText
						text={notificationInfo.broadcast.headline}
						limit={Restrictions.BroadcastHeadline}
					>
						{text => text}
					</RestrictedText>
				)
			case SupportedUserNotificationTypes.articleCommentLike:
				return notificationInfo.likeOnComment.originalComment.commentText
			case SupportedUserNotificationTypes.articleCommentReply:
				return notificationInfo.replyToComment.replies[0].commentText
			case SupportedUserNotificationTypes.postApproval:
				return notificationInfo.postApproved.title
			case SupportedUserNotificationTypes.userMention:
				return notificationInfo.mention.originalComment.commentText
			case SupportedUserNotificationTypes.awardEarned: {
				const awardTitle = notificationInfo.awardEarned.title
					? notificationInfo.awardEarned.title
					: lang.awards.awardTitleFallback
				return lang.awards.headlineEarned(awardTitle)
			}
			case SupportedUserNotificationTypes.awardAvailable:
				return lang.awards.headlineAvailable
			case SupportedUserNotificationTypes.workflow:
				return notificationInfo.workflowNotificationDisplay.heading
			case SupportedUserNotificationTypes.failedShare:
				return notificationInfo.failedShare.postName === '' ||
					notificationInfo.failedShare.channelName === ''
					? notificationInfo.failedShare.description
					: lang.failedShare.description(
							notificationInfo.failedShare.postName,
							notificationInfo.failedShare.channelName
					  )
			case SupportedUserNotificationTypes.cappedShare:
				return notificationInfo.cappedShare.postName === '' ||
					notificationInfo.cappedShare.channelName === ''
					? notificationInfo.cappedShare.description
					: lang.cappedShare.description(
							notificationInfo.cappedShare.postName,
							notificationInfo.cappedShare.channelName
					  )
			case SupportedUserNotificationTypes.socialChannelRenewal:				
				return lang.socialChannelRenewal.description(
					notificationInfo.socialChannelRenewal.channelName
			  )
			default:
				return ''
		}
	}

	getFormattedDate() {
		const { notificationInfo } = this.props
		return <DateTime format={DateFormat.FromNow} date={notificationInfo.groupedCreatedDate} />
	}

	goToSurvey = e => {
		const { notificationInfo, history, markAsRead, viewBroadcast } = this.props
		const surveyId = safeGetNestedProp(notificationInfo, 'broadcast.survey.surveyId')
		const isSurveyCompleted = safeGetNestedProp(
			notificationInfo,
			'broadcast.survey.userCompleteDate'
		)
		const isEditable = safeGetNestedProp(
			notificationInfo,
			'broadcast.survey.settings.isEditableAfterComplete'
		)
		const broadcastId = safeGetNestedProp(notificationInfo, 'broadcast.broadcastId')

		e.preventDefault()
		e.stopPropagation()

		// Mark notification as read if not already
		if (!notificationInfo.groupedReadDate) markAsRead()

		// Record broadcst view
		if (broadcastId) viewBroadcast(broadcastId)

		if (surveyId) {
			if (isSurveyCompleted && isEditable) {
				history.push(`${Routes.Survey.generateLinkPath(surveyId)}?isEditing=true`)
			} else {
				history.push(Routes.Survey.generateLinkPath(surveyId))
			}
		}
	}

	goToSocialChannels = e => {
        const { history } = this.props
        e.preventDefault()
        e.stopPropagation()
        history.push(Routes.Settings.Channels.routerPath)       
    }

	getNavigationText() {
		const { broadcastMap, notificationInfo, primaryColor } = this.props
		const navLinkStyle = {
			...styles.navLink,
			color: primaryColor,
		}

		switch (notificationInfo.notificationType) {
			case SupportedUserNotificationTypes.articleBroadcast:
				if (notificationInfo.broadcast.survey) {
					const hasSurveyBeenCompleted = notificationInfo.broadcast.survey.userCompleteDate
						? true
						: false
					const hasSurveyFinished =
						safeGetNestedProp(notificationInfo, 'broadcast.survey.status') === SurveyStatus.finished
					const isEditableAfterComplete = safeGetNestedProp(
						notificationInfo,
						'broadcast.survey.settings.isEditableAfterComplete'
					)

					if (hasSurveyFinished) {
						return (
							<span style={{ color: color.secondaryGrey }}>
								{lang.articleBroadcasts.surveyEnded}
							</span>
						)
					} else if (hasSurveyBeenCompleted) {
						return isEditableAfterComplete ? (
							<span onClick={this.goToSurvey} style={navLinkStyle}>
								{lang.articleBroadcasts.editResponses}
							</span>
						) : (
							<span style={{ color: color.secondaryGrey }}>
								{lang.articleBroadcasts.surveyCompleted}
							</span>
						)
					} else {
						return (
							<span onClick={this.goToSurvey} style={navLinkStyle}>
								{lang.articleBroadcasts.startSurvey}
							</span>
						)
					}
				} else if (notificationInfo.broadcast.newsletter) {
					return lang.articleBroadcasts.viewNewsletter
				} else {
					const acknowledgementRequired = notificationInfo.readConfirmationRequested ? true : false

					if (acknowledgementRequired) {
						const broadcastId = safeGetNestedProp(notificationInfo, 'broadcast.broadcastId')
						const broadcastAcknowledged =
							broadcastMap[broadcastId] && broadcastMap[broadcastId].readConfirmationDate
						const acknowledged =
							notificationInfo.readConfirmationDate || broadcastAcknowledged ? true : false // Check if broadcast has been acknowledged

						return acknowledged ? (
							<span style={{ color: color.secondaryGrey }}>
								{lang.articleBroadcasts.acknowledged}
							</span>
						) : (
							lang.articleBroadcasts.acknowledge
						)
					}
					return ''
				}
			case SupportedUserNotificationTypes.awardEarned:
			case SupportedUserNotificationTypes.awardAvailable:
				return lang.awards.viewBadge
			case SupportedUserNotificationTypes.socialChannelRenewal:               
                return (
                    <span onClick={this.goToSocialChannels} style={navLinkStyle}>
                        {lang.socialChannelRenewal.manageSocialChannel}
                    </span>
                )
			default:
				return ''
		}
	}

	getDetailLink() {
		// TODO : Direct to specific parts of the page as per the spec once we decide how to do that
		const { notificationInfo } = this.props
		switch (notificationInfo.notificationType) {
			case SupportedUserNotificationTypes.articleBroadcast:
			case SupportedUserNotificationTypes.birthday:
			case SupportedUserNotificationTypes.workAnniversary: {
				const broadcast = notificationInfo.broadcast
				const newsletter = broadcast.newsletter
				// TODO: Update generateLinkPath to take broadcastId!!!
				return newsletter
					? Routes.Newsletters.generateLinkPath(newsletter.newsletterId, broadcast.broadcastId)
					: Routes.Broadcasts.generateLinkPath(notificationInfo.broadcast.broadcastId)
			}
			case SupportedUserNotificationTypes.articleCommentLike: {
				const commentId = safeGetNestedProp(
					notificationInfo,
					'likeOnComment.originalComment.commentId'
				)
				const parentCommentId = safeGetNestedProp(
					notificationInfo,
					'likeOnComment.originalComment.parentCommentId',
					0
				)
				return Routes.Post.Details.generateDialogPath(
					false,
					notificationInfo.likeOnComment.originalComment.postId,
					true,
					commentId,
					parentCommentId
				)
			}
			case SupportedUserNotificationTypes.articleCommentReply: {
				const commentReplies = safeGetNestedProp(notificationInfo, 'replyToComment.replies')
				const commentId = commentReplies[0].commentId
				return Routes.Post.Details.generateDialogPath(
					false,
					notificationInfo.replyToComment.originalComment.postId,
					true,
					commentId
				)
			}
			case SupportedUserNotificationTypes.postApproval: {
				return Routes.Post.Details.generateDialogPath(false, notificationInfo.postApproved.postId)
			}
			case SupportedUserNotificationTypes.postAutoNotification: {
				const broadcast = notificationInfo.broadcast
				const postId = broadcast && broadcast.post && broadcast.post.postId
				const activity = {
					activityReason: Activity.Reason.postAutoNotification,
					activityDetail: broadcast.broadcastId,
				}
				return Routes.Post.Details.generateDialogPath(false, postId, false, null, null, activity)
			}
			case SupportedUserNotificationTypes.userMention: {
				const commentId = safeGetNestedProp(notificationInfo, 'mention.originalComment.commentId')
				return Routes.Post.Details.generateDialogPath(
					false,
					notificationInfo.mention.post.postId,
					true,
					commentId
				)
			}
			case SupportedUserNotificationTypes.awardEarned:
			case SupportedUserNotificationTypes.awardAvailable:
				return Routes.User.Profile.dialogPath
			case SupportedUserNotificationTypes.failedShare:
				return Routes.Settings.MyShares.routerPath
			case SupportedUserNotificationTypes.socialChannelRenewal:
				return Routes.Settings.Channels.routerPath
			default:
				return ''
		}
	}

	// Is the notification a PostNotification and DisplayMode is OutOfApp/OpenExternally?
	isExternalPostNotification = notificationInfo => {
		if (notificationInfo.notificationType === SupportedUserNotificationTypes.postAutoNotification) {
			const displayMode = safeGetNestedProp(notificationInfo, 'broadcast.post.displayMode', false)
			return displayMode && displayMode === DisplayMode.OpenExternally
		}
		return false
	}

	// TODO : Analytics?
	handleNotificationClick = (e, detailLink) => {
		const { notificationInfo, markAsRead, history } = this.props

		// Note: PostLink click event will override this click handler for OutOfApp posts coming from a PostNotification
		e.preventDefault()

		// Mark notification as read if not already
		if (!notificationInfo.groupedReadDate) {
			markAsRead()
		}

		if (detailLink) {
			// Navigate to the next page
			history.push(detailLink)
		}
	}

	handleNotificationKeyDown = (e, detailLink) => {
		if (e.key === KeyNames.enter || e.key === KeyNames.space) {
			this.handleNotificationClick(e, detailLink)
		}
	}

	render() {
		const { notificationInfo, primaryColor, layout, markAsRead } = this.props

		// Make sure notification type is one we currently support
		if (SupportedUserNotificationTypesList.indexOf(notificationInfo.notificationType) === -1) {
			return null
		}
		const dynamicStyles = {
			navLink: {
				...styles.navLink,
				color: primaryColor,
			},
			base: {
				...styles.base,
				...styles.base[layout],
				backgroundColor: notificationInfo.groupedReadDate ? color.white : color.bgPage,
			},
			row: {
				...styles.row,
				...styles.row[layout],
			},
		}
		const priorityIconInfo = this.showPriority() && this.getPriorityIconInfo(styles.priorityIcon)
		const detailLink = this.getDetailLink()
		const isExternalPostNotification = this.isExternalPostNotification(notificationInfo)
		const PossiblePostLink = isExternalPostNotification ? PostLink : 'div'
		const possiblePostLinkProps = isExternalPostNotification
			? {
					postId: notificationInfo.broadcast.post.postId,
					urlSlug: null,
					displayMode: notificationInfo.broadcast.post.displayMode,
					showPageDialog: false,
					activity: {
						activityReason: Activity.Reason.postAutoNotification,
						activityDetail: notificationInfo.broadcast.broadcastId,
					},
					markBroadcastAsRead: !notificationInfo.groupedReadDate ? markAsRead : null,
					showDiscussions: true, // DS-7436: Even Out Of App posts need to be displayed in our app now.
			  }
			: {}

		return (
			<PossiblePostLink
				onClick={e => {
					this.handleNotificationClick(e, detailLink)
				}}
				onKeyDown={e => {
					this.handleNotificationKeyDown(e, detailLink)
				}}
				tabIndex={0}
				role="link"
				className="accessible-focus"
				style={dynamicStyles.base}
				{...possiblePostLinkProps}
			>
				{/* Photo (Column) */}
				<div style={{ marginTop: 4 }}>{this.getPhoto()}</div>

				{/* Details (Column) */}
				<div style={styles.information}>
					{/* Title and Action (Row) */}
					<div style={dynamicStyles.row}>
						{/* Title */}
						<div style={styles.title}>
							{priorityIconInfo && (
								<Icon name={priorityIconInfo.name} style={priorityIconInfo.style} />
							)}
							{this.getTitle()}
						</div>

						{/* Action */}
						<div style={dynamicStyles.navLink}>{this.getNavigationText()}</div>
					</div>

					{/* Headline (Row) */}
					<div style={dynamicStyles.row}>
						<div style={styles.headline}>{this.getHeadline()}</div>
					</div>

					{/* Date and Pin (Row) */}
					<div style={dynamicStyles.row}>
						{/* Date */}
						<div style={styles.date}>{this.getFormattedDate()}</div>

						{/* Pin */}
						{notificationInfo.isPinned && (
							<Icon style={styles.pin} name={Name.thumbtack} transform={{ rotate: 45 }} />
						)}
					</div>
				</div>
			</PossiblePostLink>
		)
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(UserNotification)
)
