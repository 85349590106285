import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import Dialog from 'components/Dialog/Dialog'
import Button from 'components/Button/Button'
import SocialAccounts from 'scenes/Settings/ChannelSettings/SocialAccounts'

// Lang
import buttonsLang from 'helpers/lang/buttons.lang'
import lang from 'scenes/ShareDialog/shareDialog.lang'

// Styles
import { margin, flexContainer } from 'styles/variables'
const styles = {
	nextButton: {
		...flexContainer('row', 'flex-end'),
		marginBottom: margin.wide,
	},
}

class EditChannelsDialog extends Component {
	static propTypes = {
		subtitle: PropTypes.string,
		userChannels: PropTypes.array,
		selectedChannelList: PropTypes.array,
		replaceUserChannelIds: PropTypes.func,
		footer: PropTypes.func,
		handleClose: PropTypes.func,
		reaction: PropTypes.string,
	}

	state = {
		userChannelIds: null,
	}

	handleAddChannel = response => {
		const { userChannels, selectedChannelList } = this.props

		let userChannelIds = [...selectedChannelList]

		// If new channel is a Twitter channel, remove any other Twitter channels already in the list
		if (response.provider === 'Twitter') {
			userChannelIds = userChannelIds.filter(userChannelId => {
				const userChannel = userChannels.find(channel => channel.userChannelId === userChannelId)
				if (userChannel) {
					return userChannel.provider !== 'Twitter'
				} else {
					// Don't filter ID if channel is not found in userChannels list. Newly-added
					// channels might not be in userChannels yet
					return true
				}
			})
		}

		// Add ID of new channel to the end of the list
		userChannelIds.push(response.userChannelId)

		// Update local state. We'll update the global state when the user clicks the 'Next' button
		this.setState({ userChannelIds })
	}

	handleRemoveChannel = disconnectedUserChannelId => {
		const { selectedChannelList } = this.props

		// Start with existing list
		let userChannelIds = [...selectedChannelList]

		// Remove disconnected channel, if present
		const pos = userChannelIds.indexOf(disconnectedUserChannelId)
		if (pos >= 0) {
			userChannelIds.splice(pos, 1)
		}

		// Update local state. We'll update the global state when the user clicks the 'Next' button
		this.setState({ userChannelIds })
	}

	handleSkipAddChannels = e => {
		const { handleClose, replaceUserChannelIds } = this.props
		const { userChannelIds } = this.state

		// Update parent state if there are changes made in this flow
		if (userChannelIds) replaceUserChannelIds(userChannelIds)

		// Close this dialog, return to the Share Post flow
		handleClose('showEditChannelsDialog', 'showSharePostDialog')
	}

	render() {
		const { subtitle, handleClose, reaction, footer } = this.props
		return (
			<Dialog
				isOpen={true}
				title={lang.addChannelTitle}
				subtitle={subtitle}
				onClose={() => handleClose('showEditChannelsDialog')}
				shouldCloseOnOverlayClick={false}
				shareV8OtherActions={footer()}
				footerStyle={{ flexDirection: 'column' }}
			>
				<SocialAccounts
					onlyShareChannels={reaction ? false : true}
					onAddChannel={this.handleAddChannel}
					onlyProviders={true}
					onRemoveChannel={this.handleRemoveChannel}
					fromShareDialog={true}
				/>
				<div style={styles.nextButton}>
					<Button onClick={this.handleSkipAddChannels}>{buttonsLang.next}</Button>
				</div>
			</Dialog>
		)
	}
}

export default EditChannelsDialog
