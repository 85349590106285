import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import SuggestedShareTextItem from './SuggestedShareTextItem'

// Styles
import { flexContainer, border } from 'styles/variables'
const styles = {
	container: {
		...flexContainer('column', 'flex-start', 'center'),
		width: '100%',
		border: border.basic,
		borderTop: 'none',
	},
}

class SuggestedShareText extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		name: PropTypes.string,
		suggestedTextValue: PropTypes.array,
		setSuccessMessage: PropTypes.func,
		primaryColor: PropTypes.string,
		clearErrorMessage: PropTypes.func,
		disabled: PropTypes.bool,
		copyCommandSupported: PropTypes.bool,
		setErrorMessage: PropTypes.func,
		handleReplaceText: PropTypes.func,
		toggleSuggestedShareText: PropTypes.func,
	}

	render() {
		const {
			isOpen,
			name,
			suggestedTextValue,
			primaryColor,
			disabled,
			copyCommandSupported,
			clearErrorMessage,
			setErrorMessage,
			setSuccessMessage,
			toggleSuggestedShareText,
			handleReplaceText,
		} = this.props
		if (!isOpen || !suggestedTextValue || suggestedTextValue.length === 0) return null

		return (
			<div style={styles.container}>
				{suggestedTextValue.map((suggestedText, i) => (
					<SuggestedShareTextItem
						key={`${name}-${i}`}
						name={name}
						primaryColor={primaryColor}
						toggleSuggestedShareText={toggleSuggestedShareText}
						setErrorMessage={setErrorMessage}
						handleReplaceText={handleReplaceText}
						clearErrorMessage={clearErrorMessage}
						setSuccessMessage={setSuccessMessage}
						disabled={disabled}
						copyCommandSupported={copyCommandSupported}
						item={suggestedText}
					/>
				))}
			</div>
		)
	}
}

export default SuggestedShareText
