import React from 'react'
import PropTypes from 'prop-types'

// Lang
import lang from './notFound.lang'

// Routing
import Routes from 'helpers/routes'

// Components
import Layout, { Panel } from 'pages/Layouts/Layout'
import Button from 'components/Button/Button'

// Style
const styles = {
	panel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		height: '100%',
	},
	text: {
		textAlign: 'center',
	},
}

// Page
class NotFound extends React.Component {
	static propTypes = {
		showHome: PropTypes.bool,
		text: PropTypes.string,
	}

	render() {
		const { text: textOverride, showHome = true } = this.props

		return (
			<Layout>
				<Panel>
					<div style={styles.panel}>
						<h1 style={styles.text}>{textOverride ? textOverride : lang.notFound}</h1>
						{showHome && <Button href={Routes.Home.linkPath}>{lang.goHome}</Button>}
					</div>
				</Panel>
			</Layout>
		)
	}
}

export default NotFound
