import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import { color, border, width } from 'styles/variables'

// Styles
const styles = {
	base: {
		color: color.secondaryRed,
		backgroundColor: color.backgroundRed,
		borderTop: border.error,
		borderBottom: border.error,
		marginTop: width.spacing.basic,
		marginBottom: width.spacing.basic,
	},
	message: {
		paddingTop: width.spacing.narrow,
		paddingBottom: width.spacing.narrow,
		paddingLeft: width.spacing.wide,
		paddingRight: width.spacing.wide,
	},
	list: {
		paddingLeft: width.spacing.wide,
		paddingRight: width.spacing.wide,
		listStylePosition: 'inside',
	},
}

class FormErrors extends Component {
	static propTypes = {
		errors: PropTypes.object.isRequired,
		style: PropTypes.object,
	}

	static defaultProps = {
		style: {},
	}

	render() {
		const { errors, style } = this.props
		const errorList = []

		// Styles
		const dynamicStyles = {
			base: {
				...styles.base,
				...style,
			},
		}

		// Collect errors to show, if any
		for (let field in errors) {
			if (errors[field]) errorList.push({ field, message: errors[field] })
		}

		// No Errors
		if (errorList.length === 0) return null

		return (
			<div style={dynamicStyles.base}>
				{errorList.length === 1 ? (
					/* Single Error */
					<div style={styles.message}>{errorList[0].message}</div>
				) : (
					/* Multiple Errors */
					<ul style={styles.list}>
						{errorList.map(error => <li key={error.field}>{error.message}</li>)}
					</ul>
				)}
			</div>
		)
	}
}

export default FormErrors
