import { vsApi, requests } from 'helpers/apiHelpers'

// Action Types
const FETCH_BOOKMARKS_REQUEST = 'FETCH_BOOKMARKS_REQUEST'
const FETCH_BOOKMARKS_SUCCESS = 'FETCH_BOOKMARKS_SUCCESS'
const FETCH_BOOKMARKS_FAILURE = 'FETCH_BOOKMARKS_FAILURE'

const CLEAR_BOOKMARKS = 'CLEAR_BOOKMARKS'

// Action Creators
export const asyncGetBookmarks = (userId, abortable) => {
	return vsApi({
		endpoint: requests.getBookmarks.getEndpoint(userId),
		actions: {
			request: FETCH_BOOKMARKS_REQUEST,
			success: FETCH_BOOKMARKS_SUCCESS,
			failure: FETCH_BOOKMARKS_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false, abortable },
	})
}

export const asyncDeleteBookmark = (userId, bookmarkId, abortable) => {
	return vsApi({
		endpoint: requests.deleteBookmark.getEndpoint(userId, bookmarkId),
		requestOptions: requests.deleteBookmark.requestOptions,
		options: { abortable },
	})
}

export const clearBookmarks = () => {
	return {
		type: CLEAR_BOOKMARKS,
	}
}

// Initial State
const initialState = {
	bookmarks: [],
	isLoading: false,
}

// Reducer
export default function bookmarksReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_BOOKMARKS_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case FETCH_BOOKMARKS_SUCCESS: {
			const { bookmarks } = action.response
			return {
				...state,
				isLoading: false,
				bookmarks,
			}
		}
		case FETCH_BOOKMARKS_FAILURE: {
			return {
				...state,
				...initialState,
			}
		}
		case CLEAR_BOOKMARKS: {
			return {
				...state,
				bookmarks: [],
			}
		}
		default:
			return state
	}
}
