import React from 'react'
import PropTypes from 'prop-types'

// Components
import GenericUserPhoto from './GenericUserPhoto'
import Icon, { Name } from 'components/Icons/FontAwesome'
import ImagePlaceholder from 'components/Placeholders/ImagePlaceholder'

// Shared
import UserPhotoSize from './userPhotoSize'

// Styles
import styles from './UserPhoto.styles'

export const UserImageDimension = {
	Original: 'Original',
	Square160: 'Square160',
	Square80: 'Square80',
	Square40: 'Square40',
	Square20: 'Square20',
}

const GetUserPhotoSize = size => {
	switch (size) {
		case UserPhotoSize.tiny.name:
			return UserImageDimension.Square40
		case UserPhotoSize.small.name:
			return UserImageDimension.Square40
		case UserPhotoSize.medium.name:
			return UserImageDimension.Square80
		case UserPhotoSize.large.name:
			return UserImageDimension.Square160
		default:
			return UserImageDimension.Original
	}
}

// Functional component
class UserPhoto extends React.Component {
	render() {
		const { photos, tooltip, size, id, count, disabled, style } = this.props
		// Styles
		const dynamicStyles = {
			base: {
				...styles.base,
				...(size && styles.size[size]),
				...style,
			},
			img: {
				...styles.img,
				...(size && styles.img.size[size]),
			},
			disabledMaskStyle: {
				...styles.disabledMask,
				...(size && styles.img.size[size]),
			},
		}

		// Generic photo component with container
		const genericPhoto = (
			<div style={dynamicStyles.base}>
				<GenericUserPhoto displayName={tooltip} size={size} id={id} count={count} />
			</div>
		)

		// No image, use the generic
		if (!photos) return genericPhoto

		// Get the appropriate image size
		const image = photos[GetUserPhotoSize(size)]

		// Crap, looks like we need to fallback to the generic
		if (typeof image === 'undefined' || image === null) return genericPhoto

		// Render
		return (
			<div data-component="UserPhoto" style={dynamicStyles.base}>
				<ImagePlaceholder
					image={image}
					title={tooltip}
					style={dynamicStyles.img}
					renderError={
						<GenericUserPhoto
							displayName={tooltip}
							count={count}
							size={size}
							id={id}
							style={style}
						/>
					}
				/>

				{disabled && (
					// Mask draws on top of img to give disabled effect
					<div style={dynamicStyles.disabledMaskStyle} />
				)}

				{!!count && (
					// TODO - Refactor this crap out of the UserPhoto component!!!
					<Icon
						name={Name.circle}
						text={`+${count}`}
						style={styles.count}
						transform={{ size: 8 }}
						bordered
					/>
				)}
			</div>
		)
	}
}

UserPhoto.propTypes = {
	photos: PropTypes.object,
	tooltip: PropTypes.string,
	size: PropTypes.oneOf(Object.values(UserPhotoSize).map(s => s.name)),
	id: PropTypes.number,
	count: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	disabled: PropTypes.bool,
	style: PropTypes.object,
}

UserPhoto.defaultProps = {
	size: UserPhotoSize.medium.name,
	id: 1,
}

export default UserPhoto
