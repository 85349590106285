import { vsApi, requests } from 'helpers/apiHelpers'
import ContentData from 'scenes/Content/helpers/content.data'

// --- Actions ---

// In Redux, actions are plain JavaScript objects that callers create to manipulate
// the application state. When an action is dispatched, Redux calls the reducers
// to arrive at the new application state kept in the Redux store. If this results
// in any significant changes to the store, Redux updates the application state and
// React responds by rerendering (only) the relevant parts of the page.

// Action types - Not exposed. Callers should call Action Creators, below

const FETCH_CUSTOMPAGES_REQUEST = 'FETCH_CUSTOMPAGES_REQUEST'
const FETCH_CUSTOMPAGES_SUCCESS = 'FETCH_CUSTOMPAGES_SUCCESS'
const FETCH_CUSTOMPAGES_FAILURE = 'FETCH_CUSTOMPAGES_FAILURE'

const FETCH_DEDICATEDPAGES_SUCCESS = 'FETCH_DEDICATEDPAGES_SUCCESS'

const CLEAR_CUSTOMPAGES = 'CLEAR_CUSTOMPAGES'
// Action Creators

// Async Action Creators
export const asyncGetCustomPages = (pageId, abortable) => {
	return vsApi({
		endpoint: requests.getCustomPages.getEndpoint(pageId),
		actions: {
			request: FETCH_CUSTOMPAGES_REQUEST,
			success: FETCH_CUSTOMPAGES_SUCCESS,
			failure: FETCH_CUSTOMPAGES_FAILURE,
		},
		options: { abortable, captureUnhandledPromiseRejection: false },
		context: { pageId },
	})
}

export const asyncGetScriptCard = (scriptId, data, token, abortable) => {
	return vsApi({
		endpoint: requests.getScriptCard.getEndpoint(scriptId),
		requestOptions: requests.getScriptCard.getRequestOptions(data, token),
		options: { abortable },
	})
}

export const asyncGetDedicatedPages = () => {
	return vsApi({
		endpoint: requests.getDedicatedPages.endpoint,
		actions: {
			success: FETCH_DEDICATEDPAGES_SUCCESS,
		},
	})
}

export const clearCUSTOMPAGES = () => {
	return {
		type: CLEAR_CUSTOMPAGES,
	}
}

// In Redux, asynchronous action creators are thunks (functions that return a function)
// that dispatch synchronous actions after an asynchronous action completes.
//
// General form:
//
// export function asyncXyz() {
//		return (dispatch, getState) => {
//			doSomethingAsync()
//				.then(dispatch(actionCreator()))		 // Typical
//				.catch(dispatch(anotherActionCreator())) // Optional
//		}
// }

// State will be mapped to the Redux store by combineReducers
const initialState = {
	isLoading: false,
	customPagesById: {},
	dedicatedPages: {},
}

// Reducer
//
// NOTE: A reducer is a 'pure' function. Given the same arguments, it should calculate the next state
//       and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.
//
// Things you should never do inside a reducer:
//   o Mutate its arguments
//   o Perform side effects like API calls and routing transitions
//   o Call non-pure functions, e.g. Date.now() or Math.random()
//
export default function customPagesReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_CUSTOMPAGES_REQUEST: {
			const isLoading = true // Result might actually be a different page, if a translation is returned

			return {
				...state,
				isLoading,
			}
		}
		case FETCH_CUSTOMPAGES_SUCCESS: {
			const page = ContentData(action.response)

			return {
				...state,
				customPagesById: {
					...state.customPagesById,
					[page.id]: page,
				},
				isLoading: false,
			}
		}
		case FETCH_CUSTOMPAGES_FAILURE: {
			return {
				...state,
				isLoading: false,
			}
		}
		case FETCH_DEDICATEDPAGES_SUCCESS: {
			const dedicatedPages = action.response.roles

			return {
				...state,
				dedicatedPages,
			}
		}
		default:
			return state
	}
}
