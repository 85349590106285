import React from 'react'
import PropTypes from 'prop-types'
import withTheme, { Theme, ThemeValuePropType } from 'wrappers/ThemeWrapper'

// Components
import { Link } from 'react-router-dom'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Helpers
import tinycolor from 'tinycolor2'

//#region Style
import {
	color,
	timing,
	fontSize,
	fontWeight,
	fontFamily,
	lineHeight,
	width,
} from 'styles/variables'

import withRouter from 'react-router-dom/withRouter'

export const ButtonLevel = {
	primary: 'primary',
	secondary: 'secondary',
	danger: 'danger',
	hover: 'hover',
	transparent: 'transparent',
}

export const ButtonSize = {
	// tiny: 'tiny',
	// small: 'small',
	medium: 'medium',
	// large: 'large',
	// huge: 'huge',
}

const getLevelStyles = (level, primaryColor) => {
	// let primaryHover = tinycolor(primaryColor).darken(10)

	switch (level) {
		case ButtonLevel.primary: {
			let textColor = color.white
			if (tinycolor.readability(primaryColor, textColor) < 1.5) {
				textColor = color.primaryBlack
			}

			return {
				backgroundColor: primaryColor,
				color: textColor,
				borderTopColor: primaryColor,
				borderRightColor: primaryColor,
				borderBottomColor: primaryColor,
				borderLeftColor: primaryColor,

				// ':hover': { backgroundColor: primaryHover },
				// ':focus': { backgroundColor: primaryHover },
				// ':active': { backgroundColor: primaryHover },
			}
		}

		case ButtonLevel.danger: {
			return {
				backgroundColor: color.secondaryRed,
				color: color.white,
				borderTopColor: color.secondaryRed,
				borderRightColor: color.secondaryRed,
				borderBottomColor: color.secondaryRed,
				borderLeftColor: color.secondaryRed,
				// ':hover': { backgroundColor: color.secondaryRed },
				// ':focus': { backgroundColor: color.secondaryRed },
				// ':active': { backgroundColor: color.secondaryRed },
			}
		}

		case ButtonLevel.hover: {
			let textColor = primaryColor
			if (tinycolor.readability(color.white, textColor) < 1.5) {
				textColor = color.primaryBlack
			}

			return {
				color: textColor,
				backgroundColor: 'transparent',
				borderColor: 'transparent',
				transition: 'none',
			}
		}

		case ButtonLevel.transparent: {
			let textColor = primaryColor

			// Assuming content background color for readability test
			if (tinycolor.readability(color.bgContent, textColor) < 1.5) {
				textColor = color.primaryBlack
				// primaryHover = tinycolor(color.primaryBlack).darken(10)
			}

			return {
				backgroundColor: 'transparent',
				color: textColor,
				borderTopColor: 'transparent',
				borderRightColor: 'transparent',
				borderBottomColor: 'transparent',
				borderLeftColor: 'transparent',
			}
		}

		case ButtonLevel.secondary:
		default: {
			let textColor = primaryColor
			if (tinycolor.readability(color.white, textColor) < 1.5) {
				textColor = color.primaryBlack
				// primaryHover = tinycolor(color.primaryBlack).darken(10)
			}

			return {
				backgroundColor: color.white,
				color: textColor,
				borderTopColor: textColor,
				borderRightColor: textColor,
				borderBottomColor: textColor,
				borderLeftColor: textColor,

				// ':hover': { color: textColor, borderColor: primaryHover },
				// ':focus': { color: textColor, borderColor: primaryHover },
				// ':active': { color: primaryHover, borderColor: primaryHover },
			}
		}
	}
}

const getHoverStyles = (level, isHovering) => {
	if (level !== ButtonLevel.hover) return {}

	return {
		backgroundColor: isHovering ? color.bgContent : 'transparent',
	}
}

const styles = {
	base: {
		appearance: 'none',
		backgroundColor: color.white,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: '1px',
		cursor: 'pointer',
		display: 'inline-block',
		fontFamily: fontFamily.base,
		fontSize: fontSize.small,
		fontWeight: fontWeight.semiBold,
		lineHeight: lineHeight.close,
		paddingTop: width.spacing.narrow,
		paddingBottom: width.spacing.narrow,
		paddingLeft: width.spacing.basic,
		paddingRight: width.spacing.basic,
		textDecoration: 'none !important',
		transition: `color ${timing.default} ease-in-out,
			border-color ${timing.default} ease-in-out,
			background-color ${timing.default} ease-in-out,
			opacity ${timing.default} ease-in-out`,
		whiteSpace: 'nowrap',
	},
	link: {
		// TODO - This kind of thing can be extracted to base styles
		textDecoration: 'none',
		':hover': { textDecoration: 'none' },
		':focus': { textDecoration: 'none' },
		':active': { textDecoration: 'none' },
	},

	fullWidth: {
		display: 'block',
		width: '100%',
	},

	disabled: {
		backgroundColor: color.white,
		borderTopColor: color.borderPrimary,
		borderRightColor: color.borderPrimary,
		borderBottomColor: color.borderPrimary,
		borderLeftColor: color.borderPrimary,
		color: color.secondaryGrey,
		cursor: 'not-allowed',
	},

	icon: {
		marginRight: 8,
		lineHeight: 1,
	},

	rightIcon: {
		marginLeft: 8,
		lineHeight: 1,
	},
}
//#endregion

class Button extends React.Component {
	state = {
		isHovering: false,
	}

	handleMouseEnter = () => this.setState({ isHovering: true })

	handleMouseLeave = () => this.setState({ isHovering: false })

	// Event Handlers
	onLinkClick = e => {
		const { onClick } = this.props

		if (onClick) {
			onClick(e)
		}
	}

	onButtonClick = e => {
		const { onClick, blurOnClick } = this.props

		if (onClick) {
			e.preventDefault()
			e.stopPropagation()

			onClick(e)
		}

		// Fix for Dialogs going back to focus of button that calls it
		if (blurOnClick) this.button.blur()
	}

	render() {
		const {
			primaryColor,
			level,
			/*size,*/ icon,
			rightIcon,
			href,
			children,
			fullWidth,
			disabled,
			style: styleOverride,
			onClick,
			externalLink,
			target,
		} = this.props
		const { isHovering } = this.state

		const style = {
			...styles.base,
			...styles.link,
			color: primaryColor,
			...(level && getLevelStyles(level, primaryColor)),
			...getHoverStyles(level, isHovering),
			...(fullWidth && styles.fullWidth),
			...(disabled && styles.disabled),
			...styleOverride,
		}

		return href ? (
			externalLink ? (
				<a href={href} style={style} onClick={this.onLinkClick} disabled={disabled} target={target}>
					{icon && <Icon name={icon} style={styles.icon} color={style.color} />}
					{children}
					{rightIcon && <Icon name={rightIcon} style={styles.rightIcon} color={style.color} />}
				</a>
			) : (
				<Link to={href} style={style} onClick={this.onLinkClick} disabled={disabled}>
					{icon && <Icon name={icon} style={styles.icon} color={style.color} />}
					{children}
					{rightIcon && <Icon name={rightIcon} style={styles.rightIcon} color={style.color} />}
				</Link>
			)
		) : (
			<button
				data-component="Button"
				style={style}
				onClick={this.onButtonClick}
				onMouseEnter={level === ButtonLevel.hover ? this.handleMouseEnter : null}
				onMouseLeave={level === ButtonLevel.hover ? this.handleMouseLeave : null}
				disabled={disabled}
				ref={elem => (this.button = elem)}
				type={onClick ? 'button' : 'submit'}
			>
				{icon && <Icon name={icon} style={styles.icon} color={style.color} />}
				{children}
				{rightIcon && <Icon name={rightIcon} style={styles.rightIcon} color={style.color} />}
			</button>
		)
	}
}

const injectedThemeValues = [Theme.PrimaryColor]

Button.propTypes = {
	/**
	 * Pass an href prop to make the button an `a` element instead of a `button`
	 */
	href: PropTypes.string,

	/**
	 * Button label
	 */
	children: PropTypes.node,

	/**
	 * Function to run when the button is clicked
	 */
	onClick: PropTypes.func,

	/**
	 * Relative importance of button on the page
	 */
	level: PropTypes.oneOf(Object.values(ButtonLevel)),

	/**
	 * Allow button to span available width
	 */
	fullWidth: PropTypes.bool,

	/**
	 * Optional Font Awesome icon shown before the button text.
	 * Use icon name only: home, not fa-home.
	 * Ex: icon="share-square" for a sharing icon
	 */
	icon: PropTypes.oneOf(Object.values(Name)),

	/**
	 * Optional Font Awesome icon shown after the button text.
	 * Use icon name only: home, not fa-home.
	 * Ex: rightIcon="chevron-right" for a sharing icon
	 */
	rightIcon: PropTypes.oneOf(Object.values(Name)),

	/**
	 * Special styles passed in props
	 */
	style: PropTypes.objectOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
	),

	/**
	 * Disable button
	 */
	disabled: PropTypes.bool,

	...ThemeValuePropType(injectedThemeValues),

	// from withRouter
	location: PropTypes.object,
}

Button.defaultProps = {
	level: ButtonLevel.primary,
	fullWidth: false,
	disabled: false,
	blurOnClick: false,
}

Button.styles = styles

export default withTheme(withRouter(Button), injectedThemeValues)
