/**
 * Standardized broadcast priorities returned from the API
 */

export default {
	low: 'Low',
	normal: 'Normal',
	high: 'High',
	urgent: 'Urgent',
}
