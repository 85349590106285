const lang = {
	notifications: 'Notifications',
	messages: 'Messages',
	broadcasts: 'Broadcasts',
	leaderboards: 'Leaderboards',
	pointValue: points => `${points} Points`, //  points = the number of points that the current user has.
	pointOneValue: '1 Point',
	savedItems: 'Saved Items',
}

export default lang;