// import { push } from 'react-router-redux'

import { clearCurrentUser } from 'scenes/Auth/auth.reducer'
import { asyncGetCategories } from 'scenes/Category/category.reducer'
import { ssoSignOutInProgress, asyncGetCurrentUser } from 'scenes/Auth/auth.reducer'
import { vsApi, requests, apiUrlBuilder } from 'helpers/apiHelpers'
import Routes from 'helpers/routes'
import { decodeQueryParams, encodeQueryParams, safeGetNestedProp } from '@dysi/js-helpers'
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'

// This is where SocialPopup will put the result in [local|session]Storage
const dySiSocialCallbackResultKey = 'voicestorm.com:dySiSocialCallbackResult'
export const isUserLoggedIn = user => !!(user && user.profileCompleted)

export const isUserProfileIncomplete = user => !!(user && user.profileCompleted === false) // user exists and profileComplete prop exists and is set to false

// Logout that only clears localstorage/state (no post to the ManagerApp)
export const clearUserAndRedirect = (dispatch, homeUrl) => {
	// Reset user in Redux & localStorage and notify other apps of Sign Out
	dispatch(clearCurrentUser())

	// Reload app to flush all user-specific data in store and start fresh
	if (homeUrl) {
		window.location = homeUrl
	} else {
		window.location.reload()
	}
}

// Unified logout that makes a post to the ManagerApp before clearing local storage/state
export const signOutUser = dispatch => {
	dispatch((dispatch, getState) => {
		// Dispatch a thunk to get access to state (in Redux store)
		const state = getState()

		let homeUrl = safeGetSetting(state, Settings.Community.HomeUrl)
		const isSso = safeGetSetting(state, Settings.Features.EnableSso)
		const isEmailSignOn = safeGetSetting(state, Settings.Features.EnableEmailSignOn)
		const isUserNameSignOn = safeGetSetting(state, Settings.Features.EnableUsernameSignOn)
		const isSsoWithoutMixedMode = isSso && !isEmailSignOn && !isUserNameSignOn
		const ssoLogoutUrl = safeGetSetting(state, Settings.Features.SsoLogoutUrl)

		// If sphere is SSO and third party logout URL is set, redirect user there.
		// Else if SSO is enabled but not in mixed mode, redirect to signout success page.
		// Otherwise all other cases, redirect user to home page.
		if (isSso) {
			// DS-1453, TA-21344: For SSO logouts, set flag so the ProtectedRoute component doesn't try to redirect to signin page
			// clearUserAndRedirect below will redirect or reload the page
			dispatch(ssoSignOutInProgress())

			if (ssoLogoutUrl) {
				homeUrl = ssoLogoutUrl
			} else if (homeUrl && isSsoWithoutMixedMode) {
				homeUrl += Routes.SignOut.linkPath
			}
		}

		// TODO: Possibly set isPending state to true in case post takes a while so user knows something is happening

		// Make logout API call to log user out of ManagerApp
		dispatch(
			vsApi({ endpoint: requests.logout.endpoint, requestOptions: requests.logout.requestOptions })
		).finally(() => clearUserAndRedirect(dispatch, homeUrl))
	})
}

/**
 * Dispatch this function when the user has been updated and potentially changed to a new
 * user. For example, after a login, logout, our external authentication sync.
 */
export const updateUser = (dispatch, reloadWindow) => {
	dispatch(asyncGetCurrentUser()).then(user => {
		dispatch(asyncGetCategories()) // Categories are user-specific. Let's update them
		if (reloadWindow) window.location.reload()
		return user
	})
}

/**
 * Dispatch this function when properties on the current user have changed and need to
 * be updated in the Redux store. For example, after a settings change succeeds.
 */
export const refreshUser = dispatch => dispatch(asyncGetCurrentUser())

// Private method that creates a new instance of an event-listener that will resolve
// or reject a promise when the Social Popup result is saved to localStorage
const createStorageListener = (resolve, reject) => event => {
	// storage event docs: https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent (varies slightly by browser)

	// Is this the Social Popup Result storage event?
	if (
		event &&
		event.storageArea === window.localStorage &&
		event.key === dySiSocialCallbackResultKey &&
		event.newValue
	) {
		// TODO: Verify this is the correct provider and disposition??

		// Parse new value into an object
		const result = decodeQueryParams(JSON.parse(event.newValue))

		// Remove result from localStorage. We're handling it!
		try {
			localStorage.removeItem(dySiSocialCallbackResultKey)
		} catch (err) {
			// Ignore errors
		}

		// Was this an error response?
		if (result.error) {
			// Reject with error response
			reject(result)
		} else {
			// Success! Resolve with Social Popup response
			resolve(result)
		}
	}
}

/**
 * Dispatch this function to open the Social Popup that allows a user to add a
 * user channel (like Twitter or Facebook) to his/her profile
 * @param {provider} Name of the social provider to add, like 'Twitter' (provider.canonicalName)
 */
export const asyncAddUserChannel = provider => {
	return (dispatch, getState) => {
		// Return a thunk so we can get state from Redux store

		const state = getState()
		const providers = safeGetNestedProp(state, 'sphere.providers')
		const host = safeGetNestedProp(state, 'sphere.host')

		if (!providers[provider]) throw new Error(`Invalid provider "${provider}"`)

		let storageEventListener = null

		// This promise will be resolved by the storage event listener
		// when the Social Popup puts its result in localStorage
		const promise = new Promise((resolve, reject) => {
			// Create a storage event listener and add it to window
			storageEventListener = createStorageListener(resolve, reject)
			window.addEventListener('storage', storageEventListener)
		})

		// Build the Social Popup URL
		const socialPopupParams = {
			method: 'vsReturnToken',
			channelFile: `https://${host}/home/socialresponse`, // Custom BrandApp channel file for NMA
			channel: provider,
			disposition: 'addchannel',
			source: 'DesktopWeb', // TODO: DesktopWeb vs MobileWeb
			// TODO: inlineReturnUrl?
		}
		const socialPopupUrl = apiUrlBuilder(state, `channel?${encodeQueryParams(socialPopupParams)}`)

		// TODO: Show 'popup' inline for mobile?
		// if (inlineReturnUrl) {
		// 	// change the URL, return a promise that will never be resolved (the URL is changing so no code could respond anyway)
		// 	global.location.href = needTokenChannelUrl;
		//  return promise.finally(() => window.removeEventListener('storage', storageEventListener));
		// }

		// Open the pop-up window and give it focus
		// TODO: Add windowParams to providers. Shane: See ApiController line 300: details.windowParams = auth.WindowOpenParams;
		const windowParams =
			providers[provider].windowParams || 'width=800,height=800,resizable=1,scrollbars=1'
		const socialPopup = window.open(socialPopupUrl, 'DynamicSignalSocialPopup', windowParams)
		if (socialPopup && socialPopup.focus) socialPopup.focus()

		// Attach a finally handler to the storage promise that removes the event listener
		// after the Social Popup result is pulled from localStorage. Return this promise
		// as the new promise result
		return promise.finally(() => window.removeEventListener('storage', storageEventListener))
	}
}

/**
 * Whether or not the current user is an internal DySi team member. This can be used for fun
 * seasonal stuff among other things.
 * @param {any} state will be used to perform analysis
 */
export const isDySiTeamMember = state => {
	const isUserLoggedIn = Settings.User.IsLoggedIn.value(state)
	const userDivisions = safeGetNestedProp(state, 'auth.user.divisions', [])
	const isUserInDySiDivision = userDivisions.some(e => e.name && e.name === 'DySi Team')
	const isDispatch = safeGetSetting(state, Settings.Community.SphereId) === 100301
	return (
		(isDispatch && isUserLoggedIn && isUserInDySiDivision) || !!process.env.DS_TEAM_MEMBER_OVERRIDE
	)
}
