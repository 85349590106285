import React from 'react'
import PropTypes from 'prop-types'

import withSettings, { Settings, SettingsPropType } from 'wrappers/SettingsWrapper'

// Moment
import moment from 'moment-timezone'

// Lang
import lang from './dateTime.lang'

// Constants
export const DateFormat = {
	Date: 'date',
	Time: 'time',
	DateTimeTimeZone: 'dateTimeTimeZone',
	FromNow: 'fromNow',
	TimeLeft: 'timeLeft',
	Month: 'month',
	DayOfMonth: 'dayOfMonth',
}

const MomentFormat = {
	/** Feb 14, 2018 */
	Date: 'll',
	/** February 14, 2018 10:20 AM */
	DateAndTime: 'LLL',
	/** 10:20 AM */
	Time: 'LT',
	/** Feb */
	Month: 'MMM',
	/** 14 */
	DayOfMonth: 'DD',
}

const injectedSettings = [Settings.User.Timezone, Settings.User.TimezoneMediumName]

// Helper that identifies if date is within the same day
export const isSameDay = momentDate => momentDate.isSame(moment(), 'day')

// Convert date prop to correct moment object
export const getMomentFromDate = date => (moment.isMoment(date) ? date : moment(date))

// Render helpers
export const renderDate = momentDate => momentDate.format(MomentFormat.Date)
export const renderTime = momentDate => momentDate.format(MomentFormat.Time)
export const renderFromNow = momentDate =>
	isSameDay(momentDate) ? momentDate.fromNow() : momentDate.format(MomentFormat.Date)
export const renderTimeLeft = momentDate => lang.timeLeft(momentDate.toNow(true))
export const renderMonth = momentDate => momentDate.format(MomentFormat.Month)
export const renderDayOfMonth = momentDate => momentDate.format(MomentFormat.DayOfMonth)

/**
 * Renders a date consistently across the app. This assumes that the date you are passing in is a UTC date considering all dates coming from the API are UTC.
 *
 * @class Date
 */
class DateTime extends React.Component {
	static propTypes = {
		style: PropTypes.any,
		format: PropTypes.oneOf(Object.values(DateFormat)),
		children: PropTypes.any,
		date: PropTypes.any.isRequired,
		...SettingsPropType(injectedSettings),
	}

	static defaultProps = {
		format: DateFormat.Date,
	}

	render() {
		const {
			children,
			date,
			format,
			displayTimezone,
			timezoneMediumName,
			style: styleOverride,
		} = this.props

		const momentDate = getMomentFromDate(date)
		momentDate.tz(displayTimezone)

		// Render the appropriate format
		let formattedDate
		switch (format) {
			case DateFormat.Date:
				formattedDate = renderDate(momentDate)
				break
			case DateFormat.Time:
				formattedDate = renderTime(momentDate)
				break
			case DateFormat.DateTimeTimeZone:
				formattedDate = `${renderDate(momentDate)} ${renderTime(momentDate)} ${timezoneMediumName}`
				break
			case DateFormat.FromNow:
				formattedDate = renderFromNow(momentDate)
				break
			case DateFormat.TimeLeft:
				formattedDate = renderTimeLeft(momentDate)
				break
			case DateFormat.Month:
				formattedDate = renderMonth(momentDate)
				break
			case DateFormat.DayOfMonth:
				formattedDate = renderDayOfMonth(momentDate)
				break
			default:
				formattedDate = renderDate(momentDate)
				break
		}

		const formattedTitle = momentDate.format(MomentFormat.DateAndTime)

		return (
			<span style={styleOverride} title={formattedTitle} aria-label={formattedTitle}>
				{typeof children === 'function' ? children(formattedDate) : formattedDate}
			</span>
		)
	}
}

export default withSettings(DateTime, injectedSettings)
