// SocialCommentData represents a single social comment. Please add any missing props or calculations you need below.
// 	o Keep lists in alphabetical order!
/**
 * SocialCommentData represents a single social comment.
 * @param {Object} socialComment
 */
const SocialCommentData = socialComment => {
	//const authorName = safeGetNestedProp(post, 'author.author') || safeGetNestedProp(post, 'author.postSourceName') || null;

	return {
		id: socialComment.id,

		// Native props - Alphabetical, please!
		channels: socialComment.channels,
		comment: socialComment.comment,
		creator: socialComment.creator,

		/** Verifies that the comment meets the minimum requirements needed to be valid. */
		get isValid() {
			delete this.isValid
			return (this.isValid = !!(
				this.id &&
				this.channels.length > 0 &&
				this.comment &&
				this.creator
			))
		},
	}
}
export default SocialCommentData
