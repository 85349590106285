import React from 'react'
import PropTypes from 'prop-types'
import { flexContainer, width, fontFamily, color } from 'styles/variables'

import lang from 'pages/notFound.lang'

const styles = {
	error: {
		...flexContainer(),
	},
	container: {
		fontFamily: fontFamily.base,
		backgroundColor: '#ffb6c2',
		width: width.layout.main,
		padding: width.spacing.basic,
		color: color.primaryBlack,
	},
}

const AppDidBreak = props => {
	const { error, componentStack } = props
	return (
		<section style={styles.error}>
			<div style={styles.container}>
				<h1>{lang.somethingReallyBad}</h1>
				{!process.env.DS_ENV_IS_PRODUCTION && (
					<React.Fragment>
						<h2>{error && error.toString()}</h2>
						<p>{componentStack}</p>
						{!process.env.DS_ENV_IS_PRODUCTION && (
							<div>
								<p>Our team has been notified.</p>
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		</section>
	)
}

AppDidBreak.propTypes = {
	error: PropTypes.object,
	componentStack: PropTypes.string,
}

export default AppDidBreak
