import { isRtlLanguage } from 'helpers/rtlLanguageHelper'

const TranslationButtonData = (commentId, comment, commentTranslateResponse) => {
	if (commentTranslateResponse != null) {
		return TranslateComment(commentId, comment, commentTranslateResponse)
	}

	return;
}
const TranslateComment = (commentId, comment, commentTranslateResponse) => {
	return {
		// Fetch state
		isPending: false,
		isError: false,
		displayText: commentTranslateResponse.comment,
		isRtlLanguage: isRtlLanguage(commentTranslateResponse.toLang),

		// Translation direction
		fromLang: commentTranslateResponse.fromLang,
		toLang: commentTranslateResponse.toLang,

		// Copies of original Post properties (to revert to)
		original: {
			comment: comment,
			commentId: commentId,
			isRtlLanguage: isRtlLanguage(commentTranslateResponse.fromLang),
		},

		// Same properties, but translated from original language to user's display language
		translated: {
			comment: commentTranslateResponse.comment,
			commentId: commentTranslateResponse.commentId,
			isRtlLanguage: isRtlLanguage(commentTranslateResponse.toLang),
		},
	}
}
export const TranslationPending = postId => ({
	isPending: true,
	isError: false,
	postId: postId
})
export const TranslationError = () => ({
	isPending: false,
	isError: true,
})

export default TranslationButtonData
