import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import { color, border, width } from 'styles/variables'

// Styles
const styles = {
	base: {
		opacity: 1,
		transition: 'opacity .5s ease-out', // Fade in/out
		color: color.secondaryGreen,
		backgroundColor: color.backgroundGreen,
		borderTop: border.success,
		borderBottom: border.success,
		marginTop: width.spacing.basic,
		marginBottom: width.spacing.basic,
		paddingTop: width.spacing.narrow,
		paddingBottom: width.spacing.narrow,
		paddingLeft: width.spacing.wide,
		paddingRight: width.spacing.wide,
	},
	fadeOut: {
		opacity: 0,
	},
}

class FormSuccess extends Component {
	static propTypes = {
		isOpen: PropTypes.bool, // When to show success message
		autoClose: PropTypes.bool, // If set, onClose will called 5 seconds after isOpen is set to true
		onClose: PropTypes.func, // If autoClose is true, this function will be called to hide this
		style: PropTypes.object,
		children: PropTypes.any,
		autoCloseTime: PropTypes.number,
	}

	static defaultProps = {
		isOpen: true,
		autoClose: true,
		style: {},
	}

	state = {
		successTimeout: null,
		fadeOut: false,
	}

	componentDidUpdate(prevProps, prevState) {
		const { isOpen, autoClose } = this.props
		const { isOpen: wasOpen, successTimeout } = prevProps

		if (!isOpen && wasOpen && successTimeout) {
			clearTimeout(successTimeout)
		}

		// If opening and autoClose, start the auto-close timer
		if (!wasOpen && isOpen && autoClose) {
			this.startAutoClose()
		}
	}

	componentWillUnmount() {
		clearTimeout(this.successTimeout)
		clearTimeout(this.closeTimeout)
	}

	startAutoClose = () => {
		const { autoCloseTime } = this.props
		this.successTimeout = setTimeout(() => {
			const { onClose } = this.props

			if (onClose) {
				// Fade out
				this.setState({ fadeOut: true, successTimeout: null })
				this.closeTimeout = setTimeout(onClose, 500)
			}
		}, autoCloseTime * 1000 || 5000)
		this.setState({ fadeOut: false, successTimeout: this.successTimeout })
	}

	render() {
		const { isOpen, style, children } = this.props
		const { fadeOut } = this.state

		if (!isOpen) return null

		// Styles
		const dynamicStyles = {
			base: {
				...styles.base,
				...(fadeOut ? styles.fadeOut : {}),
				...style,
			},
		}

		return <div style={dynamicStyles.base}>{children}</div>
	}
}

export default FormSuccess
