// MessageData represents a single message. Please add any missing props or calculations you need below.
//	o Keep lists in alphabetical order!
/**
 * MessageData represents a single message.
 * @param {Object} message
 */

const MessageData = message => {
	return {
		conversationMessageId: message.conversationMessageId,

		// Native props - Alphabetical, please!
		conversationId: message.conversationId,
		createdDate: message.createdDate,
		currentTime: message.currentTime,
		messageText: message.messageText,
		post: message.post,
		userDisplayName: message.userDisplayName,
		userId: message.userId,
		userProfilePictureSquare40Url: message.userProfilePictureSquare40Url,

		// Calculated props - Alphabetical, please!
		// - Use these when the property isn't a 1:1 map with the API conversation object

		// ===========================================================================================
		// Getter props (Sort alphabetically)
		//   NOTE: Use these when you need to self-reference
		// ===========================================================================================
	}
}

export default MessageData
