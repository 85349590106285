import { vsApi, requests } from 'helpers/apiHelpers'

const FETCH_NOTIFICATIONS_PREFERENCES_REQUEST = 'FETCH_NOTIFICATIONS_PREFERENCES_REQUEST'
const FETCH_NOTIFICATIONS_PREFERENCES_SUCCESS = 'FETCH_NOTIFICATIONS_PREFERENCES_SUCCESS'
const FETCH_NOTIFICATIONS_PREFERENCES_FAILURE = 'FETCH_NOTIFICATIONS_PREFERENCES_FAILURE'

const SET_NOTIFICATIONS_PREFERENCES_REQUEST = 'SET_NOTIFICATIONS_PREFERENCES_REQUEST'
const SET_NOTIFICATIONS_PREFERENCES_SUCCESS = 'SET_NOTIFICATIONS_PREFERENCES_SUCCESS'
const SET_NOTIFICATIONS_PREFERENCES_FAILURE = 'SET_NOTIFICATIONS_PREFERENCES_FAILURE'

const RESEND_SMS_CODE_REQUEST = 'RESEND_SMS_CODE_REQUEST'
const RESEND_SMS_CODE_SUCCESS = 'RESEND_SMS_CODE_SUCCESS'
const RESEND_SMS_CODE_FAILURE = 'RESEND_SMS_CODE_FAILURE'

const VERIFY_SMS_CODE_REQUEST = 'VERIFY_SMS_CODE_REQUEST'
const VERIFY_SMS_CODE_SUCCESS = 'VERIFY_SMS_CODE_SUCCESS'
const VERIFY_SMS_CODE_FAILURE = 'VERIFY_SMS_CODE_FAILURE'

export const asyncGetNotificationsPreferences = userId => {
	return vsApi({
		endpoint: requests.getNotificationPreferences.getEndpoint(userId),
		actions: {
			request: FETCH_NOTIFICATIONS_PREFERENCES_REQUEST,
			success: FETCH_NOTIFICATIONS_PREFERENCES_SUCCESS,
			failure: FETCH_NOTIFICATIONS_PREFERENCES_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncSetNotificationsPreferences = (
	userId,
	preferences = null,
	subscribeToSms = null,
	smsPhoneNumber = null,
	smsReturnUrl = null,
	abortable
) => {
	return vsApi({
		endpoint: requests.getNotificationPreferences.getEndpoint(userId),
		actions: {
			request: SET_NOTIFICATIONS_PREFERENCES_REQUEST,
			success: SET_NOTIFICATIONS_PREFERENCES_SUCCESS,
			failure: SET_NOTIFICATIONS_PREFERENCES_FAILURE,
		},
		requestOptions: requests.setNotificationPreferences.getRequestOptions(
			userId,
			preferences,
			subscribeToSms,
			smsPhoneNumber,
			smsReturnUrl
		),
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

export const asyncResendNotificationsSMSCode = (userId, smsReturnUrl) => {
	return vsApi({
		endpoint: requests.resendSmsCode.getEndpoint(userId),
		actions: {
			request: RESEND_SMS_CODE_REQUEST,
			success: RESEND_SMS_CODE_SUCCESS,
			failure: RESEND_SMS_CODE_FAILURE,
		},
		requestOptions: requests.resendSmsCode.getRequestOptions(userId, smsReturnUrl),
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncVerifyNotificationsSMSCode = (userId, smsVerificationCode, abortable) => {
	return vsApi({
		endpoint: requests.verifySmsCode.getEndpoint(userId),
		actions: {
			request: VERIFY_SMS_CODE_REQUEST,
			success: VERIFY_SMS_CODE_SUCCESS,
			failure: VERIFY_SMS_CODE_FAILURE,
		},
		requestOptions: requests.verifySmsCode.getRequestOptions(userId, smsVerificationCode),
		options: { abortable, captureUnhandledPromiseRejection: false },
	})
}

// Set the initial state
const initialState = {
	preferences: [],
	subscribeToSms: false,
	smsPhoneNumber: '',
	smsVerified: false,
	verificationFailed: false,
}

// Reducer
export default function api(state = initialState, action) {
	switch (action.type) {
		case FETCH_NOTIFICATIONS_PREFERENCES_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}

		case FETCH_NOTIFICATIONS_PREFERENCES_SUCCESS: {
			const { preferences, subscribeToSms, smsPhoneNumber, smsVerified } = action.response

			return {
				...state,
				preferences,
				isLoading: false,
				subscribeToSms: subscribeToSms || false,
				smsPhoneNumber: smsPhoneNumber || '',
				smsVerified: smsVerified || false,
			}
		}

		case FETCH_NOTIFICATIONS_PREFERENCES_FAILURE: {
			return {
				...state,
				isLoading: false,
			}
		}

		case SET_NOTIFICATIONS_PREFERENCES_REQUEST: {
			return {
				...state,
			}
		}

		case SET_NOTIFICATIONS_PREFERENCES_SUCCESS: {
			const { preferences, subscribeToSms, smsPhoneNumber, smsVerified } = action.response
			return {
				...state,
				preferences,
				subscribeToSms: subscribeToSms || false,
				smsPhoneNumber: smsPhoneNumber || '',
				smsVerified: smsVerified || false,
			}
		}

		case SET_NOTIFICATIONS_PREFERENCES_FAILURE: {
			return {
				...state,
			}
		}

		case VERIFY_SMS_CODE_REQUEST: {
			return {
				...state,
				verificationFailed: false,
			}
		}

		case VERIFY_SMS_CODE_SUCCESS: {
			return {
				...state,
				smsVerified: true,
			}
		}

		case VERIFY_SMS_CODE_FAILURE: {
			return {
				...state,
				verificationFailed: true,
			}
		}

		default:
			return state
	}
}
