import { vsApi, requests } from 'helpers/apiHelpers'
import { isDesktopApp } from 'helpers/browserhelper'
import { safeGetNestedProp } from '@dysi/js-helpers'
import IpcEvent from 'helpers/desktopApp/ipcEvent'

const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST'
const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'
const MARK_READ_SUCCESS = 'MARK_READ_SUCCESS'
const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS'
const FETCH_NOTIFICATIONCOUNT_SUCCESS = 'FETCH_NOTIFICATIONCOUNT_SUCCESS'
const RELAY_SET_UNREAD_NOTIFICATION_COUNT = 'RELAY_SET_UNREAD_NOTIFICATION_COUNT'

export const asyncGetNotifications = (userId, before, limit) => {
	return vsApi({
		endpoint: requests.getNotifications.getEndpoint(userId, before, limit),
		actions: {
			request: FETCH_NOTIFICATIONS_REQUEST,
			success: FETCH_NOTIFICATIONS_SUCCESS,
			failure: FETCH_NOTIFICATIONS_FAILURE,
		},
		context: {
			before,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetNotification = notificationId => {
	return vsApi({
		endpoint: requests.getNotification.getEndpoint(notificationId),
		actions: {
			success: FETCH_NOTIFICATION_SUCCESS,
		},
	})
}

export const asyncMarkAsRead = (currentUserId, notificationIds) => {
	const groupedReadDate = new Date().toISOString() // Give notification a read date even though it's wrong, so that it's marked as read
	return vsApi({
		endpoint: requests.markAsRead.endpoint,
		actions: {
			success: MARK_READ_SUCCESS,
		},
		requestOptions: requests.markAsRead.getRequestOptions(currentUserId, notificationIds),
		context: {
			notificationIds,
			groupedReadDate,
		},
	})
}

export const clearNotifications = () => {
	return {
		type: CLEAR_NOTIFICATIONS,
	}
}

export const asyncGetNotificationCount = () => {
	return vsApi({
		endpoint: requests.getNotificationsCount.endpoint,
		actions: {
			success: FETCH_NOTIFICATIONCOUNT_SUCCESS,
		},
	})
}

export const relaySetUnreadNotificationCount = count => {
	return {
		type: RELAY_SET_UNREAD_NOTIFICATION_COUNT,
		count,
	}
}

// Helpers
const normalizeNotifications = (notifications, oldNotifications, isMore) => {
	const notificationsArray = Object.values(notifications)
	// Use an empty object if no previous notifications are set in state, otherwise keep existing notifications
	const normalizedNotifications = { ...oldNotifications }
	const allIds = []
	// Add all returned notifications to the normalized data object
	notificationsArray.forEach(notification => {
		const key = notification.notificationIds[0]

		normalizedNotifications.byId[key] = notification
		allIds.push(key)
	})
	const filteredIds = oldNotifications.ids.filter(id => !allIds.includes(id)) // remove duplicates
	normalizedNotifications.ids = isMore ? [...filteredIds, ...allIds] : [...allIds, ...filteredIds] // always set new order for notifications

	return normalizedNotifications
}

// Initial State
const initialState = {
	normalizedNotifications: {
		byId: {},
		ids: [],
	},
	notificationsNextStart: 0,
	isNotificationRequestPending: false,
	count: {},
}

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_NOTIFICATIONS_REQUEST: {
			return {
				...state,
				isNotificationRequestPending: true,
			}
		}
		case FETCH_NOTIFICATIONS_SUCCESS: {
			const isMore = action.context.before ? true : false
			// ID to use when getting more notifications
			const next = action.response.cursors.beforeId
			// If a prevURL is given, there are more notifications available
			const areMoreNotificationsAvailable = action.response.prevUrl ? true : false
			const normalizedNotifications = normalizeNotifications(
				action.response.notifications,
				state.normalizedNotifications,
				isMore
			)
			return {
				...state,
				normalizedNotifications,
				isNotificationRequestPending: false,
				notificationsNextStart: next,
				areMoreNotificationsAvailable: areMoreNotificationsAvailable,
			}
		}
		case FETCH_NOTIFICATIONS_FAILURE: {
			return {
				...state,
				...initialState,
				isNotificationRequestPending: false,
			}
		}
		case MARK_READ_SUCCESS: {
			const notificationId = action.context.notificationIds[0]
			const groupedReadDate = action.context.groupedReadDate

			return {
				...state,
				normalizedNotifications: {
					...state.normalizedNotifications,
					byId: {
						...state.normalizedNotifications.byId,
						[notificationId]: {
							...state.normalizedNotifications.byId[notificationId],
							groupedReadDate,
						},
					},
				},
			}
		}
		case CLEAR_NOTIFICATIONS: {
			return {
				...state,
				...initialState,
			}
		}
		case FETCH_NOTIFICATION_SUCCESS: {
			const notification = action.response
			const ids = state.normalizedNotifications.ids.includes(notification.notificationIds[0])
				? [...state.normalizedNotifications.ids]
				: [notification.notificationIds[0], ...state.normalizedNotifications.ids]

			return {
				...state,
				normalizedNotifications: {
					...state.normalizedNotifications,
					byId: {
						...state.normalizedNotifications.byId,
						[notification.notificationIds[0]]: notification,
					},
					ids,
				},
			}
		}
		case FETCH_NOTIFICATIONCOUNT_SUCCESS: {
			if (isDesktopApp) {
				window.ipcRenderer.send(IpcEvent.update.notificationCount, {
					count: safeGetNestedProp(action, 'response.totals.New', 0),
				})
			}

			return {
				...state,
				count: action.response,
			}
		}
		case RELAY_SET_UNREAD_NOTIFICATION_COUNT: {
			return {
				...state,
				count: {
					totals: {
						New: action.count,
					},
				},
			}
		}
		default:
			return state
	}
}
