import React, { Component } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'

// Lang
import lang from './shareChannels.lang'

// Components
import Icon, { Name } from 'components/Icons/FontAwesome'

// Enums
import ShareTextKeys from '../enums/shareTextKeys'

// Styles
import styles from './SuggestedShareTextItem.styles'
import { color, spacing } from 'styles/variables'

class SuggestedShareTextItem extends Component {
	constructor(props) {
		super(props)
		this.suggestedShareText = React.createRef()
	}

	static propTypes = {
		item: PropTypes.string,
		name: PropTypes.string,
		setSuccessMessage: PropTypes.func,
		primaryColor: PropTypes.string,
		clearErrorMessage: PropTypes.func,
		disabled: PropTypes.bool,
		copyCommandSupported: PropTypes.bool,
		setErrorMessage: PropTypes.func,
		handleReplaceText: PropTypes.func,
		toggleSuggestedShareText: PropTypes.func,
	}

	state = {
		isHovering: false,
	}

	handleMouseEnter = () => this.setState({ isHovering: true })

	handleMouseLeave = () => this.setState({ isHovering: false })

	handleCopyText = e => {
		e.preventDefault()
		const {
			clearErrorMessage,
			setErrorMessage,
			item: shareText,
			toggleSuggestedShareText,
			setSuccessMessage,
		} = this.props

		// Copy text, send success message, and then close the suggested share text box.
		try {
			// iOS won't let you select and copy unless input readOnly is false and contentEditable is true
			copy(shareText)
			setSuccessMessage(lang.copyLinkSuccess)
			toggleSuggestedShareText()
		} catch (e) {
			setErrorMessage({ error: lang.copyLinkError })
			setTimeout(() => clearErrorMessage(), 5000)
		}
	}

	handleReplaceText = e => {
		e.preventDefault()
		const { handleReplaceText: replaceText, item: shareText } = this.props
		replaceText(shareText)
	}

	render() {
		const { primaryColor, name, item: shareText, disabled, copyCommandSupported } = this.props
		const { isHovering } = this.state

		const dynamicStyles = {
			item: {
				...styles.item,
				backgroundColor: disabled ? color.secondaryGrey : isHovering ? color.bgPage : 'transparent',
				cursor: disabled ? 'not-allowed' : 'default',
			},
			shareText: {
				...styles.item.shareText,
				color: primaryColor,
			},
			copy: {
				cursor: disabled ? 'not-allowed' : 'pointer',
			},
			edit: {
				marginRight: name !== ShareTextKeys.Twitter ? spacing.narrow : 0,
				cursor: disabled ? 'not-allowed' : 'pointer',
			},
		}

		return (
			<div
				style={dynamicStyles.item}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				{/* */}
				<div ref={this.suggestedShareText} style={dynamicStyles.shareText}>
					{shareText}
				</div>
				<div style={styles.iconContainer}>
					{/* Replace ShareText as Suggested Share Text */}
					<div
						title={lang.replace}
						style={dynamicStyles.edit}
						onClick={!disabled ? this.handleReplaceText : null}
					>
						<Icon name={Name.pencil} style={styles.item.icon} color={color.secondaryGrey} />
					</div>
					{/* Copy Suggested Share Text to clipboard */}
					{name !== ShareTextKeys.Twitter && (
						<div
							title={lang.copy}
							style={dynamicStyles.copy}
							onClick={copyCommandSupported || !disabled ? this.handleCopyText : null}
						>
							<Icon name={Name.copy} style={styles.item.icon} color={color.secondaryGrey} />
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default SuggestedShareTextItem
