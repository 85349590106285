import React from 'react'
import PropTypes from 'prop-types'

import lang from './post.lang'
import Icon, { Name } from 'components/Icons/FontAwesome'
import Layout from './enums/postLayout'
import postConstants from './enums/postConstants'

import { color, border, width, fontWeight, fontSize } from 'styles/variables'

const styles = {
	container: {
		padding: width.spacing.basic,
		backgroundColor: color.white,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		border: border.basic,
		fontSize: fontSize.base,
	},
	heading: {
		display: 'flex',
		alignItems: 'center',
		fontSize: fontSize.base,
		fontWeight: fontWeight.semiBold,
		marginBottom: width.spacing.basic,
	},
	icon: {
		fontSize: 30,
		color: color.secondaryGrey,
		lineHeight: 1,
		marginRight: width.spacing.basic,
	},
}

class FallbackPost extends React.Component {
	static propTypes = {
		layout: PropTypes.oneOf(Object.values(Layout)),
		style: PropTypes.object,
	}

	render() {
		const { layout = Layout.feed, style } = this.props
		const dynamicStyles = {
			container: {
				...styles.container,
				maxWidth: postConstants[layout].maxWidth,
				...style,
			},
		}

		return (
			<div style={dynamicStyles.container}>
				<div style={styles.heading}>
					<Icon name={Name.frown} style={styles.icon} />
					<span>{lang.errorHeading}</span>
				</div>
				<div>{lang.error}</div>
			</div>
		)
	}
}

export default FallbackPost
