'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'tv-retro';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'f401';
var svgPathData = ['M392 168.07s0-8-168-8c-152 0-152 8-152 8s-8 0-8 120 8 120 8 120 0 8 152 8c168 0 168-8 168-8s8 0 8-120-8-120-8-120zM173.14 96h165.72l35.64-41.32a32 32 0 1 0-45.3-45.3L256 94.27 182.8 9.48a32 32 0 0 0-45.3 45.29z', 'M464 96.07H48a48 48 0 0 0-48 48v288a48 48 0 0 0 48 48h16v32h48l21.3-32h245.3l21.3 32h48v-32h16a48 48 0 0 0 48-48v-288a47.86 47.86 0 0 0-47.9-48zm-72 312s0 8-168 8c-152 0-152-8-152-8s-8 0-8-120 8-120 8-120 0-8 152-8c168 0 168 8 168 8s8 0 8 120-8 120-8 120zm72-100a12 12 0 0 1-12 12h-8a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h8a12 12 0 0 1 12 12zm0-64a12 12 0 0 1-12 12h-8a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h8a12 12 0 0 1 12 12z'];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faTvRetro = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;