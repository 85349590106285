import PropTypes from 'prop-types'
import { Name } from 'components/Icons/FontAwesome'

// Local, static subset of Social Providers (v1/providers) that are visible to users
const SocialProviderData = {
	// 'Blog' is not technically a Social Provider. It's used to lump together "other"
	// providers for things like Sharing Disclosures
	Blog: {
		provider: 'Blog',
		displayName: 'Blog',
		iconName: Name.blog,
		color: '#FF7c11',
	},
	Blogger: {
		provider: 'Blogger',
		displayName: 'Blogger',
		iconName: Name.blogger,
		color: '#FF6600',
	},
	Chatter: {
		provider: 'Chatter',
		displayName: 'Chatter',
		iconName: Name.chatter,
		color: '#28A0DC',
	},
	CutAndPaste: {
		provider: 'CutAndPaste',
		displayName: 'Copy Link',
		iconName: Name.cutandpaste,
		color: '#777777',
	},
	Email: {
		provider: 'Email',
		displayName: 'Email',
		iconName: Name.email,
		color: '#31a1a1',
	},
	Facebook: {
		provider: 'Facebook',
		displayName: 'Facebook',
		iconName: Name.facebook,
		color: '#3C5B9B',
	},
	FacebookPage: {
		provider: 'FacebookPage',
		displayName: 'Facebook Page',
		iconName: Name.facebookpage,
		color: '#000000',
	},
	FacebookNative: {
		provider: 'FacebookNative',
		displayName: 'Facebook',
		iconName: Name.facebook,
		color: '#3C5B9B',
	},
	FacebookWorkplace: {
		provider: 'FacebookWorkplace',
		displayName: 'Facebook Workplace',
		iconName: Name.facebook,
		color: '#3C5B9B',
	},
	Instagram: {
		provider: 'Instagram',
		displayName: 'Instagram',
		iconName: Name.instagram,
		color: '#664C28',
	},
	InstagramBusiness: {
		provider: 'InstagramBusiness',
		displayName: 'Instagram',
		iconName: Name.instagram,
		color: '#664C28',
	},
	Jive: {
		provider: 'Jive',
		displayName: 'Jive',
		iconName: Name.jive,
		color: '#272C6D',
	},
	JivePlace: {
		provider: 'JivePlace',
		displayName: 'Jive Place',
		iconName: Name.jive,
		color: '#272C6D',
	},
	LinkedIn: {
		provider: 'LinkedIn',
		displayName: 'LinkedIn',
		iconName: Name.linkedin,
		color: '#0077B5',
	},
	LinkedInNative: {
		provider: 'LinkedInNative',
		displayName: 'LinkedIn',
		iconName: Name.linkedin,
		color: '#0077B5',
	},
	LinkedInPage: {
		provider: 'LinkedInPage',
		displayName: 'LinkedIn Page',
		iconName: Name.linkedinpage,
		color: '#0077B5',
	},
	SapJam: {
		provider: 'SapJam',
		displayName: 'SAP Jam',
		iconName: Name.sapjam,
		color: '#006CB9',
	},
	Tencent: {
		provider: 'Tencent',
		displayName: 'Tencent',
		iconName: Name.tencent,
		color: '#2AB9E5',
	},
	Tumblr: {
		provider: 'Tumblr',
		displayName: 'Tumblr',
		iconName: Name.tumblr,
		color: '#2A445F',
	},
	Twitter: {
		provider: 'Twitter',
		displayName: 'Twitter',
		iconName: Name.twitter,
		color: '#0c0d0d',
	},
	TwitterNative: {
		provider: 'TwitterNative',
		displayName: 'Twitter',
		iconName: Name.twitter,
		color: '#0c0d0d',
	},
	Weibo: {
		provider: 'Weibo',
		displayName: 'Weibo',
		iconName: Name.weibo,
		color: '#E31C34',
	},
	WordPress: {
		provider: 'WordPress',
		displayName: 'WordPress',
		iconName: Name.wordpress,
		color: '#464442',
	},
	Xing: {
		provider: 'Xing',
		displayName: 'XING',
		iconName: Name.xing,
		color: '#005A5F',
	},
	XingNative: {
		provider: 'Xing',
		displayName: 'XING',
		iconName: Name.xing,
		color: '#005A5F',
	},
	YouTube: {
		provider: 'YouTube',
		displayName: 'YouTube',
		iconName: Name.youtube,
		color: '#FE3432',
	},
}

export const ProviderPropType = PropTypes.oneOf(Object.keys(SocialProviderData))

/**
 * When posts have one of these providers, we display the social author information and other things.
 */
export const SocialPostProviders = [
	// SocialProviderData.CutAndPaste.provider,
	// SocialProviderData.Email.provider,
	SocialProviderData.Facebook.provider,
	SocialProviderData.FacebookPage.provider,
	SocialProviderData.Instagram.provider,
	SocialProviderData.InstagramBusiness.provider,
	SocialProviderData.LinkedIn.provider,
	SocialProviderData.LinkedInPage.provider,
	SocialProviderData.Twitter.provider,
	SocialProviderData.YouTube.provider,
]
export const SocialPostProvidersPropType = PropTypes.oneOf(SocialPostProviders)

export const ShowShareButtonOnSocialPostProviders = [
	SocialProviderData.Facebook.provider,
	SocialProviderData.FacebookPage.provider,
	SocialProviderData.LinkedIn.provider,
	SocialProviderData.LinkedInPage.provider,
	SocialProviderData.Twitter.provider,
]

// Social Providers that require authentication validation
export const SocialProvidersVerifyAuthentication = [SocialProviderData.LinkedIn.provider]

export default SocialProviderData
