import React from 'react'
import PropTypes from 'prop-types'
import MorePropTypes from 'airbnb-prop-types'

export const RestrictText = require('./restrictText').default
export const Restrictions = require('helpers/textRestrictions').default

// Component that encapsulates the helper function and handles the tooltip logic as well
class RestrictedText extends React.Component {
	static propTypes = {
		children: PropTypes.func.isRequired,
		text: PropTypes.string.isRequired,
		limit: MorePropTypes.nonNegativeInteger.isRequired,
		ellipsis: PropTypes.string,
		shouldForceUpdate: PropTypes.bool,
	}

	static defaultProps = {
		shouldForceUpdate: false,
	}

	shouldComponentUpdate(nextProps) {
		const { shouldForceUpdate } = this.props
		// shouldForceUpdate is needed for HighlightText
		const isDifferent =
			nextProps.ellipsis !== this.props.ellipsis ||
			nextProps.limit !== this.props.limit ||
			nextProps.text !== this.props.text ||
			shouldForceUpdate ||
			nextProps.shouldForceUpdate

		return isDifferent
	}

	render() {
		const { children, ellipsis, limit = 0, text } = this.props

		// console.count('render')

		if (!text) return null

		const { output, length, tooltip } = RestrictText(text, limit, ellipsis)

		// NOTE: The body of RestrictedText must be a function that takes an argument and either
		// 		returns that text directly or returns a 'valid React element' that includes the
		// 		text.

		// Basic Text Example:
		// 	<RestrictedText limit={100} text={fullLengthText}>
		// 		{text => text}
		// 	</RestrictedText>
		//
		// Becomes:
		// 	<span title={fullLengthText}>truncatedText</span>

		// Component Example:
		// 	<RestrictedText text={fullLengthText} limit={100}>
		// 		{text => (
		// 			<h2>{text}</h2>
		// 		)}
		// 	</RestrictedText>
		//
		// Becomes:
		// 	<h2 title={fullLengthText}>truncatedText</h2>

		// Since we're using a render prop, call the function passed in so that we can understand how
		// it will be rendered. There is an expectation that there will only be only child, hence the
		// array[0] usage.
		const child = React.Children.toArray(children(output, length))[0]

		// If the text wasn't restricted, don't change anything
		if (!tooltip) return child

		// If we have a valid React element, clone it and pass the full text for a tooltip
		if (React.isValidElement(child)) return React.cloneElement(child, { title: tooltip })

		// Otherwise, wrap the raw text in a span so that the tooltip will work properly
		return (
			<span title={tooltip} aria-label={tooltip}>
				{child}
			</span>
		)
	}
}

export default RestrictedText
