import { vsApi, requests } from 'helpers/apiHelpers'
import { FETCH_USER_SUCCESS, CLEAR_CURRENT_USER } from '../../Auth/auth.reducer'
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS'
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'

//#region Async Action creators
export const asyncSetContentLanguages = (userId, languages) => {
	return vsApi({
		endpoint: requests.updateUser.getEndpoint(userId),
		actions: {
			success: FETCH_USER_SUCCESS,
		},
		requestOptions: requests.setContentLanguages.getRequestOptions(languages),
	})
}

export const asyncSetDefaultLanguage = (userId, language) => {
	return vsApi({
		endpoint: requests.updateUser.getEndpoint(userId),
		actions: {
			success: FETCH_USER_SUCCESS,
		},
		requestOptions: requests.setPrimaryLanguage.getRequestOptions(language),
	})
}

export const asyncRemoveUser = userId => {
	return vsApi({
		endpoint: requests.updateUser.getEndpoint(userId),
		actions: {
			success: CLEAR_CURRENT_USER,
		},
		requestOptions: requests.deleteUser.getRequestOptions(userId),
	})
}

export const asyncSetPhoneNumber = (userId, phoneNumber, abortable) => {
	return vsApi({
		endpoint: requests.updateUser.getEndpoint(userId),
		actions: {
			success: FETCH_USER_SUCCESS,
		},
		requestOptions: requests.setPhoneNumber.getRequestOptions(phoneNumber),
		options: { abortable },
	})
}

// NOTE: This is the PUT/POST user request. Per TA-24265, there is now a PATCH request that allows
// us to change or remove emails. This function is specifically for removing emails.
export const asyncChangeEmail = (userId, email, abortable) => {
	return vsApi({
		endpoint: requests.updateUser.getEndpoint(userId),
		actions: {
			success: FETCH_USER_SUCCESS,
		},
		requestOptions: requests.setEmail.getRequestOptions(email),
		options: { abortable },
	})
}

// PATCH API call - helpful for removing emails as well as updating other user properties.
export const asyncRemoveEmail = (userId, abortable) => {
	return vsApi({
		endpoint: requests.removeEmail.getEndpoint(userId),
		actions: {
			success: CHANGE_EMAIL_SUCCESS,
		},
		requestOptions: requests.removeEmail.requestOptions,
		options: { abortable },
	})
}

export const asyncSendVerificationEmail = (email, id = null, abortable) => {
	return vsApi({
		endpoint: requests.sendVerifyEmail.endpoint,
		actions: {
			success: SEND_VERIFICATION_EMAIL_SUCCESS,
		},
		requestOptions: requests.sendVerifyEmail.getRequestOptions(email, id),
		options: { abortable },
	})
}

export const asyncVerifyAccount = (id, verificationCode, checkOnly = false, abortable) => {
	return vsApi({
		endpoint: requests.verifyAccount.endpoint,
		actions: {
			success: VERIFY_ACCOUNT_SUCCESS,
		},
		requestOptions: requests.verifyAccount.getRequestOptions(id, verificationCode, checkOnly),
		options: { abortable },
	})
}

///#endregion
