import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Helpers
import { safeGetNestedProp } from '@dysi/js-helpers'
import { buildStorageKey, storage } from 'helpers/persistor'

const storageKey = buildStorageKey('welcomeBannerShown')
export const dismissWelcomeBannerEvent = 'dismissWelcomeBanner'
const prodGoogleAnalyticsId = 'UA-39143914-1'
const devGoogleAnalyticsId = 'UA-39143914-10'

const mapStateToProps = state => {
	const enableWelcomeBanner = safeGetNestedProp(state, 'sphere.parameters.enableWelcomeBanner')
	const googleAnalyticsId = safeGetNestedProp(state, 'sphere.parameters.googleAnalyticsId')
	const hasWelcomeBannerDismissed =
		safeGetNestedProp(state, 'auth.user.welcomeBannerDismissed') ||
		!!(storage && storage.getItem(storageKey))
	const isVoicestorm = safeGetNestedProp(state, 'sphere.host').endsWith('voicestorm.com')

	return {
		enableWelcomeBanner,
		googleAnalyticsId,
		hasWelcomeBannerDismissed,
		isVoicestorm,
	}
}

class GoogleAnalytics extends Component {
	static propTypes = {
		enableWelcomeBanner: PropTypes.bool,
		googleAnalyticsId: PropTypes.string,
		hasWelcomeBannerDismissed: PropTypes.bool,
		isVoicestorm: PropTypes.bool,
	}

	state = {
		hasAppendedScript: false,
		hasListener: false,
	}

	componentDidMount() {
		if (!process.env.DS_ENV_IS_LOCAL && !process.env.DS_ENV_IS_TEAMA) return

		const { hasWelcomeBannerDismissed, enableWelcomeBanner } = this.props

		// Append only if welcome banner is disabled / dismissed
		if (enableWelcomeBanner === false || hasWelcomeBannerDismissed) {
			this.appendAnalyticsScript()
		}
		// Add listener if enabled and not dismissed
		if (enableWelcomeBanner && !hasWelcomeBannerDismissed) {
			this.addListener()
		}
	}

	componentWillUnmount() {
		if (!process.env.DS_ENV_IS_LOCAL && !process.env.DS_ENV_IS_TEAMA) return

		this.removeListener()
	}

	componentDidUpdate(prevProps) {
		if (!process.env.DS_ENV_IS_LOCAL && !process.env.DS_ENV_IS_TEAMA) return

		const { hasWelcomeBannerDismissed, enableWelcomeBanner } = this.props
		const { hasAppendedScript, hasListener } = this.state

		if (enableWelcomeBanner && enableWelcomeBanner !== prevProps.enableWelcomeBanner) {
			if (!hasWelcomeBannerDismissed && !hasListener) {
				this.addListener()
			}
		}

		if (hasWelcomeBannerDismissed || !enableWelcomeBanner) {
			if (!hasAppendedScript) this.appendAnalyticsScript()
		}
	}

	dismissWelcomeBannerListener = e => {
		this.appendAnalyticsScript()
		this.removeListener()
	}

	appendAnalyticsScript = () => {
		const { googleAnalyticsId, isVoicestorm } = this.props
		this.setState({ hasAppendedScript: true })
		const dsGoogleAnalyticsId = process.env.DS_ENV_IS_PRODUCTION
			? prodGoogleAnalyticsId
			: devGoogleAnalyticsId

		if (window.ga) {
			window.ga('create', dsGoogleAnalyticsId, {
				clientId: isVoicestorm ? 'voicestorm.com' : null,
				cookieFlags: 'secure;samesite=none',
			})
			window.ga('set', 'anonymizeIp', true)
			window.ga('send', 'pageview')
		}

		if (googleAnalyticsId) {
			var _gaq = window._gaq || []
			_gaq.push(['_setAccount', googleAnalyticsId])
			if (isVoicestorm) {
				_gaq.push(['_setDomainName', 'voicestorm.com'])
			}
			_gaq.push(['_trackPageview'])
		}
	}

	addListener = () => {
		this.setState({ hasListener: true })
		window.addEventListener(dismissWelcomeBannerEvent, this.dismissWelcomeBannerListener)
	}

	removeListener = () => {
		window.removeEventListener(dismissWelcomeBannerEvent, this.dismissWelcomeBannerListener)
	}

	render() {
		return null
	}
}

export default connect(mapStateToProps)(GoogleAnalytics)
