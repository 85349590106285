import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Lang
import lang from './appDownloadDialog.lang.js'

// Components
import Dialog from 'components/Dialog/Dialog'
import userAgentTypes from './enums/userAgentTypes'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { Settings } from 'wrappers/SettingsWrapper.js'
import { MobileAppDownloadMode, DysiAppLinks } from 'reducers/sphere.data.js'

// Helpers
import { storage as localStorage } from 'helpers/persistor'
import styles from './AppDownloadDialog.styles'
import { isAndroid, isIos, isSafariMobile } from 'helpers/browserhelper.js'

export const localStorageKey = 'home/landing:appDownloadDialogShown'

const imageLinks = {
	itunes:
		'https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/95afcf8d-7063-4381-9848-82f3872965f6?_=636532052763155058',
	googlePlay:
		'https://d3utlhu53nfcwz.cloudfront.net/138101/cdnImage/CmsObject/d88266b4-f865-4892-a648-f5fdbb28cc49?_=636532052735498962',
}

const mapStateToProps = (state, ownProps) => {
	const androidAppDownloadMode = safeGetNestedProp(
		state,
		'sphere.parameters.androidAppDownloadMode'
	)
	const androidAppDownloadUrl = safeGetNestedProp(state, 'sphere.parameters.androidAppDownloadUrl')
	const communityName = safeGetNestedProp(state, 'sphere.communityName', '')
	const communityRegistrationCode = safeGetNestedProp(state, 'sphere.communityRegistrationCode', '')
	const enableMobileApps = safeGetNestedProp(state, 'sphere.parameters.enableMobileApps')
	const iosAppDownloadMode = safeGetNestedProp(state, 'sphere.parameters.iosAppDownloadMode')
	const iosAppDownloadUrl = safeGetNestedProp(state, 'sphere.parameters.iosAppDownloadUrl')
	const mobileStyledLogoImageUrl = safeGetNestedProp(
		state,
		Settings.Community.MobileStyledLogoImageUrl.path
	)

	return {
		androidAppDownloadMode,
		androidAppDownloadUrl,
		communityName,
		communityRegistrationCode,
		enableMobileApps,
		iosAppDownloadMode,
		iosAppDownloadUrl,
		mobileStyledLogoImageUrl,
	}
}

class AppDownloadDialog extends Component {
	static propTypes = {
		androidAppDownloadMode: PropTypes.string,
		communityName: PropTypes.string,
		communityRegistrationCode: PropTypes.string,
		enableMobileApps: PropTypes.bool,
		forceDisplay: PropTypes.bool,
		iosAppDownloadMode: PropTypes.string,
		mobileStyledLogoImageUrl: Settings.Community.MobileStyledLogoImageUrl.propType,
		onClose: PropTypes.func,
		iosAppDownloadUrl: PropTypes.string,
		androidAppDownloadUrl: PropTypes.string,
	}

	state = {
		appDownloadDialogShown: false,
		url: '',
		src: '',
		alt: '',
		shouldShowDialog: false,
	}

	updateDialogState(userAgent) {
		const {
			mobileStyledLogoImageUrl,
			androidAppDownloadMode,
			iosAppDownloadMode,
			iosAppDownloadUrl,
			androidAppDownloadUrl,
		} = this.props
		const appDownloadDialogShown =
			localStorage && localStorage.getItem(localStorageKey) !== null ? true : false

		// Default state if nothing defined
		let url = ''
		let src = ''
		let alt = ''
		let shouldShowDialog = false

		if (userAgent === userAgentTypes.android) {
			url = androidAppDownloadUrl || DysiAppLinks.android
			src = mobileStyledLogoImageUrl ? mobileStyledLogoImageUrl : imageLinks.googlePlay
			alt = lang.getItOnGooglePlay
			shouldShowDialog =
				androidAppDownloadMode !== null && androidAppDownloadMode !== MobileAppDownloadMode.None
		} else if (userAgent === userAgentTypes.ios) {
			url = iosAppDownloadUrl || DysiAppLinks.ios
			src = mobileStyledLogoImageUrl ? mobileStyledLogoImageUrl : imageLinks.itunes
			alt = lang.downloadFromAppStore
			shouldShowDialog =
				iosAppDownloadMode !== null && iosAppDownloadMode !== MobileAppDownloadMode.None
		}

		this.setState({
			appDownloadDialogShown,
			url,
			src,
			alt,
			shouldShowDialog,
		})
	}

	componentDidMount() {
		const { androidAppDownloadMode, iosAppDownloadMode, enableMobileApps } = this.props
		const userAgent = this.getUserAgent()

		if (userAgent && !isSafariMobile && enableMobileApps) {
			const noAndroidDownloadModeYet =
				userAgent === userAgentTypes.android && androidAppDownloadMode === null
			const noIosDownloadModeYet = userAgent === userAgentTypes.ios && iosAppDownloadMode === null

			// Don't show the dialog if we don't have the download mode yet
			if (noAndroidDownloadModeYet || noIosDownloadModeYet) return true

			this.updateDialogState(userAgent)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { androidAppDownloadMode, iosAppDownloadMode, enableMobileApps } = this.props
		const userAgent = this.getUserAgent()

		if (userAgent && !isSafariMobile && enableMobileApps) {
			// Only show dialog once the download mode is made available
			const androidDownloadModeAvailableNow =
				prevProps.androidAppDownloadMode !== androidAppDownloadMode
			const iosDownloadModeAvailableNow = prevProps.iosAppDownloadMode !== iosAppDownloadMode

			if (androidDownloadModeAvailableNow || iosDownloadModeAvailableNow) {
				this.updateDialogState(userAgent)
			}
		}
	}

	handleClose = () => {
		const { onClose } = this.props

		this.setState(
			{
				appDownloadDialogShown: true,
			},
			onClose
		)

		if (localStorage && localStorage.getItem(localStorageKey) === null)
			localStorage.setItem(localStorageKey, new Date())
	}

	getUserAgent = () => {
		if (isAndroid) {
			return userAgentTypes.android
		} else if (isIos) {
			return userAgentTypes.ios
		}
	}

	render() {
		const { communityRegistrationCode, communityName, forceDisplay, enableMobileApps } = this.props
		const { appDownloadDialogShown, url, src, alt, shouldShowDialog } = this.state
		const isOpen =
			shouldShowDialog &&
			(forceDisplay || (communityName && !appDownloadDialogShown && url ? true : false))

		if (isSafariMobile || !enableMobileApps) return null

		return (
			<Dialog isOpen={isOpen} onClose={this.handleClose}>
				<div style={styles.container}>
					<h1>{lang.getApp}</h1>

					{/* Download Button */}
					<a href={url}>
						<img alt={alt} src={src} style={styles.image} />
					</a>

					<p>{lang.joinCommunity(communityName)}</p>
					<h2>{communityRegistrationCode}</h2>
				</div>
			</Dialog>
		)
	}
}

export default connect(mapStateToProps)(AppDownloadDialog)
