import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import UserNotification, {
	UserNotificationLayout,
} from 'components/UserNotification/UserNotification'
import Loading from 'components/Loading/Loading'

// Lang
import lang from './notificationList.lang'

// Styles
import { margin, padding } from 'styles/variables'

const styles = {
	loading: {
		marginTop: margin.basic,
	},
	noNotifications: {
		marginTop: margin.basic,
		paddingRight: padding.basic,
		paddingLeft: padding.basic,
	},
}

export const minimalNotificationCount = 10

const NotificationList = ({ currentUserId, isLoading, layout, notifications }) => {
	const { byId, ids } = notifications
	const isMinimal = layout === UserNotificationLayout.minimal
	// Only show 10 for minimal layout
	const filteredIds = isMinimal ? [...ids].splice(0, minimalNotificationCount) : ids

	return (
		<Fragment>
			{filteredIds.map(id => (
				<UserNotification
					layout={layout}
					notificationInfo={byId[id]}
					key={id}
					currentUserId={currentUserId}
				/>
			))}

			{isLoading &&
				!isMinimal && (
					<div style={styles.loading}>
						<Loading />
					</div>
				)}

			{!isLoading &&
				filteredIds.length === 0 && (
					<div style={styles.noNotifications}>{lang.noNotifications}</div>
				)}
		</Fragment>
	)
}

NotificationList.propTypes = {
	currentUserId: PropTypes.number,
	isLoading: PropTypes.bool,
	layout: PropTypes.string,
	notifications: PropTypes.shape({
		ids: PropTypes.array,
		byId: PropTypes.object,
	}),
}

export default NotificationList
