import Prefixer from 'inline-style-prefixer'
const prefixer = new Prefixer()

import { BreakpointNames } from 'wrappers/ResponsiveComponent'
import { flexContainer, fontSize, form, fontWeight, spacing } from 'styles/variables'

const styles = {
	container: {
		...flexContainer('column'),
		marginLeft: spacing.basic,
		marginTop: spacing.narrow,
		marginRight: spacing.basic,
	},
	editChannelButton: {
		alignSelf: 'flex-end',
		fontSize: fontSize.base,
		marginLeft: 'auto',
	},
	actionBar: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},
	postTranslationButton: {
		fontWeight: fontWeight.semiBold,
		fontSize: fontSize.base,
	},
	noAccountsContainer: {
		...flexContainer('column'),
		marginBottom: spacing.wide,
		marginLeft: 'auto',
	},
	noAccountsText: {
		fontWeight: fontWeight.semiBold,
		fontSize: fontSize.heading,
		marginBottom: spacing.narrow,
	},
	noAccountsButton: {
		...flexContainer('row', 'center', 'flex-start'),
		button: {
			height: form.input.height,
			borderRightWidth: 0,
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			paddingLeft: spacing.narrow,
			paddingTop: 0,
			paddingRight: spacing.narrow,
			paddingBottom: 0,
		},
		icon: {
			marginRight: spacing.narrow,
		},
		[BreakpointNames.small]: {
			marginTop: spacing.basic,
		},
	},
	form: {
		width: '100%',
		...flexContainer('column', 'center', 'flex-start'),
	},
	zeroState: {
		...flexContainer(),
		marginTop: spacing.basic,
		marginBottom: spacing.basic,
		icon: {
			marginRight: spacing.basic,
		},
		text: {
			cursor: 'pointer',
			fontSize: fontSize.base,
			fontWeight: fontWeight.semiBold,
		},
	},

	sharingDisclosures: {
		marginTop: spacing.narrow,
		marginBottom: spacing.narrow,
		fontSize: fontSize.small,
		text: {
			fontSize: fontSize.small,
			cursor: 'pointer',
		},
	},
	alert: {
		alignSelf: 'stretch',
		marginLeft: -(spacing.wide + spacing.basic),
		marginRight: -(spacing.wide + spacing.basic),
		[BreakpointNames.small]: {
			marginLeft: -spacing.wide,
			marginRight: -spacing.wide,
		},
	},
}

export default prefixer.prefix(styles)
