import { combineReducers } from 'redux'

// Dependency Reducers
import { routerReducer as routing } from 'react-router-redux'

// Our Reducers

import approvedContent from 'scenes/Settings/ShareSettings/approvedContent.reducer'
import auth from 'scenes/Auth/auth.reducer'
import broadcast from 'scenes/Broadcast/broadcast.reducer'
import browser from 'reducers/browser.reducer'
import category from 'scenes/Category/category.reducer'
import customPages from 'scenes/Content/customPages.reducer'
import errors from 'reducers/error.reducer'
import invite from 'scenes/Invite/invite.reducer'
import leaderboard from 'scenes/Leaderboards/leaderboard.reducer'
import directory from 'scenes/Directory/directory.reducer'
import memberUsageCompliance from 'scenes/MemberUsageCompliance/memberUsageCompliance.reducer'
import messages from 'scenes/Messages/message.reducer'
import myShares from 'scenes/Settings/ShareSettings/myShare.reducer'
import newsletter from 'scenes/Content/newsletter.reducer'
import notification from 'pages/Notifications/notification.reducer'
import post from 'scenes/Post/post.reducer'
import translations from 'components/TranslationButton/translationButton.reducer'
import savedItems from 'scenes/SavedItems/savedItems.reducer'
import setting from 'reducers/setting.reducer'
import sphere from 'reducers/sphere.reducer'
import survey from 'scenes/Survey/survey.reducer'
import typeahead from 'reducers/typeahead.reducer'

// Try to keep these sorted alphabetically
const reducers = combineReducers({
	approvedContent,
	auth,
	broadcast,
	browser,
	category,
	customPages,
	errors,
	invite,
	leaderboard,
	directory,
	memberUsageCompliance,
	messages,
	myShares,
	newsletter,
	notification,
	post,
	translations,
	routing,
	savedItems,
	setting,
	sphere,
	survey,
	typeahead,
})

export default reducers
