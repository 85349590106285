import React from 'react'

// Lang
import lang from './localStorage.lang'

// Components
import Layout, { Panel } from 'pages/Layouts/Layout'
import { width } from 'styles/variables'

// Style
const styles = {
	panel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		height: '100%',
		paddingTop: 0,
		paddingBottom: width.spacing.basic,
		paddingLeft: width.spacing.basic,
		paddingRight: width.spacing.basic,
	},
	text: {
		textAlign: 'center',
	},
}

// Page
class LocalStorage extends React.Component {
	render() {
		return (
			<Layout>
				<Panel>
					<div style={styles.panel}>
						<h1 style={styles.text}>{lang.title}</h1>
						<h3>{lang.mobileHeading}</h3>
						<div>{lang.mobileDetails}</div>
						<h3>{lang.desktopHeading}</h3>
						<div>{lang.desktopDetails}</div>
					</div>
				</Panel>
			</Layout>
		)
	}
}

export default LocalStorage
