import { combineReducers } from 'redux'

// Our Reducers
import scheduledShares from 'scenes/Settings/ScheduledShares/scheduledShares.reducer'
import notificationSettings from 'scenes/Settings/NotificationSettings/notificationSettings.reducer'

const reducers = combineReducers({
	scheduledShares,
	notificationSettings,
})

export default reducers
