import { safeGetNestedProp } from '@dysi/js-helpers'

// UserData represents a single user. Its properties are either native (returned directly from the API) or
// calculated. Please add any missing props or calculations you need below.
//   o Any calculations you want to do in a component, should be here, instead, to support consistency
//     and sharing across components
//   o Keep lists in alphabetical order!
/**
 * UserData represents a single user. Its properties are either native (returned directly from the API) or calculated.
 * @param {Object} user
 */
const UserData = user => {
	return {
		// ===========================================================================================
		// Native props - Alphabetical, please!
		// ===========================================================================================
		canCommentPosts: user.canCommentPosts,
		canSharePosts: user.canSharePosts,
		canSubmitPosts: user.canSubmitPosts,
		displayName: user.displayName,
		email: user.email,
		firstName: user.firstName,
		handle: user.handle,
		languages: user.languages,
		lastName: user.lastName,
		managerUserId: user.managerUserId,
		phoneNumber: user.phoneNumber,
		postsRequireApproval: user.postsRequireApproval,
		primaryLanguage: user.primaryLanguage,
		profileCompleted: user.profileCompleted,
		profilePictureImages: user.profilePictureImages,
		scheduleSettings: user.scheduleSettings,

		/**
		 * User's selected time zone preference. May be null.
		 * Use timeZone, instead, to guarantee a time zone value (falls back to sphere time zone in API)
		 */
		selectedTimeZone: user.selectedTimeZone,

		statistics: user.statistics,
		theme: user.theme,
		timeZone: user.timeZone,
		userId: user.id,
		username: user.username,
		welcomeBannerDismissed: user.welcomeBannerDismissed,

		// ===========================================================================================
		// Calculated props - Alphabetical, please!
		//   NOTE: Use these when the property isn't a 1:1 map with the API post object
		// ===========================================================================================
		affiliations: user.affiliations || [],
		canSetPostSharable: safeGetNestedProp(user, 'privileges.canSetPostShareable'),
		channels: user.channels || [],

		/**
		 * Default post sharing state. One of PostSharingState values
		 */
		defaultPostState: safeGetNestedProp(user, 'defaults.defaultPostState'),

		/**
		 * Default post creator info visibility. Simple boolean value.
		 */
		defaultPostShowCreatorInfo: safeGetNestedProp(user, 'defaults.defaultPostShowCreatorInfo'),

		/** Default accessibility of note to members for that particular user */
		defaultPostShowCreatorComments: safeGetNestedProp(
			user,
			'defaults.defaultPostShowCreatorComments'
		),

		displayLanguage: user.primaryLanguage || null,
		divisions: user.divisions || [],
		canEditPost: safeGetNestedProp(user, 'permissions.editPost', false),
		isManager: safeGetNestedProp(user, 'privileges.canManageCommunity', false),
		isSso: user.isSso || false,
		isEmailVerified: user.isEmailVerified || false,
		contentLanguages: user.languages || [],
		lastUpdate: Date.now(), // Freshness
		status: user.status || null,
		targets: user.targets || [],

		/**
		 * Remaining point balance. Will be null if this user isn't the logged in user
		 */
		unredeemedPoints: user.unredeemedPoints || null,
	}
}
export default UserData
