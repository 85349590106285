const lang = {
	abort: 'Abort',
	add: 'Add',
	addImage: 'Add an image: Recommended image dimensions are 640 x 420 or greater',
	addingWillReplaceAttachments: 'Adding this file will replace your existing attachments.',
	addLink: 'Add a link',
	addLinkImage: 'Add a linked image',
	addVideo: maxVideoSize => `Add a video: maximum video size is ${maxVideoSize} MB`, //  max size of video file in MB
	attach: 'Attach',
	attachLink: 'Attach a link?',
	attachLinkWarning: 'We only have space to display 250 characters for posts that contain links. If you attach this link, you will lose everything but the first 250 characters of text you\'ve entered.',
	back: 'Back',
	cancel: 'Cancel',
	cancelUploadWarning: 'Do you really want to cancel this upload?',
	cancelUpload: 'Cancel upload',
	categories: 'Categories',
	continueEditing: 'Continue Editing',
	descriptionTooLongError: 'Limit to 10,000 characters or less.',
	discard: 'Discard',
	discardWarning: 'You\'ll lose any changes you\'ve made.',
	discardYourPost: 'Discard your post?',
	duplicateError: 'This post already exists in the community. Please submit a different post.',
	duplicateWarning: 'A similar post already exists.',
	enterTitle: 'Enter a title - Required',
	enterDescription: 'Enter a description',
	enterDescriptionOrPasteLink: 'Enter a description or paste a link',
	enterOrPasteUrl: 'Enter or paste a URL',
	enterNote: 'Enter a note',
	error: error => `Error: ${error}`, //  error is the error message
	invalidUrl: 'The URL you entered is invalid.',
	needToSelectCategory: 'You need to select at least one category for your post',
	needToSelectTag: 'You need to select at least one tag for your post.',
	post: 'Post',
	posted: 'Posted',
	postNotSubmitted: 'The post could not be submitted',
	postNotSubmittedIE: 'Unfortunately, Internet Explorer 11 does not support uploading video files over 50 MB in size. Please use an alternative browser such as Chrome, Firefox, or Edge.',
	preview: 'Preview',
	noPreviewHasBeenGenerated: 'The website prevented us from importing this link, so no preview has been generated.',
	notePlaceholder: 'Let others know why this post is important',
	noteTooLongError: 'We only have space to display 250 characters for posts that contain links. To submit this post, please either remove the link preview or reduce the number of characters.',
	onlyPngGifJpg: 'Only .png, .gif or .jpg files are allowed.',
	replace: 'Replace',
	replaceExistingAttachments: 'Replace existing attachments?',
	required: '- Required',
	retry: 'Retry',
	selectCategory: 'Select a category...',
	selectTag: 'Select a tag...',
	shareable: 'Shareable',
	submit: 'Submit',
	submitPost: '+ Submit Post',
	submitted: 'Submitted',
	tags: 'Tags',
	titleIsRequired: 'Title is required.',
	uploadImageTooLarge: 'Upload images cannot be larger than 5MB. Please select a smaller file size.',
	uploadingVideo: 'Uploading video',
	uploadVideoTooLarge: maxVideoSize => `Uploaded videos cannot be larger than ${maxVideoSize}MB. Please select a smaller file size.`, //  maxVideoSize is the max size of video in MB
	uploadVideoTooLargeIE: 'Unfortunately, Internet Explorer 11 does not support uploading video files over 50 MB in size. Please use an alternative browser such as Chrome, Firefox, or Edge.',
	videoMustBeMp4orMov: 'Video must be a .mp4 or .mov.',
}

export default lang;