import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Style } from 'radium'

// Components
import Button, { ButtonLevel } from 'components/Button/Button'
import Dialog from 'components/Dialog/Dialog'
import HtmlText from 'components/HtmlText/HtmlText'

// Helpers
import { asyncSetAgreement } from 'scenes/MemberUsageCompliance/memberUsageCompliance.reducer'
import { safeGetNestedProp } from '@dysi/js-helpers'

// Styles
import { color, margin } from 'styles/variables'

const styles = {
	hr: {
		marginTop: margin.narrow,
		marginBottom: margin.basic,
		background: color.secondaryGrey,
		border: 0,
		height: '1px',
	},
	body: {
		marginTop: margin.narrow,
		marginBottom: margin.wide,
		minHeight: '200px',
		color: color.primaryBlack,
	},
	// CSS rules specific to the compliance gate bodies since users can specify their own HTML - different from normal styling
	contentRules: {
		a: {
			color: color.linkPrimary,
		},
		whiteSpace: 'pre-wrap',
	},
	//
}

const mapStateToProps = (state, ownProps) => {
	const user = state.auth.user
	const memberUsageCompliance = state.memberUsageCompliance
	const sharePost = safeGetNestedProp(state, 'post.sharePost', {})

	const {
		usageComplianceRequired,
		isPending,
		pageTitle,
		bodyText,
		buttonAgree,
		buttonDecline,
		pageTitleDecline,
		bodyTextDecline,
		buttonTryAgain,
	} = memberUsageCompliance

	return {
		user,
		usageComplianceRequired,
		isPending,
		pageTitle,
		bodyText,
		buttonAgree,
		buttonDecline,
		pageTitleDecline,
		bodyTextDecline,
		buttonTryAgain,
		sharePost,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setMemberUsageComplianceAgreement: accept => dispatch(asyncSetAgreement(accept)),
	}
}

class MemberUsageCompliance extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		usageComplianceRequired: PropTypes.bool.isRequired,
		pageTitle: PropTypes.string,
		bodyText: PropTypes.string,
		buttonAgree: PropTypes.string,
		buttonDecline: PropTypes.string,
		pageTitleDecline: PropTypes.string,
		bodyTextDecline: PropTypes.string,
		buttonTryAgain: PropTypes.string,
		isPending: PropTypes.bool,
		setMemberUsageComplianceAgreement: PropTypes.func,
		sharePost: PropTypes.object,
	}

	// Local state not in redux
	state = {
		isDecline: false,
	}

	handleAgree = e => {
		const { setMemberUsageComplianceAgreement, sharePost } = this.props
		setMemberUsageComplianceAgreement().then(() => {
			if (window.location.href.includes('/post')) {
				// prevents going out of app on refresh when closing MUC on the post details page
				window.location.href = `${window.location.origin}${
					window.location.pathname
				}?fromDialog=true`
			} else {
				// if share post was opened previously, let's reload with that open again.
				if (sharePost && sharePost.postId) {
					window.location.href = `${window.location.origin}${window.location.pathname}?sharePost=${
						sharePost.postId
					}`
				} else {
					window.location.reload()
				}
			}
		})
	}

	handleDecline = e => {
		const { setMemberUsageComplianceAgreement } = this.props
		setMemberUsageComplianceAgreement(false).then(() =>
			this.setState({
				isDecline: true,
			})
		)
	}

	handleTryAgain = e => {
		this.setState({
			isDecline: false,
		})
	}

	getGateButtons = (buttonAgree, buttonDecline, disableButtons) => {
		return (
			<div>
				<Button
					disabled={disableButtons}
					level={ButtonLevel.secondary}
					onClick={this.handleDecline}
				>
					{buttonDecline}
				</Button>
				<Button disabled={disableButtons} onClick={this.handleAgree}>
					{buttonAgree}
				</Button>
			</div>
		)
	}

	getTryAgainButton = (buttonTryAgain, disableButtons) => {
		return (
			<div>
				<Button disabled={disableButtons} onClick={this.handleTryAgain}>
					{buttonTryAgain}
				</Button>
			</div>
		)
	}

	render() {
		const {
			usageComplianceRequired,
			user,
			pageTitle,
			bodyText,
			buttonAgree,
			buttonDecline,
			pageTitleDecline,
			bodyTextDecline,
			buttonTryAgain,
			isPending,
		} = this.props

		if (!user || !usageComplianceRequired) return null

		const gateButtons = this.getGateButtons(buttonAgree, buttonDecline, isPending)
		const tryAgainButton = this.getTryAgainButton(buttonTryAgain, isPending)

		return (
			<div>
				{/* Gate dialog */}
				<Dialog
					isOpen={!this.state.isDecline}
					title={pageTitle}
					submitButtons={gateButtons}
					shouldCloseOnOverlayClick={false}
				>
					<hr style={styles.hr} />
					<div style={styles.body} id="compliance-body">
						<Style scopeSelector="#compliance-body" rules={styles.contentRules} />
						<HtmlText config={{ ADD_ATTR: ['target'] }}>{bodyText}</HtmlText>
					</div>
					<hr style={styles.hr} />
				</Dialog>

				{/* Decline dialog */}
				<Dialog
					isOpen={this.state.isDecline}
					title={pageTitleDecline}
					submitButtons={tryAgainButton}
					shouldCloseOnOverlayClick={false}
				>
					<hr style={styles.hr} />
					<div style={styles.body} id="decline-body">
						<Style scopeSelector="#decline-body" rules={styles.contentRules} />
						<HtmlText config={{ ADD_ATTR: ['target'] }}>{bodyTextDecline}</HtmlText>
					</div>
					<hr style={styles.hr} />
				</Dialog>
			</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MemberUsageCompliance)
