import React from 'react'
import PropTypes from 'prop-types'
import SocialIcon from 'components/SocialIcon/SocialIcon'

// Components
import UserPhoto from 'components/UserPhoto/UserPhoto'
import UserPhotoSize from 'components/UserPhoto/userPhotoSize'

// Shared
import Icon, { Name } from 'components/Icons/FontAwesome'
import { SocialIconSize } from 'components/SocialIcon/SocialIcon'

// Lang
import lang from './userChannel.lang'

// Helpers
import KeyNames from 'helpers/keyNames'

// Styles
import {
	color,
	fontSize,
	fontWeight,
	border,
	width,
	lineHeight,
	borderStyle,
	textButtonStyle,
} from 'styles/variables'
import withTheme, { Theme, ThemeValuePropType } from 'wrappers/ThemeWrapper'
import { textEllipsis } from 'styles/defaults'
import { safeGetNestedProp } from '@dysi/js-helpers'

const styles = {
	base: {
		display: 'flex',
		alignItems: 'center',
		width: 250,
		height: 51,
		backgroundColor: color.white,
		border: border.basic,
		borderRadius: border.radius,
		disabled: {
			backgroundColor: color.bgPage,
		},
	},
	notConnected: {
		cursor: 'pointer',
	},
	text: {
		marginLeft: width.spacing.basic,
	},
	channelProvider: {
		display: 'flex',
		fontSize: fontSize.small,
		fontWeight: fontWeight.semiBold,
		lineHeight: lineHeight.close,
	},
	channels: {
		fontWeight: fontWeight.bold,
		disabled: {
			color: color.primaryGrey,
		},
	},
	channelName: {
		display: 'inline-block',
		maxWidth: 150,
		...textEllipsis,
	},
	actions: {
		lineHeight: lineHeight.close,
	},
	actionLink: {
		...textButtonStyle,
		color: color.primaryGrey,
		fontSize: fontSize.small,
	},
	bullet: {
		fontSize: 8,
		paddingLeft: '4px',
		paddingRight: '4px',
	},
	profilePhoto: {
		marginRight: 7,
	},
	imageContainer: {
		display: 'flex',
		marginTop: 8,
		marginRight: 0,
		marginBottom: 8,
		marginLeft: 8,
	},
	icon: {
		disabled: {
			color: color.secondaryGrey,
		},
	},
	reauthIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: color.secondaryRed,
		background: color.secondaryGrey,
		borderWidth: width.border.basic,
		borderStyle: borderStyle.solid,
		borderColor: color.secondaryRed,
		borderRadius: border.radius,
		fontSize: '20px',

		width: UserPhotoSize.small.width + 6,
		height: UserPhotoSize.small.width + 6,
	},
	reauthFloatingIcon: {
		position: 'relative'
	},
	reauthSocialProvider: {
		position: 'absolute',
		right: -width.spacing.narrow,
		bottom: -width.spacing.narrow / 2,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		lineHeight: 1,
		backgroundColor: color.white,
		borderRadius: border.radius,
		width: width.icon.flag,
		height: width.icon.flag,
	},
}

const injectedThemeValues = [Theme.PrimaryColor]

class UserChannel extends React.Component {
	static propTypes = {
		provider: PropTypes.object.isRequired,
		userChannels: PropTypes.array,
		onDisconnect: PropTypes.func,
		onAddChannel: PropTypes.func,
		onClick: PropTypes.func,
		onChannelIconClick: PropTypes.func,
		hideActions: PropTypes.bool, // Don't show Disconnect * Add buttons
		showChannelProfilePics: PropTypes.bool, // Show user channel profile picture(s) instead of provider icon
		disabled: PropTypes.bool,
		style: PropTypes.object,
		fromShareDialog: PropTypes.bool,
		needsReauth: PropTypes.bool,
		socialChannelRenewalEnabled: PropTypes.bool,

		// Injected
		...ThemeValuePropType(injectedThemeValues),
	}

	handleClick = () => {
		const { hideActions, onAddChannel, userChannels } = this.props

		const hasChannels = userChannels && userChannels.length > 0
		const onClick = this.props.onClick || (hasChannels && !hideActions ? null : onAddChannel)

		if (onClick) {
			onClick()
		}
	}

	handleKeyDown = e => {
		if (e.key === KeyNames.enter || e.key === KeyNames.space) {
			this.handleClick()
		}
	}

	handleAddClick = e => {
		const { onAddChannel } = this.props
		if (onAddChannel) onAddChannel(e)
	}

	handleDisconnectClick = e => {
		const { onDisconnect } = this.props

		if (onDisconnect) onDisconnect(e)
	}

	getReauthFloatingIconStyle(socialChannelRenewalEnabled, canonicalName) {
		if (socialChannelRenewalEnabled && canonicalName === 'LinkedIn') {
			return {
				...styles.reauthFloatingIcon,
				cursor: 'pointer'
			};
		} else {
			return {
				...styles.reauthFloatingIcon,
				cursor: 'auto'
			};
		}
	}

	render() {
		const {
			provider,
			userChannels,
			onDisconnect,
			hideActions,
			showChannelProfilePics,
			disabled,
			primaryColor,
			style,
			needsReauth,
			onChannelIconClick,
			socialChannelRenewalEnabled
		} = this.props

		const hasChannels = !!userChannels && userChannels.length > 0
		let firstChannelName = hasChannels && userChannels[0].displayName
		if (firstChannelName && provider.canonicalName === 'Twitter')
			firstChannelName = `@${firstChannelName}`

		// NOTE: For now, we are only using the first profile picture image, if requested and available
		const showChannelProfilePicture =
			showChannelProfilePics &&
			hasChannels &&
			safeGetNestedProp(userChannels[0], 'profilePictureImages.Square40.url')
		const firstChannelProfilePictureImages =
			showChannelProfilePicture && userChannels[0].profilePictureImages

		// Styles
		const dynamicStyles = {
			base: {
				...styles.base,
				...(hasChannels ? {} : styles.notConnected),
				...(disabled ? styles.base.disabled : {}),
				...style,
			},
			icon: {
				...styles.icon,
				...(disabled ? styles.icon.disabled : {}),
			},
			channels: {
				...styles.channelProvider,
				color: primaryColor,
				...(onDisconnect ? { cursor: 'pointer' } : {}),
				...(disabled ? styles.channels.disabled : {}),
			},
			providerName: {
				...styles.channelProvider,
				...styles.providerName,
				color: primaryColor,
			},
		}

		if (socialChannelRenewalEnabled && provider.canonicalName === 'LinkedIn' && disabled) {
			dynamicStyles.base = {
				...dynamicStyles.base,
				border: `solid 1px ${primaryColor}`
			}
		}

		const firstChannelDisplay = firstChannelName.length > 20 ? firstChannelName : null

		return (
			<div
				data-component="UserChannel"
				style={dynamicStyles.base}
				onClick={this.handleClick}
				onKeyDown={this.handleKeyDown}
				tabIndex={hasChannels ? -1 : 0}
				role="link"
				className="accessible-focus"
				aria-label={provider.canonicalName}
			>
				<div style={styles.imageContainer}>
					{needsReauth ? (
						<div style={this.getReauthFloatingIconStyle(socialChannelRenewalEnabled, provider.canonicalName)} onClick={socialChannelRenewalEnabled && provider.canonicalName === 'LinkedIn' ? onChannelIconClick : null}>
							<Icon style={styles.reauthIcon} name={Name.exclamation} />
							<SocialIcon
								style={styles.reauthSocialProvider}
								provider={provider.canonicalName}
								size={SocialIconSize.flag}
								disabled={disabled}
							/>
						</div>
					) : showChannelProfilePicture ? (
						<UserPhoto
							size={UserPhotoSize.small.name}
							photos={firstChannelProfilePictureImages}
							disabled={disabled}
							style={styles.profilePhoto}
						/>
					) : (
						<SocialIcon
							provider={provider.canonicalName}
							style={dynamicStyles.icon}
							disabled={disabled}
						/>
					)}
				</div>

				{hasChannels ? (
					<div style={styles.text}>
						<div style={dynamicStyles.channels}>
							<span
								style={styles.channelName}
								title={firstChannelDisplay}
								aria-label={firstChannelDisplay}
								onClick={onDisconnect}
							>
								{firstChannelName}
							</span>
							{userChannels.length > 1 && (
								<span onClick={onDisconnect}>, +{userChannels.length - 1}</span>
							)}
						</div>
						{!hideActions && (
							<div style={styles.actions}>
								<button
									style={styles.actionLink}
									onClick={this.handleDisconnectClick}
									role="link"
									aria-label={provider.canonicalName + lang.disconnect}
								>
									{lang.disconnect}
								</button>
								<span style={styles.bullet}>•</span>
								<button
									style={styles.actionLink}
									onClick={this.handleAddClick}
									role="link"
									aria-label={provider.canonicalName + lang.add}
								>
									{lang.add}
								</button>
							</div>
						)}
					</div>
				) : (
					<div style={styles.text}>
						<div style={dynamicStyles.providerName}>{provider.displayName === "Twitter" ? "X" : provider.displayName}</div>
					</div>
				)}
			</div>
		)
	}
}

export default withTheme(UserChannel, injectedThemeValues)
