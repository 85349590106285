import { clearUserAndRedirect } from './userHelpers'
import Routes from 'helpers/routes'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { Settings } from 'wrappers/SettingsWrapper'

// Sync tokens between old Member App and new Member App (this app)

const dySiAppLogoutKey = 'voicestorm.com:logout-event' // Includes Manager App
const syncTimeout = 1000 // Short delay when syncing logins between apps

let redirectTimer // IE workaround to avoid reloading ManagerApp before logout happens
const delayedClearUserAndRedirect = (dispatch, redirectUrl) => {
	if (redirectTimer) clearTimeout(redirectTimer)
	redirectTimer = setTimeout(() => {
		clearUserAndRedirect(dispatch, redirectUrl)
	}, syncTimeout)
}

export const watchLegacyToken = (getState, dispatch) => {
	window.addEventListener('storage', function(
		event // storage event docs: https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent (varies slightly by browser)
	) {
		// Get current State from the Redux store
		const state = getState()

		// Does this browser support local storage?
		try {
			if (event && event.storageArea === window.localStorage) {
				// we have an event on localStorage
				// Watch for VoiceStorm logout from other apps
				const isUserLoggedIn = Settings.User.IsLoggedIn.value(state)
				if (isUserLoggedIn && event.key === dySiAppLogoutKey) {
					// User clicked 'Sign Out' from somewhere else
					// clear any data unique to this tab since we just logged off
					try {
						window.sessionStorage.clear()
					} catch (error) {
						// Eat error for now, previously logged to Sentry
					}

					const isSso = safeGetNestedProp(state, 'auth.user.isSso') || false
					const redirectUrl = isSso ? state.sphere.home + Routes.SignOut.linkPath : ''
					delayedClearUserAndRedirect(dispatch, redirectUrl)
				}
			}
		} catch (error) {
			// Eat error for now, previously logged to Sentry
		}
	})
}
