import { RestrictText } from 'components/RestrictedText/RestrictedText'
import Restrictions from 'helpers/textRestrictions'
import SocialProviderData from 'helpers/socialProviderData'
import { getOriginalImage } from 'helpers/postHelpers'

export const VideoRoles = {
	hostedVideo: 'video-hosted',
	advancedVideo: 'video-advanced-hosted',
	originalVideo: 'video,original',
}

const restrictTitle = content => {
	// Restricted Text for Newsletter Title
	const parser = new DOMParser()
	const doc = parser.parseFromString(content, 'text/html')
	const td = doc.querySelector('.default-header-content')
	const header = td && td.querySelector('strong')
	if (header && header.innerText) {
		header.innerText = RestrictText(header.innerText, Restrictions.NewsletterTitle).output
		// parse back to string
		content = doc.querySelector('html').innerHTML
	}
	return content
}

const cleanHtml = content => {
	if (!content) return
	// Removes width and height from attribute and styles in image tags only
	const imgTagRgx = /<img([\w\W]+?)>/g
	const iframeTagRgx = /<iframe([\w\W]+?)>/g
	const imgMatches = content.match(imgTagRgx)
	const widthStyleRegex = /width: \d+(?:px)/
	const widthAttrRegex = /width="\d+"/
	const heightAttrRegex = /height="\d+"/
	const heightStyleRegex = /width: \d+(?:px)/

	// Strip out width / height of images / iframe
	if (imgMatches) {
		imgMatches.forEach(match => {
			const replacement = match
				.replace(widthStyleRegex, '')
				.replace(widthAttrRegex, '')
				.replace(heightStyleRegex, '')
				.replace(heightAttrRegex, '')
			content = content.replace(match, replacement)
		})
	} else {
		const iframeMatches = content.match(iframeTagRgx)
		if (iframeMatches) {
			iframeMatches.forEach(match => {
				// Make iframe responsive https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
				let replacement = match
					.replace(/'/g, '"')
					.replace(widthAttrRegex, 'style="position:absolute;top:0;left:0;width:100%;height:100%"')
					.replace(heightAttrRegex, '')
				replacement = `<div style="position:relative;width:100%;height:0;padding-bottom:56.25%">${replacement}</div>`
				content = content.replace(match, replacement)
			})
		}
	}

	return content
}

const getMedia = media => {
	if (!media) return

	const youtubeMedia = media.find(
		x => x.provider === SocialProviderData.YouTube.provider && x.role === VideoRoles.originalVideo
	)
	if (youtubeMedia) {
		return {
			...youtubeMedia,
			html: cleanHtml(youtubeMedia.html),
		}
	}

	return media.find(x => x.role === VideoRoles.hostedVideo || x.role === VideoRoles.advancedVideo)
}

const formatSections = sections => {
	return sections.map(section => {
		// Build a custom data object from the result
		const data = {
			...section,
		}

		// Clean-up, simplify complex props
		if (section.media) data.media = getMedia(section.media)
		data.content = section.content ? restrictTitle(cleanHtml(section.content)) : ''
		if (section.images) {
			data.image = getOriginalImage(section.images)
			delete data.images
		}

		return data
	})
}

const ContentData = content => {
	return {
		id: content.id,
		slug: content.slug,
		title: content.title,
		name: content.name,
		sections: formatSections(content.sections),
		links: content.links,
		scriptExecutionToken: content.scriptExecutionToken,
	}
}

export default ContentData
