'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'jedi';
var width = 576;
var height = 512;
var ligatures = [];
var unicode = 'f669';
var svgPathData = ['M226.67865,285.84578A7.99806,7.99806,0,0,1,238.039,275.15266l30.391,20.64669L279.99046,7.99485a8.00028,8.00028,0,0,1,16.00055,0L307.5214,295.73939l30.351-20.58673a7.99754,7.99754,0,0,1,11.38039,10.70309L329.12214,319.604l42.07144,8.72438a7.99376,7.99376,0,0,1,0,15.64992l-42.07144,8.71438L349.23281,386.401a7.99085,7.99085,0,0,1-1.34,9.91362,8.24461,8.24461,0,0,1-10.00034.79948L310.72149,378.656l2.27007,63.26922a123.48764,123.48764,0,0,1-22.07076,1.94876h-6.37022a123.4657,123.4657,0,0,1-21.62073-1.94876l2.29007-63.3092L238.049,397.07407a8.34473,8.34473,0,0,1-10.00034-.74951,7.9909,7.9909,0,0,1-1.39005-9.9336l20.13069-33.74824-42.07142-8.71439a7.99375,7.99375,0,0,1,0-15.64991l42.07144-8.72439Z', 'M559,224.18551c.37,5.75628,1,11.45262,1,17.25888a269.158,269.158,0,0,1-5.13018,51.82659l-58.872,58.83208h40.00136C493.36773,446.21239,398.35448,512,287.99073,512q-6.39022,0-12.85044-.28982c-104.00354-4.76692-193.8866-69.43523-235.158-159.60712H79.91365l-58.602-58.56226a262.66174,262.66174,0,0,1-5.11017-39.37462,271.838,271.838,0,0,1,.5-29.98067H63.9131L22.53169,182.83215A269.557,269.557,0,0,1,130.53537,20.86654a16.00788,16.00788,0,0,1,9.27032-2.99807,16.35349,16.35349,0,0,1,13.48046,7.24533,15.98047,15.98047,0,0,1,1.62005,15.08028,138.20862,138.20862,0,0,0-9.84033,51.227c0,45.09094,21.00071,86.52423,57.712,113.66674a16.28373,16.28373,0,0,1,1.21,25.37364c-26.55091,23.98455-41.1714,56.4636-41.1714,91.521a123.12,123.12,0,0,0,19.76067,67.0168c22.25075,34.4578,60.92207,54.7947,102.00349,54.86463h6.37021c48.67164.08994,93.33319-28.3717,112.38383-73.13285a119.64467,119.64467,0,0,0,7.22025-23.265A123.37031,123.37031,0,0,0,371.9936,230.48145a16.31451,16.31451,0,0,1,1.21-25.41363c36.66125-27.14251,57.692-68.56581,57.692-113.65675a138.03962,138.03962,0,0,0-9.90033-51.27695,15.98051,15.98051,0,0,1,1.61-15.08028,16.35347,16.35347,0,0,1,13.49045-7.23533,16.00784,16.00784,0,0,1,9.25031,2.99806,271.03544,271.03544,0,0,1,107.9037,162.15548l-41.25141,41.21346Z'];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faJedi = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;