/*
	FONTAWESOME ICON DEFINITIONS
	- LIGHT VERSION -
*/

/*eslint no-unused-vars: "error"*/

// Please try to keep this sorted alphabetically

// NOTE: Switch this to regular imports when webpack 4 upgrades it's minifier. https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking#issues

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faSnowflake } from '@fortawesome/pro-light-svg-icons/faSnowflake'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'

export default {
	faTimes,
	faSnowflake,
	faAngleLeft,
	faAngleRight,
}
