/*
	FONTAWESOME ICON DEFINITIONS
	- SOLID VERSION -
*/

/*eslint no-unused-vars: "error"*/

// Please try to keep this sorted alphabetically

// NOTE: Switch this to regular imports when webpack 4 upgrades it's minifier. https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking#issues

import { faAlignJustify } from '@fortawesome/pro-solid-svg-icons/faAlignJustify'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { faArrowAltFromBottom } from '@fortawesome/pro-solid-svg-icons/faArrowAltFromBottom'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { faBirthdayCake } from '@fortawesome/pro-solid-svg-icons/faBirthdayCake'
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold'
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark'
import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons/faBullseyeArrow'
// import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn'
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons/faChevronCircleRight'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch'
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons/faClipboardList'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment'
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faCompressAlt } from '@fortawesome/pro-solid-svg-icons/faCompressAlt'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExpandAlt } from '@fortawesome/pro-solid-svg-icons/faExpandAlt'
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages'
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faLongArrowDown } from '@fortawesome/pro-solid-svg-icons/faLongArrowDown'
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt'
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faRss } from '@fortawesome/pro-solid-svg-icons/faRss'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack'
import { faTv } from '@fortawesome/pro-solid-svg-icons/faTv'
import { faTvRetro } from '@fortawesome/pro-solid-svg-icons/faTvRetro'
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faVideo } from '@fortawesome/pro-solid-svg-icons/faVideo'

import { faUrgent } from '@dysi/fontawesome-dysi-custom'

export default {
	faAlignJustify,
	faAngleLeft,
	faAngleRight,
	faArrowAltFromBottom,
	faBars,
	faBell,
	faBirthdayCake,
	faBold,
	faBookmark,
	faBullseyeArrow,
	// faBullhorn,
	faCamera,
	faCaretDown,
	faCheck,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle, // needs a regular counterpart
	faCircleNotch,
	faClipboardList,
	faCopy,
	faCog,
	faComment,
	faComments,
	faCompressAlt,
	faDesktop,
	faEdit,
	faEllipsisH,
	faEnvelope,
	faExclamation,
	faExclamationCircle,
	faExpandAlt,
	faFile,
	faGlobe,
	faImage,
	faImages,
	faItalic,
	faLink,
	faLock,
	faLongArrowDown,
	faMobileAlt,
	faPause,
	faPencil,
	faPhone,
	faPlay,
	faPlus,
	faQuestion,
	faRss,
	faSearch,
	faShare,
	faSpinner,
	faSquare,
	faStar,
	faThumbsUp,
	faThumbtack,
	faTv,
	faTvRetro,
	faUnderline,
	faUrgent,
	faUserCircle,
	faVideo,
}
