import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Router
import { withRouter, Link } from 'react-router-dom'
import Routes from 'helpers/routes'

// Lang
import lang from './SideMenu.lang'

// Components
import SubmitPostButton from 'scenes/SubmitPost/components/SubmitPostButton'
import UserPhoto from 'components/UserPhoto/UserPhoto'
import UserPhotoSize from 'components/UserPhoto/userPhotoSize'
import Section from './Section'
import SearchPosts from 'scenes/Category/SearchPosts'
import Icon, { Name } from 'components/Icons/FontAwesome'

// Helpers
import { FormatNumber } from 'helpers/numberHelpers'
import { signOutUser, isDySiTeamMember } from 'helpers/userHelpers'
import { Settings } from 'wrappers/SettingsWrapper'
import { safeGetSetting } from 'wrappers/Settings'
import { isAtLeast, breakpoints } from 'helpers/breakpoints'

//region Styling
import { color, border, constants, fontSize, padding } from 'styles/variables'
import { safeGetNestedProp } from '@dysi/js-helpers'
import { isSafariMobile } from 'helpers/browserhelper'
import QuickLinks from 'scenes/CustomLinks/QuickLinks'

export const memberHelpUrl =
	'https://support.dynamicsignal.com/hc/en-us/categories/360000076612-Member-Guide '

// http://vutran.github.io/react-offcanvas/#
// https://tympanus.net/Development/SidebarTransitions/
const styles = {
	base: {
		width: '100%',
		borderBottom: border.basic,
		borderRight: border.basic,
		borderLeft: border.basic,
		backgroundColor: color.white,
		flexDirection: 'column',
		position: 'absolute',
		right: 0,
		overflowY: 'auto',
		minWidth: 320,
		zIndex: 100,

		// Mobile scrolling
		overflowScrolling: 'touch',
		WebkitOverflowScrolling: 'touch',
	},
	item: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		lineHeight: 2.5,
		flex: 1,
		cursor: 'pointer',
	},
	sublist: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingLeft: 20,
		lineHeight: 2.5,
		flex: 1,
	},
	subheading: {
		textTransform: 'uppercase',
		color: color.primaryGrey,
	},
	activeText: {
		color: color.primaryGrey,
	},
	unread: {
		color: color.secondaryRed,
		fontSize: fontSize.title,
		fontWeight: 'normal',
		lineHeight: 1,
	},
	badgedItem: {
		justifyContent: 'space-between',
		paddingRight: padding.wide,
	},
	noBottomBorder: {
		borderBottom: 'none',
	},
	noTopBorder: {
		borderTop: 'none',
	},
	searchPosts: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	quickLinks: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		paddingBottom: 0,
		borderBottom: 'none',
	},
	home: {
		borderTop: border.basic,
	},
}
export const ItemStyle = {
	...styles.item,
}
export const ActiveItemStyle = {
	...styles.item,
	...styles.activeText,
}
export const SubheadingStyle = {
	...styles.item,
	...styles.subheading,
}
export const SublistStyle = {
	...styles.sublist,
}
//endregion

// Exports
export const PortalMyContentId = 'my-content-menu'
export const PortalLeaderboardsId = 'leaderboards-menu'
export const PortalSettingsId = 'settings-menu'
export const PortalRelatedLinksId = 'related-links-menu'

// Redux
const mapStateToProps = (state, ownProps) => {
	const viewport = safeGetNestedProp(state, 'browser.viewport')
	const isOpen = safeGetNestedProp(state, 'browser.sideMenuOpen')
	const isCustomLinksHeaderVisible =
		safeGetNestedProp(state, 'sphere.customLinks.header.length', 0) > 0
	const isManager = safeGetSetting(state, Settings.User.IsManager)
	const enableLeaderboards = safeGetSetting(state, Settings.Features.EnableLeaderboards)
	const enableMemberProfiles = safeGetSetting(state, Settings.Features.EnableMemberProfiles)
	const enableNewMemberInvitations = safeGetSetting(
		state,
		Settings.Features.EnableNewMemberInvitations
	)
	const enableUserDirectory = safeGetSetting(state, Settings.Features.EnableUserDirectory)
	const enableDownloadPage = safeGetNestedProp(state, 'sphere.parameters.showDesktopAppLinks')
	const enableBetaLink = isDySiTeamMember(state)
	const userCanSubmitContent = safeGetSetting(state, Settings.User.CanSubmitContent)
	const userDisplayName = safeGetNestedProp(state, Settings.User.DisplayName.path)
	const userIsLoggedIn = safeGetSetting(state, Settings.User.IsLoggedIn)
	const currentUserId = safeGetSetting(state, Settings.User.ID)
	const userProfilePictures = safeGetSetting(state, Settings.User.ProfilePictures)
	const newNotificationCount = safeGetNestedProp(state, 'notification.count.totals.New')
	const unreadConvoCount = safeGetNestedProp(state, 'messages.unreadConversations')
	const quickLinks = safeGetNestedProp(state, 'sphere.customLinks.quickLinksWeb')

	let unredeemedPoints
	// It should be safe to retrieve this if we have a valid user
	if (enableLeaderboards && userIsLoggedIn) {
		const safeUnredeemedPoints = safeGetNestedProp(state, 'auth.user.unredeemedPoints')
		unredeemedPoints = !safeUnredeemedPoints ? 0 : safeUnredeemedPoints
	}

	return {
		// Viewport related
		isOpen,
		viewport,
		isCustomLinksHeaderVisible,

		// Settings
		enableLeaderboards,
		enableMemberProfiles,
		enableNewMemberInvitations,
		enableUserDirectory,
		enableDownloadPage,
		enableBetaLink,
		isManager,
		userCanSubmitContent,
		userDisplayName,
		userIsLoggedIn,
		currentUserId,
		userProfilePictures,
		unredeemedPoints,
		newNotificationCount,
		unreadConvoCount,
		quickLinks,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSignOut(e) {
			e.preventDefault()
			signOutUser(dispatch)
		},
	}
}

// Component
class SideMenu extends React.Component {
	static propTypes = {
		currentUserId: PropTypes.number,
		enableLeaderboards: Settings.Features.EnableLeaderboards.propType,
		enableMemberProfiles: Settings.Features.EnableMemberProfiles.propType,
		enableNewMemberInvitations: Settings.Features.EnableNewMemberInvitations.propType,
		enableUserDirectory: Settings.Features.EnableUserDirectory.propType,
		enableDownloadPage: PropTypes.bool,
		enableBetaLink: PropTypes.bool,
		history: PropTypes.object,
		isCustomLinksHeaderVisible: PropTypes.bool,
		isOpen: PropTypes.bool.isRequired,
		isManager: Settings.User.IsManager.propType,
		newNotificationCount: PropTypes.number,
		onSignOut: PropTypes.func.isRequired,
		unreadConvoCount: PropTypes.number,
		quickLinks: PropTypes.array,
		unredeemedPoints: PropTypes.number,
		userCanSubmitContent: Settings.User.CanSubmitContent.propType,
		userDisplayName: Settings.User.DisplayName.propType,
		userIsLoggedIn: Settings.User.IsLoggedIn.propType,
		userProfilePictures: Settings.User.ProfilePictures.propType,
		viewport: PropTypes.object,
	}

	getItemLink = (route, children) => (
		<Link to={route} style={ItemStyle}>
			{children}
		</Link>
	)

	render() {
		const {
			// Settings
			currentUserId,
			enableLeaderboards,
			enableMemberProfiles,
			enableNewMemberInvitations,
			enableUserDirectory,
			enableDownloadPage,
			enableBetaLink,
			isCustomLinksHeaderVisible,
			isManager,
			isOpen,
			newNotificationCount,
			unreadConvoCount,
			unredeemedPoints,
			userCanSubmitContent,
			userDisplayName,
			userIsLoggedIn,
			userProfilePictures,
			viewport,
			history,
			quickLinks,

			// Functions
			onSignOut,
		} = this.props

		const isNarrowScreen = !isAtLeast(breakpoints.large.name, viewport.size)
		const profileRoute =
			history.location.pathname === Routes.User.linkPath
				? Routes.User.Profile.linkPath
				: Routes.User.Profile.dialogPath
		const offsetHeight =
			constants.HeaderHeight + 2 + (isCustomLinksHeaderVisible ? constants.CustomLinksHeight : 0)

		const dynamicStyles = {
			base: {
				...styles.base,
				...(!isOpen
					? {
							// Hide and move it when it isn't 'OPEN'
							top: `calc((100vh - ${offsetHeight + (isSafariMobile ? 112 : 0)}px) * -1)`,
							visibility: 'hidden',
					  }
					: {
							top: offsetHeight,
					  }),
				height: `calc(100vh - ${offsetHeight + (isSafariMobile ? 112 : 0)}px)`,
			},
			badgedItem: {
				...ItemStyle,
				...styles.badgedItem,
			},
			quickLinks: {
				...styles.quickLinks,
				...(!userIsLoggedIn && styles.noTopBorder),
			},
			signIn: {
				...styles.noBottomBorder,
				...((!quickLinks || quickLinks.length === 0) && styles.noTopBorder),
			},
		}

		return (
			<div style={dynamicStyles.base}>
				{userIsLoggedIn && (
					<Fragment>
						{/* Search Post Titles */}
						<Section
							forceBorders={!enableMemberProfiles && !userCanSubmitContent}
							style={styles.searchPosts}
						>
							<SearchPosts />
						</Section>

						{/* User Info - Only show if profiles are enabled */}
						{enableMemberProfiles && (
							<Section forceBorders style={styles.noBottomBorder}>
								<Link to={profileRoute} style={{ ...ItemStyle, lineHeight: 1 }}>
									<UserPhoto
										size={UserPhotoSize.tiny.name}
										id={currentUserId}
										photos={userProfilePictures}
									/>
									{userDisplayName ? lang.userProfile(userDisplayName) : lang.userProfileDefault}
								</Link>
							</Section>
						)}

						{/* Post Submission */}
						{userCanSubmitContent &&
							isNarrowScreen && (
								<Section forceBorders style={styles.noBottomBorder}>
									<SubmitPostButton />
								</Section>
							)}
					</Fragment>
				)}

				{/* Quick Links */}
				{quickLinks &&
					quickLinks.length > 0 && (
						<Section forceBorders style={styles.quickLinks}>
							<QuickLinks isSideMenu={true} />
						</Section>
					)}

				{/* Sign In */}
				{!userIsLoggedIn && (
					<Section forceBorders style={styles.noBottomBorder}>
						<Link to={Routes.SignIn.generateLinkPath(null, window.location)} style={ItemStyle}>
							{lang.signIn}
						</Link>
					</Section>
				)}

				{/* Related Links */}
				<div id={PortalRelatedLinksId} />

				{/* Home / My Content */}
				<Section routes={[Routes.Home.routerPath, Routes.Category.routerPath]} style={styles.home}>
					<div>
						<Link to={Routes.Home.linkPath} style={ItemStyle}>
							{lang.home}
						</Link>
						{/* Portal - My Content */}
						<div id={PortalMyContentId} style={SublistStyle} />
					</div>
				</Section>

				{userIsLoggedIn && (
					<Fragment>
						{/* Misc Stuff */}
						<Section>
							<Link to={Routes.Notifications.linkPath} style={dynamicStyles.badgedItem}>
								{lang.notifications}
								{newNotificationCount > 0 && (
									<Icon
										name={Name.circle}
										text={`${newNotificationCount}`}
										style={styles.unread}
										transform={{ size: 9 }}
									/>
								)}
							</Link>
							<Link to={Routes.Messages.linkPath} style={dynamicStyles.badgedItem}>
								{lang.messages}
								{unreadConvoCount > 0 && (
									<Icon
										name={Name.circle}
										text={`${unreadConvoCount}`}
										style={styles.unread}
										transform={{ size: 9 }}
									/>
								)}
							</Link>
							{/* <Link to={Routes.Broadcasts.linkPath} style={ItemStyle}>{lang.broadcasts}</Link> */}
						</Section>

						{/* Leaderboards */}
						{enableLeaderboards && (
							<Section routes={[Routes.Leaderboards.routerPath]}>
								<div>
									<Link to={Routes.Leaderboards.linkPath} style={ItemStyle}>
										{unredeemedPoints === 1
											? lang.pointOneValue
											: lang.pointValue(FormatNumber(unredeemedPoints))}
									</Link>
									{/* Portal - Leaderboards */}
									<div id={PortalLeaderboardsId} style={SublistStyle} />
								</div>
							</Section>
						)}

						{/* Settings */}
						<Section routes={[Routes.Settings.routerPath]}>
							<div>
								<Link to={Routes.Settings.linkPath} style={ItemStyle}>
									{lang.settings}
								</Link>
								{/* Portal - Settings */}
								<div id={PortalSettingsId} style={SublistStyle} />
							</div>
						</Section>

						{/* Saved Items */}
						<Section>
							<Link to={Routes.SavedItems.linkPath} style={ItemStyle}>
								{lang.savedItems}
							</Link>
						</Section>

						{/* Invite */}
						{enableNewMemberInvitations && (
							<Section>
								<Link to={Routes.Invite.linkPath} style={ItemStyle}>
									{lang.invite}
								</Link>
							</Section>
						)}

						{/* Directory */}
						{enableUserDirectory && (
							<Section>
								<Link to={Routes.Directory.linkPath} style={ItemStyle}>
									{lang.directory}
								</Link>
							</Section>
						)}

						{/* Downloads */}
						{enableDownloadPage && (
							<Section>
								<Link to={Routes.Downloads.linkPath} style={ItemStyle}>
									{lang.downloads}
								</Link>
							</Section>
						)}

						{/* Experimental Features */}
						{enableBetaLink && (
							<Section>
								<Link to={Routes.Beta.linkPath} style={ItemStyle}>
									{lang.betaLink}
								</Link>
							</Section>
						)}

						{/* Manage Community & Member Help */}
						<Section forceBorders style={styles.noBottomBorder}>
							{isManager && (
								<div style={ItemStyle}>
									<a
										href={Routes.Manage.linkPath}
										style={styles.link}
										rel="noopener noreferrer"
										target="_blank"
									>
										{lang.manageCommunity}
									</a>
								</div>
							)}
							<div style={ItemStyle}>
								<a
									href={memberHelpUrl}
									style={styles.link}
									rel="noopener noreferrer"
									target="_blank"
								>
									{lang.memberHelp}
								</a>
							</div>
						</Section>

						<Section forceBorders>
							<div>
								<div onClick={onSignOut} style={ItemStyle}>
									{lang.signOut}
								</div>
							</div>
						</Section>
					</Fragment>
				)}
			</div>
		)
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SideMenu)
)
