import { vsApi, requests } from 'helpers/apiHelpers'

const FETCH_LEADERBOARDS_REQUEST = 'FETCH_LEADERBOARDS_REQUEST'
const FETCH_LEADERBOARDS_SUCCESS = 'FETCH_LEADERBOARDS_SUCCESS'
const FETCH_LEADERBOARDS_FAILURE = 'FETCH_LEADERBOARDS_FAILURE'
const FETCH_USERRANKINGS_REQUEST = 'FETCH_USERRANKINGS_REQUEST'
const FETCH_USERRANKINGS_SUCCESS = 'FETCH_USERRANKINGS_SUCCESS'
const FETCH_USERRANKINGS_FAILURE = 'FETCH_USERRANKINGS_FAILURE'
const FETCH_LEADERBOARDRANKINGS_REQUEST = 'FETCH_LEADERBOARDRANKINGS_REQUEST'
const FETCH_LEADERBOARDRANKINGS_SUCCESS = 'FETCH_LEADERBOARDRANKINGS_SUCCESS'
const FETCH_LEADERBOARDRANKINGS_FAILURE = 'FETCH_LEADERBOARDRANKINGS_FAILURE'
const CLEAR_LEADERBOARDRANKINGS = 'CLEAR_LEADERBOARDRANKINGS'

// Action Creators
export const clearLeaderboardRankings = () => {
	return {
		type: CLEAR_LEADERBOARDRANKINGS,
	}
}

export const asyncGetLeaderboards = () => {
	return vsApi({
		endpoint: requests.getAllLeaderboards.endpoint,
		actions: {
			request: FETCH_LEADERBOARDS_REQUEST,
			success: FETCH_LEADERBOARDS_SUCCESS,
			failure: FETCH_LEADERBOARDS_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetLeaderboardRankings = (leaderboardId, start = 0, count) => {
	return vsApi({
		endpoint: requests.getLeaderboardRankings.getEndpoint(leaderboardId, start, count),
		actions: {
			request: FETCH_LEADERBOARDRANKINGS_REQUEST,
			success: FETCH_LEADERBOARDRANKINGS_SUCCESS,
			failure: FETCH_LEADERBOARDRANKINGS_FAILURE,
		},
		context: {
			leaderboardId,
			start,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

export const asyncGetUserRankings = () => {
	return vsApi({
		endpoint: requests.getUserRankings.endpoint,
		actions: {
			request: FETCH_USERRANKINGS_REQUEST,
			success: FETCH_USERRANKINGS_SUCCESS,
			failure: FETCH_USERRANKINGS_FAILURE,
		},
		options: { captureUnhandledPromiseRejection: false },
	})
}

// Initial State
const initialLeaderboards = {
	leaderboards: [],
}

const initialLeaderboardRankings = {
	leaderboardRankings: {},
}

const initialUserRankings = {
	userRankings: [],
}

const initialErrors = {
	errors: [],
}

const initialState = {
	...initialLeaderboards,
	...initialLeaderboardRankings,
	...initialUserRankings,
	...initialErrors,
}

// Private Helpers
const mergeRankings = (rankings, prevRankings, start) => {
	// Remove stale data
	const rankingsUserIds = rankings.map(ranking => ranking.user.id)
	const filteredRankings = prevRankings.filter(
		ranking => !rankingsUserIds.includes(ranking.user.id)
	)
	// Start means it is coming from load more button
	return start ? [...filteredRankings, ...rankings] : [...rankings, ...filteredRankings]
}

export default function leaderboardReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_LEADERBOARDS_REQUEST: {
			return {
				...state,
				isLeaderboardsLoading: true,
			}
		}
		case FETCH_LEADERBOARDS_SUCCESS: {
			const leaderboards = Object.values(action.response.leaderboards)
			return {
				...state,
				leaderboards,
				isLeaderboardsLoading: false,
			}
		}
		case FETCH_LEADERBOARDS_FAILURE: {
			const errors = [...state.errors, ...[action.error]]
			return {
				...state,
				...initialLeaderboards,
				isLeaderboardsLoading: false,
				errors,
			}
		}
		case FETCH_LEADERBOARDRANKINGS_REQUEST: {
			return {
				...state,
				isRankingsLoading: true,
			}
		}
		case FETCH_LEADERBOARDRANKINGS_SUCCESS: {
			const leaderboardRankings = action.response
			const prevRankings = state.leaderboardRankings[action.context.leaderboardId]
				? state.leaderboardRankings[action.context.leaderboardId].rankings
				: []
			leaderboardRankings.rankings = mergeRankings(
				leaderboardRankings.rankings,
				prevRankings,
				action.context.start
			)

			return {
				...state,
				isRankingsLoading: false,
				leaderboardRankings: {
					...state.leaderboardRankings,
					[action.context.leaderboardId]: {
						...leaderboardRankings,
					},
				},
			}
		}
		case FETCH_LEADERBOARDRANKINGS_FAILURE: {
			return {
				...state,
				isRankingsLoading: false,
				...initialLeaderboardRankings,
			}
		}
		case CLEAR_LEADERBOARDRANKINGS: {
			return {
				...state,
				...initialLeaderboardRankings,
			}
		}
		case FETCH_USERRANKINGS_REQUEST: {
			return {
				...state,
			}
		}
		case FETCH_USERRANKINGS_SUCCESS: {
			const userRankings = Object.values(action.response.leaderboards)
			return {
				...state,
				userRankings,
			}
		}
		case FETCH_USERRANKINGS_FAILURE: {
			return {
				...state,
				...initialUserRankings,
			}
		}
		default:
			return state
	}
}
