import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, Formik, Form } from 'formik'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

// Components
import Button, { ButtonLevel } from 'components/Button/Button'
import Dialog from 'components/Dialog/Dialog'
import FormErrors from 'components/Formik/FormErrors'
import RegFlow from 'scenes/Auth/RegFlow'
import SharingOptionsBar from './components/SharingOptionsBar'
import ShareChannels from './components/ShareChannels'
import PostSummary from 'scenes/Post/PostSummary'
import PostTranslationButton from 'scenes/Post/components/PostTranslationButton'
import ShareDisclosures from './components/ShareDisclosures'
import ShareScheduleDialog from 'scenes/ShareDialog/components/ShareScheduleDialog'
import SocialAccounts from 'scenes/Settings/ChannelSettings/SocialAccounts'
import { Restrictions, RestrictText } from 'components/RestrictedText/RestrictedText'
import { MomentTimeFormat } from 'components/Picker/TimePicker/TimePicker'
import DateTime, { DateFormat } from 'components/DateTime/DateTime'
import ShareDialogV8 from 'scenes/ShareDialogV8/ShareDialogV8'

// Loadable Components
import Loadable from 'react-loadable'
import Loading from 'components/Loading/Loading'

export const ShareText = Loadable({
	loader: () => import('./components/ShareText'),
	loading: Loading,
})

export const ShareText_v2 = Loadable({
	loader: () => import('./components/ShareText_v2'),
	loading: Loading,
})

// Helpers
import { Settings } from 'wrappers/SettingsWrapper'
import { refreshUser } from 'helpers/userHelpers'
import { Activity } from 'helpers/activityHelpers'
import responsive, { BreakpointNames } from 'wrappers/ResponsiveComponent'
import SocialProviderData, { SocialProvidersVerifyAuthentication } from 'helpers/socialProviderData'
import Routes from 'helpers/routes'
import { dispatchIfNotImpersonating } from 'helpers/impersonationHelpers'

// API / Actions
import {
	asyncSetUserDefaultSharingChannels,
	asyncGetChannelVerifyAuthentication,
	setHasChannelsVerifyAuthenticationBeenChecked,
} from 'scenes/Auth/auth.reducer'
import { closeShareDialog, asyncGetPost, openShareDialog } from 'scenes/Post/post.reducer'
import { clearUrgentBroadcast, asyncViewBroadcast } from '../Broadcast/broadcast.reducer'
import {
	twitterFilter,
	facebookFilter,
	otherFilter,
	asyncSharePost,
	asyncGetPostLink,
	asyncGetEmailLink,
	ReactionType,
	ShareType,
	asyncDeleteScheduledShares,
	asyncGetFacebookLink,
	asyncGetTwitterLink,
	asyncGetXingLink,
	asyncGetLinkedInLink,
} from './apiHelpers'

// Lang
import lang from './shareDialog.lang'
import buttons from 'helpers/lang/buttons.lang'
import reactionsLang from 'helpers/lang/reactions.lang'

// Form
export const FormShareDialog = 'shareDialog'

// Enums
import VideoTypes from 'scenes/Post/enums/videoTypes'

const activeEditor = {
	none: null,
	twitter: 'twitter',
	facebook: 'facebook',
	other: 'other',
}
const shareTextFormNames = {
	none: null,
	twitter: 'twitterShareText',
	facebook: 'facebookShareText',
	other: 'otherShareText',
}
const ShareMultipleType = {
	none: 'none',
	now: 'now',
	scheduled: 'scheduled',
	both: 'both',
}

//#region Styles
import { margin, fontWeight, width, fontSize, flexContainer } from 'styles/variables'
import { safeGetNestedProp, addQueryParam, decodeQueryParams } from '@dysi/js-helpers'
import { listUnstyled } from 'styles/defaults'
import { safeGetSetting } from 'wrappers/Settings'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'

export const styles = {
	shareDialog: {
		overflow: 'visible',
	},
	details: {
		cursor: 'pointer',
		display: 'flex',
		lineHeight: 0,
	},
	translation: {
		marginTop: width.spacing.narrow,
	},
	shareTextContainer: {
		marginTop: margin.basic,
		marginBottom: margin.basic,
	},
	shareTextInactiveMargin: {
		height: width.lineHeight.base * (3 - 1), // Editor shows 3 lines when active, but only 1 when inactive
	},
	signIn: {
		paddingBottom: 0,
	},
	shareComplete: {
		textAlign: 'center',
		marginBottom: '40px',
		text: {
			marginTop: 0,
			marginBottom: 0,
		},
		points: {
			marginTop: 0,
		},
	},
	shareMultipleSubTitle: {
		textAlign: 'center',
		fontSize: fontSize.heading,
		lineHeight: '24px',
		marginBottom: '24px',
	},
	shareMultipleTitle: {
		textAlign: 'center',
		marginBottom: width.spacing.basic,
		whiteSpace: 'pre',
	},
	shareScheduled: {
		textAlign: 'center',
		marginBottom: width.spacing.basic,
	},
	shareScheduledDate: {
		textAlign: 'center',
		marginBottom: width.spacing.basic,
	},
	editScheduledShare: {
		textAlign: 'center',
		marginBottom: '15px',
	},
	editDeleteLink: {
		fontSize: fontSize.heading,
		fontWeight: fontWeight.semiBold,
		cursor: 'pointer',
		color: 'inherit',
	},
	editDeleteSeparator: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: width.spacing.narrow,
		paddingRight: width.spacing.narrow,
	},
	regFlow: {
		width: 'auto',
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		border: 'none',
		margin: 'auto',
	},
	sharingDisclosureDialog: {
		top: '80px',
	},
	footer: {
		marginTop: margin.wide,
	},
	buttonTrigger: {
		marginLeft: 0,
		borderLeftColor: 'rgba(255, 255, 255, 0.3)',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		paddingRight: '5px',
	},
	dropdownList: {
		...listUnstyled,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		item: {
			padding: 6,
		},
		link: {
			cursor: 'pointer',
		},
	},
	postSummary: {
		[BreakpointNames.small]: {
			...flexContainer(),
		},
	},
}
//#endregion

// Helpers

//#region Business Rule: Page providers react using the main provider
export const getReactionProvider = (reaction, sharePost) => {
	let reactionProvider = (reaction && sharePost.provider) || null
	if (reactionProvider === 'FacebookPage') reactionProvider = 'Facebook'
	if (reactionProvider === 'LinkedInPage') reactionProvider = 'LinkedIn'

	return reactionProvider
}

export const getUserChannels = (user, shareReaction, sharePost, providers, shareImagesOnly) => {
	let userChannels = (user && user.channels) || []

	if (shareReaction && sharePost) {
		// Reaction: Only show channels from post's provider
		const provider = getReactionProvider(shareReaction, sharePost)
		userChannels = userChannels.filter(channel => channel.provider === provider)
	} else {
		// Share: Only show sharing providers
		userChannels = userChannels.filter(
			c => providers[c.provider] && providers[c.provider].canShare && c.sharable
		)

		// Business Rule: If shareImagesOnly is set for the post, only a limited set of providers are allowed
		if (shareImagesOnly) {
			const shareImageOnlyProviders = ['Facebook', 'FacebookPage', 'Twitter', 'LinkedIn']
			userChannels = userChannels.filter(c => shareImageOnlyProviders.includes(c.provider))
		}
	}

	// Order user channels based on order of social providers.
	userChannels.sort((a, b) => {
		const firstProvider = providers[a.provider]
		const secondProvider = providers[b.provider]
		if (
			firstProvider &&
			firstProvider.displayOrder &&
			secondProvider &&
			secondProvider.displayOrder
		) {
			return firstProvider.displayOrder - secondProvider.displayOrder
		} else {
			return 0
		}
	})
	return userChannels
}
//#endregion

//#region Redux generated Container Component
const mapStateToProps = (state, ownProps) => {
	const providers = state.sphere.providers

	// Share post / reaction
	const sharePost = state.post.sharePost
	const shareReaction = state.post.shareReaction
	const shareImagesOnly = safeGetNestedProp(state, 'post.sharePost.shareImagesOnly')
	const routing = safeGetNestedProp(state, 'routing.location')
	const enableAlternateShareText = safeGetNestedProp(
		state,
		'sphere.parameters.enableAlternateShareText'
	)

	// Share post / reactions
	let postIds = []
	if (state.post && state.post.postIds) {
		postIds = state.post.postIds
	}

	const isMultiShare = postIds.length > 1

	// Pulling posts into local state to handle updates to the lastShareDate better.
	const posts = state.post.posts

	// Member Usage Compliance
	const memberUsageComplianceRequired = safeGetNestedProp(
		state,
		'memberUsageCompliance.usageComplianceRequired'
	)

	// Share Dialog V8
	const isShareDialogV8Enabled = safeGetSetting(state, Settings.Features.EnableShareDialogV8)

	// Settings
	const showPostShareLinks = safeGetNestedProp(state, Settings.Features.ShowPostShareLinks.path)
	const enableShareByEmail = safeGetNestedProp(state, Settings.Features.EnableShareByEmail.path)
	const enableLeaderboards = safeGetSetting(state, Settings.Features.EnableLeaderboards)
	const facebookNativeConfig = safeGetSetting(state, Settings.Features.FacebookNativeConfig) || {}
	const twitterNativeConfig = safeGetSetting(state, Settings.Features.TwitterNativeConfig) || {}
	const xingNativeConfig = safeGetSetting(state, Settings.Features.XingNativeConfig) || {}
	const linkedInNativeConfig = safeGetSetting(state, Settings.Features.LinkedInNativeConfig) || {}
	const sharingMaxChannelsPerShare = safeGetSetting(
		state,
		Settings.Features.SharingMaxChannelsPerShare
	)

	// BrandApp Native Sharing host
	const host = safeGetNestedProp(state, 'sphere.host')
	const nativeSharingHostUrl = `https://${host}/widget/nativesharing`

	const showShareButtonOnSocialPosts = safeGetNestedProp(
		state,
		Settings.Features.ShowShareButtonOnSocialPosts.path
	)
	const enableSuggestedShareTextForFacebook = Settings.Features.EnableSuggestedShareTextForFacebook.value(
		state
	)
	const isScheduledSharingEnabled = safeGetNestedProp(
		state,
		Settings.Features.IsScheduledSharingEnabled.path,
		Settings.Features.IsScheduledSharingEnabled.fallback
	)
	const isSsoEnabled = safeGetNestedProp(state, Settings.Features.EnableSso.path)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const user = safeGetNestedProp(state, 'auth.user')

	// Get channels to Share/React on
	const userChannels = getUserChannels(user, shareReaction, sharePost, providers, shareImagesOnly)
	// A flag that indicates whether the user channels verify authentication check has been made
	const hasChannelsVerifyAuthenticationBeenChecked = safeGetNestedProp(
		state,
		'auth.hasChannelsVerifyAuthenticationBeenChecked'
	)

	// Urgent Broadcasts
	const urgentBroadcastAllIds = state.broadcast.urgentBroadcastAllIds

	return {
		showPostShareLinks,
		enableShareByEmail,
		enableLeaderboards,
		showShareButtonOnSocialPosts,
		facebookNativeConfig,
		twitterNativeConfig,
		xingNativeConfig,
		linkedInNativeConfig,
		nativeSharingHostUrl,
		enableSuggestedShareTextForFacebook,
		isScheduledSharingEnabled,
		isShareDialogV8Enabled,
		isSsoEnabled,
		primaryColor,
		user,
		userChannels,
		hasChannelsVerifyAuthenticationBeenChecked,
		providers,
		sharePost,
		shareReaction,
		shareImagesOnly,
		routing,
		memberUsageComplianceRequired,
		urgentBroadcastAllIds,
		sharingMaxChannelsPerShare,
		enableAlternateShareText,
		isMultiShare,
		postIds,
		posts,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		closeDialog: () => dispatch(closeShareDialog()),
		changePostMultiple: (postId, postIds, activity) =>
			dispatch(openShareDialog(postId, postIds, null, activity)),
		getCopyLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetPostLink(postId, activity), true, true),
		getEmailLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetEmailLink(postId, activity), true, true),
		getFacebookLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetFacebookLink(postId, activity), true, true),
		getTwitterLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetTwitterLink(postId, activity), true, true),
		getXingLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetXingLink(postId, activity), true, true),
		getLinkedInLink: (postId, activity) =>
			dispatchIfNotImpersonating(asyncGetLinkedInLink(postId, activity), true, true),
		getPost: postId => dispatch(asyncGetPost(postId)),
		reloadUser: () => refreshUser(dispatch),
		setUserDefaultSharingChannels: (userId, userChannelIds) =>
			dispatch(asyncSetUserDefaultSharingChannels(userId, userChannelIds)),
		share: (postId, values, reactionType, activity) => {
			const {
				userChannelIds,
				twitterShareText,
				facebookShareText,
				otherShareText,
				shareType,
				shareDate,
			} = values
			return dispatchIfNotImpersonating(
				asyncSharePost(
					postId,
					reactionType,
					userChannelIds,
					twitterShareText,
					facebookShareText,
					otherShareText,
					shareType,
					shareDate,
					activity
				),
				true,
				true
			)
		},
		deleteScheduledShares: (userId, scheduledShareIds) =>
			dispatchIfNotImpersonating(asyncDeleteScheduledShares(userId, scheduledShareIds), true, true),
		clearUrgentBroadcast: broadcastId => dispatch(clearUrgentBroadcast(broadcastId)),
		viewBroadcast: broadcastId => dispatch(asyncViewBroadcast(broadcastId)),
		channelVerifyAuthentication: userChannelId =>
			dispatch(asyncGetChannelVerifyAuthentication(userChannelId)),
		setHasChannelsVerifyAuthenticationBeenChecked: () =>
			dispatch(setHasChannelsVerifyAuthenticationBeenChecked()),
	}
}
//#endregion

// ShareDialog
@responsive
class ShareDialog extends Component {
	static propTypes = {
		// From Settings
		showPostShareLinks: Settings.Features.ShowPostShareLinks.propType,
		enableShareByEmail: Settings.Features.EnableShareByEmail.propType,
		enableLeaderboards: Settings.Features.EnableLeaderboards.propType,
		facebookNativeConfig: Settings.Features.FacebookNativeConfig.propType,
		twitterNativeConfig: Settings.Features.TwitterNativeConfig.propType,
		showShareButtonOnSocialPosts: Settings.Features.ShowShareButtonOnSocialPosts.propType,
		enableSuggestedShareTextForFacebook:
			Settings.Features.EnableSuggestedShareTextForFacebook.propType,
		isScheduledSharingEnabled: Settings.Features.IsScheduledSharingEnabled.propType,
		isSsoEnabled: Settings.Features.EnableSso.propType,
		primaryColor: Theme.PrimaryColor.propType,

		// From Redux store
		channelVerifyAuthentication: PropTypes.func.isRequired,
		clearUrgentBroadcast: PropTypes.func,
		closeDialog: PropTypes.func.isRequired,
		changePostMultiple: PropTypes.func.isRequired,
		getCopyLink: PropTypes.func.isRequired,
		getEmailLink: PropTypes.func.isRequired,
		getFacebookLink: PropTypes.func.isRequired,
		getLinkedInLink: PropTypes.func.isRequired,
		getPost: PropTypes.func.isRequired,
		getTwitterLink: PropTypes.func,
		getXingLink: PropTypes.func,
		isShareDialogV8Enabled: PropTypes.bool,
		linkedInNativeConfig: PropTypes.object,
		memberUsageComplianceRequired: PropTypes.bool,
		nativeSharingHostUrl: PropTypes.string,
		providers: PropTypes.object.isRequired,
		reloadUser: PropTypes.func.isRequired,
		routing: PropTypes.string,
		share: PropTypes.func.isRequired,
		setUserDefaultSharingChannels: PropTypes.func.isRequired,
		sharePost: PropTypes.object,
		shareReaction: PropTypes.oneOf(Object.values(ReactionType)), // Note: reactionType === null means SharePost, reactionType !== null means ReactToPost
		shareImagesOnly: PropTypes.bool,
		urgentBroadcastAllIds: PropTypes.array,
		user: PropTypes.object, // Null if not logged in
		userChannels: PropTypes.array.isRequired,
		viewBroadcast: PropTypes.func,
		viewport: PropTypes.object,
		xingNativeConfig: PropTypes.object,
		hasChannelsVerifyAuthenticationBeenChecked: PropTypes.bool,
		setHasChannelsVerifyAuthenticationBeenChecked: PropTypes.func,
		sharingMaxChannelsPerShare: PropTypes.number,
		enableAlternateShareText: PropTypes.bool,
		isMultiShare: PropTypes.bool,
		postIds: PropTypes.array,
		posts: PropTypes.object,
	}

	static initialState = {
		initialValues: {
			shareType: ShareType.shareNow,
			shareDate: null,
			shareTime: null,
			userChannelIds: [],
			twitterShareText: '',
			facebookShareText: '',
			otherShareText: '',
		},
		isInitialized: false,
		isScheduledShare: false,
		joinNowToShare: false,
		activeShareTextEditor: activeEditor.none,
		showSharingDisclosures: false,
		showAddChannels: false,
		showScheduleShare: false,
		shareComplete: false,
		shareMultipleComplete: false,
		shareMultipleSuccessfulShares: [],
		scheduleError: null,
		reaction: null,
		commentPlaceholder: '',
		twitterCommentPlaceholder: null, // Initialized by initialize, below
		dialogTitle: '',
		sharingDisclosureInfo: null,
		suggestedTwitterShareText: '',
		suggestedFacebookShareText: '',
		suggestedOtherShareText: '',
		errors: {},
	}

	formikRef = React.createRef()

	state = {
		...ShareDialog.initialState,
	}

	// Helpers
	getRandomSuggestionIndex = suggestions =>
		suggestions.length < 2 ? 0 : Math.floor(Math.random() * suggestions.length)

	// Share text suggestions
	shareTextSuggestions = ['']
	twitterShareTextSuggestions = ['']
	facebookShareTextSuggestions = ['']

	initShareTextSuggestions = (reaction, post) => {
		if (reaction) {
			// No suggested share text for reactions
			this.shareTextSuggestions = ['']
			this.twitterShareTextSuggestions = ['']
			this.facebookShareTextSuggestions = ['']

			// Prefill Twitter replies with an @mention of the original author
			if (
				post.provider === SocialProviderData.Twitter.displayName &&
				reaction === ReactionType.comment
			) {
				this.twitterShareTextSuggestions = [post.author.providerHandle || '']
			}
		} else {
			this.shareTextSuggestions = post.suggestedShareTextList || ['']
			this.twitterShareTextSuggestions = post.shortSuggestedShareTextList || ['']
			// (post.shortSuggestedShareTextList.length > 0 && post.shortSuggestedShareTextList[0]) || ''
			this.facebookShareTextSuggestions = this.props.enableSuggestedShareTextForFacebook
				? this.shareTextSuggestions
				: ['']
		}
	}

	showAddChannelsIfNeeded(user, shareReaction, sharePost, providers, shareImagesOnly) {
		if (!user) return

		const userChannels = getUserChannels(user, shareReaction, sharePost, providers, shareImagesOnly)
		if (userChannels.length === 0 && providers.length > 0) {
			this.setState({ showAddChannels: true })
		}
	}

	//#region Initialization and Lifecycles
	initialize() {
		const {
			sharePost,
			shareReaction,
			userChannels,
			providers,
			primaryColor,
			routing,
			isMultiShare,
			postIds,
			posts,
		} = this.props

		// Initial values
		let isTwitterSelected = false
		const initialUserChannelIds =
			userChannels.length > 0
				? userChannels
						.filter(channel => channel.sharingDefault && !channel.authRequired)
						.filter(
							channel =>
								channel.provider !== 'Twitter' || (!isTwitterSelected && (isTwitterSelected = true))
						) // Set isTwitterSelected after 1st Twitter channel is allowed
						.map(channel => channel.userChannelId)
				: []

		this.initShareTextSuggestions(shareReaction, sharePost)
		const firstFacebookShareTextSuggestionIndex = this.getRandomSuggestionIndex(
			this.shareTextSuggestions
		)
		const firstOtherShareTextSuggestionIndex = this.getRandomSuggestionIndex(
			this.shareTextSuggestions
		)
		const firstTwitterShareTextSuggestionIndex = this.getRandomSuggestionIndex(
			this.twitterShareTextSuggestions
		)

		// Was uid passed in the request? This indicates an email Share request
		let joinNowToShare = ShareDialog.initialState.joinNowToShare
		if (routing && routing.search) {
			const param = decodeQueryParams(routing.search)
			if (param && param.uid) {
				joinNowToShare = true
			}
		}

		const initialValues = {
			userChannelIds: initialUserChannelIds,

			// TA-23195: Partially revert TA-20699
			//twitterShareText: '', // Business Rule: Twitter suggested share text only inserted after user types his/her comment
			twitterShareText: this.twitterShareTextSuggestions[firstTwitterShareTextSuggestionIndex],
			twitterSuggestionIndex: firstTwitterShareTextSuggestionIndex,

			facebookShareText: this.facebookShareTextSuggestions[firstFacebookShareTextSuggestionIndex],
			facebookSuggestionIndex: firstFacebookShareTextSuggestionIndex,

			otherShareText: this.shareTextSuggestions[firstOtherShareTextSuggestionIndex],
			otherSuggestionIndex: firstOtherShareTextSuggestionIndex,
		}

		// Get reaction
		let reaction = null
		const reactionProvider = getReactionProvider(shareReaction, sharePost)
		if (shareReaction && providers[reactionProvider]) {
			// Is this a supported reaction?
			const reactions = Object.values(providers[reactionProvider].reactions).filter(
				reaction => reaction.reactionType.toLowerCase() === shareReaction.toLowerCase()
			)
			reaction = reactions.length > 0 ? reactions[0] : null
		}

		// Set placeholder text
		const commentPlaceholder = reaction ? lang.reactionPlaceholder : lang.placeholder

		// TA-23195: Partially revert TA-20699
		// const twitterCommentPlaceholder = this.twitterShareTextSuggestion
		// 	? lang.twitterCommentPlaceholder + ' ' + this.twitterShareTextSuggestion
		// 	: lang.twitterCommentPlaceholder
		const twitterCommentPlaceholder = lang.twitterCommentPlaceholder

		// Dialog title
		let dialogTitle = reaction
			? reactionsLang.title[`${reactionProvider}${reaction.reactionType}`] || reaction.displayText
			: lang.dialogTitle

		let disableNext = false
		let disablePrevious = false

		if (isMultiShare) {
			let currentPostIndex = postIds.indexOf(sharePost.postId) + 1
			let totalPosts = postIds.length

			dialogTitle = lang.dialogTitle + ` (${currentPostIndex}/${totalPosts})`

			if (currentPostIndex === 1) {
				disablePrevious = true
			}

			if (currentPostIndex === totalPosts) {
				disableNext = true
			}
		}

		let sharingDisclosureInfo = null
		if (
			Array.isArray(sharePost.shareDisclosures) &&
			sharePost.shareDisclosures.some(disclosure => disclosure.shareDisclosureText)
		) {
			// There's at least 1 non-empty disclosure
			const style = {
				color: primaryColor,
				fontSize: fontSize.small,
			}
			sharingDisclosureInfo = (
				<span>
					{lang.sharingDisclosure}{' '}
					<a href="showSharingDisclosures" style={style} onClick={this.handleViewSharingDisclosure}>
						{buttons.view}
					</a>
				</span>
			)
		}

		this.setState({
			...ShareDialog.initialState,
			isInitialized: true,
			reaction,
			initialValues,
			joinNowToShare,
			commentPlaceholder,
			twitterCommentPlaceholder,
			dialogTitle,
			disableNext,
			disablePrevious,
			sharingDisclosureInfo,
			suggestedFacebookShareText: initialValues.facebookShareText,
			suggestedTwitterShareText: initialValues.twitterShareText,
			suggestedOtherShareText: initialValues.otherShareText,
			posts,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			user,
			sharePost,
			shareReaction,
			shareImagesOnly,
			userChannels,
			hasChannelsVerifyAuthenticationBeenChecked,
			isMultiShare,
			postIds,
		} = this.props
		let { providers } = this.props
		let {dialogTitle, disableNext, disablePrevious, lastSharedSubtitle, posts } = this.state

		// Verify relevant channels authentication (just once!)
		if (!sharePost && userChannels.length > 0 && !hasChannelsVerifyAuthenticationBeenChecked) {
			this.handleChannelVerifyAuthentication()
		}

		if (!sharePost) return

		const { user: wasLoggedIn } = prevProps
		const { isInitialized, showCopyLinkDialog, copyLink, copyLinkError } = this.state

		// Is the ShareDialog about to open?
		if (sharePost && !isInitialized) {
			// Reset the dialog UI
			this.initialize()

			// If user has no channels to share on, show the Add Channels page
			this.showAddChannelsIfNeeded(user, shareReaction, sharePost, providers, shareImagesOnly)
		}

		// Was post updated in store?
		if (sharePost !== prevProps.sharePost && isInitialized && this.handlePostTranslationChange) {
			this.handlePostTranslationChange(sharePost)
		}

		// Is this a fresh login?
		if (user && !wasLoggedIn) {
			// If user can't share, reload the page (No more sharing!)
			if (!user.canSharePosts) {
				window.location.reload()
			}

			// If user has no channels to share on, show the Add Channels page
			this.showAddChannelsIfNeeded(user, shareReaction, sharePost, providers, shareImagesOnly)
		}

		// Did copy link just update?
		if (showCopyLinkDialog && copyLink !== buttons.loading && !copyLinkError) {
			this.copyInput.focus()
		}

		// The default share date (if a post has not been shared) is the C# default. We need to check for the Share Date being that default, if so the post has not yet been shared.
		let hasLastShareDate = true;
		var minDate = moment.utc('0001-01-01');
		var lastSharedDate = new Date(sharePost.lastShareDate)

		 if (moment.utc(lastSharedDate).isSame(minDate, 'year')){
			hasLastShareDate = false
		}

		let lastSharedFromState = null

		if (posts){
			var postFromState = posts[sharePost.postId]

			if (postFromState){
				lastSharedFromState = postFromState.lastShareDate
			}
		}

		let newLastSharedSubtitle = null

		if (lastSharedFromState && (lastSharedFromState > sharePost.lastShareDate) ){
			newLastSharedSubtitle = lang.lastShared + moment(lastSharedFromState).format('MMM D, YYYY')
		} else if (hasLastShareDate){
			newLastSharedSubtitle = lang.lastShared + moment(sharePost.lastShareDate).format('MMM D, YYYY')
		}

		if (lastSharedSubtitle !== newLastSharedSubtitle){
			lastSharedSubtitle = newLastSharedSubtitle
			this.setState({ lastSharedSubtitle });
		}

		if (isMultiShare) {
			let currentPostIndex = postIds.indexOf(sharePost.postId) + 1
			let totalPosts = postIds.length

			let newDialogTitle = lang.dialogTitle + ` (${currentPostIndex}/${totalPosts})`

			// If the dialog title has changed we have changed post so need to update the share text suggestions and remove any warning message.
			if (newDialogTitle !== dialogTitle) {
				dialogTitle = lang.dialogTitle + ` (${currentPostIndex}/${totalPosts})`

				if (this.formikRef.current) {
					this.formikRef.current.setErrors({})
				}

				let userChannelIds = []

				if (!isInitialized) {
					// On init we need to ensure that twitter is enabled if available. If after init, we use the selected channels from state.
					let isTwitterSelected = false

					userChannelIds =
						userChannels.length > 0
							? userChannels
									.filter(channel => channel.sharingDefault && !channel.authRequired)
									.filter(
										channel =>
											channel.provider !== 'Twitter' ||
											(!isTwitterSelected && (isTwitterSelected = true))
									) // Set isTwitterSelected after 1st Twitter channel is allowed
									.map(channel => channel.userChannelId)
							: []
				} else {
					userChannelIds = this.state.initialValues.userChannelIds
				}

				this.initShareTextSuggestions(shareReaction, sharePost)
				const firstFacebookShareTextSuggestionIndex = this.getRandomSuggestionIndex(
					this.shareTextSuggestions
				)
				const firstOtherShareTextSuggestionIndex = this.getRandomSuggestionIndex(
					this.shareTextSuggestions
				)
				const firstTwitterShareTextSuggestionIndex = this.getRandomSuggestionIndex(
					this.twitterShareTextSuggestions
				)

				const initialValues = {
					userChannelIds: userChannelIds,

					// TA-23195: Partially revert TA-20699
					//twitterShareText: '', // Business Rule: Twitter suggested share text only inserted after user types his/her comment
					twitterShareText: this.twitterShareTextSuggestions[firstTwitterShareTextSuggestionIndex],
					twitterSuggestionIndex: firstTwitterShareTextSuggestionIndex,

					facebookShareText: this.facebookShareTextSuggestions[
						firstFacebookShareTextSuggestionIndex
					],
					facebookSuggestionIndex: firstFacebookShareTextSuggestionIndex,

					otherShareText: this.shareTextSuggestions[firstOtherShareTextSuggestionIndex],
					otherSuggestionIndex: firstOtherShareTextSuggestionIndex,
				}

				this.setState({
					initialValues,
					dialogTitle,
					suggestedFacebookShareText: initialValues.facebookShareText,
					suggestedTwitterShareText: initialValues.twitterShareText,
					suggestedOtherShareText: initialValues.otherShareText,
				})
			}
			let newDisableNext = false
			let newDisablePrevious = false

			if (currentPostIndex === 1) {
				newDisablePrevious = true
			}

			if (currentPostIndex === totalPosts) {
				newDisableNext = true
			}

			if (newDisableNext !== disableNext) {
				disableNext = newDisableNext
				this.setState({ disableNext })
			}

			if (newDisablePrevious !== disablePrevious) {
				disablePrevious = newDisablePrevious
				this.setState({ disablePrevious })
			}
		}
	}

	handleChannelVerifyAuthentication = () => {
		const {
			userChannels,
			channelVerifyAuthentication,
			setHasChannelsVerifyAuthenticationBeenChecked,
		} = this.props

		// Set the flag in redux store
		setHasChannelsVerifyAuthenticationBeenChecked()

		// Check the list of social providers we verify - don't include channels that already require auth!
		const userChannelIdsToVerify = userChannels
			.filter(uc => SocialProvidersVerifyAuthentication.includes(uc.provider) && !uc.authRequired)
			.map(uc => uc.userChannelId)

		if (userChannelIdsToVerify.length > 0) {
			userChannelIdsToVerify.forEach(id => channelVerifyAuthentication(id))
		}
	}

	componentWillUnmount() {
		clearTimeout(this.submitFormTimeout)
		clearTimeout(this.handleEditStartTimeout)
	}
	//#endregion

	//#region Handling Shares (Regular, Auto, and Scheduled)
	handleAutoShare = (submitForm, setFieldValue) => {
		const { sharePost } = this.props
		const selectedChannelIds = safeGetNestedProp(this.state, 'initialValues.userChannelIds', [])

		setFieldValue('shareType', ShareType.autoQueue, false)

		this.appendSuccessfulShare(sharePost.postId, ShareType.autoQueue, sharePost.sharePoints, selectedChannelIds)

		// NOTE: This setTimeout will become unnecessary if Formik Issue #529 is fixed
		this.submitFormTimeout = setTimeout(submitForm, 0) // Allow setFieldValue to finish before calling submitForm
	}

	handleShare = (formValues, formikProps) => {
		// Call the APIs to share
		const {
			user,
			sharePost,
			share,
			setUserDefaultSharingChannels,
			reloadUser,
			isMultiShare,
			getPost,
		} = this.props
		const { reaction, posts } = this.state
		const { setFieldError, setSubmitting } = formikProps
		// Remember share date for when we re-render the Formik dialog page (e.g. Scheduled Share Edit)
		const values = {
			...this.state.initialValues,
			...formValues,
		}
		// Reset to initial state for suggested share text
		this.setState({
			initialValues: {
				...values,
			},
		})

		let shareType = values.shareType

		// Keep isSubmitting set thru async API call so submit button remains disabled
		// NOTE: isSubmitting will reset automagically when async calls complete and
		// setState with their result. Those setState calls will force each dialog to
		// re-render, resetting the value of isSubmitting
		setSubmitting(true)

		// Reset schedule dialog for submission
		this.setState({ isSubmitting: true, scheduleError: null })

		// Share post
		const reactionType = (reaction && reaction.reactionType) || null
		share(sharePost.postId, values, reactionType, sharePost.activity)
			.then(shareResult => {
				// Array of results from share Promise.All

				// If auto-queue, get shareDate from result
				if (
					shareType === ShareType.autoQueue &&
					shareResult[0].shareDate &&
					shareResult[0].shareDate[0]
				) {
					// Remember share date for when we re-render the Formik dialog page (e.g. Scheduled Share Edit)
					const momentShareDate = moment.tz(shareResult[0].shareDate[0], user.timeZone)
					const shareDate = momentShareDate.format()
					const shareTime = momentShareDate.format(MomentTimeFormat)

					const initialValues = {
						...this.state.initialValues,
						...values,
						shareDate,
						shareTime,
					}
					this.setState({ initialValues })
				}

				//To allow the Last Shared subtitle to refresh on share during multi share, add the current date to state.
				//We have to check that the share is neither type of scheduled share as we don't want to update the last shared subtitle on a scheduled share and "Now" doesn't get a shareType set.
				if (shareType !== ShareType.autoQueue && shareType !== ShareType.schedule){
					if (posts){
						posts[sharePost.postId].lastShareDate = moment().format()
						this.setState({ posts })
					}
				}

				// FE-1091 update list of successful shares for share multiple
				this.appendSuccessfulShare(sharePost.postId, shareType, sharePost.sharePoints, values.userChannelIds)

				// Clear Urgent broadcast
				this.clearUrgentBroadcast()

				// Retrieve updated information for that post.
				getPost(sharePost.postId)

				// Set these user channels as the default sharing channels for next time
				setUserDefaultSharingChannels(user.userId, values.userChannelIds).then(
					setChannelsResult => {
						// Update user channels and share stats (Don't need to wait for the response)
						reloadUser()

						// Move to Share Complete page
						const isScheduledShare =
							shareType === ShareType.schedule || shareType === ShareType.autoQueue

						let shareComplete = true

						// If a multi share, we don't want to show the share summary automatically.
						// The user may want to share more than one post, the share summary will show when the user closes the dialog.
						if (isMultiShare) {

							// Reset shareType if on Share Multiple to prevent incorrect capping.
							this.setState(prevState => ({
								initialValues: {
								  ...prevState.initialValues,
								  shareType: ShareType.shareNow
								}
							  }));
							shareComplete = false
							setSubmitting(false)
						}

						this.setState({
							showScheduleShare: false,
							shareComplete: shareComplete,
							shareMultipleComplete: shareComplete,
							scheduleError: null,
							isScheduledShare,
						})
					}
				)

				// Propagate result to next promise listener
				return shareResult
			})
			.catch(error => {
				if (error) {
					const isScheduledShare = values.shareType === ShareType.schedule
					let errorMessage = isScheduledShare
						? lang.errorScheduledSharesFailed
						: reactionType
						? lang.errorUnknownReaction
						: lang.errorUnknownShare

					// Custom message?
					if (error.data && error.data.Reason) {
						switch (error.data.Reason) {
							case 'blacklisted': {
								// Restrict text if custom blacklisted error
								let blacklistMessage =
									error.messages &&
									RestrictText(error.messages, Restrictions.ShareBlacklistError).output
								errorMessage = blacklistMessage || lang.errorBlacklisted // Fallback to default msg
								break
							}
							case 'sharing_capped': {
								errorMessage = isScheduledShare
									? lang.errorScheduledSharingCapped
									: lang.errorSharingCapped
								break
							}
							case 'already_shared': {
								errorMessage = lang.errorAlreadyShared
								break
							}
							case 'duplicate_retweet': {
								errorMessage = lang.errorRepeatedRetweet
								break
							}
							default:
								break // Default set above
						}
					}
					// Show error
					setFieldError('form', errorMessage)
					this.setState({ scheduleError: isScheduledShare ? errorMessage : null })
				}
				setSubmitting(false)
			})
			.finally(() => {
				this.setState({ isSubmitting: false })
			})

		// TODO: Do we still need to handle usage_compliance_required error here, like old ShareDialog???
	}

	handleShareAgain = () => {
		this.setState(prevState => ({
			isMultiShare: false,
			shareMultipleComplete: false,
			scheduleError: null,
			shareComplete: false,
			showScheduleShare: false,
			isScheduledShare: false,
			initialValues: {
				...prevState.initialValues,
				shareDate: null,
				shareTime: null,
				shareType: ShareType.shareNow,
			},
		}))
	}
	//#endregion

	handleClose = e => {
		const { closeDialog, isMultiShare } = this.props
		const { joinNowToShare, shareMultipleComplete, shareMultipleSuccessfulShares } = this.state

		if (e) e.preventDefault()

		if (isMultiShare && !shareMultipleComplete && shareMultipleSuccessfulShares.length > 0) {
			// we want to set shareMultipleComplete to true and return to trigger the share complete dialog
			this.setState({ shareMultipleComplete: true })
			return
		}

		if (joinNowToShare) {
			if (window.location.href.includes('/post/')) {
				// prevents going out of app on refresh when closing sharedialog on the post details page
				window.location.href = `${window.location.origin}${window.location.pathname}?fromDialog=true`
			} else {
				// Put user back into the RegFlow to complete registration
				window.location.reload()
			}
			return
		}
		closeDialog()

		// Reset isInitialized for next time
		this.setState({ isInitialized: false})
	}

	//#region Handling Channels
	handleShowAddChannels = selectedChannelIds => {
		// Remember current channel selection for when we return to the Formik dialog page
		const initialValues = {
			...this.state.initialValues,
			userChannelIds: selectedChannelIds,
		}
		this.setState({ initialValues, showAddChannels: true })
	}

	handleSkipAddChannels = e => {
		this.setState({ showAddChannels: false })
	}

	updateChannels = channelIds => {
		const initialValues = { ...this.state.initialValues, userChannelIds: channelIds }
		this.setState({ initialValues })
	}

	// This is called from the Add Channel page when a user successfully authorizes
	// a new social channel. Let's preselect any channels added from that page
	handleAddChannel = socialProviderResponse => {
		const { userChannels } = this.props
		const selectedChannelIds = safeGetNestedProp(this.state, 'initialValues.userChannelIds', [])

		// Business Rule: Only 1 Twitter channel can be selected at a time
		//
		let userChannelIds = [...selectedChannelIds] // Start with existing list

		// If new channel is a Twitter channel, remove any other Twitter channels already in the list
		if (socialProviderResponse.provider === 'Twitter') {
			userChannelIds = userChannelIds.filter(userChannelId => {
				const userChannel = userChannels.find(channel => channel.userChannelId === userChannelId)
				if (userChannel) {
					return userChannel.provider !== 'Twitter'
				} else {
					// Don't filter out channel ID if channel is not found in userChannels list. Newly-added
					// channels might not be in userChannels yet
					return true
				}
			})
		}

		// Add ID of new channel to the end of the list
		userChannelIds.push(socialProviderResponse.userChannelId)

		// Remember new channel selection for when we re-render the Formik dialog page
		const initialValues = {
			...this.state.initialValues,
			userChannelIds,
		}
		this.setState({ initialValues })
	}

	// This is called from the Add Channel page when a user disconnects a social channel.
	// Let's remove that channel from our channel selection list, since it's no longer valid
	handleRemoveChannel = disconnectedUserChannelId => {
		const selectedChannelIds = safeGetNestedProp(this.state, 'initialValues.userChannelIds', [])

		// Start with existing list
		let userChannelIds = [...selectedChannelIds]

		// Remove disconnected channel, if present
		const pos = userChannelIds.indexOf(disconnectedUserChannelId)
		if (pos >= 0) {
			userChannelIds.splice(pos, 1)
		}

		// Remember new channel selection for when we re-render the Formik dialog page
		const initialValues = {
			...this.state.initialValues,
			userChannelIds,
		}
		this.setState({ initialValues })
	}
	//#endregion

	handleNextSuggestion = (values, editor) => {
		let suggestions, valueName, indexName
		switch (editor) {
			case activeEditor.facebook:
				suggestions = this.facebookShareTextSuggestions
				valueName = 'facebookShareText'
				indexName = 'facebookSuggestionIndex'
				break
			case activeEditor.other:
				suggestions = this.shareTextSuggestions
				valueName = 'otherShareText'
				indexName = 'otherSuggestionIndex'
				break
			case activeEditor.twitter:
				suggestions = this.twitterShareTextSuggestions
				valueName = 'twitterShareText'
				indexName = 'twitterSuggestionIndex'
				break

			default:
				break
		}

		if (suggestions.length < 2) return null

		return e => {
			const currentIndex = this.state.initialValues[indexName]
			const nextSuggestionIndex = (currentIndex + 1) % suggestions.length
			const nextSuggestion = suggestions[nextSuggestionIndex]

			// Move to next index
			const initialValues = {
				...this.state.initialValues,
				...values,
				[valueName]: nextSuggestion,
				[indexName]: nextSuggestionIndex,
			}
			this.setState({ initialValues })
			this.handleEditStartTimeout = setTimeout(() => this.handleEditShareTextStart(editor), 100)
		}
	}

	getPostTranslationChangeHandler = (values, setFieldValue) => post => {
		// Use translation result to update 'other' suggested share text value in Formik
		if (!post || !Array.isArray(post.suggestedShareTextList)) return

		// Has the 'other' share text been customized?
		let currentSuggestedShareTextIndex = this.state.initialValues.otherSuggestionIndex
		const currentSuggestedShareText = this.shareTextSuggestions[currentSuggestedShareTextIndex]
		const value = values.otherShareText
		const valueNotChanged = currentSuggestedShareText === value

		// Fix share suggestion index if translation returns fewer suggestions
		if (currentSuggestedShareTextIndex >= post.suggestedShareTextList.length) {
			currentSuggestedShareTextIndex = 0 // Reset

			const initialValues = {
				...this.state.initialValues,
				...values,
				otherSuggestionIndex: currentSuggestedShareTextIndex,
				otherShareText: valueNotChanged
					? post.suggestedShareTextList[currentSuggestedShareTextIndex]
					: values.otherShareText,
			}
			this.setState({ initialValues })
		}

		// Update translated/reverted suggested share text
		this.shareTextSuggestions = post.suggestedShareTextList || ['']

		// If new suggested share text is available and user has not customized the share text,
		// then update the 'other' share text field with the new translated/reverted suggestion
		if (valueNotChanged) {
			setFieldValue('otherShareText', post.suggestedShareTextList[currentSuggestedShareTextIndex])
		}
	}

	//#region Handling Scheduling
	handleShowSchedule = (values, setFieldValue) => {
		const { user } = this.props

		if (!values.shareDate) {
			// Calculate suggested share date and time
			const momentShareDate = moment.tz(new Date(), user.timeZone) // Start with now in user's time zone
			let dayOffset = 0 // Today
			let hour = momentShareDate.hour()
			let minute = momentShareDate.minute()
			hour += minute < 50 ? 1 : 2 // Start next hour with at least 10 minutes left
			if (hour > 23) {
				dayOffset = 1 // Tomorrow
				hour = minute < 50 ? 0 : 1 // 12am or 1am
			}

			// Set share date/time
			momentShareDate
				.add(dayOffset, 'days')
				.hour(hour)
				.minute(minute)
				.second(0)
			const shareDate = momentShareDate.format()
			const shareTime = momentShareDate.format(MomentTimeFormat)
			setFieldValue('shareDate', shareDate)
			setFieldValue('shareTime', shareTime)

			// Set calendar range
			const calendarStartDate = shareDate
			const calendarEndDate = moment(shareDate)
				.add(1, 'years')
				.format()

			// Remember new share date for when we re-render the Formik dialog page
			const initialValues = {
				...this.state.initialValues,
				...values,
				shareDate,
				shareTime,
			}
			this.setState({
				initialValues,
				calendarStartDate,
				calendarEndDate,
			})
		}

		this.setState({ showScheduleShare: true })
	}

	handleCancelSchedule = () => {
		this.setState({ scheduleError: null, showScheduleShare: false, isSubmitting: false })
	}

	handleCancelEditSchedule = () => {
		this.setState({
			scheduleError: null,
			showScheduleShare: false,
			shareComplete: true,
			isSubmitting: false,
		})
	}

	handleResetSchedule = () => {
		this.setState({
			scheduleError: null,
			showScheduleShare: true,
			isSubmitting: false,
		})
	}

	handleCheckMaxChannelsExceeded = (
		maxChannelsPerShare,
		numberOfChannelsSelected,
		setFieldError
	) => {
		const { errors } = this.state
		if (numberOfChannelsSelected.length > maxChannelsPerShare && maxChannelsPerShare !== 0) {
			if (Object.keys(errors).length > 0) {
				//Ignore if we are already showing the error message
				return
			}
			setFieldError('form', lang.errorSharingMaxChannelsExceeded)
			let maxChannelsUnsupported = 'unsupported'
			errors[maxChannelsUnsupported] = lang.errorSharingMaxChannelsExceeded
			this.setState({ errors: errors })
		} else {
			if (Object.keys(errors).length === 0) {
				//Ignore if we aren't showing the error message already
				return
			}
			setFieldError('form', '')
			this.setState({ errors: {} })
		}
	}

	//#endregion

	handleRegularShareClick = (submitForm, numChannels, ...args) => {
		submitForm(...args)
	}

	handleQueueShareClick = (submitForm, ...args) => {
		submitForm(...args)
	}

	getShareButton = (
		reaction,
		providers,
		values,
		isSubmitting,
		submitForm,
		setFieldValue,
		sharingMaxChannelsPerShare
	) => {
		const { isScheduledSharingEnabled } = this.props
		const label = reaction ? reaction.displayText : lang.submitButton
		const isAnyChannelSelected =
			providers.twitterProviders.length +
				providers.facebookProviders.length +
				providers.otherProviders.length >
			0

		const numChannels =
			values && Array.isArray(values.userChannelIds) && values.userChannelIds.length
		const maxChannelsHaveBeenExceeded =
			numChannels > sharingMaxChannelsPerShare && sharingMaxChannelsPerShare !== 0
		// Share text required only for Reaction Comment/Reply, and only for providers that allow it (maxCharacterLimit > 0)
		const areAllValuesSet =
			!reaction || // Share
			reaction.reactionType === ReactionType.like || // Like
			reaction.reactionType === ReactionType.share || // Retweet
			(reaction.reactionType === ReactionType.comment && // Twitter Reply that has share text
				providers.selectedProviders.includes('Twitter') &&
				values.twitterShareText.length > 0) ||
			(reaction.reactionType === ReactionType.comment && // Comment reaction that requires text (LinkedIn/YouTube) and has share text
				(reaction.maxCharacterLimit === 0 || values.otherShareText.length > 0))

		return reaction || !isScheduledSharingEnabled ? (
			// Return a React button
			<Button
				onClick={this.handleRegularShareClick.bind(this, submitForm, numChannels)}
				disabled={
					isSubmitting || !isAnyChannelSelected || !areAllValuesSet || maxChannelsHaveBeenExceeded
				}
			>
				{label}
			</Button>
		) : (
			<div>
				<Button
					level={ButtonLevel.secondary}
					onClick={e => this.handleShowSchedule(values, setFieldValue)}
					disabled={
						isSubmitting || !isAnyChannelSelected || !areAllValuesSet || maxChannelsHaveBeenExceeded
					}
				>
					{lang.scheduleButton}
				</Button>
				<Button
					level={ButtonLevel.secondary}
					onClick={e =>
						this.handleAutoShare(this.handleQueueShareClick.bind(this, submitForm), setFieldValue)
					}
					disabled={
						isSubmitting || !isAnyChannelSelected || !areAllValuesSet || maxChannelsHaveBeenExceeded
					}
				>
					{lang.autoScheduleButton}
				</Button>
				<Button
					onClick={this.handleRegularShareClick.bind(this, submitForm, numChannels)}
					disabled={
						isSubmitting || !isAnyChannelSelected || !areAllValuesSet || maxChannelsHaveBeenExceeded
					}
				>
					{label}
				</Button>
			</div>
		)
	}

	handleEditShareTextStart = group => {
		// Tell React which share text editor is active
		this.setState({ activeShareTextEditor: group })
	}

	handleEditShareTextEnd = () => {
		// No share text editor active
		this.setState({ activeShareTextEditor: activeEditor.none })
	}

	handleViewSharingDisclosure = e => {
		e.preventDefault()

		// Open Sharing Disclosures dialog
		this.setState({ showSharingDisclosures: true })
	}

	handleHideSharingDisclosure = () => {
		// Close Sharing Disclosures dialog
		this.setState({ showSharingDisclosures: false })
	}

	handleSignUpClick = () => {
		// Switch to Join Now to Share mode
		this.setState({ joinNowToShare: true })
	}

	handleSignInClick = () => {
		// Switch to Join Now to Share mode
		this.setState({ joinNowToShare: false })
	}

	getSharingError = (error, defaultMessage) => {
		let errorMessage = defaultMessage
		if (error && error.data && error.data.Reason) {
			switch (error.data.Reason) {
				case 'sharing_capped': {
					errorMessage = lang.errorSharingCapped
					break
				}
				case 'already_shared': {
					errorMessage = lang.errorAlreadyShared
					break
				}
				default:
					break // Default set above
			}
		}
		return errorMessage
	}

	getSelectedProviders = (userChannels, userChannelIds) => {
		const result = {
			selectedProviders: [],
			twitterProviders: [],
			facebookProviders: [],
			otherProviders: [],
		}

		if (userChannels.length > 0) {
			const selectedChannels = userChannels.filter(
				channel => userChannelIds.indexOf(channel.userChannelId) !== -1
			) // Only include selected

			result.selectedProviders = selectedChannels
				.map(channel => channel.provider) // Only list provider names
				.filter((el, i, arr) => i === arr.indexOf(el)) // Remove duplicates
			result.twitterProviders = result.selectedProviders.filter(twitterFilter)
			result.facebookProviders = result.selectedProviders.filter(facebookFilter)
			result.otherProviders = result.selectedProviders.filter(otherFilter)
		}

		return result
	}

	getNextPost = e => {
		const { postIds, changePostMultiple } = this.props
		let { sharePost } = this.props

		let currentPostIndex = postIds.indexOf(sharePost.postId)
		let totalPosts = postIds.length

		if (currentPostIndex === totalPosts - 1) {
			return
		}

		let nextPostId = postIds[currentPostIndex + 1]

		changePostMultiple(nextPostId, postIds, sharePost.activity)
	}

	getPreviousPost = e => {
		const { postIds, changePostMultiple } = this.props
		let { sharePost } = this.props

		let currentPostIndex = postIds.indexOf(sharePost.postId)

		if (currentPostIndex === 0) {
			return
		}

		let nextPostId = postIds[currentPostIndex - 1]

		changePostMultiple(nextPostId, postIds, sharePost.activity)
	}

	appendSuccessfulShare = (postId, shareType, pointsEarned, selectedChannelIds) => {
		const currentSuccessfulShares = this.state.shareMultipleSuccessfulShares

		var totalPoints = selectedChannelIds.length > 1 ? pointsEarned * selectedChannelIds.length : pointsEarned || 0
		
		const newShare = {
			postId: postId,
			shareType: shareType || ShareType.shareNow,
			points: totalPoints,
		}

		this.setState({
			shareMultipleSuccessfulShares: [...currentSuccessfulShares, newShare],
		})
	}

	getOtherSharingOptions = () => {
		const {
			sharePost: post,
			showShareButtonOnSocialPosts,
			facebookNativeConfig,
			twitterNativeConfig,
			xingNativeConfig,
			linkedInNativeConfig,
			getEmailLink,
			getPost,
			getTwitterLink,
			getFacebookLink,
			getXingLink,
			getLinkedInLink,
			getCopyLink,
			nativeSharingHostUrl,
			isMultiShare,
		} = this.props

		let { showPostShareLinks, enableShareByEmail } = this.props
		const { showAddChannels } = this.state

		const isNativeVideo =
			post &&
			post.media &&
			post.media.role === (VideoTypes.hostedVideo || VideoTypes.hostedAdvancedVideo)

		// No post to link to when ShareImagesOnly is set
		if (post.shareImagesOnly) return null

		// Business Rule: Don't show Email/Copy links on social posts unless
		//                ShowShareButtonOnSocialPosts is set
		if (!post.showShareButton(showShareButtonOnSocialPosts)) return null

		let isFacebookNativeSharingEnabled = facebookNativeConfig.isEnabled
		let isTwitterNativeSharingEnabled = twitterNativeConfig.isEnabled
		let isXingNativeSharingEnabled = xingNativeConfig.isEnabled
		// If we don't have the shareUrlBase, we can't really share to LinkedIn....
		let isLinkedInNativeSharingEnabled =
			linkedInNativeConfig.isEnabled && !!linkedInNativeConfig.shareUrlBase

		// Nothing to show if no 'other options' are available
		if (
			!showPostShareLinks &&
			!enableShareByEmail &&
			!isFacebookNativeSharingEnabled &&
			!isTwitterNativeSharingEnabled &&
			!isXingNativeSharingEnabled &&
			!isLinkedInNativeSharingEnabled
		)
			return null

		return (
			<SharingOptionsBar
				handleClose={this.handleClose}
				nativeSharingHostUrl={nativeSharingHostUrl}
				facebookNativeConfig={facebookNativeConfig}
				twitterNativeConfig={twitterNativeConfig}
				xingNativeConfig={xingNativeConfig}
				linkedInNativeConfig={linkedInNativeConfig}
				twitterShareTextSuggestion={this.twitterShareTextSuggestions}
				isFacebookNativeSharingEnabled={isFacebookNativeSharingEnabled}
				isTwitterNativeSharingEnabled={isTwitterNativeSharingEnabled}
				isXingNativeSharingEnabled={isXingNativeSharingEnabled}
				isLinkedInNativeSharingEnabled={isLinkedInNativeSharingEnabled}
				isNativeVideo={isNativeVideo}
				sharePostId={post.postId}
				sharePostActivity={post.activity}
				sharePostDisclosures={post.shareDisclosures}
				sharePoints={post.sharePoints}
				getEmailLink={getEmailLink}
				getCopyLink={getCopyLink}
				getPost={getPost}
				getTwitterLink={getTwitterLink}
				getFacebookLink={getFacebookLink}
				getXingLink={getXingLink}
				getLinkedInLink={getLinkedInLink}
				getSharingError={this.getSharingError}
				showAddChannels={showAddChannels}
				showPostShareLinks={showPostShareLinks}
				enableShareByEmail={enableShareByEmail}
				isMultiShare={isMultiShare}
			/>
		)
	}

	clearUrgentBroadcast = () => {
		const { urgentBroadcastAllIds, sharePost, clearUrgentBroadcast, viewBroadcast } = this.props
		const { activity: { activityReason, activityDetail } = {} } = sharePost

		// Only clear if urgent broadcast is open
		if (
			activityReason === Activity.Reason.broadcast &&
			urgentBroadcastAllIds.includes(activityDetail)
		) {
			viewBroadcast(activityDetail)
			clearUrgentBroadcast(activityDetail)
		}
	}

	getShareMultipleSuccessType = shareMultipleSuccessfulShares => {
		var isSchedule = shareMultipleSuccessfulShares.some(
			share => share.shareType === ShareType.schedule || share.shareType === ShareType.autoQueue
		)
		var isShareNow = shareMultipleSuccessfulShares.some(
			share => share.shareType === ShareType.shareNow
		)

		if (isSchedule && !isShareNow) {
			return ShareMultipleType.scheduled
		} else if (!isSchedule && isShareNow) {
			return ShareMultipleType.now
		} else if (isSchedule && isShareNow) {
			return ShareMultipleType.both
		}
		return ShareMultipleType.none
	}

	getShareMultipleSuccessMessage = (shareMultipleSuccessfulShares, shareMultipleSuccessType, enableLeaderboards) => {
		if (shareMultipleSuccessType === ShareMultipleType.scheduled) {
			return lang.shareMultipleScheduleSuccess
		} else if (
			shareMultipleSuccessType === ShareMultipleType.now ||
			shareMultipleSuccessType === ShareMultipleType.both
		) {
			var successMessage = (shareMultipleSuccessType === ShareMultipleType.now) ? lang.shareMultipleNowSuccess : lang.shareMultipleNowAndScheduleSuccess
			if (enableLeaderboards)
			{
				var shareNowPoints = 0
				shareMultipleSuccessfulShares.forEach(share => {
					if (share.shareType === ShareType.shareNow) {
						shareNowPoints += share.points
					}
				})

				return successMessage.concat(lang.shareMultiplePoints(shareNowPoints))
			}
			return successMessage
		}
		return ''
	}

	render() {
		const {
			user,
			userChannels,
			sharePost,
			shareReaction,
			showShareButtonOnSocialPosts,
			enableLeaderboards,
			primaryColor,
			isSsoEnabled,
			isShareDialogV8Enabled,
			viewport,
			memberUsageComplianceRequired,
			sharingMaxChannelsPerShare,
			enableAlternateShareText,
			isMultiShare,
		} = this.props
		const {
			reaction,
			initialValues,
			isInitialized,
			isScheduledShare,
			dialogTitle,
			disableNext,
			disablePrevious,
			scheduledShareIds,
			activeShareTextEditor,
			calendarStartDate,
			calendarEndDate,
			scheduleError,
			shareComplete,
			shareMultipleComplete,
			shareMultipleSuccessfulShares,
			showAddChannels,
			showScheduleShare,
			joinNowToShare,
			commentPlaceholder,
			twitterCommentPlaceholder,
			sharingDisclosureInfo,
			isSubmitting,
			lastSharedSubtitle,
		} = this.state

		// Is the new Share Dialog enabled?
		if (isShareDialogV8Enabled && !shareReaction) return <ShareDialogV8 />
		// Can logged in user share this post?
		const userCantShare =
			user &&
			(!user.canSharePosts ||
				(sharePost && !sharePost.showShareButton(showShareButtonOnSocialPosts) && !shareReaction))

		// Skip render if not open, if MUC is required, or not yet initialized (e.g. First render before componentDidUpdate)
		if (memberUsageComplianceRequired) return null
		if (!sharePost || !isInitialized || userCantShare) return null

		// Business Rule: Anonymous user can Share using an abbreviated RegFlow within the ShareDialog. After share
		//                is complete, user must complete the RegFlow on the main page.
		const loginRequired = !user // NOTE: Don't use isUserLoggedIn here. Share can proceed, even if user is Registered (Profile Incomplete)
		const addChannelsSubtitle =
			lang.addChannelInstructions +
			(userChannels.length === 0 ? ' ' + lang.addChannelZeroChannels : '')
		const isOpen = sharePost !== null
		const handleCancelScheduledShare = scheduledShareIds
			? this.handleCancelEditSchedule
			: this.handleCancelSchedule
		const reactionProvider = getReactionProvider(reaction, sharePost)
		const reactSuccess =
			reaction && reactionsLang.success[`${reactionProvider}${reaction.reactionType}`]
		const shareSuccessMessage = reactSuccess || lang.shareCompleteSuccess
		const selectedChannelIds = safeGetNestedProp(this.state, 'initialValues.userChannelIds', [])
		const shareSuccessPoints =
			(shareComplete &&
				!reaction &&
				selectedChannelIds &&
				sharePost.sharePoints &&
				sharePost.sharePoints > 0 &&
				lang.shareCompletePoints(sharePost.sharePoints * selectedChannelIds.length)) ||
			null
		let showShareChannels = true

		// If SSO is enabled, set an SSO return URL that will reopen the ShareDialog after the RegFlow completes
		let ssoReturnUrl = null
		if (loginRequired && isSsoEnabled && sharePost) {
			ssoReturnUrl = window.location.href
			ssoReturnUrl = addQueryParam(ssoReturnUrl, 'sharePost', sharePost.postId)
			if (shareReaction) {
				ssoReturnUrl = addQueryParam(ssoReturnUrl, 'shareReaction', shareReaction)
			}
		}

		const dynamicStyles = {
			editScheduledShare: { ...styles.editScheduledShare, color: primaryColor },
		}

		const shareMultipleSuccessType = this.getShareMultipleSuccessType(shareMultipleSuccessfulShares)
		const shareMultipleSuccessMessage = this.getShareMultipleSuccessMessage(
			shareMultipleSuccessfulShares,
			shareMultipleSuccessType,
			enableLeaderboards
		)

		return (
			<div data-component="ShareDialog">
				{loginRequired /* Sign In Page */ ? (
					<Dialog
						title={lang.dialogTitleSignInRequired}
						style={styles.signIn}
						isOpen={true}
						onClose={this.handleClose}
						shouldCloseOnOverlayClick={false}
					>
						<div style={styles.postSummary[viewport.size]}>
							<PostSummary post={sharePost} openInAppLinkInNewTab={true} />
						</div>

						<RegFlow
							style={styles.regFlow}
							fromShareDialog={true}
							joinNowToShare={joinNowToShare}
							ssoReturnUrl={ssoReturnUrl}
							onSignUpClick={this.handleSignUpClick}
							onSignInClick={this.handleSignInClick}
						/>
					</Dialog>
				) : shareComplete /* Share Complete */ ? (
					<Dialog isOpen={true} onClose={this.handleClose}>
						{isScheduledShare ? (
							<Fragment>
								<h1 style={styles.shareScheduled}>{lang.scheduleSuccess}</h1>
								<div style={styles.shareScheduledDate}>
									<DateTime date={initialValues.shareDate} format={DateFormat.DateTimeTimeZone}>
										{formattedDateTime => formattedDateTime}
									</DateTime>
								</div>
								{isScheduledShare && (
									<div style={dynamicStyles.editScheduledShare}>
										<Link
											to={Routes.Settings.ScheduledShares.linkPath}
											style={styles.editDeleteLink}
											onClick={() => this.handleClose()}
										>
											{lang.viewScheduledSharesLink}
										</Link>
										<span style={styles.editDeleteSeparator}>•</span>
										<span style={styles.editDeleteLink} onClick={this.handleShareAgain}>
											{lang.shareAgain}
										</span>
									</div>
								)}
							</Fragment>
						) : (
							<div style={styles.shareComplete}>
								<h1 style={styles.shareComplete.text}>{shareSuccessMessage}</h1>
								{enableLeaderboards && shareSuccessPoints && (
									<h1 style={styles.shareComplete.points}>{shareSuccessPoints}</h1>
								)}
								{!reaction && (
									<div style={dynamicStyles.editScheduledShare}>
										{enableLeaderboards && shareSuccessPoints && (
											<Fragment>
												<Link
													style={styles.editDeleteLink}
													to={Routes.Leaderboards.linkPath}
													onClick={() => this.handleClose()}
												>
													{lang.viewLeaderboard}
												</Link>
												<span style={styles.editDeleteSeparator}>•</span>
											</Fragment>
										)}
										<span style={styles.editDeleteLink} onClick={this.handleShareAgain}>
											{lang.shareAgain}
										</span>
									</div>
								)}
							</div>
						)}
					</Dialog>
				) : shareMultipleComplete /* Share Multiple Complete */ ? (
					<Dialog isOpen={true} onClose={this.handleClose}>
						<Fragment>
							<h1 style={styles.shareMultipleTitle}>{shareMultipleSuccessMessage}</h1>
							<p style={styles.shareMultipleSubTitle}>
								{shareMultipleSuccessType === ShareMultipleType.scheduled
									? lang.shareMultipleSubTitleSchedule
									: lang.shareMultipleSubTitle}
							</p>
							<div style={dynamicStyles.editScheduledShare}>
								{enableLeaderboards && (
									<Link
										style={styles.editDeleteLink}
										to={Routes.Leaderboards.linkPath}
										onClick={() => this.handleClose()}
									>
										{lang.viewLeaderboard}
									</Link>
								)}
								{(shareMultipleSuccessType === ShareMultipleType.scheduled ||
									shareMultipleSuccessType === ShareMultipleType.both) && (
									<Fragment>
										{enableLeaderboards && (
										<	span style={styles.editDeleteSeparator}>•</span>
										)}
										<Link
											to={Routes.Settings.ScheduledShares.linkPath}
											style={styles.editDeleteLink}
											onClick={() => this.handleClose()}
										>
											{lang.viewScheduledSharesLink}
										</Link>
									</Fragment>
								)}
							</div>
						</Fragment>
					</Dialog>
				) : showAddChannels /* Add Channels */ ? (
					<Dialog
						isOpen={true}
						title={lang.addChannelTitle}
						subtitle={addChannelsSubtitle}
						otherActions={this.getOtherSharingOptions()}
						onClose={this.handleClose}
						submitButtons={
							<div>
								<Button onClick={this.handleSkipAddChannels}>{buttons.next}</Button>
							</div>
						}
						shouldCloseOnOverlayClick={false}
					>
						<SocialAccounts
							onlyShareChannels={reaction ? false : true}
							onlyProviders={reaction ? [reactionProvider] : null}
							onAddChannel={this.handleAddChannel}
							onRemoveChannel={this.handleRemoveChannel}
							fromShareDialog={true}
						/>
					</Dialog>
				) : showScheduleShare /* Schedule Share */ ? (
					<ShareScheduleDialog
						title={lang.scheduleTitle}
						shareDate={initialValues.shareDate}
						shareTime={initialValues.shareTime}
						calendarStartDate={calendarStartDate}
						calendarEndDate={calendarEndDate}
						scheduleError={scheduleError}
						isSubmitting={isSubmitting}
						onSubmit={this.handleShare}
						onCancel={handleCancelScheduledShare}
						onClose={this.handleClose}
						onReset={this.handleResetSchedule}
					/>
				) : (
					/* Share Post - Main Page */
					<Formik
						ref={this.formikRef}
						initialValues={initialValues}
						enableReinitialize
						validate={this.validate}
						onSubmit={this.handleShare}
						render={({
							values,
							touched,
							dirty,
							isSubmitting,
							submitForm,
							setFieldValue,
							props,
							errors,
							setFieldError,
						}) => {
							const providers = this.getSelectedProviders(userChannels, values.userChannelIds)
							const submitButton = this.getShareButton(
								reaction,
								providers,
								values,
								isSubmitting,
								submitForm,
								setFieldValue,
								sharingMaxChannelsPerShare
							)

							this.CheckIfMaxChannelsHaveExceeded = this.handleCheckMaxChannelsExceeded(
								sharingMaxChannelsPerShare,
								values.userChannelIds,
								setFieldError
							)

							// Set margin below editors to compensate for inactive editor going active. This keeps
							// the Share button at a stable height (No jumping when clicked!)
							const showShareTextInactivePlaceholder =
								activeShareTextEditor === activeEditor.none && // No active editor
								shareReaction !== ReactionType.like && // Not a Like reaction (Like has no editors)
								// At least one editor shown
								(providers.twitterProviders.length > 0 ||
									providers.facebookProviders.length > 0 ||
									providers.otherProviders.length > 0)
							const shareTextEditorsStyle = showShareTextInactivePlaceholder
								? { marginBottom: width.lineHeight.base * 3 }
								: null

							// Create a Post Translation handler that captures Formik props
							this.handlePostTranslationChange = this.getPostTranslationChangeHandler(
								values,
								setFieldValue
							)

							return (
								<Fragment>
									<Form>
										<Dialog
											style={styles.shareDialog}
											footerStyle={styles.footer}
											title={dialogTitle}
											isOpen={isOpen}
											isPaged={isMultiShare}
											onClose={this.handleClose}
											onNext={this.getNextPost}
											onPrevious={this.getPreviousPost}
											disableNext={disableNext}
											disablePrevious={disablePrevious}
											otherActions={this.getOtherSharingOptions()}
											additionalInfo={sharingDisclosureInfo}
											submitButtons={<div>{submitButton}</div>}
											shouldCloseOnOverlayClick={false}
											subtitle={lastSharedSubtitle}
										>
											{/* Form Error	 */}
											<FormErrors errors={errors} touched={touched} />

											{/* Share Channels */}
											{showShareChannels && (
												<div>
													<Field
														name="userChannelIds"
														component={ShareChannels}
														channels={userChannels}
														onAddChannel={e => this.handleShowAddChannels(values.userChannelIds)}
														updateChannels={this.updateChannels}
													/>
												</div>
											)}

											{/* Post */}
											<div style={styles.postSummary[viewport.size]}>
												<PostSummary post={sharePost} openInAppLinkInNewTab={true} />
											</div>

											{/* Post Translation */}
											<div style={styles.translation}>
												<PostTranslationButton
													isTranslated={sharePost.isTranslated}
													isTranslatable={sharePost.userTranslatable}
													postId={sharePost.postId}
												/>
											</div>

											{/* Share Text */}
											<div style={shareTextEditorsStyle}>
												{providers.twitterProviders.length > 0 &&
													shareReaction !== ReactionType.like && (
														<div style={styles.shareTextContainer}>
															<Field
																name={shareTextFormNames.twitter}
																component={enableAlternateShareText ? ShareText_v2 : ShareText}
																providers={providers.twitterProviders}
																twitterMax={sharePost.twitterMaxCharCount}
																refreshButtonId="next-suggestion-twitter"
																imageCount={sharePost.imageGallery ? sharePost.imageGallery.filter(x => !x.mimeType.includes('gif')).length : 0}
																isActive={activeShareTextEditor === activeEditor.twitter}
																onInactiveClick={e =>
																	this.handleEditShareTextStart(activeEditor.twitter)
																}
																placeholder={twitterCommentPlaceholder}
																onNextSuggestion={this.handleNextSuggestion(
																	values,
																	activeEditor.twitter
																)}
															/>
														</div>
													)}
												{providers.facebookProviders.length > 0 &&
													shareReaction !== ReactionType.like && (
														<div style={styles.shareTextContainer}>
															<Field
																name={shareTextFormNames.facebook}
																component={enableAlternateShareText ? ShareText_v2 : ShareText}
																providers={['Facebook']}
																imageCount={sharePost.imageGallery ? sharePost.imageGallery.length : 0}
																refreshButtonId="next-suggestion-facebook"
																isActive={
																	activeShareTextEditor === activeEditor.facebook // Collapse providers to just Facebook, since Facebook and FacebookPage icons look the same
																}
																onInactiveClick={e =>
																	this.handleEditShareTextStart(activeEditor.facebook)
																}
																onActiveBlur={this.handleEditShareTextEnd}
																onNextSuggestion={this.handleNextSuggestion(
																	values,
																	activeEditor.facebook
																)}
																placeholder={commentPlaceholder}
															/>
														</div>
													)}
												{providers.otherProviders.length > 0 &&
													shareReaction !== ReactionType.like && (
														<div style={styles.shareTextContainer}>
															<Field
																name={shareTextFormNames.other}
																component={enableAlternateShareText ? ShareText_v2 : ShareText}
																providers={providers.otherProviders}
																isActive={activeShareTextEditor === activeEditor.other}
																refreshButtonId="next-suggestion-other-providers"
																imageCount={sharePost.imageGallery ? sharePost.imageGallery.length : 0}
																onInactiveClick={e =>
																	this.handleEditShareTextStart(activeEditor.other)
																}
																onActiveBlur={this.handleEditShareTextEnd}
																onNextSuggestion={this.handleNextSuggestion(
																	values,
																	activeEditor.other
																)}
																placeholder={commentPlaceholder}
															/>
														</div>
													)}
											</div>

											{/* Sharing Disclosures Dialog */}
											{sharingDisclosureInfo && (
												<Dialog
													style={styles.sharingDisclosureDialog}
													isOpen={this.state.showSharingDisclosures}
													onClose={this.handleHideSharingDisclosure}
													title={lang.sharingDisclosureTitle}
													subtitle={lang.sharingDisclosureInfo}
													submitButtons={
														<div>
															<Button onClick={this.handleHideSharingDisclosure}>
																{buttons.ok}
															</Button>
														</div>
													}
													shouldCloseOnOverlayClick={false}
												>
													<ShareDisclosures
														disclosures={sharePost.shareDisclosures}
														selectedProviders={providers.selectedProviders}
													/>
												</Dialog>
											)}
										</Dialog>
									</Form>
								</Fragment>
							)
						}}
					/>
				)}
			</div>
		)
	}
}

// Connect to Redux store, wrap in Redux form
export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog)
