import React from 'react'
import PropTypes from 'prop-types'
import GraphemeSplitter from 'grapheme-splitter'

// Styles
import { highlightStyle } from 'styles/defaults'

class HighlightText extends React.Component {
	static propTypes = {
		highlightStyle: PropTypes.object.isRequired,
		text: PropTypes.string.isRequired,
		ranges: PropTypes.array,
		render: PropTypes.func.isRequired,
	}

	static defaultProps = {
		highlightStyle: highlightStyle,
	}

	constructor(props) {
		super(props)
		this.splitter = new GraphemeSplitter()
	}

	chunk = (text, matches) => {
		// If there are no matches, return the full string
		if (!matches)
			return [
				{
					text: text,
					highlight: false,
				},
			]

		// Split the text into visible characters
		const textArray = this.splitter.splitGraphemes(text)

		// Split it up so it's easier to work with
		let nextIndex = 0

		const chunks = matches
			.filter(match => text.length >= match.start + match.length) // Filtering out matches that are out of range of text
			.reduce((memo, match) => {
				// Push the non-highlight first
				memo.push({
					text: textArray.slice(nextIndex, match.start).join(''),
					highlight: false,
				})

				// Push the highlight portion
				memo.push({
					text: textArray.slice(match.start, match.start + match.length).join(''),
					highlight: true,
				})

				nextIndex = match.start + match.length

				return memo
			}, [])

		// Add chunk for the rest, if last match doesn't go all the way to the end
		if (nextIndex < text.length) {
			chunks.push({
				text: textArray.slice(nextIndex).join(''),
			})
		}

		return chunks
	}

	render() {
		const { highlightStyle, ranges, text, render } = this.props

		if (!ranges || ranges.length === 0) return render(text)

		const chunks = this.chunk(text, ranges)
		const chunksLength = chunks.length

		return render(
			chunks.map((chunk, i) => (
				<span key={`${i}-${chunksLength}`} style={chunk.highlight ? highlightStyle : null}>
					{chunk.text}
				</span>
			))
		)
	}
}

export default HighlightText
